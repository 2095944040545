import { getLocalePrice } from '@common/lib/getLocalePrice';
import {
  MAX_CHOOSABLE_GIFT_CONTENTS,
  MIN_CHOOSABLE_GIFT_CONTENTS_STRING,
  MAX_CHOOSABLE_GIFT_CONTENTS_STRING
} from '@console/common/constants';
import ContentModal from '@console/components/common/ContentModal';
import PricesSlider from '@console/components/common/PricesSlider';
import SelectedContentCard from '@console/components/common/SelectedContentCard';
import Contents from '@console/components/common/contentsSelection/Contents';
import StepHandlerContainer from '@console/containers/projectDraft/StepHandlerContainer';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import type {
  ChoosableContentsSelectionPropsMappedFromState,
  ChoosableContentsSelectionPropsMappedFromDispatch
} from '@console/containers/projectDraft/contentsSelection/ChoosableContentsSelectionContainer';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';
import type { ComponentProps } from 'react';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    title: {
      marginBottom: theme.spacing(2)
    },
    titleText: {
      marginRight: theme.spacing(2)
    },
    section: {
      marginBottom: theme.spacing()
    },
    selectedContent: {
      marginRight: theme.spacing(3),
      marginBottom: theme.spacing(3)
    },
    filter: {
      minWidth: 300,
      marginRight: theme.spacing(3),
      marginBottom: theme.spacing()
    },
    pricesFilter: {
      marginTop: -theme.spacing(2)
    },
    errorMessage: {
      fontSize: 14
    }
  });

type ChoosableContentsSelectionProps =
  ChoosableContentsSelectionPropsMappedFromState &
    ChoosableContentsSelectionPropsMappedFromDispatch &
    WithStyles<typeof styles>;

export const SLIDER_MIN = 0;
export const SLIDER_MAX = 3000;

const ChoosableContentsSelection: React.FC<ChoosableContentsSelectionProps> = ({
  brands,
  contentsWithIsSelected,
  selectedContents,
  contentsTotalCount,
  isContentsLoading,
  handleStepNextFromParent,
  handleContentSelect,
  handleContentDeselect,
  onChangeBrandFilter,
  onChangePriceRangeFilter,
  handleScrollToBottom,
  giftConfigError,
  classes
}) => {
  const navigate = useNavigate();
  const SLIDER_STEP = 200;
  const sliderMarks = [
    {
      value: SLIDER_MIN,
      label: getLocalePrice(SLIDER_MIN)
    },
    {
      value: 1000,
      label: getLocalePrice(1000)
    },
    {
      value: 2000,
      label: getLocalePrice(2000)
    },
    {
      value: SLIDER_MAX,
      label: `${getLocalePrice(SLIDER_MAX)}以上`
    }
  ];

  const [selectedPricesRange, selectPricesRange] = useState<
    ComponentProps<typeof PricesSlider>['sliderPrices']
  >({
    higherPrice: SLIDER_MAX,
    lowerPrice: SLIDER_MIN
  });
  const [modalContent, setModalContent] = useState<
    (typeof contentsWithIsSelected)[number] | null
  >(null);

  const isContentModalSelectable = useCallback(
    (content: (typeof contentsWithIsSelected)[number]): boolean =>
      selectedContents.length < MAX_CHOOSABLE_GIFT_CONTENTS ||
      content.isSelected,
    [selectedContents]
  );

  const getUnselectableMessageForContentModal = useCallback(
    (content: (typeof contentsWithIsSelected)[number]): string =>
      selectedContents.length >= MAX_CHOOSABLE_GIFT_CONTENTS &&
      !content.isSelected
        ? 'この商品を選択する場合は、選択中の商品を解除してください。'
        : '',
    [selectedContents]
  );

  const handleBrandFilterChange = (
    selectedBrand: (typeof brands)[number] | null
  ) => {
    onChangeBrandFilter(selectedBrand);
  };

  const handlePricesFilterChange: ComponentProps<
    typeof PricesSlider
  >['handleSliderPricesChange'] = sliderPrices => {
    selectPricesRange(sliderPrices);
  };

  const handlePriceRangeChange: ComponentProps<
    typeof PricesSlider
  >['handleSliderPricesCommitted'] = priceRange => {
    onChangePriceRangeFilter(priceRange);
  };

  const handleSelectedContentCardOnClick = (
    content: (typeof selectedContents)[number]
  ) => {
    if (!content) {
      return;
    }
    setModalContent({ ...content, isSelected: true });
  };

  const handleContentCardOnClick = (
    content: (typeof contentsWithIsSelected)[number]
  ) => {
    setModalContent(content);
  };

  const handleContentSelectInContentModal = (
    content: (typeof contentsWithIsSelected)[number]
  ) => {
    handleContentSelect(content);
    setModalContent(null);
  };

  const handleContentDeselectInContentModal = (
    content: (typeof contentsWithIsSelected)[number]
  ) => {
    handleContentDeselect(content);
    setModalContent(null);
  };

  const renderSelectedContents = () =>
    [...Array(MAX_CHOOSABLE_GIFT_CONTENTS)].map((_, index) => (
      <SelectedContentCard
        content={selectedContents[index] || null}
        onClickHandler={() =>
          handleSelectedContentCardOnClick(selectedContents[index])
        }
        className={classes.selectedContent}
        key={index}
      />
    ));

  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="flex-start"
      data-cy="choosableContentsSelection"
    >
      <Typography
        color="error"
        className={clsx(classes.errorMessage, classes.title)}
      >
        {giftConfigError?.selectedContents}
      </Typography>

      <Grid
        item
        container
        xs={12}
        alignItems="flex-end"
        className={classes.title}
      >
        <Typography
          variant="body1"
          color="textPrimary"
          className={classes.titleText}
        >
          ギフトを{MIN_CHOOSABLE_GIFT_CONTENTS_STRING}種類以上選んでください
        </Typography>
        <Typography
          variant="body2"
          color="textPrimary"
          className={classes.titleText}
        >
          ※最大{MAX_CHOOSABLE_GIFT_CONTENTS_STRING}種類まで選択できます。
        </Typography>
      </Grid>

      <Grid item xs={12} className={classes.title}>
        <Typography variant="body1" color="textPrimary">
          選択中のギフト
        </Typography>
      </Grid>

      <Grid
        item
        container
        xs={12}
        justifyContent="flex-start"
        className={classes.section}
      >
        {renderSelectedContents()}
      </Grid>

      <Grid item xs={12} className={classes.title}>
        <Typography variant="body1" color="textPrimary">
          ギフト一覧
        </Typography>
      </Grid>

      <Grid
        item
        container
        xs={12}
        className={classes.section}
        alignItems="center"
      >
        <Autocomplete
          id="brand-filter"
          autoHighlight
          size="small"
          className={classes.filter}
          options={brands}
          getOptionLabel={(option: (typeof brands)[number]) => option.name}
          onChange={(_e, brand) => handleBrandFilterChange(brand)}
          data-cy="brandFilter"
          renderInput={params => (
            <TextField
              {...params}
              label="ブランド"
              fullWidth
              margin="none"
              variant="outlined"
              data-cy="brandFilterTextField"
            />
          )}
        />
        <div className={clsx(classes.filter, classes.pricesFilter)}>
          <PricesSlider
            sliderPrices={selectedPricesRange}
            handleSliderPricesChange={handlePricesFilterChange}
            handleSliderPricesCommitted={handlePriceRangeChange}
            sliderMin={SLIDER_MIN}
            sliderMax={SLIDER_MAX}
            sliderStep={SLIDER_STEP}
            sliderMarks={sliderMarks}
          />
        </div>
      </Grid>

      <Contents
        contentsWithIsSelected={contentsWithIsSelected}
        contentsTotalCount={contentsTotalCount}
        isLoading={isContentsLoading}
        selectContentHandler={handleContentCardOnClick}
        scrollToBottomHandler={handleScrollToBottom}
      />

      {!!modalContent && (
        <ContentModal
          isOpen={true}
          isSelectable={isContentModalSelectable(modalContent)}
          onSelectHandler={() =>
            handleContentSelectInContentModal(modalContent)
          }
          onDeselectHandler={() =>
            handleContentDeselectInContentModal(modalContent)
          }
          onCloseHandler={() => {
            setModalContent(null);
          }}
          content={modalContent}
          isSelected={modalContent.isSelected}
          unselectableMessage={getUnselectableMessageForContentModal(
            modalContent
          )}
        />
      )}

      <StepHandlerContainer
        handleStepNextFromParent={() => handleStepNextFromParent(navigate)}
      />
    </Grid>
  );
};

export default withStyles(styles)(ChoosableContentsSelection);
