import { addBulkGifteeBoxIssueConfigs } from '@common/api/graphql/addBulkGifteeBoxIssueConfigsGql';
import { createSuccessMessage } from '@common/api/graphql/successMessage';
import {
  addBulkGifteeBoxIssueConfigsStart,
  addBulkGifteeBoxIssueConfigsSuccess,
  addBulkGifteeBoxIssueConfigsFailure
} from '@console/actions/actions';
import handleApiErrorIfNeeded from './handleApiErrorIfNeeded';
import type { AddBulkGifteeBoxIssueConfigsResponse } from '@common/api/graphql/addBulkGifteeBoxIssueConfigsGql';
import type { ApiError } from '@common/api/graphql/apolloClient';
import type { AppThunkAction } from '@console/actions/thunkType';
import type { AddingBulkGifteeBoxIssueConfigs } from '@console/reducers/edit/project/gifteeBoxConfigs/addingBulkGifteeBoxIssueConfigs';

export const addBulkGifteeBoxIssueConfigsSuccessThunk =
  (
    _response: AddBulkGifteeBoxIssueConfigsResponse
  ): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(
      addBulkGifteeBoxIssueConfigsSuccess(createSuccessMessage('追加発注'))
    );
    return Promise.resolve();
  };

export const addBulkGifteeBoxIssueConfigsFailureThunk =
  (error: ApiError): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(addBulkGifteeBoxIssueConfigsFailure());
    return dispatch(handleApiErrorIfNeeded(error));
  };

export const addBulkGifteeBoxIssueConfigsThunk =
  (
    gifteeBoxConfigUrlCode: UrlCode,
    addingBulkGifteeBoxIssueConfigs: AddingBulkGifteeBoxIssueConfigs
  ): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(addBulkGifteeBoxIssueConfigsStart());

    return addBulkGifteeBoxIssueConfigs(
      gifteeBoxConfigUrlCode,
      addingBulkGifteeBoxIssueConfigs
    )
      .then((response: AddBulkGifteeBoxIssueConfigsResponse) =>
        dispatch(addBulkGifteeBoxIssueConfigsSuccessThunk(response))
      )
      .catch((error: ApiError) =>
        dispatch(addBulkGifteeBoxIssueConfigsFailureThunk(error))
      );
  };
