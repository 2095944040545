import { connect } from 'react-redux';
import Drawer from '../../components/account/Drawer';
import type { AppThunkDispatch } from '../../actions/thunkType';
import type { AppState } from '../../reducers';

const mapStateToProps = (state: AppState) => ({
  distributionPartner: state.distributionPartner,
  distributionPartnerUser: state.distributionPartnerUser
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({});

export type DrawerPropsMappedFromState = ReturnType<typeof mapStateToProps>;
export type DrawerPropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(mapStateToProps, mapDispatchToProps)(Drawer);
