import { requestGifteeBoxIssueAmount } from '@common/api/graphql/requestGifteeBoxIssueAmountGql';
import { createSuccessMessage } from '@common/api/graphql/successMessage';
import {
  requestGifteeBoxIssueAmountStart,
  requestGifteeBoxIssueAmountSuccess,
  requestGifteeBoxIssueAmountFailure
} from '../actions';
import handleApiErrorIfNeeded from './handleApiErrorIfNeeded';
import type { AppThunkAction } from '../thunkType';
import type { ApiError } from '@common/api/graphql/apolloClient';
import type { RequestGifteeBoxIssueAmountResponse } from '@common/api/graphql/requestGifteeBoxIssueAmountGql';

export const requestGifteeBoxIssueAmountSuccessThunk =
  (): AppThunkAction<Promise<void>> => dispatch => {
    const snackbarMessage = createSuccessMessage('数量確定');
    dispatch(requestGifteeBoxIssueAmountSuccess(snackbarMessage));
    return Promise.resolve();
  };

export const requestGifteeBoxIssueAmountFailureThunk =
  (error: ApiError): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(requestGifteeBoxIssueAmountFailure());
    return dispatch(handleApiErrorIfNeeded(error));
  };

export const requestGifteeBoxIssueAmountThunk =
  (
    issueAmount: number,
    bulkGifteeBoxIssueConfigUrlCode: UrlCode
  ): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(requestGifteeBoxIssueAmountStart());

    return requestGifteeBoxIssueAmount(
      issueAmount,
      bulkGifteeBoxIssueConfigUrlCode
    )
      .then((response: RequestGifteeBoxIssueAmountResponse) =>
        dispatch(requestGifteeBoxIssueAmountSuccessThunk())
      )
      .catch(error => dispatch(requestGifteeBoxIssueAmountFailureThunk(error)));
  };
