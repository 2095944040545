import { getSelectedGifteeBoxConfig } from '@console/selectors/projectDetail/gifteeBoxConfigs';
import { createSelector } from 'reselect';
import type { AppState } from '@console/reducers';
import type {
  LocalBulkGifteeBoxIssueConfig,
  LocalGifteeBoxConfig
} from '@console/reducers/projects';
import type { LocalClaimEstimateForDisplay } from '@console/selectors/projectDetail';

export type LocalBulkGifteeBoxIssueConfigForDisplay =
  LocalBulkGifteeBoxIssueConfig & {
    claimEstimate: LocalClaimEstimateForDisplay;
  };

const getSelectedProjectBulkGifteeBoxIssueConfigUrlCode = (state: AppState) =>
  state.selectedProjectBulkGifteeBoxIssueConfigUrlCode;

const getSelectedBulkGifteeBoxIssueConfigs = createSelector(
  [getSelectedGifteeBoxConfig],
  (gifteeBoxConfig): LocalGifteeBoxConfig['bulkGifteeBoxIssueConfigs'] =>
    gifteeBoxConfig?.bulkGifteeBoxIssueConfigs ?? []
);

const getSelectedBulkGifteeBoxIssueConfigFromArray = createSelector(
  [
    getSelectedBulkGifteeBoxIssueConfigs,
    getSelectedProjectBulkGifteeBoxIssueConfigUrlCode
  ],
  (
    bulkGifteeBoxIssueConfigs,
    bulkGifteeBoxIssueConfigUrlCode
  ): LocalBulkGifteeBoxIssueConfig | undefined =>
    bulkGifteeBoxIssueConfigs.find(
      bulkGifteeBoxIssueConfig =>
        bulkGifteeBoxIssueConfig.urlCode === bulkGifteeBoxIssueConfigUrlCode
    )
);

// TODO: 命名の見直し
// https://github.com/giftee/ikedayama/pull/13887/files#r736093206
const getSelectedBulkGifteeBoxIssueConfig = createSelector(
  [getSelectedBulkGifteeBoxIssueConfigFromArray],
  (
    bulkGifteeBoxIssueConfig
  ): Omit<
    LocalBulkGifteeBoxIssueConfig,
    'fixedIssueConfig' | 'issueAmountRequest' | 'claimEstimate'
  > => ({
    deliveryDate: bulkGifteeBoxIssueConfig?.deliveryDate ?? '',
    requestedIssueAmount: bulkGifteeBoxIssueConfig?.requestedIssueAmount ?? 0,
    urlCode: bulkGifteeBoxIssueConfig?.urlCode ?? '',
    initialPoint: bulkGifteeBoxIssueConfig?.initialPoint ?? 0,
    isIssueAmountRequested:
      bulkGifteeBoxIssueConfig?.isIssueAmountRequested ?? false,
    gifteeBoxIssueConfig: {
      exchangeableEndLabel:
        bulkGifteeBoxIssueConfig?.gifteeBoxIssueConfig.exchangeableEndLabel ??
        ''
    }
  })
);

const getFixedIssueConfig = createSelector(
  [getSelectedBulkGifteeBoxIssueConfigFromArray],
  (
    bulkGifteeBoxIssueConfig
  ): LocalBulkGifteeBoxIssueConfig['fixedIssueConfig'] =>
    bulkGifteeBoxIssueConfig?.fixedIssueConfig ?? {
      issueAmount: 0
    }
);

const getIssueAmountRequest = createSelector(
  [getSelectedBulkGifteeBoxIssueConfigFromArray],
  (
    bulkGifteeBoxIssueConfig
  ): LocalBulkGifteeBoxIssueConfig['issueAmountRequest'] =>
    bulkGifteeBoxIssueConfig?.issueAmountRequest ?? {
      issueAmount: 0,
      requestedAt: ''
    }
);

const getClaimEstimateForDisplay = createSelector(
  [getSelectedBulkGifteeBoxIssueConfigFromArray],
  (bulkGifteeBoxIssueConfig): LocalClaimEstimateForDisplay => {
    if (!bulkGifteeBoxIssueConfig?.claimEstimate) {
      return {
        consumptionTaxPrice: 0,
        issueCommissionPrice: 0,
        totalPrice: 0,
        estimatedAmount: 0
      };
    }
    return {
      ...bulkGifteeBoxIssueConfig.claimEstimate,
      estimatedAmount:
        bulkGifteeBoxIssueConfig.initialPoint *
        bulkGifteeBoxIssueConfig.issueAmountRequest.issueAmount
    };
  }
);

export const getSelectedBulkGifteeBoxIssueConfigForDisplay = createSelector(
  [
    getSelectedBulkGifteeBoxIssueConfig,
    getFixedIssueConfig,
    getIssueAmountRequest,
    getClaimEstimateForDisplay
  ],
  (
    bulkGifteeBoxIssueConfig,
    fixedIssueConfig,
    issueAmountRequest,
    claimEstimateForDisplay
  ): LocalBulkGifteeBoxIssueConfigForDisplay => ({
    ...bulkGifteeBoxIssueConfig,
    fixedIssueConfig,
    issueAmountRequest,
    claimEstimate: claimEstimateForDisplay
  })
);

export const getSelectedBulkGifteeBoxIssueConfigExchangeEndLabel =
  createSelector(
    [getSelectedBulkGifteeBoxIssueConfigs],
    (bulkGifteeBoxIssueConfigs): string =>
      bulkGifteeBoxIssueConfigs?.length !== 0
        ? bulkGifteeBoxIssueConfigs[bulkGifteeBoxIssueConfigs.length - 1]
            .gifteeBoxIssueConfig.exchangeableEndLabel
        : ''
  );
