import moment from './moment';
import type { Moment } from 'moment';

const KANJI_LOCALE_DATE_FORMAT = 'YYYY年M月D日';

export const getKanjiLocaleDate = (date: Date): string => {
  const momentDate = moment(date);
  return formatToKanjiDate(momentDate);
};

export const getKanjiLocaleEndDate = (date: Date): string => {
  const momentDate = getSubstractedDateIfNeeded(date);
  return formatToKanjiDate(momentDate);
};

const formatToKanjiDate = (momentDate: Moment) =>
  momentDate.format(KANJI_LOCALE_DATE_FORMAT);

export const getLocaleDate = (date: string): string =>
  new Date(date).toLocaleString('ja-JP');

export const getLocaleDateString = (date: string): string =>
  new Date(date).toLocaleDateString('ja-JP');

export const getLocaleEndDateString = (date: string | Date): string => {
  const momentDate = getSubstractedDateIfNeeded(date);
  return getLocaleDateString(momentDate.toISOString(true));
};

const getSubstractedDateIfNeeded = (date: string | Date): Moment => {
  const momentDate = moment(date);
  return isMidNight(momentDate) ? momentDate.subtract(1, 'd') : momentDate;
};

const isMidNight = (momentDate): boolean =>
  momentDate.hours() === 0 &&
  momentDate.minutes() === 0 &&
  momentDate.seconds() === 0;
