import { ERROR_MESSAGE_FOR_PAGE_VALIDATION } from '@common/middleware/errorMessages';
import {
  updateCampaignName,
  updateCampaignDescription,
  updateHostCompanyRelationToDP,
  updateHostCompanyName,
  updateStartDateOfAd,
  updateEndDateOfAd,
  updateStartDateOfDistribution,
  updateEndDateOfDistribution,
  updateCampaignSolutionSystem,
  updateCampaignSolutionSystemGcp,
  updateCampaignMarketingIndustry,
  updateUrlOfThirdPartyService,
  updateNameOfThirdPartyService,
  updateDescriptionOfThirdPartyService,
  validateProjectDraftFailure,
  focusOutFromCampaignName,
  updateCampaignSolutionSystemGcpSelected
} from '@console/actions/actions';
import { validateCampaignOverviewThunk } from '@console/actions/thunks/validateProjectDraft/validateCampaignOverview';
import CampaignOverview from '@console/components/projectDraft/CampaignOverview';
import {
  getApiBulkSolutionSystemsForDisplay,
  getGcpSolutionSystemsForDisplay
} from '@console/selectors/getSelectedProjectDraftForDisplay';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { goNextStep } from './StepHandlerContainer';
import type {
  AppThunkAction,
  AppThunkDispatch
} from '@console/actions/thunkType';
import type { AppState } from '@console/reducers';
import type { RelationBetweenHostCompanyAndDP } from '@console/reducers/projectJson/hostCompany';
import type moment from 'moment';
import type { NavigateFunction } from 'react-router-dom';

const handleStepNextFromParent =
  (navigate: NavigateFunction): AppThunkAction<Promise<void>> =>
  dispatch =>
    dispatch(validateCampaignOverviewThunk())
      .then(() => {
        const activeStep = queryString.parse(location.search);
        const childStep = Number(activeStep.childStep);
        const parentStep = Number(activeStep.parentStep);
        goNextStep(navigate, parentStep, childStep);
      })
      .catch(() => {
        dispatch(
          validateProjectDraftFailure(ERROR_MESSAGE_FOR_PAGE_VALIDATION)
        );
      });

const mapStateToProps = (state: AppState) => ({
  name: state.selectedProjectDraft.projectJson.name,
  description: state.selectedProjectDraft.projectJson.description,
  hostCompany: state.selectedProjectDraft.projectJson.hostCompany,
  distributionPartnerName: state.distributionPartner.name,
  startDateOfAd:
    state.selectedProjectDraft.projectJson.advertisingPeriod.startDateOfAd,
  endDateOfAd:
    state.selectedProjectDraft.projectJson.advertisingPeriod.endDateOfAd,
  startDateOfDistribution:
    state.selectedProjectDraft.projectJson.giftDistributionPeriod
      .startDateOfDistribution,
  endDateOfDistribution:
    state.selectedProjectDraft.projectJson.giftDistributionPeriod
      .endDateOfDistribution,
  campaignSolutionSystem:
    state.selectedProjectDraft.projectJson.campaignSolutionSystem.urlCode,
  campaignSolutionSystemGcpSelected:
    state.selectedProjectDraft.projectJson.campaignSolutionSystemGcpSelected
      .value,
  apiBulkSolutionSystems: getApiBulkSolutionSystemsForDisplay(state),
  gcpSolutionSystems: getGcpSolutionSystemsForDisplay(state),
  campaignMarketingIndustry:
    state.selectedProjectDraft.projectJson.campaignMarketingIndustry.urlCode,
  marketingIndustries: state.marketingIndustries,
  thirdPartyService: state.selectedProjectDraft.projectJson.thirdPartyService,
  errors: state.errors
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  onChangeName: (event: GenericChangeEvent<string>) =>
    dispatch(updateCampaignName(event.target.value)),
  onBlurName: (event: GenericChangeEvent<string>) =>
    dispatch(focusOutFromCampaignName(event.target.value)),
  onChangeDescription: (event: GenericChangeEvent<string>) =>
    dispatch(updateCampaignDescription(event.target.value)),
  onChangeHostCompanyRelationToDP: (event: GenericChangeEvent<string>) =>
    dispatch(
      updateHostCompanyRelationToDP(
        event.target.value as RelationBetweenHostCompanyAndDP
      )
    ),
  onChangeHostCompanyName: (event: GenericChangeEvent<string>) =>
    dispatch(updateHostCompanyName(event.target.value)),
  onChangeStartDateOfAd: (date: moment.Moment | null) =>
    dispatch(updateStartDateOfAd(date)),
  onChangeEndDateOfAd: (date: moment.Moment | null) =>
    dispatch(updateEndDateOfAd(date)),
  onChangeStartDateOfDistribution: (date: moment.Moment | null) =>
    dispatch(updateStartDateOfDistribution(date)),
  onChangeEndDateOfDistribution: (date: moment.Moment | null) =>
    dispatch(updateEndDateOfDistribution(date)),
  onChangeCampaignSolutionSystem: (event: GenericChangeEvent<string>) =>
    dispatch(updateCampaignSolutionSystem(event.target.value)),
  onChangeCampaignSolutionSystemGcp: value =>
    dispatch(updateCampaignSolutionSystemGcp(value)),
  onChangeCampaignSolutionSystemGcpSelected: (
    event: GenericChangeEvent<string>
  ) => {
    dispatch(updateCampaignSolutionSystemGcpSelected(event.target.value));
  },
  onChangeMarketingIndustry: (event: GenericChangeEvent<string>) => {
    dispatch(updateCampaignMarketingIndustry(event.target.value));
  },
  onChangeUrlOfThirdPartyService: (event: GenericChangeEvent<string>) =>
    dispatch(updateUrlOfThirdPartyService(event.target.value)),
  onChangeNameOfThirdPartyService: (event: GenericChangeEvent<string>) =>
    dispatch(updateNameOfThirdPartyService(event.target.value)),
  onChangeDescriptionOfThirdPartyService: (event: GenericChangeEvent<string>) =>
    dispatch(updateDescriptionOfThirdPartyService(event.target.value)),
  handleStepNextFromParent: (navigate: NavigateFunction) =>
    dispatch(handleStepNextFromParent(navigate))
});

export type CampaignOverviewPropsMappedFromState = ReturnType<
  typeof mapStateToProps
>;
export type CampaignOverviewPropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(mapStateToProps, mapDispatchToProps)(CampaignOverview);
