import { getGifteeBoxTemplates } from '@common/api/graphql/getGifteeBoxTemplatesGql';
import {
  getGifteeBoxTemplatesStart,
  getGifteeBoxTemplatesSuccess,
  getGifteeBoxTemplatesFailure
} from '../actions';
import handleApiErrorIfNeeded from './handleApiErrorIfNeeded';
import type { AppThunkAction } from '../thunkType';
import type { ApiError } from '@common/api/graphql/apolloClient';
import type { GetGifteeBoxTemplatesResponse } from '@common/api/graphql/getGifteeBoxTemplatesGql';

export const getGifteeBoxTemplatesSuccessThunk =
  (response: GetGifteeBoxTemplatesResponse): AppThunkAction<Promise<void>> =>
  dispatch => {
    const { gifteeBoxTemplates } = response.data!;
    dispatch(getGifteeBoxTemplatesSuccess(gifteeBoxTemplates));
    return Promise.resolve();
  };

export const getGifteeBoxTemplatesFailureThunk =
  (error: ApiError): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(getGifteeBoxTemplatesFailure());
    return dispatch(handleApiErrorIfNeeded(error));
  };

export const getGifteeBoxTemplatesThunk =
  (): AppThunkAction<Promise<void>> => dispatch => {
    dispatch(getGifteeBoxTemplatesStart());

    return getGifteeBoxTemplates()
      .then((response: GetGifteeBoxTemplatesResponse) =>
        dispatch(getGifteeBoxTemplatesSuccessThunk(response))
      )
      .catch(error => dispatch(getGifteeBoxTemplatesFailureThunk(error)));
  };
