import { gql } from '@apollo/client';

import { internalApiAppClient } from './apolloClient';
import { FRAGMENT_GIFTEE_BOX_CONFIGS } from './fragments/project';
import { operationNames } from './index';
import type { QueryGifteeBoxConfig } from './getProjectsGql';
import type { FetchResult } from '@apollo/client';

const requestGifteeBoxIssueAmountGql = gql`
  ${FRAGMENT_GIFTEE_BOX_CONFIGS}
  mutation ${operationNames.REQUEST_GIFTEE_BOX_ISSUE_AMOUNT}(
    $issueAmount: Int!
    $bulkGifteeBoxIssueConfigUrlCode: String!
  ) {
    requestGifteeBoxIssueAmount(
      input: {
        issueAmount: $issueAmount
        bulkGifteeBoxIssueConfigUrlCode: $bulkGifteeBoxIssueConfigUrlCode
      }
    ) {
      gifteeBoxConfig {
        ...GifteeBoxConfigsFields
      }
    }
  }
`;

export type RequestGifteeBoxIssueAmountResponse = FetchResult<{
  requestGiftIssueAmount: {
    gifteeBoxConfig: QueryGifteeBoxConfig;
  };
}>;

export const requestGifteeBoxIssueAmount = (
  issueAmount: number,
  bulkGifteeBoxIssueConfigUrlCode: UrlCode
): Promise<RequestGifteeBoxIssueAmountResponse> =>
  internalApiAppClient.mutate({
    mutation: requestGifteeBoxIssueAmountGql,
    variables: {
      issueAmount,
      bulkGifteeBoxIssueConfigUrlCode
    }
  });
