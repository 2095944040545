import { Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { BeatLoader } from 'react-spinners';
import { ProjectDraftSyncStatuses } from '../../reducers/projectDraftSyncStatus';
import type {
  SyncStatusPropsMappedFromState,
  SyncStatusPropsMappedFromDispatch
} from '../../containers/projectDraft/SyncStatusContainer';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    syncStatus: {
      fontSize: 14,
      color: 'gray',
      height: 24,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: `${theme.spacing()} ${theme.spacing(4)} 0 auto`
    }
  });

const renderSyncStatus = (
  projectDraftSyncStatus,
  projectDraftSyncedDate
): JSX.Element | string => {
  switch (projectDraftSyncStatus) {
    case ProjectDraftSyncStatuses.INITIAL:
      return '保存済み';
    case ProjectDraftSyncStatuses.SYNCED:
      return `${projectDraftSyncedDate.getHours()}時${projectDraftSyncedDate.getMinutes()}分に保存済み`;
    default:
      return <BeatLoader size={10} color={'#0C6993'} />;
  }
};

type SyncStatusProps = SyncStatusPropsMappedFromState &
  SyncStatusPropsMappedFromDispatch &
  WithStyles<typeof styles>;

const SyncStatus: React.FC<SyncStatusProps> = ({
  projectDraftSyncStatus,
  projectDraftSyncedDate,
  classes
}) => (
  <Typography className={classes.syncStatus} variant="caption">
    {renderSyncStatus(projectDraftSyncStatus, projectDraftSyncedDate)}
  </Typography>
);

export default withStyles(styles)(SyncStatus);
