import { gql } from '@apollo/client';
import { internalApiAppClient } from '@common/api/graphql/apolloClient';
import {
  FRAGMENT_BULK_GIFTEE_BOX_DELIVERABLE,
  FRAGMENT_BULK_GIFT_CARD_DELIVERABLE
} from '@common/api/graphql/fragments/project';
import { operationNames } from './index';
import type { FetchResult } from '@apollo/client';
import type { QueryBulkDeliverable } from '@common/api/graphql/getProjectsGql';

const getProjectBulkDeliverablesGql = gql`
  ${FRAGMENT_BULK_GIFTEE_BOX_DELIVERABLE}
  ${FRAGMENT_BULK_GIFT_CARD_DELIVERABLE}
  query ${operationNames.GET_PROJECT_BULK_DELIVERABLES}(
    $urlCode: String!
    $first: Int!
    $after: String
  ) {
    project(urlCode: $urlCode) {
      urlCode
      confidentialInformation {
        __typename
        ... on BulkConfidentialInformation {
          bulkDeliverableConnection(first: $first, after: $after) {
            pageInfo {
              startCursor
              endCursor
              hasNextPage
              hasPreviousPage
            }
            edges {
              cursor
              node {
                __typename
                ... on BulkGiftCardDeliverable {
                  ...BulkGiftCardDeliverableFields
                }
                ... on BulkGifteeBoxDeliverable {
                  ...BulkGifteeBoxDeliverableFields
                }
              }
            }
          }
        }
      }
    }
  }
`;

type GetProjectBulkDeliverablesConnection = {
  pageInfo: IkedayamaConnection<QueryBulkDeliverable>['pageInfo'];
  edges: IkedayamaConnection<QueryBulkDeliverable>['edges'];
};

export type GetProjectBulkDeliverablesResponse = FetchResult<{
  project: {
    confidentialInformation: {
      bulkDeliverableConnection?: GetProjectBulkDeliverablesConnection;
    };
  };
}>;

export type GetProjectBulkDeliverablesQueryVariables = {
  urlCode: string;
  searchInfo?: {
    first?: number;
    after?: string;
  };
};

export const getProjectBulkDeliverables = (
  variables: GetProjectBulkDeliverablesQueryVariables
): Promise<GetProjectBulkDeliverablesResponse> =>
  internalApiAppClient.query({
    query: getProjectBulkDeliverablesGql,
    variables: {
      urlCode: variables.urlCode,
      first: variables.searchInfo?.first || 10,
      after: variables.searchInfo?.after || null
    }
  });
