import { createSelector } from 'reselect';
import type { AppState } from '@console/reducers';
import type { LocalProject } from '@console/reducers/projects';

const getProjectDetailThirdPartyService = (state: AppState) =>
  state.projectDetail.thirdPartyService;

export const getThirdPartyService = createSelector(
  [getProjectDetailThirdPartyService],
  (thirdPartyService): LocalProject['thirdPartyService'] => ({
    description: thirdPartyService.description ?? '',
    name: thirdPartyService.name ?? '',
    url: thirdPartyService.url ?? ''
  })
);
