import {
  updateGiftConfigIssueAmount,
  updateGiftConfigDeliveryDate,
  updateGiftConfigGifteeBoxInitialPoint,
  deselectGifteeBoxTemplate,
  selectGifteeBoxTemplateForDraftProject
} from '@console/actions/actions';
import { getGifteeBoxTemplatesThunk } from '@console/actions/thunks/getGifteeBoxTemplates';
import BulkTable from '@console/components/common/deliveryConfig/gifteeBoxDeliveryConfig/BulkTable';
import { getSelectedProjectDraftSelectedGiftConfig } from '@console/selectors/getSelectedProjectDraftForDisplay';
import {
  getGifteeBoxInitialPointCandidates,
  getSelectedGifteeBoxTemplate
} from '@console/selectors/projectJson/giftConfigs';
import { connect } from 'react-redux';
import type moment from '@common/lib/moment';
import type {
  AppThunkAction,
  AppThunkDispatch
} from '@console/actions/thunkType';
import type { AppState } from '@console/reducers';
import type {
  GiftConfig,
  SelectedGifteeBoxTemplate
} from '@console/reducers/projectJson/giftConfigs';

const onChangeIssueAmount =
  (issueAmount: GiftConfig['issueAmount']): AppThunkAction<void> =>
  (dispatch, getState) => {
    const { selectedGiftConfigIndex } =
      getState().selectedProjectDraft.projectJson;
    dispatch(updateGiftConfigIssueAmount(issueAmount, selectedGiftConfigIndex));
  };

const onChangeDeliveryDate =
  (date: moment.Moment | null): AppThunkAction<void> =>
  (dispatch, getState) => {
    const { selectedGiftConfigIndex } =
      getState().selectedProjectDraft.projectJson;
    dispatch(updateGiftConfigDeliveryDate(date, selectedGiftConfigIndex));
  };

const onChangeGifteeBoxInitialPoint =
  (gifteeBoxInitialPoint: number): AppThunkAction<void> =>
  dispatch =>
    dispatch(
      onInputChangeGifteeBoxInitialPoint(gifteeBoxInitialPoint.toString())
    );

const onInputChangeGifteeBoxInitialPoint =
  (gifteeBoxInitialPoint: string): AppThunkAction<void> =>
  (dispatch, getState) => {
    const { selectedGiftConfigIndex } =
      getState().selectedProjectDraft.projectJson;
    dispatch(
      updateGiftConfigGifteeBoxInitialPoint(
        gifteeBoxInitialPoint,
        selectedGiftConfigIndex
      )
    );
  };

const onMount = (): AppThunkAction<void> => dispatch => {
  dispatch(getGifteeBoxTemplatesThunk()).catch(() => {});
};

const mapStateToProps = (state: AppState) => ({
  giftConfig: getSelectedProjectDraftSelectedGiftConfig(state),
  giftConfigErrors:
    state.errors.projectDraftGiftConfigs[
      state.selectedProjectDraft.projectJson.selectedGiftConfigIndex
    ],
  initialPointCandidates: getGifteeBoxInitialPointCandidates(state),
  selectedGifteeBoxTemplate: getSelectedGifteeBoxTemplate(state)
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  onChangeIssueAmount: (event: GenericChangeEvent<string>) =>
    dispatch(onChangeIssueAmount(event.target.value)),
  onChangeDeliveryDate: (date: moment.Moment | null) =>
    dispatch(onChangeDeliveryDate(date)),
  onChangeGifteeBoxInitialPoint: (gifteeBoxInitialPoint: number) =>
    dispatch(onChangeGifteeBoxInitialPoint(gifteeBoxInitialPoint)),
  onInputChangeGifteeBoxInitialPoint: (gifteeBoxInitialPoint: string) =>
    dispatch(onInputChangeGifteeBoxInitialPoint(gifteeBoxInitialPoint)),
  selectGifteeBoxTemplate: (
    gifteeBoxTemplate: NonNullable<SelectedGifteeBoxTemplate>
  ) => dispatch(selectGifteeBoxTemplateForDraftProject(gifteeBoxTemplate)),
  deselectGifteeBoxTemplate: () => dispatch(deselectGifteeBoxTemplate()),
  onMount: () => dispatch(onMount())
});

export type BulkTablePropsMappedFromState = ReturnType<typeof mapStateToProps>;
export type BulkTablePropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(mapStateToProps, mapDispatchToProps)(BulkTable);
