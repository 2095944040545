import { Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { useEffect } from 'react';
import APIIpRestrictionContent from './apiIpRestriction/Content';
import type {
  APIIpRestrictionPropsMappedFromState,
  APIIpRestrictionPropsMappedFromDispatch
} from '@console/containers/project/APIIpRestrictionContainer';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';
import type { ReactElement } from 'react';

const styles = (theme: Theme): StyleRules => ({
  sections: {
    paddingBottom: theme.spacing(5)
  },
  section: {
    paddingTop: theme.spacing(4)
  }
});

type APIIpRestrictionProps = APIIpRestrictionPropsMappedFromState &
  APIIpRestrictionPropsMappedFromDispatch &
  WithStyles<typeof styles>;

const APIIpRestriction: React.FC<APIIpRestrictionProps> = ({
  classes,
  apiConfidentialInformation,
  setApiAllowedIps
}) => {
  const renderBody = (): ReactElement | null => {
    if (!!apiConfidentialInformation) {
      return renderContent();
    }
    return null;
  };

  const renderContent = (): ReactElement => <APIIpRestrictionContent />;

  // const renderEmpty = (): ReactElement => {};

  useEffect(() => {
    if (!!apiConfidentialInformation) {
      setApiAllowedIps(apiConfidentialInformation.apiAllowedIps);
    }
  }, []);

  return (
    <div className={classes.sections}>
      <section className={classes.section}>
        <Typography variant="h6" color="textPrimary">
          IPアドレス制限
        </Typography>
      </section>
      <section className={classes.section}>{renderBody()}</section>
    </div>
  );
};

export default withStyles(styles)(APIIpRestriction);
