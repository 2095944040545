import {
  isGiftCardFixedTicketConfig,
  isGiftCardChoosableTicketConfig
} from '@console/common/project/giftCardTicketConfig';
import { TableCell, TableRow, Chip } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Fragment } from 'react';
import TicketTypeChip, { PROJECT_TICKET_TYPE } from '../common/TicketTypeChip';
import BrandCheckStatusChip from './BrandCheckStatusChip';
import ContentCells from './ContentCells';
import type { QueryGiftCardTicketConfig } from '@common/api/graphql/getProjectsGql';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => ({
  tableCell: {
    paddingRight: theme.spacing(4),
    verticalAlign: 'middle'
  },
  contentCell: {
    paddingRight: theme.spacing(2),
    width: theme.spacing(52)
  },
  contentImage: {
    width: theme.spacing(9),
    verticalAlign: 'middle'
  },
  contentImageContainer: {
    marginRight: theme.spacing(2)
  }
});

type GiftCardTicketConfigRowOwnProps = {
  giftCardTicketConfig: QueryGiftCardTicketConfig;
};

type GiftCardTicketConfigRowProps = GiftCardTicketConfigRowOwnProps &
  WithStyles<typeof styles>;

const GiftCardTicketConfigRow: React.FC<GiftCardTicketConfigRowProps> = ({
  classes,
  giftCardTicketConfig
}) => {
  if (isGiftCardFixedTicketConfig(giftCardTicketConfig)) {
    return (
      <TableRow data-cy="giftCardFixedTicketConfigRow">
        <TableCell
          className={classes.tableCell}
          align="left"
          data-cy="giftCardTicketConfigTicketType"
        >
          <TicketTypeChip ticketType={PROJECT_TICKET_TYPE.FIXED} />
        </TableCell>
        <ContentCells
          brandName={giftCardTicketConfig.fixedContentOption.content.brand.name}
          contentName={
            giftCardTicketConfig.fixedContentOption.content.internalName
          }
          contentImageUrl={
            giftCardTicketConfig.fixedContentOption.content.imageUrl
          }
        />
        <TableCell className={classes.tableCell} align="right">
          <BrandCheckStatusChip
            creativeCheckApproved={
              giftCardTicketConfig.fixedContentOption.creativeCheckApproved
            }
          />
        </TableCell>
      </TableRow>
    );
  }

  if (isGiftCardChoosableTicketConfig(giftCardTicketConfig)) {
    return (
      <Fragment>
        {giftCardTicketConfig.choosableContentOption.choices
          .sort((a, b) => a.order - b.order)
          .map((choice, choiceIndex) => (
            <TableRow
              key={`choice-${choiceIndex}`}
              data-cy={`giftCardChoosableTicketConfigRow-${choice.content.urlCode}`}
            >
              {choiceIndex === 0 ? (
                <TableCell
                  className={classes.tableCell}
                  align="left"
                  rowSpan={
                    giftCardTicketConfig.choosableContentOption.choices.length
                  }
                  data-cy="giftCardTicketConfigTicketType"
                >
                  <Chip label="選べる" />
                </TableCell>
              ) : null}
              <ContentCells
                brandName={choice.content.brand.name}
                contentName={choice.content.internalName}
                contentImageUrl={choice.content.imageUrl}
              />
              <TableCell className={classes.tableCell} align="right">
                <BrandCheckStatusChip
                  creativeCheckApproved={choice.creativeCheckApproved}
                />
              </TableCell>
            </TableRow>
          ))}
      </Fragment>
    );
  }

  return null;
};

export default withStyles(styles)(GiftCardTicketConfigRow);
