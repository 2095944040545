import { updateGiftConfigTicketType } from '@console/actions/actions';
import GiftTicketTypeConfig from '@console/components/common/GiftTicketTypeConfig';
import {
  isBulkSolutionSystemSelected,
  isGcpSolutionSystemSelected
} from '@console/selectors/getSelectedProjectDraftForDisplay';
import {
  getBulkSolutionSystem,
  getApiSolutionSystem
} from '@console/selectors/getSelectedSolutionSystem';
import { connect } from 'react-redux';
import type {
  AppThunkAction,
  AppThunkDispatch
} from '@console/actions/thunkType';
import type { AppState } from '@console/reducers';
import type { TicketType } from '@console/reducers/projectJson/giftConfigs';

const onChangeTicketType =
  (ticketType: TicketType): AppThunkAction<void> =>
  (dispatch, getState) => {
    const { selectedGiftConfigIndex } =
      getState().selectedProjectDraft.projectJson;
    dispatch(updateGiftConfigTicketType(ticketType, selectedGiftConfigIndex));
  };

const mapStateToProps = (state: AppState) => ({
  ticketType:
    state.selectedProjectDraft.projectJson.giftConfigs[
      state.selectedProjectDraft.projectJson.selectedGiftConfigIndex
    ].ticketType,
  bulkSolutionSystem: getBulkSolutionSystem(state),
  apiSolutionSystem: getApiSolutionSystem(state)
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  onChangeTicketType: (event: GenericChangeEvent<string>) =>
    dispatch(onChangeTicketType(event.target.value as TicketType))
});

export type GiftTicketTypeConfigPropsMappedFromState = ReturnType<
  typeof mapStateToProps
>;
export type GiftTicketTypeConfigPropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GiftTicketTypeConfig);
