import { messageThreadPostedMessageObserver } from '../../../../common/api/graphql/messageThreadPostedMessageObserverGql';
import {
  receiveMessageThreadPostedMessageSuccess,
  receiveMessageThreadPostedMessageFailure
} from '../actions';
import type { ApiError } from '../../../../common/api/graphql/apolloClient';
import type { MessageThreadUrlCode } from '../../../../common/api/graphql/getMessageThread';
import type { MessageThreadPostedMessageSubscriptionResponse } from '../../../../common/api/graphql/messageThreadPostedMessageObserverGql';
import type { AppThunkAction } from '../thunkType';

const messageThreadPostedMessageObserverNextThunk =
  (
    response: MessageThreadPostedMessageSubscriptionResponse
  ): AppThunkAction<void> =>
  dispatch => {
    dispatch(
      receiveMessageThreadPostedMessageSuccess(
        response.data!.messageThreadMessageWasPosted.message
      )
    );
  };

const messageThreadPostedMessageObserverErrorThunk =
  (error: ApiError): AppThunkAction<void> =>
  dispatch => {
    dispatch(receiveMessageThreadPostedMessageFailure());
    // cf.https://github.com/giftee/ikedayama/issues/4780
    // return dispatch(handleApiErrorIfNeeded(error));
  };

export const subscribeMessageThreadPostedMessageThunk =
  (messageThreadUrlCode: MessageThreadUrlCode): AppThunkAction<void> =>
  dispatch => {
    messageThreadPostedMessageObserver(messageThreadUrlCode).subscribe({
      next(data) {
        dispatch(messageThreadPostedMessageObserverNextThunk(data));
      },
      error(error) {
        dispatch(messageThreadPostedMessageObserverErrorThunk(error));
      }
    });
  };
