import { AppActionTypes } from '@console/actions/actionType';
import { combineReducers } from 'redux';
import type { AppAction } from '@console/actions/actionType';
import type { LocalProject } from '@console/reducers/projects';
import type { Reducer } from 'react';

const beginAt: Reducer<
  NonNullable<LocalProject['advertisingPeriod']>['beginAt'] | null,
  AppAction
> = (state = null, action) => {
  switch (action.type) {
    case AppActionTypes.GET_PROJECT_DETAIL_SUCCESS:
      return action.project.advertisingPeriod
        ? action.project.advertisingPeriod.beginAt
        : null;
    default:
      return state;
  }
};

const endAt: Reducer<
  NonNullable<LocalProject['advertisingPeriod']>['endAt'] | null,
  AppAction
> = (state = null, action) => {
  switch (action.type) {
    case AppActionTypes.GET_PROJECT_DETAIL_SUCCESS:
      return action.project.advertisingPeriod
        ? action.project.advertisingPeriod.endAt
        : null;
    default:
      return state;
  }
};

export const advertisingPeriod = combineReducers({
  beginAt,
  endAt
});
