import { aggregateGifteeBoxSelectedContentPercentages } from '@common/api/graphql/aggregateGifteeBoxSelectedContentPercentageGql';
import {
  aggregateGifteeBoxSelectedContentPercentageStart,
  aggregateGifteeBoxSelectedContentPercentageSuccess,
  aggregateGifteeBoxSelectedContentPercentageFailure
} from '../actions';
import handleApiErrorIfNeeded from './handleApiErrorIfNeeded';
import type { AppThunkAction } from '../thunkType';
import type { AggregateGifteeBoxSelectedContentPercentagesResponse } from '@common/api/graphql/aggregateGifteeBoxSelectedContentPercentageGql';
import type { ApiError } from '@common/api/graphql/apolloClient';

export const aggregateGifteeBoxSelectedContentPercentageSuccessThunk =
  (
    response: AggregateGifteeBoxSelectedContentPercentagesResponse
  ): AppThunkAction<Promise<void>> =>
  dispatch => {
    const results =
      response.data!.aggregateGifteeBoxSelectedContentPercentage.results;
    dispatch(aggregateGifteeBoxSelectedContentPercentageSuccess(results));
    return Promise.resolve();
  };

export const aggregateGifteeBoxSelectedContentPercentageFailureThunk =
  (error: ApiError): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(aggregateGifteeBoxSelectedContentPercentageFailure());
    return dispatch(handleApiErrorIfNeeded(error));
  };

export const aggregateGifteeBoxSelectedContentPercentageThunk =
  (gifteeBoxConfigUrlCode: UrlCode): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(aggregateGifteeBoxSelectedContentPercentageStart());
    return aggregateGifteeBoxSelectedContentPercentages({
      gifteeBoxConfigUrlCode
    })
      .then((response: AggregateGifteeBoxSelectedContentPercentagesResponse) =>
        dispatch(
          aggregateGifteeBoxSelectedContentPercentageSuccessThunk(response)
        )
      )
      .catch(error =>
        dispatch(aggregateGifteeBoxSelectedContentPercentageFailureThunk(error))
      );
  };
