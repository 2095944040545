import { Help } from '@mui/icons-material';
import {
  Grid,
  Typography,
  RadioGroup,
  Radio,
  FormControlLabel,
  Link
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { useNavigate } from 'react-router-dom';
import StepHandlerContainer from '../../containers/projectDraft/StepHandlerContainer';
import type {
  MessageCardUsingPropsMappedFromState,
  MessageCardUsingPropsMappedFromDispatch
} from '../../containers/projectDraft/MessageCardUsingContainer';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    title: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1)
    },
    titleItem: {
      marginRight: theme.spacing(4)
    },
    button: {
      margin: `${theme.spacing(3)} 0`,
      padding: theme.spacing(1)
    },
    helpLink: {
      display: 'flex',
      alignItems: 'center'
    }
  });

type MessageCardUsingProps = MessageCardUsingPropsMappedFromState &
  MessageCardUsingPropsMappedFromDispatch &
  WithStyles<typeof styles>;

const MessageCardUsing: React.FC<MessageCardUsingProps> = ({
  messageCardUsing,
  onChangeMessageCardUsing,
  handleStepNextFromParent,
  classes
}) => {
  const navigate = useNavigate();

  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="flex-start"
      className={classes.root}
    >
      <Grid container item xs={12} className={classes.title}>
        <Grid item className={classes.titleItem}>
          <Typography variant="body1" color="textPrimary">
            メッセージカードの有無を選択してください
          </Typography>
        </Grid>
        <Grid item>
          <Link
            href="https://docs.giftee.biz/business/downloads/gift_template.pdf"
            variant="body1"
            target="_blank"
            rel="noopener"
            className={classes.helpLink}
            data-cy="messageCardHelpLink"
            underline="hover"
          >
            <Help />
            メッセージカードとは？
          </Link>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <RadioGroup
          aria-label="messageCardUsing"
          name="messageCardUsing"
          className={classes.group}
          onChange={onChangeMessageCardUsing}
          value={String(messageCardUsing)}
        >
          <FormControlLabel
            value="true"
            control={<Radio color="primary" />}
            label="有り"
            data-cy="withMessageCard"
          />
          <FormControlLabel
            value="false"
            control={<Radio color="primary" />}
            label="無し"
            data-cy="noMessageCard"
          />
        </RadioGroup>
      </Grid>
      <StepHandlerContainer
        handleStepNextFromParent={() => handleStepNextFromParent(navigate)}
      />
    </Grid>
  );
};

export default withStyles(styles)(MessageCardUsing);
