import { isFeatureEnable, FeatureTypes } from '@common/lib/featureToggle';
import { getLocaleEndDateString } from '@common/lib/getLocaleDate';
import { Typography, Card, CardActionArea, Grid, Chip } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import { useState, useEffect } from 'react';
import getReadableDate from '../lib/getReadableDate';
import GiftWalletButton from './GiftWalletButton';
import UnchoosableModal from './choices/UnchoosableModal';
import type { ChoosingOption, Choice } from '../api/graphql/getGiftCardGql';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => {
  const CARD_WIDTH = 135;
  const CARD_HEIGHT_WITH_CHOICE_CHOOSABLE_PERIOD = 220;
  const CARD_HEIGHT_WITHOUT_CHOICE_CHOOSABLE_PERIOD = 195;
  const CONTENT_HEIGHT = CARD_WIDTH;

  return {
    root: {
      maxWidth: 600
    },
    title: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(),
      paddingLeft: theme.spacing(2)
    },
    titleMain: {
      fontWeight: 'bold'
    },
    titleSub: {
      marginTop: theme.spacing(),
      marginBottom: theme.spacing()
    },
    chooseCardsContainer: {
      width: '100%',
      display: 'grid',
      justifyContent: 'center',
      rowGap: theme.spacing(2),
      columnGap: theme.spacing(1.5),
      gridTemplateColumns: 'repeat(auto-fill, 140px)'
    },
    card: {
      borderRadius: theme.spacing(),
      boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.1)',
      position: 'relative',
      width: CARD_WIDTH
    },
    cardWithChoiceChoosablePeriod: {
      height: CARD_HEIGHT_WITH_CHOICE_CHOOSABLE_PERIOD
    },
    cardWithoutChoiceChoosablePeriod: {
      height: CARD_HEIGHT_WITHOUT_CHOICE_CHOOSABLE_PERIOD
    },
    contentActionArea: {
      height: '100%',
      position: 'absolute'
    },
    contentImage: {
      height: CONTENT_HEIGHT,
      width: CARD_WIDTH,
      objectFit: 'contain',
      position: 'absolute',
      top: 0,
      left: 0
    },
    contentName: {
      position: 'absolute',
      top: 143, // CONTENT_HEIGHT + theme.spacing(),
      left: theme.spacing(),
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      width: 127 // CARD_WIDTH - theme.spacing()
    },
    contentAvailablePeriod: {
      position: 'absolute',
      bottom: theme.spacing(),
      left: theme.spacing(),
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      width: 127 // CARD_WIDTH - theme.spacing()
    },
    mask: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.1)'
    },
    chip: {
      margin: theme.spacing(),
      background: theme.palette.info.dark,
      color: 'white'
    },
    saveGiftCardButton: {
      width: 200,
      height: 50,
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      borderRadius: 50,
      margin: `${theme.spacing()} 0`
    },
    giftWalletButton: {
      margin: `${theme.spacing(2)} 0 ${theme.spacing()} 0`
    }
  };
};

interface ChoicesOwnProps {
  choosingOption: NonNullable<ChoosingOption>;
  selectChoice: (choice: Choice) => void;
  giftWalletStorable: boolean;
  handleGiftWalletButtonClick?: () => void;
}

type ChoicesProps = ChoicesOwnProps & WithStyles<typeof styles>;

const Choices: React.FC<ChoicesProps> = ({
  classes,
  choosingOption,
  selectChoice,
  giftWalletStorable,
  handleGiftWalletButtonClick
}) => {
  const isBeforeChoosableBeginAt = (): boolean => {
    const currentTime = new Date();
    return currentTime < new Date(choosingOption.choosableBeginAt);
  };

  const isAfterChoosableEndAt = (): boolean => {
    const currentTime = new Date();
    return currentTime > new Date(choosingOption.choosableEndAt);
  };

  const [
    showGiftCardTicketChoosableEndAt,
    setShowGiftCardTicketChoosableEndAt
  ] = useState(true);

  useEffect(() => {
    if (choiceChoosablePeriodOptionExist()) {
      setShowGiftCardTicketChoosableEndAt(false);
    }
  }, [choosingOption]);

  const choiceChoosablePeriodOptionExist = (): boolean =>
    choosingOption.choices.some(
      choice => choice.choosablePeriodOption !== null
    );

  return (
    <Grid container className={classes.root}>
      <UnchoosableModal
        open={isBeforeChoosableBeginAt()}
        choosablePeriodText={`${getReadableDate(
          choosingOption.choosableBeginAt
        )}から`}
      />
      <UnchoosableModal
        open={isAfterChoosableEndAt()}
        choosablePeriodText={`${getLocaleEndDateString(
          choosingOption.choosableEndAt
        )}まで`}
      />
      <Grid
        item
        container
        justifyContent="flex-start"
        className={classes.title}
      >
        <Grid item xs={12}>
          <Typography variant="h6" className={classes.titleMain}>
            以下から１つ選んでください。
          </Typography>
        </Grid>
        {showGiftCardTicketChoosableEndAt ? (
          <Grid item xs={12}>
            <Typography
              variant="body2"
              display="block"
              className={classes.titleSub}
              data-cy="giftCardTicketChoosableEndAt"
            >
              （{getLocaleEndDateString(choosingOption.choosableEndAt)}
              まで選択可能です。）
            </Typography>
          </Grid>
        ) : null}
      </Grid>
      <Grid
        item
        container
        className={classes.chooseCardsContainer}
        data-cy="giftCardTicketChoices"
      >
        {choosingOption.choices
          .sort((a, b) => a.order - b.order)
          .map((choice, choiceIndex) => (
            <Card
              className={clsx(classes.card, {
                [classes.cardWithoutChoiceChoosablePeriod]:
                  showGiftCardTicketChoosableEndAt,
                [classes.cardWithChoiceChoosablePeriod]:
                  !showGiftCardTicketChoosableEndAt
              })}
              key={`choice-${choiceIndex}`}
              data-cy={`choiceCard-${choiceIndex}`}
            >
              <CardActionArea
                className={classes.contentActionArea}
                data-cy={`choiceButton-${choiceIndex}`}
                onClick={() => selectChoice(choice)}
                disabled={!choice.choosable}
              >
                <img
                  className={classes.contentImage}
                  src={choice.content.imageUrl}
                  data-cy={`choiceContentImage-${choiceIndex}`}
                />
                <Typography
                  variant="body2"
                  color="textPrimary"
                  display="block"
                  className={classes.contentName}
                  data-cy={`choiceContentName-${choiceIndex}`}
                >
                  {choice.content.name}
                </Typography>
                {showGiftCardTicketChoosableEndAt ? null : (
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    display="block"
                    className={classes.contentAvailablePeriod}
                    data-cy={`choiceChoosablePeriod-${choiceIndex}`}
                  >
                    選択期限：
                    {!!choice.choosablePeriodOption
                      ? getLocaleEndDateString(
                          choice.choosablePeriodOption.choosableEndAt
                        )
                      : getLocaleEndDateString(choosingOption.choosableEndAt)}
                  </Typography>
                )}
              </CardActionArea>
              {!choice.choosable ? (
                <div className={classes.mask}>
                  <Chip label="選択できません" className={classes.chip} />
                </div>
              ) : null}
            </Card>
          ))}
      </Grid>
      {giftWalletStorable ? (
        <Grid
          item
          container
          justifyContent="center"
          className={classes.giftWalletButton}
        >
          <GiftWalletButton
            handleGiftWalletButtonClick={handleGiftWalletButtonClick}
            text="選択前にギフトを保存"
          />
        </Grid>
      ) : null}
    </Grid>
  );
};

export default withStyles(styles)(Choices);
