import {
  deselectGifteeBoxTemplate,
  selectGifteeBoxTemplate
} from '@console/actions/actions';
import DisplaySelectedGifteeBoxConfigForAPIProject from '@console/components/project/gifteeBoxConfigs/exchangeReport/DisplaySelectedGifteeBoxConfigForAPIProject';
import { getSelectedApiGifteeBoxIssueConfigExchangeableEndLabel } from '@console/selectors/projectDetail/gifteeBoxConfigs/apiGifteeBoxConfigs';
import { getGifteeBoxConfigForSelectionReport } from '@console/selectors/projectDetail/gifteeBoxSelectionReports';
import { connect } from 'react-redux';
import type { AppThunkDispatch } from '@console/actions/thunkType';
import type { AppState } from '@console/reducers';
import type { LocalGifteeBoxConfig } from '@console/reducers/projects';

const mapStateToProps = (state: AppState) => ({
  gifteeBoxConfigForSelectionReport:
    getGifteeBoxConfigForSelectionReport(state),
  exchangeableEndLabel:
    getSelectedApiGifteeBoxIssueConfigExchangeableEndLabel(state)
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  selectGifteeBoxTemplate: (
    gifteeBoxTemplate: LocalGifteeBoxConfig['gifteeBoxTemplate']
  ) => dispatch(selectGifteeBoxTemplate(gifteeBoxTemplate)),
  deselectGifteeBoxTemplate: () => dispatch(deselectGifteeBoxTemplate())
});

export type DisplaySelectedGifteeBoxConfigForAPIProjectMappedFromState =
  ReturnType<typeof mapStateToProps>;
export type DisplaySelectedGifteeBoxConfigForAPIProjectMappedFromDispatch =
  ReturnType<typeof mapDispatchToProps>;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DisplaySelectedGifteeBoxConfigForAPIProject);
