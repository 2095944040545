import { gql } from '@apollo/client';

import { internalApiAppClient } from './apolloClient';
import { FRAGMENT_GENERAL_ANNOUNCEMENT } from './fragments/generalAnnouncement';
import { operationNames } from './operationNames';
import type { FetchResult } from '@apollo/client';

const getGeneralAnnouncementGql = gql`
${FRAGMENT_GENERAL_ANNOUNCEMENT}
  query ${operationNames.GET_GENERAL_ANNOUNCEMENTS}{
    generalAnnouncements {
      ...GeneralAnnouncementFields
    }
  }
`;

export type GeneralAnnouncement = {
  urlCode: string;
  title: string;
  url: string | null;
};

export type GeneralAnnouncements = GeneralAnnouncement[];

export type GetGeneralAnnouncementsResponse = FetchResult<{
  generalAnnouncements: GeneralAnnouncements;
}>;

export const getGeneralAnnouncements =
  (): Promise<GetGeneralAnnouncementsResponse> =>
    internalApiAppClient.query({
      query: getGeneralAnnouncementGql
    });
