import { gql } from '@apollo/client';

import { internalApiAppClient } from './apolloClient';
import { operationNames } from './index';
import type { FetchResult } from '@apollo/client';

const registerProjectDraftGql = gql`
  mutation ${operationNames.REGISTER_PROJECT_DRAFT}($schemaVersion: Int!, $projectJson: JSON!) {
    registerProjectDraft(
      input: { schemaVersion: $schemaVersion, projectJson: $projectJson }
    ) {
      projectDraft {
        urlCode
      }
    }
  }
`;

export type RegisterProjectDraftResponse = FetchResult<{
  registerProjectDraft: {
    projectDraft: {
      urlCode: string;
    };
  };
}>;

export type ProjectDraft = {
  schemaVersion: number;
  projectJson: string;
};

export const registerProjectDraft = (
  projectDraft: ProjectDraft
): Promise<RegisterProjectDraftResponse> =>
  internalApiAppClient.mutate({
    mutation: registerProjectDraftGql,
    variables: {
      schemaVersion: projectDraft.schemaVersion,
      projectJson: projectDraft.projectJson
    }
  });
