import { getClaimEstimate } from '@common/api/graphql/getClaimEstimate';
import {
  getBulkGiftCardIssueConfigClaimEstimateStart,
  getBulkGiftCardIssueConfigClaimEstimateSuccess,
  getBulkGiftCardIssueConfigClaimEstimateFailure
} from '@console/actions/actions';
import handleApiErrorIfNeeded from '@console/actions/thunks/handleApiErrorIfNeeded';
import type { ApiError } from '@common/api/graphql/apolloClient';
import type { GetClaimEstimateResponse } from '@common/api/graphql/getClaimEstimate';
import type { AppThunkAction } from '@console/actions/thunkType';

const getBulkGiftCardIssueConfigClaimEstimateSuccessThunk =
  (
    response: GetClaimEstimateResponse,
    giftCardConfigUrlCode: UrlCode,
    bulkGiftCardIssueConfigIndex: number
  ): AppThunkAction<Promise<void>> =>
  dispatch => {
    const claimEstimate = response.data!.claimEstimate;
    dispatch(
      getBulkGiftCardIssueConfigClaimEstimateSuccess(
        claimEstimate,
        giftCardConfigUrlCode,
        bulkGiftCardIssueConfigIndex
      )
    );
    return Promise.resolve();
  };

export const getBulkGiftCardIssueConfigClaimEstimateFailureThunk =
  (error: ApiError): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(getBulkGiftCardIssueConfigClaimEstimateFailure());
    return dispatch(handleApiErrorIfNeeded(error));
  };

export const getBulkGiftCardIssueConfigClaimEstimateThunk =
  (
    giftCardConfigUrlCode: UrlCode,
    bulkGiftCardIssueConfigIndex: number,
    bulkGiftCardIssueConfigAmount: number
  ): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(getBulkGiftCardIssueConfigClaimEstimateStart());
    return getClaimEstimate(bulkGiftCardIssueConfigAmount)
      .then((response: GetClaimEstimateResponse) =>
        dispatch(
          getBulkGiftCardIssueConfigClaimEstimateSuccessThunk(
            response,
            giftCardConfigUrlCode,
            bulkGiftCardIssueConfigIndex
          )
        )
      )
      .catch(error =>
        dispatch(getBulkGiftCardIssueConfigClaimEstimateFailureThunk(error))
      );
  };
