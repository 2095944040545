import { connect } from 'react-redux';
import { openDistributionPartnerEdit } from '../../actions/actions';
import { AccountContents } from '../../components/MainContents';
import DistributionPartner from '../../components/account/DistributionPartner';
import type { AppThunkAction, AppThunkDispatch } from '../../actions/thunkType';
import type { AppState } from '../../reducers';
import type { NavigateFunction } from 'react-router-dom';

const goDistributionPartnerEdit =
  (navigate: NavigateFunction): AppThunkAction<void> =>
  (dispatch, getState) => {
    dispatch(openDistributionPartnerEdit());

    const { urlCode } = getState().distributionPartner;
    navigate(
      `/console/${AccountContents.DISTRIBUTION_PARTNERS}/${urlCode}/edit`
    );
  };

const mapStateToProps = (state: AppState) => ({
  distributionPartner: state.distributionPartner
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  goDistributionPartnerEdit: (navigate: NavigateFunction) =>
    dispatch(goDistributionPartnerEdit(navigate))
});

export type DistributionPartnerPropsMappedFromState = ReturnType<
  typeof mapStateToProps
>;
export type DistributionPartnerPropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DistributionPartner);
