import {
  selectGifteeBoxTemplate,
  deselectGifteeBoxTemplate
} from '@console/actions/actions';
import { requestBulkDeliverableUrlThunk } from '@console/actions/thunks/requestBulkDeliverableUrl';
import Table from '@console/components/project/delivery/Table';
import { connect } from 'react-redux';
import type {
  AppThunkAction,
  AppThunkDispatch
} from '@console/actions/thunkType';
import type { AppState } from '@console/reducers';
import type { LocalGifteeBoxConfig } from '@console/reducers/projects';

const requestBulkDeliverableUrl =
  (urlCode: string, downloadType: string): AppThunkAction<void> =>
  dispatch => {
    dispatch(requestBulkDeliverableUrlThunk(urlCode, downloadType))
      .then(downloadUrl => {
        if (downloadUrl) {
          location.href = downloadUrl;
        }
      })
      .catch(() => {});
  };

const mapStateToProps = (state: AppState) => ({});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  requestBulkDeliverableUrl: (urlCode: string, downloadType: string) =>
    dispatch(requestBulkDeliverableUrl(urlCode, downloadType)),
  selectGifteeBoxTemplate: (
    gifteeBoxTemplate: LocalGifteeBoxConfig['gifteeBoxTemplate']
  ) => dispatch(selectGifteeBoxTemplate(gifteeBoxTemplate)),
  deselectGifteeBoxTemplate: () => dispatch(deselectGifteeBoxTemplate())
});

export type TablePropsMappedFromState = ReturnType<typeof mapStateToProps>;
export type TablePropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(mapStateToProps, mapDispatchToProps)(Table);
