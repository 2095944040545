import { AppActionTypes } from '../actions/actionType';
import type { AppAction } from '../actions/actionType';
import type { GiftDistributionChannelCategories } from '@common/api/graphql/getGiftDistributionChannelCategoriesGql';

const giftDistributionChannelCategories = (
  state = [],
  action: AppAction
): GiftDistributionChannelCategories => {
  switch (action.type) {
    case AppActionTypes.GET_GIFT_DISTRIBUTION_CHANNEL_CATEGORIES_SUCCESS:
    case AppActionTypes.GET_MASTER_DATA_SUCCESS:
      return action.giftDistributionChannelCategories;
    default:
      return state;
  }
};

export default giftDistributionChannelCategories;
