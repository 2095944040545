import { createSelector } from 'reselect';
import type { AppState } from '@console/reducers';

const getAddingBulkGifteeBoxIssueConfigs = (state: AppState) =>
  state.edits.project.gifteeBoxConfigs.addingBulkGifteeBoxIssueConfigs;

export const getAddingBulkGifteeBoxIssueConfigsSubTotals = createSelector(
  [getAddingBulkGifteeBoxIssueConfigs],
  (addingBulkGifteeBoxIssueConfigs): number[] =>
    addingBulkGifteeBoxIssueConfigs.map(addingBulkGifteeBoxIssueConfig => {
      if (
        !!addingBulkGifteeBoxIssueConfig.requestedIssueAmount &&
        !!addingBulkGifteeBoxIssueConfig.initialPoint
      ) {
        return (
          Number(addingBulkGifteeBoxIssueConfig.requestedIssueAmount) *
          Number(addingBulkGifteeBoxIssueConfig.initialPoint)
        );
      }
      return 0;
    })
);

export const getAddingBulkGifteeBoxIssueConfigsTotal = createSelector(
  [getAddingBulkGifteeBoxIssueConfigsSubTotals],
  (addingBulkGifteeBoxIssueConfigsSubTotals): number =>
    addingBulkGifteeBoxIssueConfigsSubTotals.reduce(
      (total, addingBulkGifteeBoxIssueConfigsSubTotal) =>
        total + addingBulkGifteeBoxIssueConfigsSubTotal,
      0
    )
);

export const getAddingBulkGifteeBoxIssueAmountTotal = createSelector(
  [getAddingBulkGifteeBoxIssueConfigs],
  (addingBulkGifteeBoxIssueConfigs): number =>
    addingBulkGifteeBoxIssueConfigs.reduce(
      (total, addingBulkGifteeBoxIssueConfig) =>
        total + Number(addingBulkGifteeBoxIssueConfig.requestedIssueAmount),
      0
    )
);
