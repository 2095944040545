import moment from '@common/lib/moment';
import { combineReducers } from 'redux';
import { AppActionTypes } from '../actions/actionType';
import type { AppAction } from '../actions/actionType';

const initialIssueReportsFilter = {
  issueDateFrom: moment().set('date', 1).format('YYYY-MM-DD'),
  issueDateTo: moment().format('YYYY-MM-DD')
};

const issueDateFrom = (
  state = initialIssueReportsFilter.issueDateFrom,
  action: AppAction
): string | null => {
  switch (action.type) {
    case AppActionTypes.SET_ISSUE_REPORTS_FILTER_ISSUE_DATE_FROM:
      return action.from.format('YYYY-MM-DD');
    default:
      return state;
  }
};

const issueDateTo = (
  state = initialIssueReportsFilter.issueDateTo,
  action: AppAction
): string | null => {
  switch (action.type) {
    case AppActionTypes.SET_ISSUE_REPORTS_FILTER_ISSUE_DATE_TO:
      return action.to.format('YYYY-MM-DD');
    default:
      return state;
  }
};

const issueReportsFilter = combineReducers({
  issueDateFrom,
  issueDateTo
});

export default issueReportsFilter;
