import { getGifteeBoxTemplateInitialContentSelectionsThunk } from '@console/actions/thunks/getGifteeBoxTemplateInitialContentSelections';
import FilteringSection from '@console/components/common/gifteeBoxTemplateContentSelectionsModal/FilteringSection';
import { connect } from 'react-redux';
import type {
  AppThunkDispatch,
  AppThunkAction
} from '@console/actions/thunkType';
import type { AppState } from '@console/reducers';

const filterGifteeBoxTemplateContentSelections =
  (): AppThunkAction<void> => (dispatch, getState) => {
    const state = getState();
    const gifteeBoxTemplateUrlCode = state.selectedGifteeBoxTemplate.urlCode;
    const { contentSelections } = state.searchInfo;
    if (!!gifteeBoxTemplateUrlCode && !!contentSelections) {
      dispatch(
        getGifteeBoxTemplateInitialContentSelectionsThunk(
          gifteeBoxTemplateUrlCode,
          {
            conditions: contentSelections.conditions
          }
        )
      ).catch(() => {});
    }
  };

const mapStateToProps = (state: AppState) => ({});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  filterGifteeBoxTemplateContentSelections: () =>
    dispatch(filterGifteeBoxTemplateContentSelections())
});

export type FilteringSectionPropsMappedFromState = ReturnType<
  typeof mapStateToProps
>;
export type FilteringSectionPropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(mapStateToProps, mapDispatchToProps)(FilteringSection);
