import {
  setIssueReportsFilterIssueDateFrom,
  setIssueReportsFilterIssueDateTo
} from '@console/actions/actions';
import { getIssueCountsThunk } from '@console/actions/thunks/getIssueCounts';
import IssueReportsIssueDatePickers from '@console/components/project/apiIssueReports/IssueDateRangePicker';
import { connect } from 'react-redux';
import type {
  AppThunkDispatch,
  AppThunkAction
} from '../../../actions/thunkType';
import type { AppState } from '../../../reducers';
import type moment from '@common/lib/moment';

const mapStateToProps = (state: AppState) => ({
  issueDateFrom: state.issueReportsFilter.issueDateFrom,
  issueDateTo: state.issueReportsFilter.issueDateTo,
  projectUrlCode: state.selectedProjectUrlCode,
  disableDatePicker: state.loaders.isAPIIssueReportsLoading
});

const initializeIssueReportsIssueDateRangePicker =
  (): AppThunkAction<Promise<void>> => (dispatch: AppThunkDispatch) =>
    dispatch(getIssueCountsThunk()).catch(() => {});

const handleIssueDateFrom =
  (newIssueDateFrom: moment.Moment): AppThunkAction<Promise<void>> =>
  (dispatch: AppThunkDispatch) => {
    dispatch(setIssueReportsFilterIssueDateFrom(newIssueDateFrom));
    return Promise.resolve();
  };

const handleIssueDateFromBlur =
  (): AppThunkAction<Promise<void>> => (dispatch: AppThunkDispatch) => {
    dispatch(getIssueCountsThunk()).catch(() => {});
    return Promise.resolve();
  };

const handleIssueDateTo =
  (newIssueDateTo: moment.Moment): AppThunkAction<Promise<void>> =>
  (dispatch: AppThunkDispatch) => {
    dispatch(setIssueReportsFilterIssueDateTo(newIssueDateTo));
    return Promise.resolve();
  };

const handleIssueDateToBlur =
  (): AppThunkAction<Promise<void>> => (dispatch: AppThunkDispatch) => {
    dispatch(getIssueCountsThunk()).catch(() => {});
    return Promise.resolve();
  };

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  initializeIssueReportsIssueDateRangePicker: () =>
    dispatch(initializeIssueReportsIssueDateRangePicker()),
  handleIssueDateFrom: (newIssueDateFrom: moment.Moment) =>
    dispatch(handleIssueDateFrom(newIssueDateFrom)).catch(() => {}),
  handleIssueDateFromBlur: () =>
    dispatch(handleIssueDateFromBlur()).catch(() => {}),
  handleIssueDateTo: (newIssueDateTo: moment.Moment) =>
    dispatch(handleIssueDateTo(newIssueDateTo)).catch(() => {}),
  handleIssueDateToBlur: () => dispatch(handleIssueDateToBlur()).catch(() => {})
});

export type IssueReportsIssueDateRangePickerPropsMappedFromState = ReturnType<
  typeof mapStateToProps
>;
export type IssueReportsIssueDateRangePickerPropsMappedFromDispatch =
  ReturnType<typeof mapDispatchToProps>;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IssueReportsIssueDatePickers);
