import SelectionReport from '@console/components/project/SelectionReport';
import {
  getIsBulkProject,
  getIsApiProject,
  getIsGcpProject
} from '@console/selectors/projectDetail/solutionSystem';
import { connect } from 'react-redux';
import type { AppThunkDispatch } from '@console/actions/thunkType';
import type { AppState } from '@console/reducers/index';

const mapStateToProps = (state: AppState) => ({
  isApiProject: getIsApiProject(state),
  isBulkProject: getIsBulkProject(state),
  isGcpProject: getIsGcpProject(state)
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({});

export type SelectedGifteeBoxReportsPropsMappedFromState = ReturnType<
  typeof mapStateToProps
>;

export type SelectedGifteeBoxReportsPropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(mapStateToProps, mapDispatchToProps)(SelectionReport);
