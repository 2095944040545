import { gql } from '@apollo/client';

import { internalApiAppClient } from './apolloClient';
import { operationNames } from './index';
import type { QueryGiftCardConfig } from './getProjectsGql';
import type { FetchResult } from '@apollo/client';

const requestGiftCardIssueAmountGql = gql`
  mutation ${operationNames.REQUEST_GIFT_CARD_ISSUE_AMOUNT}($issueAmount: Int!, $urlCode: String!) {
    requestGiftCardIssueAmount(
      input: {
        bulkGiftCardIssueConfigUrlCode: $urlCode
        issueAmount: $issueAmount
      }
    ) {
      giftCardConfig {
        urlCode
      }
    }
  }
`;

export type RequestGiftCardIssueAmountResponse = FetchResult<{
  requestGiftIssueAmount: {
    giftCardConfig: QueryGiftCardConfig;
  };
}>;

export const requestGiftCardIssueAmount = (
  issueAmount: number,
  urlCode: string
): Promise<RequestGiftCardIssueAmountResponse> =>
  internalApiAppClient.mutate({
    mutation: requestGiftCardIssueAmountGql,
    variables: {
      issueAmount,
      urlCode
    }
  });
