import APIIpRestrictionTableContainer from '@console/containers/project/apiIpRestriction/TableContainer';
import APIIpRestrictionNotes from './Notes';

const APIIpRestrictionContent: React.FC<{}> = ({}) => (
  <div>
    <APIIpRestrictionTableContainer />
    <APIIpRestrictionNotes />
  </div>
);

export default APIIpRestrictionContent;
