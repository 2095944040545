import { gql } from '@apollo/client';

import { internalApiAppClient } from './apolloClient';
import { FRAGMENT_PROJJECT } from './fragments/project';
import { operationNames } from './index';
import type { Content } from './getContentsGql';
import type { GifteeBoxTemplate } from './getGifteeBoxTemplatesGql';
import type { DistributionPartnerUser } from './getLoginUserGql';
import type { MessageThreadUrlCode } from './getMessageThread';
import type { SolutionSystem } from './getSolutionSystemsGql';
import type {
  Project,
  ProjectGiftDistributionChannel,
  ProjectAdvertisingMediumRegistration,
  MessageCardConfig,
  MarketingIndustry
} from './projectTypes';
import type { FetchResult } from '@apollo/client';

const getProjectsGql = gql`
  ${FRAGMENT_PROJJECT}
  query ${operationNames.GET_PROJECTS}{
    projects {
      ...ProjectFields
    }
  }
`;

export type QueryProjects = QueryProject[];

export type QueryProject = Project<
  QueryProjectGiftDistributionChannel[],
  QuerySolutionSystem,
  QueryGiftCardConfig[],
  QueryMarketingIndustry
> & {
  isSample: boolean;
  messageThread: QueryMessageThread | null;
  urlCode: QueryProjectUrlCode;
  claims: QueryProjectClaims;
  hostCompany: QueryHostCompany;
  confidentialInformation: QueryConfidentialInformation;
  gifteeBoxConfigs: QueryGifteeBoxConfigs;
};

export type QueryProjectGiftDistributionChannel =
  ProjectGiftDistributionChannel;

export type QueryProjectAdvertisingMediumRegistration =
  ProjectAdvertisingMediumRegistration;

export type QuerySolutionSystem = SolutionSystem;

export type QueryMarketingIndustry = MarketingIndustry & {
  name: string;
};

export type QueryFixedIssueConfig = {
  issueAmount: number;
} | null;

export type QueryIssueAmountRequest = null | {
  issueAmount: number;
  requestedAt: string;
};

export type QueryMessageThread = {
  urlCode: MessageThreadUrlCode;
};

export type QueryBulkDeliverable =
  | QueryBulkGiftCardDeliverable
  | QueryBulkGifteeBoxDeliverable;

export type QueryBulkGiftCardIssueConfig = {
  fixedIssueConfig: QueryFixedIssueConfig;
  deliveryDate: string;
  requestedIssueAmount: number;
  urlCode: string;
  issueAmountRequest: QueryIssueAmountRequest;
};

export type QueryBulkGiftCardDeliverable = {
  __typename: 'BulkGiftCardDeliverable';
  giftCardConfig: QueryGiftCardConfig;
  unitPrice: number;
} & QueryBulkDeliverableBase;

export type QueryBulkDeliverableBase = {
  urlCode: string;
  issueAmount: number;
  deliveredAt: string;
  downloadType: string;
};

export type QueryBulkGifteeBoxDeliverable = {
  __typename: 'BulkGifteeBoxDeliverable';
  gifteeBoxConfig: QueryGifteeBoxConfig;
  initialPoint: number;
  exchangeableEndLabel: string;
} & QueryBulkDeliverableBase;

export const isQueryBulkGiftCardDeliverable = (
  arg: any
): arg is QueryBulkGiftCardDeliverable =>
  arg.__typename === 'BulkGiftCardDeliverable';

export const isQueryBulkGifteeBoxDeliverable = (
  arg: any
): arg is QueryBulkGifteeBoxDeliverable =>
  arg.__typename === 'BulkGifteeBoxDeliverable';

export type QueryAccessToken = {
  token: string;
};

export type QueryProjectUrlCode = string;

export type QueryHostCompany = {
  name: string;
  isOtherCompanyHosting: boolean;
};

export type QueryGiftCardConfig = {
  urlCode: string;
  unitPrice: number;
  giftWalletStorable: boolean;
  giftCardTicketConfigs: QueryGiftCardTicketConfig[];
  messageCardConfig: QueryMessageCardConfig | null;
  creativeCheckApproved: boolean;
  isIssuable: boolean;
  bulkGiftCardIssueConfigs: QueryBulkGiftCardIssueConfig[];
  apiGiftCardIssueConfigs: QueryApiGiftCardIssueConfig[];
  currentApiGiftCardIssueConfig: QueryApiGiftCardIssueConfig | null;
};

export type QueryGiftCardTicketConfig =
  | QueryGiftCardFixedTicketConfig
  | QueryGiftCardChoosableTicketConfig;

export type QueryGiftCardFixedTicketConfig = {
  fixedContentOption: QueryFixedContentOption;
  choosableContentOption: null;
};

export type QueryGiftCardChoosableTicketConfig = {
  fixedContentOption: null;
  choosableContentOption: QueryChoosableContentOption;
};

export type QueryFixedContentOption = {
  content: Content;
  creativeCheckApproved: boolean;
};

export type QueryChoosableContentOption = {
  choices: QueryChoice[];
};

export type QueryChoice = {
  content: Content;
  creativeCheckApproved: boolean;
  order: number;
};

export type QueryBulkGiftCardIssueAmountRequest = {
  distributionPartnerUser: DistributionPartnerUser;
  issueAmount: number;
  requestedAt: Date;
};

export type QueryMainVisualUrlCode = string;
export type QueryMainVisualImageUrl = string;
type QueryMainVisual = {
  urlCode: QueryMainVisualUrlCode;
  imageUrl: QueryMainVisualImageUrl;
};

export type QueryMessageCardConfig = MessageCardConfig & {
  mainVisual: QueryMainVisual | null;
  mainVisualUrl: string | null;
};

export type QueryApiGiftCardIssueConfig = {
  issuableEndAt: string;
  issuableBeginAt: string;
  estimatedIssueAmount: number;
  urlCode: string;
};

export type QueryIssueCode = string | null;

export type QueryProjectClaims = QueryProjectClaim[];
export type QueryProjectClaim = {
  invoiceIdentity: string;
  invoiceUrlCode: string;
  name: string;
  note: string;
  paid: boolean;
  paymentDueDate: string;
  totalPaymentAmount: bigint;
  billingDate: string;
};

export type QueryApiAllowedIps = QueryApiAllowedIp[];

export type QueryApiAllowedIp = {
  ip: string;
  urlCode: UrlCode;
};

export type QueryConfidentialInformation =
  | QueryNullConfidentialInformation
  | QueryBulkConfidentialInformation
  | QueryAPIConfidentialInformation;

export const NULL_CONFIDENTIAL_INFORMATION = 'NullConfidentialInformation';
export const BULK_CONFIDENTIAL_INFORMATION = 'BulkConfidentialInformation';
export const API_CONFIDENTIAL_INFORMATION = 'APIConfidentialInformation';

export type QueryNullConfidentialInformation = {
  __typename: typeof NULL_CONFIDENTIAL_INFORMATION;
  reason: string;
};

export type QueryBulkConfidentialInformation = {
  __typename: typeof BULK_CONFIDENTIAL_INFORMATION;
};

export type QueryAPIConfidentialInformation = {
  __typename: typeof API_CONFIDENTIAL_INFORMATION;
  apiAllowedIps: QueryApiAllowedIp[];
  accessTokens: QueryAccessToken[];
  giftCardConfigs: QueryAPIConfidentialInformationGiftCardConfig[];
  gifteeBoxConfigs: QueryAPIConfidentialInformationGifteeBoxConfigs;
};

export type QueryAPIConfidentialInformationGiftCardConfig = {
  issueCode: QueryIssueCode;
  urlCode: UrlCode;
};

export type QueryAPIConfidentialInformationGifteeBoxConfig = {
  issueCode: QueryIssueCode;
  urlCode: UrlCode;
};

export type QueryAPIConfidentialInformationGifteeBoxConfigs =
  QueryAPIConfidentialInformationGifteeBoxConfig[];

export type QueryGifteeBoxConfigs = QueryGifteeBoxConfig[] | null;
export type QueryGifteeBoxConfig = {
  urlCode: UrlCode;
  brandCheckApproved: boolean;
  gifteeBoxTemplate: QueryGifteeBoxTemplate;
  bulkGifteeBoxIssueConfigs: QueryBulkGifteeBoxIssueConfigs;
  apiGifteeBoxIssueConfigs: QueryAPIGifteeBoxIssueConfigs;
  messageCardOption: MessageCardOption | null;
  currentApiGifteeBoxIssueConfig: QueryAPIGifteeBoxIssueConfig | null;
};

export type MessageCardOption = {
  description: string;
  imageUrl: string;
};

export type QueryGifteeBoxTemplate = GifteeBoxTemplate;

export type QueryBulkGifteeBoxIssueConfigs = QueryBulkGifteeBoxIssueConfig[];
export type QueryBulkGifteeBoxIssueConfig = {
  deliveryDate: string;
  requestedIssueAmount: number;
  urlCode: string;
  issueAmountRequest: QueryBulkGifteeBoxIssueConfigIssueAmountRequest;
  fixedIssueConfig: QueryFixedIssueConfig | null;
  initialPoint: number;
  gifteeBoxIssueConfig: QueryBulkGifteeBoxIssueConfigsExchageable;
};

export type QueryBulkGifteeBoxIssueConfigIssueAmountRequest = null | {
  issueAmount: number;
  requestedAt: string;
};

export type QueryAPIGifteeBoxIssueConfigs = QueryAPIGifteeBoxIssueConfig[];
export type QueryAPIGifteeBoxIssueConfig = {
  urlCode: UrlCode;
  issuableBeginAt: string;
  issuableEndAt: string;
  issueEstimateOptions: QueryAPIGifteeBoxIssueConfigIssueEstimateOptions;
  gifteeBoxIssueConfig: QueryBulkGifteeBoxIssueConfigsExchageable;
};

export type QueryBulkGifteeBoxIssueConfigsExchageable = {
  exchangeableEndLabel: string;
};

export type QueryAPIGifteeBoxIssueConfigIssueEstimateOptions =
  QueryAPIGifteeBoxIssueConfigIssueEstimateOption[];
export type QueryAPIGifteeBoxIssueConfigIssueEstimateOption = {
  initialPoint: number;
  issueAmount: number;
  urlCode: UrlCode;
};

export type GetProjectsQuery = {
  projects: {
    name: string;
    description: string;
    urlCode: string;
    isSample: boolean;
    hostCompany: {
      name: string;
      isOtherCompanyHosting: boolean;
    };
    advertisingPeriod: {
      beginAt: string;
      endAt: string;
    } | null;
    projectGiftDistributionChannels: {
      urlCode: string;
      description: string;
      name: string;
    }[];
    projectAdvertisingMediumRegistrations: {
      urlCode: string;
      description: string;
    }[];
    marketingIndustry: {
      urlCode: string;
      name: string;
    };
    solutionSystem: {
      name: string;
      solutionSystemIdentifier: string;
      urlCode: string;
    };
    messageThread: {
      urlCode: string;
    } | null;
    thirdPartyService: {
      url: string;
      name: string;
      description: string;
    } | null;
    giftCardConfigs: {
      urlCode: string;
      giftCardTicketConfigs: (
        | {
            fixedContentOption: {
              content: Content;
            };
            choosableContentOption: null;
          }
        | {
            fixedContentOption: null;
            choosableContentOption: {
              choices: {
                content: Content;
                order: number;
              }[];
            };
          }
      )[];
    }[];
    gifteeBoxConfigs: {
      urlCode: string;
      gifteeBoxTemplate: GifteeBoxTemplate;
    }[];
    gxItems: {
      imageUrl: string;
    }[];
  }[];
};

export type GetProjectsResponse = FetchResult<GetProjectsQuery>;

export const getProjects = (): Promise<GetProjectsResponse> =>
  internalApiAppClient.query({
    query: getProjectsGql
  });
