import GiftCardTicketConfigsContents from '@console/components/project/common/GiftCardTicketConfigsContents';
import AddingBulkGiftCardIssueConfigsTableContainer from '@console/containers/project/giftCardConfigs/bulkGiftCardIssueConfigs/new/AddingBulkGiftCardIssueConfigsTableContainer';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import { Grid, Typography, Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import type { ProjectLayoutPathParams } from '@console/components/project/ProjectLayout';
import type {
  NewPropsMappedFromState,
  NewPropsMappedFromDispatch
} from '@console/containers/project/giftCardConfigs/bulkGiftCardIssueConfigs/NewContainer';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => ({
  title: {
    marginTop: theme.spacing(6)
  },
  section: {
    marginBottom: theme.spacing(2)
  },
  giftCardConfigContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    marginRight: theme.spacing(8)
  }
});

type NewPathParams = ProjectLayoutPathParams & {
  giftCardConfigUrlCode: UrlCode;
};

type NewProps = NewPropsMappedFromState &
  NewPropsMappedFromDispatch &
  WithStyles<typeof styles>;
const New: React.FC<NewProps> = ({
  giftCardConfig,
  onRenderNew,
  errors,
  classes
}) => {
  const params = useParams<NewPathParams>();

  useEffect(() => {
    if (!!params.giftCardConfigUrlCode) {
      onRenderNew(params.giftCardConfigUrlCode);
    }
  }, [params.giftCardConfigUrlCode]);

  return (
    <Grid data-cy="new">
      <Typography variant="subtitle1" className={classes.title}>
        追加発注の登録をしてください
      </Typography>
      <Typography
        id="projectAddingBulkGiftCardIssueConfigsTotal"
        className={classes.errorMessage}
        color="error"
      >
        {errors.projectAddingBulkGiftCardIssueConfigsTotal}
      </Typography>
      <Grid container className={classes.section} spacing={4}>
        <Grid item className={classes.giftCardConfigContainer}>
          {!!giftCardConfig ? (
            <GiftCardTicketConfigsContents giftCardConfig={giftCardConfig} />
          ) : null}
        </Grid>
        <Grid item>
          <AddingBulkGiftCardIssueConfigsTableContainer />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(New);
