import { getUniqueElements } from '@common/lib/getUniqueElements';
import isEmojiIncluded from '@common/lib/isEmojiIncluded';
import {
  createErrorMessageForArrayEmpty,
  createErrorMessageForUnselected,
  createErrorMessageForEmpty,
  createErrorMessageForChoosableGiftConfigSelectedContentsLength,
  createErrorMessageForIsNumeric,
  createErrorMessageForGreaterThan,
  createErrorMessageForPastDate,
  createErrorMessageForEmojiIncluded,
  createErrorMessageForProjectDraftFutureDate,
  createErrorForGifteeBoxInitialPoint,
  createErrorForDuplicateEntries,
  ERROR_MESSAGE_FOR_EXCEED_CREDIT_LIMIT_IN_PROJECT_DRAFT
} from '@common/middleware/errorMessages';
import {
  validationGiftConfigSuccess,
  validationGiftConfigFailure,
  validationGiftConfigCommonSuccess,
  validationGiftConfigCommonFailure
} from '@console/actions/actions';
import {
  JP_CONTENT,
  JP_GIFT_CONFIG,
  JP_ISSUE_AMOUNT,
  JP_DELIVERY_DATE,
  JP_GIFTEE_BOX_INITIAL_POINT,
  JP_GIFTEE_BOX_ISSUE_ESTIMATE_OPTION_INITIAL_POINT,
  JP_GIFTEE_BOX_ISSUE_ESTIMATE_OPTION_ISSUE_AMOUNT,
  JP_SENDER_NAME,
  JP_MESSAGE,
  MIN_CHOOSABLE_GIFT_CONTENTS
} from '@console/common/constants';
import {
  isChoosableGiftConfig,
  isGifteeBoxGiftConfig
} from '@console/common/projectDraft/giftConfig';
import {
  isBulkSolutionSystem,
  isApiSolutionSystem
} from '@console/common/solutionSystem';
import { isSupportedPoint } from '@console/middleware/validator/common/isSupportedPoint';
import { getCreditLimitBalance } from '@console/selectors/distributionPartner';
import { getGifteeBoxTemplates } from '@console/selectors/getGifteeBoxTemplates';
import {
  getSelectedSolutionSystem,
  getSelectedProjectDraftGiftConfigsEstimatedAmount
} from '@console/selectors/getSelectedProjectDraftForDisplay';
import validator from 'validator';
import type {
  GifteeBoxTemplate,
  GifteeBoxTemplates
} from '@common/api/graphql/getGifteeBoxTemplatesGql';
import type { SolutionSystem } from '@common/api/graphql/getSolutionSystemsGql';
import type { AppThunkAction } from '@console/actions/thunkType';
import type { Errors } from '@console/reducers/errors';
import type {
  GiftConfigs,
  GiftConfig,
  GifteeBoxGiftConfig,
  GiftCardGiftConfig
} from '@console/reducers/projectJson/giftConfigs';

const GIFT_CONFIGS_COMMON = 'projectDraftGiftConfigsCommon';
const GIFT_CONFIGS = 'projectDraftGiftConfigs';
const SELECTED_CONTENTS = 'selectedContents';
const SELECTED_GIFTEE_BOX_TEMPLATE = 'selectedGifteeBoxTemplate';
const ISSUE_AMOUNT = 'issueAmount';
const DELIVERY_DATE = 'deliveryDate';
const GIFTEE_BOX_INITIAL_POINT = 'gifteeBoxInitialPoint';
const GIFTEE_BOX_ISSUE_ESTIMATE_OPTION_INITIAL_POINT =
  'gifteeBoxIssueEstimateOptionInitialPoint';
const GIFTEE_BOX_ISSUE_ESTIMATE_OPTION_ISSUE_AMOUNT =
  'gifteeBoxIssueEstimateOptionIssueAmount';
const SENDER_NAME = 'senderName';
const MESSAGE = 'message';

const validateGiftConfigsCommon =
  (giftConfigs: GiftConfigs): AppThunkAction<void> =>
  (dispatch, getState) => {
    let message: string = '';
    const state = getState();
    const selectedSolutionSystem = getSelectedSolutionSystem(state);

    const creditLimitBalance = getCreditLimitBalance(state);
    const estimatedAmountTotal =
      getSelectedProjectDraftGiftConfigsEstimatedAmount(state);

    if (giftConfigs.length === 0) {
      message = createErrorMessageForArrayEmpty(JP_GIFT_CONFIG);
    } else if (
      isExceedCreditLimit(
        estimatedAmountTotal,
        creditLimitBalance,
        selectedSolutionSystem
      )
    ) {
      message = ERROR_MESSAGE_FOR_EXCEED_CREDIT_LIMIT_IN_PROJECT_DRAFT;
    }

    if (message === '') {
      dispatch(validationGiftConfigCommonSuccess());
    } else {
      dispatch(validationGiftConfigCommonFailure(message));
    }
  };

const hasApiGifteeBoxConfig = (
  giftConfigs: GiftConfigs,
  selectedSolutionSystem: SolutionSystem | undefined
): boolean => {
  if (!selectedSolutionSystem) return false;

  const hasGifteeBoxGiftConfig = giftConfigs.some(giftConfig =>
    isGifteeBoxGiftConfig(giftConfig)
  );
  const isApiSolutionSystemSelected = isApiSolutionSystem(
    selectedSolutionSystem
  );
  return hasGifteeBoxGiftConfig && isApiSolutionSystemSelected;
};

const isExceedCreditLimit = (
  estimatedAmountTotal: number,
  creditLimitBalance: number | null,
  selectedSolutionSystem: SolutionSystem | undefined
): boolean => {
  if (!selectedSolutionSystem || !isBulkSolutionSystem(selectedSolutionSystem))
    return false;
  if (creditLimitBalance === null) return false;

  return creditLimitBalance < estimatedAmountTotal;
};

const validateGiftConfigsForBulkProject =
  (giftConfigs: GiftConfigs): AppThunkAction<void> =>
  dispatch => {
    for (const i in giftConfigs) {
      dispatch(validateGiftConfigForBulkProject(giftConfigs[i], Number(i)));
    }
  };

const validateGiftConfigForBulkProject =
  (giftConfig: GiftConfig, giftConfigIndex: number): AppThunkAction<void> =>
  dispatch => {
    if (isGifteeBoxGiftConfig(giftConfig)) {
      dispatch(
        validateGifteeBoxGiftConfigForBulkProject(giftConfig, giftConfigIndex)
      );
    } else {
      dispatch(
        validateGiftCardGiftConfigForBulkProject(giftConfig, giftConfigIndex)
      );
    }
  };

const validateGifteeBoxGiftConfigForBulkProject =
  (
    giftConfig: GifteeBoxGiftConfig,
    giftConfigIndex: number
  ): AppThunkAction<void> =>
  dispatch => {
    dispatch(validateGifteeBoxTemplateSelection(giftConfigIndex));
    dispatch(validateIssueAmount(giftConfig, giftConfigIndex));
    dispatch(validateDeliveryDate(giftConfig, giftConfigIndex));
    dispatch(validateGifteeBoxInitialPoint(giftConfig, giftConfigIndex));
  };

const validateGiftCardGiftConfigForBulkProject =
  (
    giftConfig: GiftCardGiftConfig,
    giftConfigIndex: number
  ): AppThunkAction<void> =>
  dispatch => {
    dispatch(validateSelectedContents(giftConfig, giftConfigIndex));
    dispatch(validateIssueAmount(giftConfig, giftConfigIndex));
    dispatch(validateDeliveryDate(giftConfig, giftConfigIndex));
    dispatch(validateSenderName(giftConfig, giftConfigIndex));
    dispatch(validateMessage(giftConfig, giftConfigIndex));
  };

const validateGiftConfigsForGcpProject =
  (giftConfigs: GiftConfigs): AppThunkAction<void> =>
  (dispatch, getState) => {
    for (const i in giftConfigs) {
      dispatch(validateGiftConfigForGcpProject(giftConfigs[i], Number(i)));
    }
  };

const validateGiftConfigForGcpProject =
  (giftConfig: GiftConfig, giftConfigIndex: number): AppThunkAction<void> =>
  dispatch => {
    if (isGifteeBoxGiftConfig(giftConfig)) {
      dispatch(
        validateGifteeBoxGiftConfigForGcpProject(giftConfig, giftConfigIndex)
      );
    } else {
      dispatch(
        validateGiftCardGiftConfigForGcpProject(giftConfig, giftConfigIndex)
      );
    }
  };

const validateGiftConfigForApiProject =
  (giftConfig: GiftConfig, giftConfigIndex: number): AppThunkAction<void> =>
  dispatch => {
    if (isGifteeBoxGiftConfig(giftConfig)) {
      dispatch(
        validateGifteeBoxGiftConfigForApiProject(giftConfig, giftConfigIndex)
      );
    } else {
      dispatch(
        validateGiftCardGiftConfigForApiProject(giftConfig, giftConfigIndex)
      );
    }
  };

const validateGifteeBoxGiftConfigForGcpProject =
  (giftConfig, giftConfigIndex): AppThunkAction<void> =>
  dispatch => {
    dispatch(validateGifteeBoxTemplateSelection(giftConfigIndex));
    dispatch(
      validateGifteeBoxIssueEstimateOptionIssueAmount(
        giftConfig,
        giftConfigIndex
      )
    );
    dispatch(
      validateGifteeBoxIssueEstimateOptionInitialPoint(
        giftConfig,
        giftConfigIndex
      )
    );
  };

const validateGifteeBoxGiftConfigForApiProject =
  (giftConfig, giftConfigIndex): AppThunkAction<void> =>
  dispatch => {
    dispatch(validateGifteeBoxTemplateSelection(giftConfigIndex));
    dispatch(
      validateGifteeBoxIssueEstimateOptionIssueAmount(
        giftConfig,
        giftConfigIndex
      )
    );
    dispatch(
      validateGifteeBoxIssueEstimateOptionInitialPoint(
        giftConfig,
        giftConfigIndex
      )
    );
  };

const validateGiftCardGiftConfigForGcpProject =
  (giftConfig, giftConfigIndex): AppThunkAction<void> =>
  dispatch => {
    dispatch(validateSelectedContents(giftConfig, giftConfigIndex));
    dispatch(validateIssueAmount(giftConfig, giftConfigIndex));
    dispatch(validateSenderName(giftConfig, giftConfigIndex));
    dispatch(validateMessage(giftConfig, giftConfigIndex));
  };

const validateGiftConfigsForApiProject =
  (giftConfigs: GiftConfigs): AppThunkAction<void> =>
  (dispatch, getState) => {
    for (const i in giftConfigs) {
      dispatch(validateGiftConfigForApiProject(giftConfigs[i], Number(i)));
    }
  };

const validateGiftCardGiftConfigForApiProject =
  (giftConfig: GiftConfig, giftConfigIndex: number): AppThunkAction<void> =>
  dispatch => {
    dispatch(validateSelectedContents(giftConfig, giftConfigIndex));
    dispatch(validateIssueAmount(giftConfig, giftConfigIndex));
    dispatch(validateSenderName(giftConfig, giftConfigIndex));
    dispatch(validateMessage(giftConfig, giftConfigIndex));
  };

const validateSelectedContents =
  (giftConfig: GiftConfig, giftConfigIndex: number): AppThunkAction<void> =>
  dispatch => {
    if (isChoosableGiftConfig(giftConfig)) {
      dispatch(validateChoosableGiftSelectedContents(giftConfigIndex));
    } else {
      dispatch(validateFixedGiftSelectedContents(giftConfig, giftConfigIndex));
    }
  };

const validateFixedGiftSelectedContents =
  (giftConfig: GiftConfig, giftConfigIndex: number): AppThunkAction<void> =>
  dispatch => {
    let errorMessage: string = '';

    if (giftConfig.selectedContents.length === 0) {
      errorMessage = createErrorMessageForUnselected(JP_GIFT_CONFIG);
    }

    if (errorMessage === '') {
      dispatch(validationGiftConfigSuccess(SELECTED_CONTENTS, giftConfigIndex));
    } else {
      dispatch(
        validationGiftConfigFailure(
          SELECTED_CONTENTS,
          giftConfigIndex,
          errorMessage
        )
      );
    }
  };

const validateChoosableGiftSelectedContents =
  (giftConfigIndex: number): AppThunkAction<void> =>
  (dispatch, getState) => {
    const giftConfig =
      getState().selectedProjectDraft.projectJson.giftConfigs[giftConfigIndex];
    let errorMessage: string = '';

    if (giftConfig.selectedContents.length < MIN_CHOOSABLE_GIFT_CONTENTS) {
      errorMessage =
        createErrorMessageForChoosableGiftConfigSelectedContentsLength(
          JP_GIFT_CONFIG
        );
    }

    if (errorMessage === '') {
      dispatch(validationGiftConfigSuccess(SELECTED_CONTENTS, giftConfigIndex));
    } else {
      dispatch(
        validationGiftConfigFailure(
          SELECTED_CONTENTS,
          giftConfigIndex,
          errorMessage
        )
      );
    }
  };

const validateGifteeBoxTemplateSelection =
  (giftConfigIndex: number): AppThunkAction<void> =>
  (dispatch, getState) => {
    const giftConfig =
      getState().selectedProjectDraft.projectJson.giftConfigs[giftConfigIndex];
    let errorMessage: string = '';

    if (giftConfig.selectedGifteeBoxTemplate === null) {
      errorMessage = createErrorMessageForUnselected(JP_GIFT_CONFIG);
    }

    if (errorMessage === '') {
      dispatch(
        validationGiftConfigSuccess(
          SELECTED_GIFTEE_BOX_TEMPLATE,
          giftConfigIndex
        )
      );
    } else {
      dispatch(
        validationGiftConfigFailure(
          SELECTED_GIFTEE_BOX_TEMPLATE,
          giftConfigIndex,
          errorMessage
        )
      );
    }
  };

const validateIssueAmount =
  (giftConfig: GiftConfig, giftConfigIndex: number): AppThunkAction<void> =>
  (dispatch, getState) => {
    let errorMessage: string = '';

    if (validator.isEmpty(giftConfig.issueAmount)) {
      errorMessage = createErrorMessageForEmpty(JP_ISSUE_AMOUNT);
    } else if (!validator.isNumeric(giftConfig.issueAmount)) {
      errorMessage = createErrorMessageForIsNumeric(JP_ISSUE_AMOUNT);
    } else if (!validator.isInt(giftConfig.issueAmount, { gt: 0 })) {
      errorMessage = createErrorMessageForGreaterThan(JP_ISSUE_AMOUNT, 0);
    }

    if (errorMessage === '') {
      dispatch(validationGiftConfigSuccess(ISSUE_AMOUNT, giftConfigIndex));
    } else {
      dispatch(
        validationGiftConfigFailure(ISSUE_AMOUNT, giftConfigIndex, errorMessage)
      );
    }
  };

const validateDeliveryDate =
  (giftConfig: GiftConfig, giftConfigIndex: number): AppThunkAction<void> =>
  (dispatch, getState) => {
    let errorMessage: string = '';
    const date = new Date();
    date.setFullYear(new Date().getFullYear() + 1);

    if (giftConfig.deliveryDate === null) {
      errorMessage = createErrorMessageForEmpty(JP_DELIVERY_DATE);
    } else if (!validator.isAfter(giftConfig.deliveryDate)) {
      errorMessage = createErrorMessageForPastDate(JP_DELIVERY_DATE);
    } else if (!validator.isBefore(giftConfig.deliveryDate, date.toString())) {
      errorMessage = createErrorMessageForProjectDraftFutureDate(
        JP_DELIVERY_DATE,
        '１年'
      );
    }

    if (errorMessage === '') {
      dispatch(validationGiftConfigSuccess(DELIVERY_DATE, giftConfigIndex));
    } else {
      dispatch(
        validationGiftConfigFailure(
          DELIVERY_DATE,
          giftConfigIndex,
          errorMessage
        )
      );
    }
  };

const getSelectedGifteeBoxTemplate = (
  giftConfig: GiftConfig,
  gifteeBoxTemplates: GifteeBoxTemplates
): GifteeBoxTemplate | null => {
  if (!giftConfig?.selectedGifteeBoxTemplate || !gifteeBoxTemplates) {
    return null;
  }
  return (
    gifteeBoxTemplates.find(
      gifteeBoxTemplate =>
        giftConfig.selectedGifteeBoxTemplate!.urlCode ===
        gifteeBoxTemplate.urlCode
    ) ?? null
  );
};

export const validateGifteeBoxInitialPoint =
  (giftConfig: GiftConfig, giftConfigIndex: number): AppThunkAction<void> =>
  (dispatch, getState) => {
    const selectedGifteeBoxTemplate = getSelectedGifteeBoxTemplate(
      giftConfig,
      getGifteeBoxTemplates(getState())
    );
    let errorMessage: string = '';

    if (!selectedGifteeBoxTemplate) {
      errorMessage = createErrorMessageForUnselected(JP_GIFT_CONFIG);
    } else if (giftConfig.gifteeBoxInitialPoint === '') {
      errorMessage = createErrorMessageForEmpty(JP_GIFTEE_BOX_INITIAL_POINT);
    } else if (
      !validator.isNumeric(giftConfig.gifteeBoxInitialPoint, {
        no_symbols: true
      })
    ) {
      errorMessage = createErrorMessageForIsNumeric(
        JP_GIFTEE_BOX_INITIAL_POINT
      );
    } else if (
      !isSupportedPoint(
        Number(giftConfig.gifteeBoxInitialPoint),
        selectedGifteeBoxTemplate.supportedPoint
      )
    ) {
      errorMessage = createErrorForGifteeBoxInitialPoint(
        JP_GIFTEE_BOX_INITIAL_POINT,
        selectedGifteeBoxTemplate.supportedPoint
      );
    }

    if (errorMessage === '') {
      dispatch(
        validationGiftConfigSuccess(GIFTEE_BOX_INITIAL_POINT, giftConfigIndex)
      );
    } else {
      dispatch(
        validationGiftConfigFailure(
          GIFTEE_BOX_INITIAL_POINT,
          giftConfigIndex,
          errorMessage
        )
      );
    }
  };

export const validateGifteeBoxIssueEstimateOptionInitialPoint =
  (giftConfig: GiftConfig, giftConfigIndex: number): AppThunkAction<void> =>
  (dispatch, getState) => {
    const selectedGifteeBoxTemplate = getSelectedGifteeBoxTemplate(
      giftConfig,
      getGifteeBoxTemplates(getState())
    );
    let errorMessage: string = '';

    if (!selectedGifteeBoxTemplate) {
      errorMessage = createErrorMessageForUnselected(JP_GIFT_CONFIG);
    } else {
      for (const gifteeBoxIssueEstimateOption of giftConfig.gifteeBoxIssueEstimateOptions) {
        if (gifteeBoxIssueEstimateOption.initialPoint === '') {
          errorMessage = createErrorMessageForEmpty(
            JP_GIFTEE_BOX_ISSUE_ESTIMATE_OPTION_INITIAL_POINT
          );
          break;
        }
        if (
          !validator.isNumeric(gifteeBoxIssueEstimateOption.initialPoint, {
            no_symbols: true
          })
        ) {
          errorMessage = createErrorMessageForIsNumeric(
            JP_GIFTEE_BOX_ISSUE_ESTIMATE_OPTION_INITIAL_POINT
          );
          break;
        }
        if (
          !isSupportedPoint(
            Number(gifteeBoxIssueEstimateOption.initialPoint),
            selectedGifteeBoxTemplate.supportedPoint
          )
        ) {
          errorMessage = createErrorForGifteeBoxInitialPoint(
            JP_GIFTEE_BOX_ISSUE_ESTIMATE_OPTION_INITIAL_POINT,
            selectedGifteeBoxTemplate.supportedPoint
          );
          break;
        }
      }
    }

    if (giftConfig.gifteeBoxIssueEstimateOptions.length > 1) {
      const initialPoints = giftConfig.gifteeBoxIssueEstimateOptions.map(
        gifteeBoxIssueEstimateOption =>
          gifteeBoxIssueEstimateOption.initialPoint
      );
      if (initialPoints.length > getUniqueElements(initialPoints).length) {
        errorMessage = createErrorForDuplicateEntries(
          JP_GIFTEE_BOX_ISSUE_ESTIMATE_OPTION_INITIAL_POINT
        );
      }
    }

    if (errorMessage === '') {
      dispatch(
        validationGiftConfigSuccess(
          GIFTEE_BOX_ISSUE_ESTIMATE_OPTION_INITIAL_POINT,
          giftConfigIndex
        )
      );
    } else {
      dispatch(
        validationGiftConfigFailure(
          GIFTEE_BOX_ISSUE_ESTIMATE_OPTION_INITIAL_POINT,
          giftConfigIndex,
          errorMessage
        )
      );
    }
  };

const validateGifteeBoxIssueEstimateOptionIssueAmount =
  (giftConfig: GiftConfig, giftConfigIndex: number): AppThunkAction<void> =>
  (dispatch, getState) => {
    let errorMessage: string = '';

    if (
      giftConfig.gifteeBoxIssueEstimateOptions.some(
        gifteeBoxIssueEstimateOption =>
          gifteeBoxIssueEstimateOption.issueAmount === ''
      )
    ) {
      errorMessage = createErrorMessageForEmpty(
        JP_GIFTEE_BOX_ISSUE_ESTIMATE_OPTION_ISSUE_AMOUNT
      );
    }

    if (errorMessage === '') {
      dispatch(
        validationGiftConfigSuccess(
          GIFTEE_BOX_ISSUE_ESTIMATE_OPTION_ISSUE_AMOUNT,
          giftConfigIndex
        )
      );
    } else {
      dispatch(
        validationGiftConfigFailure(
          GIFTEE_BOX_ISSUE_ESTIMATE_OPTION_ISSUE_AMOUNT,
          giftConfigIndex,
          errorMessage
        )
      );
    }
  };

const validateSenderName =
  (giftConfig: GiftConfig, giftConfigIndex: number): AppThunkAction<void> =>
  dispatch => {
    let errorMessage: string = '';

    if (isEmojiIncluded(giftConfig.senderName)) {
      errorMessage = createErrorMessageForEmojiIncluded(JP_SENDER_NAME);
    }

    if (errorMessage === '') {
      dispatch(validationGiftConfigSuccess(SENDER_NAME, giftConfigIndex));
    } else {
      dispatch(
        validationGiftConfigFailure(SENDER_NAME, giftConfigIndex, errorMessage)
      );
    }
  };

const validateMessage =
  (giftConfig: GiftConfig, giftConfigIndex: number): AppThunkAction<void> =>
  dispatch => {
    let errorMessage: string = '';

    if (isEmojiIncluded(giftConfig.message)) {
      errorMessage = createErrorMessageForEmojiIncluded(JP_MESSAGE);
    }

    if (errorMessage === '') {
      dispatch(validationGiftConfigSuccess(MESSAGE, giftConfigIndex));
    } else {
      dispatch(
        validationGiftConfigFailure(MESSAGE, giftConfigIndex, errorMessage)
      );
    }
  };

export const projectDraftGiftConfigsHasValidationErrors = (
  errors: Errors
): boolean => {
  const giftConfigsError = errors.projectDraftGiftConfigs;
  return Object.keys(giftConfigsError).length > 0;
};

export const projectDraftGiftConfigsCommonHasValidationErrors = (
  errors: Errors
): boolean => {
  const giftConfigsCommonErrorMessage = errors.projectDraftGiftConfigsCommon;
  return giftConfigsCommonErrorMessage.length > 0;
};

export const projectDraftFixedContentSelectionHasValidationErrors = (
  errors: Errors,
  selectedGiftConfigIndex: number
): boolean => {
  const giftConfigError =
    errors.projectDraftGiftConfigs?.[selectedGiftConfigIndex];
  return !!giftConfigError?.selectedContents;
};

export const projectDraftChoosableContentsSelectionHasValidationErrors = (
  errors: Errors,
  selectedGiftConfigIndex: number
): boolean => {
  const giftConfigError =
    errors.projectDraftGiftConfigs?.[selectedGiftConfigIndex];
  return !!giftConfigError?.selectedContents;
};

export const projectDraftGifteeBoxTemplateSelectionHasValidationErrors = (
  errors: Errors,
  selectedGiftConfigIndex: number
): boolean => {
  const giftConfigError =
    errors.projectDraftGiftConfigs?.[selectedGiftConfigIndex];
  return !!giftConfigError?.selectedGifteeBoxTemplate;
};

export const projectDraftDeliveryHasValidationErrors = (
  errors: Errors,
  selectedGiftConfigIndex: number
): boolean => {
  const giftConfigError =
    errors.projectDraftGiftConfigs[selectedGiftConfigIndex];
  return !!giftConfigError?.issueAmount || !!giftConfigError?.deliveryDate;
};

export const projectDraftGifteeBoxDeliveryConfigHasValidationErrors = (
  errors: Errors,
  selectedGiftConfigIndex: number
): boolean => {
  const giftConfigError =
    errors.projectDraftGiftConfigs[selectedGiftConfigIndex];
  return (
    !!giftConfigError?.issueAmount ||
    !!giftConfigError?.deliveryDate ||
    !!giftConfigError?.gifteeBoxInitialPoint ||
    !!giftConfigError?.gifteeBoxIssueEstimateOptionInitialPoint ||
    !!giftConfigError?.gifteeBoxIssueEstimateOptionIssueAmount
  );
};

export const projectDraftMessageCardConfigHasValidationErrors = (
  errors: Errors,
  selectedGiftConfigIndex: number
): boolean => {
  const giftConfigError =
    errors.projectDraftGiftConfigs[selectedGiftConfigIndex];
  return !!giftConfigError?.senderName || !!giftConfigError?.message;
};

export const projectDraftGiftConfigs = {
  validateGiftConfigsCommon,
  validateGiftConfigsForBulkProject,
  validateGiftConfigsForApiProject,
  validateGiftConfigsForGcpProject,
  validateFixedGiftSelectedContents,
  validateChoosableGiftSelectedContents,
  validateGifteeBoxTemplateSelection,
  validateIssueAmount,
  validateDeliveryDate,
  validateGifteeBoxInitialPoint,
  validateGifteeBoxIssueEstimateOptionInitialPoint,
  validateGifteeBoxIssueEstimateOptionIssueAmount,
  validateSenderName,
  validateMessage
};
