import { AppActionTypes } from '../actions/actionType';
import type { AppAction } from '../actions/actionType';

const notificationNumber = (state = 0, action: AppAction): number => {
  switch (action.type) {
    case AppActionTypes.UPDATE_NOTIFICATION_NUMBER:
      return action.notificationNumber;
    default:
      return state;
  }
};

export default notificationNumber;
