import {
  selectGifteeBoxTemplate,
  deselectGifteeBoxTemplate
} from '@console/actions/actions';
import GifteeBoxConfigsTable from '@console/components/project/giftConfig/APITable/GifteeBoxConfigsTable';
import { getConfidentialInformation } from '@console/selectors/projectDetail/confidentialInformation';
import { getGifteeBoxConfigs } from '@console/selectors/projectDetail/gifteeBoxConfigs';
import { connect } from 'react-redux';
import type { AppThunkDispatch } from '@console/actions/thunkType';
import type { AppState } from '@console/reducers';
import type { LocalGifteeBoxConfig } from '@console/reducers/projects';

const mapStateToProps = (state: AppState) => ({
  gifteeBoxConfigs: getGifteeBoxConfigs(state),
  confidentialInformation: getConfidentialInformation(state)
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  selectGifteeBoxTemplate: (
    gifteeBoxTemplate: LocalGifteeBoxConfig['gifteeBoxTemplate']
  ) => dispatch(selectGifteeBoxTemplate(gifteeBoxTemplate)),
  deselectGifteeBoxTemplate: () => dispatch(deselectGifteeBoxTemplate())
});

export type GifteeBoxConfigsTablePropsMappedFromState = ReturnType<
  typeof mapStateToProps
>;
export type GifteeBoxConfigsTablePropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GifteeBoxConfigsTable);
