import { AppActionTypes } from '../actions/actionType';
import type { AppAction } from '../actions/actionType';
import type { MarketingIndustries } from '@common/api/graphql/getMarketingIndustriesGql';

const marketingIndustries = (
  state = [],
  action: AppAction
): MarketingIndustries => {
  switch (action.type) {
    case AppActionTypes.GET_MARKETING_INDUSTRIES_SUCCESS:
    case AppActionTypes.GET_MASTER_DATA_SUCCESS:
      return action.marketingIndustries;
    default:
      return state;
  }
};

export default marketingIndustries;
