import { deleteApiAllowedIp } from '@common/api/graphql/deleteApiAllowedIpGql';
import { createMessage } from '@common/api/graphql/successMessage';
import {
  deleteApiAllowedIpStart,
  deleteApiAllowedIpSuccess,
  deleteApiAllowedIpFailure
} from '../actions';
import handleApiErrorIfNeeded from './handleApiErrorIfNeeded';
import type { AppThunkAction } from '../thunkType';
import type { ApiError } from '@common/api/graphql/apolloClient';
import type { DeleteApiAllowedIpResponse } from '@common/api/graphql/deleteApiAllowedIpGql';

export const deleteApiAllowedIpSuccessThunk =
  (response: DeleteApiAllowedIpResponse): AppThunkAction<Promise<void>> =>
  dispatch => {
    const apiAllowedIpUrlCode =
      response.data?.deleteApiAllowedIp.apiAllowedIp.urlCode;
    dispatch(
      deleteApiAllowedIpSuccess(
        createMessage('許可済みIPアドレスを削除'),
        apiAllowedIpUrlCode!
      )
    );
    return Promise.resolve();
  };

export const deleteApiAllowedIpFailureThunk =
  (error: ApiError): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(deleteApiAllowedIpFailure());
    return dispatch(handleApiErrorIfNeeded(error));
  };

export const deleteApiAllowedIpThunk =
  (
    projectUrlCode: UrlCode,
    apiAllowedIpUrlCode: UrlCode
  ): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(deleteApiAllowedIpStart());

    return deleteApiAllowedIp(projectUrlCode, apiAllowedIpUrlCode)
      .then((response: DeleteApiAllowedIpResponse) =>
        dispatch(deleteApiAllowedIpSuccessThunk(response))
      )
      .catch((error: ApiError) =>
        dispatch(deleteApiAllowedIpFailureThunk(error))
      );
  };
