import { getRoundPercentage } from '@common/lib/getPercentage';
import { createSelector } from 'reselect';
import type { AggregateGifteeBoxSelectedContentResult } from '@common/api/graphql/aggregateGifteeBoxSelectedContentPercentageGql';
import type { AppState } from '@console/reducers';
import type { LocalGifteeBoxTemplates } from '@console/reducers/gifteeBoxTemplates';

export const MAX_DISPLAY_COUNT = 10;

export const getAggregatedGifteeBoxSelectedContentResults = (
  state: AppState
): AggregateGifteeBoxSelectedContentResult[] =>
  state.aggregatedGifteeBoxSelectedContentResults;

export const getSortedAggregatedGifteeBoxSelectedContentResults =
  createSelector(
    [getAggregatedGifteeBoxSelectedContentResults],
    (
      aggregatedGifteeBoxSelectedContentResults
    ): AggregateGifteeBoxSelectedContentResult[] =>
      aggregatedGifteeBoxSelectedContentResults.sort((a, b) =>
        a.selectedPercentage < b.selectedPercentage ? 1 : -1
      )
  );

export const getGifteeBoxTemplates = (
  state: AppState
): LocalGifteeBoxTemplates => state.gifteeBoxTemplates;

export const getRemainingContentsSelectedPercentage = createSelector(
  [getAggregatedGifteeBoxSelectedContentResults],
  contentResult =>
    contentResult
      .slice(MAX_DISPLAY_COUNT, contentResult.length)
      .reduce((acc, value) => acc + value.selectedPercentage, 0)
);

const escapeForCSV = (str: string): string => `"${str.replace(/\"/g, '""')}"`;

export const getAggregatedGifteeBoxSelectedContentResultsForCsvExport =
  createSelector(
    [getSortedAggregatedGifteeBoxSelectedContentResults],
    (sortedAggregatedGifteeBoxSelectedContentResults): (string | number)[][] =>
      sortedAggregatedGifteeBoxSelectedContentResults.map(
        aggregatedGifteeBoxSelectedContentResult => [
          escapeForCSV(
            aggregatedGifteeBoxSelectedContentResult.content.brand.name
          ),
          escapeForCSV(
            aggregatedGifteeBoxSelectedContentResult.content.internalName
          ),
          getRoundPercentage(
            aggregatedGifteeBoxSelectedContentResult.selectedPercentage,
            1
          )
        ]
      )
  );
