import type { MutationErrors, MutationError } from './mutationErrorType';

const isMutationError = (arg: any): arg is MutationError =>
  arg !== undefined &&
  arg.hasOwnProperty('message') &&
  arg.hasOwnProperty('path') &&
  arg.hasOwnProperty('__typename');

export const isMutationErrors = (arg: any): arg is MutationErrors =>
  Array.isArray(arg) && isMutationError(arg[0]);
