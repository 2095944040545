import { gql } from '@apollo/client';

import { internalApiAppClient } from './apolloClient';
import { operationNames } from './operationNames';
import type { FetchResult } from '@apollo/client';

const getIssueCountsGql = gql`
  mutation ${operationNames.GET_ISSUE_COUNTS}(
    $projectUrlCode: String!
    $issueDateFrom: ISO8601DateTime!
    $issueDateTo: ISO8601DateTime!
  ) {
    aggregateIssueCount(
      input: {
        projectUrlCode: $projectUrlCode
        issueDateFrom: $issueDateFrom
        issueDateTo: $issueDateTo
      }
    ) {
      giftCardResults {
        giftCardConfigUrlCode
        count
      }
      gifteeBoxResults {
        gifteeBoxConfigUrlCode
        initialPoint
        count
      }
    }
  }
`;

export type IssueCountsParams = {
  issueDateFrom: string;
  issueDateTo: string;
  projectUrlCode: string;
};

export type GiftCardIssueCount = {
  giftCardConfigUrlCode: string;
  count: number;
};

export type GiftCardIssueCounts = GiftCardIssueCount[];

export type GifteeBoxIssueCount = {
  gifteeBoxConfigUrlCode: string;
  initialPoint: number;
  count: number;
};

export type GifteeBoxIssueCounts = GifteeBoxIssueCount[];

export type IssueCountResult = {
  giftCardResults: GiftCardIssueCounts;
  gifteeBoxResults: GifteeBoxIssueCounts;
};

export type IssueCountsResponse = FetchResult<{
  aggregateIssueCount: IssueCountResult;
}>;

export const getIssueCounts = (
  issueCountsParams: IssueCountsParams
): Promise<IssueCountsResponse> =>
  internalApiAppClient.mutate({
    mutation: getIssueCountsGql,
    variables: {
      ...issueCountsParams
    }
  });
