import { connect } from 'react-redux';
import { requestInvoicePdfUrlThunk } from '../../../actions/thunks/requestInvoicePdfUrl';
import Table from '../../../components/project/billing/Table';
import type {
  AppThunkDispatch,
  AppThunkAction
} from '../../../actions/thunkType';
import type { AppState } from '../../../reducers';

const requestInvoicePdfUrl =
  (urlCode: UrlCode): AppThunkAction<void> =>
  dispatch => {
    dispatch(requestInvoicePdfUrlThunk(urlCode))
      .then(downloadUrl => {
        if (downloadUrl) {
          location.href = downloadUrl;
        }
      })
      .catch(() => {});
  };

const mapStateToProps = (state: AppState) => ({});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  requestInvoicePdfUrl: (urlCode: UrlCode) =>
    dispatch(requestInvoicePdfUrl(urlCode))
});

export type TablePropsMappedFromState = ReturnType<typeof mapStateToProps>;
export type TablePropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(mapStateToProps, mapDispatchToProps)(Table);
