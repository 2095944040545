import { getLocalString } from '@common/lib/getLocalString';
import { getLocalePrice } from '@common/lib/getLocalePrice';
import { isGiftCardFixedTicketConfig } from '@console/common/project/giftCardTicketConfig';
import GiftCardPreviewModal from '@console/components/common/GiftCardPreviewModal';
import GifteeBoxTemplateBrief from '@console/components/common/GifteeBoxTemplateBrief';
import GiftCardTicketConfigsContents from '@console/components/project/common/GiftCardTicketConfigsContents';
import { PROJECT_TICKET_TYPE } from '@console/components/project/common/TicketTypeChip';
import TicketTypeChips from '@console/components/project/common/TicketTypeChips';
import GifteeBoxTemplateContentSelectionsModalContainer from '@console/containers/common/GifteeBoxTemplateContentSelectionsModalContainer';
import GiftCardPreviewContainer from '@console/containers/project/GiftCardPreviewContainer';
import {
  Typography,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Button
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { useState, useCallback } from 'react';
import GiftCardTicketConfigContents from '../common/GiftCardTicketConfigContents';
import TicketTypeChip, {
  getGiftCardTicketType
} from '../common/TicketTypeChip';
import Notes from './Notes';
import type {
  GcpTablePropsMappedFromState,
  GcpTablePropsMappedFromDispatch
} from '@console/containers/project/giftConfig/GcpTableContainer';
import type { LocalGifteeBoxConfig } from '@console/reducers/projects';
import type { GiftCardConfigForDisplay } from '@console/selectors/projectDetail/giftCardConfigs';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => ({
  tableContainer: {
    overflowX: 'scroll',
    whiteSpace: 'nowrap'
  },
  table: {
    textAlign: 'left',
    marginTop: theme.spacing(0.5)
  },
  tableCell: {
    padding: theme.spacing(2),
    verticalAlign: 'middle'
  },
  button: {
    margin: theme.spacing(),
    marginRight: 0,
    padding: '5px 8px'
  },
  notes: {
    marginTop: theme.spacing(2)
  }
});

type GcpTableProps = GcpTablePropsMappedFromState &
  GcpTablePropsMappedFromDispatch &
  WithStyles<typeof styles>;

const GcpTable: React.FC<GcpTableProps> = ({
  classes,
  giftCardConfigs,
  gifteeBoxConfigs,
  onClickGiftCardPreviewButton,
  selectGifteeBoxTemplate,
  deselectGifteeBoxTemplate
}) => {
  const [giftCardPreviewModalOpen, setGiftCardPreviewModalOpen] =
    useState(false);

  const openGiftCardPreviewModal = useCallback(() => {
    setGiftCardPreviewModalOpen(true);
  }, []);

  const closeGiftCardPreviewModal = useCallback(() => {
    setGiftCardPreviewModalOpen(false);
  }, []);

  const handleGiftCardPreviewButtonClick = useCallback(
    (giftCardConfig: GiftCardConfigForDisplay) => {
      onClickGiftCardPreviewButton(giftCardConfig);
      openGiftCardPreviewModal();
    },
    []
  );

  const handleGiftCardPreviewModalClose = useCallback(() => {
    closeGiftCardPreviewModal();
  }, []);

  const [
    gifteeBoxTemplateContentSelectionsModalOpen,
    setGifteeBoxTemplateContentSelectionsModalOpen
  ] = useState(false);

  const openGifteeBoxTemplateContentSelectionsModal = (
    gifteeBoxConfig: LocalGifteeBoxConfig
  ) => {
    selectGifteeBoxTemplate(gifteeBoxConfig.gifteeBoxTemplate);
    setGifteeBoxTemplateContentSelectionsModalOpen(true);
  };

  const closeGifteeBoxTemplateContentSelectionsModal = () => {
    setGifteeBoxTemplateContentSelectionsModalOpen(false);
    deselectGifteeBoxTemplate();
  };

  return (
    <div className={classes.tableContainer} data-cy="gcpTableContainer">
      <Table className={classes.table}>
        <TableHead data-cy="tableHeader">
          <TableRow>
            <TableCell className={classes.tableCell} align="center">
              ギフトのタイプ
            </TableCell>
            <TableCell className={classes.tableCell} align="left">
              ギフト・ブランド名
            </TableCell>
            <TableCell className={classes.tableCell} align="left">
              ギフトカード
            </TableCell>
            <TableCell className={classes.tableCell} align="right">
              単価
            </TableCell>
            <TableCell className={classes.tableCell} align="right">
              数量（見込み）
            </TableCell>
            <TableCell className={classes.tableCell} align="right">
              予定金額
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!giftCardConfigs
            ? null
            : giftCardConfigs.map((giftCardConfig, giftCardConfigIndex) => {
                const lastApiGiftCardConfig =
                  giftCardConfig.lastApiGiftCardIssueConfig;
                if (!!lastApiGiftCardConfig) {
                  return (
                    <TableRow
                      key={`gcpGiftCardIssueConfig-${giftCardConfigIndex}`}
                      data-cy={`apiGiftCardIssueConfigRow-${giftCardConfigIndex}`}
                    >
                      <TableCell
                        className={classes.tableCell}
                        align="center"
                        data-cy={`apiGiftCardIssueConfigTicketType-${giftCardConfigIndex}`}
                      >
                        <TicketTypeChips giftCardConfig={giftCardConfig} />
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        align="left"
                        data-cy={`apiGiftCardIssueConfigContent-${giftCardConfigIndex}`}
                      >
                        <GiftCardTicketConfigsContents
                          giftCardConfig={giftCardConfig}
                          imageDownloadable={true}
                        />
                      </TableCell>

                      <TableCell
                        className={classes.tableCell}
                        align="left"
                        data-cy={`apiGiftCardIssueConfigMessageCard-${giftCardConfigIndex}`}
                      >
                        {giftCardConfig.isGiftCardPreviewVisible ? (
                          <Button
                            color="primary"
                            variant="outlined"
                            className={classes.button}
                            onClick={() => {
                              handleGiftCardPreviewButtonClick(giftCardConfig);
                            }}
                          >
                            <Typography variant="body2">
                              プレビューを見る
                            </Typography>
                          </Button>
                        ) : (
                          '無し'
                        )}
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        align="right"
                        data-cy={`apiGiftCardIssueConfigContentCurrentPrice-${giftCardConfigIndex}`}
                      >
                        {getLocalePrice(giftCardConfig.unitPrice)}
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        align="right"
                        data-cy={`apiGiftCardIssueConfigEstimatedIssueAmount-${giftCardConfigIndex}`}
                      >
                        {lastApiGiftCardConfig.estimatedIssueAmount}
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        align="right"
                        data-cy={`apiGiftCardIssueConfigEstimatedTotal-${giftCardConfigIndex}`}
                      >
                        {getLocalePrice(
                          giftCardConfig.unitPrice *
                            lastApiGiftCardConfig.estimatedIssueAmount
                        )}
                      </TableCell>
                    </TableRow>
                  );
                }
              })}
          {!gifteeBoxConfigs
            ? null
            : gifteeBoxConfigs.map((gifteeBoxConfig, gifteeBoxConfigIndex) =>
                gifteeBoxConfig.apiGifteeBoxIssueConfigs.map(
                  apiGifteeBoxIssueConfig => (
                    <TableRow
                      key={`apiGifteeBoxIssueConfig-${apiGifteeBoxIssueConfig.urlCode}`}
                      data-cy={`apiGifteeBoxIssueConfigRow-${apiGifteeBoxIssueConfig.urlCode}`}
                    >
                      <TableCell
                        className={classes.tableCell}
                        align="center"
                        data-cy={`apiGifteeBoxIssueConfigTicketType-${apiGifteeBoxIssueConfig.urlCode}`}
                      >
                        <TicketTypeChip
                          ticketType={PROJECT_TICKET_TYPE.GIFTEE_BOX}
                        />
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        align="left"
                        data-cy={`gifteeBoxTemplateBrief-${apiGifteeBoxIssueConfig.urlCode}`}
                      >
                        <GifteeBoxTemplateBrief
                          gifteeBoxTemplateName={
                            gifteeBoxConfig.gifteeBoxTemplate.name
                          }
                          gifteeBoxTemplateImageUrl={
                            gifteeBoxConfig.gifteeBoxTemplate.imageUrl
                          }
                          gifteeBoxConfigUrlCode={gifteeBoxConfig.urlCode}
                          handleButtonClick={() =>
                            openGifteeBoxTemplateContentSelectionsModal(
                              gifteeBoxConfig
                            )
                          }
                          exchangeableEndLabel={
                            apiGifteeBoxIssueConfig.gifteeBoxIssueConfig
                              .exchangeableEndLabel
                          }
                          imageDownloadable={true}
                        />
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        align="left"
                        data-cy={`apiGifteeBoxIssueConfigPreview-${apiGifteeBoxIssueConfig.urlCode}`}
                      >
                        無し
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        align="right"
                        data-cy={`apiGifteeBoxIssueConfigIssueEstimateOptionsInitialPoints-${apiGifteeBoxIssueConfig.urlCode}`}
                      >
                        {apiGifteeBoxIssueConfig.issueEstimateOptions.map(
                          (issueEstimateOption, issueEstimateOptionIndex) => (
                            <Typography
                              variant="body2"
                              data-cy={`apiGifteeBoxIssueConfigIssueEstimateOptionsInitialPoint-${apiGifteeBoxIssueConfig.urlCode}-${issueEstimateOptionIndex}`}
                              key={`apiGifteeBoxIssueConfigIssueEstimateOptionsInitialPoint-${apiGifteeBoxIssueConfig.urlCode}-${issueEstimateOptionIndex}`}
                            >
                              {getLocalePrice(issueEstimateOption.initialPoint)}
                            </Typography>
                          )
                        )}
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        align="right"
                        data-cy={`apiGifteeBoxIssueConfigIssueEstimateOptionsIssueAmounts-${apiGifteeBoxIssueConfig.urlCode}`}
                      >
                        {apiGifteeBoxIssueConfig.issueEstimateOptions.map(
                          (issueEstimateOption, issueEstimateOptionIndex) => (
                            <Typography
                              variant="body2"
                              data-cy={`apiGifteeBoxIssueConfigIssueEstimateOptionsIssueAmount-${apiGifteeBoxIssueConfig.urlCode}-${issueEstimateOptionIndex}`}
                              key={`apiGifteeBoxIssueConfigIssueEstimateOptionsIssueAmount-${apiGifteeBoxIssueConfig.urlCode}-${issueEstimateOptionIndex}`}
                            >
                              {getLocalString(issueEstimateOption.issueAmount)}
                            </Typography>
                          )
                        )}
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        align="right"
                        data-cy={`apiGifteeBoxIssueConfigIssueEstimateOptionsTotalPrices-${apiGifteeBoxIssueConfig.urlCode}`}
                      >
                        {apiGifteeBoxIssueConfig.issueEstimateOptions.map(
                          (issueEstimateOption, issueEstimateOptionIndex) => (
                            <Typography
                              variant="body2"
                              data-cy={`apiGifteeBoxIssueConfigIssueEstimateOptionsTotalPrice-${apiGifteeBoxIssueConfig.urlCode}-${issueEstimateOptionIndex}`}
                              key={`apiGifteeBoxIssueConfigIssueEstimateOptionsTotalPrice-${apiGifteeBoxIssueConfig.urlCode}-${issueEstimateOptionIndex}`}
                            >
                              {getLocalePrice(
                                issueEstimateOption.issueAmount *
                                  issueEstimateOption.initialPoint
                              )}
                            </Typography>
                          )
                        )}
                      </TableCell>
                    </TableRow>
                  )
                )
              )}
        </TableBody>
      </Table>
      <div className={classes.notes}>
        <Notes />
      </div>
      <GifteeBoxTemplateContentSelectionsModalContainer
        isOpen={gifteeBoxTemplateContentSelectionsModalOpen}
        handleClose={closeGifteeBoxTemplateContentSelectionsModal}
      />
      <GiftCardPreviewModal
        isOpen={giftCardPreviewModalOpen}
        onCloseHandler={handleGiftCardPreviewModalClose}
      >
        <GiftCardPreviewContainer />
      </GiftCardPreviewModal>
    </div>
  );
};

export default withStyles(styles)(GcpTable);
