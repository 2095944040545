import { deleteProjectDraft } from '@common/api/graphql/deleteProjectDraftGql';
import { createMessage } from '@common/api/graphql/successMessage';
import {
  deleteProjectDraftStart,
  deleteProjectDraftSuccess,
  deleteProjectDraftFailure
} from '../actions';
import handleApiErrorIfNeeded from './handleApiErrorIfNeeded';
import type { AppThunkAction } from '../thunkType';
import type { ApiError } from '@common/api/graphql/apolloClient';
import type { DeleteProjectDraftResponse } from '@common/api/graphql/deleteProjectDraftGql';

export const deleteProjectDraftSuccessThunk =
  (
    deleteProjectDraftResponse: DeleteProjectDraftResponse
  ): AppThunkAction<Promise<void>> =>
  dispatch => {
    const { urlCode } = deleteProjectDraftResponse.data!.deleteProjectDraft;
    dispatch(
      deleteProjectDraftSuccess(
        urlCode,
        createMessage('一時保存中の案件を削除')
      )
    );
    return Promise.resolve();
  };

export const deleteProjectDraftFailureThunk =
  (error: ApiError): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(deleteProjectDraftFailure());
    return dispatch(handleApiErrorIfNeeded(error));
  };

export const deleteProjectDraftThunk =
  (projectDraftUrlCode: UrlCode): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(deleteProjectDraftStart());

    return deleteProjectDraft(projectDraftUrlCode)
      .then(response => dispatch(deleteProjectDraftSuccessThunk(response)))
      .catch((error: ApiError) =>
        dispatch(deleteProjectDraftFailureThunk(error))
      );
  };
