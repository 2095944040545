import { getProjectDetail } from '@common/api/graphql/getProjectDetailGql';
import {
  getProjectDetailStart,
  getProjectDetailSuccess,
  getProjectDetailFailure
} from '@console/actions/actions';
import { convertQueryProjectIntoLocalProject } from '@console/actions/thunks/converters/convertQueryProjectIntoLocalProject';
import handleApiErrorIfNeeded from '@console/actions/thunks/handleApiErrorIfNeeded';
import type { ApiError } from '@common/api/graphql/apolloClient';
import type { GetProjectDetailResponse } from '@common/api/graphql/getProjectDetailGql';
import type { AppThunkAction } from '@console/actions/thunkType';

// GraphQL schema 上、 BulkGiftCardIssueConfig.issueAmountRequest は nullable だが、
// issueAmountRequest.issueAmount が確定数量の編集時に必要なため、変換処理を行う。
export const getProjectDetailSuccessThunk =
  (response: GetProjectDetailResponse): AppThunkAction<Promise<void>> =>
  dispatch => {
    const { project } = response.data!;
    const projectWithIssueAmountRequest =
      convertQueryProjectIntoLocalProject(project);
    dispatch(getProjectDetailSuccess(projectWithIssueAmountRequest));
    return Promise.resolve();
  };

export const getProjectDetailFailureThunk =
  (error: ApiError): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(getProjectDetailFailure());
    return dispatch(handleApiErrorIfNeeded(error));
  };

export const getProjectDetailThunk =
  (projectUrlCode: UrlCode): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(getProjectDetailStart());
    return getProjectDetail(projectUrlCode)
      .then(response => dispatch(getProjectDetailSuccessThunk(response)))
      .catch(error => dispatch(getProjectDetailFailureThunk(error)));
  };
