import { Typography, Divider, Grid } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    authenticationErrorContainer: {
      width: '100%',
      height: '100%',
      padding: theme.spacing(3),
      paddingTop: '20%'
    },
    divider: {
      width: 200
    },
    statusCode: {
      paddingBottom: theme.spacing(2)
    },
    message: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing()
    }
  });

type AuthenticationErrorProps = WithStyles<typeof styles>;

const AuthenticationError: React.FC<AuthenticationErrorProps> = ({
  classes
}) => (
  <Grid
    className={classes.authenticationErrorContainer}
    container
    direction="column"
    justifyContent="center"
    alignItems="center"
    data-cy="authenticationError"
  >
    <Typography variant="h6" color="textPrimary" className={classes.message}>
      エラーが発生しました。
    </Typography>
    <Divider className={classes.divider} />
    <Typography variant="body1" color="textPrimary" className={classes.message}>
      大変申し訳ありません。お使いの端末に対応していない可能性があります。
      引き続きご利用いただくには、最新のOSに更新してください。
    </Typography>
  </Grid>
);
export default withStyles(styles)(AuthenticationError);
