import { SUCCESS, ERROR } from '../../../common/components/SnackbarMessage';
import { AppActionTypes } from '../actions/actionType';
import type { SnackbarMessage } from '../../../common/components/SnackbarMessage';
import type { AppAction } from '../actions/actionType';

const initialSnackbar: SnackbarMessage = {
  isOpen: false,
  status: undefined,
  content: ''
};

const snackbarMessage = (
  state = initialSnackbar,
  action: AppAction
): SnackbarMessage => {
  switch (action.type) {
    case AppActionTypes.CREATE_PROJECT_SUCCESS:
    case AppActionTypes.REQUEST_GIFT_CARD_ISSUE_AMOUNT_SUCCESS:
    case AppActionTypes.UPDATE_DISTRIBUTION_PARTNER_USER_SUCCESS:
    case AppActionTypes.UPDATE_API_ALLOWED_IP_SUCCESS:
    case AppActionTypes.DELETE_API_ALLOWED_IP_SUCCESS:
    case AppActionTypes.ADD_API_ALLOWED_IP_SUCCESS:
    case AppActionTypes.CREATE_SAMPLE_PROJECT_SUCCESS:
    case AppActionTypes.DELETE_PROJECT_DRAFT_SUCCESS:
    case AppActionTypes.COPY_PROJECT_TO_PROJECT_DRAFT_SUCCESS:
    case AppActionTypes.REQUEST_GIFTEE_BOX_ISSUE_AMOUNT_SUCCESS:
    case AppActionTypes.ADD_BULK_GIFT_CARD_ISSUE_CONFIGS_SUCCESS:
    case AppActionTypes.ADD_BULK_GIFTEE_BOX_ISSUE_CONFIGS_SUCCESS:
      return {
        isOpen: true,
        status: SUCCESS,
        content: action.snackbarMessageContent
      };
    case AppActionTypes.VALIDATE_PROJECT_DRAFT_FAILURE:
    case AppActionTypes.COPY_PROJECT_TO_PROJECT_DRAFT_FAILURE:
      return {
        isOpen: true,
        status: ERROR,
        content: action.snackbarMessageContent
      };
    case AppActionTypes.GRAPHQL_FAILURE:
      return {
        isOpen: true,
        status: ERROR,
        content: action.errors[0] ?? ''
      };
    case AppActionTypes.INITIALIZE_SNACKBAR_MESSAGE:
      return initialSnackbar;
    default:
      return state;
  }
};

export default snackbarMessage;
