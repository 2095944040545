import { AppActionTypes } from '@console/actions/actionType';
import type { QueryApiAllowedIps } from '@common/api/graphql/getProjectsGql';
import type { AppAction } from '@console/actions/actionType';

const initialState: QueryApiAllowedIps = [];

const apiAllowedIps = (
  state = initialState,
  action: AppAction
): QueryApiAllowedIps => {
  switch (action.type) {
    case AppActionTypes.OPEN_API_IP_RESTRICTION:
      return action.selectedApiAllowedIps;
    case AppActionTypes.UPDATE_EDIT_API_ALLOWED_IP_ADDRESS:
      return state.map((apiAllowedIp, index) => {
        if (action.apiAllowedIpIndex === index) {
          return {
            ...apiAllowedIp,
            ip: action.ipAddress
          };
        }
        return apiAllowedIp;
      });
    case AppActionTypes.DELETE_API_ALLOWED_IP_SUCCESS:
      return state.filter(
        apiAllowedIp => apiAllowedIp.urlCode !== action.apiAllowedIpUrlCode
      );
    case AppActionTypes.ADD_API_ALLOWED_IP_SUCCESS:
      return [...state, action.apiAllowedIp];
    default:
      return state;
  }
};

export default apiAllowedIps;
