import { scroller } from 'react-scroll';
import type { RequestCode } from '@common/api/graphql/postMessageThreadMessage';

const scrollOption = {
  duration: 300,
  delay: 100,
  smooth: true,
  containerId: 'messageThreadContainer',
  offset: 0
};

export const handleMessageThreadScroll = (requestCode: RequestCode) => {
  scroller.scrollTo(requestCode, scrollOption);
};
