import { gql } from '@apollo/client';

import { internalApiAppClient } from './apolloClient';
import { operationNames } from './index';
import type { MutationErrors } from './mutationErrorType';
import type { FetchResult } from '@apollo/client';

const updateDefaultBillingAddressGql = gql`
  mutation ${operationNames.UPDATE_DEFAULT_BILLING_ADDRESS}(
    $city: String!
    $address: String!
    $division: String!
    $name: String!
    $optionalAddress: String!
    $postalCode: String!
    $prefectureId: Int!
  ) {
    updateDefaultBillingAddress(
      input: {
        city: $city
        address: $address
        division: $division
        name: $name
        optionalAddress: $optionalAddress
        postalCode: $postalCode
        prefectureId: $prefectureId
      }
    ) {
      errors {
        __typename
        message
        path
      }
    }
  }
`;

export type UpdateDefaultBillingAddressResponse = FetchResult<{
  updateDefaultBillingAddress: {
    errors: MutationErrors;
  };
}>;

export type UpdateDefaultBillingAddressParams = {
  city: string;
  address: string;
  division: string;
  name: string;
  optionalAddress: string;
  postalCode: string;
  prefectureId: number;
};

export const hasUpdateDefaultBillingAddressDistributionPartnerProp = (
  response: UpdateDefaultBillingAddressResponse
) =>
  response.data !== undefined &&
  response.data!.updateDefaultBillingAddress !== undefined;

export const updateDefaultBillingAddress = (
  updateDefaultBillingAddressParams: UpdateDefaultBillingAddressParams
): Promise<UpdateDefaultBillingAddressResponse> =>
  internalApiAppClient.mutate({
    mutation: updateDefaultBillingAddressGql,
    variables: {
      ...updateDefaultBillingAddressParams
    }
  });
