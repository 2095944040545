import { isHostCompanyOther } from '@console/reducers/projectJson/hostCompany';
import type { HostCompanyOption } from '@common/api/graphql/createProjectGql';
import type { ProjectJson } from '@console/reducers/selectedProjectDraft';

export const getHostCompanyOption = (
  hostCompany: ProjectJson['hostCompany']
): HostCompanyOption | undefined =>
  isHostCompanyOther(hostCompany)
    ? {
        name: hostCompany.name
      }
    : undefined;
