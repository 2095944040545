import SnackbarMessage from '@common/components/SnackbarMessage';
import CommonErrorContainer from '@gift_cards/containers/CommonErrorContainer';
import CssBaseline from '@mui/material/CssBaseline';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { useState, useEffect, useCallback } from 'react';
import Loader from '../../../common/components/Loader';
import GiftCard from '../../../common/gift_card/GiftCard';
import type {
  AppPropsMappedFromState,
  AppPropsMappedFromDispatch
} from '../containers/AppContainer';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';
import type { FC } from 'react';

export const GIFT_WALLET_FLOATING_BUTTON_VISIBILE_BORDER = 100;

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column'
    }
  });

export type AppProps = AppPropsMappedFromState &
  AppPropsMappedFromDispatch &
  WithStyles<typeof styles>;

const App: FC<AppProps> = ({
  classes,
  header,
  footer,
  messageCard,
  giftCardTickets,
  giftWalletStorable,
  isLoading,
  hasCommonError,
  chooseGiftCardTicketChoice,
  snackbarMessage,
  handleCloseSnackbar,
  handleGiftWalletButtonClick,
  getGiftCard
}) => {
  const [
    isGiftWalletFloatingButtonVisible,
    setIsGiftWalletFloatingButtonVisible
  ] = useState(false);

  useEffect(() => {
    const { urlCode } = gon;
    getGiftCard(urlCode);

    window.addEventListener('scroll', () =>
      showGiftWalletFloatingButtonVisibleIfNeeded()
    );
  }, []);

  const showGiftWalletFloatingButtonVisibleIfNeeded = useCallback(() => {
    const currentScrollPosition = getCurrentScrollPosition();
    const isGiftWalletFloatingButtonVisible =
      currentScrollPosition < GIFT_WALLET_FLOATING_BUTTON_VISIBILE_BORDER;

    setIsGiftWalletFloatingButtonVisible(isGiftWalletFloatingButtonVisible);
  }, []);

  const getCurrentScrollPosition = useCallback(
    () =>
      // ref: https://javascript.info/size-and-scroll-window#page-scroll
      Math.max(
        window.pageYOffset,
        document.documentElement.scrollTop,
        document.body.scrollTop
      ),
    []
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Loader isLoading={isLoading} />
      {hasCommonError ? (
        <CommonErrorContainer />
      ) : !!header ? (
        <>
          <SnackbarMessage
            snackbarMessage={snackbarMessage}
            handleClose={handleCloseSnackbar}
            autoHideDuration={3000}
          />
          <GiftCard
            header={header!}
            footer={footer!}
            messageCard={messageCard}
            giftCardTickets={giftCardTickets!}
            giftWalletStorable={giftWalletStorable}
            handleConfirmTicketChoice={chooseGiftCardTicketChoice}
            handleGiftWalletButtonClick={handleGiftWalletButtonClick}
            isGiftWalletFloatingButtonVisible={
              isGiftWalletFloatingButtonVisible
            }
          />
        </>
      ) : null}
    </div>
  );
};

export default withStyles(styles)(App);
