import { getProjectBulkDeliverables } from '@common/api/graphql/getProjectBulkDeliverablesGql';
import {
  getProjectInitialBulkDeliverablesStart,
  getProjectInitialBulkDeliverablesSuccess,
  getProjectInitialBulkDeliverablesFailure
} from '../actions';
import handleApiErrorIfNeeded from './handleApiErrorIfNeeded';
import type { AppThunkAction } from '../thunkType';
import type { ApiError } from '@common/api/graphql/apolloClient';
import type { GetProjectBulkDeliverablesResponse } from '@common/api/graphql/getProjectBulkDeliverablesGql';

export const getProjectInitialBulkDeliverablesSuccessThunk =
  (
    response: GetProjectBulkDeliverablesResponse
  ): AppThunkAction<Promise<void>> =>
  dispatch => {
    const { bulkDeliverableConnection } =
      response.data!.project.confidentialInformation;

    dispatch(
      getProjectInitialBulkDeliverablesSuccess(bulkDeliverableConnection)
    );
    return Promise.resolve();
  };

export const getProjectInitialBulkDeliverablesFailureThunk =
  (error: ApiError): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(getProjectInitialBulkDeliverablesFailure());
    return dispatch(handleApiErrorIfNeeded(error));
  };

export const getProjectInitialBulkDeliverablesThunk =
  (projectUrlCode: UrlCode): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(getProjectInitialBulkDeliverablesStart());

    return getProjectBulkDeliverables({
      urlCode: projectUrlCode
    })
      .then((response: GetProjectBulkDeliverablesResponse) =>
        dispatch(getProjectInitialBulkDeliverablesSuccessThunk(response))
      )
      .catch(error =>
        dispatch(getProjectInitialBulkDeliverablesFailureThunk(error))
      );
  };
