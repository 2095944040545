import { AppActionTypes } from '../../actions/actionType';
import type { AppAction } from '../../actions/actionType';

export type CampaignSolutionSystem = {
  urlCode: string;
};

export const initialStateOfCampaignSolutionSystem = {
  urlCode: ''
};

export const solutionSystem = (
  state = initialStateOfCampaignSolutionSystem,
  action: AppAction
): CampaignSolutionSystem => {
  switch (action.type) {
    case AppActionTypes.ADD_PROJECT_DRAFT:
    case AppActionTypes.COPY_PROJECT_TO_PROJECT_DRAFT_FAILURE:
      return initialStateOfCampaignSolutionSystem;
    case AppActionTypes.SELECT_PROJECT_DRAFT:
      return action.projectDraft.projectJson.campaignSolutionSystem;
    case AppActionTypes.UPDATE_CAMPAIGN_SOLUTION_SYSTEM_API_BULK:
    case AppActionTypes.UPDATE_CAMPAIGN_SOLUTION_SYSTEM_GCP:
      return {
        urlCode: action.campaignSolutionSystem
      };
    case AppActionTypes.UPDATE_CAMPAIGN_SOLUTION_SYSTEM_GCP_SELECTED:
      return {
        urlCode: ''
      };
    case AppActionTypes.EXCLUDE_INVALID_SOlUTION_SYSTEM:
      return initialStateOfCampaignSolutionSystem;
    case AppActionTypes.COPY_PROJECT_TO_PROJECT_DRAFT:
      return { urlCode: action.project.solutionSystem.urlCode };
    default:
      return state;
  }
};

export default solutionSystem;
