import { connect } from 'react-redux';
import {
  updateDistributionPartnerUserName,
  updateDistributionPartnerUserDivision,
  updateDistributionPartnerUserPhoneNumber
} from '../../actions/actions';
import { getLoginUserThunk } from '../../actions/thunks/getLoginUser';
import { updateDistributionPartnerUserThunk } from '../../actions/thunks/updateDistributionPartnerUser';
import { AccountContents } from '../../components/MainContents';
import DistributionPartnerUserEdit from '../../components/account/DistributionPartnerUserEdit';
import type { AppThunkDispatch, AppThunkAction } from '../../actions/thunkType';
import type { AppState } from '../../reducers';
import type { NavigateFunction } from 'react-router-dom';

const mapStateToProps = (state: AppState) => ({
  distributionPartnerUser: state.edits.distributionPartnerUser,
  errors: state.errors
});

const onClickUpdateDistributionPartnerUser =
  (navigate: NavigateFunction): AppThunkAction<void> =>
  (dispatch, getState) => {
    const { urlCode } = getState().distributionPartnerUser;
    dispatch(updateDistributionPartnerUserThunk())
      .then(() => {
        dispatch(getLoginUserThunk());
        navigate(`/console/${AccountContents.USERS}/${urlCode}`);
      })
      .catch(() => {});
  };

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  onChangeDistributionPartnerUserName: (event: GenericChangeEvent<string>) =>
    dispatch(updateDistributionPartnerUserName(event.target.value)),
  onChangeDistributionPartnerUserDivision: (
    event: GenericChangeEvent<string>
  ) => dispatch(updateDistributionPartnerUserDivision(event.target.value)),
  onChangeDistributionPartnerUserPhoneNumber: (
    event: GenericChangeEvent<string>
  ) => dispatch(updateDistributionPartnerUserPhoneNumber(event.target.value)),
  onClickUpdateDistributionPartnerUser: (navigate: NavigateFunction) =>
    dispatch(onClickUpdateDistributionPartnerUser(navigate))
});

export type DistributionPartnerUserEditPropsMappedFromState = ReturnType<
  typeof mapStateToProps
>;
export type DistributionPartnerUserEditPropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DistributionPartnerUserEdit);
