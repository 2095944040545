import { AppActionTypes } from '../../actions/actionType';
import type { AppAction } from '../../actions/actionType';

export type AdvertisingPeriod = {
  startDateOfAd: string | null;
  endDateOfAd: string | null;
};

export const initialStateOfAdvertisingPeriod = {
  startDateOfAd: null,
  endDateOfAd: null
};

export const advertisingPeriod = (
  state = initialStateOfAdvertisingPeriod,
  action: AppAction
): AdvertisingPeriod => {
  switch (action.type) {
    case AppActionTypes.ADD_PROJECT_DRAFT:
    case AppActionTypes.COPY_PROJECT_TO_PROJECT_DRAFT:
    case AppActionTypes.COPY_PROJECT_TO_PROJECT_DRAFT_FAILURE:
      return initialStateOfAdvertisingPeriod;
    case AppActionTypes.SELECT_PROJECT_DRAFT:
      return action.projectDraft.projectJson.advertisingPeriod;
    case AppActionTypes.UPDATE_START_DATE_OF_AD:
      return {
        ...state,
        startDateOfAd: action.startDateOfAd
          ? action.startDateOfAd.format('YYYY-MM-DD')
          : null
      };
    case AppActionTypes.UPDATE_END_DATE_OF_AD:
      return {
        ...state,
        endDateOfAd: action.endDateOfAd
          ? action.endDateOfAd.format('YYYY-MM-DD')
          : null
      };
    default:
      return state;
  }
};

export default advertisingPeriod;
