import {
  faFilePdf,
  faFilePowerpoint,
  faFileExcel,
  faFileCsv,
  faFile,
  faFileImage
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import withStyles from '@mui/styles/withStyles';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => ({
  pdfIcon: {
    color: theme.palette.icon.pdf
  },
  powerpointIcon: {
    color: theme.palette.icon.powerPoint
  },
  excelIcon: {
    color: theme.palette.icon.excel
  },
  csvIcon: {
    color: theme.palette.icon.csv
  },
  fileIcon: {
    color: theme.palette.icon.file
  }
});

type ownProps = { filename: string };
type fileIconProps = WithStyles<typeof styles> & ownProps;

const FileIcon: React.FC<fileIconProps> = ({ filename, classes }) => {
  const extention = filename.split('.').pop();
  switch (extention) {
    case 'pdf':
      return (
        <FontAwesomeIcon
          icon={faFilePdf}
          size="2x"
          className={classes.pdfIcon}
        />
      );
    case 'pptx':
    case 'ppt':
    case 'pptm':
      return (
        <FontAwesomeIcon
          icon={faFilePowerpoint}
          size="2x"
          className={classes.powerpointIcon}
        />
      );
    case 'xlsx':
      return (
        <FontAwesomeIcon
          icon={faFileExcel}
          size="2x"
          className={classes.excelIcon}
        />
      );
    case 'csv':
      return (
        <FontAwesomeIcon
          icon={faFileCsv}
          size="2x"
          className={classes.csvIcon}
        />
      );
    case 'png':
    case 'jpeg':
    case 'jpg':
      return (
        <FontAwesomeIcon
          icon={faFileImage}
          size="2x"
          className={classes.fileIcon}
        />
      );
    default:
      return (
        <FontAwesomeIcon icon={faFile} size="2x" className={classes.fileIcon} />
      );
  }
};

export default withStyles(styles)(FileIcon);
