import { uploadFile } from '../../../../common/api/graphql/uploadFileGql';
import {
  uploadFileStart,
  uploadFileSuccess,
  uploadFileFailure
} from '../actions';
import handleApiErrorIfNeeded from './handleApiErrorIfNeeded';
import type { ApiError } from '../../../../common/api/graphql/apolloClient';
import type {
  UploadFileResponse,
  UploadedFileUrlCode
} from '../../../../common/api/graphql/uploadFileGql';
import type { AppThunkAction } from '../thunkType';

export const uploadFileSuccessThunk =
  (
    response: UploadFileResponse
  ): AppThunkAction<Promise<UploadedFileUrlCode>> =>
  dispatch => {
    dispatch(uploadFileSuccess());
    return Promise.resolve(response.data!.uploadFile.uploadedFile.urlCode);
  };

export const uploadFileFailureThunk =
  (error: ApiError): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(uploadFileFailure());
    return dispatch(handleApiErrorIfNeeded(error));
  };

/**
 *
 * @param {File} file
 * File is a type that is defined by Web API.
 * cf. https://developer.mozilla.org/ja/docs/Web/API/File
 */

export const uploadFileThunk =
  (file: File): AppThunkAction<Promise<UploadedFileUrlCode | void>> =>
  dispatch => {
    dispatch(uploadFileStart());
    return uploadFile(file)
      .then((response: UploadFileResponse) =>
        dispatch(uploadFileSuccessThunk(response))
      )
      .catch(error => dispatch(uploadFileFailureThunk(error)));
  };
