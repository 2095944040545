import { validateMessageCardConfig } from '@console/actions/actions';
import { projectDraftMessageCardConfigHasValidationErrors } from '@console/middleware/validator/projectDraft/giftConfigs';
import type { AppThunkAction } from '@console/actions/thunkType';

export const validateMessageCardConfigThunk =
  (): AppThunkAction<Promise<void>> => (dispatch, getState) => {
    const { projectJson } = getState().selectedProjectDraft;
    dispatch(validateMessageCardConfig(projectJson));

    const { errors } = getState();
    const { selectedGiftConfigIndex } = projectJson;
    if (
      projectDraftMessageCardConfigHasValidationErrors(
        errors,
        selectedGiftConfigIndex
      )
    ) {
      return Promise.reject();
    }
    return Promise.resolve();
  };
