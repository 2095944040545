import { createMessage } from '@common/api/graphql/successMessage';
import { updateApiAllowedIp } from '@common/api/graphql/updateApiAllowedIpGql';
import {
  updateApiAllowedIpStart,
  updateApiAllowedIpSuccess,
  updateApiAllowedIpFailure
} from '../actions';
import handleApiErrorIfNeeded from './handleApiErrorIfNeeded';
import type { AppThunkAction } from '../thunkType';
import type { ApiError } from '@common/api/graphql/apolloClient';
import type { QueryApiAllowedIp } from '@common/api/graphql/getProjectsGql';
import type { UpdateApiAllowedIpResponse } from '@common/api/graphql/updateApiAllowedIpGql';

export const updateApiAllowedIpSuccessThunk =
  (response: UpdateApiAllowedIpResponse): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(
      updateApiAllowedIpSuccess(createMessage('許可済みIPアドレスを更新'))
    );
    return Promise.resolve();
  };

export const updateApiAllowedIpFailureThunk =
  (error: ApiError): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(updateApiAllowedIpFailure());
    return dispatch(handleApiErrorIfNeeded(error));
  };

export const updateApiAllowedIpThunk =
  (
    projectUrlCode: UrlCode,
    apiAllowedIp: QueryApiAllowedIp
  ): AppThunkAction<Promise<void>> =>
  (dispatch, getState) => {
    dispatch(updateApiAllowedIpStart());

    return updateApiAllowedIp(projectUrlCode, apiAllowedIp)
      .then((response: UpdateApiAllowedIpResponse) =>
        dispatch(updateApiAllowedIpSuccessThunk(response))
      )
      .catch((error: ApiError) =>
        dispatch(updateApiAllowedIpFailureThunk(error))
      );
  };
