import { gql } from '@apollo/client';

import { internalApiAppClient } from './apolloClient';
import { operationNames } from './operationNames';
import type { FetchResult } from '@apollo/client';

const getDefaultGiftCardConfigGql = gql`
  query ${operationNames.GET_DEFAULT_GIFT_CARD_CONFIG}{
    defaultGiftCardConfig {
      header {
        imageUrl
      }
      footer {
        imageUrl
        description
      }
    }
  }
`;

interface Header {
  imageUrl: string;
}

interface Footer {
  imageUrl: string;
  description: string;
}

export interface DefaultGiftCardConfig {
  header: Header;
  footer: Footer;
}

export type GetDefaultGiftCardConfigResponse = FetchResult<{
  defaultGiftCardConfig: DefaultGiftCardConfig;
}>;

export const getDefaultGiftCardConfig =
  (): Promise<GetDefaultGiftCardConfigResponse> =>
    internalApiAppClient.query({
      query: getDefaultGiftCardConfigGql
    });
