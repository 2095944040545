import { gql } from '@apollo/client';

import { internalApiAppClient } from './apolloClient';
import { operationNames } from './operationNames';
import type { DistributionPartner } from './getLoginUserGql';
import type { TextContent } from './postMessageThreadMessage';
import type { UploadedFiles } from './uploadFileGql';
import type { FetchResult } from '@apollo/client';

const getMessageThreadGql = gql`
  query ${operationNames.GET_MESSAGE_THREAD}($urlCode: String!, $last: Int, $before: String) {
    messageThread(urlCode: $urlCode) {
      messages(last: $last, before: $before) {
        nodes {
          requestCode
          postedAt
          text {
            content
          }
          files {
            urlCode
            name
            size
          }
          contributor {
            __typename
            ... on DistributionPartnerContributor {
              name
              iconUrl
              distributionPartner {
                name
              }
            }
            ... on ProjectManagementContributor {
              name
              iconUrl
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
    }
  }
`;

export interface MessageThread {
  messages: Messages;
}

interface Messages {
  nodes: Nodes;
  pageInfo: PageInfo;
}

export type Nodes = Node[];
export interface Node {
  requestCode: string;
  postedAt: string;
  text: Text;
  files: UploadedFiles;
  contributor: Contributor;
}

type Text = {
  content: TextContent;
} | null;

type Contributor =
  | DistributionPartnerContributor
  | ProjectManagementContributor;

export interface DistributionPartnerContributor extends ContributorBase {
  distributionPartner: DistributionPartner;
}

interface ProjectManagementContributor extends ContributorBase {}

export interface ContributorBase {
  __typename: ContributorTypeName;
  name: string;
  iconUrl: string;
}

export type ContributorTypeName =
  | 'DistributionPartnerContributor'
  | 'ProjectManagementContributor';

export interface PageInfo {
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
}

export type GetMessageThreadResponse = FetchResult<{
  messageThread: MessageThread;
}>;

export type MessageThreadUrlCode = string;

export const getMessageThread = (
  urlCode: MessageThreadUrlCode,
  last?: number | 10,
  before?: string | null
): Promise<GetMessageThreadResponse> =>
  internalApiAppClient.query({
    query: getMessageThreadGql,
    variables: {
      urlCode,
      last,
      before
    }
  });
