import moment from '@common/lib/moment';
import type { MutationProject } from '@common/api/graphql/createProjectGql';
import type { ProjectJson } from '@console/reducers/selectedProjectDraft';

export const getGiftDistributionPeriod = (
  giftDistributionPeriod: ProjectJson['giftDistributionPeriod']
): MutationProject['giftDistributionPeriod'] => ({
  beginAt: giftDistributionPeriod.startDateOfDistribution || '',
  endAt: giftDistributionPeriod.endDateOfDistribution
    ? moment(giftDistributionPeriod.endDateOfDistribution)
        .add(1, 'd')
        .toISOString(true)
    : ''
});
