import { getClaimEstimate } from '@common/api/graphql/getClaimEstimate';
import {
  getBulkIssueConfigsClaimEstimateStart,
  getBulkIssueConfigsClaimEstimateSuccess,
  getBulkIssueConfigsClaimEstimateFailure
} from '../actions';
import handleApiErrorIfNeeded from './handleApiErrorIfNeeded';
import type { AppThunkAction } from '../thunkType';
import type { ApiError } from '@common/api/graphql/apolloClient';
import type { GetClaimEstimateResponse } from '@common/api/graphql/getClaimEstimate';

export const getBulkIssueConfigsClaimEstimateSuccessThunk =
  (response: GetClaimEstimateResponse): AppThunkAction<Promise<void>> =>
  dispatch => {
    const claimEstimate = response.data!.claimEstimate;
    dispatch(getBulkIssueConfigsClaimEstimateSuccess(claimEstimate));
    return Promise.resolve();
  };

export const getBulkIssueConfigsClaimEstimateFailureThunk =
  (error: ApiError): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(getBulkIssueConfigsClaimEstimateFailure());
    return dispatch(handleApiErrorIfNeeded(error));
  };

export const getBulkIssueConfigsClaimEstimateThunk =
  (bulkIssueConfigsTotal: number): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(getBulkIssueConfigsClaimEstimateStart());
    return getClaimEstimate(bulkIssueConfigsTotal)
      .then((response: GetClaimEstimateResponse) =>
        dispatch(getBulkIssueConfigsClaimEstimateSuccessThunk(response))
      )
      .catch(error =>
        dispatch(getBulkIssueConfigsClaimEstimateFailureThunk(error))
      );
  };
