import {
  addGiftConfigSelectedContents,
  removeGiftConfigSelectedContents
} from '../actions';
import type { Content } from '../../../../common/api/graphql/getContentsGql';
import type { AppThunkAction } from '../thunkType';

export const toggleFixedContentThunk =
  (content: Content): AppThunkAction<void> =>
  async (dispatch, getState) => {
    const selectedGiftConfigIndex =
      getState().selectedProjectDraft.projectJson.selectedGiftConfigIndex;
    const selectedContents =
      getState().selectedProjectDraft.projectJson.giftConfigs[
        selectedGiftConfigIndex
      ].selectedContents;

    // selectedContents が空なら、クリックしたギフトを追加する
    if (selectedContents.length === 0) {
      return dispatch(
        addGiftConfigSelectedContents(content, selectedGiftConfigIndex)
      );
    }

    const firstSelectedContent = getState().contents.find(
      content => content.urlCode === selectedContents[0]
    );
    if (!!firstSelectedContent) {
      await dispatch(
        removeGiftConfigSelectedContents(
          firstSelectedContent,
          selectedGiftConfigIndex
        )
      );
    }
    const isClickedContentSelected = selectedContents.includes(content.urlCode);
    if (!isClickedContentSelected) {
      // クリックしたギフトが selectedContents に含まれていなければ、add
      await dispatch(
        addGiftConfigSelectedContents(content, selectedGiftConfigIndex)
      );
    }
  };
