import { gql } from '@apollo/client';

import { internalApiAppClient } from './apolloClient';
import { operationNames } from './index';
import type { FetchResult } from '@apollo/client';

const requestMessageThreadFileUrlGql = gql`
  mutation ${operationNames.REQUEST_MESSAGE_THREAD_FILE_URL}($urlCode: String!) {
    requestMessageThreadFileUrl(input: { urlCode: $urlCode }) {
      downloadUrl
    }
  }
`;

export type DownloadUrl = string;

export type RequestMessageThreadFileUrlResponse = FetchResult<{
  requestMessageThreadFileUrl: {
    downloadUrl: DownloadUrl;
  };
}>;

export const requestMessageThreadFileUrl = (
  urlCode: string
): Promise<RequestMessageThreadFileUrlResponse> =>
  internalApiAppClient.mutate({
    mutation: requestMessageThreadFileUrlGql,
    variables: {
      urlCode
    }
  });
