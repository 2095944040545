import { AppActionTypes } from '../../actions/actionType';
import type { AppAction } from '../../actions/actionType';
import type { GiftDistributionChannelCategory } from '@common/api/graphql/getGiftDistributionChannelCategoriesGql';

export type CampaignGiftDistributionChannels =
  GiftDistributionChannelCategory['urlCode'][];

export const initialStateOfCampaignGiftDistributionChannels: CampaignGiftDistributionChannels =
  [];

export const campaignGiftDistributionChannels = (
  state = initialStateOfCampaignGiftDistributionChannels,
  action: AppAction
): CampaignGiftDistributionChannels => {
  switch (action.type) {
    case AppActionTypes.ADD_PROJECT_DRAFT:
    case AppActionTypes.COPY_PROJECT_TO_PROJECT_DRAFT_FAILURE:
      return initialStateOfCampaignGiftDistributionChannels;
    case AppActionTypes.SELECT_PROJECT_DRAFT:
      return action.projectDraft.projectJson.campaignGiftDistributionChannels;
    case AppActionTypes.TOGGLE_CAMPAIGN_DISTRIBUTION_CHANNEL:
      const hasUrlCode = state.includes(action.urlCode);
      if (hasUrlCode) {
        return state.filter(
          giftDistributionChannel => giftDistributionChannel !== action.urlCode
        );
      }
      return [...state, action.urlCode];
    case AppActionTypes.EXCLUDE_INVALID_GIFT_DISTRIBUTION_CHANNEL_CATEGORIES:
      return action.excludedGiftDistributionChannelCategories;
    case AppActionTypes.COPY_PROJECT_TO_PROJECT_DRAFT:
      return action.project.projectGiftDistributionChannels.map(
        projectGiftDistributionChannel => projectGiftDistributionChannel.urlCode
      );
    default:
      return state;
  }
};

export default campaignGiftDistributionChannels;
