import Grid from '@mui/material/Grid';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { Component } from 'react';
import Choices from './Choices';
import GiftCardChooseModal from './GiftCardChooseModal';
import GiftCardConfirmModal from './GiftCardConfirmModal';
import GiftCardFloatingButton from './GiftCardFloatingButton';
import GiftCardFooter from './GiftCardFooter';
import GiftCardHeader from './GiftCardHeader';
import GiftCardMessageCard from './GiftCardMessageCard';
import GiftCardTickets from './GiftCardTickets';
import type {
  IsChoosingModalOpen,
  IsChoosingConfirmModalOpen,
  SelectedChoosableTicket,
  SelectedChoice
} from './localTypes';
import type {
  Header,
  Footer,
  MessageCard,
  Choice,
  GiftCardTicket as GiftCardTicketType,
  GiftCardTickets as GiftCardTicketsType
} from '../api/graphql/getGiftCardGql';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      backgroundColor: theme.palette.giftCard.background.main,
      minHeight: '100vh',
      position: 'relative'
    }
  });

interface GiftCardOwnProps {
  header: Header;
  footer: Footer;
  messageCard: MessageCard | null;
  giftCardTickets: GiftCardTicketsType;
  giftWalletStorable: boolean;
  handleTicketClick?: Function;
  handleConfirmTicketChoice?: Function;
  handleGiftWalletButtonClick?: () => void;
  isGiftWalletFloatingButtonVisible: boolean;
}

interface GiftCardLocalState {
  isChoosingModalOpen: IsChoosingModalOpen;
  isChoosingConfirmModalOpen: IsChoosingConfirmModalOpen;
  selectedChoosableTicket: SelectedChoosableTicket;
  selectedChoice: SelectedChoice;
  redirectChecked: boolean;
}

type GiftCardProps = GiftCardOwnProps & WithStyles<typeof styles>;

class GiftCard extends Component<GiftCardProps, GiftCardLocalState> {
  constructor(props: GiftCardProps) {
    super(props);
    this.state = {
      isChoosingModalOpen: false,
      isChoosingConfirmModalOpen: false,
      selectedChoosableTicket: null,
      selectedChoice: null,
      redirectChecked: false
    };
  }

  componentDidMount() {
    this.redirectToGiftTicketUrlIfNeeded();
  }

  redirectToGiftTicketUrlIfNeeded = (): void => {
    const { giftCardTickets } = this.props;
    if (this.isNeededToRedirect()) {
      location.href = giftCardTickets[0].gift!.url!;
    } else {
      this.setState({ redirectChecked: true });
    }
  };

  isNeededToRedirect = (): boolean =>
    this.isRedirectableFixedTicket() || this.isRedirectableChoosableTicket();

  isRedirectableFixedTicket = (): boolean => {
    const { messageCard } = this.props;
    return (
      messageCard === null &&
      this.hasSingleGiftCardTicket() &&
      this.isFixedGiftCardTicket()
    );
  };

  isRedirectableChoosableTicket = (): boolean =>
    this.hasSingleGiftCardTicket() && this.isSelectedChoosableGiftCardTicket();

  hasSingleGiftCardTicket = (): boolean => {
    const { giftCardTickets } = this.props;
    return giftCardTickets.length === 1;
  };

  isFixedGiftCardTicket = (): boolean => {
    const { giftCardTickets } = this.props;
    return giftCardTickets[0].ticketType === 'fixed';
  };

  isSelectedChoosableGiftCardTicket = (): boolean => {
    const { giftCardTickets } = this.props;
    return (
      giftCardTickets[0].ticketType === 'choosable' &&
      giftCardTickets[0].gift !== null
    );
  };

  // 親から、handleTicketClickが提供されなければ(undefined)は、デフォルト動作
  // 親から、handleTicketClickが、関数として提供されればそれを実行
  handleTicketClick = (ticket: GiftCardTicketType): void => {
    const { handleTicketClick } = this.props;
    if (ticket.gift) {
      if (handleTicketClick) {
        handleTicketClick();
      } else {
        this.goGiftTicketURL(ticket);
      }
    } else {
      this.openChoosableTicketsModal(ticket);
    }
  };

  goGiftTicketURL = (ticket: GiftCardTicketType): void => {
    location.href = ticket.gift!.url!;
  };

  openChoosableTicketsModal = (ticket: GiftCardTicketType): void => {
    this.setState({ selectedChoosableTicket: ticket });
    this.openChooseModal();
  };

  closeChoosableTicketsModal = (): void => {
    this.closeChooseModal();
  };

  selectChoice = (choice: Choice): void => {
    this.closeChooseModal();
    this.setState({ selectedChoice: choice });
    this.openConfirmModal();
  };

  selectChoiceWithSingleChoosableTicket = (choice: Choice): void => {
    const { giftCardTickets } = this.props;

    this.setState({ selectedChoice: choice });
    this.setState({ selectedChoosableTicket: giftCardTickets[0] });
    this.openConfirmModal();
  };

  handleConfirmTicketChoice = (): void => {
    const { handleConfirmTicketChoice } = this.props;
    const { selectedChoice, selectedChoosableTicket } = this.state;
    if (handleConfirmTicketChoice) {
      handleConfirmTicketChoice(
        selectedChoosableTicket!.urlCode,
        selectedChoice!.urlCode
      );
    }
    this.closeConfirmModal();
  };

  openChooseModal = (): void => {
    this.setState({ isChoosingModalOpen: true });
  };

  closeChooseModal = (): void => {
    this.setState({ isChoosingModalOpen: false });
  };

  openConfirmModal = (): void => {
    this.setState({ isChoosingConfirmModalOpen: true });
  };

  closeConfirmModal = (): void => {
    this.setState({ isChoosingConfirmModalOpen: false });
  };

  isSingleChoosableTicket = (): boolean => {
    const { giftCardTickets } = this.props;

    return (
      giftCardTickets.length === 1 &&
      giftCardTickets[0].ticketType === 'choosable'
    );
  };

  render() {
    const {
      classes,
      header,
      footer,
      messageCard,
      giftCardTickets,
      giftWalletStorable,
      handleGiftWalletButtonClick,
      isGiftWalletFloatingButtonVisible
    } = this.props;
    const {
      isChoosingModalOpen,
      isChoosingConfirmModalOpen,
      selectedChoosableTicket,
      selectedChoice,
      redirectChecked
    } = this.state;
    return (
      redirectChecked && (
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          className={classes.root}
          data-cy="giftCard"
        >
          <GiftCardChooseModal
            isChoosingModalOpen={isChoosingModalOpen}
            closeChoosableTicketsModal={this.closeChoosableTicketsModal}
            selectedChoosableTicket={selectedChoosableTicket}
            selectChoice={this.selectChoice}
          />
          <GiftCardConfirmModal
            isChoosingConfirmModalOpen={isChoosingConfirmModalOpen}
            closeConfirmModal={this.closeConfirmModal}
            selectedChoice={selectedChoice}
            handleConfirmTicketChoice={this.handleConfirmTicketChoice}
            selectedChoosableTicket={selectedChoosableTicket}
          />
          <GiftCardHeader header={header} />
          <GiftCardMessageCard messageCard={messageCard} />
          {this.isSingleChoosableTicket() ? (
            <Choices
              choosingOption={giftCardTickets[0].choosingOption!}
              selectChoice={this.selectChoiceWithSingleChoosableTicket}
              giftWalletStorable={giftWalletStorable}
              handleGiftWalletButtonClick={handleGiftWalletButtonClick}
            />
          ) : (
            <GiftCardTickets
              giftCardTickets={giftCardTickets}
              handleTicketClick={this.handleTicketClick}
              giftWalletStorable={giftWalletStorable}
              handleGiftWalletButtonClick={handleGiftWalletButtonClick}
            />
          )}

          <GiftCardFooter footer={footer} />
          {/* <GiftCardFloatingButton
            isGiftWalletFloatingButtonVisible={
              isGiftWalletFloatingButtonVisible
            }
            handleGiftWalletButtonClick={handleGiftWalletButtonClick}
          /> */}
        </Grid>
      )
    );
  }
}

export default withStyles(styles)(GiftCard);
