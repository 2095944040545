import { initializeBulkGifteeBoxIssueConfigsNew } from '@console/actions/actions';
import New from '@console/components/project/gifteeBoxConfigs/bulkGifteeBoxIssueConfigs/New';
import { getSelectedGifteeBoxConfig } from '@console/selectors/projectDetail/gifteeBoxConfigs';
import { connect } from 'react-redux';
import type { AppThunkDispatch } from '@console/actions/thunkType';
import type { AppState } from '@console/reducers';

const mapStateToProps = (state: AppState) => ({
  gifteeBoxConfig: getSelectedGifteeBoxConfig(state),
  errors: state.errors
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  onRenderNew: (gifteeBoxConfigUrlCode: UrlCode) =>
    dispatch(initializeBulkGifteeBoxIssueConfigsNew(gifteeBoxConfigUrlCode))
});

export type NewPropsMappedFromState = ReturnType<typeof mapStateToProps>;
export type NewPropsMappedFromDispatch = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(New);
