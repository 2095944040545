import {
  createErrorMessageForUnselected,
  createErrorMessageForEmpty
} from '../../../../../common/middleware/errorMessages';
import { validationSuccess, validationFailure } from '../../../actions/actions';
import type { AppThunkAction } from '../../../actions/thunkType';
import type { Errors } from '../../../reducers/errors';

const GIFT_DISTRIBUTION = 'projectDraftGiftDistribution';
const CAMPAIGN_DISTRIBUTION_CHANNELS = 'campaignGiftDistributionChannels';
const CAMPAIGN_ADVERTISING_MEDIA = 'campaignAdvertisingMedia';

const validateGiftDistribution = (): AppThunkAction<void> => dispatch => {
  dispatch(validateCampaignGiftDistributionChannels());
  dispatch(validateCampaignAdvertisingMedia());
};

const collectEmptyDescriptionNames = (
  giftDistributionChannelCategories,
  campaignGiftDistributionChannels,
  campaignGiftDistributionChannelDescriptions
): string[] =>
  giftDistributionChannelCategories.reduce((names, category) => {
    if (
      category.descriptionEnabled &&
      campaignGiftDistributionChannels.includes(category.urlCode)
    ) {
      const campaignGiftDistributionChannelDescription =
        campaignGiftDistributionChannelDescriptions.find(
          channel => channel.urlCode === category.urlCode
        );

      if (
        campaignGiftDistributionChannelDescription === undefined ||
        campaignGiftDistributionChannelDescription.description === ''
      ) {
        names.push(category.name);
      }
    }
    return names;
  }, []);

const validateCampaignGiftDistributionChannels =
  (): AppThunkAction<void> => (dispatch, getState) => {
    let errorMessage: string = '';

    const {
      campaignGiftDistributionChannels,
      campaignGiftDistributionChannelDescriptions
    } = getState().selectedProjectDraft.projectJson;

    const { giftDistributionChannelCategories } = getState();

    const emptyDescriptionNames = collectEmptyDescriptionNames(
      giftDistributionChannelCategories,
      campaignGiftDistributionChannels,
      campaignGiftDistributionChannelDescriptions
    );

    if (campaignGiftDistributionChannels.length === 0) {
      errorMessage = createErrorMessageForUnselected('ギフトの配布方法');
    } else if (emptyDescriptionNames.length !== 0) {
      errorMessage = createErrorMessageForEmpty(
        `ギフトの配布方法:${emptyDescriptionNames.join('、')}の詳細`
      );
    }

    if (errorMessage === '') {
      dispatch(
        validationSuccess(GIFT_DISTRIBUTION, CAMPAIGN_DISTRIBUTION_CHANNELS)
      );
    } else {
      dispatch(
        validationFailure(GIFT_DISTRIBUTION, {
          [CAMPAIGN_DISTRIBUTION_CHANNELS]: errorMessage
        })
      );
    }
  };

const validateCampaignAdvertisingMedia =
  (): AppThunkAction<void> => (dispatch, getState) => {
    let errorMessage: string = '';
    const { campaignAdvertisingMedia } =
      getState().selectedProjectDraft.projectJson;

    if (Object.keys(campaignAdvertisingMedia).length === 0) {
      errorMessage = createErrorMessageForUnselected('露出する媒体');
    }

    if (errorMessage === '') {
      dispatch(
        validationSuccess(GIFT_DISTRIBUTION, CAMPAIGN_ADVERTISING_MEDIA)
      );
    } else {
      dispatch(
        validationFailure(GIFT_DISTRIBUTION, {
          [CAMPAIGN_ADVERTISING_MEDIA]: errorMessage
        })
      );
    }
  };

export const projectDraftGiftDistributionHasValidationErrors = (
  errors: Errors
): boolean => {
  const giftDistributionErrors = errors.projectDraftGiftDistribution;
  return Object.keys(giftDistributionErrors).length > 0;
};

export const projectDraftGiftDistributionValidators = {
  validateCampaignGiftDistributionChannels,
  validateCampaignAdvertisingMedia,
  validateGiftDistribution
};
