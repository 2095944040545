import { AppActionTypes } from '../actions/actionType';
import type { AppAction } from '../actions/actionType';

const isDrawerOpen = (state = true, action: AppAction): boolean => {
  switch (action.type) {
    case AppActionTypes.CLOSE_DRAWER:
      return false;
    case AppActionTypes.OPEN_DRAWER:
      return true;
    default:
      return state;
  }
};

export default isDrawerOpen;
