import {
  FormControl,
  InputLabel,
  Select,
  Input,
  MenuItem,
  Chip,
  Grid
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { useEffect } from 'react';
import type {
  BrandFilteringPropsMappedFromDispatch,
  BrandFilteringPropsMappedFromState
} from '@console/containers/common/gifteeBoxTemplateContentSelectionsModal/filteringSection/BrandFilteringContainer';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    brandFilteringSection: {
      minWidth: 250
    },
    chip: {
      margin: theme.spacing()
    }
  });

type BrandFilteringProps = BrandFilteringPropsMappedFromDispatch &
  BrandFilteringPropsMappedFromState &
  WithStyles<typeof styles>;

const BrandFiltering: React.FC<BrandFilteringProps> = ({
  selectedGifteeBoxTemplateBrands,
  selectedGifteeBoxTemplateUrlCode,
  selectedBrandUrlCodes,
  selectedBrands,
  getGifteeBoxTemplateBrands,
  handleBrandFilterChange,
  classes
}) => {
  useEffect(() => {
    if (!!selectedGifteeBoxTemplateUrlCode) {
      getGifteeBoxTemplateBrands(selectedGifteeBoxTemplateUrlCode);
    }
  }, [selectedGifteeBoxTemplateUrlCode]);

  return !!selectedGifteeBoxTemplateBrands ? (
    <FormControl
      variant="standard"
      className={classes.brandFilteringSection}
      data-cy="brandFiltering"
    >
      <InputLabel id="gifteeBoxTemplateBrandsLabel">ブランド</InputLabel>
      <Select
        variant="standard"
        labelId="gifteeBoxTemplateBrandsLabel"
        id="gifteeBoxTemplateBrandsSelect"
        multiple
        input={<Input id="gifteeBoxTemplateBrandsSelect" />}
        data-cy="gifteeBoxTemplateBrandsSelect"
        value={selectedBrandUrlCodes}
        onChange={e => handleBrandFilterChange(e.target.value)}
        renderValue={() => (
          <Grid container data-cy="selectedBrandsChips">
            {selectedBrands.map(selectedBrand => (
              <Chip
                key={selectedBrand.urlCode}
                label={selectedBrand.name}
                className={classes.chip}
              />
            ))}
          </Grid>
        )}
      >
        {selectedGifteeBoxTemplateBrands.map(brand => (
          <MenuItem
            key={brand.urlCode}
            value={brand.urlCode}
            data-cy={`brandFilteringCandidates-${brand.urlCode}`}
          >
            {brand.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  ) : null;
};

export default withStyles(styles)(BrandFiltering);
