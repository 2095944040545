import moment from '@common/lib/moment';
import { isGifteeBoxGiftConfig } from '@console/common/projectDraft/giftConfig';
import type {
  MutationGifteeBoxConfigs,
  BulkGifteeBoxIssueConfig,
  ApiGifteeBoxIssueConfig
} from '@common/api/graphql/createProjectGql';
import type {
  GiftConfigs,
  GiftConfig,
  GifteeBoxGiftConfig
} from '@console/reducers/projectJson/giftConfigs';
import type { GiftDistributionPeriod } from '@console/reducers/projectJson/giftDistributionPeriod';

export const getGifteeBoxConfigsForBulkProject = (
  giftConfigs: GiftConfigs
): MutationGifteeBoxConfigs => {
  const gifteeBoxGiftConfigs = getGifteeBoxGiftConfigs(giftConfigs);

  const gifteeBoxConfigs = gifteeBoxGiftConfigs.map(giftConfig => ({
    gifteeBoxTemplateUrlCode:
      giftConfig.selectedGifteeBoxTemplate?.urlCode || '',
    bulkGifteeBoxIssueConfigs: [getBulkGifteeBoxIssueConfig(giftConfig)],
    apiGifteeBoxIssueConfigs: []
  }));

  return gifteeBoxConfigs.length === 0 ? null : gifteeBoxConfigs;
};

export const getGifteeBoxConfigsForApiProject = (
  giftDistributionPeriod: GiftDistributionPeriod,
  giftConfigs: GiftConfigs
): MutationGifteeBoxConfigs => {
  const gifteeBoxGiftConfigs = getGifteeBoxGiftConfigs(giftConfigs);

  const gifteeBoxConfigs = gifteeBoxGiftConfigs.map(giftConfig => ({
    gifteeBoxTemplateUrlCode:
      giftConfig.selectedGifteeBoxTemplate?.urlCode || '',
    bulkGifteeBoxIssueConfigs: [],
    apiGifteeBoxIssueConfigs: [
      getApiGifteeBoxIssueConfig(giftDistributionPeriod, giftConfig)
    ]
  }));

  return gifteeBoxConfigs.length === 0 ? null : gifteeBoxConfigs;
};

export const getGifteeBoxConfigsForGcpProject = (
  giftDistributionPeriod: GiftDistributionPeriod,
  giftConfigs: GiftConfigs
): MutationGifteeBoxConfigs => {
  const gifteeBoxGiftConfigs = getGifteeBoxGiftConfigs(giftConfigs);

  const gifteeBoxConfigs = gifteeBoxGiftConfigs.map(giftConfig => ({
    gifteeBoxTemplateUrlCode:
      giftConfig.selectedGifteeBoxTemplate?.urlCode || '',
    bulkGifteeBoxIssueConfigs: [],
    apiGifteeBoxIssueConfigs: [
      getGcpGifteeBoxIssueConfig(giftDistributionPeriod, giftConfig)
    ]
  }));

  return gifteeBoxConfigs.length === 0 ? null : gifteeBoxConfigs;
};

const getGifteeBoxGiftConfigs = (
  giftConfigs: GiftConfigs
): GifteeBoxGiftConfig[] =>
  giftConfigs.filter(
    (giftConfig: GiftConfig): giftConfig is GifteeBoxGiftConfig =>
      isGifteeBoxGiftConfig(giftConfig)
  );

const getBulkGifteeBoxIssueConfig = (
  giftConfig: GiftConfig
): BulkGifteeBoxIssueConfig => ({
  requestedIssueAmount: Number(giftConfig.issueAmount),
  deliveryDate: giftConfig.deliveryDate || '',
  initialPoint: Number(giftConfig.gifteeBoxInitialPoint)
});

const getApiGifteeBoxIssueConfig = (
  giftDistributionPeriod: GiftDistributionPeriod,
  giftConfig: GiftConfig
): ApiGifteeBoxIssueConfig => ({
  issuableBeginAt: moment().toISOString(),
  issuableEndAt: giftDistributionPeriod.endDateOfDistribution
    ? moment(giftDistributionPeriod.endDateOfDistribution)
        .add(1, 'd')
        .toISOString(true)
    : '',
  issueEstimateOptions: giftConfig.gifteeBoxIssueEstimateOptions.map(
    gifteeBoxIssueEstimateOption => ({
      initialPoint: Number(gifteeBoxIssueEstimateOption.initialPoint),
      issueAmount: Number(gifteeBoxIssueEstimateOption.issueAmount)
    })
  )
});

const getGcpGifteeBoxIssueConfig = (
  giftDistributionPeriod: GiftDistributionPeriod,
  giftConfig: GiftConfig
): ApiGifteeBoxIssueConfig => ({
  issuableBeginAt: moment().toISOString(),
  issuableEndAt: giftDistributionPeriod.endDateOfDistribution
    ? moment(giftDistributionPeriod.endDateOfDistribution)
        .add(1, 'd')
        .toISOString(true)
    : '',
  issueEstimateOptions: giftConfig.gifteeBoxIssueEstimateOptions.map(
    gifteeBoxIssueEstimateOption => ({
      initialPoint: Number(gifteeBoxIssueEstimateOption.initialPoint),
      issueAmount: Number(gifteeBoxIssueEstimateOption.issueAmount)
    })
  )
});
