import { AppActionTypes } from '../actions/actionType';
import type { AppAction } from '../actions/actionType';

type Anchor = null | (EventTarget & Element);

const dashboardProjectDraftAnchors = (
  state: Anchor[] = [],
  action: AppAction
): Anchor[] => {
  switch (action.type) {
    case AppActionTypes.GET_PROJECT_DRAFTS_SUCCESS:
      return action.projectDrafts.map(draft => null);
    case AppActionTypes.OPEN_PROJECT_DRAFT_OPTION:
      state[action.index] = action.anchor;
      return [...state];
    case AppActionTypes.CLOSE_PROJECT_DRAFT_OPTION:
      state[action.index] = null;
      return [...state];
    default:
      return state;
  }
};

export default dashboardProjectDraftAnchors;
