import { convertToFullWidthCharacter } from '@common/lib/convertHalfWidthAndFullWidthCharacter';

// TODO: 発行手数料をGraphQLによってbackendから取得するように変更する
export const ISSUE_RATE: number = 10;
export const CONSUMPTION_TAX: number = 10;

// 案件申込
export const JP_START_DATE_OF_AD = '掲載開始日';
export const JP_END_DATE_OF_AD = '掲載終了日';
export const JP_START_DATE_OF_DISTRIBUTION = 'ギフト配布開始日';
export const JP_END_DATE_OF_DISTRIBUTION = 'ギフト配布終了日';
export const JP_GIFT_CONFIG: string = 'ギフト';
export const JP_CONTENT: string = '商品';
export const JP_ISSUE_AMOUNT: string = '数量（見込み）';
export const JP_DELIVERY_DATE: string = '納品希望日';
export const JP_GIFTEE_BOX_INITIAL_POINT: string = '単価';
export const JP_GIFTEE_BOX_ISSUE_ESTIMATE_OPTION_INITIAL_POINT: string = '単価';
export const JP_GIFTEE_BOX_ISSUE_ESTIMATE_OPTION_ISSUE_AMOUNT: string =
  '数量（見込み）';
export const JP_SENDER_NAME: string = '差出人名';
export const JP_MESSAGE: string = 'メッセージ';
export const MIN_CHOOSABLE_GIFT_CONTENTS = 2;
export const MAX_CHOOSABLE_GIFT_CONTENTS = 4;
export const MIN_CHOOSABLE_GIFT_CONTENTS_STRING = convertToFullWidthCharacter(
  MIN_CHOOSABLE_GIFT_CONTENTS.toString()
);
export const MAX_CHOOSABLE_GIFT_CONTENTS_STRING = convertToFullWidthCharacter(
  MAX_CHOOSABLE_GIFT_CONTENTS.toString()
);
export const CAMPAIGN_AD_MEDIA_NAME_EMAIL = 'ギフトURL配布メール';
export const CAMPAIGN_AD_MEDIA_NAME_PAPER = '【印刷】チラシ・ポスター類';
export const CAMPAIGN_AD_MEDIA_NAME_WEBPAGE = 'キャンペーンWebページ';
export const CAMPAIGN_AD_MEDIA_NAME_BANNER = '告知バナー';
export const CAMPAIGN_AD_MEDIA_NAME_AD_MAIL = '告知メール';
export const CAMPAIGN_AD_MEDIA_NAME_PRESS = 'プレスリリース';
export const CAMPAIGN_AD_MEDIA_NAME_LINE = 'LINE';
export const CAMPAIGN_AD_MEDIA_NAME_TWITTER = 'Twitter';
export const CAMPAIGN_AD_MEDIA_NAME_FACEBOOK = 'Facebook';
export const CAMPAIGN_AD_MEDIA_NAME_INSTAGRAM = 'Instagram';
export const CAMPAIGN_AD_MEDIA_NAME_OTHER = 'その他';

// アカウント
export const JP_BILLING_ADDRESS_NAME = '担当者名' as const;
export const JP_BILLING_ADDRESS_DIVISION = '部署名' as const;
export const JP_BILLING_ADDRESS_POSTAL_CODE = '郵便番号' as const;
export const JP_BILLING_ADDRESS_PREFECTURE = '都道府県' as const;
export const JP_BILLING_ADDRESS_CITY = '市区町村' as const;
export const JP_BILLING_ADDRESS_ADDRESS = '番地' as const;

// DP担当者
export const JP_DISTRIBUTION_PARTNER_USER_NAME = '担当者名' as const;
export const JP_DISTRIBUTION_PARTNER_USER_DIVISION = '部署名' as const;
export const JP_DISTRIBUTION_PARTNER_USER_PHONE_NUMBER = '電話番号' as const;

// giftee*Box
export const GIFTEE_BOX_TEMPLATE_BRAND_NAME = 'ギフティ' as const;
export const GIFTEE_BOX_TICKET_TYPE_DISPLAY_NAME = 'giftee Box' as const;
export const GIFTEE_BOX_DISPLAY_NAME = 'giftee Box / えらべるPay' as const;

export const GIFT_CARD_FIXED_TICKET_TYPE_DISPLAY_NAME = '単一' as const;
export const GIFT_CARD_CHOOSABLE_TICKET_TYPE_DISPLAY_NAME = '選べる' as const;

export const GIFTEE_BOX_TEMPLATE_IMAGE_DOWNLOAD_URL =
  'https://giftee.zendesk.com/hc/ja/sections/8351210964505' as const;

// メニューアイコン
export const PRIVACY_POLICY_URL = 'https://giftee.co.jp/privacypolicy';
export const TERMS_OF_URL =
  'https://docs.giftee.biz/business/downloads/giftee_Business_Agreement_index.pdf';
export const API_DOC_URL = 'https://g4b.giftee.biz/docs/api_spec/gift_issue';
export const HELPSITE_URL = 'https://giftee.zendesk.com/hc/ja';

export const HELPSITE_GIFTS_FOR_EMPLOYEE_URL =
  'https://giftee.zendesk.com/hc/ja/articles/12617159350681';

export const HELPSITE_CREDIT_LIMIT_URL =
  'https://giftee.zendesk.com/hc/ja/articles/31073483957529';

export const SELECTION__REPORTS_CSV_HEADER = [
  'ブランド名',
  '商品名',
  '選択率[%]'
];

export const SELECTION_REPORT_BUTTON_LABEL = '選択実績をダウンロード';

export const CONTENTS_PER_PAGE = 50;
