import { getLocalString } from '@common/lib/getLocalString';
import { getLocalePrice } from '@common/lib/getLocalePrice';
import getReadableDate from '@common/lib/getReadableDate';
import getTransitionTimeout from '@common/lib/getTransitionTimeout';
import getTruncatedString from '@common/lib/getTruncatedString';
import { isGifteeBoxTicketType } from '@console/common/projectDraft/giftConfig';
import StepHandlerContainer from '@console/containers/projectDraft/StepHandlerContainer';
import { projectDraftGiftConfigsHasValidationErrors } from '@console/middleware/validator/projectDraft/giftConfigs';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Typography,
  Card,
  CardActionArea,
  Grid,
  Grow,
  IconButton
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import GiftCardConfirmModal from '../common/GiftConfigConfirmModal';
import SectionTitle from './common/SectionTitle';
import type {
  GiftConfigsPropsMappedFromState,
  GiftConfigsPropsMappedFromDispatch
} from '@console/containers/projectDraft/GiftConfigsContainer';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const CARD_WIDTH = 250;

const styles = (theme: Theme): StyleRules =>
  createStyles({
    title: {
      marginTop: theme.spacing(3)
    },
    createGiftConfigIcon: {
      color: 'white'
    },
    createGiftConfigTitle: {
      color: 'white'
    },
    errorCard: {
      border: `solid 1px ${theme.palette.error.main}`
    },
    card: {
      width: CARD_WIDTH,
      height: 200,
      borderRadius: theme.spacing(),
      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
      textAlign: 'center',
      margin: '5%'
    },
    projects: {
      margin: `${theme.spacing()} 0`
    },
    createGiftConfigActionArea: {
      height: '100%',
      backgroundColor: theme.palette.primary.light
    },
    sample: {
      display: 'flex',
      justifyContent: 'center'
    },
    giftConfigActionArea: {
      width: 'inherit',
      height: 160,
      padding: `${theme.spacing(3)} ${theme.spacing(2)} 0 ${theme.spacing(2)}`,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'inherit'
    },
    giftImage: {
      width: 70,
      height: 70,
      objectFit: 'contain'
    },
    giftLabel: {
      paddingLeft: theme.spacing()
    },
    deliveryContainer: {
      width: '100%'
    },
    textArea: {
      marginTop: '15px'
    },
    cardFooter: {
      padding: '5px 15px'
    },
    errorMessage: {
      minHeight: theme.spacing(3),
      fontSize: 14
    },
    gifteeBoxInitialPointArea: {
      width: '45%'
    },
    gifteeBoxEstimatedIssueAmountArea: {
      width: '50%'
    },
    gifteeBoxEstimatedIssueAmount: {
      marginLeft: theme.spacing(0.5)
    }
  });

type GiftConfigsProps = GiftConfigsPropsMappedFromState &
  GiftConfigsPropsMappedFromDispatch &
  WithStyles<typeof styles>;

const GiftConfigs: React.FC<GiftConfigsProps> = ({
  giftConfigsForDisplay,
  isBulkSolutionSystemSelected,
  isGcpSolutionSystemSelected,
  isApiSolutionSystemSelected,
  onMount,
  createGiftConfig,
  selectGiftConfig,
  handleStepNextFromParent,
  onConfirmedDeletion,
  errors,
  classes
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [confirmModalOpened, setConfirmModalOpened] = useState(false);
  const [deletableGiftConfigIndex, setDeletableGiftConfigIndex] = useState<
    number | null
  >(null);

  useEffect(() => onMount(), []);

  const openConfirmDialog = () => {
    setConfirmModalOpened(true);
  };

  const closeConfirmDialog = () => {
    setConfirmModalOpened(false);
  };

  const handleDeleteButtonClick = (giftConfigIndex: number) => {
    setDeletableGiftConfigIndex(giftConfigIndex);
    openConfirmDialog();
  };

  const handleConfirmModalOK = (giftConfigIndex: number) => {
    onConfirmedDeletion(giftConfigIndex);
    closeConfirmDialog();
    setDeletableGiftConfigIndex(null);
  };

  const handleConfirmModalClose = () => {
    closeConfirmDialog();
    setDeletableGiftConfigIndex(null);
  };

  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="flex-start"
      className={classes.root}
    >
      <Typography
        id="errorMessageForEndCampaignSolutionSystem"
        className={classes.errorMessage}
        color="error"
      >
        {errors.projectDraftGiftConfigsCommon}
      </Typography>
      <Typography
        id="errorMessageForGiftConfigs"
        className={classes.errorMessage}
        color="error"
      >
        {projectDraftGiftConfigsHasValidationErrors(errors) &&
          '各ギフトをもう一度ご確認ください。'}
      </Typography>
      <Grid item xs={12} className={classes.title}>
        <SectionTitle text="ギフトを追加してください" />
      </Grid>
      <Grid
        item
        container
        alignItems="flex-end"
        justifyContent="flex-start"
        xs={12}
        className={classes.projects}
      >
        <Grid item sm={12} md={6} lg={4}>
          <Card className={classes.card}>
            <CardActionArea
              className={classes.createGiftConfigActionArea}
              onClick={() => createGiftConfig(navigate, location)}
              data-cy="addGiftConfig"
            >
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <AddIcon
                  fontSize="large"
                  className={classes.createGiftConfigIcon}
                />
                <Typography
                  variant="h6"
                  className={classes.createGiftConfigTitle}
                >
                  ギフトを追加
                </Typography>
              </Grid>
            </CardActionArea>
            {/* <CardActions className={classes.sample}>
              <InfoIcon />
              <Link underline="always" href="#">
                サンプルを見る
              </Link>
            </CardActions> */}
          </Card>
        </Grid>
        {giftConfigsForDisplay.length !== 0
          ? giftConfigsForDisplay.map((giftConfigForDisplay, index) => (
              <Grid
                item
                sm={12}
                md={6}
                lg={4}
                key={index}
                data-cy={`giftConfig-${index}`}
              >
                <Grow
                  in={Boolean(giftConfigsForDisplay)}
                  {...(giftConfigsForDisplay
                    ? { timeout: getTransitionTimeout(index) }
                    : {})}
                >
                  <Card
                    className={clsx(classes.card, {
                      [classes.errorCard]:
                        !!errors.projectDraftGiftConfigs[index]
                    })}
                  >
                    <CardActionArea
                      className={classes.giftConfigActionArea}
                      onClick={() =>
                        selectGiftConfig(navigate, location, index)
                      }
                    >
                      <Grid container>
                        <Grid item sm={4}>
                          <img
                            className={classes.giftImage}
                            src={giftConfigForDisplay.thumbnailImageUrl}
                          />
                        </Grid>
                        <Grid
                          item
                          sm={8}
                          container
                          direction="column"
                          className={classes.giftLabel}
                          justifyContent="center"
                          alignItems="flex-start"
                        >
                          <Typography variant="caption" color="textSecondary">
                            {getTruncatedString(
                              giftConfigForDisplay.firstBrandName,
                              20,
                              '...'
                            )}
                          </Typography>
                          <Typography variant="body2">
                            {getTruncatedString(
                              giftConfigForDisplay.firstContentName,
                              35,
                              '...'
                            ) || '商品が未選択です'}
                          </Typography>
                        </Grid>
                      </Grid>
                      <div className={classes.deliveryContainer}>
                        {isGifteeBoxTicketType(
                          giftConfigForDisplay.ticketType
                        ) && !isBulkSolutionSystemSelected ? null : (
                          <Grid
                            container
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Typography
                              variant="caption"
                              color="textSecondary"
                              align="left"
                            >
                              単価
                            </Typography>
                            <Typography
                              variant="body2"
                              align="right"
                              color="textSecondary"
                            >
                              {giftConfigForDisplay.price !== 0
                                ? getLocalePrice(giftConfigForDisplay.price)
                                : '未設定'}
                            </Typography>
                          </Grid>
                        )}
                        {isGifteeBoxTicketType(
                          giftConfigForDisplay.ticketType
                        ) &&
                        (isGcpSolutionSystemSelected ||
                          isApiSolutionSystemSelected)
                          ? giftConfigForDisplay.gifteeBoxIssueEstimateOptions.map(
                              (gifteeBoxIssueEstimateOption, index) => (
                                <Grid
                                  container
                                  alignItems="center"
                                  justifyContent="space-between"
                                  key={index}
                                >
                                  <Grid
                                    container
                                    className={
                                      classes.gifteeBoxInitialPointArea
                                    }
                                    justifyContent="space-between"
                                  >
                                    <Typography
                                      variant="caption"
                                      color="textSecondary"
                                      align="left"
                                    >
                                      単価
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      align="right"
                                      color="textSecondary"
                                    >
                                      {getLocalePrice(
                                        Number(
                                          gifteeBoxIssueEstimateOption.initialPoint
                                        )
                                      )}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    container
                                    className={
                                      classes.gifteeBoxEstimatedIssueAmountArea
                                    }
                                    justifyContent="flex-start"
                                  >
                                    <Typography
                                      variant="caption"
                                      color="textSecondary"
                                    >
                                      見込み数量
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                      className={
                                        classes.gifteeBoxEstimatedIssueAmount
                                      }
                                    >
                                      {getLocalString(
                                        Number(
                                          gifteeBoxIssueEstimateOption.issueAmount
                                        )
                                      ) || '未設定'}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              )
                            )
                          : null}
                        {isBulkSolutionSystemSelected && (
                          <Grid
                            container
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Typography
                              variant="caption"
                              color="textSecondary"
                              align="left"
                            >
                              納品希望日
                            </Typography>
                            <Typography
                              variant="body2"
                              align="right"
                              color="textSecondary"
                            >
                              {getReadableDate(
                                giftConfigForDisplay.deliveryDate
                              ) || '未設定'}
                            </Typography>
                          </Grid>
                        )}
                      </div>
                    </CardActionArea>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="flex-end"
                      className={classes.cardFooter}
                    >
                      <IconButton
                        aria-label="delete"
                        onClick={() => handleDeleteButtonClick(index)}
                        size="large"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Card>
                </Grow>
              </Grid>
            ))
          : null}
      </Grid>
      {deletableGiftConfigIndex !== null && (
        <GiftCardConfirmModal
          isOpen={confirmModalOpened}
          giftConfigForDisplay={giftConfigsForDisplay[deletableGiftConfigIndex]}
          onOKHandler={() => handleConfirmModalOK(deletableGiftConfigIndex)}
          onCloseHandler={() => handleConfirmModalClose()}
        />
      )}
      <StepHandlerContainer
        handleStepNextFromParent={() =>
          handleStepNextFromParent(navigate, location)
        }
      />
    </Grid>
  );
};

export default withStyles(styles)(GiftConfigs);
