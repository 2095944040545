import { connect } from 'react-redux';
import CommonError from '../../components/common/CommonError';
import type { AppThunkDispatch } from '../../actions/thunkType';
import type { AppState } from '../../reducers';

const mapStateToProps = (state: AppState, ownProps: OwnProps) => ({
  errors: state.errors,
  errorKey: ownProps.errorKey
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({});

type OwnProps = {
  errorKey?: string;
};

export type CommonErrorPropsMappedFromState = ReturnType<
  typeof mapStateToProps
>;
export type CommonErrorPropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(mapStateToProps, mapDispatchToProps)(CommonError);
