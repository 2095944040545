import { Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Fragment } from 'react';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => ({
  example: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing()
  },
  code: {
    backgroundColor: theme.palette.background.default,
    padding: `0 ${theme.spacing(0.5)}`,
    borderRadius: 3
  }
});

type APIIpRestrictionExampleProps = WithStyles<typeof styles>;

const APIIpRestrictionExample: React.FC<APIIpRestrictionExampleProps> = ({
  classes
}) => (
  <Fragment>
    <div className={classes.example}>
      <Typography variant="body2" color="textSecondary">
        設定例：192.0.2.1 のみを許可する場合
      </Typography>
      <Typography variant="body2" color="textSecondary" display="block">
        <code className={classes.code}>192.0.2.1</code>
      </Typography>
    </div>
    <div className={classes.example}>
      <Typography variant="body2" color="textSecondary">
        設定例：192.0.2.1〜192.0.2.255 までを許可する場合
      </Typography>
      <Typography variant="body2" color="textSecondary" display="block">
        <code className={classes.code}>192.0.2.0/24</code>
      </Typography>
    </div>
  </Fragment>
);

export default withStyles(styles)(APIIpRestrictionExample);
