type ApiUri = string;
type ApiUriWebsocket = string;

type HttpProtocol = string;
type WebsocketProtocol = string;

const HttpWithSSL = 'https:';
const Websocket = 'ws:';
const WebsocketWithSSL = 'wss:';

export const getApiUri = (): ApiUri => {
  const { protocol, host } = window.location;
  return `${protocol}//${host}`;
};

export const getApiUriWebsocket = (): ApiUriWebsocket => {
  const { protocol, host } = window.location;
  return `${getWebsocketProtocol(protocol as HttpProtocol)}//${host}`;
};

const getWebsocketProtocol = (
  httpProtocol: HttpProtocol
): WebsocketProtocol => {
  switch (httpProtocol) {
    case HttpWithSSL:
      return WebsocketWithSSL;
    default:
      return Websocket;
  }
};

export const isWithSSL = window.location.protocol === HttpWithSSL;
