import { getAdvertisingPeriod } from '@console/selectors/projectDetail/advertisingPeriod';
import { getClaims } from '@console/selectors/projectDetail/claims';
import { getConfidentialInformation } from '@console/selectors/projectDetail/confidentialInformation';
import { getGiftCardConfigs } from '@console/selectors/projectDetail/giftCardConfigs';
import { getGiftDistributionPeriod } from '@console/selectors/projectDetail/giftDistributionPeriod';
import { getGifteeBoxConfigs } from '@console/selectors/projectDetail/gifteeBoxConfigs';
import { getHostCompany } from '@console/selectors/projectDetail/hostCompany';
import { getMarketingIndustry } from '@console/selectors/projectDetail/marketingIndustry';
import { getMessageThread } from '@console/selectors/projectDetail/messageThread';
import { getProjectAdvertisingMediumRegistrations } from '@console/selectors/projectDetail/projectAdvertisingMediumRegistrations';
import { getProjectGiftDistributionChannels } from '@console/selectors/projectDetail/projectGiftDistributionChannels';
import { getSolutionSystem } from '@console/selectors/projectDetail/solutionSystem';
import { getThirdPartyService } from '@console/selectors/projectDetail/thirdPartyService';
import { createSelector } from 'reselect';
import type { AppState } from '@console/reducers';
import type {
  LocalClaimEstimate,
  LocalProject
} from '@console/reducers/projects';

export type LocalClaimEstimateForDisplay = NonNullable<LocalClaimEstimate> & {
  estimatedAmount: number;
};

const getProjectDetailFromAppState = (state: AppState) => state.projectDetail;

// TODO: 命名の見直し
// https://github.com/giftee/ikedayama/pull/13887/files#r736093206
const getProjectDetail = createSelector(
  [getProjectDetailFromAppState],
  (
    projectDetail
  ): Omit<
    LocalProject,
    | 'hostCompany'
    | 'advertisingPeriod'
    | 'giftDistributionPeriod'
    | 'solutionSystem'
    | 'marketingIndustry'
    | 'projectGiftDistributionChannels'
    | 'projectAdvertisingMediumRegistrations'
    | 'advertisingMedia'
    | 'thirdPartyService'
    | 'messageThread'
    | 'giftCardConfigs'
    | 'claims'
    | 'confidentialInformation'
    | 'gifteeBoxConfigs'
  > => ({
    name: projectDetail.name ?? '',
    description: projectDetail.description ?? '',
    urlCode: projectDetail.urlCode ?? '',
    isSample: projectDetail.isSample ?? false
  })
);

export const getSelectedProjectDetail = createSelector(
  [
    getProjectDetail,
    getHostCompany,
    getAdvertisingPeriod,
    getGiftDistributionPeriod,
    getSolutionSystem,
    getMarketingIndustry,
    getProjectGiftDistributionChannels,
    getProjectAdvertisingMediumRegistrations,
    getThirdPartyService,
    getMessageThread,
    getGiftCardConfigs,
    getClaims,
    getConfidentialInformation,
    getGifteeBoxConfigs
  ],
  (
    projectDetail,
    hostCompany,
    advertisingPeriod,
    giftDistributionPeriod,
    solutionSystem,
    marketingIndustry,
    projectGiftDistributionChannels,
    projectAdvertisingMediumRegistrations,
    thirdPartyService,
    messageThread,
    giftCardConfigs,
    claims,
    confidentialInformation,
    gifteeBoxConfigs
  ): LocalProject => ({
    ...projectDetail,
    hostCompany,
    advertisingPeriod,
    giftDistributionPeriod,
    solutionSystem,
    marketingIndustry,
    projectGiftDistributionChannels,
    projectAdvertisingMediumRegistrations,
    thirdPartyService,
    messageThread,
    giftCardConfigs,
    claims,
    confidentialInformation,
    gifteeBoxConfigs
  })
);

export const getIsMypageProject = createSelector(
  [getSelectedProjectDetail],
  (projectDetail): boolean => {
    if (projectDetail.urlCode === '') {
      return false;
    }
    return projectDetail.messageThread?.urlCode ? false : true;
  }
);
