import { getProjects } from '../../../../common/api/graphql/getProjectsGql';
import {
  getProjectsStart,
  getProjectsSuccess,
  getProjectsFailure
} from '../actions';
import handleApiErrorIfNeeded from './handleApiErrorIfNeeded';
import type { ApiError } from '../../../../common/api/graphql/apolloClient';
import type { GetProjectsQuery } from '../../../../common/api/graphql/getProjectsGql';
import type { AppThunkAction } from '../thunkType';

export const getProjectsSuccessThunk =
  (projects: GetProjectsQuery['projects']): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(getProjectsSuccess(projects));
    return Promise.resolve();
  };

export const getProjectsFailureThunk =
  (error: ApiError): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(getProjectsFailure());
    return dispatch(handleApiErrorIfNeeded(error));
  };

export const getProjectsThunk =
  (): AppThunkAction<Promise<void>> => (dispatch, getState) => {
    dispatch(getProjectsStart());
    return getProjects()
      .then(response =>
        dispatch(getProjectsSuccessThunk(response.data!.projects))
      )
      .catch(error => dispatch(getProjectsFailureThunk(error)));
  };
