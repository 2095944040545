import {
  updateProjectBulkGifteeBoxIssueConfigIssueAmount,
  selectBulkGifteeBoxIssueConfig
} from '@console/actions/actions';
import { getBulkGifteeBoxIssueConfigClaimEstimateThunk } from '@console/actions/thunks/getBulkGifteeBoxIssueConfigClaimEstimate';
import { getLoginUserThunk } from '@console/actions/thunks/getLoginUser';
import { getProjectDetailThunk } from '@console/actions/thunks/getProjectDetail';
import { requestGifteeBoxIssueAmountThunk } from '@console/actions/thunks/requestGifteeBoxIssueAmount';
import { validateRequestGifteeBoxIssueAmountThunk } from '@console/actions/thunks/validateRequestGifteeBoxIssueAmount';
import { goProjectBulkGifteeBoxIssueConfigsNew } from '@console/common/movePages';
import GifteeBoxConfigRow from '@console/components/project/giftConfig/bulkTable/GifteeBoxConfigRow';
import { getCreditLimitBalance } from '@console/selectors/distributionPartner';
import { getSelectedBulkGifteeBoxIssueConfigForDisplay } from '@console/selectors/projectDetail/gifteeBoxConfigs/bulkGifteeBoxConfigs';
import { connect } from 'react-redux';
import type {
  AppThunkDispatch,
  AppThunkAction
} from '@console/actions/thunkType';
import type { AppState } from '@console/reducers';
import type {
  LocalGifteeBoxConfig,
  LocalBulkGifteeBoxIssueConfig
} from '@console/reducers/projects';
import type { NavigateFunction } from 'react-router-dom';

const onChangeBulkGifteeBoxIssueConfigIssueAmount =
  (
    issueAmount: string,
    gifteeBoxConfigUrlCode: UrlCode,
    bulkGifteeBoxIssueConfigUrlCode: UrlCode
  ): AppThunkAction<void> =>
  dispatch => {
    dispatch(
      updateProjectBulkGifteeBoxIssueConfigIssueAmount(
        issueAmount,
        gifteeBoxConfigUrlCode,
        bulkGifteeBoxIssueConfigUrlCode
      )
    );
  };

type BulkGifteeBoxIssueConfigOwnProps = {
  gifteeBoxConfig: LocalGifteeBoxConfig;
  handleGifteeBoxTemplateContentSelectionsModalOpen: () => void;
  handleOpenGifteeBoxConfigMessageCardOptionModalOpen: () => void;
};

const handleIssueAmountRequestClick =
  (
    gifteeBoxConfig: LocalGifteeBoxConfig,
    bulkGifteeBoxIssueConfig: LocalBulkGifteeBoxIssueConfig,
    resoleved: () => void
  ): AppThunkAction<void> =>
  (dispatch, getState) => {
    dispatch(validateRequestGifteeBoxIssueAmountThunk(bulkGifteeBoxIssueConfig))
      .then(() => {
        dispatch(
          getBulkGifteeBoxIssueConfigClaimEstimateThunk(
            gifteeBoxConfig.urlCode,
            bulkGifteeBoxIssueConfig
          )
        );
        dispatch(
          selectBulkGifteeBoxIssueConfig(
            gifteeBoxConfig.urlCode,
            bulkGifteeBoxIssueConfig.urlCode
          )
        );
        resoleved();
      })
      .catch(errorMessage => {
        alert(errorMessage);
      });
  };

const requestGifteeBoxIssueAmount =
  (
    issueAmount: LocalBulkGifteeBoxIssueConfig['issueAmountRequest']['issueAmount'],
    bulkGifteeBoxIssueConfigUrlCode: UrlCode
  ): AppThunkAction<void> =>
  (dispatch, getState) => {
    const state = getState();
    const { projectDetail } = state;

    dispatch(
      requestGifteeBoxIssueAmountThunk(
        issueAmount,
        bulkGifteeBoxIssueConfigUrlCode
      )
    )
      .then(() => {
        if (projectDetail.urlCode) {
          dispatch(getProjectDetailThunk(projectDetail.urlCode));
        }

        if (getCreditLimitBalance(state) !== null) {
          // creditLimitBalance が更新されるので再取得
          dispatch(getLoginUserThunk());
        }
      })
      .catch(() => {});
  };

const goBulkGifteeBoxIssueConfigsNewPage =
  (
    navigate: NavigateFunction,
    gifteeBoxConfig: LocalGifteeBoxConfig
  ): AppThunkAction<void> =>
  (_, getState) => {
    const { selectedProjectUrlCode } = getState();
    if (!!selectedProjectUrlCode) {
      goProjectBulkGifteeBoxIssueConfigsNew(
        navigate,
        selectedProjectUrlCode,
        gifteeBoxConfig.urlCode
      );
    }
  };

const mapStateToProps = (
  state: AppState,
  ownProps: BulkGifteeBoxIssueConfigOwnProps
) => {
  const { gifteeBoxConfig } = ownProps;

  return {
    gifteeBoxConfig,
    selectedBulkGifteeBoxIssueConfig:
      getSelectedBulkGifteeBoxIssueConfigForDisplay(state)
  };
};

const mapDispatchToProps = (
  dispatch: AppThunkDispatch,
  ownProps: BulkGifteeBoxIssueConfigOwnProps
) => ({
  handleGifteeBoxTemplateContentSelectionsModalOpen:
    ownProps.handleGifteeBoxTemplateContentSelectionsModalOpen,
  handleOpenGifteeBoxConfigMessageCardOptionModalOpen:
    ownProps.handleOpenGifteeBoxConfigMessageCardOptionModalOpen,
  onChangeBulkGifteeBoxIssueConfigIssueAmount: (
    event: GenericChangeEvent<string>,
    gifteeBoxConfigUrlCode: UrlCode,
    bulkGifteeBoxIssueConfigUrlCode: UrlCode
  ) =>
    dispatch(
      onChangeBulkGifteeBoxIssueConfigIssueAmount(
        event.target.value,
        gifteeBoxConfigUrlCode,
        bulkGifteeBoxIssueConfigUrlCode
      )
    ),
  requestGifteeBoxIssueAmount: (
    issueAmount: LocalBulkGifteeBoxIssueConfig['issueAmountRequest']['issueAmount'],
    bulkGifteeBoxIssueConfigUrlCode: UrlCode
  ) =>
    dispatch(
      requestGifteeBoxIssueAmount(issueAmount, bulkGifteeBoxIssueConfigUrlCode)
    ),
  goBulkGifteeBoxIssueConfigsNewPage: (
    navigate: NavigateFunction,
    gifteeBoxConfig: LocalGifteeBoxConfig
  ) => dispatch(goBulkGifteeBoxIssueConfigsNewPage(navigate, gifteeBoxConfig)),
  handleIssueAmountRequestClick: (
    gifteeBoxConfig: LocalGifteeBoxConfig,
    bulkGifteeBoxIssueConfig: LocalBulkGifteeBoxIssueConfig,
    resoleved: () => void
  ) =>
    dispatch(
      handleIssueAmountRequestClick(
        gifteeBoxConfig,
        bulkGifteeBoxIssueConfig,
        resoleved
      )
    )
});

export type GifteeBoxConfigRowPropsMappedFromState = ReturnType<
  typeof mapStateToProps
>;
export type GifteeBoxConfigRowPropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(mapStateToProps, mapDispatchToProps)(GifteeBoxConfigRow);
