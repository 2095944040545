import ComponentLoader from '@common/components/ComponentLoader';
import { useIntersectionObserver } from '@common/hooks/useIntersectionObserver';
import getTransitionTimeout from '@common/lib/getTransitionTimeout';
import ContentCard from '@console/components/common/ContentCard';
import Grid from '@mui/material/Grid';
import Grow from '@mui/material/Grow';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { Fragment, useEffect, useRef } from 'react';
import type {
  ContentWithIsSelected,
  ContentsWithIsSelected
} from '@console/selectors/getSelectedProjectDraftForDisplay';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    contentCount: {
      marginBottom: theme.spacing(3)
    },
    contentCard: {
      marginRight: theme.spacing(3),
      marginBottom: theme.spacing(3)
    },
    infiniteScrollTarget: {
      width: '100%'
    }
  });

type ContentsOwnProps = {
  contentsWithIsSelected: ContentsWithIsSelected;
  contentsTotalCount: number;
  isLoading: boolean;
  selectContentHandler: (content: ContentWithIsSelected) => void;
  scrollToBottomHandler: () => void;
};

type ContentsProps = ContentsOwnProps & WithStyles<typeof styles>;

const Contents: React.FC<ContentsProps> = ({
  contentsWithIsSelected,
  contentsTotalCount,
  isLoading,
  selectContentHandler,
  scrollToBottomHandler,
  classes
}) => {
  const infiniteScrollTarget = useRef<HTMLDivElement>(null);
  const { startObserving, isIntersect } =
    useIntersectionObserver(infiniteScrollTarget);

  useEffect(() => {
    startObserving(true);
  }, []);

  useEffect(() => {
    if (!isIntersect) {
      return;
    }
    scrollToBottomHandler();
  }, [isIntersect]);

  const renderBody = () => {
    if (contentsWithIsSelected.length === 0) {
      return null;
    }

    return contentsWithIsSelected.map((contentWithIsSelected, index) => (
      <Fragment key={index}>
        <Grow in={true} timeout={getTransitionTimeout(index)}>
          <div>
            <ContentCard
              content={contentWithIsSelected}
              isSelected={contentWithIsSelected.isSelected}
              onClickHandler={() => selectContentHandler(contentWithIsSelected)}
              className={classes.contentCard}
            />
          </div>
        </Grow>
      </Fragment>
    ));
  };

  return (
    <>
      <Grid item xs={12} className={classes.contentCount}>
        <Typography variant="body1" color="textPrimary" data-cy="contentsCount">
          {`合計：${contentsTotalCount}件`}
        </Typography>
      </Grid>

      <Grid item container justifyContent="flex-start" xs={12}>
        {renderBody()}
        <ComponentLoader isLoading={isLoading} />
      </Grid>

      <div
        className={classes.infiniteScrollTarget}
        ref={infiniteScrollTarget}
      />
    </>
  );
};

export default withStyles(styles)(Contents);
