import { Typography, Divider, Grid } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    internaServerErrorContainer: {
      width: '100%',
      height: '100%',
      padding: theme.spacing(3),
      paddingTop: '20%'
    },
    divider: {
      width: 200
    },
    statusCode: {
      paddingBottom: theme.spacing(2)
    },
    message: {
      paddingTop: theme.spacing(3)
    }
  });

type InternaServerErrorProps = WithStyles<typeof styles>;

const InternaServerError: React.FC<InternaServerErrorProps> = ({ classes }) => (
  <Grid
    className={classes.internaServerErrorContainer}
    container
    direction="column"
    justifyContent="center"
    alignItems="center"
    data-cy="internalServerError"
  >
    <Typography variant="h2" color="textPrimary" className={classes.statusCode}>
      500
    </Typography>
    <Divider className={classes.divider} />
    <Typography variant="body1" color="textPrimary" className={classes.message}>
      お探しのページは一時的にアクセスできない状況です。しばらく経ってからアクセスしてください。
    </Typography>
  </Grid>
);

export default withStyles(styles)(InternaServerError);
