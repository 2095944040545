import 'whatwg-fetch';
import 'core-js/actual/number/is-nan';
import 'core-js/actual/promise/index';
import 'core-js/actual/array/iterator';
import 'core-js/actual/array/from';
import 'core-js/actual/object/assign';
import 'core-js/actual/symbol/for';
import 'intersection-observer';

import renderConsole from '../../../src/project_executor/console';

renderConsole();
