import { gql } from '@apollo/client';
import { internalApiAppClient } from './apolloClient';
import { operationNames } from './operationNames';
import type { MutationErrors } from './mutationErrorType';
import type { FetchResult } from '@apollo/client';
import type { AddingBulkGifteeBoxIssueConfigs } from '@console/reducers/edit/project/gifteeBoxConfigs/addingBulkGifteeBoxIssueConfigs';

const addBulkGifteeBoxIssueConfigsGql = gql`
  mutation ${operationNames.ADD_BULK_GIFTEE_BOX_ISSUE_CONFIGS}($gifteeBoxConfigUrlCode: String!, $bulkGifteeBoxIssueConfigs: [BulkGifteeBoxIssueConfigInput!]!) {
    addBulkGifteeBoxIssueConfigs(input: { gifteeBoxConfigUrlCode: $gifteeBoxConfigUrlCode, bulkGifteeBoxIssueConfigs: $bulkGifteeBoxIssueConfigs }) {
      bulkGifteeBoxIssueConfigs {
        urlCode
      }
      errors {
        __typename
        message
        path
      }
    }
  }
`;

type BulkGifteeBoxIssueConfig = {
  urlCode: UrlCode;
};

type BulkGifteeBoxIssueConfigs = BulkGifteeBoxIssueConfig[];

export type AddBulkGifteeBoxIssueConfigsResponse = FetchResult<{
  addBulkGifteeBoxIssueConfigs: {
    bulkGifteeBoxIssueConfigs: BulkGifteeBoxIssueConfigs;
    errors: MutationErrors;
  };
}>;

export const addBulkGifteeBoxIssueConfigs = (
  gifteeBoxConfigUrlCode: UrlCode,
  bulkGifteeBoxIssueConfigs: AddingBulkGifteeBoxIssueConfigs
): Promise<AddBulkGifteeBoxIssueConfigsResponse> =>
  internalApiAppClient.mutate({
    mutation: addBulkGifteeBoxIssueConfigsGql,
    variables: {
      gifteeBoxConfigUrlCode,
      bulkGifteeBoxIssueConfigs: bulkGifteeBoxIssueConfigs.map(
        bulkGifteeBoxIssueConfig => ({
          ...bulkGifteeBoxIssueConfig,
          requestedIssueAmount: Number(
            bulkGifteeBoxIssueConfig.requestedIssueAmount
          ),
          initialPoint: Number(bulkGifteeBoxIssueConfig.initialPoint)
        })
      )
    }
  });
