import { getProjectContinuousBulkDeliverablesThunk } from '@console/actions/thunks/getProjectContinuousBulkDeliverables';
import { getProjectInitialBulkDeliverablesThunk } from '@console/actions/thunks/getProjectInitialBulkDeliverables';
import Delivery from '@console/components/project/Delivery';
import { getProjectDetailBulkDeliverables } from '@console/selectors/projectDetail/bulkDeliverables';
import { connect } from 'react-redux';
import type { QueryProjectUrlCode } from '@common/api/graphql/getProjectsGql';
import type {
  AppThunkDispatch,
  AppThunkAction
} from '@console/actions/thunkType';
import type { AppState } from '@console/reducers';

const getProjectInitialBulkDeliverables =
  (urlCode: QueryProjectUrlCode): AppThunkAction<Promise<void>> =>
  (dispatch, _getState) =>
    dispatch(getProjectInitialBulkDeliverablesThunk(urlCode)).catch(() => {});

const getProjectContinuousBulkDeliverables =
  (urlCode: QueryProjectUrlCode): AppThunkAction<void> =>
  (dispatch, getState) => {
    const pageInfo = getState().searchInfo.bulkDeliverables?.pageInfo;
    if (pageInfo?.hasNextPage) {
      dispatch(
        getProjectContinuousBulkDeliverablesThunk(urlCode, {
          first: 10,
          after: pageInfo.endCursor === null ? undefined : pageInfo.endCursor
        })
      ).catch(() => {});
    }
  };

const mapStateToProps = (state: AppState) => ({
  bulkDeliverables: getProjectDetailBulkDeliverables(state),
  isBulkDeliverablesLoading: state.loaders.isProjectBulkDeliverablesLoading
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  getProjectInitialBulkDeliverables: (urlCode: QueryProjectUrlCode) =>
    dispatch(getProjectInitialBulkDeliverables(urlCode)),
  getProjectContinuousBulkDeliverables: (urlCode: QueryProjectUrlCode) =>
    dispatch(getProjectContinuousBulkDeliverables(urlCode))
});

export type DeliveryPropsMappedFromState = ReturnType<typeof mapStateToProps>;
export type DeliveryPropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(mapStateToProps, mapDispatchToProps)(Delivery);
