import { connect } from 'react-redux';
import removeTokenFromCookie from '../../../../common/lib/removeTokenFromCookie';
import {
  toggleSettingMenu,
  updateNotificationNumber
} from '../../actions/actions';
import Navbar from '../../components/common/Navbar';
import type { AppThunkAction, AppThunkDispatch } from '../../actions/thunkType';
import type { AppState } from '../../reducers';

const handleSettingMenu =
  (target: (EventTarget & Element) | null = null): AppThunkAction<void> =>
  (dispatch, getState) => {
    const { isSettingOpen } = getState();
    dispatch(toggleSettingMenu(!isSettingOpen, target));
  };

const handleLogout = () => {
  removeTokenFromCookie();
  location.href = 'account/login';
};

const mapStateToProps = (state: AppState) => ({
  distributionPartnerUser: state.distributionPartnerUser,
  distributionPartner: state.distributionPartner,
  notificationNumber: state.notificationNumber,
  isSettingOpen: state.isSettingOpen,
  menuAnchor: state.menuAnchor
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  onGetNotificationNumber: () => dispatch(updateNotificationNumber(1)),
  handleOpenSettingMenu: (event: React.MouseEvent) =>
    dispatch(handleSettingMenu(event.currentTarget)),
  handleCloseSettingMenu: (event: React.MouseEvent) =>
    dispatch(handleSettingMenu()),
  handleLogout: () => handleLogout()
});

export type NavbarPropsMappedFromState = ReturnType<typeof mapStateToProps>;
export type NavbarPropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
