import {
  isGifteeBoxGiftConfig,
  isFixedGiftConfig,
  isChoosableGiftConfig
} from '@console/common/projectDraft/giftConfig';
import { getSelectedProjectDraftSelectedGiftConfig } from '@console/selectors/getSelectedProjectDraftForDisplay';
import { connect } from 'react-redux';
import DeliveryConfig from '../../components/common/DeliveryConfig';
import type { AppThunkDispatch } from '../../actions/thunkType';
import type { AppState } from '../../reducers';

const mapStateToProps = (state: AppState) => ({
  isGifteeBoxGiftConfig: isGifteeBoxGiftConfig(
    getSelectedProjectDraftSelectedGiftConfig(state)
  ),
  isFixedGiftConfig: isFixedGiftConfig(
    getSelectedProjectDraftSelectedGiftConfig(state)
  ),
  isChoosableGiftConfig: isChoosableGiftConfig(
    getSelectedProjectDraftSelectedGiftConfig(state)
  )
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({});

export type DeliveryConfigPropsMappedFromState = ReturnType<
  typeof mapStateToProps
>;
export type DeliveryConfigPropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryConfig);
