import { getLocalePrice } from '@common/lib/getLocalePrice';
import CheckCircle from '@mui/icons-material/CheckCircle';
import { CardContent, Chip } from '@mui/material';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import type { Content } from '@common/api/graphql/getContentsGql';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';
import type { HTMLAttributes } from 'react';

const styles = (theme: Theme): StyleRules => {
  const CARD_WIDTH = 180;
  const CARD_HEIGHT = 260;
  const CARD_HEIGHT_WITH_CHIP = 290;
  const CONTENT_HEIGHT = 180;

  return createStyles({
    card: {
      width: CARD_WIDTH,
      height: CARD_HEIGHT,
      borderRadius: theme.spacing(),
      boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.1)'
    },
    cardWithChip: {
      height: CARD_HEIGHT_WITH_CHIP
    },
    selectedCard: {
      boxShadow: `0px 0px 16px ${theme.palette.primary.main}`
    },
    selectedIcon: {
      position: 'absolute',
      top: theme.spacing(),
      right: theme.spacing(),
      fontSize: 32,
      filter: 'drop-shadow(1px 0px 0px rgba(0,0,0))'
    },
    contentImage: {
      width: CARD_WIDTH,
      height: CONTENT_HEIGHT,
      objectFit: 'contain'
    },
    contentTexts: {
      padding: theme.spacing()
    },
    chip: {
      marginBottom: theme.spacing(0.5),
      verticalAlign: 'text-bottom'
    }
  });
};

type ContentCardOwnProps = {
  content: Content;
  isSelected: boolean;
  onClickHandler?: (event: React.MouseEvent, ...args: any) => void;
};

type ContentCardProps = Pick<HTMLAttributes<HTMLDivElement>, 'className'> &
  ContentCardOwnProps &
  WithStyles<typeof styles>;

const ContentCard: React.FC<ContentCardProps> = ({
  content,
  isSelected,
  onClickHandler,
  className,
  classes
}) => (
  <Card
    className={clsx(
      classes.card,
      {
        [classes.cardWithChip]: content.isLimitedPublished,
        [classes.selectedCard]: isSelected
      },
      className
    )}
  >
    <CardActionArea
      onClick={onClickHandler}
      data-cy={`contentCard-${content.urlCode}`}
    >
      {isSelected && (
        <CheckCircle className={classes.selectedIcon} color="primary" />
      )}
      <div>
        <img className={classes.contentImage} src={content.imageUrl} />
      </div>
      <CardContent className={classes.contentTexts}>
        {content.isLimitedPublished && (
          <Chip
            label="貴社専用"
            color="primary"
            variant="outlined"
            size="small"
            className={classes.chip}
          />
        )}
        <Typography
          align="left"
          color="textPrimary"
          noWrap={true}
          variant="body1"
        >
          {content.internalName}
        </Typography>
        <Typography
          align="left"
          color="textSecondary"
          noWrap={true}
          variant="body2"
        >
          {content.brand.name}
        </Typography>
        <Typography align="left" color="textSecondary" variant="body2">
          {getLocalePrice(content.price)}
        </Typography>
      </CardContent>
    </CardActionArea>
  </Card>
);

export default withStyles(styles)(ContentCard);
