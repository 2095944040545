import getReadableDate from '@common/lib/getReadableDate';
import getTruncatedString from '@common/lib/getTruncatedString';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Grow,
  Grid,
  Card,
  CardActionArea,
  Typography,
  CardActions,
  CardContent,
  IconButton
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { useCallback } from 'react';
import type { ProjectDraftForDisplay } from '@console/selectors/getProjectDraftsForDisplay';
import type { Theme } from '@mui/material';

import type { StyleRules, WithStyles } from '@mui/styles';

const styles = (theme: Theme): StyleRules => {
  const CONTENT_IMAGE_WIDTH = 40;
  const CONTENT_IMAGE_HEIGHT = 40;
  return createStyles({
    card: {
      width: '288px',
      borderRadius: theme.spacing(),
      boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)',
      textAlign: 'left'
    },
    contentThumbnailsWrapper: {
      minHeight: '44px'
    },
    contentThumbnails: {
      marginRight: theme.spacing()
    },
    projectName: {
      marginBottom: theme.spacing(),
      minHeight: '48px'
    },
    advertisingPeriod: {
      display: 'block',
      marginBottom: theme.spacing()
    },
    contentImage: {
      width: CONTENT_IMAGE_WIDTH,
      height: CONTENT_IMAGE_HEIGHT,
      objectFit: 'contain'
    },
    cardActions: {
      justifyContent: 'flex-end'
    }
  });
};

type ProjectDraftOwnProps = {
  projectDraft: ProjectDraftForDisplay;
  transitionTimeout: number;
  onClickHandler: (event: React.MouseEvent, ...args: any) => void;
  onDeleteHandler: (event: React.MouseEvent, ...args: any) => void;
} & WithStyles<typeof styles>;

const ProjectDraft: React.FC<ProjectDraftOwnProps> = ({
  projectDraft,
  transitionTimeout,
  onClickHandler,
  onDeleteHandler,
  classes
}) => {
  const MAX_CONTENT_IMAGE_COUNT = 5;

  const getProjectNameForDisplay = useCallback(
    (projectName: string) =>
      getTruncatedString(projectName, 60, '...') || '未設定',
    []
  );

  const getAdvertisingPeriodForDisplay = useCallback(
    (
      advertisingPeriod: (typeof projectDraft)['projectJson']['advertisingPeriod']
    ) => {
      const startDateOfAd =
        getReadableDate(advertisingPeriod.startDateOfAd) || '未設定';
      const endDateOfAd =
        getReadableDate(advertisingPeriod.endDateOfAd) || '未設定';
      return `掲載期間：${startDateOfAd} 〜 ${endDateOfAd}`;
    },
    []
  );

  const getContentThumbnails = useCallback(
    (contentImageUrls: (typeof projectDraft)['contentImageUrls']) => {
      if (contentImageUrls.length === 0) {
        return (
          <Typography
            variant="caption"
            data-cy="projectDraftContentImage-noContent"
          >
            ギフト未選択
          </Typography>
        );
      }
      const contentThumbnails = contentImageUrls
        .slice(0, MAX_CONTENT_IMAGE_COUNT)
        .map(
          (contentImageUrl, contentIndex) =>
            !!contentImageUrl && (
              <Grid
                item
                className={classes.contentThumbnails}
                key={`projectDraftContentImage-${contentIndex}`}
                data-cy={`projectDraftContentImage-${contentIndex}`}
              >
                <img className={classes.contentImage} src={contentImageUrl} />
              </Grid>
            )
        );
      if (contentImageUrls.length > MAX_CONTENT_IMAGE_COUNT) {
        contentThumbnails.push(
          <Typography variant="body1" key="omissionSymbol">
            ...
          </Typography>
        );
      }
      return contentThumbnails;
    },
    []
  );

  return (
    <Grow
      in={!!projectDraft}
      timeout={projectDraft ? transitionTimeout : undefined}
    >
      <Card className={classes.card}>
        <CardActionArea onClick={onClickHandler} data-cy="projectDraftCard">
          <CardContent>
            <div>
              <Typography
                variant="body1"
                color="textPrimary"
                className={classes.projectName}
                data-cy={`projectDraftName-${projectDraft.urlCode}`}
              >
                {getProjectNameForDisplay(projectDraft.projectJson.name)}
              </Typography>
              <Typography
                variant="caption"
                color="textSecondary"
                className={classes.advertisingPeriod}
                data-cy={`projectDraftAdvertisingPeriod-${projectDraft.urlCode}`}
              >
                {getAdvertisingPeriodForDisplay(
                  projectDraft.projectJson.advertisingPeriod
                )}
              </Typography>
            </div>
            <div
              className={classes.projectActionAreaContentImages}
              data-cy={`projectDraftContentImages-${projectDraft.urlCode}`}
            >
              <Grid container className={classes.contentThumbnailsWrapper}>
                {getContentThumbnails(projectDraft.contentImageUrls)}
              </Grid>
            </div>
          </CardContent>
        </CardActionArea>
        <CardActions className={classes.cardActions}>
          <IconButton
            aria-label="delete"
            data-cy="projectDraftDeleteButton"
            onClick={onDeleteHandler}
            size="large"
          >
            <DeleteIcon />
          </IconButton>
        </CardActions>
      </Card>
    </Grow>
  );
};

export default withStyles(styles)(ProjectDraft);
