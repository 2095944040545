import { createSelector } from 'reselect';
import type { AppState } from '@console/reducers';
import type { LocalProject } from '@console/reducers/projects';

const getProjectDetailGiftDistributionPeriod = (state: AppState) =>
  state.projectDetail.giftDistributionPeriod;

export const getGiftDistributionPeriod = createSelector(
  [getProjectDetailGiftDistributionPeriod],
  (giftDistributionPeriod): LocalProject['giftDistributionPeriod'] => ({
    beginAt: giftDistributionPeriod.beginAt ?? '',
    endAt: giftDistributionPeriod.endAt ?? ''
  })
);
