export const errorMessageForApolloClientNetworkError =
  'ネットワークエラーが発生しました。';

export const errorMessageForApolloClientAuthenticationError =
  '認証エラーが発生しました。';

export const errorMessageForApolloClientGraphqlError =
  'システムエラーが発生しました。';

export const errorMessageForNotFound = 'お探しのページが見つかりません。';

export const createMessage = (key: string): string =>
  `${key}できませんでした。`;
