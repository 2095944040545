import { Contents } from '@common/api/graphql/getContentsGql';
import { AppActionTypes } from '@console/actions/actionType';
import type { ProjectDrafts } from '../reducers/projectDrafts';
import type {
  GiftConfigs,
  TicketType,
  GiftConfig,
  GifteeBoxIssueEstimateOption,
  SelectedGifteeBoxTemplate
} from '../reducers/projectJson/giftConfigs';
import type { LocalGifteeBoxConfig } from '../reducers/projects';
import type {
  ProjectDraft,
  ProjectJson
} from '../reducers/selectedProjectDraft';
import type {
  ProjectsState,
  ProjectState
} from '@/src/project_executor/console/reducers/projects';
import type { AggregateGifteeBoxSelectedContentResults } from '@common/api/graphql/aggregateGifteeBoxSelectedContentPercentageGql';
import type {
  AdvertisingMedium,
  AdvertisingMedia
} from '@common/api/graphql/getAdvertisingMediaGql';
import type { Brands } from '@common/api/graphql/getBrandsGql';
import type { ClaimEstimate } from '@common/api/graphql/getClaimEstimate';
import type {
  Content,
  ContentsConnection
} from '@common/api/graphql/getContentsGql';
import type { DefaultGiftCardConfig } from '@common/api/graphql/getDefaultGiftCardConfig';
import type {
  GeneralAnnouncements,
  GeneralAnnouncement
} from '@common/api/graphql/getGeneralAnnouncementsGql';
import type { GiftCardMainVisuals } from '@common/api/graphql/getGiftCardMainVisualsGql';
import type {
  GiftDistributionChannelCategory,
  GiftDistributionChannelCategories
} from '@common/api/graphql/getGiftDistributionChannelCategoriesGql';
import type { GifteeBoxTemplateBrands } from '@common/api/graphql/getGifteeBoxTemplateBrandsGql';
import type {
  GifteeBoxTemplateContentSelectionsConnection,
  ContentSelectionsFilter
} from '@common/api/graphql/getGifteeBoxTemplateContentSelectionsGql';
import type {
  GifteeBoxTemplates,
  GifteeBoxTemplate
} from '@common/api/graphql/getGifteeBoxTemplatesGql';
import type { IssueCountResult } from '@common/api/graphql/getIssueCountsGql';
import type { Identity } from '@common/api/graphql/getLoginUserGql';
import type { MarketingIndustries } from '@common/api/graphql/getMarketingIndustriesGql';
import type {
  MessageThread,
  MessageThreadUrlCode,
  Node
} from '@common/api/graphql/getMessageThread';
import type { Prefectures } from '@common/api/graphql/getPrefecturesGql';
import type { GetProjectBulkDeliverablesResponse } from '@common/api/graphql/getProjectBulkDeliverablesGql';
import type {
  QueryProjectUrlCode,
  QueryApiAllowedIps,
  QueryApiAllowedIp
} from '@common/api/graphql/getProjectsGql';
import type { SolutionSystems } from '@common/api/graphql/getSolutionSystemsGql';
import type {
  TextContent,
  RequestCode
} from '@common/api/graphql/postMessageThreadMessage';
import type { ProjectAdvertisingMediumRegistration } from '@common/api/graphql/projectTypes';
import type { SnackbarMessageContent } from '@common/components/SnackbarMessage';

import type {
  PROJECT_ADDING_BULK_GIFT_CARD_ISSUE_CONFIG_ERROR_KEY_TYPES,
  PROJECT_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIG_ERROR_KEY_TYPES
} from '@console/reducers/errors';
import type { RelationBetweenHostCompanyAndDP } from '@console/reducers/projectJson/hostCompany';
import type { LocalProject } from '@console/reducers/projects';
import type moment from 'moment';

export const updateIsLoggedIn = (isLoggedIn: boolean) => ({
  type: AppActionTypes.UPDATE_IS_LOGGED_IN as typeof AppActionTypes.UPDATE_IS_LOGGED_IN,
  isLoggedIn
});

export const getLoginUserStart = () => ({
  type: AppActionTypes.GET_LOGIN_USER_START as typeof AppActionTypes.GET_LOGIN_USER_START
});

export const getLoginUserSuccess = (identity: Identity) => ({
  type: AppActionTypes.GET_LOGIN_USER_SUCCESS as typeof AppActionTypes.GET_LOGIN_USER_SUCCESS,
  distributionPartnerUser: identity.distributionPartnerUser,
  distributionPartner: identity.distributionPartner
});

export const getLoginUserFailure = () => ({
  type: AppActionTypes.GET_LOGIN_USER_FAILURE as typeof AppActionTypes.GET_LOGIN_USER_FAILURE
});

export const openDrawer = () => ({
  type: AppActionTypes.OPEN_DRAWER as typeof AppActionTypes.OPEN_DRAWER
});

export const closeDrawer = () => ({
  type: AppActionTypes.CLOSE_DRAWER as typeof AppActionTypes.CLOSE_DRAWER
});

export const updateNotificationNumber = (notificationNumber: number) => ({
  type: AppActionTypes.UPDATE_NOTIFICATION_NUMBER as typeof AppActionTypes.UPDATE_NOTIFICATION_NUMBER,
  notificationNumber
});

export const toggleSettingMenu = (
  isSettingOpen: boolean,
  menuAnchor: (EventTarget & Element) | null
) => ({
  type: AppActionTypes.TOGGLE_SETTING_MENU as typeof AppActionTypes.TOGGLE_SETTING_MENU,
  isSettingOpen,
  menuAnchor
});

export const addProjectDraft = () => ({
  type: AppActionTypes.ADD_PROJECT_DRAFT as typeof AppActionTypes.ADD_PROJECT_DRAFT
});

export const selectProjectDraft = (projectDraft: ProjectDraft) => ({
  type: AppActionTypes.SELECT_PROJECT_DRAFT as typeof AppActionTypes.SELECT_PROJECT_DRAFT,
  projectDraft
});

export const getProjectDraftsStart = () => ({
  type: AppActionTypes.GET_PROJECT_DRAFTS_START as typeof AppActionTypes.GET_PROJECT_DRAFTS_START
});

export const getProjectDraftsSuccess = (projectDrafts: ProjectDrafts) => ({
  type: AppActionTypes.GET_PROJECT_DRAFTS_SUCCESS as typeof AppActionTypes.GET_PROJECT_DRAFTS_SUCCESS,
  projectDrafts
});

export const getProjectDraftsFailure = () => ({
  type: AppActionTypes.GET_PROJECT_DRAFTS_FAILURE as typeof AppActionTypes.GET_PROJECT_DRAFTS_FAILURE
});

export const getProjectDetailStart = () =>
  ({
    type: AppActionTypes.GET_PROJECT_DETAIL_START
  }) as const;

export const getProjectDetailSuccess = (project: LocalProject) =>
  ({
    type: AppActionTypes.GET_PROJECT_DETAIL_SUCCESS,
    project
  }) as const;

export const getProjectDetailFailure = () =>
  ({
    type: AppActionTypes.GET_PROJECT_DETAIL_FAILURE
  }) as const;

export const getProjectsStart = () => ({
  type: AppActionTypes.GET_PROJECTS_START as typeof AppActionTypes.GET_PROJECTS_START
});

export const getProjectsSuccess = (projects: ProjectsState) => ({
  type: AppActionTypes.GET_PROJECTS_SUCCESS as typeof AppActionTypes.GET_PROJECTS_SUCCESS,
  projects
});

export const getProjectsFailure = () => ({
  type: AppActionTypes.GET_PROJECTS_FAILURE as typeof AppActionTypes.GET_PROJECTS_FAILURE
});

export const checkIfProjectExistsStart = () =>
  ({
    type: AppActionTypes.CHECK_IF_PROJECT_EXISTS_START
  }) as const;

export const checkIfProjectExistsSuccess = () =>
  ({
    type: AppActionTypes.CHECK_IF_PROJECT_EXISTS_SUCCESS
  }) as const;

export const checkIfProjectExistsFailure = () =>
  ({
    type: AppActionTypes.CHECK_IF_PROJECT_EXISTS_FAILURE
  }) as const;

export const setSelectedProjectUrlCode = (
  projectUrlCode: QueryProjectUrlCode
) => ({
  type: AppActionTypes.SET_SELECTED_PROJECT_URL_CODE as typeof AppActionTypes.SET_SELECTED_PROJECT_URL_CODE,
  projectUrlCode
});

export const updateProjectBulkGiftCardIssueConfigIssueAmount = (
  issueAmount: number,
  giftCardConfigUrlCode: UrlCode,
  bulkGiftCardIssueConfigIndex: number
) =>
  ({
    type: AppActionTypes.UPDATE_PROJECT_BULK_GIFT_CARD_ISSUE_CONFIG_ISSUE_AMOUNT,
    issueAmount,
    giftCardConfigUrlCode,
    bulkGiftCardIssueConfigIndex
  }) as const;

export const updateSelectedProjectBulkGiftCardIssueConfig = (
  giftCardConfigUrlCode: UrlCode,
  bulkGiftCardIssueConfigIndex: number
) => ({
  type: AppActionTypes.UPDATE_SELECTED_PROJECT_BULK_GIFT_CARD_ISSUE_CONFIG as typeof AppActionTypes.UPDATE_SELECTED_PROJECT_BULK_GIFT_CARD_ISSUE_CONFIG,
  giftCardConfigUrlCode,
  bulkGiftCardIssueConfigIndex
});

export const openProjectGiftCardPreview = (giftCardConfigUrlCode: UrlCode) =>
  ({
    type: AppActionTypes.OPEN_PROJECT_GIFT_CARD_PREVIEW,
    giftCardConfigUrlCode
  }) as const;

export const handleProjectDraftOptionOpen = (
  anchor: (EventTarget & Element) | null,
  index: number
) => ({
  type: AppActionTypes.OPEN_PROJECT_DRAFT_OPTION as typeof AppActionTypes.OPEN_PROJECT_DRAFT_OPTION,
  anchor,
  index
});

export const handleProjectDraftOptionClose = (index: number) => ({
  type: AppActionTypes.CLOSE_PROJECT_DRAFT_OPTION as typeof AppActionTypes.CLOSE_PROJECT_DRAFT_OPTION,
  index
});

export const updateProjectDrafts = (projectDrafts: any) => ({
  type: AppActionTypes.UPDATE_PROJECT_DRAFTS as typeof AppActionTypes.UPDATE_PROJECT_DRAFTS,
  projectDrafts
});

export const updateHostCompanyRelationToDP = (
  relationToDP: RelationBetweenHostCompanyAndDP
) =>
  ({
    type: AppActionTypes.UPDATE_HOST_COMPANY_RELATION_TO_DP,
    relationToDP
  }) as const;

export const updateHostCompanyName = (hostCompanyName: string) =>
  ({
    type: AppActionTypes.UPDATE_HOST_COMPANY_NAME,
    hostCompanyName
  }) as const;

export const updateCampaignName = (campaignName: string) => ({
  type: AppActionTypes.UPDATE_CAMPAIGN_NAME as typeof AppActionTypes.UPDATE_CAMPAIGN_NAME,
  campaignName
});

export const focusOutFromCampaignName = (campaignName: string) =>
  ({
    type: AppActionTypes.FOCUS_OUT_FROM_CAMPAIGN_NAME,
    campaignName
  }) as const;

export const updateCampaignDescription = (campaignDescription: string) => ({
  type: AppActionTypes.UPDATE_CAMPAIGN_DESCRIPTION as typeof AppActionTypes.UPDATE_CAMPAIGN_DESCRIPTION,
  campaignDescription
});

export const updateStartDateOfAd = (startDateOfAd: moment.Moment | null) => ({
  type: AppActionTypes.UPDATE_START_DATE_OF_AD as typeof AppActionTypes.UPDATE_START_DATE_OF_AD,
  startDateOfAd
});

export const updateEndDateOfAd = (endDateOfAd: moment.Moment | null) => ({
  type: AppActionTypes.UPDATE_END_DATE_OF_AD as typeof AppActionTypes.UPDATE_END_DATE_OF_AD,
  endDateOfAd
});

export const updateStartDateOfDistribution = (
  startDateOfDistribution: moment.Moment | null
) => ({
  type: AppActionTypes.UPDATE_START_DATE_OF_DISTRIBUTION as typeof AppActionTypes.UPDATE_START_DATE_OF_DISTRIBUTION,
  startDateOfDistribution
});

export const updateEndDateOfDistribution = (
  endDateOfDistribution: moment.Moment | null
) => ({
  type: AppActionTypes.UPDATE_END_DATE_OF_DISTRIBUTION as typeof AppActionTypes.UPDATE_END_DATE_OF_DISTRIBUTION,
  endDateOfDistribution
});

export const updateCampaignSolutionSystem = (
  campaignSolutionSystem: string
) => ({
  type: AppActionTypes.UPDATE_CAMPAIGN_SOLUTION_SYSTEM_API_BULK as typeof AppActionTypes.UPDATE_CAMPAIGN_SOLUTION_SYSTEM_API_BULK,
  campaignSolutionSystem
});

export const updateCampaignSolutionSystemGcp = (
  campaignSolutionSystem: string
) => ({
  type: AppActionTypes.UPDATE_CAMPAIGN_SOLUTION_SYSTEM_GCP as typeof AppActionTypes.UPDATE_CAMPAIGN_SOLUTION_SYSTEM_GCP,
  campaignSolutionSystem
});

export const updateCampaignSolutionSystemGcpSelected = (
  campaignSolutionSystemGcpSelected: string
) =>
  ({
    type: AppActionTypes.UPDATE_CAMPAIGN_SOLUTION_SYSTEM_GCP_SELECTED,
    campaignSolutionSystemGcpSelected
  }) as const;

export const updateCampaignMarketingIndustry = (
  campaignMarketingIndustry: string
) =>
  ({
    type: AppActionTypes.UPDATE_CAMPAIGN_MARKETING_INDUSTRY,
    campaignMarketingIndustry
  }) as const;

export const toggleCampaignDistributionChannel = (urlCode: string) => ({
  type: AppActionTypes.TOGGLE_CAMPAIGN_DISTRIBUTION_CHANNEL as typeof AppActionTypes.TOGGLE_CAMPAIGN_DISTRIBUTION_CHANNEL,
  urlCode
});

export const updateGiftDistributionChannelDescription = (
  urlCode: string,
  description: string
) =>
  ({
    type: AppActionTypes.UPDATE_GIFT_DISTRIBUTION_CHANNEL_DESCRIPTION,
    urlCode,
    description
  }) as const;

export const toggleCampaignAdvertisingMedium = (urlCode: UrlCode) =>
  ({
    type: AppActionTypes.TOGGLE_CAMPAIGN_ADVERTISING_MEDIUM,
    urlCode
  }) as const;

export const updateCampaignAdvertisingMediumDescription = (
  urlCode: UrlCode,
  description: string
) =>
  ({
    type: AppActionTypes.UPDATE_CAMPAIGN_ADVERTISING_MEDIUM_DESCRIPTION,
    urlCode,
    description
  }) as const;

export const updateNameOfThirdPartyService = (name: string) => ({
  type: AppActionTypes.UPDATE_NAME_OF_THIRD_PARTY_SERVICE as typeof AppActionTypes.UPDATE_NAME_OF_THIRD_PARTY_SERVICE,
  name
});

export const updateUrlOfThirdPartyService = (url: string) => ({
  type: AppActionTypes.UPDATE_URL_OF_THIRD_PARTY_SERVICE as typeof AppActionTypes.UPDATE_URL_OF_THIRD_PARTY_SERVICE,
  url
});

export const updateDescriptionOfThirdPartyService = (description: string) => ({
  type: AppActionTypes.UPDATE_DESCRIPTION_OF_THIRD_PARTY_SERVICE as typeof AppActionTypes.UPDATE_DESCRIPTION_OF_THIRD_PARTY_SERVICE,
  description
});

export const registerProjectDraftStart = () => ({
  type: AppActionTypes.REGISTER_PROJECT_DRAFT_START as typeof AppActionTypes.REGISTER_PROJECT_DRAFT_START
});

export const registerProjectDraftSuccess = (urlCode: string) => ({
  type: AppActionTypes.REGISTER_PROJECT_DRAFT_SUCCESS as typeof AppActionTypes.REGISTER_PROJECT_DRAFT_SUCCESS,
  urlCode
});

export const registerProjectDraftFailure = () => ({
  type: AppActionTypes.REGISTER_PROJECT_DRAFT_FAILURE as typeof AppActionTypes.REGISTER_PROJECT_DRAFT_FAILURE
});

export const setSelectedGiftConfig = (index: number) => ({
  type: AppActionTypes.SET_SELECTED_GIFT_CONFIG as typeof AppActionTypes.SET_SELECTED_GIFT_CONFIG,
  index
});

export const addNewGiftConfig = () => ({
  type: AppActionTypes.ADD_NEW_GIFT_CONFIG as typeof AppActionTypes.ADD_NEW_GIFT_CONFIG
});

export const selectNewGiftConfig = () => ({
  type: AppActionTypes.SELECT_NEW_GIFT_CONFIG as typeof AppActionTypes.SELECT_NEW_GIFT_CONFIG,
  index: 0
});

export const deleteGiftConfig = (index: number) => ({
  type: AppActionTypes.DELETE_GIFT_CONFIG as typeof AppActionTypes.DELETE_GIFT_CONFIG,
  index
});

export const initilizeSelectedGiftConfigIndex = () => ({
  type: AppActionTypes.INITIALIZE_SELECTED_GIFT_CONFIG as typeof AppActionTypes.INITIALIZE_SELECTED_GIFT_CONFIG,
  index: 0
});

export const updateGiftConfigTicketType = (
  ticketType: TicketType,
  index: number
) => ({
  type: AppActionTypes.UPDATE_GIFT_CONFIG_TICKET_TYPE as typeof AppActionTypes.UPDATE_GIFT_CONFIG_TICKET_TYPE,
  ticketType,
  index
});

export const addGiftConfigSelectedContents = (
  content: Content,
  index: number
) => ({
  type: AppActionTypes.ADD_GIFT_CONFIG_SELECTED_CONTENTS as typeof AppActionTypes.ADD_GIFT_CONFIG_SELECTED_CONTENTS,
  content: content.urlCode,
  index
});

export const removeGiftConfigSelectedContents = (
  content: Content,
  index: number
) => ({
  type: AppActionTypes.REMOVE_GIFT_CONFIG_SELECTED_CONTENTS as typeof AppActionTypes.REMOVE_GIFT_CONFIG_SELECTED_CONTENTS,
  content: content.urlCode,
  index
});

export const updateGiftConfigIssueAmount = (
  issueAmount: string,
  index: number
) => ({
  type: AppActionTypes.UPDATE_GIFT_CONFIG_ISSUE_AMOUNT as typeof AppActionTypes.UPDATE_GIFT_CONFIG_ISSUE_AMOUNT,
  issueAmount,
  index
});

export const updateGiftConfigDeliveryDate = (
  deliveryDate: moment.Moment | null,
  index: number
) => ({
  type: AppActionTypes.UPDATE_GIFT_CONFIG_DELIVERY_DATE as typeof AppActionTypes.UPDATE_GIFT_CONFIG_DELIVERY_DATE,
  deliveryDate,
  index
});

export const updateGiftConfigGifteeBoxInitialPoint = (
  gifteeBoxInitialPoint: GiftConfig['gifteeBoxInitialPoint'],
  index: number
) =>
  ({
    type: AppActionTypes.UPDATE_GIFT_CONFIG_GIFTEE_BOX_INITIAL_POINT,
    gifteeBoxInitialPoint,
    index
  }) as const;

export const updateGiftConfigGifteeBoxIssueEstimateOptionsInitialPoint = (
  initialPoint: GifteeBoxIssueEstimateOption['initialPoint'],
  giftConfigindex: number,
  issueEstimateOptionIndex: number
) =>
  ({
    type: AppActionTypes.UPDATE_GIFT_CONFIG_GIFTEE_BOX_ISSUE_ESTIMATE_OPTIONS_INITIAL_POINT,
    initialPoint,
    giftConfigindex,
    issueEstimateOptionIndex
  }) as const;

export const updateGiftConfigGifteeBoxIssueEstimateOptionsIssueAmount = (
  issueAmount: GifteeBoxIssueEstimateOption['issueAmount'],
  giftConfigindex: number,
  issueEstimateOptionIndex: number
) =>
  ({
    type: AppActionTypes.UPDATE_GIFT_CONFIG_GIFTEE_BOX_ISSUE_ESTIMATE_OPTIONS_ISSUE_AMOUNT,
    issueAmount,
    giftConfigindex,
    issueEstimateOptionIndex
  }) as const;

export const addGiftConfigGifteeBoxIssueEstimateOption = (
  giftConfigIndex: number
) =>
  ({
    type: AppActionTypes.ADD_GIFT_CONFIG_GIFTEE_BOX_ISSUE_ESTIMATE_OPTION,
    giftConfigIndex
  }) as const;

export const removeGiftConfigGifteeBoxIssueEstimateOption = (
  giftConfigIndex: number,
  issueEstimateOptionIndex: number
) =>
  ({
    type: AppActionTypes.REMOVE_GIFT_CONFIG_GIFTEE_BOX_ISSUE_ESTIMATE_OPTION,
    giftConfigIndex,
    issueEstimateOptionIndex
  }) as const;

export const updateMessageCardUsing = (
  messageCardUsing: boolean,
  index: number
) => ({
  type: AppActionTypes.UPDATE_GIFT_CONFIG_MESSAGE_CARD_USING as typeof AppActionTypes.UPDATE_GIFT_CONFIG_MESSAGE_CARD_USING,
  messageCardUsing,
  index
});

export const updateGiftConfigSenderName = (
  senderName: string,
  index: number
) => ({
  type: AppActionTypes.UPDATE_GIFT_CONFIG_SENDER_NAME as typeof AppActionTypes.UPDATE_GIFT_CONFIG_SENDER_NAME,
  senderName,
  index
});

export const updateGiftConfigMessage = (message: string, index: number) => ({
  type: AppActionTypes.UPDATE_GIFT_CONFIG_MESSAGE as typeof AppActionTypes.UPDATE_GIFT_CONFIG_MESSAGE,
  message,
  index
});

export const updateGiftConfigMainVisual = (urlCode: string, index: number) => ({
  type: AppActionTypes.UPDATE_GIFT_CONFIG_MAIN_VISUAL as typeof AppActionTypes.UPDATE_GIFT_CONFIG_MAIN_VISUAL,
  urlCode,
  index
});

export const getBrandsStart = () => ({
  type: AppActionTypes.GET_BRANDS_START as typeof AppActionTypes.GET_BRANDS_START
});

export const getBrandsSuccess = (brands: Brands) => ({
  type: AppActionTypes.GET_BRANDS_SUCCESS as typeof AppActionTypes.GET_BRANDS_SUCCESS,
  brands
});

export const getBrandsFailure = () => ({
  type: AppActionTypes.GET_BRANDS_FAILURE as typeof AppActionTypes.GET_BRANDS_FAILURE
});

export const openContentsPage = () =>
  ({
    type: AppActionTypes.OPEN_CONTENTS_PAGE
  }) as const;

export const openProjectDraftContentsSelectionPage = () =>
  ({
    type: AppActionTypes.OPEN_PROJECT_DRAFT_CONTENTS_SELECTION_PAGE
  }) as const;

export const getInitialContentsStart = () =>
  ({
    type: AppActionTypes.GET_INITIAL_CONTENTS_START
  }) as const;

export const getInitialContentsSuccess = (
  contentsConnection: ContentsConnection
) =>
  ({
    type: AppActionTypes.GET_INITIAL_CONTENTS_SUCCESS,
    contentsConnection
  }) as const;

export const getInitialContentsFailure = () =>
  ({
    type: AppActionTypes.GET_INITIAL_CONTENTS_FAILURE
  }) as const;

export const getContinuousContentsStart = () =>
  ({
    type: AppActionTypes.GET_CONTINUOUS_CONTENTS_START
  }) as const;

export const getContinuousContentsSuccess = (
  contentsConnection: ContentsConnection
) =>
  ({
    type: AppActionTypes.GET_CONTINUOUS_CONTENTS_SUCCESS,
    contentsConnection
  }) as const;

export const getContinuousContentsFailure = () =>
  ({
    type: AppActionTypes.GET_CONTINUOUS_CONTENTS_FAILURE
  }) as const;

export const getContentsOfSelectedProjectDraftStart = () =>
  ({
    type: AppActionTypes.GET_CONTENTS_OF_SELECTED_PROJECT_DRAFT_START
  }) as const;

export const getContentsOfSelectedProjectDraftSuccess = (
  contentsConnection: ContentsConnection
) =>
  ({
    type: AppActionTypes.GET_CONTENTS_OF_SELECTED_PROJECT_DRAFT_SUCCESS,
    contentsConnection
  }) as const;

export const getContentsOfSelectedProjectDraftFailure = () =>
  ({
    type: AppActionTypes.GET_CONTENTS_OF_SELECTED_PROJECT_DRAFT_FAILURE
  }) as const;

export const changeContentsBrandFilter = (brands: Brands | undefined) =>
  ({
    type: AppActionTypes.CHANGE_CONTENTS_BRAND_FILTER,
    brands
  }) as const;

export const changeContentsPriceRangeFilter = (
  minPrice: number | undefined,
  maxPrice: number | undefined
) =>
  ({
    type: AppActionTypes.CHANGE_CONTENTS_PRICE_RANGE_FILTER,
    minPrice,
    maxPrice
  }) as const;

export const getGiftCardMainVisualsStart = () => ({
  type: AppActionTypes.GET_GIFT_CARD_MAIN_VISUALS_START as typeof AppActionTypes.GET_GIFT_CARD_MAIN_VISUALS_START
});

export const getGiftCardMainVisualsSuccess = (
  giftCardMainVisuals: GiftCardMainVisuals
) => ({
  type: AppActionTypes.GET_GIFT_CARD_MAIN_VISUALS_SUCCESS as typeof AppActionTypes.GET_GIFT_CARD_MAIN_VISUALS_SUCCESS,
  giftCardMainVisuals
});

export const getGiftCardMainVisualsFailure = () => ({
  type: AppActionTypes.GET_GIFT_CARD_MAIN_VISUALS_FAILURE as typeof AppActionTypes.GET_GIFT_CARD_MAIN_VISUALS_FAILURE
});

export const getSolutionSystemsStart = () => ({
  type: AppActionTypes.GET_SOLUTION_SYSTEMS_START as typeof AppActionTypes.GET_SOLUTION_SYSTEMS_START
});

export const getSolutionSystemsSuccess = (
  solutionSystems: SolutionSystems
) => ({
  type: AppActionTypes.GET_SOLUTION_SYSTEMS_SUCCESS as typeof AppActionTypes.GET_SOLUTION_SYSTEMS_SUCCESS,
  solutionSystems
});

export const getSolutionSystemsFailure = () => ({
  type: AppActionTypes.GET_SOLUTION_SYSTEMS_FAILURE as typeof AppActionTypes.GET_SOLUTION_SYSTEMS_FAILURE
});

export const getMarketingIndustriesStart = () =>
  ({
    type: AppActionTypes.GET_MARKETING_INDUSTRIES_START
  }) as const;

export const getMarketingIndustriesSuccess = (
  marketingIndustries: MarketingIndustries
) => ({
  type: AppActionTypes.GET_MARKETING_INDUSTRIES_SUCCESS as typeof AppActionTypes.GET_MARKETING_INDUSTRIES_SUCCESS,
  marketingIndustries
});

export const getMarketingIndustriesFailure = () => ({
  type: AppActionTypes.GET_MARKETING_INDUSTRIES_FAILURE as typeof AppActionTypes.GET_MARKETING_INDUSTRIES_FAILURE
});

export const getGeneralAnnouncementsStart = () =>
  ({
    type: AppActionTypes.GET_GENERAL_ANNOUNCEMENTS_START
  }) as const;

export const getGeneralAnnouncementsSuccess = (
  generalAnnouncements: GeneralAnnouncements
) =>
  ({
    type: AppActionTypes.GET_GENERAL_ANNOUNCEMENTS_SUCCESS,
    generalAnnouncements
  }) as const;

export const getGeneralAnnouncementsFailure = () =>
  ({
    type: AppActionTypes.GET_GENERAL_ANNOUNCEMENTS_FAILURE
  }) as const;

export const getGiftDistributionChannelCategoriesStart = () =>
  ({
    type: AppActionTypes.GET_GIFT_DISTRIBUTION_CHANNEL_CATEGORIES_START
  }) as const;

export const getGiftDistributionChannelCategoriesSuccess = (
  giftDistributionChannelCategories: GiftDistributionChannelCategories
) =>
  ({
    type: AppActionTypes.GET_GIFT_DISTRIBUTION_CHANNEL_CATEGORIES_SUCCESS,
    giftDistributionChannelCategories
  }) as const;

export const getGiftDistributionChannelCategoriesFailure = () =>
  ({
    type: AppActionTypes.GET_GIFT_DISTRIBUTION_CHANNEL_CATEGORIES_FAILURE
  }) as const;

export const getAdvertisingMediaStart = () =>
  ({
    type: AppActionTypes.GET_ADVERTISING_MEDIA_START
  }) as const;

export const getAdvertisingMediaSuccess = (
  advertisingMedia: AdvertisingMedia
) =>
  ({
    type: AppActionTypes.GET_ADVERTISING_MEDIA_SUCCESS,
    advertisingMedia
  }) as const;

export const getAdvertisingMediaFailure = () =>
  ({
    type: AppActionTypes.GET_ADVERTISING_MEDIA_FAILURE
  }) as const;

export const getPrefecturesStart = () => ({
  type: AppActionTypes.GET_PREFECTURES_START as typeof AppActionTypes.GET_PREFECTURES_START
});

export const getPrefecturesSuccess = (prefectures: Prefectures) => ({
  type: AppActionTypes.GET_PREFECTURES_SUCCESS as typeof AppActionTypes.GET_PREFECTURES_SUCCESS,
  prefectures
});

export const getPrefecturesFailure = () => ({
  type: AppActionTypes.GET_PREFECTURES_FAILURE as typeof AppActionTypes.GET_PREFECTURES_FAILURE
});

export const getMessageThreadStart = () => ({
  type: AppActionTypes.GET_MESSAGE_THREAD_START as typeof AppActionTypes.GET_MESSAGE_THREAD_START
});

export const getMessageThreadSuccess = (messageThread: MessageThread) => ({
  type: AppActionTypes.GET_MESSAGE_THREAD_SUCCESS as typeof AppActionTypes.GET_MESSAGE_THREAD_SUCCESS,
  messageThread
});

export const getMessageThreadFailure = () => ({
  type: AppActionTypes.GET_MESSAGE_THREAD_FAILURE as typeof AppActionTypes.GET_MESSAGE_THREAD_FAILURE
});

export const updateMessageThreadInputMessages = (
  messageThreadUrlCode: MessageThreadUrlCode,
  text: TextContent
) => ({
  type: AppActionTypes.UPDATE_MESSAGE_THREAD_INPUT_MESSAGES as typeof AppActionTypes.UPDATE_MESSAGE_THREAD_INPUT_MESSAGES,
  messageThreadUrlCode,
  text
});

export const addMessageThreadAttachedFiles = (files: File[]) =>
  ({
    type: AppActionTypes.ADD_MESSAGE_THREAD_ATTACHED_FILES,
    files
  }) as const;

export const removeMessageThreadAttachedFiles = (index: number) =>
  ({
    type: AppActionTypes.REMOVE_MESSAGE_THREAD_ATTACHED_FILES,
    index
  }) as const;

export const removeMessageThreadAttachedFilesAll = () =>
  ({
    type: AppActionTypes.REMOVE_MESSAGE_THREAD_ATTACHED_FILES_ALL
  }) as const;

export const postMessageThreadMessageStart = (node: Node) => ({
  type: AppActionTypes.POST_MESSAGE_THREAD_MESSAGE_START as typeof AppActionTypes.POST_MESSAGE_THREAD_MESSAGE_START,
  node
});

export const postMessageThreadMessageSuccess = (
  messageThreadUrlCode: MessageThreadUrlCode
) => ({
  type: AppActionTypes.POST_MESSAGE_THREAD_MESSAGE_SUCCESS as typeof AppActionTypes.POST_MESSAGE_THREAD_MESSAGE_SUCCESS,
  messageThreadUrlCode
});

export const postMessageThreadMessageFailure = (requestCode: RequestCode) => ({
  type: AppActionTypes.POST_MESSAGE_THREAD_MESSAGE_FAILURE as typeof AppActionTypes.POST_MESSAGE_THREAD_MESSAGE_FAILURE,
  requestCode
});

export const receiveMessageThreadPostedMessageSuccess = (node: Node) => ({
  type: AppActionTypes.RECEIVE_MESSAGE_THREAD_POSTED_MESSAGE_SUCCESS as typeof AppActionTypes.RECEIVE_MESSAGE_THREAD_POSTED_MESSAGE_SUCCESS,
  node
});

export const receiveMessageThreadPostedMessageFailure = () => ({
  type: AppActionTypes.RECEIVE_MESSAGE_THREAD_POSTED_MESSAGE_FAILURE as typeof AppActionTypes.RECEIVE_MESSAGE_THREAD_POSTED_MESSAGE_FAILURE
});

export const uploadFileStart = () => ({
  type: AppActionTypes.UPLOAD_FILE_START as typeof AppActionTypes.UPLOAD_FILE_START
});

export const uploadFileSuccess = () => ({
  type: AppActionTypes.UPLOAD_FILE_SUCCESS as typeof AppActionTypes.UPLOAD_FILE_SUCCESS
});

export const uploadFileFailure = () => ({
  type: AppActionTypes.UPLOAD_FILE_FAILURE as typeof AppActionTypes.UPLOAD_FILE_FAILURE
});

export const updateProjectDraftStart = () => ({
  type: AppActionTypes.UPDATE_PROJECT_DRAFT_START as typeof AppActionTypes.UPDATE_PROJECT_DRAFT_START
});

export const updateProjectDraftSuccess = (date: Date) => ({
  type: AppActionTypes.UPDATE_PROJECT_DRAFT_SUCCESS as typeof AppActionTypes.UPDATE_PROJECT_DRAFT_SUCCESS,
  date
});

export const updateProjectDraftFailure = () => ({
  type: AppActionTypes.UPDATE_PROJECT_DRAFT_FAILURE as typeof AppActionTypes.UPDATE_PROJECT_DRAFT_FAILURE
});

export const createProjectStart = () => ({
  type: AppActionTypes.CREATE_PROJECT_START as typeof AppActionTypes.CREATE_PROJECT_START
});

export const createProjectSuccess = (
  snackbarMessageContent: SnackbarMessageContent
) => ({
  type: AppActionTypes.CREATE_PROJECT_SUCCESS as typeof AppActionTypes.CREATE_PROJECT_SUCCESS,
  snackbarMessageContent
});

export const createProjectFailure = () => ({
  type: AppActionTypes.CREATE_PROJECT_FAILURE as typeof AppActionTypes.CREATE_PROJECT_FAILURE
});

export const initilizeProjectDraftSyncStatus = () => ({
  type: AppActionTypes.INITIALIZE_PROJECT_DRAFT_SYNC_STATUS as typeof AppActionTypes.INITIALIZE_PROJECT_DRAFT_SYNC_STATUS
});

export const requestGiftCardIssueAmountStart = () => ({
  type: AppActionTypes.REQUEST_GIFT_CARD_ISSUE_AMOUNT_START as typeof AppActionTypes.REQUEST_GIFT_CARD_ISSUE_AMOUNT_START
});

export const requestGiftCardIssueAmountSuccess = (
  snackbarMessageContent: SnackbarMessageContent
) => ({
  type: AppActionTypes.REQUEST_GIFT_CARD_ISSUE_AMOUNT_SUCCESS as typeof AppActionTypes.REQUEST_GIFT_CARD_ISSUE_AMOUNT_SUCCESS,
  snackbarMessageContent
});

export const requestGiftCardIssueAmountFailure = () => ({
  type: AppActionTypes.REQUEST_GIFT_CARD_ISSUE_AMOUNT_FAILURE as typeof AppActionTypes.REQUEST_GIFT_CARD_ISSUE_AMOUNT_FAILURE
});

export const requestBulkDeliverableUrlStart = () => ({
  type: AppActionTypes.REQUEST_BULK_DELIVERABLE_URL_START as typeof AppActionTypes.REQUEST_BULK_DELIVERABLE_URL_START
});

export const requestBulkDeliverableUrlSuccess = () => ({
  type: AppActionTypes.REQUEST_BULK_DELIVERABLE_URL_SUCCESS as typeof AppActionTypes.REQUEST_BULK_DELIVERABLE_URL_SUCCESS
});

export const requestBulkDeliverableUrlFailure = () => ({
  type: AppActionTypes.REQUEST_BULK_DELIVERABLE_URL_FAILURE as typeof AppActionTypes.REQUEST_BULK_DELIVERABLE_URL_FAILURE
});

export const requestMessageThreadFileUrlStart = () => ({
  type: AppActionTypes.REQUEST_MESSAGE_THREAD_FILE_URL_START as typeof AppActionTypes.REQUEST_MESSAGE_THREAD_FILE_URL_START
});

export const requestMessageThreadFileUrlSuccess = () => ({
  type: AppActionTypes.REQUEST_MESSAGE_THREAD_FILE_URL_SUCCESS as typeof AppActionTypes.REQUEST_MESSAGE_THREAD_FILE_URL_SUCCESS
});

export const requestMessageThreadFileUrlFailure = () => ({
  type: AppActionTypes.REQUEST_MESSAGE_THREAD_FILE_URL_FAILURE as typeof AppActionTypes.REQUEST_BULK_DELIVERABLE_URL_FAILURE
});

export const setSelectedContentUrlCode = (contentUrlCode: Content['urlCode']) =>
  ({
    type: AppActionTypes.SET_SELECTED_CONTENT_URL_CODE,
    contentUrlCode
  }) as const;

export const removeSelectedContentUrlCode = () =>
  ({
    type: AppActionTypes.REMOVE_SELECTED_CONTENT_URL_CODE
  }) as const;

export const getClaimEstimateStart = () =>
  ({
    type: AppActionTypes.GET_CLAIM_ESTIMATE_START
  }) as const;

export const getClaimEstimateSuccess = (claimEstimate: ClaimEstimate) =>
  ({
    type: AppActionTypes.GET_CLAIM_ESTIMATE_SUCCESS,
    claimEstimate
  }) as const;

export const getClaimEstimateFailure = () =>
  ({
    type: AppActionTypes.GET_CLAIM_ESTIMATE_FAILURE
  }) as const;

export const getBulkGiftCardIssueConfigClaimEstimateStart = () =>
  ({
    type: AppActionTypes.GET_PROJECT_BULK_GIFT_CARD_ISSUE_CONFIG_CLAIM_ESTIMATE_START
  }) as const;

export const getBulkGiftCardIssueConfigClaimEstimateSuccess = (
  claimEstimate: ClaimEstimate,
  giftCardConfigUrlCode: UrlCode,
  bulkGiftCardIssueConfigIndex: number
) =>
  ({
    type: AppActionTypes.GET_PROJECT_BULK_GIFT_CARD_ISSUE_CONFIG_CLAIM_ESTIMATE_SUCCESS,
    claimEstimate,
    giftCardConfigUrlCode,
    bulkGiftCardIssueConfigIndex
  }) as const;

export const getBulkGiftCardIssueConfigClaimEstimateFailure = () =>
  ({
    type: AppActionTypes.GET_PROJECT_BULK_GIFT_CARD_ISSUE_CONFIG_CLAIM_ESTIMATE_FAILURE
  }) as const;

export const getDefaultGiftCardConfigStart = () =>
  ({
    type: AppActionTypes.GET_DEFAULT_GIFT_CARD_CONFIG_START
  }) as const;

export const getDefaultGiftCardConfigSuccess = (
  defaultGiftCardConfig: DefaultGiftCardConfig
) =>
  ({
    type: AppActionTypes.GET_DEFAULT_GIFT_CARD_CONFIG_SUCCESS,
    header: defaultGiftCardConfig.header,
    footer: defaultGiftCardConfig.footer
  }) as const;

export const getDefaultGiftCardConfigFailure = () =>
  ({
    type: AppActionTypes.GET_DEFAULT_GIFT_CARD_CONFIG_FAILURE
  }) as const;

export const validateBulkProjectDraft = (projectJson: ProjectJson) =>
  ({
    type: AppActionTypes.VALIDATE_BULK_PROJECT_DRAFT,
    projectJson
  }) as const;

export const validateApiProjectDraft = (projectJson: ProjectJson) =>
  ({
    type: AppActionTypes.VALIDATE_API_PROJECT_DRAFT,
    projectJson
  }) as const;

export const validateGcpProjectDraft = (projectJson: ProjectJson) =>
  ({
    type: AppActionTypes.VALIDATE_GCP_PROJECT_DRAFT,
    projectJson
  }) as const;

export const validateCampaignOverview = (projectJson: ProjectJson) => ({
  type: AppActionTypes.VALIDATE_CAMPAIGN_OVERVIEW as typeof AppActionTypes.VALIDATE_CAMPAIGN_OVERVIEW,
  projectJson
});

export const validateGiftDistribution = (projectJson: ProjectJson) => ({
  type: AppActionTypes.VALIDATE_GIFT_DISTRIBUTION as typeof AppActionTypes.VALIDATE_GIFT_DISTRIBUTION,
  projectJson
});

export const validateGiftConfigsForBulkProject = (
  projectJson: ProjectJson
) => ({
  type: AppActionTypes.VALIDATE_GIFT_CONFIGS_FOR_BULK_PROJECT as typeof AppActionTypes.VALIDATE_GIFT_CONFIGS_FOR_BULK_PROJECT,
  projectJson
});

export const validateGiftConfigsForApiProject = (projectJson: ProjectJson) =>
  ({
    type: AppActionTypes.VALIDATE_GIFT_CONFIGS_FOR_API_PROJECT,
    projectJson
  }) as const;

export const validateGiftConfigsForGcpProject = (projectJson: ProjectJson) =>
  ({
    type: AppActionTypes.VALIDATE_GIFT_CONFIGS_FOR_GCP_PROJECT,
    projectJson
  }) as const;

export const validateFixedContentSelection = (projectJson: ProjectJson) =>
  ({
    type: AppActionTypes.VALIDATE_FIXED_CONTENT_SELECTION,
    projectJson
  }) as const;

export const validateChoosableContentsSelection = (projectJson: ProjectJson) =>
  ({
    type: AppActionTypes.VALIDATE_CHOOSABLE_CONTENTS_SELECTION,
    projectJson
  }) as const;

export const validateGifteeBoxTemplateSelection = (
  selectedGiftConfigIndex: number
) =>
  ({
    type: AppActionTypes.VALIDATE_GIFTEE_BOX_TEMPLATE_SELECTION,
    selectedGiftConfigIndex
  }) as const;

export const validateDeliveryForBulkProject = (projectJson: ProjectJson) => ({
  type: AppActionTypes.VALIDATE_DELIVERY_FOR_BULK_PROJECT as typeof AppActionTypes.VALIDATE_DELIVERY_FOR_BULK_PROJECT,
  projectJson
});

export const validateDeliveryForApiProject = (projectJson: ProjectJson) =>
  ({
    type: AppActionTypes.VALIDATE_DELIVERY_FOR_API_PROJECT,
    projectJson
  }) as const;

export const validateGifteeBoxDeliveryConfigForBulkProject = (
  projectJson: ProjectJson
) =>
  ({
    type: AppActionTypes.VALIDATE_GIFTEE_BOX_DELIVERY_CONFIG_FOR_BULK_PROJECT,
    projectJson
  }) as const;

export const validateGifteeBoxDeliveryConfigForGcpProject = (
  projectJson: ProjectJson
) =>
  ({
    type: AppActionTypes.VALIDATE_GIFTEE_BOX_DELIVERY_CONFIG_FOR_GCP_PROJECT,
    projectJson
  }) as const;

export const validateGifteeBoxDeliveryConfigForApiProject = (
  projectJson: ProjectJson
) =>
  ({
    type: AppActionTypes.VALIDATE_GIFTEE_BOX_DELIVERY_CONFIG_FOR_API_PROJECT,
    projectJson
  }) as const;

export const validateMessageCardConfig = (projectJson: ProjectJson) => ({
  type: AppActionTypes.VALIDATE_MESSAGE_CARD_CONFIG as typeof AppActionTypes.VALIDATE_MESSAGE_CARD_CONFIG,
  projectJson
});

export const validateProjectDraftFailure = (message: string) => ({
  type: AppActionTypes.VALIDATE_PROJECT_DRAFT_FAILURE as typeof AppActionTypes.VALIDATE_PROJECT_DRAFT_FAILURE,
  snackbarMessageContent: message
});

export const validationGiftConfigCommonSuccess = () => ({
  type: AppActionTypes.VALIDATION_GIFT_CONFIG_COMMON_SUCCESS as typeof AppActionTypes.VALIDATION_GIFT_CONFIG_COMMON_SUCCESS
});

export const validationGiftConfigCommonFailure = (errorMessage: string) => ({
  type: AppActionTypes.VALIDATION_GIFT_CONFIG_COMMON_FAILURE as typeof AppActionTypes.VALIDATION_GIFT_CONFIG_COMMON_FAILURE,
  errorMessage
});

export const validationGiftConfigSuccess = (key: string, index: number) => ({
  type: AppActionTypes.VALIDATION_GIFT_CONFIG_SUCCESS as typeof AppActionTypes.VALIDATION_GIFT_CONFIG_SUCCESS,
  key,
  index
});

export const validationGiftConfigFailure = (
  key: string,
  index: number,
  errorMessage: string
) => ({
  type: AppActionTypes.VALIDATION_GIFT_CONFIG_FAILURE as typeof AppActionTypes.VALIDATION_GIFT_CONFIG_FAILURE,
  key,
  index,
  errorMessage
});

export const validateProjectAddingBulkGiftCardIssueConfigs = () =>
  ({
    type: AppActionTypes.VALIDATE_PROJECT_ADDING_BULK_GIFT_CARD_ISSUE_CONFIGS
  }) as const;

export const validateProjectAddingBulkGiftCardIssueConfigsSuccess = (
  key: PROJECT_ADDING_BULK_GIFT_CARD_ISSUE_CONFIG_ERROR_KEY_TYPES,
  index: number
) =>
  ({
    type: AppActionTypes.VALIDATE_PROJECT_ADDING_BULK_GIFT_CARD_ISSUE_CONFIGS_SUCCESS,
    key,
    index
  }) as const;

export const validateProjectAddingBulkGiftCardIssueConfigsFailure = (
  key: PROJECT_ADDING_BULK_GIFT_CARD_ISSUE_CONFIG_ERROR_KEY_TYPES,
  index: number,
  errorMessage: string
) =>
  ({
    type: AppActionTypes.VALIDATE_PROJECT_ADDING_BULK_GIFT_CARD_ISSUE_CONFIGS_FAILURE,
    key,
    index,
    errorMessage
  }) as const;

export const validateProjectAddingBulkGiftCardIssueConfigsTotalSuccess = () =>
  ({
    type: AppActionTypes.VALIDATE_PROJECT_ADDING_BULK_GIFT_CARD_ISSUE_CONFIGS_TOTAL_SUCCESS
  }) as const;

export const validateProjectAddingBulkGiftCardIssueConfigsTotalFailure = (
  errorMessage: string
) =>
  ({
    type: AppActionTypes.VALIDATE_PROJECT_ADDING_BULK_GIFT_CARD_ISSUE_CONFIGS_TOTAL_FAILURE,
    errorMessage
  }) as const;

export const validateProjectAddingBulkGifteeBoxIssueConfigs = () =>
  ({
    type: AppActionTypes.VALIDATE_PROJECT_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIGS
  }) as const;

export const validateProjectAddingBulkGifteeBoxIssueConfigsSuccess = (
  key: PROJECT_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIG_ERROR_KEY_TYPES,
  index: number
) =>
  ({
    type: AppActionTypes.VALIDATE_PROJECT_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIGS_SUCCESS,
    key,
    index
  }) as const;

export const validateProjectAddingBulkGifteeBoxIssueConfigsFailure = (
  key: PROJECT_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIG_ERROR_KEY_TYPES,
  index: number,
  errorMessage: string
) =>
  ({
    type: AppActionTypes.VALIDATE_PROJECT_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIGS_FAILURE,
    key,
    index,
    errorMessage
  }) as const;

export const validateProjectAddingBulkGifteeBoxIssueConfigsTotalSuccess = () =>
  ({
    type: AppActionTypes.VALIDATE_PROJECT_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIGS_TOTAL_SUCCESS
  }) as const;

export const validateProjectAddingBulkGifteeBoxIssueConfigsTotalFailure = (
  errorMessage: string
) =>
  ({
    type: AppActionTypes.VALIDATE_PROJECT_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIGS_TOTAL_FAILURE,
    errorMessage
  }) as const;

export const validationSuccess = (category: string, errorKey: string) => ({
  type: AppActionTypes.VALIDATE_SUCCESS as typeof AppActionTypes.VALIDATE_SUCCESS,
  category,
  errorKey
});

export const validationFailure = (key, error: { [key in string]: string }) => ({
  type: AppActionTypes.VALIDATE_FAILURE as typeof AppActionTypes.VALIDATE_FAILURE,
  key,
  error
});

export const mutationFailure = (errors: string[]) => ({
  type: AppActionTypes.MUTATION_FAILURE as typeof AppActionTypes.MUTATION_FAILURE,
  errors
});

export const graphqlFailure = (errors: string[]) => ({
  type: AppActionTypes.GRAPHQL_FAILURE as typeof AppActionTypes.GRAPHQL_FAILURE,
  errors
});

export const initializeSnackbarMessage = () => ({
  type: AppActionTypes.INITIALIZE_SNACKBAR_MESSAGE as typeof AppActionTypes.INITIALIZE_SNACKBAR_MESSAGE
});

export const excludeInvalidSolutionSystem = () =>
  ({
    type: AppActionTypes.EXCLUDE_INVALID_SOlUTION_SYSTEM
  }) as const;

export const excludeInvalidGiftDistributionChannelCategories = (
  excludedGiftDistributionChannelCategories: GiftDistributionChannelCategory['urlCode'][]
) =>
  ({
    type: AppActionTypes.EXCLUDE_INVALID_GIFT_DISTRIBUTION_CHANNEL_CATEGORIES,
    excludedGiftDistributionChannelCategories
  }) as const;

export const excludeInvalidCampaignAdvertisingMedia = (
  excludedCampaignAdvertisingMedia: ProjectAdvertisingMediumRegistration[]
) =>
  ({
    type: AppActionTypes.EXCLUDE_INVALID_CAMPAIGN_ADVERTISING_MEDIA,
    excludedCampaignAdvertisingMedia
  }) as const;

export const excludeInvalidGiftConfigs = (excludedGiftConfigs: GiftConfigs) =>
  ({
    type: AppActionTypes.EXCLUDE_INVALID_GIFT_CONFIGS,
    excludedGiftConfigs
  }) as const;

export const updateBillingAddressName = (billingAddressName: string) =>
  ({
    type: AppActionTypes.UPDATE_BILLING_ADDRESS_NAME,
    billingAddressName
  }) as const;

export const updateBillingAddressDivision = (billingAddressDivision: string) =>
  ({
    type: AppActionTypes.UPDATE_BILLING_ADDRESS_DIVISION,
    billingAddressDivision
  }) as const;

export const updateBillingAddressPostalCode = (
  billingAddressPostalCode: string
) =>
  ({
    type: AppActionTypes.UPDATE_BILLING_ADDRESS_POSTAL_CODE,
    billingAddressPostalCode
  }) as const;

export const updateBillingAddressPrefectureId = (
  billingAddressPrefectureId: number
) =>
  ({
    type: AppActionTypes.UPDATE_BILLING_ADDRESS_PREFECTURE_ID,
    billingAddressPrefectureId
  }) as const;

export const updateBillingAddressCity = (billingAddressCity: string) =>
  ({
    type: AppActionTypes.UPDATE_BILLING_ADDRESS_CITY,
    billingAddressCity
  }) as const;

export const updateBillingAddressAddress = (billingAddressAddress: string) =>
  ({
    type: AppActionTypes.UPDATE_BILLING_ADDRESS_ADDRESS,
    billingAddressAddress
  }) as const;

export const updateillingAddressOptionalAddress = (
  billingAddressOptionalAddress: string
) =>
  ({
    type: AppActionTypes.UPDATE_BILLING_ADDRESS_OPTIONAL_ADDRESS,
    billingAddressOptionalAddress
  }) as const;

export const searchPostcodesStart = () =>
  ({
    type: AppActionTypes.SEARCH_POSTCODES_START
  }) as const;

export const searchPostcodesSuccess = (
  prefectureId: number,
  city: string,
  address: string,
  postcode: string
) =>
  ({
    type: AppActionTypes.SEARCH_POSTCODES_SUCCESS,
    prefectureId,
    city,
    address,
    postcode
  }) as const;

export const searchPostcodesFailure = () =>
  ({
    type: AppActionTypes.SEARCH_POSTCODES_FAILURE
  }) as const;

export const requestInvoicePdfUrlStart = () =>
  ({
    type: AppActionTypes.REQUEST_INVOICE_PDF_URL_START
  }) as const;

export const requestInvoicePdfUrlSuccess = () =>
  ({
    type: AppActionTypes.REQUEST_INVOICE_PDF_URL_SUCCESS
  }) as const;

export const requestInvoicePdfUrlFailure = () =>
  ({
    type: AppActionTypes.REQUEST_INVOICE_PDF_URL_FAILURE
  }) as const;

export const updateDefaultBillingAddressStart = () =>
  ({
    type: AppActionTypes.UPDATE_DEFAULT_BILLING_ADDRESS_START
  }) as const;

export const updateDefaultBillingAddressSuccess = () =>
  ({
    type: AppActionTypes.UPDATE_DEFAULT_BILLING_ADDRESS_SUCCESS
  }) as const;

export const updateDefaultBillingAddressFailure = () =>
  ({
    type: AppActionTypes.UPDATE_DEFAULT_BILLING_ADDRESS_FAILURE
  }) as const;

export const openDistributionPartnerEdit = () =>
  ({
    type: AppActionTypes.OPEN_DISTRIBUTION_PARTNER_EDIT
  }) as const;

export const updateDistributionPartnerUserName = (name: string) =>
  ({
    type: AppActionTypes.UPDATE_DISTRIBUTION_PARTNER_USER_NAME,
    name
  }) as const;

export const updateDistributionPartnerUserDivision = (division: string) =>
  ({
    type: AppActionTypes.UPDATE_DISTRIBUTION_PARTNER_USER_DIVISION,
    division
  }) as const;

export const updateDistributionPartnerUserPhoneNumber = (phoneNumber: string) =>
  ({
    type: AppActionTypes.UPDATE_DISTRIBUTION_PARTNER_USER_PHONE_NUMBER,
    phoneNumber
  }) as const;

export const updateDistributionPartnerUserStart = () =>
  ({
    type: AppActionTypes.UPDATE_DISTRIBUTION_PARTNER_USER_START
  }) as const;

export const updateDistributionPartnerUserSuccess = (
  snackbarMessageContent: SnackbarMessageContent
) =>
  ({
    type: AppActionTypes.UPDATE_DISTRIBUTION_PARTNER_USER_SUCCESS,
    snackbarMessageContent
  }) as const;

export const updateDistributionPartnerUserFailure = () =>
  ({
    type: AppActionTypes.UPDATE_DISTRIBUTION_PARTNER_USER_FAILURE
  }) as const;

export const openApiIpRestriction = (
  selectedApiAllowedIps: QueryApiAllowedIps
) =>
  ({
    type: AppActionTypes.OPEN_API_IP_RESTRICTION,
    selectedApiAllowedIps
  }) as const;

export const updateEditApiAllowedIpAddress = (
  ipAddress: string,
  apiAllowedIpIndex: number
) =>
  ({
    type: AppActionTypes.UPDATE_EDIT_API_ALLOWED_IP_ADDRESS,
    ipAddress,
    apiAllowedIpIndex
  }) as const;

export const updateApiAllowedIpStart = () =>
  ({
    type: AppActionTypes.UPDATE_API_ALLOWED_IP_START
  }) as const;

export const updateApiAllowedIpSuccess = (
  snackbarMessageContent: SnackbarMessageContent
) =>
  ({
    type: AppActionTypes.UPDATE_API_ALLOWED_IP_SUCCESS,
    snackbarMessageContent
  }) as const;

export const updateApiAllowedIpFailure = () =>
  ({
    type: AppActionTypes.UPDATE_API_ALLOWED_IP_FAILURE
  }) as const;

export const deleteApiAllowedIpStart = () =>
  ({
    type: AppActionTypes.DELETE_API_ALLOWED_IP_START
  }) as const;

export const deleteApiAllowedIpSuccess = (
  snackbarMessageContent: SnackbarMessageContent,
  apiAllowedIpUrlCode: UrlCode
) =>
  ({
    type: AppActionTypes.DELETE_API_ALLOWED_IP_SUCCESS,
    snackbarMessageContent,
    apiAllowedIpUrlCode
  }) as const;

export const deleteApiAllowedIpFailure = () =>
  ({
    type: AppActionTypes.DELETE_API_ALLOWED_IP_FAILURE
  }) as const;

export const addApiAllowedIpStart = () =>
  ({
    type: AppActionTypes.ADD_API_ALLOWED_IP_START
  }) as const;

export const addApiAllowedIpSuccess = (
  snackbarMessageContent: SnackbarMessageContent,
  apiAllowedIp: QueryApiAllowedIp
) =>
  ({
    type: AppActionTypes.ADD_API_ALLOWED_IP_SUCCESS,
    snackbarMessageContent,
    apiAllowedIp
  }) as const;

export const addApiAllowedIpFailure = () =>
  ({
    type: AppActionTypes.ADD_API_ALLOWED_IP_FAILURE
  }) as const;

export const exitApiAllowedIpAddModal = () =>
  ({
    type: AppActionTypes.EXIT_API_ALLOWED_IP_ADD_MODAL
  }) as const;

export const createSampleProjectStart = () =>
  ({
    type: AppActionTypes.CREATE_SAMPLE_PROJECT_START
  }) as const;

export const createSampleProjectSuccess = (
  snackbarMessageContent: SnackbarMessageContent,
  project: ProjectState
) =>
  ({
    type: AppActionTypes.CREATE_SAMPLE_PROJECT_SUCCESS,
    snackbarMessageContent,
    project
  }) as const;

export const createSampleProjectFailure = () =>
  ({
    type: AppActionTypes.CREATE_SAMPLE_PROJECT_FAILURE
  }) as const;

export const deleteProjectDraftStart = () =>
  ({
    type: AppActionTypes.DELETE_PROJECT_DRAFT_START
  }) as const;

export const deleteProjectDraftSuccess = (
  deletedProjectDraftUrlCode: UrlCode,
  snackbarMessageContent: SnackbarMessageContent
) =>
  ({
    type: AppActionTypes.DELETE_PROJECT_DRAFT_SUCCESS,
    deletedProjectDraftUrlCode,
    snackbarMessageContent
  }) as const;

export const deleteProjectDraftFailure = () =>
  ({
    type: AppActionTypes.DELETE_PROJECT_DRAFT_FAILURE
  }) as const;

export const getMasterDataStart = () =>
  ({
    type: AppActionTypes.GET_MASTER_DATA_START
  }) as const;

export const getMasterDataSuccess = (
  brands: Brands,
  giftCardMainVisuals: GiftCardMainVisuals,
  solutionSystems: SolutionSystems,
  marketingIndustries: MarketingIndustries,
  giftDistributionChannelCategories: GiftDistributionChannelCategories,
  advertisingMedia: AdvertisingMedia,
  defaultGiftCardConfig: DefaultGiftCardConfig,
  prefectures: Prefectures
) =>
  ({
    type: AppActionTypes.GET_MASTER_DATA_SUCCESS,
    brands,
    giftCardMainVisuals,
    solutionSystems,
    marketingIndustries,
    giftDistributionChannelCategories,
    advertisingMedia,
    defaultGiftCardConfig,
    prefectures
  }) as const;

export const getMasterDataFailure = () =>
  ({
    type: AppActionTypes.GET_MASTER_DATA_FAILURE
  }) as const;

export const getIssueCountsStart = () =>
  ({
    type: AppActionTypes.GET_ISSUE_COUNTS_START
  }) as const;

export const getIssueCountsSuccess = (issueCountResult: IssueCountResult) =>
  ({
    type: AppActionTypes.GET_ISSUE_COUNTS_SUCCESS,
    issueCountResult
  }) as const;

export const getIssueCountsFailure = () =>
  ({
    type: AppActionTypes.GET_ISSUE_COUNTS_FAILURE
  }) as const;

export const setIssueReportsFilterIssueDateFrom = (from: moment.Moment) =>
  ({
    type: AppActionTypes.SET_ISSUE_REPORTS_FILTER_ISSUE_DATE_FROM,
    from
  }) as const;

export const setIssueReportsFilterIssueDateTo = (to: moment.Moment) =>
  ({
    type: AppActionTypes.SET_ISSUE_REPORTS_FILTER_ISSUE_DATE_TO,
    to
  }) as const;

export const closeProjectGiftConfigBulkTable = () =>
  ({
    type: AppActionTypes.CLOSE_PROJECT_GIFT_CONFIG_BULK_TABLE
  }) as const;

export const copyProjectToProjectDraft = (project: ProjectState) =>
  ({
    type: AppActionTypes.COPY_PROJECT_TO_PROJECT_DRAFT,
    project
  }) as const;

export const copyProjectToProjectDraftSuccess = (
  snackbarMessageContent: SnackbarMessageContent
) =>
  ({
    type: AppActionTypes.COPY_PROJECT_TO_PROJECT_DRAFT_SUCCESS,
    snackbarMessageContent
  }) as const;

export const copyProjectToProjectDraftFailure = (
  snackbarMessageContent: SnackbarMessageContent
) =>
  ({
    type: AppActionTypes.COPY_PROJECT_TO_PROJECT_DRAFT_FAILURE,
    snackbarMessageContent
  }) as const;

export const getGifteeBoxTemplatesStart = () =>
  ({
    type: AppActionTypes.GET_GIFTEE_BOX_TEMPLATES_START
  }) as const;

export const getGifteeBoxTemplatesSuccess = (
  gifteeBoxTemplates: GifteeBoxTemplates
) =>
  ({
    type: AppActionTypes.GET_GIFTEE_BOX_TEMPLATES_SUCCESS,
    gifteeBoxTemplates
  }) as const;

export const getGifteeBoxTemplatesFailure = () =>
  ({
    type: AppActionTypes.GET_GIFTEE_BOX_TEMPLATES_FAILURE
  }) as const;

export const selectGiftConfigGifteeBoxTemplate = (
  gifteeBoxTemplate: GifteeBoxTemplate,
  index: number
) =>
  ({
    type: AppActionTypes.SELECT_GIFT_CONFIG_GIFTEE_BOX_TEMPLATE,
    gifteeBoxTemplate,
    index
  }) as const;

export const deselectGiftConfigGifteeBoxTemplate = (index: number) =>
  ({
    type: AppActionTypes.DESELECT_GIFT_CONFIG_GIFTEE_BOX_TEMPLATE,
    index
  }) as const;

export const getSelectedProjectDraftContentsStart = () =>
  ({
    type: AppActionTypes.GET_SELECTED_PROJECT_DRAFT_CONTENTS_START
  }) as const;

export const getSelectedProjectDraftContentsSuccess = (
  contentsConnection: ContentsConnection
) =>
  ({
    type: AppActionTypes.GET_SELECTED_PROJECT_DRAFT_CONTENTS_SUCCESS,
    contentsConnection
  }) as const;

export const getSelectedProjectDraftContentsFailure = () =>
  ({
    type: AppActionTypes.GET_SELECTED_PROJECT_DRAFT_CONTENTS_FAILURE
  }) as const;

export const getProjectDraftsContentsStart = () =>
  ({
    type: AppActionTypes.GET_PROJECT_DRAFTS_CONTENTS_START
  }) as const;

export const getProjectDraftsContentsSuccess = (
  contentsConnection: ContentsConnection
) =>
  ({
    type: AppActionTypes.GET_PROJECT_DRAFTS_CONTENTS_SUCCESS,
    contentsConnection
  }) as const;

export const getProjectDraftsContentsFailure = () =>
  ({
    type: AppActionTypes.GET_PROJECT_DRAFTS_CONTENTS_FAILURE
  }) as const;

export const updateProjectBulkGifteeBoxIssueConfigIssueAmount = (
  issueAmount: string,
  gifteeBoxConfigUrlCode: UrlCode,
  bulkGifteeBoxIssueConfigUrlCode: UrlCode
) =>
  ({
    type: AppActionTypes.UPDATE_PROJECT_BULK_GIFTEE_BOX_ISSUE_CONFIG_ISSUE_AMOUNT,
    issueAmount,
    gifteeBoxConfigUrlCode,
    bulkGifteeBoxIssueConfigUrlCode
  }) as const;

export const getBulkGifteeBoxIssueConfigClaimEstimateStart = () =>
  ({
    type: AppActionTypes.GET_BULK_GIFTEE_BOX_ISSUE_CONFIG_CLAIM_ESTIMATE_START
  }) as const;

export const getBulkGifteeBoxIssueConfigClaimEstimateSuccess = (
  claimEstimate: ClaimEstimate,
  gifteeBoxConfigUrlCode: UrlCode,
  bulkGifteeBoxIssueConfigUrlCode: UrlCode
) =>
  ({
    type: AppActionTypes.GET_BULK_GIFTEE_BOX_ISSUE_CONFIG_CLAIM_ESTIMATE_SUCCESS,
    claimEstimate,
    gifteeBoxConfigUrlCode,
    bulkGifteeBoxIssueConfigUrlCode
  }) as const;

export const getBulkGifteeBoxIssueConfigClaimEstimateFailure = () =>
  ({
    type: AppActionTypes.GET_BULK_GIFTEE_BOX_ISSUE_CONFIG_CLAIM_ESTIMATE_FAILURE
  }) as const;

export const requestGifteeBoxIssueAmountStart = () =>
  ({
    type: AppActionTypes.REQUEST_GIFTEE_BOX_ISSUE_AMOUNT_START
  }) as const;

export const requestGifteeBoxIssueAmountSuccess = (snackbarMessage: string) =>
  ({
    type: AppActionTypes.REQUEST_GIFTEE_BOX_ISSUE_AMOUNT_SUCCESS,
    snackbarMessageContent: snackbarMessage
  }) as const;

export const requestGifteeBoxIssueAmountFailure = () =>
  ({
    type: AppActionTypes.REQUEST_GIFTEE_BOX_ISSUE_AMOUNT_FAILURE
  }) as const;

export const getGifteeBoxTemplateInitialContentSelectionsStart = () =>
  ({
    type: AppActionTypes.GET_GIFTEE_BOX_TEMPLATE_INITIAL_CONTENT_SELECTIONS_START
  }) as const;

export const getGifteeBoxTemplateInitialContentSelectionsSuccess = (
  contentSelectionsConnection: GifteeBoxTemplateContentSelectionsConnection
) =>
  ({
    type: AppActionTypes.GET_GIFTEE_BOX_TEMPLATE_INITIAL_CONTENT_SELECTIONS_SUCCESS,
    contentSelectionsConnection
  }) as const;

export const getGifteeBoxTemplateInitialContentSelectionsFailure = () =>
  ({
    type: AppActionTypes.GET_GIFTEE_BOX_TEMPLATE_INITIAL_CONTENT_SELECTIONS_FAILURE
  }) as const;

export const selectGifteeBoxTemplateForDraftProject = (
  gifteeBoxTemplate: SelectedGifteeBoxTemplate
) =>
  ({
    type: AppActionTypes.SELECT_GIFTEE_BOX_TEMPLATE_FOR_DRAFT_PROJECT,
    gifteeBoxTemplate
  }) as const;

export const selectGifteeBoxTemplate = (
  gifteeBoxTemplate: LocalGifteeBoxConfig['gifteeBoxTemplate']
) =>
  ({
    type: AppActionTypes.SELECT_GIFTEE_BOX_TEMPLATE,
    gifteeBoxTemplate
  }) as const;

export const deselectGifteeBoxTemplate = () =>
  ({
    type: AppActionTypes.DESELECT_GIFTEE_BOX_TEMPLATE
  }) as const;

export const getGifteeBoxTemplateBrandsStart = () =>
  ({
    type: AppActionTypes.GET_GIFTEE_BOX_TEMPLATE_BRANDS_START
  }) as const;

export const getGifteeBoxTemplateBrandsSuccess = (
  brands: GifteeBoxTemplateBrands
) =>
  ({
    type: AppActionTypes.GET_GIFTEE_BOX_TEMPLATE_BRANDS_SUCCESS,
    brands
  }) as const;

export const getGifteeBoxTemplateBrandsFailure = () =>
  ({
    type: AppActionTypes.GET_GIFTEE_BOX_TEMPLATE_BRANDS_FAILURE
  }) as const;

export const getGifteeBoxTemplateContinuousContentSelectionsStart = () =>
  ({
    type: AppActionTypes.GET_GIFTEE_BOX_TEMPLATE_CONTINUOUS_CONTENT_SELECTIONS_START
  }) as const;

export const getGifteeBoxTemplateContinuousContentSelectionsSuccess = (
  contentSelectionsConnection: GifteeBoxTemplateContentSelectionsConnection
) =>
  ({
    type: AppActionTypes.GET_GIFTEE_BOX_TEMPLATE_CONTINUOUS_CONTENT_SELECTIONS_SUCCESS,
    contentSelectionsConnection
  }) as const;

export const getGifteeBoxTemplateContinuousContentSelectionsFailure = () =>
  ({
    type: AppActionTypes.GET_GIFTEE_BOX_TEMPLATE_CONTINUOUS_CONTENT_SELECTIONS_FAILURE
  }) as const;

export const updateGifteeBoxTemplateBrandFilter = (
  selectedBrandsUrlCodes: ContentSelectionsFilter['brandUrlCodes']
) =>
  ({
    type: AppActionTypes.UPDATE_GIFTEE_BOX_TEMPLATE_BRAND_FILTER,
    selectedBrandsUrlCodes
  }) as const;

export const selectBulkGifteeBoxIssueConfig = (
  gifteeBoxConfigUrlCode: UrlCode,
  bulkGifteeBoxIssueConfigUrlCode: UrlCode
) =>
  ({
    type: AppActionTypes.SELECT_BULK_GIFTEE_BOX_ISSUE_CONFIG,
    gifteeBoxConfigUrlCode,
    bulkGifteeBoxIssueConfigUrlCode
  }) as const;

export const initializeBulkGiftCardIssueConfigsNew = (
  giftCardConfigUrlCode: UrlCode
) =>
  ({
    type: AppActionTypes.INITIALIZE_BULK_GIFT_CARD_ISSUE_CONFIGS_NEW,
    giftCardConfigUrlCode
  }) as const;

export const updateAddingBulkGiftCardIssueConfigRequestedIssueAmount = (
  requestedIssueAmount: string,
  addingBulkGiftCardIssueConfigIndex: number
) =>
  ({
    type: AppActionTypes.UPDATE_ADDING_BULK_GIFT_CARD_ISSUE_CONFIG_REQUESTED_ISSUE_AMOUNT,
    requestedIssueAmount,
    addingBulkGiftCardIssueConfigIndex
  }) as const;

export const updateAddingBulkGiftCardIssueConfigDesiredDeliveryDate = (
  desiredDeliveryDate: moment.Moment | null,
  addingBulkGiftCardIssueConfigIndex: number
) =>
  ({
    type: AppActionTypes.UPDATE_ADDING_BULK_GIFT_CARD_ISSUE_CONFIG_DESIRED_DELIVERY_DATE,
    desiredDeliveryDate,
    addingBulkGiftCardIssueConfigIndex
  }) as const;

export const getBulkIssueConfigsClaimEstimateStart = () =>
  ({
    type: AppActionTypes.GET_BULK_ISSUE_CONFIGS_CLAIM_ESTIMATE_START
  }) as const;

export const getBulkIssueConfigsClaimEstimateSuccess = (
  claimEstimate: ClaimEstimate
) =>
  ({
    type: AppActionTypes.GET_BULK_ISSUE_CONFIGS_CLAIM_ESTIMATE_SUCCESS,
    claimEstimate
  }) as const;

export const getBulkIssueConfigsClaimEstimateFailure = () =>
  ({
    type: AppActionTypes.GET_BULK_ISSUE_CONFIGS_CLAIM_ESTIMATE_FAILURE
  }) as const;

export const aggregateGifteeBoxSelectedContentPercentageStart = () =>
  ({
    type: AppActionTypes.AGGREGATE_GIFTEE_BOX_SELECTED_CONTENT_PERCENTAGE_START
  }) as const;

export const aggregateGifteeBoxSelectedContentPercentageSuccess = (
  results: AggregateGifteeBoxSelectedContentResults
) =>
  ({
    type: AppActionTypes.AGGREGATE_GIFTEE_BOX_SELECTED_CONTENT_PERCENTAGE_SUCCESS,
    results
  }) as const;

export const aggregateGifteeBoxSelectedContentPercentageFailure = () =>
  ({
    type: AppActionTypes.AGGREGATE_GIFTEE_BOX_SELECTED_CONTENT_PERCENTAGE_FAILURE
  }) as const;

export const clearAggregateGifteeBoxSelectedContentPercentage = () =>
  ({
    type: AppActionTypes.CLEAR_AGGREGATE_GIFTEE_BOX_SELECTED_CONTENT_PERCENTAGE
  }) as const;

export const addAddingBulkGiftCardIssueConfig = () =>
  ({
    type: AppActionTypes.ADD_ADDING_BULK_GIFT_CARD_ISSUE_CONFIG
  }) as const;

export const removeAddingBulkGiftCardIssueConfig = (
  addingBulkGiftCardIssueConfigIndex: number
) =>
  ({
    type: AppActionTypes.REMOVE_ADDING_BULK_GIFT_CARD_ISSUE_CONFIG,
    addingBulkGiftCardIssueConfigIndex
  }) as const;

export const initializeBulkGifteeBoxIssueConfigsNew = (
  gifteeBoxConfigUrlCode: UrlCode
) =>
  ({
    type: AppActionTypes.INITIALIZE_BULK_GIFTEE_BOX_ISSUE_CONFIGS_NEW,
    gifteeBoxConfigUrlCode
  }) as const;

export const addBulkGiftCardIssueConfigsStart = () =>
  ({
    type: AppActionTypes.ADD_BULK_GIFT_CARD_ISSUE_CONFIGS_START
  }) as const;

export const addBulkGiftCardIssueConfigsSuccess = (
  snackbarMessageContent: SnackbarMessageContent
) =>
  ({
    type: AppActionTypes.ADD_BULK_GIFT_CARD_ISSUE_CONFIGS_SUCCESS,
    snackbarMessageContent
  }) as const;

export const addBulkGiftCardIssueConfigsFailure = () =>
  ({
    type: AppActionTypes.ADD_BULK_GIFT_CARD_ISSUE_CONFIGS_FAILURE
  }) as const;

export const addBulkGifteeBoxIssueConfigsStart = () =>
  ({
    type: AppActionTypes.ADD_BULK_GIFTEE_BOX_ISSUE_CONFIGS_START
  }) as const;

export const addBulkGifteeBoxIssueConfigsSuccess = (
  snackbarMessageContent: SnackbarMessageContent
) =>
  ({
    type: AppActionTypes.ADD_BULK_GIFTEE_BOX_ISSUE_CONFIGS_SUCCESS,
    snackbarMessageContent
  }) as const;

export const addBulkGifteeBoxIssueConfigsFailure = () =>
  ({
    type: AppActionTypes.ADD_BULK_GIFTEE_BOX_ISSUE_CONFIGS_FAILURE
  }) as const;

export const updateAddingBulkGifteeBoxIssueConfigInitialPoint = (
  initialPoint: string,
  addingBulkGifteeBoxIssueConfigIndex: number
) =>
  ({
    type: AppActionTypes.UPDATE_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIG_INITIAL_POINT,
    initialPoint,
    addingBulkGifteeBoxIssueConfigIndex
  }) as const;

export const updateAddingBulkGifteeBoxIssueConfigRequestedIssueAmount = (
  requestedIssueAmount: string,
  addingBulkGifteeBoxIssueConfigIndex: number
) =>
  ({
    type: AppActionTypes.UPDATE_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIG_REQUESTED_ISSUE_AMOUNT,
    requestedIssueAmount,
    addingBulkGifteeBoxIssueConfigIndex
  }) as const;

export const updateAddingBulkGifteeBoxIssueConfigDeliveryDate = (
  deliveryDate: moment.Moment | null,
  addingBulkGifteeBoxIssueConfigIndex: number
) =>
  ({
    type: AppActionTypes.UPDATE_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIG_DELIVERY_DATE,
    deliveryDate,
    addingBulkGifteeBoxIssueConfigIndex
  }) as const;

export const addAddingBulkGifteeBoxIssueConfig = () =>
  ({
    type: AppActionTypes.ADD_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIG
  }) as const;

export const removeAddingBulkGifteeBoxIssueConfig = (
  addingBulkGifteeBoxIssueConfigIndex: number
) =>
  ({
    type: AppActionTypes.REMOVE_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIG,
    addingBulkGifteeBoxIssueConfigIndex
  }) as const;

export const mountExchangeReport = (gifteeBoxConfigUrlCode: UrlCode) =>
  ({
    type: AppActionTypes.MOUNT_EXCHANGE_REPORT,
    gifteeBoxConfigUrlCode
  }) as const;

export const getProjectInitialBulkDeliverablesStart = () =>
  ({
    type: AppActionTypes.GET_PROJECT_INITIAL_BULK_DELIVERABLES_START
  }) as const;

export const getProjectInitialBulkDeliverablesSuccess = (
  bulkDeliverableConnection: NonNullable<
    GetProjectBulkDeliverablesResponse['data']
  >['project']['confidentialInformation']['bulkDeliverableConnection']
) =>
  ({
    type: AppActionTypes.GET_PROJECT_INITIAL_BULK_DELIVERABLES_SUCCESS,
    bulkDeliverableConnection
  }) as const;

export const getProjectInitialBulkDeliverablesFailure = () =>
  ({
    type: AppActionTypes.GET_PROJECT_INITIAL_BULK_DELIVERABLES_FAILURE
  }) as const;

export const getProjectContinuousBulkDeliverablesStart = () =>
  ({
    type: AppActionTypes.GET_PROJECT_CONTINUOUS_BULK_DELIVERABLES_START
  }) as const;

export const getProjectContinuousBulkDeliverablesSuccess = (
  bulkDeliverableConnection: NonNullable<
    GetProjectBulkDeliverablesResponse['data']
  >['project']['confidentialInformation']['bulkDeliverableConnection']
) =>
  ({
    type: AppActionTypes.GET_PROJECT_CONTINUOUS_BULK_DELIVERABLES_SUCCESS,
    bulkDeliverableConnection
  }) as const;

export const getProjectContinuousBulkDeliverablesFailure = () =>
  ({
    type: AppActionTypes.GET_PROJECT_CONTINUOUS_BULK_DELIVERABLES_FAILURE
  }) as const;

export const exitProjectDetail = () =>
  ({
    type: AppActionTypes.EXIT_PROJECT_DETAIL
  }) as const;
