import { Typography, TableCell, Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => {
  const IMAGE_WIDTH = 72;
  const IMAGE_HEIGHT = 72;
  return {
    tableCell: {
      paddingRight: theme.spacing(4),
      verticalAlign: 'middle'
    },
    contentCell: {
      paddingRight: theme.spacing(2),
      width: theme.spacing(52)
    },
    contentContainer: {
      width: theme.spacing(50),
      whiteSpace: 'pre-line'
    },
    contentImage: {
      width: IMAGE_WIDTH,
      height: IMAGE_HEIGHT,
      objectFit: 'contain',
      verticalAlign: 'middle'
    },
    contentImageContainer: {
      marginRight: theme.spacing(2)
    }
  };
};

type ContentCellsOwnProps = {
  brandName: string;
  contentImageUrl: string;
  contentName: string;
};

type ContentCellsProps = ContentCellsOwnProps & WithStyles<typeof styles>;

const ContentCells: React.FC<ContentCellsProps> = ({
  classes,
  brandName,
  contentImageUrl,
  contentName
}) => (
  <>
    <TableCell
      className={classes.tableCell}
      align="left"
      rowSpan={1}
      data-cy="projectBrandCheckContentBrandName"
    >
      <Typography variant="body2" className={classes.content}>
        {brandName}
      </Typography>
    </TableCell>
    <TableCell className={classes.contentCell} align="left">
      <Grid
        container
        direction="row"
        alignItems="center"
        className={classes.contentContainer}
      >
        <Grid item xs={3} className={classes.contentImageContainer}>
          <img
            className={classes.contentImage}
            src={contentImageUrl}
            data-cy="projectBrandCheckContentImage"
          />
        </Grid>
        <Grid item xs={8}>
          <Typography
            variant="body2"
            className={classes.content}
            data-cy="projectBrandCheckContentName"
          >
            {contentName}
          </Typography>
        </Grid>
      </Grid>
    </TableCell>
  </>
);

export default withStyles(styles)(ContentCells);
