import { setSelectedProjectUrlCode } from '@console/actions/actions';
import { getProjectDetailThunk } from '@console/actions/thunks/getProjectDetail';
import MessageThread from '@console/components/project/MessageThread';
import { getIsMypageProject } from '@console/selectors/projectDetail';
import { connect } from 'react-redux';
import type { QueryProjectUrlCode } from '@common/api/graphql/getProjectsGql';
import type {
  AppThunkDispatch,
  AppThunkAction
} from '@console/actions/thunkType';
import type { AppState } from '@console/reducers';

const initializeSelectedProject =
  (urlCode: QueryProjectUrlCode): AppThunkAction<Promise<boolean>> =>
  (dispatch, getState) =>
    dispatch(getProjectDetailThunk(urlCode))
      .then(() => {
        dispatch(setSelectedProjectUrlCode(urlCode));
        return Promise.resolve(getIsMypageProject(getState()));
      })
      .catch(error => Promise.reject(error));

const mapStateToProps = (state: AppState) => ({
  isSampleProject: state.projectDetail.isSample
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  initializeSelectedProject: (urlCode: QueryProjectUrlCode) =>
    dispatch(initializeSelectedProject(urlCode))
});

export type MessageThreadPropsMappedFromState = ReturnType<
  typeof mapStateToProps
>;
export type MessageThreadPropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(mapStateToProps, mapDispatchToProps)(MessageThread);
