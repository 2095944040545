import { gql } from '@apollo/client';

import { publicApiAppClient } from './apolloClient';
import { operationNames } from './index';
import type { FetchResult } from '@apollo/client';

const rsearchPostcodesGql = gql`
  query ${operationNames.SEARCH_POST_CODES}(
    $postcode: String!
    $startWith: Boolean
    $office: Boolean
  ) {
    searchPostcodes(
      postcode: $postcode
      startWith: $startWith
      office: $office
    ) {
      data {
        postcode
        pref
        city
        town
      }
    }
  }
`;

export type AddressData = {
  postcode: string;
  pref: string;
  city: string;
  town: string;
}[];

export type SearchPostcodesResponse = FetchResult<{
  searchPostcodes: {
    data: AddressData;
  };
}>;

export const searchPostcodes = (
  postcode: string
): Promise<SearchPostcodesResponse> =>
  publicApiAppClient.mutate({
    mutation: rsearchPostcodesGql,
    variables: {
      postcode,
      startWith: false,
      office: true
    }
  });
