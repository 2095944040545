import {
  deselectGifteeBoxTemplate,
  selectGifteeBoxTemplate
} from '@console/actions/actions';
import DisplaySelectedGifteeBoxConfigForBulkProject from '@console/components/project/gifteeBoxConfigs/exchangeReport/DisplaySelectedGifteeBoxConfigForBulkProject';
import { getSelectedGifteeBoxConfig } from '@console/selectors/projectDetail/gifteeBoxConfigs';
import { getSelectedBulkGifteeBoxIssueConfigExchangeEndLabel } from '@console/selectors/projectDetail/gifteeBoxConfigs/bulkGifteeBoxConfigs';
import { connect } from 'react-redux';
import type { AppThunkDispatch } from '@console/actions/thunkType';
import type { AppState } from '@console/reducers';
import type { LocalGifteeBoxConfig } from '@console/reducers/projects';

const mapStateToProps = (state: AppState) => ({
  selectedGifteeBoxConfig: getSelectedGifteeBoxConfig(state),
  exchangeableEndLabel:
    getSelectedBulkGifteeBoxIssueConfigExchangeEndLabel(state)
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  selectGifteeBoxTemplate: (
    gifteeBoxTemplate: LocalGifteeBoxConfig['gifteeBoxTemplate']
  ) => dispatch(selectGifteeBoxTemplate(gifteeBoxTemplate)),
  deselectGifteeBoxTemplate: () => dispatch(deselectGifteeBoxTemplate())
});

export type DisplaySelectedGifteeBoxConfigForBulkProjectMappedFromState =
  ReturnType<typeof mapStateToProps>;
export type DisplaySelectedGifteeBoxConfigForBulkProjectMappedFromDispatch =
  ReturnType<typeof mapDispatchToProps>;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DisplaySelectedGifteeBoxConfigForBulkProject);
