import { getHtmlString } from '@common/lib/getHtmlString';
import { ArrowDropDown } from '@mui/icons-material';
import {
  Grid,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Link
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import DOMPurify from 'dompurify';
import linkifyHtml from 'linkify-html';
import { useCallback } from 'react';
import { scroller } from 'react-scroll';
import type {
  IsChoosingConfirmModalOpen,
  CloseConfirmModal,
  HandleConfirmTicketChoice,
  SelectedChoosableTicket,
  SelectedChoice
} from './localTypes';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    modal: {
      maxWidth: 600,
      maxHeight: '90%',
      margin: 'auto'
    },
    paper: {
      padding: `${theme.spacing(3)} ${theme.spacing(3)} 0`,
      textAlign: 'center'
    },
    giftImage: {
      height: 140,
      width: 140,
      objectFit: 'contain',
      margin: theme.spacing(2),
      borderRadius: theme.spacing()
    },
    giftName: {
      fontWeight: 'bold'
    },
    contentAttentionContainer: {
      margin: `${theme.spacing()} 0`
    },
    contentAttention: {
      textDecorationLine: 'underline',
      color: theme.palette.text.secondary,
      display: 'block'
    },
    contentAttentionDownArrow: {
      fontSize: theme.typography.caption.fontSize
    },
    expiresAt: {
      fontSize: 10,
      marginTop: 5
    },
    warning: {
      color: theme.palette.giftCard.warning.main,
      marginTop: theme.spacing(4)
    },
    dialogActions: {
      width: '100%',
      margin: `${theme.spacing()} 0`,
      height: 50,
      fontSize: 14
    },
    selectButton: {
      width: '100%',
      height: 'inherit',
      backgroundColor: theme.palette.giftCard.background.button,
      color: 'white',
      borderRadius: 50,
      fontWeight: 'bold',
      '&:hover': {
        backgroundColor: '#4F4F4F'
      }
    },
    cancel: {
      fontSize: 14,
      textDecorationLine: 'underline',
      color: theme.palette.text.secondary,
      margin: '5px 0'
    },
    attention: {
      marginTop: theme.spacing(2),
      marginRight: 0,
      marginBottom: theme.spacing(2),
      marginLeft: 0,
      width: '100%'
    },
    attentionTitle: {
      fontWeight: 'bold',
      marginBottom: theme.spacing()
    },
    attentionDescription: {
      padding: theme.spacing(),
      overflowWrap: 'break-word',
      textAlign: 'left',
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.spacing(),
      width: '100%'
    }
  });

interface GiftCardModalOwnProps {
  isChoosingConfirmModalOpen: IsChoosingConfirmModalOpen;
  closeConfirmModal: CloseConfirmModal;
  selectedChoice: SelectedChoice;
  handleConfirmTicketChoice: HandleConfirmTicketChoice;
  selectedChoosableTicket: SelectedChoosableTicket;
}

type GiftCardModalProps = GiftCardModalOwnProps & WithStyles<typeof styles>;

const DIALOG_CONTENT_CONTAINER_ID = 'dialogContentContainerId';
const DIALOG_ATTENTION_ID = 'dialogAttentionId';

const scrollOption = {
  duration: 300,
  delay: 100,
  smooth: true,
  containerId: DIALOG_CONTENT_CONTAINER_ID,
  offset: -10
};

const GiftCardModal: React.FC<GiftCardModalProps> = ({
  isChoosingConfirmModalOpen,
  closeConfirmModal,
  selectedChoice,
  handleConfirmTicketChoice,
  selectedChoosableTicket,
  classes
}) => {
  const handleAttentionScrollLinkClick = useCallback(() => {
    scrollToAttention();
  }, []);

  const scrollToAttention = useCallback(() => {
    scroller.scrollTo(DIALOG_ATTENTION_ID, scrollOption);
  }, []);

  if (!selectedChoice) return null;
  return (
    <Dialog
      open={isChoosingConfirmModalOpen}
      onClose={closeConfirmModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modal}
      scroll="paper"
    >
      <DialogContent className={classes.paper} id={DIALOG_CONTENT_CONTAINER_ID}>
        <Typography variant="subtitle1" className={classes.explanation}>
          下記のギフトでよろしいですか？
        </Typography>
        <img
          src={selectedChoice.content.imageUrl}
          className={classes.giftImage}
          data-cy="selectedChoiceContentImage"
        />
        <Typography variant="body1" className={classes.giftName}>
          {selectedChoice.content.brand.name}
        </Typography>
        <Typography variant="body1" className={classes.giftName}>
          {selectedChoice.content.name}
        </Typography>
        <div className={classes.contentAttentionContainer}>
          <Typography color="textSecondary" variant="caption">
            選択後の利用期限：{selectedChoice.content.availablePeriod}
          </Typography>
          <Link
            onClick={handleAttentionScrollLinkClick}
            variant="caption"
            className={classes.contentAttention}
            data-cy="giftCardConfirmModalAttentionScrollLink"
            underline="hover"
          >
            <Typography variant="caption">
              ギフトの詳細と注意事項はこちら
            </Typography>
            <ArrowDropDown
              fontSize="small"
              color="inherit"
              className={classes.contentAttentionDownArrow}
            />
          </Link>
        </div>

        {/* TODO: 選べるギフトの選択段階では、個別の有効期限はない。個別の有効期限がAPIから取れるようになったら、表示 */}
        {/* <Typography className={classes.expiresAt}>
          {getReadableDate(
            selectedChoosableTicket!.choosingOption.choosableEndAt
          )}
          まで有効
        </Typography> */}
        <Typography variant="body2" className={classes.warning}>
          ※一度選択すると変更出来ません。
        </Typography>
        <DialogActions className={classes.dialogActions}>
          <Button
            className={classes.selectButton}
            onClick={() => handleConfirmTicketChoice()}
            data-cy="giftCardConfirmModalSelectButton"
          >
            このギフトを選択する
          </Button>
        </DialogActions>
        <Link
          className={classes.cancel}
          onClick={closeConfirmModal}
          underline="hover"
        >
          <Typography variant="body1" color="textSecondary">
            キャンセル
          </Typography>
        </Link>
        <Grid
          container
          direction="column"
          alignItems="flex-start"
          spacing={3}
          className={classes.attention}
          id={DIALOG_ATTENTION_ID}
        >
          <Typography variant="body2" className={classes.attentionTitle}>
            ギフト詳細
          </Typography>
          <Typography
            variant="caption"
            display="block"
            className={classes.attentionDescription}
            dangerouslySetInnerHTML={{
              __html: getHtmlString(selectedChoice.content.detail)
            }}
          />
        </Grid>
        <Grid
          container
          direction="column"
          alignItems="flex-start"
          spacing={3}
          className={classes.attention}
        >
          <Typography variant="body2" className={classes.attentionTitle}>
            注意事項
          </Typography>
          <Typography
            variant="caption"
            display="block"
            className={classes.attentionDescription}
            dangerouslySetInnerHTML={{
              __html: getHtmlString(selectedChoice.content.customerAttention)
            }}
          />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(styles)(GiftCardModal);
