import { connect } from 'react-redux';
import MainContents from '../components/MainContents';
import type { AppThunkDispatch } from '../actions/thunkType';
import type { AppState } from '../reducers';

const mapStateToProps = (state: AppState) => ({
  isDrawerOpen: state.isDrawerOpen,
  distributionPartner: state.distributionPartner
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({});

export type MainContentsPropsMappedFromState = ReturnType<
  typeof mapStateToProps
>;
export type MainContentsPropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(mapStateToProps, mapDispatchToProps)(MainContents);
