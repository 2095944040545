import { getApiConfidentialInformation } from '@console/selectors/projectDetail/confidentialInformation';
import { getGiftCardConfigs } from '@console/selectors/projectDetail/giftCardConfigs';
import { createSelector } from 'reselect';
import type {
  GiftCardIssueCount,
  GiftCardIssueCounts
} from '@common/api/graphql/getIssueCountsGql';
import type { AppState } from '@console/reducers';
import type {
  LocalAPIConfidentialInformationGiftCardConfig,
  LocalGiftCardConfig
} from '@console/reducers/projects';

type GiftCardIssueReport = {
  giftCardConfig: GiftCardConfigForIssueReport;
  issueCount: number;
  totalPrice: number;
};
export type GiftCardIssueReports = GiftCardIssueReport[];

type GiftCardConfigForIssueReport = LocalGiftCardConfig & {
  issueCode: NonNullable<
    LocalAPIConfidentialInformationGiftCardConfig['issueCode']
  >;
};

const getGiftCardIssueCounts = (state: AppState): GiftCardIssueCounts =>
  state.giftCardIssueCounts;

export const getApiGiftCardIssueReports = createSelector(
  [getGiftCardIssueCounts, getGiftCardConfigs, getApiConfidentialInformation],
  (
    giftCardIssueCounts,
    giftCardConfigs,
    apiConfidentialInformation
  ): GiftCardIssueReports => {
    if (!apiConfidentialInformation) {
      return [];
    }

    return giftCardIssueCounts.flatMap<GiftCardIssueReport>(
      giftCardIssueCount => {
        const { giftCardConfigUrlCode } = giftCardIssueCount;

        const giftCardConfig = giftCardConfigs.find(
          localGiftCardConfig =>
            localGiftCardConfig.urlCode === giftCardConfigUrlCode
        );

        if (!giftCardConfig) {
          return [];
        }

        const confidentialInformationGiftCardConfig =
          apiConfidentialInformation.giftCardConfigs.find(
            localConfidentialInformationGiftCardConfig =>
              localConfidentialInformationGiftCardConfig.urlCode ===
              giftCardConfig.urlCode
          );

        return createApiGiftCardIssueReport(
          giftCardConfig,
          confidentialInformationGiftCardConfig,
          giftCardIssueCount
        );
      }
    );
  }
);

const createApiGiftCardIssueReport = (
  giftCardConfig: LocalGiftCardConfig,
  confidentialInformationGiftCardConfig:
    | LocalAPIConfidentialInformationGiftCardConfig
    | undefined,
  giftCardIssueCount: GiftCardIssueCount
): GiftCardIssueReport => ({
  giftCardConfig: {
    ...giftCardConfig,
    issueCode: confidentialInformationGiftCardConfig?.issueCode ?? ''
  },
  issueCount: giftCardIssueCount.count,
  totalPrice: giftCardConfig.unitPrice * giftCardIssueCount.count
});
