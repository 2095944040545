import { gql } from '@apollo/client';

export const FRAGMENT_CONTENT = gql`
  fragment ContentFields on Content {
    name
    internalName
    detail
    imageUrl
    brand {
      name
      noteForUsageOptions {
        description
      }
      urlCode
    }
    noteForUsageOptions {
      description
    }
    availablePeriod
    urlCode
    price
    customerAttention
    isLimitedPublished
  }
`;
