import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Button
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import type { QueryApiAllowedIp } from '@common/api/graphql/getProjectsGql';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    dialogCard: {
      padding: theme.spacing(2)
    },
    button: {
      padding: `${theme.spacing()} ${theme.spacing(3)}`
    }
  });

export type ModalOwnProps = {
  open: boolean;
  isUpdate: boolean;
  selectedApiAllowedIp: QueryApiAllowedIp | null;
  closeModal: () => void;
  handleOKClick: () => void;
};

type ModalProps = ModalOwnProps & WithStyles<typeof styles>;

const Modal: React.FC<ModalProps> = ({
  open,
  isUpdate,
  selectedApiAllowedIp,
  closeModal,
  handleOKClick,
  classes
}) => (
  <Dialog
    open={open}
    onClose={closeModal}
    aria-labelledby="modal-title"
    aria-describedby="modal-description"
    data-cy="dialog"
  >
    <div className={classes.dialogCard}>
      <DialogTitle id="modal-title">
        以下のIPアドレス{isUpdate ? 'で更新' : 'を削除'}しますか？
      </DialogTitle>
      <DialogContent>
        <Typography variant="h6" color="textPrimary">
          {selectedApiAllowedIp?.ip}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} color="primary">
          キャンセル
        </Button>
        <Button
          variant="contained"
          onClick={handleOKClick}
          color={isUpdate ? 'primary' : 'secondary'}
          autoFocus
          className={classes.button}
          data-cy="dialogOKClick"
        >
          {isUpdate ? '更新' : '削除'}
        </Button>
      </DialogActions>
    </div>
  </Dialog>
);

export default withStyles(styles)(Modal);
