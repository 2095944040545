import type {
  QueryGiftCardTicketConfig,
  QueryGiftCardFixedTicketConfig,
  QueryGiftCardChoosableTicketConfig
} from '@common/api/graphql/getProjectsGql';

export const isGiftCardFixedTicketConfig = (
  giftCardTicketConfig: QueryGiftCardTicketConfig
): giftCardTicketConfig is QueryGiftCardFixedTicketConfig =>
  !!giftCardTicketConfig.fixedContentOption;

export const isGiftCardChoosableTicketConfig = (
  giftCardTicketConfig: QueryGiftCardTicketConfig
): giftCardTicketConfig is QueryGiftCardChoosableTicketConfig =>
  !!giftCardTicketConfig.choosableContentOption;
