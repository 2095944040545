import CommonError from '@gift_cards/components/CommonError';
import {
  getHasNotFoundError,
  getHasAuthenticationError,
  getHasInternalServerError
} from '@gift_cards/selectors/getError';
import { connect } from 'react-redux';
import type { AppThunkDispatch } from '@gift_cards/actions/thunkType';
import type { AppState } from '@gift_cards/reducers';

const mapStateToProps = (state: AppState) => ({
  hasNotFoundError: getHasNotFoundError(state),
  hasAuthenticationError: getHasAuthenticationError(state),
  hasInternalServerError: getHasInternalServerError(state)
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({});

export type CommonErrorPropsMappedFromState = ReturnType<
  typeof mapStateToProps
>;
export type CommonErrorPropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(mapStateToProps, mapDispatchToProps)(CommonError);
