import { getProjectBulkDeliverables } from '@common/api/graphql/getProjectBulkDeliverablesGql';
import {
  getProjectContinuousBulkDeliverablesStart,
  getProjectContinuousBulkDeliverablesSuccess,
  getProjectContinuousBulkDeliverablesFailure
} from '../actions';
import handleApiErrorIfNeeded from './handleApiErrorIfNeeded';
import type { AppThunkAction } from '../thunkType';
import type { ApiError } from '@common/api/graphql/apolloClient';
import type {
  GetProjectBulkDeliverablesQueryVariables,
  GetProjectBulkDeliverablesResponse
} from '@common/api/graphql/getProjectBulkDeliverablesGql';

export const getProjectContinuousBulkDeliverablesSuccessThunk =
  (
    response: GetProjectBulkDeliverablesResponse
  ): AppThunkAction<Promise<void>> =>
  dispatch => {
    const { bulkDeliverableConnection } =
      response.data!.project.confidentialInformation;

    dispatch(
      getProjectContinuousBulkDeliverablesSuccess(bulkDeliverableConnection)
    );
    return Promise.resolve();
  };

export const getProjectContinuousBulkDeliverablesFailureThunk =
  (error: ApiError): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(getProjectContinuousBulkDeliverablesFailure());
    return dispatch(handleApiErrorIfNeeded(error));
  };

export const getProjectContinuousBulkDeliverablesThunk =
  (
    projectUrlCode: UrlCode,
    searchInfo: GetProjectBulkDeliverablesQueryVariables['searchInfo']
  ): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(getProjectContinuousBulkDeliverablesStart());

    return getProjectBulkDeliverables({
      urlCode: projectUrlCode,
      searchInfo
    })
      .then((response: GetProjectBulkDeliverablesResponse) =>
        dispatch(getProjectContinuousBulkDeliverablesSuccessThunk(response))
      )
      .catch(error =>
        dispatch(getProjectContinuousBulkDeliverablesFailureThunk(error))
      );
  };
