import GiftCardDeliveryConfigContainer from '@console/containers/projectDraft/deliveryConfig/GiftCardDeliveryConfigContainer';
import GifteeBoxDeliveryConfigContainer from '@console/containers/projectDraft/deliveryConfig/GifteeBoxDeliveryConfigContainer';
import Grid from '@mui/material/Grid';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import type {
  DeliveryConfigPropsMappedFromState,
  DeliveryConfigPropsMappedFromDispatch
} from '@console/containers/projectDraft/DeliveryConfigContainer';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => createStyles({});

type DeliveryConfigProps = DeliveryConfigPropsMappedFromState &
  DeliveryConfigPropsMappedFromDispatch &
  WithStyles<typeof styles>;

const DeliveryConfig: React.FC<DeliveryConfigProps> = ({
  classes,
  isGifteeBoxGiftConfig,
  isFixedGiftConfig,
  isChoosableGiftConfig
}) => (
  <Grid container justifyContent="flex-start" alignItems="flex-start">
    {isGifteeBoxGiftConfig ? (
      <GifteeBoxDeliveryConfigContainer />
    ) : isFixedGiftConfig || isChoosableGiftConfig ? (
      <GiftCardDeliveryConfigContainer />
    ) : null}
  </Grid>
);

export default withStyles(styles)(DeliveryConfig);
