import queryString from 'query-string';

export type ActiveStep = {
  parentStep: number;
  childStep: number;
};

export const getActiveStep = (): ActiveStep => {
  const activeStep = queryString.parse(location.search);
  const childStep = Number(activeStep.childStep);
  const parentStep = Number(activeStep.parentStep);

  return { parentStep, childStep };
};
