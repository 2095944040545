import moment from '@common/lib/moment';
import { isGiftCardFixedTicketConfig } from '@console/common/project/giftCardTicketConfig';
import { TICKET_TYPE } from '@console/reducers/projectJson/giftConfigs';
import { getSelectedGiftCardConfig } from '@console/selectors/projectDetail/giftCardConfigs';
import { createSelector } from 'reselect';
import type {
  GiftCardTicket,
  GiftCardTickets,
  MessageCard
} from '@common/api/graphql/getGiftCardGql';
import type {
  QueryGiftCardChoosableTicketConfig,
  QueryGiftCardFixedTicketConfig
} from '@common/api/graphql/getProjectsGql';

export const getMessageCardForPreview = createSelector(
  [getSelectedGiftCardConfig],
  (selectedGiftCardConfig): MessageCard | null =>
    selectedGiftCardConfig?.messageCardConfig
      ? {
          mainVisualUrl:
            selectedGiftCardConfig.messageCardConfig.mainVisualUrl ?? null,
          senderName:
            selectedGiftCardConfig.messageCardConfig.senderName ?? null,
          description:
            selectedGiftCardConfig.messageCardConfig.description ?? null
        }
      : null
);

export const getGiftCardTicketsForPreview = createSelector(
  [getSelectedGiftCardConfig],
  (giftCardConfig): GiftCardTickets =>
    giftCardConfig?.giftCardTicketConfigs.map(giftCardTicketConfig =>
      isGiftCardFixedTicketConfig(giftCardTicketConfig)
        ? getFixedGiftCardTicket(giftCardTicketConfig)
        : getChoosableGiftCardTicket(giftCardTicketConfig)
    ) ?? []
);

export const getGiftWalletStorableForPreview = createSelector(
  [getSelectedGiftCardConfig],
  (giftCardConfig): boolean => giftCardConfig?.giftWalletStorable ?? false
);

const getFixedGiftCardTicket = (
  giftCardTicketConfig: QueryGiftCardFixedTicketConfig
): GiftCardTicket => ({
  urlCode: '',
  ticketType: TICKET_TYPE.FIXED,
  content: {
    name: giftCardTicketConfig.fixedContentOption.content.name,
    imageUrl: giftCardTicketConfig.fixedContentOption.content.imageUrl,
    brand: {
      name: giftCardTicketConfig.fixedContentOption.content.brand.name
    },
    availablePeriod:
      giftCardTicketConfig.fixedContentOption.content.availablePeriod,
    customerAttention:
      giftCardTicketConfig.fixedContentOption.content.customerAttention
  },
  choosingOption: null,
  gift: {
    url: '',
    expiresAt: moment().add(1, 'days').toString()
  }
});

const getChoosableGiftCardTicket = (
  giftCardTicketConfig: QueryGiftCardChoosableTicketConfig
): GiftCardTicket => ({
  urlCode: '',
  ticketType: TICKET_TYPE.CHOOSABLE,
  content: null,
  choosingOption: {
    choosableBeginAt: moment().toString(),
    choosableEndAt: moment().add(1, 'month').endOf('month').toString(),
    choices: giftCardTicketConfig.choosableContentOption.choices.map(
      choice => ({
        choosable: true,
        order: choice.order,
        urlCode: choice.content.urlCode,
        content: choice.content,
        choosablePeriodOption: null
      })
    )
  },
  gift: null
});
