import { getLocalString } from '@common/lib/getLocalString';
import { getLocalePrice } from '@common/lib/getLocalePrice';
import TicketTypeChips from '@console/components/project/common/TicketTypeChips';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
  TableRow,
  TableCell,
  Button,
  Typography,
  Grid,
  TextField
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Fragment } from 'react';
import GiftCardTicketConfigsContents from '../../common/GiftCardTicketConfigsContents';
import type { BulkTablePropsMappedFromDispatch } from '@console/containers/project/giftConfig/BulkTableContainer';
import type { GiftCardConfigForDisplay } from '@console/selectors/projectDetail/giftCardConfigs';
import type { Theme } from '@mui/material';

import type { StyleRules, WithStyles } from '@mui/styles';

const styles = (theme: Theme): StyleRules => ({
  tableCell: {
    padding: theme.spacing(2),
    verticalAlign: 'middle'
  },
  issueAmountContainer: {
    width: theme.spacing(12),
    whiteSpace: 'pre-line'
  },
  textField: {
    minWidth: theme.spacing(10)
  },
  button: {
    margin: theme.spacing(),
    marginRight: 0,
    padding: '5px 8px'
  },
  bulkGiftCardIssueConfigRow: {
    height: 100
  }
});

type GiftCardConfigRowOwnProps = {
  giftCardConfig: GiftCardConfigForDisplay;
  handleOpenGiftCardPreviewButtonClick: (
    giftCardConfig: GiftCardConfigForDisplay
  ) => void;
  onChangeBulkGiftCardIssueConfigIssueAmount: BulkTablePropsMappedFromDispatch['onChangeBulkGiftCardIssueConfigIssueAmount'];
  handleBulkGiftCardIssueConfigClick: (
    giftCardConfig: GiftCardConfigForDisplay,
    bulkGiftCardIssueConfigIndex: number
  ) => void;
  handleAddBulkGiftCardIssueConfigsClick: (
    giftCardConfig: GiftCardConfigForDisplay
  ) => void;
};

type GiftCardConfigRowProps = GiftCardConfigRowOwnProps &
  WithStyles<typeof styles>;

const GiftCardConfigRow: React.FC<GiftCardConfigRowProps> = ({
  giftCardConfig,
  handleOpenGiftCardPreviewButtonClick,
  onChangeBulkGiftCardIssueConfigIssueAmount,
  handleBulkGiftCardIssueConfigClick,
  handleAddBulkGiftCardIssueConfigsClick,
  classes
}) => {
  const getGiftCardPreview = () =>
    giftCardConfig.isGiftCardPreviewVisible ? (
      <Button
        color="primary"
        variant="outlined"
        className={classes.button}
        onClick={() => {
          handleOpenGiftCardPreviewButtonClick(giftCardConfig);
        }}
      >
        <Typography variant="body2">プレビューを見る</Typography>
      </Button>
    ) : (
      '無し'
    );

  const getFixedIssueAmount = (
    bulkGiftCardIssueConfig,
    bulkGiftCardIssueConfigIndex
  ) =>
    bulkGiftCardIssueConfig.fixedIssueConfig ? (
      getLocalString(bulkGiftCardIssueConfig.fixedIssueConfig.issueAmount)
    ) : bulkGiftCardIssueConfig.isIssueAmountRequested ? (
      getLocalString(bulkGiftCardIssueConfig.issueAmountRequest.issueAmount)
    ) : (
      <Grid
        className={classes.issueAmountContainer}
        container
        justifyContent="flex-end"
      >
        <TextField
          variant="standard"
          required
          id="bulkGiftCardIssueConfigRowfixedIssueAmount"
          name="bulkGiftCardIssueConfigRowfixedIssueAmount"
          type="number"
          value={bulkGiftCardIssueConfig.issueAmountRequest.issueAmount}
          inputProps={{ min: '0' }}
          onChange={event =>
            onChangeBulkGiftCardIssueConfigIssueAmount(
              event,
              giftCardConfig,
              bulkGiftCardIssueConfigIndex
            )
          }
          className={classes.textField}
          disabled={!giftCardConfig.creativeCheckApproved}
          data-cy={`bulkGiftCardIssueConfigRowIssueAmountRequestTextField-${bulkGiftCardIssueConfig.urlCode}`}
        />
        <Button
          color="primary"
          variant="contained"
          className={classes.button}
          disabled={!giftCardConfig.creativeCheckApproved}
          data-cy={`bulkGiftCardIssueConfigRowIssueAmountRequestButton-${bulkGiftCardIssueConfig.urlCode}`}
          onClick={() =>
            handleBulkGiftCardIssueConfigClick(
              giftCardConfig,
              bulkGiftCardIssueConfigIndex
            )
          }
        >
          <Typography variant="body2">数量確定</Typography>
        </Button>
      </Grid>
    );

  const getFixedAmount = bulkGiftCardIssueConfig =>
    bulkGiftCardIssueConfig.fixedIssueConfig
      ? getLocalePrice(
          bulkGiftCardIssueConfig.fixedIssueConfig.issueAmount *
            giftCardConfig.unitPrice
        )
      : bulkGiftCardIssueConfig.isIssueAmountRequested
        ? getLocalePrice(
            bulkGiftCardIssueConfig.issueAmountRequest.issueAmount *
              giftCardConfig.unitPrice
          )
        : null;

  return (
    <>
      {giftCardConfig.bulkGiftCardIssueConfigs.map(
        (bulkGiftCardIssueConfig, bulkGiftCardIssueConfigIndex) => (
          <TableRow
            key={bulkGiftCardIssueConfig.urlCode}
            className={classes.bulkGiftCardIssueConfigRow}
            data-cy={`bulkGiftCardIssueConfigRow-${giftCardConfig.urlCode}`}
          >
            {bulkGiftCardIssueConfigIndex === 0 ? (
              <>
                <TableCell
                  className={classes.tableCell}
                  align="center"
                  rowSpan={giftCardConfig.bulkGiftCardIssueConfigs.length}
                  data-cy={`giftCardConfigRowTicketType-${giftCardConfig.urlCode}`}
                >
                  <TicketTypeChips giftCardConfig={giftCardConfig} />
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  align="left"
                  rowSpan={giftCardConfig.bulkGiftCardIssueConfigs.length}
                  data-cy={`giftCardConfigRowContent-${giftCardConfig.urlCode}`}
                >
                  <GiftCardTicketConfigsContents
                    giftCardConfig={giftCardConfig}
                    imageDownloadable={true}
                  />
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  align="left"
                  rowSpan={giftCardConfig.bulkGiftCardIssueConfigs.length}
                  data-cy={`giftCardConfigRowPreview-${giftCardConfig.urlCode}`}
                >
                  {getGiftCardPreview()}
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  align="right"
                  rowSpan={giftCardConfig.bulkGiftCardIssueConfigs.length}
                  data-cy={`giftCardConfigRowContentCurrentPrice-${giftCardConfig.urlCode}`}
                >
                  {getLocalePrice(giftCardConfig.unitPrice)}
                </TableCell>
              </>
            ) : null}
            <TableCell align="right" className={classes.tableCell}>
              <Typography
                variant="subtitle2"
                data-cy={`bulkGiftCardIssueConfigRowRequestedIssueAmount-${bulkGiftCardIssueConfig.urlCode}`}
              >
                {getLocalString(bulkGiftCardIssueConfig.requestedIssueAmount)}
              </Typography>
            </TableCell>
            <TableCell align="right" className={classes.tableCell}>
              <Typography
                variant="subtitle2"
                data-cy={`bulkGiftCardIssueConfigRowRequestedAmount-${bulkGiftCardIssueConfig.urlCode}`}
              >
                {getLocalePrice(
                  bulkGiftCardIssueConfig.requestedIssueAmount *
                    giftCardConfig.unitPrice
                )}
              </Typography>
            </TableCell>
            <TableCell align="right" className={classes.tableCell}>
              <Typography
                variant="subtitle2"
                data-cy={`bulkGiftCardIssueConfigRowFixedIssueAmount-${bulkGiftCardIssueConfig.urlCode}`}
              >
                {getFixedIssueAmount(
                  bulkGiftCardIssueConfig,
                  bulkGiftCardIssueConfigIndex
                )}
              </Typography>
            </TableCell>
            <TableCell align="right" className={classes.tableCell}>
              <Typography
                variant="subtitle2"
                data-cy={`bulkGiftCardIssueConfigRowFixedAmount-${bulkGiftCardIssueConfig.urlCode}`}
              >
                {getFixedAmount(bulkGiftCardIssueConfig)}
              </Typography>
            </TableCell>
            <TableCell align="right" className={classes.tableCell}>
              <Typography
                variant="subtitle2"
                data-cy={`bulkGiftCardIssueConfigRowDeliveryDate-${bulkGiftCardIssueConfig.urlCode}`}
              >
                {bulkGiftCardIssueConfig.deliveryDate}
              </Typography>
            </TableCell>
            {bulkGiftCardIssueConfigIndex === 0 ? (
              <TableCell
                className={classes.tableCell}
                align="center"
                rowSpan={giftCardConfig.bulkGiftCardIssueConfigs.length}
              >
                <Button
                  color="primary"
                  variant="contained"
                  disabled={
                    !giftCardConfig.creativeCheckApproved ||
                    !giftCardConfig.isIssuable
                  }
                  data-cy={`bulkGiftCardIssueConfigRowAddButton-${giftCardConfig.urlCode}`}
                  onClick={() =>
                    handleAddBulkGiftCardIssueConfigsClick(giftCardConfig)
                  }
                >
                  追加発注
                  <KeyboardArrowRightIcon />
                </Button>
                {giftCardConfig.creativeCheckApproved &&
                  !giftCardConfig.isIssuable && (
                    <Typography>
                      本ギフトは販売停止中のため、追加発注できません。
                    </Typography>
                  )}
              </TableCell>
            ) : null}
          </TableRow>
        )
      )}
    </>
  );
};

export default withStyles(styles)(GiftCardConfigRow);
