import { combineReducers } from 'redux';
import { AppActionTypes } from '../actions/actionType';
import type { AppAction } from '../actions/actionType';

const initialState = {
  isAppLoading: false,
  isProjectCardLoading: false,
  isProjectDraftCardLoading: false,
  isGifteeBoxTemplateContentSelectionsLoading: false,
  isContentsLoading: false,
  isAPIIssueReportsLoading: false,
  isProjectBulkDeliverablesLoading: false
};

const isAppLoading = (
  state: boolean = initialState.isAppLoading,
  action: AppAction
) => {
  switch (action.type) {
    case AppActionTypes.GET_LOGIN_USER_START:
    case AppActionTypes.GET_BRANDS_START:
    case AppActionTypes.GET_GIFT_CARD_MAIN_VISUALS_START:
    case AppActionTypes.GET_SOLUTION_SYSTEMS_START:
    case AppActionTypes.GET_MARKETING_INDUSTRIES_START:
    case AppActionTypes.GET_GENERAL_ANNOUNCEMENTS_START:
    case AppActionTypes.GET_GIFT_DISTRIBUTION_CHANNEL_CATEGORIES_START:
    case AppActionTypes.REGISTER_PROJECT_DRAFT_START:
    case AppActionTypes.CREATE_PROJECT_START:
    case AppActionTypes.REQUEST_GIFT_CARD_ISSUE_AMOUNT_START:
    case AppActionTypes.REQUEST_BULK_DELIVERABLE_URL_START:
    case AppActionTypes.GET_CLAIM_ESTIMATE_START:
    case AppActionTypes.GET_PROJECT_BULK_GIFT_CARD_ISSUE_CONFIG_CLAIM_ESTIMATE_START:
    case AppActionTypes.GET_DEFAULT_GIFT_CARD_CONFIG_START:
    case AppActionTypes.GET_PREFECTURES_START:
    case AppActionTypes.SEARCH_POSTCODES_START:
    case AppActionTypes.REQUEST_INVOICE_PDF_URL_START:
    case AppActionTypes.UPDATE_DEFAULT_BILLING_ADDRESS_START:
    case AppActionTypes.UPDATE_DISTRIBUTION_PARTNER_USER_START:
    case AppActionTypes.UPDATE_API_ALLOWED_IP_START:
    case AppActionTypes.DELETE_API_ALLOWED_IP_START:
    case AppActionTypes.ADD_API_ALLOWED_IP_START:
    case AppActionTypes.CREATE_SAMPLE_PROJECT_START:
    case AppActionTypes.DELETE_PROJECT_DRAFT_START:
    case AppActionTypes.GET_MASTER_DATA_START:
    case AppActionTypes.UPLOAD_FILE_START:
    case AppActionTypes.CHECK_IF_PROJECT_EXISTS_START:
    case AppActionTypes.GET_PROJECT_DRAFTS_CONTENTS_START:
    case AppActionTypes.GET_SELECTED_PROJECT_DRAFT_CONTENTS_START:
    case AppActionTypes.GET_BULK_GIFTEE_BOX_ISSUE_CONFIG_CLAIM_ESTIMATE_START:
    case AppActionTypes.REQUEST_GIFTEE_BOX_ISSUE_AMOUNT_START:
    case AppActionTypes.GET_GIFTEE_BOX_TEMPLATE_INITIAL_CONTENT_SELECTIONS_START:
    case AppActionTypes.ADD_BULK_GIFT_CARD_ISSUE_CONFIGS_START:
    case AppActionTypes.ADD_BULK_GIFTEE_BOX_ISSUE_CONFIGS_START:
    case AppActionTypes.AGGREGATE_GIFTEE_BOX_SELECTED_CONTENT_PERCENTAGE_START:
      return true;
    case AppActionTypes.GET_LOGIN_USER_SUCCESS:
    case AppActionTypes.GET_LOGIN_USER_FAILURE:
    case AppActionTypes.GET_BRANDS_SUCCESS:
    case AppActionTypes.GET_BRANDS_FAILURE:
    case AppActionTypes.GET_GIFT_CARD_MAIN_VISUALS_SUCCESS:
    case AppActionTypes.GET_GIFT_CARD_MAIN_VISUALS_FAILURE:
    case AppActionTypes.GET_SOLUTION_SYSTEMS_SUCCESS:
    case AppActionTypes.GET_SOLUTION_SYSTEMS_FAILURE:
    case AppActionTypes.GET_MARKETING_INDUSTRIES_SUCCESS:
    case AppActionTypes.GET_MARKETING_INDUSTRIES_FAILURE:
    case AppActionTypes.GET_GENERAL_ANNOUNCEMENTS_SUCCESS:
    case AppActionTypes.GET_GENERAL_ANNOUNCEMENTS_FAILURE:
    case AppActionTypes.GET_GIFT_DISTRIBUTION_CHANNEL_CATEGORIES_SUCCESS:
    case AppActionTypes.GET_GIFT_DISTRIBUTION_CHANNEL_CATEGORIES_FAILURE:
    case AppActionTypes.REGISTER_PROJECT_DRAFT_SUCCESS:
    case AppActionTypes.REGISTER_PROJECT_DRAFT_FAILURE:
    case AppActionTypes.CREATE_PROJECT_SUCCESS:
    case AppActionTypes.CREATE_PROJECT_FAILURE:
    case AppActionTypes.REQUEST_GIFT_CARD_ISSUE_AMOUNT_SUCCESS:
    case AppActionTypes.REQUEST_GIFT_CARD_ISSUE_AMOUNT_FAILURE:
    case AppActionTypes.REQUEST_BULK_DELIVERABLE_URL_SUCCESS:
    case AppActionTypes.REQUEST_BULK_DELIVERABLE_URL_FAILURE:
    case AppActionTypes.GET_CLAIM_ESTIMATE_SUCCESS:
    case AppActionTypes.GET_CLAIM_ESTIMATE_FAILURE:
    case AppActionTypes.GET_PROJECT_BULK_GIFT_CARD_ISSUE_CONFIG_CLAIM_ESTIMATE_SUCCESS:
    case AppActionTypes.GET_PROJECT_BULK_GIFT_CARD_ISSUE_CONFIG_CLAIM_ESTIMATE_FAILURE:
    case AppActionTypes.GET_DEFAULT_GIFT_CARD_CONFIG_SUCCESS:
    case AppActionTypes.GET_DEFAULT_GIFT_CARD_CONFIG_FAILURE:
    case AppActionTypes.GET_PREFECTURES_SUCCESS:
    case AppActionTypes.GET_PREFECTURES_FAILURE:
    case AppActionTypes.SEARCH_POSTCODES_SUCCESS:
    case AppActionTypes.SEARCH_POSTCODES_FAILURE:
    case AppActionTypes.REQUEST_INVOICE_PDF_URL_SUCCESS:
    case AppActionTypes.REQUEST_INVOICE_PDF_URL_FAILURE:
    case AppActionTypes.UPDATE_DEFAULT_BILLING_ADDRESS_SUCCESS:
    case AppActionTypes.UPDATE_DEFAULT_BILLING_ADDRESS_FAILURE:
    case AppActionTypes.UPDATE_DISTRIBUTION_PARTNER_USER_SUCCESS:
    case AppActionTypes.UPDATE_DISTRIBUTION_PARTNER_USER_FAILURE:
    case AppActionTypes.UPDATE_API_ALLOWED_IP_SUCCESS:
    case AppActionTypes.UPDATE_API_ALLOWED_IP_FAILURE:
    case AppActionTypes.DELETE_API_ALLOWED_IP_SUCCESS:
    case AppActionTypes.DELETE_API_ALLOWED_IP_FAILURE:
    case AppActionTypes.ADD_API_ALLOWED_IP_SUCCESS:
    case AppActionTypes.ADD_API_ALLOWED_IP_FAILURE:
    case AppActionTypes.CREATE_SAMPLE_PROJECT_SUCCESS:
    case AppActionTypes.CREATE_SAMPLE_PROJECT_FAILURE:
    case AppActionTypes.DELETE_PROJECT_DRAFT_SUCCESS:
    case AppActionTypes.DELETE_PROJECT_DRAFT_FAILURE:
    case AppActionTypes.GET_MASTER_DATA_SUCCESS:
    case AppActionTypes.GET_MASTER_DATA_FAILURE:
    case AppActionTypes.UPLOAD_FILE_FAILURE:
    case AppActionTypes.POST_MESSAGE_THREAD_MESSAGE_SUCCESS:
    case AppActionTypes.POST_MESSAGE_THREAD_MESSAGE_FAILURE:
    case AppActionTypes.CHECK_IF_PROJECT_EXISTS_SUCCESS:
    case AppActionTypes.CHECK_IF_PROJECT_EXISTS_FAILURE:
    case AppActionTypes.GET_PROJECT_DRAFTS_CONTENTS_SUCCESS:
    case AppActionTypes.GET_PROJECT_DRAFTS_CONTENTS_FAILURE:
    case AppActionTypes.GET_SELECTED_PROJECT_DRAFT_CONTENTS_SUCCESS:
    case AppActionTypes.GET_SELECTED_PROJECT_DRAFT_CONTENTS_FAILURE:
    case AppActionTypes.GET_BULK_GIFTEE_BOX_ISSUE_CONFIG_CLAIM_ESTIMATE_SUCCESS:
    case AppActionTypes.GET_BULK_GIFTEE_BOX_ISSUE_CONFIG_CLAIM_ESTIMATE_FAILURE:
    case AppActionTypes.REQUEST_GIFTEE_BOX_ISSUE_AMOUNT_SUCCESS:
    case AppActionTypes.REQUEST_GIFTEE_BOX_ISSUE_AMOUNT_FAILURE:
    case AppActionTypes.GET_GIFTEE_BOX_TEMPLATE_INITIAL_CONTENT_SELECTIONS_SUCCESS:
    case AppActionTypes.GET_GIFTEE_BOX_TEMPLATE_INITIAL_CONTENT_SELECTIONS_FAILURE:
    case AppActionTypes.ADD_BULK_GIFT_CARD_ISSUE_CONFIGS_SUCCESS:
    case AppActionTypes.ADD_BULK_GIFT_CARD_ISSUE_CONFIGS_FAILURE:
    case AppActionTypes.ADD_BULK_GIFTEE_BOX_ISSUE_CONFIGS_SUCCESS:
    case AppActionTypes.ADD_BULK_GIFTEE_BOX_ISSUE_CONFIGS_FAILURE:
    case AppActionTypes.AGGREGATE_GIFTEE_BOX_SELECTED_CONTENT_PERCENTAGE_SUCCESS:
    case AppActionTypes.AGGREGATE_GIFTEE_BOX_SELECTED_CONTENT_PERCENTAGE_FAILURE:
      return false;
    default:
      return state;
  }
};

const isProjectCardLoading = (
  state: boolean = initialState.isProjectCardLoading,
  action: AppAction
) => {
  switch (action.type) {
    case AppActionTypes.GET_PROJECTS_START:
      return true;
    case AppActionTypes.GET_PROJECTS_SUCCESS:
    case AppActionTypes.GET_PROJECTS_FAILURE:
      return false;
    default:
      return state;
  }
};

const isProjectDraftCardLoading = (
  state: boolean = initialState.isProjectDraftCardLoading,
  action: AppAction
) => {
  switch (action.type) {
    case AppActionTypes.GET_PROJECT_DRAFTS_START:
      return true;
    case AppActionTypes.GET_PROJECT_DRAFTS_SUCCESS:
    case AppActionTypes.GET_PROJECT_DRAFTS_FAILURE:
      return false;
    default:
      return state;
  }
};

const isGifteeBoxTemplateContentSelectionsLoading = (
  state: boolean = initialState.isGifteeBoxTemplateContentSelectionsLoading,
  action: AppAction
): boolean => {
  switch (action.type) {
    case AppActionTypes.GET_GIFTEE_BOX_TEMPLATE_CONTINUOUS_CONTENT_SELECTIONS_START:
      return true;
    case AppActionTypes.GET_GIFTEE_BOX_TEMPLATE_CONTINUOUS_CONTENT_SELECTIONS_SUCCESS:
    case AppActionTypes.GET_GIFTEE_BOX_TEMPLATE_CONTINUOUS_CONTENT_SELECTIONS_FAILURE:
      return false;
    default:
      return state;
  }
};

const isContentsLoading = (
  state: boolean = initialState.isContentsLoading,
  action: AppAction
): boolean => {
  switch (action.type) {
    case AppActionTypes.GET_CONTENTS_OF_SELECTED_PROJECT_DRAFT_START:
    case AppActionTypes.GET_INITIAL_CONTENTS_START:
    case AppActionTypes.GET_CONTINUOUS_CONTENTS_START:
      return true;
    case AppActionTypes.GET_CONTENTS_OF_SELECTED_PROJECT_DRAFT_SUCCESS:
    case AppActionTypes.GET_CONTENTS_OF_SELECTED_PROJECT_DRAFT_FAILURE:
    case AppActionTypes.GET_INITIAL_CONTENTS_SUCCESS:
    case AppActionTypes.GET_INITIAL_CONTENTS_FAILURE:
    case AppActionTypes.GET_CONTINUOUS_CONTENTS_SUCCESS:
    case AppActionTypes.GET_CONTINUOUS_CONTENTS_FAILURE:
      return false;
    default:
      return state;
  }
};

const isAPIIssueReportsLoading = (
  state: boolean = initialState.isAPIIssueReportsLoading,
  action: AppAction
): boolean => {
  switch (action.type) {
    case AppActionTypes.GET_ISSUE_COUNTS_START:
      return true;
    case AppActionTypes.GET_ISSUE_COUNTS_SUCCESS:
    case AppActionTypes.GET_ISSUE_COUNTS_FAILURE:
      return false;
    default:
      return state;
  }
};

const isProjectBulkDeliverablesLoading = (
  state: boolean = initialState.isProjectBulkDeliverablesLoading,
  action: AppAction
): boolean => {
  switch (action.type) {
    case AppActionTypes.GET_PROJECT_INITIAL_BULK_DELIVERABLES_START:
    case AppActionTypes.GET_PROJECT_CONTINUOUS_BULK_DELIVERABLES_START:
      return true;
    case AppActionTypes.GET_PROJECT_INITIAL_BULK_DELIVERABLES_SUCCESS:
    case AppActionTypes.GET_PROJECT_INITIAL_BULK_DELIVERABLES_FAILURE:
    case AppActionTypes.GET_PROJECT_CONTINUOUS_BULK_DELIVERABLES_SUCCESS:
    case AppActionTypes.GET_PROJECT_CONTINUOUS_BULK_DELIVERABLES_FAILURE:
      return false;
    default:
      return state;
  }
};

const loaders = combineReducers({
  isAppLoading,
  isProjectCardLoading,
  isProjectDraftCardLoading,
  isGifteeBoxTemplateContentSelectionsLoading,
  isContentsLoading,
  isAPIIssueReportsLoading,
  isProjectBulkDeliverablesLoading
});

export default loaders;
