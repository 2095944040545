import {
  updateProjectBulkGiftCardIssueConfigIssueAmount,
  updateSelectedProjectBulkGiftCardIssueConfig,
  openProjectGiftCardPreview,
  closeProjectGiftConfigBulkTable,
  selectGifteeBoxTemplate,
  deselectGifteeBoxTemplate
} from '@console/actions/actions';
import { getBulkGiftCardIssueConfigClaimEstimateThunk } from '@console/actions/thunks/getBulkGiftCardIssueConfigClaimEstimate';
import { getLoginUserThunk } from '@console/actions/thunks/getLoginUser';
import { getProjectDetailThunk } from '@console/actions/thunks/getProjectDetail';
import { requestGiftCardIssueAmountThunk } from '@console/actions/thunks/requestGiftCardIssueAmount';
import { validateRequestGiftCardIssueAmountThunk } from '@console/actions/thunks/validateRequestGiftCardIssueAmount';
import { goProjectBulkGiftCardIssueConfigsNew } from '@console/common/movePages';
import BulkTable from '@console/components/project/giftConfig/BulkTable';
import { getCreditLimitBalance } from '@console/selectors/distributionPartner';
import {
  getGiftCardConfigsForDisplay,
  getSelectedGiftCardConfig
} from '@console/selectors/projectDetail/giftCardConfigs';
import { getSelectedBulkGiftCardIssueConfigForDisplay } from '@console/selectors/projectDetail/giftCardConfigs/bulkGiftCardIssueConfigs';
import { getGifteeBoxConfigs } from '@console/selectors/projectDetail/gifteeBoxConfigs';
import { connect } from 'react-redux';
import type {
  AppThunkDispatch,
  AppThunkAction
} from '@console/actions/thunkType';
import type { AppState } from '@console/reducers';
import type { LocalGifteeBoxConfig } from '@console/reducers/projects';
import type { GiftCardConfigForDisplay } from '@console/selectors/projectDetail/giftCardConfigs';
import type { NavigateFunction } from 'react-router-dom';

const handleBulkGiftCardIssueConfigClick =
  (resoleved: () => void) =>
  (
    giftCardConfig: GiftCardConfigForDisplay,
    bulkGiftCardIssueConfigIndex: number
  ): AppThunkAction<void> =>
  dispatch => {
    dispatch(
      validateRequestGiftCardIssueAmountThunk(
        giftCardConfig,
        bulkGiftCardIssueConfigIndex
      )
    )
      .then(() => {
        dispatch(
          updateSelectedProjectBulkGiftCardIssueConfig(
            giftCardConfig.urlCode,
            bulkGiftCardIssueConfigIndex
          )
        );
        dispatch(
          getBulkGiftCardIssueConfigClaimEstimate(bulkGiftCardIssueConfigIndex)
        );
        resoleved();
      })
      .catch(errorMessage => {
        alert(errorMessage);
      });
  };

const requestGiftCardIssueAmount =
  (issueAmount: number, urlCode: string): AppThunkAction<void> =>
  (dispatch, getState) => {
    dispatch(requestGiftCardIssueAmountThunk(issueAmount, urlCode))
      .then(() => {
        const state = getState();
        const { projectDetail } = state;
        if (projectDetail.urlCode) {
          dispatch(getProjectDetailThunk(projectDetail.urlCode));
        }

        if (getCreditLimitBalance(state) !== null) {
          // creditLimitBalance が更新されるので再取得
          dispatch(getLoginUserThunk());
        }
      })
      .catch(() => {});
  };

const getBulkGiftCardIssueConfigClaimEstimate =
  (bulkGiftCardIssueConfigIndex: number): AppThunkAction<void> =>
  (dispatch, getState) => {
    const giftCardConfig = getSelectedGiftCardConfig(getState());
    const bulkGiftCardIssueConfig =
      giftCardConfig?.bulkGiftCardIssueConfigs[bulkGiftCardIssueConfigIndex];
    const amount = bulkGiftCardIssueConfig?.issueAmountRequest.issueAmount ?? 0;
    const unitPrice = giftCardConfig?.unitPrice ?? 0;
    const bulkGiftCardIssueConfigAmount = amount * unitPrice;
    dispatch(
      getBulkGiftCardIssueConfigClaimEstimateThunk(
        giftCardConfig?.urlCode ?? '',
        bulkGiftCardIssueConfigIndex,
        bulkGiftCardIssueConfigAmount
      )
    ).catch(() => {});
  };

const goBulkGiftCardIssueConfigsNewPage =
  (
    navigate: NavigateFunction,
    giftCardConfig: GiftCardConfigForDisplay
  ): AppThunkAction<void> =>
  (_, getState) => {
    const { selectedProjectUrlCode } = getState();
    if (!!selectedProjectUrlCode) {
      goProjectBulkGiftCardIssueConfigsNew(
        navigate,
        selectedProjectUrlCode,
        giftCardConfig.urlCode
      );
    }
  };

const mapStateToProps = (state: AppState) => ({
  giftCardConfigs: getGiftCardConfigsForDisplay(state),
  gifteeBoxConfigs: getGifteeBoxConfigs(state),
  selectedGiftCardConfig: getSelectedGiftCardConfig(state),
  selectedBulkGiftCardIssueConfig:
    getSelectedBulkGiftCardIssueConfigForDisplay(state)
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  requestGiftCardIssueAmount: (issueAmount: number, urlCode: string) =>
    dispatch(requestGiftCardIssueAmount(issueAmount, urlCode)),
  onChangeBulkGiftCardIssueConfigIssueAmount: (
    event: GenericChangeEvent<string>,
    giftCardConfig: GiftCardConfigForDisplay,
    bulkGiftCardIssueConfigIndex: number
  ) =>
    dispatch(
      updateProjectBulkGiftCardIssueConfigIssueAmount(
        Number(event.target.value),
        giftCardConfig.urlCode,
        bulkGiftCardIssueConfigIndex
      )
    ),
  onClickOpenGiftCardPreviewButton: (
    giftCardConfig: GiftCardConfigForDisplay
  ) => dispatch(openProjectGiftCardPreview(giftCardConfig.urlCode)),
  closeProjectGiftConfigBulkTable: () =>
    dispatch(closeProjectGiftConfigBulkTable()),
  selectGifteeBoxTemplate: (
    gifteeBoxTemplate: LocalGifteeBoxConfig['gifteeBoxTemplate']
  ) => dispatch(selectGifteeBoxTemplate(gifteeBoxTemplate)),
  deselectGifteeBoxTemplate: () => dispatch(deselectGifteeBoxTemplate()),
  handleAddBulkGiftCardIssueConfigsClick: (
    navigate: NavigateFunction,
    giftCardConfig: GiftCardConfigForDisplay
  ) => dispatch(goBulkGiftCardIssueConfigsNewPage(navigate, giftCardConfig)),
  handleBulkGiftCardIssueConfigClick:
    (resoleved: () => void) =>
    (
      giftCardConfig: GiftCardConfigForDisplay,
      bulkGiftCardIssueConfigIndex: number
    ) =>
      dispatch(
        handleBulkGiftCardIssueConfigClick(resoleved)(
          giftCardConfig,
          bulkGiftCardIssueConfigIndex
        )
      )
});

export type BulkTablePropsMappedFromState = ReturnType<typeof mapStateToProps>;
export type BulkTablePropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(mapStateToProps, mapDispatchToProps)(BulkTable);
