import moment from '@common/lib/moment';
import { isFixedGiftConfig } from '@console/common/projectDraft/giftConfig';
import { createSelector } from 'reselect';
import type { Content } from '@common/api/graphql/getContentsGql';
import type {
  MessageCard,
  GiftCardTickets
} from '@common/api/graphql/getGiftCardGql';
import type { GiftCardMainVisual } from '@common/api/graphql/getGiftCardMainVisualsGql';
import type { AppState } from '@console/reducers';
import type { GiftConfig } from '@console/reducers/projectJson/giftConfigs';

const getSelectedProjectDraftGiftConfigs = (state: AppState) =>
  state.selectedProjectDraft.projectJson.giftConfigs;
const getSelectedProjectDraftSelectedGiftConfigIndex = (state: AppState) =>
  state.selectedProjectDraft.projectJson.selectedGiftConfigIndex;
const getContents = (state: AppState) => state.contents;
const getGiftCardMainVisuals = (state: AppState) => state.giftCardMainVisuals;

const getSelectedProjectDraftGiftConfig = createSelector(
  [
    getSelectedProjectDraftGiftConfigs,
    getSelectedProjectDraftSelectedGiftConfigIndex
  ],
  (giftConfigs, selectedGiftConfigIndex) => giftConfigs[selectedGiftConfigIndex]
);

const getSelectedProjectDraftGiftConfigGiftCardMainVisual = createSelector(
  [getSelectedProjectDraftGiftConfig, getGiftCardMainVisuals],
  (giftConfig, giftCardMainVisuals): GiftCardMainVisual | undefined => {
    if (!giftConfig) return undefined;
    return giftCardMainVisuals.find(
      mainVisual => mainVisual.urlCode === giftConfig.selectedMainVisual
    );
  }
);

export const getMessageCardForPreviewInProjectDraft = createSelector(
  [
    getSelectedProjectDraftGiftConfig,
    getSelectedProjectDraftGiftConfigGiftCardMainVisual
  ],
  (selectedGiftConfig, mainVisual): MessageCard => ({
    mainVisualUrl: mainVisual?.imageUrl || null,
    senderName: !!selectedGiftConfig ? selectedGiftConfig.senderName : '',
    description: !!selectedGiftConfig ? selectedGiftConfig.message : ''
  })
);

const getSelectedProjectDraftGiftConfigSelectedContents = createSelector(
  [getSelectedProjectDraftGiftConfig, getContents],
  (giftConfig, contents): (Content | undefined)[] => {
    if (!giftConfig) return [];
    return giftConfig.selectedContents.map(selectedContentUrlCode =>
      contents.find(content => content.urlCode === selectedContentUrlCode)
    );
  }
);

export const getGiftCardTicketsForReviewInProjectDraft = createSelector(
  [
    getSelectedProjectDraftGiftConfig,
    getSelectedProjectDraftGiftConfigSelectedContents
  ],
  (giftConfig, selectedContents): GiftCardTickets => {
    if (!giftConfig) return [];

    const contents = selectedContents.filter(
      (content): content is Content => !!content
    );
    return getGiftCardTicketsByGiftConfig(giftConfig, contents);
  }
);

const getGiftCardTicketsByGiftConfig = (
  giftConfig: GiftConfig,
  contents: Content[]
): GiftCardTickets =>
  isFixedGiftConfig(giftConfig)
    ? contents.map(content =>
        getFixedGiftCardTicketByGiftConfig(giftConfig, content)
      )
    : [getChoosableGiftCardTicketByGiftConfig(giftConfig, contents)];

const getFixedGiftCardTicketByGiftConfig = (
  giftConfig: GiftConfig,
  content: Content
): GiftCardTickets[number] => ({
  urlCode: '',
  ticketType: giftConfig.ticketType,
  content: {
    name: content.name,
    imageUrl: content.imageUrl,
    brand: {
      name: content.brand.name
    },
    availablePeriod: content.availablePeriod,
    customerAttention: content.customerAttention
  },
  choosingOption: null,
  gift: {
    url: '',
    expiresAt: moment().add(1, 'days').toString()
  }
});

const getChoosableGiftCardTicketByGiftConfig = (
  giftConfig: GiftConfig,
  contents: Content[]
): GiftCardTickets[number] => ({
  urlCode: '',
  ticketType: giftConfig.ticketType,
  content: null,
  choosingOption: {
    choosableBeginAt: moment().toString(),
    choosableEndAt: moment().add(1, 'month').endOf('month').toString(),
    choices: contents.map((content, index) => ({
      choosable: true,
      order: index,
      urlCode: content.urlCode,
      choosablePeriodOption: null,
      content: {
        name: content.name,
        detail: content.detail,
        imageUrl: content.imageUrl,
        brand: {
          name: content.brand.name
        },
        availablePeriod: content.availablePeriod,
        customerAttention: content.customerAttention
      }
    }))
  },
  gift: null
});
