import AssignmentIcon from '@mui/icons-material/Assignment';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import copy from 'copy-to-clipboard';
import { useCallback, useState, useRef, useEffect } from 'react';
import type { Theme } from '@mui/material';
import type { TooltipProps } from '@mui/material/Tooltip';
import type { WithStyles, StyleRules } from '@mui/styles';
import type { ButtonHTMLAttributes } from 'react';

const styles = (theme: Theme): StyleRules => createStyles({});

type CopyToClipboardButtonOwnProps = {
  copyText: string;
  tooltipPlacement?: TooltipProps['placement'];
};

type CopyToClipboardButtonProps = Pick<
  ButtonHTMLAttributes<HTMLButtonElement>,
  'className'
> &
  CopyToClipboardButtonOwnProps &
  WithStyles<typeof styles>;

const AUTO_HIDE_TOOLTIP_DURATION = 2000;

const CopyToClipboardButton: React.FC<CopyToClipboardButtonProps> = ({
  copyText,
  tooltipPlacement = 'bottom',
  className,
  classes
}) => {
  const [isVisibleTooltip, setIsVisibleTooltip] = useState(false);
  const autoHideTooltipTimer = useRef<number>();

  const handleClick = useCallback((_: React.MouseEvent<HTMLButtonElement>) => {
    copy(copyText);
    setIsVisibleTooltip(true);
  }, []);

  const handleTooltipClose = useCallback(() => {
    setIsVisibleTooltip(false);
  }, []);

  const setAutoHideTooltipTimer = useCallback(() => {
    clearTimeout(autoHideTooltipTimer.current);

    autoHideTooltipTimer.current = window.setTimeout(() => {
      handleTooltipClose();
    }, AUTO_HIDE_TOOLTIP_DURATION);
  }, []);

  useEffect(() => {
    if (isVisibleTooltip) {
      setAutoHideTooltipTimer();
    }

    return () => {
      clearTimeout(autoHideTooltipTimer.current);
    };
  }, [isVisibleTooltip]);

  return (
    <Tooltip
      arrow={true}
      disableFocusListener
      disableHoverListener
      placement={tooltipPlacement}
      PopperProps={{
        disablePortal: true
      }}
      title="コピーしました"
      onClose={handleTooltipClose}
      open={isVisibleTooltip}
    >
      <Button
        color="primary"
        variant="outlined"
        size="small"
        className={clsx(classes.button, className)}
        startIcon={<AssignmentIcon />}
        onClick={handleClick}
        data-cy={`copyToClipboardButton-${copyText}`}
      >
        コピー
      </Button>
    </Tooltip>
  );
};

export default withStyles(styles)(CopyToClipboardButton);
