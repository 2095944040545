import { AppActionTypes } from '../actions/actionType';
import type { AppAction } from '../actions/actionType';

const isLoggedIn = (state = false, action: AppAction): boolean => {
  switch (action.type) {
    case AppActionTypes.UPDATE_IS_LOGGED_IN:
      return action.isLoggedIn;
    default:
      return state;
  }
};

export default isLoggedIn;
