import { AppActionTypes } from '../actions/actionType';
import type { AppAction } from '../actions/actionType';

const menuAnchor = (
  state = null,
  action: AppAction
): null | (EventTarget & Element) => {
  switch (action.type) {
    case AppActionTypes.TOGGLE_SETTING_MENU:
      return action.menuAnchor;
    default:
      return state;
  }
};

export default menuAnchor;
