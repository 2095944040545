import { getClaimEstimate } from '@common/api/graphql/getClaimEstimate';
import {
  getBulkGifteeBoxIssueConfigClaimEstimateStart,
  getBulkGifteeBoxIssueConfigClaimEstimateSuccess,
  getBulkGifteeBoxIssueConfigClaimEstimateFailure
} from '../actions';
import handleApiErrorIfNeeded from './handleApiErrorIfNeeded';
import type { AppThunkAction } from '../thunkType';
import type { ApiError } from '@common/api/graphql/apolloClient';
import type { GetClaimEstimateResponse } from '@common/api/graphql/getClaimEstimate';
import type { LocalBulkGifteeBoxIssueConfig } from '@console/reducers/projects';

export const getBulkGifteeBoxIssueConfigClaimEstimateSuccessThunk =
  (
    response: GetClaimEstimateResponse,
    gifteeBoxConfigUrlCode: UrlCode,
    bulkGifteeBoxIssueConfigUrlCode: UrlCode
  ): AppThunkAction<Promise<void>> =>
  dispatch => {
    const claimEstimate = response.data!.claimEstimate;

    dispatch(
      getBulkGifteeBoxIssueConfigClaimEstimateSuccess(
        claimEstimate,
        gifteeBoxConfigUrlCode,
        bulkGifteeBoxIssueConfigUrlCode
      )
    );
    return Promise.resolve();
  };

export const getBulkGifteeBoxIssueConfigClaimEstimateFailureThunk =
  (error: ApiError): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(getBulkGifteeBoxIssueConfigClaimEstimateFailure());
    return dispatch(handleApiErrorIfNeeded(error));
  };

export const getBulkGifteeBoxIssueConfigClaimEstimateThunk =
  (
    gifteeBoxConfigUrlCode: UrlCode,
    bulkGifteeBoxIssueConfig: LocalBulkGifteeBoxIssueConfig
  ): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(getBulkGifteeBoxIssueConfigClaimEstimateStart());

    const { initialPoint, issueAmountRequest, urlCode } =
      bulkGifteeBoxIssueConfig;
    const currentPriceTotal = initialPoint * issueAmountRequest.issueAmount;

    return getClaimEstimate(currentPriceTotal)
      .then((response: GetClaimEstimateResponse) =>
        dispatch(
          getBulkGifteeBoxIssueConfigClaimEstimateSuccessThunk(
            response,
            gifteeBoxConfigUrlCode,
            urlCode
          )
        )
      )
      .catch(error =>
        dispatch(getBulkGifteeBoxIssueConfigClaimEstimateFailureThunk(error))
      );
  };
