import ComponentLoader from '@common/components/ComponentLoader';
import { useIntersectionObserver } from '@common/hooks/useIntersectionObserver';
import { getLocalString } from '@common/lib/getLocalString';
import ContentRow from '@console/components/common/ContentRow';
import FilteringSectionContainer from '@console/containers/common/gifteeBoxTemplateContentSelectionsModal/FilteringSectionContainer';
import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { useEffect, useRef } from 'react';
import type {
  GifteeBoxTemplateContentSelectionsModalPropsMappedFromState,
  GifteeBoxTemplateContentSelectionsModalPropsMappedFromDispatch
} from '@console/containers/common/GifteeBoxTemplateContentSelectionsModalContainer';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    }
  });

type OwnProps = {
  isOpen: boolean;
  handleClose: () => void;
};

type GifteeBoxTemplateContentSelectionsModalProps = OwnProps &
  GifteeBoxTemplateContentSelectionsModalPropsMappedFromState &
  GifteeBoxTemplateContentSelectionsModalPropsMappedFromDispatch &
  WithStyles<typeof styles>;

const GifteeBoxTemplateContentSelectionsModal: React.FC<
  GifteeBoxTemplateContentSelectionsModalProps
> = ({
  isOpen,
  isGifteeBoxTemplateContentSelectionsLoading,
  handleClose,
  selectedGifteeBoxTemplate,
  getGifteeBoxTemplateInitialContentSelections,
  getGifteeBoxTemplateContinuousContentSelections,
  classes
}) => {
  const infiniteScrollTarget = useRef<HTMLDivElement>(null);
  const { startObserving, isIntersect } =
    useIntersectionObserver(infiniteScrollTarget);

  useEffect(() => {
    startObserving(true);
  }, []);

  useEffect(() => {
    if (selectedGifteeBoxTemplate.urlCode) {
      if (isIntersect) {
        getGifteeBoxTemplateContinuousContentSelections(
          selectedGifteeBoxTemplate.urlCode
        );
      }
    }
  }, [selectedGifteeBoxTemplate.urlCode, isIntersect]);

  useEffect(() => {
    if (!!selectedGifteeBoxTemplate.urlCode) {
      getGifteeBoxTemplateInitialContentSelections(
        selectedGifteeBoxTemplate.urlCode
      );
    }
  }, [selectedGifteeBoxTemplate.urlCode]);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="giftee-box-template-content-selections-title"
      fullWidth
      data-cy="gifteeBoxTemplateContentSelectionsModal"
    >
      <DialogTitle id="giftee-box-template-content-selections-title">
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
          data-cy="closeButton"
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FilteringSectionContainer />
        <div data-cy="tableContainer">
          <Table>
            <TableHead data-cy="tableHeader">
              <TableRow>
                <TableCell align="center" colSpan={2} />
              </TableRow>
            </TableHead>
            <TableBody data-cy="tableBody">
              {selectedGifteeBoxTemplate.contentSelections?.map(
                contentSelection => (
                  <TableRow
                    key={`contentSelection-${contentSelection.urlCode}`}
                    data-cy={`contentSelection-${contentSelection.urlCode}`}
                  >
                    <TableCell align="left">
                      <ContentRow
                        contentName={contentSelection.content.name}
                        contentInternalName={
                          contentSelection.content.internalName
                        }
                        contentImageUrl={contentSelection.content.imageUrl}
                        contentBrandName={contentSelection.content.brand.name}
                        contentAvailablePeriodText={`交換後の有効期限：${contentSelection.content.availablePeriod}`}
                      />
                    </TableCell>
                    <TableCell
                      align="left"
                      data-cy={`gifteeBoxTemplateExchangePoint-${contentSelection.urlCode}`}
                    >
                      <Typography variant="body1">
                        {getLocalString(contentSelection.exchangePoint)}pt
                      </Typography>
                    </TableCell>
                  </TableRow>
                )
              ) || null}
            </TableBody>
          </Table>
          <ComponentLoader
            isLoading={isGifteeBoxTemplateContentSelectionsLoading}
          />
          <div ref={infiniteScrollTarget} />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(styles)(GifteeBoxTemplateContentSelectionsModal);
