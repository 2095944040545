import { updateGifteeBoxTemplateBrandFilter } from '@console/actions/actions';
import { getGifteeBoxTemplateBrandsThunk } from '@console/actions/thunks/getGifteeBoxTemplateBrands';
import BrandFiltering from '@console/components/common/gifteeBoxTemplateContentSelectionsModal/filteringSection/BrandFiltering';
import { getContentSelectionsFilteringBrandUrlCodes } from '@console/selectors/getSearchInfo';
import { getSelectedGifteeBoxTemplateFilteredBrands } from '@console/selectors/getSelectedGifteeBoxTemplate';
import { connect } from 'react-redux';
import type { ContentSelectionsFilter } from '@common/api/graphql/getGifteeBoxTemplateContentSelectionsGql';
import type {
  AppThunkDispatch,
  AppThunkAction
} from '@console/actions/thunkType';
import type { AppState } from '@console/reducers';

const getGifteeBoxTemplateBrands =
  (gifteeBoxTemplateUrlCode: UrlCode): AppThunkAction<void> =>
  dispatch => {
    dispatch(getGifteeBoxTemplateBrandsThunk(gifteeBoxTemplateUrlCode)).catch(
      () => {}
    );
  };

const mapStateToProps = (state: AppState) => ({
  selectedGifteeBoxTemplateBrands: state.selectedGifteeBoxTemplate.brands,
  selectedGifteeBoxTemplateUrlCode: state.selectedGifteeBoxTemplate.urlCode,
  selectedBrandUrlCodes: getContentSelectionsFilteringBrandUrlCodes(state),
  selectedBrands: getSelectedGifteeBoxTemplateFilteredBrands(state)
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  getGifteeBoxTemplateBrands: (gifteeBoxTemplateUrlCode: UrlCode) =>
    dispatch(getGifteeBoxTemplateBrands(gifteeBoxTemplateUrlCode)),
  handleBrandFilterChange: value =>
    dispatch(updateGifteeBoxTemplateBrandFilter(value))
});

export type BrandFilteringPropsMappedFromState = ReturnType<
  typeof mapStateToProps
>;
export type BrandFilteringPropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(mapStateToProps, mapDispatchToProps)(BrandFiltering);
