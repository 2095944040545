import { getLocalePrice } from '@common/lib/getLocalePrice';
import { CheckCircle, Error } from '@mui/icons-material';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  Chip
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import Linkify from 'linkify-react';
import type { Content } from '../../../../common/api/graphql/getContentsGql';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    contentBox: {
      marginBottom: theme.spacing(2)
    },
    contentImage: {
      width: theme.spacing(25),
      height: theme.spacing(25),
      objectFit: 'contain',
      marginRight: theme.spacing(2),
      verticalAlign: 'middle'
    },
    contentNameBox: {
      marginBottom: theme.spacing()
    },
    icon: {
      marginBottom: theme.spacing()
    },
    iconText: {
      fontWeight: 'bold'
    },
    chip: {
      marginBottom: theme.spacing(0.5),
      verticalAlign: 'text-bottom'
    }
  });

type OwnProps = {
  isOpen: boolean;
  isSelectable?: boolean;
  onSelectHandler?: (event: React.MouseEvent, ...args: any) => void;
  onDeselectHandler?: (event: React.MouseEvent, ...args: any) => void;
  onCloseHandler: (event: React.MouseEvent, ...args: any) => void;
  content: Content;
  isSelected?: boolean;
  unselectableMessage?: string;
};

type ContentModalProps = OwnProps & WithStyles<typeof styles>;

const ContentModal: React.FC<ContentModalProps> = ({
  isOpen,
  isSelectable = true,
  onSelectHandler,
  onDeselectHandler,
  onCloseHandler,
  content,
  isSelected,
  unselectableMessage,
  classes
}) => (
  <Dialog
    aria-labelledby="dialog-title"
    aria-describedby="modal-description"
    fullWidth
    maxWidth="md"
    open={isOpen}
    onClose={onCloseHandler}
    data-cy="contentModal"
  >
    <DialogTitle id="dialog-title">ギフト詳細</DialogTitle>
    <DialogContent>
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="flex-start"
        className={classes.contentBox}
      >
        <img
          src={content.imageUrl}
          className={classes.contentImage}
          data-cy="contentImage"
        />
        <Box>
          {content.isLimitedPublished && (
            <Chip
              label="貴社専用"
              color="primary"
              variant="outlined"
              size="small"
              className={classes.chip}
            />
          )}
          <Box className={classes.contentNameBox}>
            <Typography variant="h6" color="textPrimary" data-cy="contentName">
              {content.internalName}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              data-cy="brandName"
            >
              {content.brand.name}
            </Typography>
            <Typography color="textPrimary" data-cy="currentPrice">
              {getLocalePrice(content.price)}
            </Typography>
          </Box>
          {isSelectable && isSelected && (
            <Box display="flex" alignItems="center" className={classes.icon}>
              <CheckCircle color="primary" />
              <Typography color="primary" className={classes.iconText}>
                選択中
              </Typography>
            </Box>
          )}
          {!!unselectableMessage && (
            <Box display="flex" alignItems="center" className={classes.icon}>
              <Error color="secondary" />
              <Typography color="secondary" className={classes.iconText}>
                {unselectableMessage}
              </Typography>
            </Box>
          )}
          <Box>
            <Typography color="textPrimary">有効期限</Typography>
            <Typography
              variant="caption"
              color="textPrimary"
              data-cy="availablePeriod"
            >
              {content.availablePeriod}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box className={classes.contentBox}>
        <Typography color="textPrimary">注意事項</Typography>
        {content.brand.noteForUsageOptions.length !== 0 ? (
          content.brand.noteForUsageOptions.map((noteForUsageOption, i) => (
            <Typography
              variant="caption"
              color="textPrimary"
              display="block"
              key={i}
              data-cy="brandNoteForUsageOptions"
            >
              <Linkify
                tagName="span"
                options={{ target: '_blank', rel: 'noopener noreferrer' }}
              >
                {noteForUsageOption.description}
              </Linkify>
            </Typography>
          ))
        ) : (
          <Typography
            variant="caption"
            color="textPrimary"
            display="block"
            data-cy="brandNoteForUsageOptions"
          >
            無し
          </Typography>
        )}
      </Box>
      <Box>
        <Typography color="textPrimary">その他</Typography>
        {(content.noteForUsageOptions?.length ?? 0) !== 0 ? (
          content.noteForUsageOptions?.map((noteForUsageOption, i) => (
            <Typography
              variant="caption"
              color="textPrimary"
              display="block"
              key={i}
              data-cy="contentNoteForUsageOptions"
            >
              <Linkify
                tagName="span"
                options={{ target: '_blank', rel: 'noopener noreferrer' }}
              >
                {noteForUsageOption.description}
              </Linkify>
            </Typography>
          ))
        ) : (
          <Typography
            variant="caption"
            color="textPrimary"
            display="block"
            data-cy="contentNoteForUsageOptions"
          >
            無し
          </Typography>
        )}
      </Box>
    </DialogContent>
    <DialogActions>
      {isSelectable && (
        <>
          <Button onClick={onCloseHandler} color="primary" data-cy="cancel">
            キャンセル
          </Button>
          {isSelected ? (
            <Button
              onClick={onDeselectHandler}
              variant="contained"
              color="secondary"
              data-cy="deselectContent"
            >
              選択解除
            </Button>
          ) : (
            <Button
              onClick={onSelectHandler}
              variant="contained"
              color="primary"
              data-cy="selectContent"
            >
              選択
            </Button>
          )}
        </>
      )}
    </DialogActions>
  </Dialog>
);

export default withStyles(styles)(ContentModal);
