import { getSelectedGifteeBoxConfig } from '@console/selectors/projectDetail/gifteeBoxConfigs';
import { createSelector } from 'reselect';
import type { QueryAPIGifteeBoxIssueConfigs } from '@common/api/graphql/getProjectsGql';

export const getSelectedApiGifteeBoxIssueConfigs = createSelector(
  [getSelectedGifteeBoxConfig],
  (selectedGifteeBoxConfig): QueryAPIGifteeBoxIssueConfigs =>
    selectedGifteeBoxConfig?.apiGifteeBoxIssueConfigs || []
);

export const getSelectedApiGifteeBoxIssueConfigExchangeableEndLabel =
  createSelector(
    [getSelectedApiGifteeBoxIssueConfigs],
    (apiGifteeBoxIssueConfigs): string =>
      apiGifteeBoxIssueConfigs[apiGifteeBoxIssueConfigs.length - 1]
        .gifteeBoxIssueConfig.exchangeableEndLabel || ''
  );
