import moment from '@common/lib/moment';
import { createSelector } from 'reselect';
import type { AppState } from '@console/reducers';
import type { LocalProject } from '@console/reducers/projects';

type ClaimsGroupedByBillingMonth = {
  [billingMonth: string]: LocalProject['claims'];
};

const getProjectDetailClaims = (state: AppState) => state.projectDetail.claims;

export const getClaims = createSelector(
  [getProjectDetailClaims],
  (claims): LocalProject['claims'] => claims ?? []
);

export const getClaimsGroupedByBillingMonth = createSelector(
  [getClaims],
  (claims): ClaimsGroupedByBillingMonth =>
    claims.reduce((groupedClaims, claim) => {
      const billingMonth = moment(claim.billingDate).format('YYYY年M月');

      groupedClaims[billingMonth] = groupedClaims[billingMonth] ?? [];
      groupedClaims[billingMonth].push(claim);

      return groupedClaims;
    }, {})
);
