import getTransitionTimeout from '@common/lib/getTransitionTimeout';
import SectionTitle from '@console/components/projectDraft/common/SectionTitle';
import StepHandlerContainer from '@console/containers/projectDraft/StepHandlerContainer';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Grid from '@mui/material/Grid';
import Grow from '@mui/material/Grow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import type {
  MessageCardConfigPropsMappedFromState,
  MessageCardConfigPropsMappedFromDispatch
} from '@console/containers/projectDraft/MessageCardConfigContainer';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const GIFT_IMAGE_WIDTH = 200;

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {},
    title: {
      marginTop: theme.spacing(3)
    },
    input: {
      padding: '1% 0',
      paddingRight: '3%'
    },
    mainVisuals: {
      margin: `${theme.spacing()} 0`
    },
    mainVisualContainer: {
      width: GIFT_IMAGE_WIDTH,
      height: GIFT_IMAGE_WIDTH,
      textAlign: 'center',
      margin: '5%',
      position: 'relative'
    },
    mainVisual: {
      height: 'inherit',
      width: 'inherit'
    },
    formControl: {
      minWidth: 150,
      marginRight: '5%'
    },
    selected: {
      opacity: 0.3
    },
    icon: {
      fontSize: 32,
      position: 'absolute',
      top: theme.spacing(),
      right: theme.spacing(),
      filter: 'drop-shadow(1px 0px 0px rgba(0,0,0))'
    },
    giftTitle: {
      marginRight: theme.spacing()
    },
    giftPrice: {
      marginRight: theme.spacing()
    },
    errorMessage: {
      minHeight: theme.spacing(3),
      fontSize: 14
    }
  });

type MessageCardConfigProps = MessageCardConfigPropsMappedFromState &
  MessageCardConfigPropsMappedFromDispatch &
  WithStyles<typeof styles>;

const MessageCardConfig: React.FC<MessageCardConfigProps> = ({
  giftCardMainVisuals,
  senderName,
  message,
  selectedMainVisual,
  giftConfigError,
  onChangeSenderName,
  onChangeMessage,
  toggleMainVisual,
  handleStepNextFromParent,
  classes
}) => {
  const navigate = useNavigate();

  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="flex-start"
      className={classes.root}
    >
      <Grid item xs={12} className={classes.title}>
        <SectionTitle text="メッセージカードを作成してください" />
      </Grid>
      <Grid item xs={6} className={classes.input}>
        <TextField
          variant="standard"
          id="senderName"
          name="senderName"
          label="差出人名"
          helperText="（例）ギフティ（全角半角20文字以内・絵文字不可）"
          value={senderName}
          onChange={onChangeSenderName}
          fullWidth
          inputProps={{ maxLength: 20 }}
          error={!!giftConfigError?.senderName}
        />
        <Typography
          id="errorMessageForSenderName"
          className={classes.errorMessage}
          color="error"
        >
          {giftConfigError?.senderName}
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.input}>
        <TextField
          variant="standard"
          id="message"
          name="message"
          label="メッセージ"
          helperText="（例）新規入会キャンペーンにご参加いただきありがとうございます。今後ともよろしくお願い致します。（全角半角100文字以内・絵文字不可）"
          value={message}
          onChange={onChangeMessage}
          multiline
          fullWidth
          inputProps={{ maxLength: 100 }}
          error={!!giftConfigError?.message}
        />
        <Typography
          id="errorMessageForMessage"
          className={classes.errorMessage}
          color="error"
        >
          {giftConfigError?.message}
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.title}>
        <SectionTitle text="カードデザインを選択してください" />
      </Grid>
      <Grid
        item
        container
        alignItems="flex-end"
        justifyContent="flex-start"
        xs={12}
        className={classes.mainVisuals}
        data-cy="mainVisuals"
      >
        {giftCardMainVisuals.length !== 0
          ? giftCardMainVisuals.map((mainVisual, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={index}>
                <Grow
                  in={Boolean(mainVisual)}
                  {...(mainVisual
                    ? { timeout: getTransitionTimeout(index) }
                    : {})}
                >
                  <div className={classes.mainVisualContainer}>
                    <img
                      src={mainVisual.imageUrl}
                      className={clsx(classes.mainVisual, {
                        [classes.selected]:
                          selectedMainVisual === mainVisual.urlCode
                      })}
                      onClick={() => toggleMainVisual(mainVisual.urlCode)}
                      data-cy={`mainVisual-${index}`}
                    />
                    {selectedMainVisual === mainVisual.urlCode ? (
                      <CheckCircle className={classes.icon} color="primary" />
                    ) : null}
                  </div>
                </Grow>
              </Grid>
            ))
          : null}
      </Grid>
      <StepHandlerContainer
        handleStepNextFromParent={() => handleStepNextFromParent(navigate)}
      />
    </Grid>
  );
};

export default withStyles(styles)(MessageCardConfig);
