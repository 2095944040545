import { getLocaleEndDateString } from '@common/lib/getLocaleDate';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import Ticket from './Ticket';
import type {
  IsChoosingModalOpen,
  CloseChoosableTicketsModal,
  SelectChoice,
  SelectedChoosableTicket
} from './localTypes';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    modal: {
      maxWidth: 600,
      margin: 'auto'
    },
    paper: {
      position: 'absolute',
      width: '90%',
      backgroundColor: 'white',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(3),
      outline: 'none',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: 5
    },
    tickets: {
      margin: '15px 0',
      width: '100%'
    },
    ticket: {
      width: '100%',
      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
      margin: '10px 0'
    },
    giftImage: {
      height: theme.spacing(10),
      minWidth: theme.spacing(10)
    }
  });

interface GiftCardModalOwnProps {
  isChoosingModalOpen: IsChoosingModalOpen;
  closeChoosableTicketsModal: CloseChoosableTicketsModal;
  selectedChoosableTicket: SelectedChoosableTicket;
  selectChoice: SelectChoice;
}

type GiftCardModalProps = GiftCardModalOwnProps & WithStyles<typeof styles>;

const GiftCardModal: React.FC<GiftCardModalProps> = ({
  isChoosingModalOpen,
  selectedChoosableTicket,
  closeChoosableTicketsModal,
  selectChoice,
  classes
}) => (
  <Modal
    open={isChoosingModalOpen}
    onClose={closeChoosableTicketsModal}
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
    className={classes.modal}
  >
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      className={classes.paper}
    >
      <Grid item container justifyContent="center" alignItems="center">
        <Typography variant="subtitle1">
          下記からギフトを１つ選んで、ギフトチケットとしてお使いいただけます。
        </Typography>
      </Grid>
      <div className={classes.tickets}>
        {!selectedChoosableTicket
          ? null
          : selectedChoosableTicket!.choosingOption!.choices.map(
              (choice, i) => (
                <Ticket
                  ticket={choice}
                  handleClick={selectChoice}
                  expiresAt={getLocaleEndDateString(
                    selectedChoosableTicket.choosingOption!.choosableEndAt
                  )}
                  beginsAt={
                    selectedChoosableTicket.choosingOption!.choosableBeginAt
                  }
                  key={i}
                  withMask={true}
                />
              )
            )}
      </div>
    </Grid>
  </Modal>
);

export default withStyles(styles)(GiftCardModal);
