import BrandFilteringContainer from '@console/containers/common/gifteeBoxTemplateContentSelectionsModal/filteringSection/BrandFilteringContainer';
import { Button } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { useCallback } from 'react';
import type {
  FilteringSectionPropsMappedFromDispatch,
  FilteringSectionPropsMappedFromState
} from '@console/containers/common/gifteeBoxTemplateContentSelectionsModal/FilteringSectionContainer';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';
import type { FormEvent } from 'react';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    searchForm: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    conditions: {
      marginBottom: theme.spacing(2)
    },
    searchButton: {
      textAlign: 'center'
    }
  });

type FilteringSectionProps = FilteringSectionPropsMappedFromDispatch &
  FilteringSectionPropsMappedFromState &
  WithStyles<typeof styles>;

const FilteringSection: React.FC<FilteringSectionProps> = ({
  filterGifteeBoxTemplateContentSelections,
  classes
}) => {
  const handleSubmit = useCallback(
    (event: FormEvent) => {
      filterGifteeBoxTemplateContentSelections();
      event.preventDefault();
    },
    [filterGifteeBoxTemplateContentSelections]
  );

  return (
    <form
      onSubmit={handleSubmit}
      className={classes.searchForm}
      data-cy="filteringSection"
    >
      <div className={classes.conditions}>
        <BrandFilteringContainer />
      </div>
      <div className={classes.searchButton}>
        <Button type="submit" variant="contained" color="primary">
          検索する
        </Button>
      </div>
    </form>
  );
};

export default withStyles(styles)(FilteringSection);
