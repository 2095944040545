import { ERROR_MESSAGE_FOR_PAGE_VALIDATION } from '@common/middleware/errorMessages';
import {
  selectGiftConfigGifteeBoxTemplate,
  deselectGiftConfigGifteeBoxTemplate,
  validateProjectDraftFailure,
  deselectGifteeBoxTemplate,
  selectGifteeBoxTemplateForDraftProject
} from '@console/actions/actions';
import { getGifteeBoxTemplatesThunk } from '@console/actions/thunks/getGifteeBoxTemplates';
import { validateGifteeBoxTemplateSelectionThunk } from '@console/actions/thunks/validateProjectDraft/validateGiftConfig/validateContentsSelection';
import GifteeBoxTemplateSelection from '@console/components/common/contentsSelection/GifteeBoxTemplateSelection';
import { goNextStep } from '@console/containers/projectDraft/StepHandlerContainer';
import { getSelectedGiftConfigSelectedGifteeBoxTemplate } from '@console/selectors/getSelectedProjectDraftForDisplay';
import queryString from 'query-string';
import { connect } from 'react-redux';
import type { GifteeBoxTemplate } from '@common/api/graphql/getGifteeBoxTemplatesGql';
import type {
  AppThunkAction,
  AppThunkDispatch
} from '@console/actions/thunkType';
import type { AppState } from '@console/reducers';
import type { LocalGifteeBoxTemplate } from '@console/reducers/gifteeBoxTemplates';
import type { NavigateFunction } from 'react-router-dom';

const handleStepNextFromParent =
  (navigate: NavigateFunction): AppThunkAction<void> =>
  dispatch =>
    dispatch(validateGifteeBoxTemplateSelectionThunk())
      .then(() => {
        const activeStep = queryString.parse(location.search);
        const parentStep = Number(activeStep.parentStep);
        const childStep = Number(activeStep.childStep);

        goNextStep(navigate, parentStep, childStep);
      })
      .catch(() => {
        dispatch(
          validateProjectDraftFailure(ERROR_MESSAGE_FOR_PAGE_VALIDATION)
        );
      });

const getGifteeBoxTemplates = (): AppThunkAction<void> => dispatch => {
  dispatch(getGifteeBoxTemplatesThunk()).catch(() => {});
};

const toggleSelectedGifteeBoxTemplate =
  (gifteeBoxTemplate: GifteeBoxTemplate): AppThunkAction<void> =>
  (dispatch, getState) => {
    const { selectedGiftConfigIndex } =
      getState().selectedProjectDraft.projectJson;
    const selectedGifteeBoxTemplate =
      getSelectedGiftConfigSelectedGifteeBoxTemplate(getState());

    if (selectedGifteeBoxTemplate?.urlCode === gifteeBoxTemplate.urlCode) {
      dispatch(deselectGiftConfigGifteeBoxTemplate(selectedGiftConfigIndex));
    } else {
      dispatch(
        selectGiftConfigGifteeBoxTemplate(
          gifteeBoxTemplate,
          selectedGiftConfigIndex
        )
      );
    }
  };

const mapStateToProps = (state: AppState) => ({
  gifteeBoxTemplates: state.gifteeBoxTemplates,
  selectedGifteeBoxTemplate:
    getSelectedGiftConfigSelectedGifteeBoxTemplate(state),
  giftConfigError:
    state.errors.projectDraftGiftConfigs[
      state.selectedProjectDraft.projectJson.selectedGiftConfigIndex
    ]
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  handleStepNextFromParent: (navigate: NavigateFunction) =>
    dispatch(handleStepNextFromParent(navigate)),
  getGifteeBoxTemplates: () => dispatch(getGifteeBoxTemplates()),
  toggleSelectedGifteeBoxTemplate: (gifteeBoxTemplate: GifteeBoxTemplate) =>
    dispatch(toggleSelectedGifteeBoxTemplate(gifteeBoxTemplate)),
  selectGifteeBoxTemplate: (gifteeBoxTemplate: LocalGifteeBoxTemplate) =>
    dispatch(selectGifteeBoxTemplateForDraftProject(gifteeBoxTemplate)),
  deselectGifteeBoxTemplate: () => dispatch(deselectGifteeBoxTemplate())
});

export type GifteeBoxTemplateSelectionPropsMappedFromState = ReturnType<
  typeof mapStateToProps
>;
export type GifteeBoxTemplateSelectionPropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GifteeBoxTemplateSelection);
