import { Node } from '@common/api/graphql/getMessageThread';
import { getMessageThread as getMessageThreadGql } from '../../../../common/api/graphql/getMessageThread';
import {
  getMessageThreadStart,
  getMessageThreadSuccess,
  getMessageThreadFailure
} from '../actions';
import handleApiErrorIfNeeded from './handleApiErrorIfNeeded';
import type { ApiError } from '../../../../common/api/graphql/apolloClient';
import type { GetMessageThreadResponse } from '../../../../common/api/graphql/getMessageThread';
import type { MessageThread } from '@common/api/graphql/getMessageThread';
import type { AppThunkAction } from '@console/actions/thunkType';

const getMessageThreadSuccessThunk =
  (
    response: GetMessageThreadResponse
  ): AppThunkAction<Promise<MessageThread>> =>
  dispatch => {
    const messageThread = response.data!.messageThread;
    dispatch(getMessageThreadSuccess(messageThread));
    return Promise.resolve(messageThread);
  };

export const getMessageThreadFailureThunk =
  (error: ApiError): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(getMessageThreadFailure());
    return dispatch(handleApiErrorIfNeeded(error));
  };

export const getMessageThreadThunk =
  (
    urlCode: string,
    last?: number,
    beforeCursor?: string
  ): AppThunkAction<Promise<MessageThread | void>> =>
  (dispatch, getState) => {
    dispatch(getMessageThreadStart());
    return getMessageThreadGql(urlCode, last, beforeCursor)
      .then(response => dispatch(getMessageThreadSuccessThunk(response)))
      .catch(error => dispatch(getMessageThreadFailureThunk(error)));
  };
