import {
  updateGiftConfigGifteeBoxIssueEstimateOptionsInitialPoint,
  updateGiftConfigGifteeBoxIssueEstimateOptionsIssueAmount,
  addGiftConfigGifteeBoxIssueEstimateOption,
  removeGiftConfigGifteeBoxIssueEstimateOption,
  deselectGifteeBoxTemplate,
  selectGifteeBoxTemplateForDraftProject
} from '@console/actions/actions';
import { getGifteeBoxTemplatesThunk } from '@console/actions/thunks/getGifteeBoxTemplates';
import GcpTable from '@console/components/common/deliveryConfig/gifteeBoxDeliveryConfig/GcpTable';
import {
  getSelectedProjectDraftSelectedGiftConfig,
  isGifteeBoxIssueEstimateOptionAddable,
  isGifteeBoxIssueEstimateOptionRemovable
} from '@console/selectors/getSelectedProjectDraftForDisplay';
import {
  getGifteeBoxInitialPointCandidates,
  getSelectedGifteeBoxTemplate
} from '@console/selectors/projectJson/giftConfigs';
import { connect } from 'react-redux';
import type {
  AppThunkAction,
  AppThunkDispatch
} from '@console/actions/thunkType';
import type { AppState } from '@console/reducers';
import type {
  GifteeBoxIssueEstimateOption,
  SelectedGifteeBoxTemplate
} from '@console/reducers/projectJson/giftConfigs';

const onChangeIssueEstimateOptionsInitialPoint =
  (
    initialPoint: number,
    issueEstimateOptionIndex: number
  ): AppThunkAction<void> =>
  dispatch =>
    dispatch(
      onInputChangeIssueEstimateOptionsInitialPoint(
        initialPoint.toString(),
        issueEstimateOptionIndex
      )
    );

const onInputChangeIssueEstimateOptionsInitialPoint =
  (
    initialPoint: string,
    issueEstimateOptionIndex: number
  ): AppThunkAction<void> =>
  (dispatch, getState) => {
    const { selectedGiftConfigIndex } =
      getState().selectedProjectDraft.projectJson;
    dispatch(
      updateGiftConfigGifteeBoxIssueEstimateOptionsInitialPoint(
        initialPoint,
        selectedGiftConfigIndex,
        issueEstimateOptionIndex
      )
    );
  };

const onChangeIssueEstimateOptionsIssueAmount =
  (
    issueAmount: GifteeBoxIssueEstimateOption['issueAmount'],
    issueEstimateOptionIndex: number
  ): AppThunkAction<void> =>
  (dispatch, getState) => {
    const { selectedGiftConfigIndex } =
      getState().selectedProjectDraft.projectJson;
    dispatch(
      updateGiftConfigGifteeBoxIssueEstimateOptionsIssueAmount(
        issueAmount,
        selectedGiftConfigIndex,
        issueEstimateOptionIndex
      )
    );
  };

const onClickAddIssueEstimateOption =
  (): AppThunkAction<void> => (dispatch, getState) => {
    const { selectedGiftConfigIndex } =
      getState().selectedProjectDraft.projectJson;
    dispatch(
      addGiftConfigGifteeBoxIssueEstimateOption(selectedGiftConfigIndex)
    );
  };

const onClickRemoveIssueEstimateOption =
  (issueEstimateOptionIndex: number): AppThunkAction<void> =>
  (dispatch, getState) => {
    const { selectedGiftConfigIndex } =
      getState().selectedProjectDraft.projectJson;
    dispatch(
      removeGiftConfigGifteeBoxIssueEstimateOption(
        selectedGiftConfigIndex,
        issueEstimateOptionIndex
      )
    );
  };

const onMount = (): AppThunkAction<void> => dispatch => {
  dispatch(getGifteeBoxTemplatesThunk()).catch(() => {});
};

const mapStateToProps = (state: AppState) => ({
  giftConfig: getSelectedProjectDraftSelectedGiftConfig(state),
  isIssueEstimateOptionAddable: isGifteeBoxIssueEstimateOptionAddable(state),
  isIssueEstimateOptionRemovable:
    isGifteeBoxIssueEstimateOptionRemovable(state),
  giftConfigErrors:
    state.errors.projectDraftGiftConfigs[
      state.selectedProjectDraft.projectJson.selectedGiftConfigIndex
    ],
  initialPointCandidates: getGifteeBoxInitialPointCandidates(state),
  selectedGifteeBoxTemplate: getSelectedGifteeBoxTemplate(state)
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  onChangeIssueEstimateOptionsIssueAmount: (
    event: GenericChangeEvent<string>,
    issueEstimateOptionIndex: number
  ) =>
    dispatch(
      onChangeIssueEstimateOptionsIssueAmount(
        event.target.value,
        issueEstimateOptionIndex
      )
    ),
  onChangeIssueEstimateOptionsInitialPoint: (
    initialPoint: number,
    issueEstimateOptionIndex: number
  ) =>
    dispatch(
      onChangeIssueEstimateOptionsInitialPoint(
        initialPoint,
        issueEstimateOptionIndex
      )
    ),
  onInputChangeIssueEstimateOptionsInitialPoint: (
    initialPoint: string,
    issueEstimateOptionIndex: number
  ) =>
    dispatch(
      onInputChangeIssueEstimateOptionsInitialPoint(
        initialPoint,
        issueEstimateOptionIndex
      )
    ),
  onClickAddIssueEstimateOption: () =>
    dispatch(onClickAddIssueEstimateOption()),
  onClickRemoveIssueEstimateOption: (issueEstimateOptionIndex: number) =>
    dispatch(onClickRemoveIssueEstimateOption(issueEstimateOptionIndex)),
  selectGifteeBoxTemplate: (
    gifteeBoxTemplate: NonNullable<SelectedGifteeBoxTemplate>
  ) => dispatch(selectGifteeBoxTemplateForDraftProject(gifteeBoxTemplate)),
  deselectGifteeBoxTemplate: () => dispatch(deselectGifteeBoxTemplate()),
  onMount: () => dispatch(onMount())
});

export type GcpTablePropsMappedFromState = ReturnType<typeof mapStateToProps>;
export type GcpTablePropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(mapStateToProps, mapDispatchToProps)(GcpTable);
