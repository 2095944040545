import { getItemizedString } from '@common/lib/getItemizedString';
import { createSelector } from 'reselect';
import type { AdvertisingMedia } from '@common/api/graphql/getAdvertisingMediaGql';
import type { AppState } from '@console/reducers';
import type { LocalProject } from '@console/reducers/projects';

export const getProjectAdvertisingMediumRegistrations = (
  state: AppState
): LocalProject['projectAdvertisingMediumRegistrations'] =>
  state.projectDetail.projectAdvertisingMediumRegistrations ?? [];

const getAdvertisingMedia = (state: AppState): AdvertisingMedia =>
  state.advertisingMedia;

export const getProjectAdvertisingMediumRegistrationsForDisplay =
  createSelector(
    [getProjectAdvertisingMediumRegistrations, getAdvertisingMedia],
    (projectAdvertisingMediumRegistrations, advertisingMedia): string =>
      getItemizedString(
        getProjectAdvertisingMediumRegistrationStrings(
          projectAdvertisingMediumRegistrations,
          advertisingMedia
        )
      )
  );

const getProjectAdvertisingMediumRegistrationStrings = (
  projectAdvertisingMediumRegistrations: LocalProject['projectAdvertisingMediumRegistrations'],
  advertisingMedia: AdvertisingMedia
): string[] => {
  const adMediaTable: { [key: string]: string } = {};
  advertisingMedia.forEach(medium => {
    adMediaTable[medium.urlCode] = medium.name;
  });
  return projectAdvertisingMediumRegistrations.map(
    projectAdvertisingMediumRegistration =>
      projectAdvertisingMediumRegistration.description
        ? `${adMediaTable[projectAdvertisingMediumRegistration.urlCode]}：${
            projectAdvertisingMediumRegistration.description
          }`
        : `${adMediaTable[projectAdvertisingMediumRegistration.urlCode]}`
  );
};
