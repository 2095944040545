import InfoIcon from '@mui/icons-material/Info';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import type {
  APITokenPropsMappedFromState,
  APITokenPropsMappedFromDispatch
} from '@console/containers/project/APITokenContainer';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    info: {
      margin: `0 0 ${theme.spacing(4)} 0`,
      padding: theme.spacing(),
      border: `solid 2px ${theme.palette.error.main}`,
      borderRadius: 5
    },
    infoIcon: {
      fill: theme.palette.info.main,
      margin: `0 ${theme.spacing()}`
    },
    section: {
      paddingTop: theme.spacing(4)
    }
  });
type APITokenProps = APITokenPropsMappedFromState &
  APITokenPropsMappedFromDispatch &
  WithStyles<typeof styles>;

const APIAddressSettingMessage: React.FC<APITokenProps> = ({
  classes,
  apiConfidentialInformation
}) => (
  <>
    {apiConfidentialInformation?.apiAllowedIps.length === 0 && (
      <section className={classes.section}>
        <Grid
          container
          item
          wrap="nowrap"
          alignItems="center"
          xs={12}
          className={classes.info}
          data-cy="apiIPAddressSettingMessage"
        >
          <InfoIcon className={classes.infoIcon} fontSize="large" />
          <ul>
            <li>
              <Typography variant="body2" color="textPrimary">
                IPアドレス制限にIPが設定されていません。
                <br />
                左メニューの「IPアドレス制限」から設定してください。
              </Typography>
            </li>
          </ul>
        </Grid>
      </section>
    )}
  </>
);

export default withStyles(styles)(APIAddressSettingMessage);
