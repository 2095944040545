import {
  GIFTEE_BOX_TICKET_TYPE_DISPLAY_NAME,
  GIFT_CARD_FIXED_TICKET_TYPE_DISPLAY_NAME,
  GIFT_CARD_CHOOSABLE_TICKET_TYPE_DISPLAY_NAME
} from '@console/common/constants';
import { isGiftCardFixedTicketConfig } from '@console/common/project/giftCardTicketConfig';
import { Chip } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { useCallback } from 'react';
import type { QueryGiftCardTicketConfig } from '@common/api/graphql/getProjectsGql';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => ({});

export const PROJECT_TICKET_TYPE = {
  FIXED: 'fixed',
  CHOOSABLE: 'choosable',
  GIFTEE_BOX: 'gifteeBox'
} as const;

type ProjectTicketType =
  (typeof PROJECT_TICKET_TYPE)[keyof typeof PROJECT_TICKET_TYPE];

export const getGiftCardTicketType = (
  giftCardTicketConfig: QueryGiftCardTicketConfig
): ProjectTicketType =>
  isGiftCardFixedTicketConfig(giftCardTicketConfig)
    ? PROJECT_TICKET_TYPE.FIXED
    : PROJECT_TICKET_TYPE.CHOOSABLE;

type TicketTypeChipOwnProps = {
  ticketType: ProjectTicketType;
};

type TicketTypeChipProps = TicketTypeChipOwnProps & WithStyles<typeof styles>;

const TicketTypeChip: React.FC<TicketTypeChipProps> = ({
  classes,
  ticketType
}) => {
  const getLabel = useCallback((): string => {
    if (ticketType === PROJECT_TICKET_TYPE.GIFTEE_BOX) {
      return GIFTEE_BOX_TICKET_TYPE_DISPLAY_NAME;
    } else if (ticketType === PROJECT_TICKET_TYPE.FIXED) {
      return GIFT_CARD_FIXED_TICKET_TYPE_DISPLAY_NAME;
    } else if (ticketType === PROJECT_TICKET_TYPE.CHOOSABLE) {
      return GIFT_CARD_CHOOSABLE_TICKET_TYPE_DISPLAY_NAME;
    }
    return '';
  }, [ticketType]);

  return <Chip label={getLabel()} />;
};

export default withStyles(styles)(TicketTypeChip);
