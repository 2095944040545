import GiftCard from '@common/gift_card/GiftCard';
import { GIFT_CARD_PREVIEW_DIALOG_SCROLL_CONTAINER } from '@console/components/common/GiftCardPreviewModal';
import { GIFT_WALLET_FLOATING_BUTTON_VISIBILE_BORDER } from '@gift_cards/components/App';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { useEffect, useState } from 'react';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';
import type { ComponentProps } from 'react';

const styles = (theme: Theme): StyleRules => createStyles({});

type GiftCardComponentProps = ComponentProps<typeof GiftCard>;

type OwnProps = {
  header: GiftCardComponentProps['header'];
  footer: GiftCardComponentProps['footer'];
  messageCard: GiftCardComponentProps['messageCard'];
  giftCardTickets: GiftCardComponentProps['giftCardTickets'];
  giftWalletStorable: GiftCardComponentProps['giftWalletStorable'];
  handleTicketClick: GiftCardComponentProps['handleTicketClick'];
};

type GiftCardPreviewProps = OwnProps & WithStyles<typeof styles>;

const GiftCardPreview: React.FC<GiftCardPreviewProps> = ({
  header,
  footer,
  messageCard,
  giftCardTickets,
  giftWalletStorable,
  handleTicketClick
}) => {
  const [
    isGiftWalletFloatingButtonVisible,
    setIsGiftWalletFloatingButtonVisible
  ] = useState(true);

  useEffect(() => {
    const scrollContainerElement = document.getElementById(
      GIFT_CARD_PREVIEW_DIALOG_SCROLL_CONTAINER
    );
    scrollContainerElement?.addEventListener('scroll', handleScroll);
  }, []);

  const handleScroll = (event: Event) => {
    const scrollContainerElement = event.target as Element;
    const currentScrollPosition = scrollContainerElement.scrollTop;

    const isGiftWalletFloatingButtonVisible = currentScrollPosition
      ? currentScrollPosition < GIFT_WALLET_FLOATING_BUTTON_VISIBILE_BORDER
      : true;

    setIsGiftWalletFloatingButtonVisible(isGiftWalletFloatingButtonVisible);
  };

  return (
    <GiftCard
      header={header}
      footer={footer}
      messageCard={messageCard}
      giftCardTickets={giftCardTickets}
      handleTicketClick={handleTicketClick}
      isGiftWalletFloatingButtonVisible={isGiftWalletFloatingButtonVisible}
      giftWalletStorable={giftWalletStorable}
    />
  );
};

export default withStyles(styles)(GiftCardPreview);
