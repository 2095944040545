import {
  createErrorMessageForEmpty,
  createErrorMessageForIsNumeric
} from '@common/middleware/errorMessages';
import validator from 'validator';
import { validationSuccess, validationFailure } from '../../../actions/actions';
import {
  JP_DISTRIBUTION_PARTNER_USER_NAME,
  JP_DISTRIBUTION_PARTNER_USER_DIVISION,
  JP_DISTRIBUTION_PARTNER_USER_PHONE_NUMBER
} from '../../../common/constants';
import type { AppThunkAction } from '@console/actions/thunkType';

const DISTRIBUTION_PARTNER_USER_EDIT = 'accountDistributionPartnerUserEdit';
export const DISTRIBUTION_PARTNER_USER_NAME =
  'distributionPartnerUserName' as const;
export const DISTRIBUTION_PARTNER_USER_DIVISION =
  'distributionPartnerUserDivision' as const;
export const DISTRIBUTION_PARTNER_USER_PHONE_NUMBER =
  'distributionPartnerUserPhoneNumber' as const;

const validateDistributionPartnerUserName =
  (distributionPartnerUserName: string): AppThunkAction<void> =>
  dispatch => {
    let errorMessage: string = '';

    if (validator.isEmpty(distributionPartnerUserName)) {
      errorMessage = createErrorMessageForEmpty(
        JP_DISTRIBUTION_PARTNER_USER_NAME
      );
    }

    dispatch(handleValidation(errorMessage, DISTRIBUTION_PARTNER_USER_NAME));
  };

const validateDistributionPartnerUserDivision =
  (distributionPartnerUserDivision: string): AppThunkAction<void> =>
  dispatch => {
    let errorMessage: string = '';

    if (validator.isEmpty(distributionPartnerUserDivision)) {
      errorMessage = createErrorMessageForEmpty(
        JP_DISTRIBUTION_PARTNER_USER_DIVISION
      );
    }

    dispatch(
      handleValidation(errorMessage, DISTRIBUTION_PARTNER_USER_DIVISION)
    );
  };

const validateDistributionPartnerUserPhoneNumber =
  (distributionPartnerUserPhoneNumber: string): AppThunkAction<void> =>
  dispatch => {
    let errorMessage: string = '';

    if (validator.isEmpty(distributionPartnerUserPhoneNumber)) {
      errorMessage = createErrorMessageForEmpty(
        JP_DISTRIBUTION_PARTNER_USER_PHONE_NUMBER
      );
    } else if (
      !validator.isNumeric(distributionPartnerUserPhoneNumber, {
        no_symbols: true
      })
    ) {
      errorMessage = createErrorMessageForIsNumeric(
        JP_DISTRIBUTION_PARTNER_USER_PHONE_NUMBER
      );
    }

    dispatch(
      handleValidation(errorMessage, DISTRIBUTION_PARTNER_USER_PHONE_NUMBER)
    );
  };

const handleValidation =
  (errorMessage: string, errorKey: string): AppThunkAction<void> =>
  dispatch => {
    if (errorMessage === '') {
      dispatch(validationSuccess(DISTRIBUTION_PARTNER_USER_EDIT, errorKey));
    } else {
      dispatch(
        validationFailure(DISTRIBUTION_PARTNER_USER_EDIT, {
          [errorKey]: errorMessage
        })
      );
    }
  };

export const accountDistibutionPartnerUserEditValidators = {
  validateDistributionPartnerUserName,
  validateDistributionPartnerUserDivision,
  validateDistributionPartnerUserPhoneNumber
};
