import { AUTHENTICATION_ERROR_CODE } from '@gift_cards/actions/thunks/handleApolloError';
import {
  GRAPHQL_ERROR_NOT_FOUND_CODE,
  INTERNAL_SERVER_ERROR_CODE
} from '@gift_cards/actions/thunks/handleGraphqlErrorsIfNeeded';
import { createSelector } from 'reselect';
import type { AppState } from '@gift_cards/reducers';
import type { Error } from '@gift_cards/reducers/error';

const getError = (state: AppState): Error => state.error;

export const getHasNotFoundError = createSelector(
  [getError],
  (error): boolean => !!error && error.code === GRAPHQL_ERROR_NOT_FOUND_CODE
);

export const getHasAuthenticationError = createSelector(
  [getError],
  (error): boolean => !!error && error.code === AUTHENTICATION_ERROR_CODE
);

export const getHasInternalServerError = createSelector(
  [getError],
  (error): boolean => !!error && error.code === INTERNAL_SERVER_ERROR_CODE
);

export const getHasCommonError = createSelector(
  [getHasNotFoundError, getHasAuthenticationError, getHasInternalServerError],
  (hasNotFoundError, hasAuthenticationError, hasInternalServerError): boolean =>
    hasNotFoundError || hasAuthenticationError || hasInternalServerError
);
