import { ERROR_MESSAGE_FOR_EXCEED_CREDIT_LIMIT_IN_ADDING_ISSUE_CONFIGS } from '@common/middleware/errorMessages';
import {
  validateProjectAddingBulkGifteeBoxIssueConfigsSuccess,
  validateProjectAddingBulkGifteeBoxIssueConfigsFailure,
  validateProjectAddingBulkGifteeBoxIssueConfigsTotalSuccess,
  validateProjectAddingBulkGifteeBoxIssueConfigsTotalFailure
} from '@console/actions/actions';
import {
  getRequestedIssueAmountValidationErrorMessage,
  getDeliveryDateValidationErrorMessage
} from '@console/middleware/validator/project/validations';
import { PROJECT_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIG_ERROR_KEYS } from '@console/reducers/errors';
import { getCreditLimitBalance } from '@console/selectors/distributionPartner';
import { getAddingBulkGifteeBoxIssueConfigsTotal } from '@console/selectors/edit/project/gifteeBoxConfigs/addingBulkGifteeBoxIssueConfigs';
import { getSelectedGifteeBoxTemplate } from '@console/selectors/projectDetail/gifteeBoxConfigs';
import { getInitialPointValidationErrorMessage } from './validations';
import type { AppThunkAction } from '@console/actions/thunkType';
import type { AddingBulkGifteeBoxIssueConfig } from '@console/reducers/edit/project/gifteeBoxConfigs/addingBulkGifteeBoxIssueConfigs';
import type { ErrorMessage } from '@console/reducers/errors';
import type { Moment } from 'moment';

const validateAddingBulkGifteeBoxIssueConfigs =
  (): AppThunkAction<void> => (dispatch, getState) => {
    const { addingBulkGifteeBoxIssueConfigs } =
      getState().edits.project.gifteeBoxConfigs;

    addingBulkGifteeBoxIssueConfigs.forEach(
      (addingBulkGifteeBoxIssueConfig, index) =>
        dispatch(
          validateAddingBulkGifteeBoxIssueConfig(
            index,
            addingBulkGifteeBoxIssueConfig
          )
        )
    );

    dispatch(validateAddingBulkGifteeBoxIssueConfigsTotal());
  };

const validateAddingBulkGifteeBoxIssueConfig =
  (
    addingBulkGifteeBoxIssueConfigsIndex: number,
    addingBulkGifteeBoxIssueConfig: AddingBulkGifteeBoxIssueConfig
  ): AppThunkAction<void> =>
  dispatch => {
    dispatch(
      validateRequestedIssueAmount(
        addingBulkGifteeBoxIssueConfigsIndex,
        addingBulkGifteeBoxIssueConfig.requestedIssueAmount
      )
    );
    dispatch(
      validateDeliveryDate(
        addingBulkGifteeBoxIssueConfigsIndex,
        addingBulkGifteeBoxIssueConfig.deliveryDate
      )
    );
    dispatch(
      validateInitialPoint(
        addingBulkGifteeBoxIssueConfigsIndex,
        addingBulkGifteeBoxIssueConfig.initialPoint
      )
    );
  };

const validateRequestedIssueAmount =
  (
    addingBulkGifteeBoxIssueConfigsIndex: number,
    requestedIssueAmount:
      | string
      | AddingBulkGifteeBoxIssueConfig['requestedIssueAmount']
  ): AppThunkAction<void> =>
  dispatch => {
    const errorMessage: ErrorMessage =
      getRequestedIssueAmountValidationErrorMessage(requestedIssueAmount);

    if (errorMessage === '') {
      dispatch(
        validateProjectAddingBulkGifteeBoxIssueConfigsSuccess(
          PROJECT_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIG_ERROR_KEYS.REQUESTED_ISSUE_AMOUNT,
          addingBulkGifteeBoxIssueConfigsIndex
        )
      );
    } else {
      dispatch(
        validateProjectAddingBulkGifteeBoxIssueConfigsFailure(
          PROJECT_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIG_ERROR_KEYS.REQUESTED_ISSUE_AMOUNT,
          addingBulkGifteeBoxIssueConfigsIndex,
          errorMessage
        )
      );
    }
  };

const validateDeliveryDate =
  (
    addingBulkGifteeBoxIssueConfigsIndex: number,
    desiredDeliveryDate:
      | Moment
      | null
      | AddingBulkGifteeBoxIssueConfig['deliveryDate']
  ): AppThunkAction<void> =>
  dispatch => {
    const errorMessage: ErrorMessage =
      getDeliveryDateValidationErrorMessage(desiredDeliveryDate);

    if (errorMessage === '') {
      dispatch(
        validateProjectAddingBulkGifteeBoxIssueConfigsSuccess(
          PROJECT_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIG_ERROR_KEYS.DELIVERY_DATE,
          addingBulkGifteeBoxIssueConfigsIndex
        )
      );
    } else {
      dispatch(
        validateProjectAddingBulkGifteeBoxIssueConfigsFailure(
          PROJECT_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIG_ERROR_KEYS.DELIVERY_DATE,
          addingBulkGifteeBoxIssueConfigsIndex,
          errorMessage
        )
      );
    }
  };

const validateInitialPoint =
  (
    addingBulkGifteeBoxIssueConfigsIndex: number,
    initialPoint: AddingBulkGifteeBoxIssueConfig['initialPoint']
  ): AppThunkAction<void> =>
  (dispatch, getState) => {
    const supportedPoint =
      getSelectedGifteeBoxTemplate(getState())?.supportedPoint;

    const errorMessage: ErrorMessage = getInitialPointValidationErrorMessage(
      initialPoint,
      supportedPoint
    );

    if (errorMessage === '') {
      dispatch(
        validateProjectAddingBulkGifteeBoxIssueConfigsSuccess(
          PROJECT_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIG_ERROR_KEYS.INITIAL_POINT,
          addingBulkGifteeBoxIssueConfigsIndex
        )
      );
    } else {
      dispatch(
        validateProjectAddingBulkGifteeBoxIssueConfigsFailure(
          PROJECT_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIG_ERROR_KEYS.INITIAL_POINT,
          addingBulkGifteeBoxIssueConfigsIndex,
          errorMessage
        )
      );
    }
  };

const validateAddingBulkGifteeBoxIssueConfigsTotal =
  (): AppThunkAction<void> => (dispatch, getState) => {
    const state = getState();
    const creditLimitBalance = getCreditLimitBalance(state);
    const total = getAddingBulkGifteeBoxIssueConfigsTotal(state);
    if (typeof creditLimitBalance === 'number' && creditLimitBalance < total) {
      const errorMessage =
        ERROR_MESSAGE_FOR_EXCEED_CREDIT_LIMIT_IN_ADDING_ISSUE_CONFIGS;
      dispatch(
        validateProjectAddingBulkGifteeBoxIssueConfigsTotalFailure(errorMessage)
      );
    } else {
      dispatch(validateProjectAddingBulkGifteeBoxIssueConfigsTotalSuccess());
    }
  };

export const projectBulkGifteeBoxIssueConfigsValidator = {
  validateAddingBulkGifteeBoxIssueConfigs,
  validateRequestedIssueAmount,
  validateDeliveryDate,
  validateInitialPoint
};
