import { Typography, Grid, TableRow, TableCell } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => ({
  labelTableCell: {
    width: '25%'
  },
  valueTableCell: {
    whiteSpace: 'pre-wrap'
  }
});

type DetailItemOwnProps = { label: string; value: string };
type DetailItemProps = WithStyles<typeof styles> & DetailItemOwnProps;

const DetailTableRow: React.FC<DetailItemProps> = ({
  classes,
  label,
  value
}) => (
  <TableRow key={value}>
    {!!label ? (
      <TableCell component="th" scope="row" className={classes.labelTableCell}>
        <Typography variant="caption" color="textSecondary">
          {label}
        </Typography>
      </TableCell>
    ) : null}
    <TableCell
      align="left"
      className={classes.valueTableCell}
      data-cy={`detailTableCellValue-${label}`}
    >
      {value}
    </TableCell>
  </TableRow>
);

export default withStyles(styles)(DetailTableRow);
