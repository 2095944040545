import { getContents } from '@common/api/graphql/getContentsGql';
import {
  getContinuousContentsStart,
  getContinuousContentsSuccess,
  getContinuousContentsFailure
} from '@console/actions/actions';
import handleApiErrorIfNeeded from '@console/actions/thunks/handleApiErrorIfNeeded';
import { CONTENTS_PER_PAGE } from '@console/common/constants';
import type { ApiError } from '@common/api/graphql/apolloClient';
import type { GetContentsResponse } from '@common/api/graphql/getContentsGql';
import type { AppThunkAction } from '@console/actions/thunkType';

export const getContinuousContentsSuccessThunk =
  (response: GetContentsResponse): AppThunkAction<Promise<void>> =>
  dispatch => {
    const { contents } = response.data!;
    dispatch(getContinuousContentsSuccess(contents));
    return Promise.resolve();
  };

export const getContinuousContentsFailureThunk =
  (error: ApiError): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(getContinuousContentsFailure());
    return dispatch(handleApiErrorIfNeeded(error));
  };

export const getContinuousContentsThunk =
  (): AppThunkAction<Promise<void>> => (dispatch, getState) => {
    const { searchInfo } = getState();
    const contentsSearchInfo = searchInfo.contents;

    if (!contentsSearchInfo?.pageInfo?.hasNextPage) {
      return Promise.resolve();
    }

    dispatch(getContinuousContentsStart());

    return getContents({
      count: CONTENTS_PER_PAGE,
      startCursor: contentsSearchInfo?.pageInfo.endCursor ?? undefined,
      conditions: contentsSearchInfo.conditions
    })
      .then((response: GetContentsResponse) =>
        dispatch(getContinuousContentsSuccessThunk(response))
      )
      .catch(error => dispatch(getContinuousContentsFailureThunk(error)));
  };
