import { closeDrawer } from '@console/actions/actions';
import SideDrawer from '@console/components/project/Drawer';
import {
  getSelectedProjectDetail,
  getIsMypageProject
} from '@console/selectors/projectDetail';
import {
  getIsBulkProject,
  getIsApiProject,
  getIsGcpProject
} from '@console/selectors/projectDetail/solutionSystem';
import { connect } from 'react-redux';
import type { AppThunkDispatch } from '@console/actions/thunkType';
import type { AppState } from '@console/reducers';
import type { Location } from 'react-router-dom';

const isSelectedMenu = (location: Location, contentName: string) => {
  const regexp = new RegExp(`\/projects\/(.*\/)${contentName}`);
  return location.pathname.search(regexp) !== -1;
};

const mapStateToProps = (state: AppState) => ({
  isApiProject: getIsApiProject(state),
  isBulkProject: getIsBulkProject(state),
  isGcpProject: getIsGcpProject(state),
  isDrawerOpen: state.isDrawerOpen,
  isSampleProject: state.projectDetail.isSample,
  isMypageProject: getIsMypageProject(state)
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  closeDrawer: () => dispatch(closeDrawer()),
  isSelectedMenu: (location: Location, contentName: string) =>
    isSelectedMenu(location, contentName)
});

export type DrawerPropsMappedFromState = ReturnType<typeof mapStateToProps>;
export type DrawerPropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(mapStateToProps, mapDispatchToProps)(SideDrawer);
