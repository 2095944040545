import { AppActionTypes } from '@console/actions/actionType';
import type { AppAction } from '@console/actions/actionType';
import type { Reducer } from 'redux';

export type AddingBulkGiftCardIssueConfig = {
  requestedIssueAmount: string | null;
  desiredDeliveryDate: string | null;
};

export type AddingBulkGiftCardIssueConfigs = AddingBulkGiftCardIssueConfig[];

const initialAddingBulkGiftCardIssueConfig: AddingBulkGiftCardIssueConfig = {
  requestedIssueAmount: null,
  desiredDeliveryDate: null
};

const initialState: AddingBulkGiftCardIssueConfigs = [
  initialAddingBulkGiftCardIssueConfig
];

export const addingBulkGiftCardIssueConfigs: Reducer<
  AddingBulkGiftCardIssueConfigs,
  AppAction
> = (state = initialState, action) => {
  switch (action.type) {
    case AppActionTypes.INITIALIZE_BULK_GIFT_CARD_ISSUE_CONFIGS_NEW:
      return initialState;
    case AppActionTypes.UPDATE_ADDING_BULK_GIFT_CARD_ISSUE_CONFIG_REQUESTED_ISSUE_AMOUNT:
      return state.map((addingBulkGiftCardIssueConfig, index) => {
        if (index === action.addingBulkGiftCardIssueConfigIndex) {
          return {
            ...addingBulkGiftCardIssueConfig,
            requestedIssueAmount: action.requestedIssueAmount
          };
        }
        return addingBulkGiftCardIssueConfig;
      });

    case AppActionTypes.UPDATE_ADDING_BULK_GIFT_CARD_ISSUE_CONFIG_DESIRED_DELIVERY_DATE:
      return state.map((addingBulkGiftCardIssueConfig, index) => {
        if (index === action.addingBulkGiftCardIssueConfigIndex) {
          return {
            ...addingBulkGiftCardIssueConfig,
            desiredDeliveryDate: !!action.desiredDeliveryDate
              ? action.desiredDeliveryDate.format('YYYY-MM-DD')
              : null
          };
        }
        return addingBulkGiftCardIssueConfig;
      });
    case AppActionTypes.ADD_ADDING_BULK_GIFT_CARD_ISSUE_CONFIG:
      return [...state, initialAddingBulkGiftCardIssueConfig];
    case AppActionTypes.REMOVE_ADDING_BULK_GIFT_CARD_ISSUE_CONFIG:
      return state.filter(
        (_, index) => index !== action.addingBulkGiftCardIssueConfigIndex
      );
    default:
      return state;
  }
};
