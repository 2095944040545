import { getApiConfidentialInformation } from '@console/selectors/projectDetail/confidentialInformation';
import { getGifteeBoxConfigs } from '@console/selectors/projectDetail/gifteeBoxConfigs';
import { getGifteeBoxConfigForIssueReport } from '@console/selectors/projectDetail/gifteeBoxIssueCounts';
import { createSelector } from 'reselect';
import type { AppState } from '@console/reducers';
import type {
  LocalAPIConfidentialInformationGifteeBoxConfig,
  LocalGifteeBoxConfig
} from '@console/reducers/projects';
import type { GifteeBoxConfigForIssueReport } from '@console/selectors/projectDetail/gifteeBoxIssueCounts';

export type GifteeBoxConfigForSelectionReport = GifteeBoxConfigForIssueReport;
type GifteeBoxConfigsForSelectionReport = GifteeBoxConfigForSelectionReport[];

export const getGifteeBoxConfigsForSelectionReport = createSelector(
  [getGifteeBoxConfigs, getApiConfidentialInformation],
  (
    gifteeBoxConfigs,
    apiConfidentialInformation
  ): GifteeBoxConfigsForSelectionReport => {
    if (!apiConfidentialInformation) {
      return [];
    }
    return gifteeBoxConfigs.map(gifteeBoxConfig => {
      const confidentialInformationGifteeBoxConfig =
        apiConfidentialInformation.gifteeBoxConfigs.find(
          localConfidentialInformationGifteeBoxConfig =>
            localConfidentialInformationGifteeBoxConfig.urlCode ===
            gifteeBoxConfig.urlCode
        );

      return createGifteeBoxConfigForSelectionReport(
        gifteeBoxConfig,
        confidentialInformationGifteeBoxConfig
      );
    });
  }
);

const createGifteeBoxConfigForSelectionReport = (
  gifteeBoxConfig: LocalGifteeBoxConfig,
  apiConfidentialInformationGifteeBoxConfig:
    | LocalAPIConfidentialInformationGifteeBoxConfig
    | undefined
): GifteeBoxConfigForSelectionReport =>
  getGifteeBoxConfigForIssueReport(
    gifteeBoxConfig,
    apiConfidentialInformationGifteeBoxConfig
  );

const getSelectedGifteeBoxConfigUrlCode = (state: AppState) =>
  state.selectedProjectGifteeBoxConfigUrlCode;

export const getGifteeBoxConfigForSelectionReport = createSelector(
  [getGifteeBoxConfigsForSelectionReport, getSelectedGifteeBoxConfigUrlCode],
  (
    gifteeBoxConfigsForSelectionReport,
    selectedGifteeBoxConfigUrlCode
  ): GifteeBoxConfigForSelectionReport | undefined =>
    gifteeBoxConfigsForSelectionReport.find(
      gifteeBoxConfigForSelectionReport =>
        gifteeBoxConfigForSelectionReport.urlCode ===
        selectedGifteeBoxConfigUrlCode
    )
);
