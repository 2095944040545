import { combineReducers } from 'redux';
import { AppActionTypes } from '../actions/actionType';
import type { AppAction } from '../actions/actionType';
import type { ContentsConnection } from '@common/api/graphql/getContentsGql';
import type {
  GifteeBoxTemplateContentSelectionsConnection,
  ContentSelectionsConditions
} from '@common/api/graphql/getGifteeBoxTemplateContentSelectionsGql';
import type { Reducer } from 'redux';

export type ContentSelectionsSearchInfo = {
  pageInfo?: GifteeBoxTemplateContentSelectionsConnection['pageInfo'];
  conditions?: ContentSelectionsConditions;
};

const contentSelections = (
  state: ContentSelectionsSearchInfo | null = null,
  action: AppAction
): ContentSelectionsSearchInfo | null => {
  switch (action.type) {
    case AppActionTypes.DESELECT_GIFTEE_BOX_TEMPLATE:
      return null;
    case AppActionTypes.GET_GIFTEE_BOX_TEMPLATE_INITIAL_CONTENT_SELECTIONS_SUCCESS:
      return {
        ...state,
        pageInfo: action.contentSelectionsConnection.pageInfo
      };
    case AppActionTypes.GET_GIFTEE_BOX_TEMPLATE_CONTINUOUS_CONTENT_SELECTIONS_SUCCESS:
      if (state === null) {
        return {
          pageInfo: action.contentSelectionsConnection.pageInfo
        };
      } else {
        return {
          ...state,
          pageInfo: action.contentSelectionsConnection.pageInfo
        };
      }
    case AppActionTypes.UPDATE_GIFTEE_BOX_TEMPLATE_BRAND_FILTER:
      if (state === null) {
        return {
          conditions: getContentSelectionConditions(
            action.selectedBrandsUrlCodes
          )
        };
      } else {
        return {
          ...state,
          conditions: getContentSelectionConditions(
            action.selectedBrandsUrlCodes
          )
        };
      }
    default:
      return state;
  }
};

const getContentSelectionConditions = (
  brandUrlCodes: ContentSelectionsConditions['filter']['brandUrlCodes']
): ContentSelectionsConditions => ({
  filter: {
    brandUrlCodes
  }
});

export type ContentsSearchInfo = {
  pageInfo: ContentsConnection['pageInfo'] | undefined;
  conditions: ContentsConnection['conditions'];
};

const initialStateOfContentsSearchInfo = null;

const contents: Reducer<ContentsSearchInfo | null, AppAction> = (
  state = initialStateOfContentsSearchInfo,
  action
) => {
  switch (action.type) {
    case AppActionTypes.OPEN_CONTENTS_PAGE:
    case AppActionTypes.OPEN_PROJECT_DRAFT_CONTENTS_SELECTION_PAGE:
      return initialStateOfContentsSearchInfo;
    case AppActionTypes.GET_INITIAL_CONTENTS_SUCCESS:
    case AppActionTypes.GET_CONTINUOUS_CONTENTS_SUCCESS:
      return {
        pageInfo: action.contentsConnection.pageInfo,
        conditions: {
          filter: {
            ...state?.conditions.filter
          }
        }
      };
    case AppActionTypes.GET_INITIAL_CONTENTS_START: {
      if (!state) return initialStateOfContentsSearchInfo;

      const newState = { ...state };
      newState.pageInfo = undefined;

      return newState;
    }
    case AppActionTypes.CHANGE_CONTENTS_BRAND_FILTER: {
      if (!state) return initialStateOfContentsSearchInfo;

      const newState = { ...state };
      newState.conditions.filter.brandUrlCodes = action.brands?.map(
        brand => brand.urlCode
      );

      return newState;
    }
    case AppActionTypes.CHANGE_CONTENTS_PRICE_RANGE_FILTER: {
      if (!state) return initialStateOfContentsSearchInfo;

      const newState = { ...state };
      newState.conditions.filter.minPrice = action.minPrice;
      newState.conditions.filter.maxPrice = action.maxPrice;

      return newState;
    }
    default:
      return state;
  }
};

export type BulkDeliverablesSearchInfo = {
  pageInfo?: {
    startCursor: ConnectionCursor | null;
    endCursor: ConnectionCursor | null;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
  };
};

const initialStateOfBulkDeliverablesSearchInfo = null;

const bulkDeliverables: Reducer<
  BulkDeliverablesSearchInfo | null,
  AppAction
> = (state = initialStateOfBulkDeliverablesSearchInfo, action) => {
  switch (action.type) {
    case AppActionTypes.GET_PROJECT_INITIAL_BULK_DELIVERABLES_SUCCESS:
    case AppActionTypes.GET_PROJECT_CONTINUOUS_BULK_DELIVERABLES_SUCCESS:
      if (!action.bulkDeliverableConnection) return state;
      return {
        pageInfo: action.bulkDeliverableConnection.pageInfo
      };
    default:
      return state;
  }
};

export const searchInfo = combineReducers({
  contentSelections,
  contents,
  bulkDeliverables
});
