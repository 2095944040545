import { errorMessageForApolloClientNetworkError } from '@common/api/graphql/errorMessage';
import { isApolloError } from '@common/api/graphql/isApolloError';
import { graphqlFailure } from '../actions';
import { handleGraphqlErrors } from './handleGraphqlErrorsIfNeeded';
import type { AppThunkAction } from '../thunkType';
import type { ApolloError } from '@apollo/client';

export const handleApolloError =
  (error: ApolloError): AppThunkAction<Promise<void>> =>
  dispatch => {
    if (!isApolloError(error)) {
      return Promise.reject(JSON.stringify(error));
    }

    if (error.networkError) {
      dispatch(graphqlFailure([errorMessageForApolloClientNetworkError]));
      return Promise.reject(JSON.stringify(error));
    } else if (error.graphQLErrors.length > 0) {
      return dispatch(handleGraphqlErrors(error.graphQLErrors));
    }

    return Promise.reject(JSON.stringify(error));
  };
