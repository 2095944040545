import { gql } from '@apollo/client';

import { internalApiAppClient } from './apolloClient';
import { operationNames } from './operationNames';
import type { FetchResult } from '@apollo/client';

const getGiftCardMainVisualsGql = gql`
  query ${operationNames.GET_GIFT_CARD_DEFAULT_MAIN_VISUALS}{
    giftCardDefaultMainVisuals {
      imageUrl
      urlCode
    }
  }
`;

export type GiftCardMainVisual = {
  imageUrl: string;
  urlCode: string;
};

export type GiftCardMainVisuals = GiftCardMainVisual[];

export type GetGiftCardMainVisualsResponse = FetchResult<{
  giftCardDefaultMainVisuals: GiftCardMainVisuals;
}>;

export const getGiftCardMainVisuals =
  (): Promise<GetGiftCardMainVisualsResponse> =>
    internalApiAppClient.query({
      query: getGiftCardMainVisualsGql
    });
