import { updateDefaultBillingAddress } from '../../../../common/api/graphql/updateDefaultBillingAddressGql';
import {
  updateDefaultBillingAddressSuccess,
  updateDefaultBillingAddressFailure,
  updateDefaultBillingAddressStart
} from '../actions';
import handleApiErrorIfNeeded from './handleApiErrorIfNeeded';
import type {
  UpdateDefaultBillingAddressResponse,
  UpdateDefaultBillingAddressParams
} from '../../../../common/api/graphql/updateDefaultBillingAddressGql';
import type { AppState } from '../../reducers';
import type { AppThunkAction } from '../thunkType';
import type { ApiError } from '@common/api/graphql/apolloClient';

export const getUpdateDefaultBillingAddressRequestParams = (
  state: AppState
): UpdateDefaultBillingAddressParams => {
  const defaultBillingAddress =
    state.edits.distributionPartner.billingAddresses[0];
  return {
    city: defaultBillingAddress.city,
    address: defaultBillingAddress.address,
    division: defaultBillingAddress.division,
    name: defaultBillingAddress.name,
    optionalAddress: defaultBillingAddress.optionalAddress,
    postalCode: defaultBillingAddress.postalCode,
    prefectureId: defaultBillingAddress.prefecture.id
  };
};

export const updateDefaultBillingAddressSuccessThunk =
  (
    response: UpdateDefaultBillingAddressResponse
  ): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(updateDefaultBillingAddressSuccess());
    return Promise.resolve();
  };

export const updateDefaultBillingAddressFailureThunk =
  (error: ApiError): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(updateDefaultBillingAddressFailure());
    return dispatch(handleApiErrorIfNeeded(error));
  };

export const updateDefaultBillingAddressThunk =
  (): AppThunkAction<Promise<void>> => (dispatch, getState) => {
    dispatch(updateDefaultBillingAddressStart());
    const updateDefaultBillingAddressRequestParams =
      getUpdateDefaultBillingAddressRequestParams(getState());

    return updateDefaultBillingAddress(updateDefaultBillingAddressRequestParams)
      .then(response =>
        dispatch(updateDefaultBillingAddressSuccessThunk(response))
      )
      .catch(error => dispatch(updateDefaultBillingAddressFailureThunk(error)));
  };
