import { getLocaleEndDateString } from '@common/lib/getLocaleDate';
import {
  GIFTEE_BOX_TEMPLATE_BRAND_NAME,
  GIFTEE_BOX_TICKET_TYPE_DISPLAY_NAME
} from '@console/common/constants';
import { TableRow, TableCell, Chip, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import BrandCheckStatusChip from '../BrandCheckStatusChip';
import ContentCells from '../ContentCells';
import type { LocalGifteeBoxConfig } from '@console/reducers/projects';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => ({
  tableCell: {
    paddingRight: theme.spacing(4),
    verticalAlign: 'middle'
  }
});

type OwnProps = {
  gifteeBoxConfig: LocalGifteeBoxConfig;
};

type GifteeBoxConfigRowProps = OwnProps & WithStyles<typeof styles>;

const GifteeBoxConfigRow: React.FC<GifteeBoxConfigRowProps> = ({
  classes,
  gifteeBoxConfig
}) => (
  <TableRow data-cy={`gifteeBoxConfigRow-${gifteeBoxConfig.urlCode}`}>
    <TableCell className={classes.tableCell}>
      {gifteeBoxConfig.apiGifteeBoxIssueConfigs.map(apiGifteeBoxIssueConfig => (
        <div
          key={`apiGifteeBoxIssueConfig-${apiGifteeBoxIssueConfig.urlCode}`}
          className={classes.issuableDuration}
        >
          <Typography
            variant="body2"
            key={`apiGifteeBoxIssueConfigIssuableEndAt-${apiGifteeBoxIssueConfig.urlCode}`}
          >
            {getLocaleEndDateString(apiGifteeBoxIssueConfig.issuableEndAt)}
          </Typography>
        </div>
      ))}
    </TableCell>
    <TableCell
      className={classes.tableCell}
      align="left"
      data-cy={`gifteeBoxConfigRowTicketType-${gifteeBoxConfig.urlCode}`}
    >
      <Chip label={GIFTEE_BOX_TICKET_TYPE_DISPLAY_NAME} />
    </TableCell>
    <ContentCells
      brandName={GIFTEE_BOX_TEMPLATE_BRAND_NAME}
      contentName={gifteeBoxConfig.gifteeBoxTemplate.name}
      contentImageUrl={gifteeBoxConfig.gifteeBoxTemplate.imageUrl}
    />
    <TableCell className={classes.tableCell} align="right">
      <BrandCheckStatusChip
        creativeCheckApproved={gifteeBoxConfig.brandCheckApproved}
      />
    </TableCell>
  </TableRow>
);

export default withStyles(styles)(GifteeBoxConfigRow);
