import { getLocaleDateString } from '@common/lib/getLocaleDate';
import { CONSUMPTION_TAX } from '@console/common/constants';
import GifteeBoxTemplateBrief from '@console/components/common/GifteeBoxTemplateBrief';
import GiftCardTicketConfigContents from '@console/components/project/common/GiftCardTicketConfigContents';
import IssueAmountRequestNotes from '@console/components/project/common/IssueAmountRequestNotes';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  DialogActions,
  Button,
  Grid
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { Fragment } from 'react';
import type { AddingBulkGiftCardIssueConfigs } from '@console/reducers/edit/project/giftCardConfigs/addingBulkGiftCardIssueConfigs';
import type { AddingBulkGifteeBoxIssueConfigs } from '@console/reducers/edit/project/gifteeBoxConfigs/addingBulkGifteeBoxIssueConfigs';
import type {
  LocalGiftCardTicketConfig,
  LocalGifteeBoxConfig,
  LocalBulkGifteeBoxIssueConfig
} from '@console/reducers/projects';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';
import type { ReactElement } from 'react';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    dialogCard: {
      padding: theme.spacing(2)
    },
    table: {
      borderBottom: 'hidden'
    },
    labelTableCell: {
      whiteSpace: 'nowrap'
    },
    contentImage: {
      width: theme.spacing(8),
      height: theme.spacing(8),
      objectFit: 'contain',
      verticalAlign: 'middle',
      margin: theme.spacing()
    },
    alert: {
      border: '1px solid',
      borderColor: theme.palette.error.main,
      error: theme.palette.error.main,
      padding: theme.spacing(),
      listStyle: 'none'
    }
  });

export type IssueAmountRequestModalOwnProps = {
  open: boolean;
  giftCardTicketConfigs?: LocalGiftCardTicketConfig[];
  gifteeBoxConfig?: LocalGifteeBoxConfig;
  toggleModalOpen: () => void;
  handleClick: Function;
  issueAmount: string;
  currentPriceTotal: string;
  deliveryDate?: string;
  addingBulkGiftCardIssueConfigs?: AddingBulkGiftCardIssueConfigs;
  addingBulkGifteeBoxIssueConfigs?: AddingBulkGifteeBoxIssueConfigs;
};

type IssueAmountRequestModalProps = IssueAmountRequestModalOwnProps &
  WithStyles<typeof styles>;

const IssueAmountRequestModal: React.FC<IssueAmountRequestModalProps> = ({
  open,
  giftCardTicketConfigs,
  gifteeBoxConfig,
  addingBulkGiftCardIssueConfigs,
  addingBulkGifteeBoxIssueConfigs,
  issueAmount,
  currentPriceTotal,
  deliveryDate,
  toggleModalOpen,
  handleClick,
  classes
}) => {
  if (
    !giftCardTicketConfigs &&
    !gifteeBoxConfig &&
    !addingBulkGifteeBoxIssueConfigs
  ) {
    return null;
  }

  const getContentsCell = (): ReactElement[] | ReactElement => {
    if (!!giftCardTicketConfigs) {
      return getGiftCardContentCell(giftCardTicketConfigs);
    } else if (!!gifteeBoxConfig) {
      return getGifteeBoxContentCell(gifteeBoxConfig);
    }

    return <></>;
  };

  const getGiftCardContentCell = (
    giftCardTicketConfigs: LocalGiftCardTicketConfig[]
  ): ReactElement[] =>
    giftCardTicketConfigs.map(
      (giftCardTicketConfig, giftCardTicketConfigIndex) => (
        <Fragment key={`content-${giftCardTicketConfigIndex}`}>
          <GiftCardTicketConfigContents
            giftCardTicketConfig={giftCardTicketConfig}
          />
        </Fragment>
      )
    );

  const exchangeableEndLabelString = (
    bulkGifteeBoxIssueConfigs: LocalBulkGifteeBoxIssueConfig[]
  ): string =>
    bulkGifteeBoxIssueConfigs.length !== 0
      ? bulkGifteeBoxIssueConfigs[bulkGifteeBoxIssueConfigs.length - 1]
          .gifteeBoxIssueConfig.exchangeableEndLabel
      : '';

  const getGifteeBoxContentCell = (
    gifteeBoxConfig: LocalGifteeBoxConfig
  ): ReactElement => (
    <GifteeBoxTemplateBrief
      gifteeBoxTemplateName={gifteeBoxConfig.gifteeBoxTemplate.name}
      gifteeBoxTemplateImageUrl={gifteeBoxConfig.gifteeBoxTemplate.imageUrl}
      exchangeableEndLabel={exchangeableEndLabelString(
        gifteeBoxConfig.bulkGifteeBoxIssueConfigs
      )}
    />
  );
  return (
    <Dialog
      open={open}
      onClose={toggleModalOpen}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      className={classes.dialogCard}
      data-cy="issueAmountRequestModal"
    >
      <DialogTitle id="modal-title">
        下記の数量と納品日で発注/納品します。最終確定しますか？
      </DialogTitle>
      <DialogContent>
        <ul className={classes.alert}>
          <li>
            <Typography color="error">
              ・確定後の変更・キャンセルはできません。
            </Typography>
          </li>
          <li>
            <Typography color="error">
              ・確定した件数でご請求します。
            </Typography>
          </li>
        </ul>
        <Table className={classes.table}>
          <TableBody>
            <TableRow>
              <TableCell
                component="th"
                scope="row"
                className={classes.labelTableCell}
              >
                <Typography variant="caption" color="textSecondary">
                  ギフト・ブランド名
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Grid container alignItems="center">
                  {getContentsCell()}
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow data-cy="issueAmountRow">
              <TableCell
                component="th"
                scope="row"
                className={classes.labelTableCell}
              >
                <Typography variant="caption" color="textSecondary">
                  数量
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography variant="body2" className={classes.content}>
                  {issueAmount}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow data-cy="currentPriceTotalRow">
              <TableCell
                component="th"
                scope="row"
                className={classes.labelTableCell}
              >
                <Typography variant="caption" color="textSecondary">
                  商品代金合計
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography variant="body2" className={classes.content}>
                  {currentPriceTotal}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow data-cy="deliveryDateRow">
              <TableCell
                component="th"
                scope="row"
                className={classes.labelTableCell}
              >
                <Typography variant="caption" color="textSecondary">
                  希望納品日
                </Typography>
              </TableCell>
              <TableCell align="left">
                {deliveryDate && (
                  <Typography variant="body2" className={classes.content}>
                    {getLocaleDateString(deliveryDate)}
                  </Typography>
                )}
                {addingBulkGiftCardIssueConfigs &&
                  addingBulkGiftCardIssueConfigs.map(
                    (addingBulkGiftCardIssueConfig, index) => (
                      <Typography
                        key={index}
                        variant="body2"
                        className={classes.content}
                      >
                        {`${
                          addingBulkGiftCardIssueConfig &&
                          addingBulkGiftCardIssueConfig.desiredDeliveryDate &&
                          getLocaleDateString(
                            addingBulkGiftCardIssueConfig.desiredDeliveryDate
                          )
                        } 数量: ${
                          addingBulkGiftCardIssueConfig.requestedIssueAmount
                        }`}
                      </Typography>
                    )
                  )}
                {addingBulkGifteeBoxIssueConfigs &&
                  addingBulkGifteeBoxIssueConfigs.map(
                    (addingBulkGifteeBoxIssueConfig, index) => (
                      <Typography
                        key={index}
                        variant="body2"
                        className={classes.content}
                      >
                        {`${
                          addingBulkGifteeBoxIssueConfig &&
                          addingBulkGifteeBoxIssueConfig.deliveryDate &&
                          getLocaleDateString(
                            addingBulkGifteeBoxIssueConfig.deliveryDate
                          )
                        } 数量: ${
                          addingBulkGifteeBoxIssueConfig.requestedIssueAmount
                        }`}
                      </Typography>
                    )
                  )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <div data-cy="issueAmountRequestNote">
          <IssueAmountRequestNotes />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleModalOpen} color="primary">
          キャンセル
        </Button>
        <Button
          variant="contained"
          onClick={() => handleClick()}
          color="primary"
          autoFocus
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(IssueAmountRequestModal);
