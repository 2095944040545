import { AppActionTypes } from '../../actions/actionType';
import type { AppAction } from '../../actions/actionType';

export type CampaignSolutionSystemGcpSelected = {
  value: boolean;
};

export const initialStateOfCampaignSolutionSystemGcpSelected = {
  value: false
};

export const solutionSystemGcpSelected = (
  state = initialStateOfCampaignSolutionSystemGcpSelected,
  action: AppAction
): CampaignSolutionSystemGcpSelected => {
  switch (action.type) {
    case AppActionTypes.ADD_PROJECT_DRAFT:
    case AppActionTypes.COPY_PROJECT_TO_PROJECT_DRAFT_FAILURE:
      return initialStateOfCampaignSolutionSystemGcpSelected;
    case AppActionTypes.UPDATE_CAMPAIGN_SOLUTION_SYSTEM_GCP_SELECTED:
      return {
        value: action.campaignSolutionSystemGcpSelected === 'true'
      };
    case AppActionTypes.UPDATE_CAMPAIGN_SOLUTION_SYSTEM_API_BULK:
      return {
        value: false
      };
    default:
      return state;
  }
};

export default solutionSystemGcpSelected;
