import { GRAPHQL_ERROR_CODE_OF_NOT_FOUND } from '@common/api/graphql/errorCode';
import { getProjectByName } from '@common/api/graphql/getProjectByNameGql';
import { isApolloError } from '@common/api/graphql/isApolloError';
import { isReadonlyArrayGraphQLError } from '@common/api/graphql/isReadonlyArrayGraphqlErrors';
import {
  checkIfProjectExistsFailure,
  checkIfProjectExistsStart,
  checkIfProjectExistsSuccess
} from '@console/actions/actions';
import type { ApiError } from '@common/api/graphql/apolloClient';
import type { AppThunkAction } from '@console/actions/thunkType';

export const checkIfProjectExistsFailureThunk =
  (error: ApiError): AppThunkAction<boolean> =>
  () => {
    if (isApolloError(error)) {
      return true;
    } else if (isReadonlyArrayGraphQLError(error)) {
      return error[0].extensions.code !== GRAPHQL_ERROR_CODE_OF_NOT_FOUND;
    }
    return true;
  };

export const checkIfProjectExistsThunk =
  (name: string): AppThunkAction<Promise<boolean>> =>
  dispatch => {
    dispatch(checkIfProjectExistsStart());

    return getProjectByName(name)
      .then(() => {
        dispatch(checkIfProjectExistsSuccess());
        return true;
      })
      .catch(error => {
        dispatch(checkIfProjectExistsFailure());
        return dispatch(checkIfProjectExistsFailureThunk(error));
      });
  };
