import { hasGon } from '@common/lib/gon';
import type { Gon } from '@console/common/window';

const G4B_MYPAGE_PATHES = {
  DASHBOARD: {
    PROJECTS: 'dashboard/projects'
  }
};

const getG4bMyPageEndpoint = (): string => {
  if (hasGon<Gon>(window)) {
    const { g4bMyPageEndpoint } = window.gon;
    return g4bMyPageEndpoint || '';
  } else {
    return '';
  }
};

export const getG4bMyPageContentsUrl = (): string => {
  const g4bMyPageEndpoint = getG4bMyPageEndpoint();
  return `${g4bMyPageEndpoint}/contents`;
};

export const getG4bMyPageProjectsUrl = (): string =>
  `${getG4bMyPageEndpoint()}/${G4B_MYPAGE_PATHES.DASHBOARD.PROJECTS}`;

export const getG4bMyPageImmediateDeliveryUrl = (): string => {
  const g4bMyPageEndpoint = getG4bMyPageEndpoint();
  return `${g4bMyPageEndpoint}/dashboard/projects/new/immediate_delivery_entry/agreement`;
};

export const getG4bMyPageBulkEntryUrl = (): string => {
  const g4bMyPageEndpoint = getG4bMyPageEndpoint();
  return `${g4bMyPageEndpoint}/dashboard/projects/new/bulk_entry/cart`;
};
