import { Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => ({
  note: {
    marginBottom: theme.spacing()
  }
});

type NoteProps = WithStyles<typeof styles>;

const Note: React.FC<React.PropsWithChildren<NoteProps>> = ({
  classes,
  children
}) => (
  <Typography
    variant="body2"
    color="textSecondary"
    className={classes.note}
    data-cy="note"
  >
    {children}
  </Typography>
);

export default withStyles(styles)(Note);
