import { getLocaleEndDateString } from '@common/lib/getLocaleDate';
import {
  GIFTEE_BOX_TEMPLATE_BRAND_NAME,
  GIFTEE_BOX_DISPLAY_NAME
} from '@console/common/constants';
import ContentBrief from '@console/components/common/ContentBrief';
import { faFileCsv } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { useNavigate } from 'react-router';
import type {
  APITablePropsMappedFromDispatch,
  APITablePropsMappedFromState
} from '@console/containers/project/selectionReport/APITableContainer';
import type { Theme } from '@mui/material';

import type { StyleRules, WithStyles } from '@mui/styles';

// apiの有効期限は最新の設定だけを表示する
const LAST_API_ISSUE_CONFIG_ITEM_INDEX = -1;

const styles = (theme: Theme): StyleRules => ({
  tableContainer: {
    overflowX: 'scroll',
    whiteSpace: 'nowrap'
  },
  contentTableCell: {
    width: '30%',
    minWidth: 400
  },
  tableCell: {
    width: '20%',
    minWidth: 100
  },
  gifteeBoxConfigIssueCodeTableCell: {
    width: '30%',
    minWidth: 200
  },
  nextButtonTableCell: {
    width: '20%',
    minWidth: 200,
    textAlign: 'center'
  },
  empty: {
    textAlign: 'center',
    paddingTop: theme.spacing(4)
  },
  emptyGifteeBoxSelectionReport: {
    margin: theme.spacing(5),
    color: theme.palette.icon.file
  }
});

type APITableProps = APITablePropsMappedFromState &
  APITablePropsMappedFromDispatch &
  WithStyles<typeof styles>;

const APITable: React.FC<APITableProps> = ({
  gifteeBoxConfigsForSelectionReport,
  onClickGifteeBoxConfig,
  classes
}) => {
  const navigate = useNavigate();

  return (
    <>
      {gifteeBoxConfigsForSelectionReport.length !== 0 ? (
        <div className={classes.tableContainer}>
          <Table>
            <TableHead data-cy="tableHeader">
              <TableRow>
                <TableCell>
                  <Typography>ギフト名</Typography>
                </TableCell>
                <TableCell>
                  <Typography>giftee_box_config_code</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>有効期限</Typography>
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {gifteeBoxConfigsForSelectionReport.length !== 0 &&
                gifteeBoxConfigsForSelectionReport.map(
                  (
                    gifteeBoxConfigForSelectionReport,
                    gifteeBoxConfigForSelectionReportIndex
                  ) =>
                    gifteeBoxConfigForSelectionReport.apiGifteeBoxIssueConfigs
                      .slice(LAST_API_ISSUE_CONFIG_ITEM_INDEX)
                      .map(
                        (
                          apiGifteeBoxIssueConfig,
                          apiGifteeBoxIssueConfigIndex
                        ) => (
                          <TableRow
                            key={`${gifteeBoxConfigForSelectionReportIndex}`}
                            data-cy={`gifteeBoxConfig-tableRow-${gifteeBoxConfigForSelectionReport.urlCode}`}
                          >
                            <TableCell
                              className={classes.contentTableCell}
                              data-cy={`gifteeBoxConfig-tableCell-contentBrief-${gifteeBoxConfigForSelectionReport.urlCode}`}
                            >
                              <ContentBrief
                                contentName={
                                  gifteeBoxConfigForSelectionReport
                                    .gifteeBoxTemplate.name
                                }
                                contentImageUrl={
                                  gifteeBoxConfigForSelectionReport
                                    .gifteeBoxTemplate.imageUrl
                                }
                                contentBrandName={
                                  GIFTEE_BOX_TEMPLATE_BRAND_NAME
                                }
                              />
                            </TableCell>
                            <TableCell
                              className={
                                classes.gifteeBoxConfigIssueCodeTableCell
                              }
                              data-cy={`gifteeBoxConfig-tableCell-issueCode-${gifteeBoxConfigForSelectionReport.urlCode}`}
                            >
                              <Typography>
                                {gifteeBoxConfigForSelectionReport.issueCode}
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tableCell}
                              data-cy={`gifteeBoxConfig-tableCell-issuableEndAt-${gifteeBoxConfigForSelectionReport.urlCode}`}
                            >
                              <Typography>
                                {getLocaleEndDateString(
                                  apiGifteeBoxIssueConfig.issuableEndAt
                                )}
                              </Typography>
                            </TableCell>
                            <TableCell className={classes.nextButtonTableCell}>
                              <Button
                                color="primary"
                                variant="contained"
                                data-cy={`gifteeBoxConfig-tableCell-goToExchangeReoprtButton-${gifteeBoxConfigForSelectionReport.urlCode}`}
                                onClick={() =>
                                  onClickGifteeBoxConfig(
                                    navigate,
                                    gifteeBoxConfigForSelectionReport.urlCode
                                  )
                                }
                              >
                                選択実績を見る
                                <KeyboardArrowRightIcon />
                              </Button>
                            </TableCell>
                          </TableRow>
                        )
                      )
                )}
            </TableBody>
          </Table>
        </div>
      ) : (
        <div className={classes.empty}>
          <FontAwesomeIcon
            icon={faFileCsv}
            size="3x"
            className={classes.emptyGifteeBoxSelectionReport}
            data-cy="dataEmptyIcon"
          />
          <Typography
            variant="subtitle1"
            color="textSecondary"
            data-cy="dataEmptyString"
          >
            {`${GIFTEE_BOX_DISPLAY_NAME}選択実績はありません`}
          </Typography>
        </div>
      )}
    </>
  );
};

export default withStyles(styles)(APITable);
