import type {
  QueryProject,
  QueryGiftCardConfig,
  QueryBulkGiftCardIssueConfig,
  QueryGifteeBoxConfigs,
  QueryBulkGifteeBoxIssueConfig
} from '@common/api/graphql/getProjectsGql';
import type {
  LocalProject,
  LocalGiftCardConfig,
  LocalBulkGiftCardIssueConfig,
  LocalGifteeBoxConfigs,
  LocalBulkGifteeBoxIssueConfig,
  LocalBulkGifteeBoxIssueConfigIssueAmountRequest
} from '@console/reducers/projects';

export const convertQueryProjectIntoLocalProject = (
  project: QueryProject
): LocalProject => ({
  ...project,
  giftCardConfigs: getLocalGiftCardConfigs(project.giftCardConfigs),
  gifteeBoxConfigs: getLocalGifteeBoxConfigs(project.gifteeBoxConfigs)
});

const getLocalGiftCardConfigs = (
  giftCardConfigs: QueryGiftCardConfig[]
): LocalGiftCardConfig[] =>
  giftCardConfigs.map(giftCardConfig => ({
    ...giftCardConfig,
    bulkGiftCardIssueConfigs: giftCardConfig.bulkGiftCardIssueConfigs.map(
      bulkGiftCardIssueConfig =>
        addBulkGiftCardConfigIssueAmountRequestIfNeeded(bulkGiftCardIssueConfig)
    )
  }));

const addBulkGiftCardConfigIssueAmountRequestIfNeeded = (
  bulkGiftCardIssueConfig: QueryBulkGiftCardIssueConfig
): LocalBulkGiftCardIssueConfig => ({
  ...bulkGiftCardIssueConfig,
  issueAmountRequest:
    bulkGiftCardIssueConfig.issueAmountRequest !== null
      ? bulkGiftCardIssueConfig.issueAmountRequest
      : getBulkGiftCardConfigIssueAmountRequest(bulkGiftCardIssueConfig),
  isIssueAmountRequested: bulkGiftCardIssueConfig.issueAmountRequest !== null,
  claimEstimate: null
});

const getBulkGiftCardConfigIssueAmountRequest = (
  bulkGiftCardIssueConfig: QueryBulkGiftCardIssueConfig
): LocalBulkGiftCardIssueConfig['issueAmountRequest'] => ({
  issueAmount: bulkGiftCardIssueConfig.requestedIssueAmount,
  requestedAt: ''
});

const getLocalGifteeBoxConfigs = (
  gifteeBoxConfigs: QueryGifteeBoxConfigs
): LocalGifteeBoxConfigs => {
  if (!gifteeBoxConfigs) return null;

  return gifteeBoxConfigs.map(gifteeBoxConfig => ({
    ...gifteeBoxConfig,
    bulkGifteeBoxIssueConfigs: getLocalBulkGifteeBoxIssueConfigs(
      gifteeBoxConfig.bulkGifteeBoxIssueConfigs
    )
  }));
};

const getLocalBulkGifteeBoxIssueConfigs = (
  bulkGifteeBoxIssueConfigs: QueryBulkGifteeBoxIssueConfig[]
): LocalBulkGifteeBoxIssueConfig[] =>
  bulkGifteeBoxIssueConfigs.map(bulkGifteeBoxIssueConfig => ({
    ...bulkGifteeBoxIssueConfig,
    isIssueAmountRequested:
      bulkGifteeBoxIssueConfig.issueAmountRequest !== null,
    issueAmountRequest: getLocalIssueAmountRequest(bulkGifteeBoxIssueConfig),
    claimEstimate: null
  }));

const getLocalIssueAmountRequest = (
  bulkGifteeBoxIssueConfig: QueryBulkGifteeBoxIssueConfig
): LocalBulkGifteeBoxIssueConfigIssueAmountRequest =>
  bulkGifteeBoxIssueConfig.issueAmountRequest !== null
    ? bulkGifteeBoxIssueConfig.issueAmountRequest
    : {
        issueAmount: bulkGifteeBoxIssueConfig.requestedIssueAmount,
        requestedAt: ''
      };
