import AuthenticationError from '@common/components/AuthenticationError';
import InternalServerError from '@common/components/InternalServerError';
import NotFound from '@common/components/NotFound';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import type {
  CommonErrorPropsMappedFromState,
  CommonErrorPropsMappedFromDispatch
} from '@gift_cards/containers/CommonErrorContainer';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => createStyles({});

export type CommonErrorProps = CommonErrorPropsMappedFromState &
  CommonErrorPropsMappedFromDispatch &
  WithStyles<typeof styles>;

const CommonError: React.FC<CommonErrorProps> = ({
  hasNotFoundError,
  hasAuthenticationError,
  hasInternalServerError,
  classes
}) => {
  const renderErrorFromCode = () => {
    if (hasNotFoundError) {
      return <NotFound />;
    }
    if (hasAuthenticationError) {
      return <AuthenticationError />;
    }
    if (hasInternalServerError) {
      return <InternalServerError />;
    }
    return null;
  };

  return renderErrorFromCode();
};

export default withStyles(styles)(CommonError);
