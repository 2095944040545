import { getProjectDrafts } from '@common/api/graphql/getProjectDraftsGql';
import {
  getProjectDraftsStart,
  getProjectDraftsSuccess,
  getProjectDraftsFailure
} from '@console/actions/actions';
import { convertProjectDrafts } from './converters/getProjectDrafts';
import handleApiErrorIfNeeded from './handleApiErrorIfNeeded';
import type { ApiError } from '@common/api/graphql/apolloClient';
import type { AppThunkAction } from '@console/actions/thunkType';

const getProjectDraftsSuccessThunk =
  (projectDrafts): AppThunkAction<Promise<void>> =>
  dispatch => {
    const convertedProjectDrafts = convertProjectDrafts(projectDrafts);

    dispatch(getProjectDraftsSuccess(convertedProjectDrafts));
    return Promise.resolve();
  };

export const getProjectDraftsFailureThunk =
  (error: ApiError): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(getProjectDraftsFailure());
    return dispatch(handleApiErrorIfNeeded(error));
  };

export const getProjectDraftsThunk =
  (): AppThunkAction<Promise<void>> => (dispatch, getState) => {
    dispatch(getProjectDraftsStart());
    return getProjectDrafts()
      .then(response => {
        dispatch(getProjectDraftsSuccessThunk(response.data!.projectDrafts));
      })
      .catch(error => {
        dispatch(getProjectDraftsFailureThunk(error));
      });
  };
