import { gql } from '@apollo/client';
import { internalApiAppClient } from '@common/api/graphql/apolloClient';
import { FRAGMENT_PROJECT_DETAIL } from '@common/api/graphql/fragments/project';
import { operationNames } from './index';
import type { FetchResult } from '@apollo/client';
import type { QueryProject } from '@common/api/graphql/getProjectsGql';

const getProjectDetailGql = gql`
  ${FRAGMENT_PROJECT_DETAIL}
  query ${operationNames.GET_PROJECT_DETAIL}($urlCode: String!) {
    project(urlCode: $urlCode) {
      ...ProjectDetailFields
    }
  }
`;

export type GetProjectDetailResponse = FetchResult<{
  project: QueryProject;
}>;

export const getProjectDetail = (
  urlCode: UrlCode
): Promise<GetProjectDetailResponse> =>
  internalApiAppClient.query({
    query: getProjectDetailGql,
    variables: {
      urlCode
    }
  });
