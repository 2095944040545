import { getHasProjectAddingBulkGifteeBoxIssueConfigsError } from '@console/selectors/errors';
import { validateProjectAddingBulkGifteeBoxIssueConfigs } from '../actions';
import type { AppThunkAction } from '../thunkType';

export const validateProjectAddingBulkGifteeBoxIssueConfigsThunk =
  (): AppThunkAction<Promise<void>> => (dispatch, getState) => {
    dispatch(validateProjectAddingBulkGifteeBoxIssueConfigs());

    if (getHasProjectAddingBulkGifteeBoxIssueConfigsError(getState())) {
      return Promise.reject();
    }
    return Promise.resolve();
  };
