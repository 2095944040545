import { isFeatureEnable, FeatureTypes } from '@common/lib/featureToggle';
import { checkIfProjectExistsThunk } from '@console/actions/thunks/checkIfProjectExists';
import { isHostCompanyOther } from '@console/reducers/projectJson/hostCompany';
import moment from 'moment';
import validator from 'validator';
import {
  createErrorMessageForEmpty,
  createErrorMessageForPastDate,
  createErrorMessageForProjectDraftFutureDate,
  createErrorMessageForDateConsistency,
  createErrorMessageForUnselected,
  createErrorMessageForDateDuration,
  ERROR_MESSAGE_FOR_DUPLICATE_CAMPAIGN_NAMES
} from '../../../../../common/middleware/errorMessages';
import { validationSuccess, validationFailure } from '../../../actions/actions';
import {
  JP_START_DATE_OF_AD,
  JP_END_DATE_OF_AD,
  JP_START_DATE_OF_DISTRIBUTION,
  JP_END_DATE_OF_DISTRIBUTION
} from '../../../common/constants';
import {
  PROJECT_DRAFT_CAMPAIGN_OVERVIEW_ERROR_KEYS,
  PROJECT_DRAFT_CAMPAIGN_OVERVIEW_ERROR_GROUP
} from '../../../reducers/errors';
import type { AppThunkAction } from '../../../actions/thunkType';
import type { Errors } from '../../../reducers/errors';
import type { HostCompany } from '@console/reducers/projectJson/hostCompany';

const validateCampaignOverview =
  (): AppThunkAction<void> => async (dispatch, getState) => {
    const { projectJson } = getState().selectedProjectDraft;

    await dispatch(validateCampaignName(projectJson.name));
    dispatch(validateCampaignDescription(projectJson.description));
    dispatch(validateHostCompany(projectJson.hostCompany));

    dispatch(
      validateStartDateOfAd(projectJson.advertisingPeriod.startDateOfAd)
    );
    dispatch(validateEndDateOfAd(projectJson.advertisingPeriod.endDateOfAd));
    dispatch(validateDateConsistencyOfAd());
    dispatch(validateDateDurationOfAd());

    dispatch(
      validateStartDateOfDistribution(
        projectJson.giftDistributionPeriod.startDateOfDistribution
      )
    );
    dispatch(
      validateEndDateOfDistribution(
        projectJson.giftDistributionPeriod.endDateOfDistribution
      )
    );
    dispatch(validateDateConsistencyOfDistiribution());
    dispatch(validateDateDurationOfDistiribution());

    dispatch(
      validateCampaignSolutionSystem(projectJson.campaignSolutionSystem.urlCode)
    );

    dispatch(
      validateCampaignMarketingIndustry(
        projectJson.campaignMarketingIndustry.urlCode
      )
    );
  };

const validateCampaignName =
  (name: string): AppThunkAction<Promise<void>> =>
  async dispatch => {
    let errorMessage: string = '';

    if (validator.isEmpty(name)) {
      errorMessage = createErrorMessageForEmpty('キャンペーン名');
    } else {
      const isExists = await dispatch(checkIfProjectExistsThunk(name));
      if (isExists) {
        errorMessage = ERROR_MESSAGE_FOR_DUPLICATE_CAMPAIGN_NAMES;
      }
    }
    if (errorMessage === '') {
      dispatch(
        validationSuccess(
          PROJECT_DRAFT_CAMPAIGN_OVERVIEW_ERROR_GROUP,
          PROJECT_DRAFT_CAMPAIGN_OVERVIEW_ERROR_KEYS.NAME
        )
      );
    } else {
      dispatch(
        validationFailure(PROJECT_DRAFT_CAMPAIGN_OVERVIEW_ERROR_GROUP, {
          [PROJECT_DRAFT_CAMPAIGN_OVERVIEW_ERROR_KEYS.NAME]: errorMessage
        })
      );
    }
  };

const validateCampaignDescription =
  (description: string): AppThunkAction<void> =>
  dispatch => {
    let errorMessage: string = '';

    if (validator.isEmpty(description)) {
      errorMessage = createErrorMessageForEmpty('キャンペーン内容');
    }
    if (errorMessage === '') {
      dispatch(
        validationSuccess(
          PROJECT_DRAFT_CAMPAIGN_OVERVIEW_ERROR_GROUP,
          PROJECT_DRAFT_CAMPAIGN_OVERVIEW_ERROR_KEYS.DESCRIPTION
        )
      );
    } else {
      dispatch(
        validationFailure(PROJECT_DRAFT_CAMPAIGN_OVERVIEW_ERROR_GROUP, {
          [PROJECT_DRAFT_CAMPAIGN_OVERVIEW_ERROR_KEYS.DESCRIPTION]: errorMessage
        })
      );
    }
  };

const validateHostCompany =
  (hostCompany: HostCompany): AppThunkAction<void> =>
  dispatch => {
    if (isHostCompanyOther(hostCompany)) {
      dispatch(validateHostCompanyName(hostCompany.name));
    }
  };

const validateHostCompanyName =
  (hostCompanyName: HostCompany['name']): AppThunkAction<void> =>
  dispatch => {
    let errorMessage: string = '';

    if (hostCompanyName === null || validator.isEmpty(hostCompanyName)) {
      errorMessage = createErrorMessageForEmpty('企業名');
    }

    if (errorMessage === '') {
      dispatch(
        validationSuccess(
          PROJECT_DRAFT_CAMPAIGN_OVERVIEW_ERROR_GROUP,
          PROJECT_DRAFT_CAMPAIGN_OVERVIEW_ERROR_KEYS.HOST_COMPANY
        )
      );
    } else {
      dispatch(
        validationFailure(PROJECT_DRAFT_CAMPAIGN_OVERVIEW_ERROR_GROUP, {
          [PROJECT_DRAFT_CAMPAIGN_OVERVIEW_ERROR_KEYS.HOST_COMPANY]:
            errorMessage
        })
      );
    }
  };

const validateStartDateOfAd =
  (startDateOfAd: moment.Moment | string | null): AppThunkAction<void> =>
  dispatch => {
    let errorMessage: string = '';

    errorMessage = validateDate(
      startDateOfAd,
      JP_START_DATE_OF_AD,
      validateStartDate
    );

    if (errorMessage === '') {
      dispatch(
        validationSuccess(
          PROJECT_DRAFT_CAMPAIGN_OVERVIEW_ERROR_GROUP,
          PROJECT_DRAFT_CAMPAIGN_OVERVIEW_ERROR_KEYS.START_DATE_OF_AD
        )
      );
    } else {
      dispatch(
        validationFailure(PROJECT_DRAFT_CAMPAIGN_OVERVIEW_ERROR_GROUP, {
          [PROJECT_DRAFT_CAMPAIGN_OVERVIEW_ERROR_KEYS.START_DATE_OF_AD]:
            errorMessage
        })
      );
    }
  };

const validateEndDateOfAd =
  (endDateOfAd: moment.Moment | string | null): AppThunkAction<void> =>
  (dispatch, getState) => {
    let errorMessage: string = '';

    errorMessage = validateDate(
      endDateOfAd,
      JP_END_DATE_OF_AD,
      validateEndDate
    );

    if (errorMessage === '') {
      dispatch(
        validationSuccess(
          PROJECT_DRAFT_CAMPAIGN_OVERVIEW_ERROR_GROUP,
          PROJECT_DRAFT_CAMPAIGN_OVERVIEW_ERROR_KEYS.END_DATE_OF_AD
        )
      );
    } else {
      dispatch(
        validationFailure(PROJECT_DRAFT_CAMPAIGN_OVERVIEW_ERROR_GROUP, {
          [PROJECT_DRAFT_CAMPAIGN_OVERVIEW_ERROR_KEYS.END_DATE_OF_AD]:
            errorMessage
        })
      );
    }
  };

const validateDateConsistencyOfAd =
  (): AppThunkAction<void> => (dispatch, getState) => {
    let errorMessage: string = '';
    const { startDateOfAd, endDateOfAd } =
      getState().selectedProjectDraft.projectJson.advertisingPeriod;

    errorMessage = validateDateConsistency(
      startDateOfAd,
      endDateOfAd,
      JP_START_DATE_OF_AD,
      JP_END_DATE_OF_AD
    );

    if (errorMessage === '') {
      dispatch(
        validationSuccess(
          PROJECT_DRAFT_CAMPAIGN_OVERVIEW_ERROR_GROUP,
          PROJECT_DRAFT_CAMPAIGN_OVERVIEW_ERROR_KEYS.DATE_CONSISTENCY_OF_AD
        )
      );
    } else {
      dispatch(
        validationFailure(PROJECT_DRAFT_CAMPAIGN_OVERVIEW_ERROR_GROUP, {
          [PROJECT_DRAFT_CAMPAIGN_OVERVIEW_ERROR_KEYS.DATE_CONSISTENCY_OF_AD]:
            errorMessage
        })
      );
    }
  };

const validateDateDurationOfAd =
  (): AppThunkAction<void> => (dispatch, getState) => {
    let errorMessage: string = '';
    const { startDateOfAd, endDateOfAd } =
      getState().selectedProjectDraft.projectJson.advertisingPeriod;

    errorMessage = validateDateDuration(
      startDateOfAd,
      endDateOfAd,
      JP_START_DATE_OF_AD,
      JP_END_DATE_OF_AD
    );

    if (errorMessage === '') {
      dispatch(
        validationSuccess(
          PROJECT_DRAFT_CAMPAIGN_OVERVIEW_ERROR_GROUP,
          PROJECT_DRAFT_CAMPAIGN_OVERVIEW_ERROR_KEYS.DATE_DURATION_OF_AD
        )
      );
    } else {
      dispatch(
        validationFailure(PROJECT_DRAFT_CAMPAIGN_OVERVIEW_ERROR_GROUP, {
          [PROJECT_DRAFT_CAMPAIGN_OVERVIEW_ERROR_KEYS.DATE_DURATION_OF_AD]:
            errorMessage
        })
      );
    }
  };

const validateStartDateOfDistribution =
  (
    startDateOfDistribution: moment.Moment | string | null
  ): AppThunkAction<void> =>
  dispatch => {
    let errorMessage: string = '';

    errorMessage = validateDate(
      startDateOfDistribution,
      JP_START_DATE_OF_DISTRIBUTION,
      validateStartDate
    );

    if (errorMessage === '') {
      dispatch(
        validationSuccess(
          PROJECT_DRAFT_CAMPAIGN_OVERVIEW_ERROR_GROUP,
          PROJECT_DRAFT_CAMPAIGN_OVERVIEW_ERROR_KEYS.START_DATE_OF_DISTRIBUTION
        )
      );
    } else {
      dispatch(
        validationFailure(PROJECT_DRAFT_CAMPAIGN_OVERVIEW_ERROR_GROUP, {
          [PROJECT_DRAFT_CAMPAIGN_OVERVIEW_ERROR_KEYS.START_DATE_OF_DISTRIBUTION]:
            errorMessage
        })
      );
    }
  };

const validateEndDateOfDistribution =
  (
    endDateOfDistribution: moment.Moment | string | null
  ): AppThunkAction<void> =>
  (dispatch, getState) => {
    let errorMessage: string = '';

    errorMessage = validateDate(
      endDateOfDistribution,
      JP_END_DATE_OF_DISTRIBUTION,
      validateEndDate
    );

    if (errorMessage === '') {
      dispatch(
        validationSuccess(
          PROJECT_DRAFT_CAMPAIGN_OVERVIEW_ERROR_GROUP,
          PROJECT_DRAFT_CAMPAIGN_OVERVIEW_ERROR_KEYS.END_DATE_OF_DISTRIBUTION
        )
      );
    } else {
      dispatch(
        validationFailure(PROJECT_DRAFT_CAMPAIGN_OVERVIEW_ERROR_GROUP, {
          [PROJECT_DRAFT_CAMPAIGN_OVERVIEW_ERROR_KEYS.END_DATE_OF_DISTRIBUTION]:
            errorMessage
        })
      );
    }
  };

const validateDateConsistencyOfDistiribution =
  (): AppThunkAction<void> => (dispatch, getState) => {
    let errorMessage: string = '';
    const { startDateOfDistribution, endDateOfDistribution } =
      getState().selectedProjectDraft.projectJson.giftDistributionPeriod;

    errorMessage = validateDateConsistency(
      startDateOfDistribution,
      endDateOfDistribution,
      JP_START_DATE_OF_DISTRIBUTION,
      JP_END_DATE_OF_DISTRIBUTION
    );

    if (errorMessage === '') {
      dispatch(
        validationSuccess(
          PROJECT_DRAFT_CAMPAIGN_OVERVIEW_ERROR_GROUP,
          PROJECT_DRAFT_CAMPAIGN_OVERVIEW_ERROR_KEYS.DATE_CONSISTENCY_OF_DISTRIBUTION
        )
      );
    } else {
      dispatch(
        validationFailure(PROJECT_DRAFT_CAMPAIGN_OVERVIEW_ERROR_GROUP, {
          [PROJECT_DRAFT_CAMPAIGN_OVERVIEW_ERROR_KEYS.DATE_CONSISTENCY_OF_DISTRIBUTION]:
            errorMessage
        })
      );
    }
  };

const validateDateDurationOfDistiribution =
  (): AppThunkAction<void> => (dispatch, getState) => {
    let errorMessage: string = '';
    const { startDateOfDistribution, endDateOfDistribution } =
      getState().selectedProjectDraft.projectJson.giftDistributionPeriod;

    errorMessage = validateDateDuration(
      startDateOfDistribution,
      endDateOfDistribution,
      JP_START_DATE_OF_DISTRIBUTION,
      JP_END_DATE_OF_DISTRIBUTION
    );

    if (errorMessage === '') {
      dispatch(
        validationSuccess(
          PROJECT_DRAFT_CAMPAIGN_OVERVIEW_ERROR_GROUP,
          PROJECT_DRAFT_CAMPAIGN_OVERVIEW_ERROR_KEYS.DATE_DURATION_OF_DISTRIBUTION
        )
      );
    } else {
      dispatch(
        validationFailure(PROJECT_DRAFT_CAMPAIGN_OVERVIEW_ERROR_GROUP, {
          [PROJECT_DRAFT_CAMPAIGN_OVERVIEW_ERROR_KEYS.DATE_DURATION_OF_DISTRIBUTION]:
            errorMessage
        })
      );
    }
  };

const validateDate = (
  date: moment.Moment | string | null,
  key: string,
  validateFunction: (date: string | null, key: string) => string
): string => {
  if (moment.isMoment(date)) {
    return validateFunction(date.toISOString(), key);
  }
  return validateFunction(date, key);
};

const validateStartDate = (date: string | null, key: string): string => {
  const oneYearLater = new Date();
  oneYearLater.setFullYear(new Date().getFullYear() + 1);

  if (!date) {
    return createErrorMessageForEmpty(key);
  }
  if (!validator.isAfter(date)) {
    return createErrorMessageForPastDate(key);
  }
  if (!validator.isBefore(date, oneYearLater.toString())) {
    return createErrorMessageForProjectDraftFutureDate(key, '1年');
  }
  return '';
};

const validateEndDate = (date: string | null, key: string): string => {
  if (!date) {
    return createErrorMessageForEmpty(key);
  }
  if (!validator.isAfter(date)) {
    return createErrorMessageForPastDate(key);
  }
  return '';
};

const validateDateConsistency = (
  startDate: string | null,
  endDate: string | null,
  startKey: string,
  endKey: string
): string => {
  if (startDate && endDate) {
    if (moment(endDate).isBefore(startDate, 'date')) {
      return createErrorMessageForDateConsistency(startKey, endKey);
    }
  }
  return '';
};

const validateDateDuration = (
  startDate: string | null,
  endDate: string | null,
  startKey: string,
  endKey: string
): string => {
  if (startDate && endDate) {
    const startDateOneYearLater = moment(startDate)
      .add(1, 'y')
      .endOf('day')
      .toISOString();
    if (!validator.isBefore(endDate, startDateOneYearLater)) {
      return createErrorMessageForDateDuration(startKey, endKey, '1年以内');
    }
  }
  return '';
};

const validateCampaignSolutionSystem =
  (campaignSolutionSystem: string): AppThunkAction<void> =>
  dispatch => {
    let errorMessage: string = '';

    if (validator.isEmpty(campaignSolutionSystem)) {
      errorMessage = createErrorMessageForUnselected('利用サービス');
    }
    if (errorMessage === '') {
      dispatch(
        validationSuccess(
          PROJECT_DRAFT_CAMPAIGN_OVERVIEW_ERROR_GROUP,
          PROJECT_DRAFT_CAMPAIGN_OVERVIEW_ERROR_KEYS.CAMPAIGN_SOLUTION
        )
      );
    } else {
      dispatch(
        validationFailure(PROJECT_DRAFT_CAMPAIGN_OVERVIEW_ERROR_GROUP, {
          [PROJECT_DRAFT_CAMPAIGN_OVERVIEW_ERROR_KEYS.CAMPAIGN_SOLUTION]:
            errorMessage
        })
      );
    }
  };

const validateCampaignMarketingIndustry =
  (campaignMarketingIndustry: string): AppThunkAction<void> =>
  dispatch => {
    let errorMessage: string = '';

    if (validator.isEmpty(campaignMarketingIndustry)) {
      errorMessage = createErrorMessageForUnselected('実施業界');
    }
    if (errorMessage === '') {
      dispatch(
        validationSuccess(
          PROJECT_DRAFT_CAMPAIGN_OVERVIEW_ERROR_GROUP,
          PROJECT_DRAFT_CAMPAIGN_OVERVIEW_ERROR_KEYS.CAMPAIGN_MARKETING_INDUSTRY
        )
      );
    } else {
      dispatch(
        validationFailure(PROJECT_DRAFT_CAMPAIGN_OVERVIEW_ERROR_GROUP, {
          [PROJECT_DRAFT_CAMPAIGN_OVERVIEW_ERROR_KEYS.CAMPAIGN_MARKETING_INDUSTRY]:
            errorMessage
        })
      );
    }
  };

export const projectDraftCampaignOverviewHasValidationErrors = (
  errors: Errors
): boolean => {
  const campaignOverviewErrors = errors.projectDraftCampaignOverview;
  return Object.keys(campaignOverviewErrors).length > 0;
};

export const projectDraftCampaignOverviewValidators = {
  validateCampaignName,
  validateCampaignDescription,
  validateHostCompanyName,
  validateStartDateOfAd,
  validateEndDateOfAd,
  validateDateConsistencyOfAd,
  validateStartDateOfDistribution,
  validateDateConsistencyOfDistiribution,
  validateEndDateOfDistribution,
  validateCampaignSolutionSystem,
  validateCampaignMarketingIndustry,
  validateCampaignOverview
};
