import { CircularProgress, Fade } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import type { Theme } from '@mui/material';
import type { WithStyles } from '@mui/styles';

const styles = (theme: Theme) =>
  createStyles({
    loaderContainer: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      height: '100%',
      width: '100%'
    },
    progress: {
      margin: theme.spacing(2),
      width: 60
    }
  });

type ComponentLoaderOwnProps = {
  isLoading: boolean;
};

export type ComponentLoaderProps = ComponentLoaderOwnProps &
  WithStyles<typeof styles>;

const ComponentLoader: React.FC<ComponentLoaderProps> = ({
  isLoading,
  classes
}) => (
  <Fade in={isLoading} timeout={{ exit: 500 }}>
    <div className={classes.loaderContainer}>
      <CircularProgress
        className={classes.progress}
        color="primary"
        data-cy="componentLoader"
      ></CircularProgress>
    </div>
  </Fade>
);

export default withStyles(styles)(ComponentLoader);
