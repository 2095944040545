import DownloadIcon from '@mui/icons-material/CloudDownload';
import { Box, Grid, Typography, IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import { useState } from 'react';
import FileIcon from './FileIcon';
import type {
  UploadedFilePropsMappedFromState,
  UploadedFilePropsMappedFromDispatch
} from '../../containers/project/messageThread/UploadedFileContainer';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => ({
  fileBox: {
    marginTop: theme.spacing(1),
    cursor: 'pointer'
  },
  fileGrid: {
    padding: theme.spacing(1)
  },
  fileDetail: {
    paddingLeft: theme.spacing(1)
  },
  fileName: {
    lineHeight: '1.2'
  },
  downloadIconGrid: {
    textAlign: 'right',
    paddingRight: theme.spacing(0.5),
    visibility: 'hidden'
  },
  downloadIconVisible: {
    visibility: 'visible'
  },
  downloadIcon: {
    color: theme.palette.icon.file
  }
});

type UploadedFileProps = UploadedFilePropsMappedFromState &
  UploadedFilePropsMappedFromDispatch &
  WithStyles<typeof styles>;

const UploadedFileComponent: React.FC<
  React.PropsWithChildren<UploadedFileProps>
> = ({
  uploadedFile,
  downloadUploadedFile,
  openUploadedFileInAnotherTab,
  children,
  classes
}) => {
  const [isMouseOver, setIsMouseOver] = useState(false);
  return (
    <Box
      border={1}
      borderColor="grey.500"
      borderRadius={1}
      className={classes.fileBox}
      onMouseOver={() => setIsMouseOver(true)}
      onMouseOut={() => setIsMouseOver(false)}
      onClick={() => openUploadedFileInAnotherTab(uploadedFile.urlCode)}
    >
      <Grid container className={classes.fileGrid} alignItems="center">
        <Grid item xs={1}>
          <FileIcon filename={uploadedFile.name} />
        </Grid>
        <Grid item xs={10} className={classes.fileDetail}>
          <Typography
            variant="caption"
            className={classes.fileName}
            component="p"
          >
            {uploadedFile.name}
          </Typography>
          {children}
        </Grid>
        <Grid
          item
          xs={1}
          className={clsx(classes.downloadIconGrid, {
            [classes.downloadIconVisible]: isMouseOver
          })}
        >
          <Tooltip title="ダウンロード" aria-label="add" placement="top">
            <IconButton
              className={classes.downloadIcon}
              onClick={event => {
                event.stopPropagation();
                downloadUploadedFile(uploadedFile.urlCode);
              }}
              size="large"
            >
              <DownloadIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </Box>
  );
};

export default withStyles(styles)(UploadedFileComponent);
