import { AppActionTypes } from '../actions/actionType';
import type { AppAction } from '../actions/actionType';

const isSettingOpen = (state = false, action: AppAction): boolean => {
  switch (action.type) {
    case AppActionTypes.TOGGLE_SETTING_MENU:
      return action.isSettingOpen;
    case AppActionTypes.CLOSE_DRAWER:
      return true;
    default:
      return state;
  }
};

export default isSettingOpen;
