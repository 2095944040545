import APIIPAddressSettingMessage from '@console/containers/project/APIIPAddressSettingMessage';
import APITokenContainer from '@console/containers/project/APITokenContainer';
import ApiTableContainer from '@console/containers/project/giftConfig/ApiTableContainer';
import { Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => ({
  sections: {
    paddingBottom: theme.spacing(5)
  },
  section: {
    paddingTop: theme.spacing(4)
  }
});

type APIIssueDetailProps = WithStyles<typeof styles>;

const APIIssueDetail: React.FC<APIIssueDetailProps> = ({ classes }) => (
  <div className={classes.sections}>
    <APIIPAddressSettingMessage />
    <section className={classes.section}>
      <Typography variant="h6" color="textPrimary">
        APIトークン
      </Typography>
    </section>
    <APITokenContainer />
    <section className={classes.section}>
      <Typography variant="h6" color="textPrimary">
        発行ギフト
      </Typography>
    </section>
    <ApiTableContainer />
  </div>
);

export default withStyles(styles)(APIIssueDetail);
