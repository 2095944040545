import { gql } from '@apollo/client';

import { internalApiAppClient } from './apolloClient';
import { FRAGMENT_GIFTEE_BOX_TEMPLATE } from './fragments/gifteeBoxTemplate';
import { operationNames } from './operationNames';
import type { FetchResult } from '@apollo/client';

const getGifteeBoxTemplatesGql = gql`
${FRAGMENT_GIFTEE_BOX_TEMPLATE}
  query ${operationNames.GET_GIFTEE_BOX_TEMPLATES}{
    gifteeBoxTemplates {
      ...GifteeBoxTemplateFields
    }
  }
`;

export type GifteeBoxTemplate = {
  name: string;
  imageUrl: string;
  urlCode: string;
  isLimitedPublished: boolean;
  exchangeableBeginLabel: string;
  exchangeableEndLabel: string;
  initialPointCandidates: GifteeBoxTemplateInitialPointCandidate[];
  supportedPoint: SupportedPoint;
};

export type GifteeBoxTemplateInitialPointCandidate = {
  initialPoint: number;
};

export type SupportedPoint = {
  maxPoint: number;
  minPoint: number;
  step: number;
};

export type GifteeBoxTemplates = GifteeBoxTemplate[];

export type GetGifteeBoxTemplatesResponse = FetchResult<{
  gifteeBoxTemplates: GifteeBoxTemplates;
}>;

export const getGifteeBoxTemplates =
  (): Promise<GetGifteeBoxTemplatesResponse> =>
    internalApiAppClient.query({
      query: getGifteeBoxTemplatesGql
    });
