import { AppActionTypes } from '../actions/actionType';
import type { AppAction } from '../actions/actionType';
import type { GifteeBoxTemplate } from '@common/api/graphql/getGifteeBoxTemplatesGql';

export type LocalGifteeBoxTemplate = GifteeBoxTemplate;
export type LocalGifteeBoxTemplates = GifteeBoxTemplate[];

export const gifteeBoxTemplates = (
  state: LocalGifteeBoxTemplates = [],
  action: AppAction
): LocalGifteeBoxTemplates => {
  switch (action.type) {
    case AppActionTypes.GET_GIFTEE_BOX_TEMPLATES_SUCCESS:
      return action.gifteeBoxTemplates;
    default:
      return state;
  }
};
