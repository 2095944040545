import { AppActionTypes } from '../../actions/actionType';
import type { AppAction } from '../../actions/actionType';

export type CampaignGiftDistributionChannelDescription = {
  urlCode: string;
  description: string;
};

export type CampaignGiftDistributionChannelDescriptions =
  CampaignGiftDistributionChannelDescription[];

export const initialCampaignGiftDistributionChannelDescriptions: CampaignGiftDistributionChannelDescriptions =
  [];

export const campaignGiftDistributionChannelDescriptions = (
  state = initialCampaignGiftDistributionChannelDescriptions,
  action: AppAction
): CampaignGiftDistributionChannelDescriptions => {
  switch (action.type) {
    case AppActionTypes.ADD_PROJECT_DRAFT:
    case AppActionTypes.COPY_PROJECT_TO_PROJECT_DRAFT_FAILURE:
      return initialCampaignGiftDistributionChannelDescriptions;
    case AppActionTypes.SELECT_PROJECT_DRAFT:
      return (
        action.projectDraft.projectJson
          .campaignGiftDistributionChannelDescriptions ||
        initialCampaignGiftDistributionChannelDescriptions
      );
    case AppActionTypes.UPDATE_GIFT_DISTRIBUTION_CHANNEL_DESCRIPTION:
      const descriptions = state.filter(giftDistributionChannel => {
        giftDistributionChannel.urlCode !== action.urlCode;
      });

      return [
        ...descriptions,
        { urlCode: action.urlCode, description: action.description }
      ];
    case AppActionTypes.COPY_PROJECT_TO_PROJECT_DRAFT:
      return action.project.projectGiftDistributionChannels.map(
        projectGiftDistributionChannel => ({
          urlCode: projectGiftDistributionChannel.urlCode,
          description: projectGiftDistributionChannel.description
        })
      );
    default:
      return state;
  }
};

export default campaignGiftDistributionChannelDescriptions;
