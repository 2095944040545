import { createSelector } from 'reselect';
import { getSelectedProjectDraftSelectedGiftConfig } from '../getSelectedProjectDraftForDisplay';
import type { InitialPointCandidate } from '../../reducers/projectJson/giftConfigs';
import type { GifteeBoxTemplate } from '@common/api/graphql/getGifteeBoxTemplatesGql';
import type { AppState } from '@console/reducers';

const getGifteeBoxTemplates = (state: AppState) => state.gifteeBoxTemplates;

export const getSelectedGifteeBoxTemplate = createSelector(
  [getSelectedProjectDraftSelectedGiftConfig, getGifteeBoxTemplates],
  (giftConfig, gifteeBoxTemplates): GifteeBoxTemplate | null => {
    if (!giftConfig?.selectedGifteeBoxTemplate || !gifteeBoxTemplates)
      return null;
    return (
      gifteeBoxTemplates.find(
        localGifteeBoxTemplate =>
          giftConfig.selectedGifteeBoxTemplate!.urlCode ===
          localGifteeBoxTemplate.urlCode
      ) ?? null
    );
  }
);

export const getGifteeBoxInitialPointCandidates = createSelector(
  [getSelectedGifteeBoxTemplate],
  (selectedGifteeBoxTemplate): InitialPointCandidate['initialPoint'][] =>
    selectedGifteeBoxTemplate
      ? selectedGifteeBoxTemplate.initialPointCandidates.map(
          initialPointCandidate => initialPointCandidate.initialPoint
        )
      : []
);
