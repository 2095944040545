import { getLocalString } from '@common/lib/getLocalString';
import { getLocalePrice } from '@common/lib/getLocalePrice';
import {
  GIFTEE_BOX_DISPLAY_NAME,
  GIFTEE_BOX_TEMPLATE_BRAND_NAME
} from '@console/common/constants';
import ContentBrief from '@console/components/common/ContentBrief';
import { faFileCsv } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { useNavigate } from 'react-router-dom';
import type {
  BulkTablePropsMappedFromDispatch,
  BulkTablePropsMappedFromState
} from '@console/containers/project/selectionReport/BulkTableContainer';
import type { Theme } from '@mui/material';

import type { StyleRules, WithStyles } from '@mui/styles';

const styles = (theme: Theme): StyleRules => ({
  tableContainer: {
    overflowX: 'scroll',
    whiteSpace: 'nowrap'
  },
  tableHeadCell: {
    textAlign: 'left'
  },
  contentTableCell: {
    width: '40%',
    minWidth: 400
  },
  tableCell: {
    width: '15%',
    minWidth: 100
  },
  nextButtonTableCell: {
    width: '15%',
    minWidth: 200,
    textAlign: 'center'
  },
  empty: {
    paddingTop: theme.spacing(4),
    textAlign: 'center'
  },
  emptyGifteeBoxSelectionReport: {
    margin: theme.spacing(5),
    color: theme.palette.icon.file
  }
});

type BulkTableProps = BulkTablePropsMappedFromState &
  BulkTablePropsMappedFromDispatch &
  WithStyles<typeof styles>;

const BulkTable: React.FC<BulkTableProps> = ({
  gifteeBoxConfigs,
  onClickGifteeBoxConfig,
  classes
}) => {
  const navigate = useNavigate();

  return (
    <>
      {!gifteeBoxConfigs || gifteeBoxConfigs.length !== 0 ? (
        <div className={classes.tableContainer}>
          <Table>
            <TableHead data-cy="tableHeader">
              <TableRow>
                <TableCell>
                  <Typography>ギフト名</Typography>
                </TableCell>
                <TableCell className={classes.tableHeadCell}>
                  <Typography>単価</Typography>
                </TableCell>
                <TableCell className={classes.tableHeadCell}>
                  <Typography>確定数量</Typography>
                </TableCell>
                <TableCell className={classes.tableHeadCell}>
                  <Typography>納品希望日</Typography>
                </TableCell>
                <TableCell className={classes.tableHeadCell} />
              </TableRow>
            </TableHead>
            <TableBody>
              {!!gifteeBoxConfigs &&
                gifteeBoxConfigs.map((gifteeBoxConfig, gifteeBoxConfigIndex) =>
                  gifteeBoxConfig.bulkGifteeBoxIssueConfigs.map(
                    (
                      bulkGifteeBoxIssueConfig,
                      bulkGifteeBoxIssueConfigIndex
                    ) => (
                      <TableRow
                        key={`${gifteeBoxConfigIndex}-${bulkGifteeBoxIssueConfigIndex}`}
                        data-cy={`gifteeBoxConfig-tableRow-${gifteeBoxConfig.urlCode}`}
                      >
                        {bulkGifteeBoxIssueConfigIndex === 0 && (
                          <TableCell
                            rowSpan={
                              gifteeBoxConfig.bulkGifteeBoxIssueConfigs.length
                            }
                            className={classes.contentTableCell}
                            data-cy={`gifteeBoxConfig-tableCell-contentBrief-${gifteeBoxConfig.urlCode}`}
                          >
                            <ContentBrief
                              contentName={
                                gifteeBoxConfig.gifteeBoxTemplate.name
                              }
                              contentImageUrl={
                                gifteeBoxConfig.gifteeBoxTemplate.imageUrl
                              }
                              contentBrandName={GIFTEE_BOX_TEMPLATE_BRAND_NAME}
                            />
                          </TableCell>
                        )}
                        <TableCell
                          className={classes.tableCell}
                          data-cy={`gifteeBoxConfig-tableCell-price-${gifteeBoxConfig.urlCode}`}
                        >
                          <Typography className={classes.tableCellBody}>
                            {getLocalePrice(
                              bulkGifteeBoxIssueConfig.initialPoint
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell
                          className={classes.tableCell}
                          data-cy={`gifteeBoxConfig-tableCell-fixedIssueConfig-${gifteeBoxConfig.urlCode}`}
                        >
                          {!!bulkGifteeBoxIssueConfig.fixedIssueConfig
                            ?.issueAmount ? (
                            <Typography className={classes.tableCellBody}>
                              {getLocalString(
                                bulkGifteeBoxIssueConfig.fixedIssueConfig
                                  ?.issueAmount
                              )}
                            </Typography>
                          ) : (
                            <Typography className={classes.tableCellBody}>
                              未確定
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell
                          className={classes.tableCell}
                          data-cy={`gifteeBoxConfig-tableCell-deliveryDate-${gifteeBoxConfig.urlCode}`}
                        >
                          <Typography className={classes.tableCellBody}>
                            {bulkGifteeBoxIssueConfig.deliveryDate}
                          </Typography>
                        </TableCell>
                        {bulkGifteeBoxIssueConfigIndex === 0 && (
                          <TableCell
                            rowSpan={
                              gifteeBoxConfig.bulkGifteeBoxIssueConfigs.length
                            }
                            className={classes.nextButtonTableCell}
                          >
                            <Button
                              color="primary"
                              variant="contained"
                              data-cy={`gifteeBoxConfig-tableCell-nextPageButton-${gifteeBoxConfig.urlCode}`}
                              onClick={() =>
                                onClickGifteeBoxConfig(
                                  navigate,
                                  gifteeBoxConfig.urlCode
                                )
                              }
                            >
                              選択実績を見る
                              <KeyboardArrowRightIcon />
                            </Button>
                          </TableCell>
                        )}
                      </TableRow>
                    )
                  )
                )}
            </TableBody>
          </Table>
        </div>
      ) : (
        <div className={classes.empty}>
          <FontAwesomeIcon
            icon={faFileCsv}
            size="3x"
            className={classes.emptyGifteeBoxSelectionReport}
            data-cy="dataEmptyIcon"
          />
          <Typography
            variant="subtitle1"
            color="textSecondary"
            data-cy="dataEmptyString"
          >{`${GIFTEE_BOX_DISPLAY_NAME}選択実績はありません`}</Typography>
        </div>
      )}
    </>
  );
};

export default withStyles(styles)(BulkTable);
