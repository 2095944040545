import { getContents } from '@common/api/graphql/getContentsGql';
import { getUniqueElements } from '@common/lib/getUniqueElements';
import handleApiErrorIfNeeded from '@console/actions/thunks/handleApiErrorIfNeeded';
import {
  getProjectDraftsContentsStart,
  getProjectDraftsContentsSuccess,
  getProjectDraftsContentsFailure
} from '../actions';
import type { AppThunkAction } from '../thunkType';
import type { ApiError } from '@common/api/graphql/apolloClient';
import type { GetContentsResponse } from '@common/api/graphql/getContentsGql';
import type { ProjectDrafts } from '@console/reducers/projectDrafts';
import type { GiftConfigs } from '@console/reducers/projectJson/giftConfigs';

export const getProjectDraftsContentsSuccessThunk =
  (response: GetContentsResponse): AppThunkAction<Promise<void>> =>
  dispatch => {
    const { contents } = response.data!;
    dispatch(getProjectDraftsContentsSuccess(contents));
    return Promise.resolve();
  };

export const getProjectDraftsContentsFailureThunk =
  (error: ApiError): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(getProjectDraftsContentsFailure());
    return dispatch(handleApiErrorIfNeeded(error));
  };

export const getProjectDraftsContentsThunk =
  (): AppThunkAction<Promise<void>> => (dispatch, getState) => {
    const { projectDrafts } = getState();
    const projectDraftUniqueContentUrlCodes =
      getProjectDraftUniqueContentUrlCodes(projectDrafts);

    dispatch(getProjectDraftsContentsStart());

    return getContents({
      conditions: {
        filter: {
          urlCodes: projectDraftUniqueContentUrlCodes
        }
      }
    })
      .then(response =>
        dispatch(getProjectDraftsContentsSuccessThunk(response))
      )
      .catch(error => dispatch(getProjectDraftsContentsFailureThunk(error)));
  };

export const getProjectDraftUniqueContentUrlCodes = (
  projectDrafts: ProjectDrafts
): UrlCode[] => {
  const projectDraftsContentUrlCodes =
    getProjectDraftsContentUrlCodes(projectDrafts);
  return getUniqueElements<UrlCode>(projectDraftsContentUrlCodes);
};

export const getProjectDraftsContentUrlCodes = (
  projectDrafts: ProjectDrafts
): UrlCode[] =>
  projectDrafts.flatMap(projectDraft => {
    const { giftConfigs } = projectDraft.projectJson;
    return getProjectDraftContentUrlCodes(giftConfigs);
  });

export const getProjectDraftContentUrlCodes = (
  giftConfigs: GiftConfigs
): UrlCode[] => giftConfigs.flatMap(giftConfig => giftConfig.selectedContents);
