import { isFeatureEnable, FeatureTypes } from '@common/lib/featureToggle';
import {
  API_DOC_URL,
  PRIVACY_POLICY_URL,
  TERMS_OF_URL,
  HELPSITE_URL
} from '@console/common/constants';
import { getG4bMyPageContentsUrl } from '@console/common/g4bMyPage';
import MoreVert from '@mui/icons-material/MoreVert';
import { Divider } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import { useLocation, Link } from 'react-router-dom';
import logo from '../../../../assets/images/logo.svg';
import type {
  NavbarPropsMappedFromState,
  NavbarPropsMappedFromDispatch
} from '../../containers/common/NavbarContainer';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const LOGO_HEIGHT = 40;

const styles = (theme: Theme): StyleRules =>
  createStyles({
    navBar: {
      display: 'flex',
      justifyContent: 'center',
      borderBottom: `solid thin ${theme.palette.divider}`
    },
    logo: {
      height: LOGO_HEIGHT,
      margin: LOGO_HEIGHT / 2,
      '&:hover': {
        cursor: 'pointer'
      }
    },
    identity: {
      height: 30
    },
    name: {
      margin: `0 0 0 ${theme.spacing(2)}`,
      color: 'black'
    },
    links: {
      height: 50
    },
    link: {
      textDecoration: 'none',
      color: 'black',
      marginRight: theme.spacing(4),
      height: 'inherit',
      '&:hover': {
        borderBottom: `2px solid ${theme.palette.primary.main}`
      }
    },
    selected: {
      borderBottom: `2px solid ${theme.palette.primary.main}`,
      '& p': {
        fontWeight: 'bold'
      }
    },
    linkName: {
      lineHeight: '50px'
    },
    menuButton: {
      marginRight: theme.spacing(2)
    },
    menu: {
      zIndex: theme.zIndex.appBar + 100,
      top: theme.spacing(5)
    },
    menuItems: {
      padding: `${theme.spacing()} ${theme.spacing(2)}`,
      paddingRight: theme.spacing(8),
      fontSize: 14
    },
    divider: {
      padding: 1,
      margin: theme.spacing()
    }
  });

type NavbarProps = NavbarPropsMappedFromState &
  NavbarPropsMappedFromDispatch &
  WithStyles<typeof styles>;

const Navbar: React.FC<NavbarProps> = ({
  classes,
  distributionPartner,
  distributionPartnerUser,
  menuAnchor,
  handleOpenSettingMenu,
  handleCloseSettingMenu,
  handleLogout
}) => {
  const location = useLocation();

  return (
    <AppBar position="fixed" className={classes.navBar}>
      <Toolbar>
        <Link to="/console/">
          <img src={logo} className={classes.logo} data-cy="logo" />
        </Link>
        <Grid container direction="column" justifyContent="flex-end">
          <Grid
            item
            container
            alignItems="center"
            justifyContent="flex-end"
            className={classes.identity}
          >
            <Typography className={classes.name} variant="caption">
              {distributionPartner.name}
            </Typography>
            <Typography className={classes.name} variant="caption">
              {distributionPartnerUser.name}
            </Typography>
          </Grid>
          <Grid
            item
            container
            alignItems="center"
            justifyContent="flex-end"
            className={classes.links}
          >
            <Link
              to="/console/"
              className={clsx(classes.link, {
                [classes.selected]:
                  location.pathname === '/console' ||
                  location.pathname === '/console/'
              })}
              data-cy="goDashboard"
            >
              <Typography variant="body2" className={classes.linkName}>
                案件一覧
              </Typography>
            </Link>
            <a
              href={getG4bMyPageContentsUrl()}
              target="_blank"
              className={classes.link}
              data-cy="goContents"
              rel="noreferrer"
            >
              <Typography variant="body2" className={classes.linkName}>
                ギフト一覧
              </Typography>
            </a>
            <Link
              to={`/console/distribution_partners/${distributionPartner.urlCode}`}
              className={classes.link}
              data-cy="goAccount"
            >
              <Typography variant="body2" className={classes.linkName}>
                アカウント
              </Typography>
            </Link>
            <a
              href={HELPSITE_URL}
              target="_blank"
              className={classes.link}
              data-cy="goHelpSite"
              rel="noreferrer"
            >
              <Typography variant="body2" className={classes.linkName}>
                よくある質問
              </Typography>
            </a>
            {/**
            <Link
              to="/console/messages"
              className={clsx(classes.link, {
                [classes.selected]: location.pathname === '/console/messages'
              })}
            >
              <Typography variant="body2" className={classes.linkName}>
                メッセージ
              </Typography>
            </Link>
            <Link
              to="#"
              className={clsx(classes.link, {
                [classes.selected]: location.pathname === '#'
              })}
            >
              <Typography variant="body2" className={classes.linkName}>
                ヘルプ
              </Typography>
            </Link>
            */}
            <IconButton
              aria-owns={menuAnchor ? 'setting-menu' : undefined}
              aria-haspopup="true"
              onClick={handleOpenSettingMenu}
              data-cy="openSettingMenu"
              size="large"
            >
              <MoreVert color="primary" />
            </IconButton>
            <Menu
              id="setting-menu"
              anchorEl={menuAnchor}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={!!menuAnchor}
              onClose={handleCloseSettingMenu}
              className={classes.menu}
            >
              {/**
              <MenuItem className={classes.menuItems}>アカウント設定</MenuItem>
              <MenuItem className={classes.menuItems}>通知</MenuItem>
              <MenuItem className={classes.menuItems}>ご請求</MenuItem>
              <MenuItem className={classes.menuItems}>納品</MenuItem>
              <Divider className={classes.divider} />
              <MenuItem className={classes.menuItems}>FAQ</MenuItem>
              <Divider className={classes.divider} />
              */}
              <MenuItem
                className={classes.menuItems}
                component="a"
                target="_blank"
                href={API_DOC_URL}
              >
                API仕様書
              </MenuItem>
              <Divider className={classes.divider} />
              <MenuItem
                className={classes.menuItems}
                component="a"
                target="_blank"
                href={TERMS_OF_URL}
              >
                利用規約
              </MenuItem>
              <MenuItem
                className={classes.menuItems}
                component="a"
                target="_blank"
                href={PRIVACY_POLICY_URL}
              >
                プライバシーポリシー
              </MenuItem>
              <Divider className={classes.divider} />
              <MenuItem className={classes.menuItems} onClick={handleLogout}>
                ログアウト
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default withStyles(styles)(Navbar);
