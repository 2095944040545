import { isApolloError } from '../../../../common/api/graphql/isApolloError';
import { isMutationErrors } from '../../../../common/api/graphql/isMutationErrors';
import { isReadonlyArrayGraphQLError } from '../../../../common/api/graphql/isReadonlyArrayGraphqlErrors';
import { handleApolloError } from './handleApolloError';
import { handleGraphqlErrors } from './handleGraphqlErrorsIfNeeded';
import { handleMutationErrors } from './handleMutationErrorsIfNeeded';
import type { ApiError } from '../../../../common/api/graphql/apolloClient';
import type { AppThunkAction } from '../thunkType';

const handleApiErrorIfNeeded =
  (error: ApiError): AppThunkAction<Promise<void>> =>
  dispatch => {
    if (isApolloError(error)) {
      return dispatch(handleApolloError(error));
    } else if (isMutationErrors(error)) {
      return dispatch(handleMutationErrors(error));
    } else if (isReadonlyArrayGraphQLError(error)) {
      return dispatch(handleGraphqlErrors(error));
    }
    return Promise.reject(JSON.stringify(error));
  };

export default handleApiErrorIfNeeded;
