import { getGifteeBoxTemplateContentSelections } from '@common/api/graphql/getGifteeBoxTemplateContentSelectionsGql';
import {
  getGifteeBoxTemplateContinuousContentSelectionsStart,
  getGifteeBoxTemplateContinuousContentSelectionsSuccess,
  getGifteeBoxTemplateContinuousContentSelectionsFailure
} from '../actions';

import handleApiErrorIfNeeded from './handleApiErrorIfNeeded';
import type { AppThunkAction } from '../thunkType';
import type { ApiError } from '@common/api/graphql/apolloClient';
import type {
  GetGifteeBoxTemplateContentSelectionsResponse,
  GetGifteeBoxTemplateContentSelectionsSearchInfo
} from '@common/api/graphql/getGifteeBoxTemplateContentSelectionsGql';

export const getGifteeBoxTemplateContinuousContentSelectionsSuccessThunk =
  (
    response: GetGifteeBoxTemplateContentSelectionsResponse
  ): AppThunkAction<Promise<void>> =>
  dispatch => {
    const { contentSelections } = response.data!.gifteeBoxTemplate;
    dispatch(
      getGifteeBoxTemplateContinuousContentSelectionsSuccess(contentSelections)
    );
    return Promise.resolve();
  };

export const getGifteeBoxTemplateContinuousContentSelectionsFailureThunk =
  (error: ApiError): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(getGifteeBoxTemplateContinuousContentSelectionsFailure());
    return dispatch(handleApiErrorIfNeeded(error));
  };

export const getGifteeBoxTemplateContinuousContentSelectionsThunk =
  (
    gifteeBoxTemplateUrlCode: UrlCode,
    searchInfo: GetGifteeBoxTemplateContentSelectionsSearchInfo
  ): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(getGifteeBoxTemplateContinuousContentSelectionsStart());

    return getGifteeBoxTemplateContentSelections(
      gifteeBoxTemplateUrlCode,
      searchInfo
    )
      .then((response: GetGifteeBoxTemplateContentSelectionsResponse) =>
        dispatch(
          getGifteeBoxTemplateContinuousContentSelectionsSuccessThunk(response)
        )
      )
      .catch(error =>
        dispatch(
          getGifteeBoxTemplateContinuousContentSelectionsFailureThunk(error)
        )
      );
  };
