import BillingEmpty from '@console/components/project/billing/Empty';
import BillingLabel from '@console/components/project/billing/Label';
import BillingTableContainer from '@console/containers/project/billing/TableContainer';
import withStyles from '@mui/styles/withStyles';
import type {
  BillingPropsMappedFromState,
  BillingPropsMappedFromDispatch
} from '@console/containers/project/BillingContainer';
import type { LocalProject } from '@console/reducers/projects';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';
import type { ReactElement } from 'react';

const styles = (theme: Theme): StyleRules => ({
  sections: {
    paddingBottom: theme.spacing(5)
  },
  section: {
    paddingTop: theme.spacing(4)
  },
  billing: {
    marginBottom: theme.spacing(9)
  },
  sectionTitle: {
    paddingBottom: theme.spacing(1)
  },
  list: {
    color: 'unset'
  }
});

type BillingProps = BillingPropsMappedFromState &
  BillingPropsMappedFromDispatch &
  WithStyles<typeof styles>;

const Billing: React.FC<BillingProps> = ({
  classes,
  claimsGroupedByBillingMonth
}) => {
  const getTableSection = (
    claims: LocalProject['claims'],
    billingMonth: string
  ): ReactElement => (
    <div
      key={billingMonth}
      className={classes.billing}
      data-cy={`tableSection-${billingMonth}`}
    >
      {renderBillingLabel(billingMonth)}
      {renderBillingTable(claims)}
    </div>
  );

  const renderBillingTable = (claims: LocalProject['claims']): ReactElement => (
    <BillingTableContainer claims={claims} />
  );

  const renderBillingLabel = (billingMonth: string): ReactElement => (
    <BillingLabel billingMonth={billingMonth} />
  );

  const renderEmpty = (): ReactElement => <BillingEmpty />;

  const renderBillings = (): ReactElement[] | ReactElement => {
    const tableSections: ReactElement[] = [];

    for (const billingMonth in claimsGroupedByBillingMonth) {
      const claims = claimsGroupedByBillingMonth[billingMonth];
      tableSections.push(getTableSection(claims, billingMonth));
    }

    return tableSections.length !== 0 ? tableSections : renderEmpty();
  };

  return (
    <div className={classes.sections}>
      <section className={classes.section}>{renderBillings()}</section>
    </div>
  );
};

export default withStyles(styles)(Billing);
