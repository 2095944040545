import { gql } from '@apollo/client';

import { publicApiAppClient } from './apolloClient';
import { operationNames } from './operationNames';
import type { FetchResult } from '@apollo/client';

const getPrefecturesGql = gql`
  query ${operationNames.GET_PREFECTURES}{
    prefectures {
      name
      id
    }
  }
`;

export type Prefecture = {
  name: string;
  id: number;
};

export type Prefectures = Prefecture[];

export type GetPrefecturesResponse = FetchResult<{
  prefectures: Prefectures;
}>;

export const getPrefectures = (): Promise<GetPrefecturesResponse> =>
  publicApiAppClient.query({
    query: getPrefecturesGql
  });
