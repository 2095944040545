import { GIFTEE_BOX_TEMPLATE_BRAND_NAME } from '@console/common/constants';
import { downloadImage } from '@console/common/downloadImage';
import DownloadIcon from '@mui/icons-material/CloudDownload';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Typography, Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => ({
  imageDownloadText: {
    whiteSpace: 'nowrap',
    marginRight: theme.spacing()
  }
});

type ImageDownloadButtonOwnProps = {
  contentName: string;
  contentBrandName: string;
  imageDownloadUrl: string;
};

type ImageDownloadButtonProps = ImageDownloadButtonOwnProps &
  WithStyles<typeof styles>;

const ImageDownloadButton: React.FC<ImageDownloadButtonProps> = ({
  classes,
  contentName,
  contentBrandName,
  imageDownloadUrl
}) => (
  <div>
    {contentBrandName === GIFTEE_BOX_TEMPLATE_BRAND_NAME ? (
      <Button
        color="primary"
        variant="text"
        href={imageDownloadUrl}
        size="small"
        data-cy={`gifteeBox-imageDownloadUrl-${imageDownloadUrl}`}
      >
        <Typography className={classes.imageDownloadText} variant="caption">
          画像DLページへ
        </Typography>
        <KeyboardArrowRightIcon fontSize="small" />
      </Button>
    ) : (
      <Button
        color="primary"
        variant="text"
        className={classes.button}
        onClick={() => downloadImage(imageDownloadUrl, contentName)}
        size="small"
        data-cy={`giftCard-imageDownloadUrl-${imageDownloadUrl}`}
      >
        <Typography className={classes.imageDownloadText} variant="caption">
          画像DL
        </Typography>
        <DownloadIcon fontSize="small" />
      </Button>
    )}
  </div>
);

export default withStyles(styles)(ImageDownloadButton);
