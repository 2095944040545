import { connect } from 'react-redux';
import SyncStatus from '../../components/projectDraft/SyncStatus';
import type { AppThunkDispatch } from '../../actions/thunkType';
import type { AppState } from '../../reducers';

const mapStateToProps = (state: AppState) => ({
  projectDraftSyncStatus: state.projectDraftSyncStatus,
  projectDraftSyncedDate: state.projectDraftSyncedDate
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({});

export type SyncStatusPropsMappedFromState = ReturnType<typeof mapStateToProps>;
export type SyncStatusPropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(mapStateToProps, mapDispatchToProps)(SyncStatus);
