import {
  GIFTEE_BOX_TICKET_TYPE_DISPLAY_NAME,
  GIFT_CARD_FIXED_TICKET_TYPE_DISPLAY_NAME,
  GIFT_CARD_CHOOSABLE_TICKET_TYPE_DISPLAY_NAME
} from '@console/common/constants';
import {
  isFixedTicketType,
  isChoosableTicketType,
  isGifteeBoxTicketType
} from '@console/common/projectDraft/giftConfig';
import { Chip } from '@mui/material';
import type { TicketType } from '@console/reducers/projectJson/giftConfigs';

type TicketTypeChipOwnProps = {
  ticketType: TicketType;
};

type TicketTypeChipProps = TicketTypeChipOwnProps;

const TicketTypeChip: React.FC<TicketTypeChipProps> = ({ ticketType }) => {
  const getLabel = (): string => {
    if (isFixedTicketType(ticketType)) {
      return GIFT_CARD_FIXED_TICKET_TYPE_DISPLAY_NAME;
    } else if (isChoosableTicketType(ticketType)) {
      return GIFT_CARD_CHOOSABLE_TICKET_TYPE_DISPLAY_NAME;
    } else if (isGifteeBoxTicketType(ticketType)) {
      return GIFTEE_BOX_TICKET_TYPE_DISPLAY_NAME;
    }
    return '';
  };

  return <Chip label={getLabel()} />;
};

export default TicketTypeChip;
