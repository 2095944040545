import { createTheme, adaptV4Theme } from '@mui/material/styles';
import type { DeprecatedThemeOptions } from '@mui/material/styles';

export default createTheme(
  adaptV4Theme({
    palette: {
      primary: {
        main: '#0C6993',
        light: '#3c87a8',
        dark: '#084966'
      },
      secondary: {
        main: '#EF866A',
        light: '#f29e87',
        dark: '#a75d4a'
      },
      info: {
        main: '#bfbfbf',
        light: '#cbcbcb',
        dark: '#858585'
      },
      processing: {
        main: '#F7D177',
        light: '#f8da92',
        dark: '#ac9253'
      },
      success: {
        main: '#97cdb6',
        light: '#abd7c4',
        dark: '#698f7f'
      },
      failure: {
        main: '#e8bbc0',
        light: '#ecc8cc',
        dark: '#a28286'
      },
      icon: {
        pdf: '#e8bbc0',
        powerPoint: '#ecd2c1',
        excel: '#97cdb6',
        csv: '#97cdb6',
        file: '#bfbfbf',
        primary: '#5097C4'
      },
      background: {
        default: '#F3F3F3',
        console: '#FFFFFF'
      },
      text: {
        primary: '#393939',
        secondary: '#757575',
        disabled: '#BDBDBD'
      },
      giftCard: {
        main: '#0C6993',
        warning: {
          main: '#F54927'
        },
        text: {
          primary: '#393939',
          secondary: '#888888'
        },
        background: {
          main: '#FFFFFF',
          button: '#232323'
        }
      }
    },
    typography: {
      fontFamily: ['Hiragino Sans', 'sans-serif'].join(',')
    },
    zIndex: {
      drawer: 1200,
      appBar: 1250,
      modal: 1300,
      loader: 1500
    },
    spacing: 8,
    overrides: {
      MuiGrid: {
        'spacing-xs-8': {
          '& > $item': {
            padding: 0
          }
        }
      },
      MuiDivider: {
        root: {
          marginTop: 1
        }
      },
      MuiPrivateNotchedOutline: {
        root: {
          borderRadius: 0
        }
      },
      MuiIconButton: {
        root: {
          padding: 0,
          marginRight: 0
        }
      },
      MuiButton: {
        root: {
          textTransform: 'none'
        },
        contained: {
          'box-shadow': 'none'
        },
        containedSecondary: {
          color: '#FFFFFF'
        }
      },
      MuiCard: {
        root: {
          boxShadow: 'none'
        }
      },
      MuiTypography: {
        root: {
          fontSize: 14
        }
      },
      MuiAppBar: {
        root: {
          boxShadow: 'none',
          height: 80
        },
        colorPrimary: {
          backgroundColor: '#FFFFFF'
        }
      },
      MuiDrawer: {
        root: {
          boxShadow: 'none'
        },
        paper: {
          width: 200
        }
      },
      MuiPickersModal: {
        dialogRoot: {
          padding: '0 20px'
        }
      }
    }
  } as DeprecatedThemeOptions)
);
