import { getLocaleEndDateString } from '@common/lib/getLocaleDate';
import GifteeBoxTemplateBrief from '@console/components/common/GifteeBoxTemplateBrief';
import GifteeBoxTemplateContentSelectionsModalContainer from '@console/containers/common/GifteeBoxTemplateContentSelectionsModalContainer';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { useState } from 'react';
import type {
  DisplaySelectedGifteeBoxConfigForAPIProjectMappedFromState,
  DisplaySelectedGifteeBoxConfigForAPIProjectMappedFromDispatch
} from '@console/containers/project/gifteeBoxConfigs/exchangeReport/DisplaySelectedGifteeBoxConfigForAPIProjectContainer';
import type { LocalGifteeBoxConfig } from '@console/reducers/projects';
import type { Theme } from '@mui/material';
import type { StyleRules, WithStyles } from '@mui/styles';

// apiの有効期限は最新の設定だけを表示する
const LAST_API_ISSUE_CONFIG_ITEM_INDEX = -1;

const styles = (theme: Theme): StyleRules => ({
  tableContainer: {
    overflowX: 'scroll',
    whiteSpace: 'nowrap'
  },
  contentTableCell: {
    width: '40%',
    minWidth: 400
  },
  contentTableCellContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center'
  },
  gifteeBoxConfigIssueCodeTableCell: {
    width: '40%',
    minWidth: 200
  },
  tableCell: {
    width: '20%',
    minWidth: 100
  }
});

type DisplaySelectedGifteeBoxConfigForAPIProjectProps =
  DisplaySelectedGifteeBoxConfigForAPIProjectMappedFromState &
    DisplaySelectedGifteeBoxConfigForAPIProjectMappedFromDispatch &
    WithStyles<typeof styles>;

const DisplaySelectedGifteeBoxConfigForAPIProject: React.FC<
  DisplaySelectedGifteeBoxConfigForAPIProjectProps
> = ({
  gifteeBoxConfigForSelectionReport,
  exchangeableEndLabel,
  selectGifteeBoxTemplate,
  deselectGifteeBoxTemplate,
  classes
}) => {
  const [
    gifteeBoxTemplateContentSelectionsModalOpen,
    setGifteeBoxTemplateContentSelectionsModalOpen
  ] = useState(false);

  const openGifteeBoxTemplateContentSelectionsModal = (
    gifteeBoxTemplate: LocalGifteeBoxConfig['gifteeBoxTemplate']
  ) => {
    selectGifteeBoxTemplate(gifteeBoxTemplate);
    setGifteeBoxTemplateContentSelectionsModalOpen(true);
  };

  const closeGifteeBoxTemplateContentSelectionsModal = () => {
    setGifteeBoxTemplateContentSelectionsModalOpen(false);
    deselectGifteeBoxTemplate();
  };

  return (
    <div className={classes.tableContainer}>
      <Table>
        <TableHead data-cy="displaySelectedGifteeBoxConfigForAPIProject-tableHeader">
          <TableRow>
            <TableCell>
              <Typography>ギフト名</Typography>
            </TableCell>
            <TableCell>
              <Typography>giftee_box_config_code</Typography>
            </TableCell>
            <TableCell align="center">
              <Typography>発行有効期限</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!!gifteeBoxConfigForSelectionReport &&
            gifteeBoxConfigForSelectionReport.apiGifteeBoxIssueConfigs
              .slice(LAST_API_ISSUE_CONFIG_ITEM_INDEX)
              .map((apiGifteeBoxIssueConfig, apiGifteeBoxIssueConfigIndex) => (
                <TableRow
                  key={apiGifteeBoxIssueConfigIndex}
                  data-cy="displaySelectedGifteeBoxConfigForAPIProject-tableRow"
                >
                  <TableCell
                    rowSpan={
                      gifteeBoxConfigForSelectionReport.apiGifteeBoxIssueConfigs
                        .length
                    }
                    className={classes.contentTableCell}
                    data-cy="displaySelectedGifteeBoxConfigForAPIProject-tableCell-gifteeBoxTemplateBrief"
                  >
                    <GifteeBoxTemplateBrief
                      gifteeBoxTemplateName={
                        gifteeBoxConfigForSelectionReport.gifteeBoxTemplate.name
                      }
                      gifteeBoxTemplateImageUrl={
                        gifteeBoxConfigForSelectionReport.gifteeBoxTemplate
                          .imageUrl
                      }
                      handleButtonClick={() =>
                        openGifteeBoxTemplateContentSelectionsModal(
                          gifteeBoxConfigForSelectionReport.gifteeBoxTemplate
                        )
                      }
                      exchangeableEndLabel={exchangeableEndLabel}
                    />
                  </TableCell>
                  <TableCell
                    className={classes.gifteeBoxConfigIssueCodeTableCell}
                    rowSpan={
                      gifteeBoxConfigForSelectionReport.apiGifteeBoxIssueConfigs
                        .length
                    }
                    data-cy="displaySelectedGifteeBoxConfigForAPIProject-tableCell-issueCode"
                  >
                    <Typography>
                      {gifteeBoxConfigForSelectionReport.issueCode}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="center"
                    className={classes.tableCell}
                    data-cy="displaySelectedGifteeBoxConfigForAPIProject-tableCell-issuableEndAt"
                  >
                    <Typography>
                      {getLocaleEndDateString(
                        apiGifteeBoxIssueConfig.issuableEndAt
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
        </TableBody>
      </Table>
      <GifteeBoxTemplateContentSelectionsModalContainer
        isOpen={gifteeBoxTemplateContentSelectionsModalOpen}
        handleClose={closeGifteeBoxTemplateContentSelectionsModal}
      />
    </div>
  );
};

export default withStyles(styles)(DisplaySelectedGifteeBoxConfigForAPIProject);
