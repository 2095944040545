import { AppActionTypes } from '../actions/actionType';
import type { AppAction } from '../actions/actionType';
import type { GifteeBoxIssueCounts } from '@common/api/graphql/getIssueCountsGql';

const gifteeBoxIssueCounts = (
  state: GifteeBoxIssueCounts = [],
  action: AppAction
): GifteeBoxIssueCounts => {
  switch (action.type) {
    case AppActionTypes.GET_ISSUE_COUNTS_SUCCESS:
      return action.issueCountResult.gifteeBoxResults;
    default:
      return state;
  }
};

export default gifteeBoxIssueCounts;
