import { mutationFailure } from '../actions';
import type { MutationErrors } from '../../../../common/api/graphql/mutationErrorType';
import type { AppThunkAction } from '../thunkType';

export const handleMutationErrorsIfNeeded =
  (errors: MutationErrors): AppThunkAction<Promise<void>> =>
  dispatch => {
    if (hasMutationErrors(errors)) {
      const commonErrors = errors.map(error => error.message);
      dispatch(mutationFailure(commonErrors));
      return Promise.reject();
    }
    return Promise.resolve();
  };

export const handleMutationErrors =
  (errors: MutationErrors): AppThunkAction<Promise<void>> =>
  dispatch => {
    const commonErrors = errors.map(error => error.message);
    dispatch(mutationFailure(commonErrors));
    return Promise.reject(JSON.stringify(errors));
  };

export const hasMutationErrors = (errors: MutationErrors) =>
  !!errors && errors.length > 0;
