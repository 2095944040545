import { useZendeskWidget } from '@common/hooks/useZendeskWidget';
import { hasGon } from '@common/lib/gon';
import { isWindowWithZendeskWidget } from '@console/common/window';
import { faCircleQuestion } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { useEffect, useState } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import type { DistributionPartnerUser } from '@common/api/graphql/getLoginUserGql';
import type { Gon } from '@console/common/window';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

// カスタムフィールドIDはこちらで確認
// https://giftee.zendesk.com/admin/objects-rules/tickets/ticket-fields
const ZENDESK_DISTRIBUTION_PARTNER_USER_NAME_FIELD_ID = '4415055654937';

const styles = (theme: Theme): StyleRules => ({
  button: {
    minWidth: 140,
    position: 'fixed',
    bottom: 20,
    right: 20,
    // 以下は g4b マイページのボタンデザインと合わせるためのもの
    fontSize: 16,
    paddingTop: 12,
    paddingBottom: 12,
    borderRadius: 100,
    fontWeight: 'bold',
    borderColor: theme.palette.primary.main,
    backgroundColor: '#FFFFFF',
    borderWidth: 2,
    '&:hover': {
      borderWidth: 2
    }
  }
});

type OwnProps = WithStyles<typeof styles> & {
  distributionPartnerUser: DistributionPartnerUser;
};

const ZendeskWidget: React.FC<OwnProps> = ({
  distributionPartnerUser,
  classes
}) => {
  const location = useLocation();
  const [zendeskWidgetAccountKey, setZendeskWidgetAccountKey] = useState<
    null | string
  >(null);
  const { isScriptLoaded } = useZendeskWidget(zendeskWidgetAccountKey);
  const [isShown, setIsShown] = useState<boolean>(false);

  useEffect(() => {
    if (hasGon<Gon>(window)) {
      if (window.gon.zendeskWidgetAccountKey) {
        setZendeskWidgetAccountKey(window.gon.zendeskWidgetAccountKey);
      }
    }
  }, []);

  useEffect(() => {
    if (isScriptLoaded && isWindowWithZendeskWidget(window)) {
      if (matchPath('/console', location.pathname)) {
        window.zESettings = {
          webWidget: {
            contactForm: {
              fields: [
                {
                  id: 'email',
                  prefill: {
                    '*': distributionPartnerUser.email
                  }
                },
                {
                  id: ZENDESK_DISTRIBUTION_PARTNER_USER_NAME_FIELD_ID,
                  prefill: {
                    '*': distributionPartnerUser.name
                  }
                }
              ]
            }
          }
        };
        setIsShown(true);
      } else {
        window.zE('webWidget', 'hide');
        setIsShown(false);
      }
    } else {
      setIsShown(false);
    }
  }, [location.pathname, isScriptLoaded]);

  return isShown ? (
    <Button
      variant="outlined"
      color="primary"
      className={classes.button}
      startIcon={<FontAwesomeIcon icon={faCircleQuestion} />}
      onClick={() => {
        if (isWindowWithZendeskWidget(window)) {
          const zE = window.zE;
          zE('webWidget', 'show');
          zE('webWidget', 'open');

          zE('webWidget:on', 'close', () => {
            zE('webWidget', 'hide');
          });
        }
      }}
    >
      サポート
    </Button>
  ) : (
    <></>
  );
};

export default withStyles(styles)(ZendeskWidget);
