const openFileByUrlInAnotherTab = (url: string) => {
  const isIE11OrEdge = window.navigator && !!window.navigator.msSaveOrOpenBlob;

  let fileViewer;
  if (!isIE11OrEdge) {
    fileViewer = window.open();
  }

  fetch(url)
    .then(response =>
      response
        .blob()
        .then(blob => {
          if (fileViewer) {
            if (fileViewer.closed) {
              // don't do anything if window is closed
              return;
            }

            const fileUrl = URL.createObjectURL(blob);
            fileViewer.location.href = fileUrl;
            setTimeout(() => URL.revokeObjectURL(fileUrl));
          } else {
            // download files instead of opening ones if users use IE11, Edge, safari, Firefox
            location.href = url;
          }
        })
        .catch(() => {})
    )
    .catch(() => {});
};

export default openFileByUrlInAnotherTab;
