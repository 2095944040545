import { gql } from '@apollo/client';

export const FRAGMENT_GIFTEE_BOX_TEMPLATE = gql`
  fragment GifteeBoxTemplateFields on GifteeBoxTemplate {
    name
    imageUrl
    urlCode
    isLimitedPublished
    initialPointCandidates {
      initialPoint
    }
    exchangeableBeginLabel
    exchangeableEndLabel
    supportedPoint {
      maxPoint
      minPoint
      step
    }
  }
`;
