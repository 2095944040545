import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => ({
  projectProperty: {
    marginBottom: theme.spacing(3.5)
  },
  projectPropertyTitle: {
    marginBottom: theme.spacing(1)
  },
  projectPropertyValue: {
    whiteSpace: 'pre-wrap'
  }
});

type OwnProps = { label: string; value: string };
type ProjectItemProps = OwnProps & WithStyles<typeof styles>;

const ProjectItem: React.FC<ProjectItemProps> = ({ label, value, classes }) => (
  <div className={classes.projectProperty}>
    <Typography
      variant="caption"
      color="textSecondary"
      className={classes.projectPropertyTitle}
      component="p"
    >
      {label}
    </Typography>
    <Typography variant="body2" className={classes.projectPropertyValue}>
      {value}
    </Typography>
  </div>
);

export default withStyles(styles)(ProjectItem);
