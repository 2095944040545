import { gql } from '@apollo/client';

import { internalApiAppClient } from './apolloClient';
import { FRAGMENT_DISTRIBUTION_PARTNER_USER } from './fragments/distributionPartnerUser';
import { operationNames } from './operationNames';
import type { Prefecture } from './getPrefecturesGql';
import type { FetchResult } from '@apollo/client';

const getLoginUserGql = gql`
  ${FRAGMENT_DISTRIBUTION_PARTNER_USER}
  query ${operationNames.GET_LOGIN_USER}{
    distributionPartnerUser {
      ...DistributionPartnerUserFields
    }
    distributionPartner {
      name
      inBusiness
      urlCode
      creditLimitValue
      creditLimitBalance
      billingAddresses {
        address
        city
        company
        division
        name
        optionalAddress
        phoneNumber
        phoneNumber
        postalCode
        prefecture {
          id
          name
        }
      }
    }
  }
`;

export interface DistributionPartnerUser {
  name: string;
  email: string;
  division: string;
  phoneNumber: string;
  urlCode: UrlCode;
}

export interface DistributionPartner {
  name: string;
  inBusiness: boolean;
  urlCode: UrlCode;
  creditLimitValue: number | null;
  creditLimitBalance: number | null;
  billingAddresses: BillingAddress[];
}

interface BillingAddress {
  address: string;
  city: string;
  company: string;
  division: string;
  name: string;
  optionalAddress: string;
  phoneNumber: string;
  postalCode: string;
  prefecture: Prefecture;
}

export interface Identity {
  distributionPartnerUser: DistributionPartnerUser;
  distributionPartner: DistributionPartner;
}

export type GetLoginUserResponse = FetchResult<Identity>;

export const getLoginUser = (): Promise<GetLoginUserResponse> =>
  internalApiAppClient.query({
    query: getLoginUserGql
  });
