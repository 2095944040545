import { AppActionTypes } from '../actions/actionType';
import type { GiftCardMainVisuals } from '../../../common/api/graphql/getGiftCardMainVisualsGql';
import type { AppAction } from '../actions/actionType';

const giftCardMainVisuals = (
  state = [],
  action: AppAction
): GiftCardMainVisuals => {
  switch (action.type) {
    case AppActionTypes.GET_GIFT_CARD_MAIN_VISUALS_SUCCESS:
    case AppActionTypes.GET_MASTER_DATA_SUCCESS:
      return action.giftCardMainVisuals;
    default:
      return state;
  }
};

export default giftCardMainVisuals;
