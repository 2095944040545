import ApiTableContainer from '@console/containers/project/giftConfig/ApiTableContainer';
import GcpTableContainer from '@console/containers/project/giftConfig/GcpTableContainer';
import { Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Fragment } from 'react';
import CommonErrorContainer from '../../containers/common/CommonErrorContainer';
import BulkTableContainer from '../../containers/project/giftConfig/BulkTableContainer';
import type {
  GiftConfigPropsMappedFromState,
  GiftConfigPropsMappedFromDispatch
} from '../../containers/project/GiftConfigContainer';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';
import type { ReactNode } from 'react';

const styles = (theme: Theme): StyleRules => ({});

type GiftConfigProps = GiftConfigPropsMappedFromState &
  GiftConfigPropsMappedFromDispatch &
  WithStyles<typeof styles>;

// TODO: 綴り対応 & 選べる対応 & GiftCardConfig の issueAmountRequest 対応
const GiftConfig: React.FC<GiftConfigProps> = ({
  isApiProject,
  isBulkProject,
  isGcpProject,
  classes
}) => {
  const renderTable = (): ReactNode => {
    if (isBulkProject) {
      return <BulkTableContainer />;
    }
    if (isApiProject) {
      return <ApiTableContainer />;
    }
    if (isGcpProject) {
      return <GcpTableContainer />;
    }

    return null;
  };

  return (
    <Fragment>
      <CommonErrorContainer />
      {renderTable()}
    </Fragment>
  );
};

export default withStyles(styles)(GiftConfig);
