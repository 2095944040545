import {
  isApiSolutionSystem,
  isBulkSolutionSystem,
  isGcpSolutionSystem
} from '@console/common/solutionSystem';
import { createSelector } from 'reselect';
import type { AppState } from '@console/reducers';
import type { LocalProject } from '@console/reducers/projects';

const getProjectDetailSolutionSystem = (state: AppState) =>
  state.projectDetail.solutionSystem;

export const getSolutionSystem = createSelector(
  [getProjectDetailSolutionSystem],
  (solutionSystem): LocalProject['solutionSystem'] => ({
    name: solutionSystem.name ?? '',
    solutionSystemIdentifier: solutionSystem.solutionSystemIdentifier,
    urlCode: solutionSystem.urlCode ?? ''
  })
);

export const getIsBulkProject = createSelector(
  [getSolutionSystem],
  (solutionSystem): boolean => isBulkSolutionSystem(solutionSystem)
);

export const getIsApiProject = createSelector(
  [getSolutionSystem],
  (solutionSystem): boolean => isApiSolutionSystem(solutionSystem)
);

export const getIsGcpProject = createSelector(
  [getSolutionSystem],
  (solutionSystem): boolean => isGcpSolutionSystem(solutionSystem)
);
