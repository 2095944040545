import { getNodes } from '@common/lib/ikedayamaConnection';
import { AppActionTypes } from '../actions/actionType';
import type { ProjectDraft } from './selectedProjectDraft';
import type { AppAction } from '../actions/actionType';

export type ProjectDrafts = ProjectDraft[];

const projectDrafts = (
  state: ProjectDrafts = [],
  action: AppAction
): ProjectDrafts => {
  switch (action.type) {
    case AppActionTypes.UPDATE_PROJECT_DRAFTS:
      return action.projectDrafts;
    case AppActionTypes.GET_PROJECT_DRAFTS_SUCCESS:
      return action.projectDrafts;
    case AppActionTypes.DELETE_PROJECT_DRAFT_SUCCESS:
      return state.filter(
        projectDraft =>
          projectDraft.urlCode !== action.deletedProjectDraftUrlCode
      );
    case AppActionTypes.GET_PROJECT_DRAFTS_CONTENTS_SUCCESS:
      const contentUrlCodes = getNodes(action.contentsConnection).map(
        content => content.urlCode
      );

      return state.map(projectDraft => ({
        ...projectDraft,
        projectJson: {
          ...projectDraft.projectJson,
          giftConfigs: projectDraft.projectJson.giftConfigs.map(giftConfig => ({
            ...giftConfig,
            selectedContents: giftConfig.selectedContents.filter(
              selectedContent => contentUrlCodes.includes(selectedContent)
            )
          }))
        }
      }));
    default:
      return state;
  }
};

export default projectDrafts;
