import { createSelector } from 'reselect';
import type { AppState } from '@console/reducers';
import type { LocalProject } from '@console/reducers/projects';

const getProjectDetailMarketingIndustry = (state: AppState) =>
  state.projectDetail.marketingIndustry;

export const getMarketingIndustry = createSelector(
  [getProjectDetailMarketingIndustry],
  (marketingIndustry): LocalProject['marketingIndustry'] => ({
    name: marketingIndustry.name ?? '',
    urlCode: marketingIndustry.urlCode ?? ''
  })
);
