import { ERROR_MESSAGE_FOR_PAGE_VALIDATION } from '@common/middleware/errorMessages';
import { validateProjectDraftFailure } from '@console/actions/actions';
import { validateCampaignOverviewThunk } from '@console/actions/thunks/validateProjectDraft/validateCampaignOverview';
import { validateContentsSelectionThunk } from '@console/actions/thunks/validateProjectDraft/validateGiftConfig/validateContentsSelection';
import { validateDeliveryConfigThunk } from '@console/actions/thunks/validateProjectDraft/validateGiftConfig/validateDeliveryConfig';
import { validateMessageCardConfigThunk } from '@console/actions/thunks/validateProjectDraft/validateGiftConfig/validateMessageCardConfig';
import { validateGiftConfigsThunk } from '@console/actions/thunks/validateProjectDraft/validateGiftConfigs';
import { validateGiftDistributionThunk } from '@console/actions/thunks/validateProjectDraft/validateGiftDistribution';
import { getActiveStep } from '@console/common/getActiveStep';
import {
  isCampaignOverview,
  isGiftDistribution,
  isGiftConfigs,
  isContentsSelection,
  isDeliveryConfig,
  isMessageCardConfig
} from '@console/components/projectDraft/Root';
import SideStepper from '@console/components/projectDraft/SideStepper';
import { connect } from 'react-redux';
import type {
  AppThunkAction,
  AppThunkDispatch
} from '@console/actions/thunkType';
import type { AppState } from '@console/reducers';
import type { NavigateFunction } from 'react-router-dom';

const handleLinkClick =
  (
    navigate: NavigateFunction,
    url: string,
    clickedParentIndex: number,
    clickedChildIndex?: number
  ): AppThunkAction<Promise<void>> =>
  dispatch =>
    dispatch(validatePageIfNeeded(clickedParentIndex, clickedChildIndex))
      .then(() => {
        navigate(url);
      })
      .catch(() => {
        dispatch(
          validateProjectDraftFailure(ERROR_MESSAGE_FOR_PAGE_VALIDATION)
        );
      });

const validatePageIfNeeded =
  (
    clickedParentIndex: number,
    clickedChildIndex?: number
  ): AppThunkAction<Promise<void>> =>
  dispatch => {
    const { parentStep, childStep } = getActiveStep();

    if (isOwnStep(clickedParentIndex, clickedChildIndex)) {
      return Promise.resolve();
    }

    if (isCampaignOverview(parentStep, childStep)) {
      return dispatch(validateCampaignOverviewThunk());
    }
    if (isGiftDistribution(parentStep, childStep)) {
      return dispatch(validateGiftDistributionThunk());
    }
    if (isGiftConfigs(parentStep, childStep)) {
      return dispatch(validateGiftConfigsThunk());
    }
    if (isContentsSelection(parentStep, childStep)) {
      return dispatch(validateContentsSelectionThunk());
    }
    if (isDeliveryConfig(parentStep, childStep)) {
      return dispatch(validateDeliveryConfigThunk());
    }
    if (isMessageCardConfig(parentStep, childStep)) {
      return dispatch(validateMessageCardConfigThunk());
    }
    return Promise.resolve();
  };

const isOwnStep = (clickedParentIndex, clickedChildIndex) => {
  const { parentStep, childStep } = getActiveStep();

  return clickedParentIndex === parentStep && clickedChildIndex === childStep;
};

const mapStateToProps = (state: AppState) => ({
  giftConfigs: state.selectedProjectDraft.projectJson.giftConfigs,
  selectedGiftConfigIndex:
    state.selectedProjectDraft.projectJson.selectedGiftConfigIndex
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  handleLinkClick: (
    navigate: NavigateFunction,
    url: string,
    clickedParentIndex: number,
    clickedChildIndex?: number
  ) =>
    dispatch(
      handleLinkClick(navigate, url, clickedParentIndex, clickedChildIndex)
    )
});

export type SideStepperPropsMappedFromState = ReturnType<
  typeof mapStateToProps
>;
export type SideStepperPropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(mapStateToProps, mapDispatchToProps)(SideStepper);
