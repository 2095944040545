import { getUniqueObjects } from '@common/lib/getUniqueElements';
import { getNodes } from '@common/lib/ikedayamaConnection';
import { AppActionTypes } from '../actions/actionType';
import type { AppAction } from '../actions/actionType';
import type { Content, Contents } from '@common/api/graphql/getContentsGql';

const initialState: Contents = [];

const contents = (state = initialState, action: AppAction): Contents => {
  switch (action.type) {
    case AppActionTypes.OPEN_CONTENTS_PAGE:
    case AppActionTypes.OPEN_PROJECT_DRAFT_CONTENTS_SELECTION_PAGE:
    case AppActionTypes.CHANGE_CONTENTS_BRAND_FILTER:
    case AppActionTypes.CHANGE_CONTENTS_PRICE_RANGE_FILTER:
      return initialState;
    case AppActionTypes.GET_INITIAL_CONTENTS_SUCCESS:
    case AppActionTypes.GET_CONTENTS_OF_SELECTED_PROJECT_DRAFT_SUCCESS:
      return getUniqueObjects<Content>(
        [...state, ...getNodes(action.contentsConnection)],
        (content1, content2) => content1.urlCode === content2.urlCode
      );
    case AppActionTypes.GET_CONTINUOUS_CONTENTS_SUCCESS:
      return getUniqueObjects<Content>(
        [...state, ...getNodes(action.contentsConnection)],
        (content1, content2) => content1.urlCode === content2.urlCode,
        true
      );
    case AppActionTypes.GET_PROJECT_DRAFTS_CONTENTS_SUCCESS:
    case AppActionTypes.GET_SELECTED_PROJECT_DRAFT_CONTENTS_SUCCESS:
      return getNodes(action.contentsConnection);
    default:
      return state;
  }
};

export default contents;
