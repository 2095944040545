import { handleMessageThreadScroll } from '@console/actions/thunks/handleMessageThreadScroll';
import { postMessageThreadMessageThunk } from '@console/actions/thunks/postMessageThreadMessage';
import { uploadFileThunk } from '@console/actions/thunks/uploadFile';
import type { TextContent } from '@common/api/graphql/postMessageThreadMessage';
import type {
  UploadedFileUrlCodes,
  UploadedFiles
} from '@common/api/graphql/uploadFileGql';
import type { AppThunkAction } from '@console/actions/thunkType';

export const uploadAttachedFiles =
  (): AppThunkAction<Promise<string[]>> => (dispatch, getState) =>
    Promise.all(
      getState().messageThreadAttachedFiles.map(
        async file => (await dispatch(uploadFileThunk(file))) || ''
      )
    );

export const postMessageThreadMessageWithAttachedFilesThunk =
  (messageThreadUrlCode: string): AppThunkAction<Promise<void>> =>
  async (dispatch, getState) => {
    const inputMessage: TextContent =
      getState().messageThreadInputMessages[messageThreadUrlCode];
    const attachedFiles: File[] = getState().messageThreadAttachedFiles;
    const attachedFileUrlCodes: UploadedFileUrlCodes = await dispatch(
      uploadAttachedFiles()
    );
    // file object displayed before posting message thread message via grapql api.
    const uploadedFiles: UploadedFiles = attachedFiles.map((file, index) => {
      const attachedFileUrlCode = attachedFileUrlCodes[index];
      return { urlCode: attachedFileUrlCode, name: file.name, size: file.size };
    });

    return dispatch(
      postMessageThreadMessageThunk(
        messageThreadUrlCode,
        inputMessage,
        attachedFileUrlCodes,
        uploadedFiles
      )
    ).then(handleMessageThreadScroll);
  };
