import { gql } from '@apollo/client';

export const FRAGMENT_DISTRIBUTION_PARTNER_USER = gql`
  fragment DistributionPartnerUserFields on DistributionPartnerUser {
    division
    email
    name
    phoneNumber
    urlCode
  }
`;
