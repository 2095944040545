import { AppActionTypes } from '../../actions/actionType';
import type { AppAction } from '../../actions/actionType';

export type SelectedGiftConfigIndex = number;
export const initialStateOfselectedGiftConfigIndex = 0;

export const selectedGiftConfigIndex = (
  state = initialStateOfselectedGiftConfigIndex,
  action: AppAction
): SelectedGiftConfigIndex => {
  switch (action.type) {
    case AppActionTypes.ADD_PROJECT_DRAFT:
    case AppActionTypes.COPY_PROJECT_TO_PROJECT_DRAFT:
    case AppActionTypes.COPY_PROJECT_TO_PROJECT_DRAFT_FAILURE:
      return initialStateOfselectedGiftConfigIndex;
    case AppActionTypes.SELECT_PROJECT_DRAFT:
      return action.projectDraft.projectJson.selectedGiftConfigIndex;
    case AppActionTypes.SET_SELECTED_GIFT_CONFIG:
    case AppActionTypes.SELECT_NEW_GIFT_CONFIG:
    case AppActionTypes.INITIALIZE_SELECTED_GIFT_CONFIG:
      return action.index;
    default:
      return state;
  }
};

export default selectedGiftConfigIndex;
