import queryString from 'query-string';

export enum FeatureTypes {
  HOGE = 'feature-hoge',
  ACCOUNT = 'feature-account',
  NEW_APPLY_START = 'new-apply-start'
}

export const isFeatureEnable = (feature: FeatureTypes): boolean => {
  const queryParams = queryString.parse(location.search);
  return queryParams[feature] === 'true';
};
