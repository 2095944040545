import { Typography, Grid, Table, TableBody, Button } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import AccountContainer from '../../containers/account/AccountContainer';
import DetailTableRow from '../common/DetailTableRow';
import type {
  DistributionPartnerUserPropsMappedFromDispatch,
  DistributionPartnerUserPropsMappedFromState
} from '../../containers/account/DistributionPartnerUserContainer';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    sections: {
      maxWidth: 600
    },
    section: {
      marginBottom: theme.spacing(6)
    },
    sectionTitle: {
      marginBottom: theme.spacing(3)
    }
  });

type DistributionPartnerUserProps = WithStyles<typeof styles> &
  DistributionPartnerUserPropsMappedFromDispatch &
  DistributionPartnerUserPropsMappedFromState;

const DistributionPartnerUser: React.FC<DistributionPartnerUserProps> = ({
  classes,
  distributionPartnerUser
}) => (
  <AccountContainer>
    <div className={classes.sections}>
      <section className={classes.section}>
        <Typography
          variant="h6"
          color="textPrimary"
          className={classes.sectionTitle}
        >
          ユーザー情報
        </Typography>
        <Table>
          <TableBody>
            <DetailTableRow label="名前" value={distributionPartnerUser.name} />
            <DetailTableRow
              label="電話番号"
              value={distributionPartnerUser.phoneNumber}
            />
            <DetailTableRow
              label="メールアドレス"
              value={distributionPartnerUser.email}
            />
          </TableBody>
        </Table>
      </section>
    </div>
  </AccountContainer>
);

export default withStyles(styles)(DistributionPartnerUser);
