import { Chip } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => ({
  chip: {
    borderRadius: 5
  },
  sucesssIcon: {
    backgroundColor: theme.palette.success.main
  }
});

type BrandCheckStatusChipOwnProps = {
  creativeCheckApproved: boolean;
};

type BrandCheckStatusChipProps = BrandCheckStatusChipOwnProps &
  WithStyles<typeof styles>;

const BrandCheckStatusChip: React.FC<BrandCheckStatusChipProps> = ({
  classes,
  creativeCheckApproved
}) => (
  <Chip
    label={creativeCheckApproved ? '承認済み' : '未承認'}
    className={clsx(classes.chip, {
      [classes.sucesssIcon]: creativeCheckApproved
    })}
    data-cy="brandCheckStatusChip"
  />
);

export default withStyles(styles)(BrandCheckStatusChip);
