import { createProject } from '@common/api/graphql/createProjectGql';
import { createSuccessMessage } from '@common/api/graphql/successMessage';
import {
  createProjectStart,
  createProjectSuccess,
  createProjectFailure
} from '../actions';
import { getCreateProjectParamsThunk } from './converters/createProject';
import handleApiErrorIfNeeded from './handleApiErrorIfNeeded';
import type { AppThunkAction } from '../thunkType';
import type { ApiError } from '@common/api/graphql/apolloClient';
import type { CreateProjectResponse } from '@common/api/graphql/createProjectGql';

export const createProjectSuccessThunk =
  (response: CreateProjectResponse): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(createProjectSuccess(createSuccessMessage('デジタルギフト利用')));
    return Promise.resolve();
  };

export const createProjectFailureThunk =
  (error: ApiError): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(createProjectFailure());
    return dispatch(handleApiErrorIfNeeded(error));
  };

export const createProjectThunk =
  (): AppThunkAction<Promise<void>> => dispatch => {
    dispatch(createProjectStart());
    const createProjectParams = dispatch(getCreateProjectParamsThunk());

    if (!createProjectParams) {
      return Promise.resolve();
    }

    return createProject(createProjectParams!)
      .then((response: CreateProjectResponse) =>
        dispatch(createProjectSuccessThunk(response))
      )
      .catch((error: ApiError) => dispatch(createProjectFailureThunk(error)));
  };
