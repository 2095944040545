import { isGifteeBoxGiftConfig } from '@console/common/projectDraft/giftConfig';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import queryString from 'query-string';
import { Fragment } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import type {
  SideStepperPropsMappedFromState,
  SideStepperPropsMappedFromDispatch
} from '../../containers/projectDraft/SideStepperContainer';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => ({
  drawer: {
    flexShrink: 0
  },
  stepper: {
    marginTop: 100
  },
  menuIcon: {
    padding: theme.spacing()
  },
  list: {
    textDecoration: 'none',
    color: 'unset'
  },
  childListItem: {
    paddingLeft: theme.spacing(4)
  },
  typography: {
    paddingLeft: theme.spacing()
  },
  selected: {
    borderLeft: `solid 2px ${theme.palette.primary.main}`,
    marginLeft: -2,
    fontWeight: 700
  },
  disabled: {
    color: theme.palette.text.disabled,
    padding: `${theme.spacing()} ${theme.spacing(2)}`,
    paddingLeft: theme.spacing(5)
  }
});

export const steps = [
  {
    title: '利用概要'
  },
  {
    title: 'ギフトの配布方法'
  },
  {
    title: 'ギフト追加',
    children: [
      // 旧タイトルを削除、「ギフト追加」をtitleに変更。
      // isMessageCardConfigNecessary()等がIndexを受け取る前提を崩したくないため、
      // childrenにギフト追加を残したままにしておく
      'ギフト追加',
      'ギフトのタイプ選択',
      'ギフト選択',
      '数量・納品日入力',
      'メッセージカードの有無選択',
      'メッセージカード作成'
    ]
  },
  {
    title: '申込内容確認'
  }
];

interface PathParams {
  urlCode: string;
}

type SideStepperProps = SideStepperPropsMappedFromState &
  SideStepperPropsMappedFromDispatch &
  WithStyles<typeof styles>;

const SideStepper: React.FC<SideStepperProps> = ({
  giftConfigs,
  selectedGiftConfigIndex,
  handleLinkClick,
  classes
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const activeStep = queryString.parse(location.search);
  const parentStep = Number(activeStep.parentStep);
  const childStep = Number(activeStep.childStep);

  const isMessageCardConfigNecessary = (
    parentIndex: number,
    childIndex: number
  ): boolean =>
    giftConfigs.length === 0 ||
    !(
      isGifteeBoxGiftConfig(giftConfigs[selectedGiftConfigIndex]) &&
      parentIndex === 2 &&
      childIndex >= 4
    );

  const isGiftConfigStepperDisable = (
    parentIndex: number,
    childIndex: number
  ): boolean =>
    // giftConfigs が空の場合、以降のギフト設定のサイドステッパーを disable にする
    giftConfigs.length === 0 && parentIndex === 2 && childIndex >= 1;
  const isMessageCardStepperDisable = (
    parentIndex: number,
    childIndex: number
  ): boolean =>
    parentIndex === 2 &&
    childIndex >= 5 &&
    !giftConfigs[selectedGiftConfigIndex].messageCardUsing;

  return (
    <Drawer
      variant="persistent"
      className={classes.drawer}
      anchor="left"
      open
      classes={{
        paper: classes.drawerPaper
      }}
    >
      <List className={classes.stepper}>
        {steps.map((step, parentIndex) => {
          const targetParentUrl = `${location.pathname}?parentStep=${parentIndex}&childStep=0`;
          return (
            <Fragment key={parentIndex}>
              <ListItemButton
                onClick={() =>
                  handleLinkClick(navigate, targetParentUrl, parentIndex)
                }
                data-cy={`parentStep-${step.title}`}
              >
                <Typography
                  variant="body2"
                  className={clsx(classes.typography, {
                    [classes.selected]:
                      childStep === 0 && parentStep === parentIndex
                  })}
                >
                  {step.title}
                </Typography>
              </ListItemButton>
              {!step.children
                ? null
                : step.children.map((child, childIndex) => {
                    if (childIndex === 0) {
                      return null;
                    }
                    if (
                      !isMessageCardConfigNecessary(parentIndex, childIndex)
                    ) {
                      return null;
                    }
                    if (
                      isGiftConfigStepperDisable(parentIndex, childIndex) ||
                      isMessageCardStepperDisable(parentIndex, childIndex)
                    ) {
                      return (
                        <Typography
                          variant="body2"
                          className={clsx(classes.typography, classes.disabled)}
                          key={childIndex}
                          data-cy={`childStep-${child}`}
                        >
                          {child}
                        </Typography>
                      );
                    }
                    const targetChildUrl = `${location.pathname}?parentStep=${parentIndex}&childStep=${childIndex}`;
                    return (
                      <ListItemButton
                        onClick={() =>
                          handleLinkClick(
                            navigate,
                            targetChildUrl,
                            parentIndex,
                            childIndex
                          )
                        }
                        className={classes.childListItem}
                        key={childIndex}
                        data-cy={`childStep-${child}`}
                      >
                        <Typography
                          variant="body2"
                          className={clsx(classes.typography, {
                            [classes.selected]:
                              parentStep === parentIndex &&
                              childStep === childIndex
                          })}
                        >
                          {child}
                        </Typography>
                      </ListItemButton>
                    );
                  })}
            </Fragment>
          );
        })}
      </List>
    </Drawer>
  );
};

export default withStyles(styles)(SideStepper);
