import { getLocalString } from '@common/lib/getLocalString';
import GifteeBoxInitialPointInput from '@console/components/common/GifteeBoxInitialPointInput';
import GifteeBoxTemplateBrief from '@console/components/common/GifteeBoxTemplateBrief';
import SectionTitle from '@console/components/projectDraft/common/SectionTitle';
import TicketTypeChip from '@console/components/projectDraft/common/TicketTypeChip';
import GifteeBoxTemplateContentSelectionsModalContainer from '@console/containers/common/GifteeBoxTemplateContentSelectionsModalContainer';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Grid,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  IconButton,
  Button,
  FormControl
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { useEffect, useState } from 'react';
import type {
  ApiTablePropsMappedFromDispatch,
  ApiTablePropsMappedFromState
} from '@console/containers/projectDraft/deliveryConfig/gifteeBoxDevliveryConfig/ApiTableContainer';
import type { SelectedGifteeBoxTemplate } from '@console/reducers/projectJson/giftConfigs';
import type { Theme } from '@mui/material';
import type { StyleRules, WithStyles } from '@mui/styles';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    table: {
      margin: `1% 0`
    },
    tableCell: {
      padding: theme.spacing(2),
      verticalAlign: 'middle',
      minWidth: 130
    },
    contentCell: {
      paddingRight: theme.spacing(2),
      width: theme.spacing(52)
    },
    issueEstimateOptionFields: {
      width: '50%',
      minWidth: theme.spacing(30)
    },
    issueEstimateOptionFieldItemContainer: {
      marginBottom: theme.spacing(2)
    },
    issueEstimateOptionFieldItem: {
      width: '40%',
      minWidth: theme.spacing(5)
    },
    iconButtonContainer: {
      margin: 'auto 0'
    },
    issueEstimateOptionSubFields: {
      marginTop: theme.spacing(2)
    },
    errorMessage: {
      minHeight: theme.spacing(3),
      fontSize: 14
    }
  });

type ApiTableProps = ApiTablePropsMappedFromDispatch &
  ApiTablePropsMappedFromState &
  WithStyles<typeof styles>;

const ApiTable: React.FC<ApiTableProps> = ({
  classes,
  giftConfig,
  initialPointCandidates,
  selectedGifteeBoxTemplate,
  giftConfigErrors,
  isIssueEstimateOptionAddable,
  isIssueEstimateOptionRemovable,
  onChangeIssueEstimateOptionsInitialPoint,
  onInputChangeIssueEstimateOptionsInitialPoint,
  onChangeIssueEstimateOptionsIssueAmount,
  onClickAddIssueEstimateOption,
  onClickRemoveIssueEstimateOption,
  selectGifteeBoxTemplate,
  deselectGifteeBoxTemplate,
  onMount
}) => {
  if (!giftConfig || !giftConfig.selectedGifteeBoxTemplate) return null;

  useEffect(() => {
    onMount();
  }, []);

  const [
    gifteeBoxTemplateContentSelectionsModalOpen,
    setGifteeBoxTemplateContentSelectionsModalOpen
  ] = useState(false);

  const openGifteeBoxTemplateContentSelectionsModal = (
    gifteeBoxTemplate: NonNullable<SelectedGifteeBoxTemplate>
  ) => {
    selectGifteeBoxTemplate(gifteeBoxTemplate);
    setGifteeBoxTemplateContentSelectionsModalOpen(true);
  };

  const closeGifteeBoxTemplateContentSelectionsModal = () => {
    setGifteeBoxTemplateContentSelectionsModalOpen(false);
    deselectGifteeBoxTemplate();
  };

  return (
    <>
      <SectionTitle text="単価と見込み数量を入力してください" />
      <Table
        className={classes.table}
        data-cy="gifteeBoxDeliveryConfigApiTable"
      >
        <TableHead>
          <TableRow data-cy="gifteeBoxDeliveryConfigApiTableHeader">
            <TableCell className={classes.tableCell} align="center">
              ギフトのタイプ
            </TableCell>
            <TableCell className={classes.contentCell} align="left">
              ギフト・ブランド名
            </TableCell>
            <TableCell className={classes.tableCell} align="left">
              単価と見込み数量
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell
              className={classes.tableCell}
              align="center"
              data-cy="gifteeBoxDeliveryConfigApiTableTicketType"
            >
              <TicketTypeChip ticketType={giftConfig.ticketType} />
            </TableCell>
            <TableCell className={classes.contentCell} align="left">
              <Grid container alignItems="center">
                <GifteeBoxTemplateBrief
                  gifteeBoxTemplateName={
                    giftConfig.selectedGifteeBoxTemplate.name
                  }
                  gifteeBoxTemplateImageUrl={
                    giftConfig.selectedGifteeBoxTemplate.imageUrl
                  }
                  exchangeableEndLabel={
                    giftConfig.selectedGifteeBoxTemplate.exchangeableEndLabel
                  }
                  isLimitedPublished={
                    giftConfig.selectedGifteeBoxTemplate.isLimitedPublished
                  }
                  handleButtonClick={() =>
                    openGifteeBoxTemplateContentSelectionsModal(
                      giftConfig.selectedGifteeBoxTemplate!
                    )
                  }
                />
              </Grid>
            </TableCell>
            <TableCell className={classes.tableCell} align="left">
              <Grid
                direction="column"
                container
                className={classes.issueEstimateOptionFields}
              >
                {giftConfig.gifteeBoxIssueEstimateOptions.map(
                  (gifteeBoxIssueEstimateOption, index) => (
                    <Grid
                      key={`gifteeBoxDeliveryConfigApiTableGifteeBoxIssueEstimateOption-${index}`}
                      justifyContent="space-around"
                      alignItems="flex-end"
                      container
                      className={classes.issueEstimateOptionFieldItemContainer}
                      item
                    >
                      <FormControl
                        variant="standard"
                        className={classes.issueEstimateOptionFieldItem}
                      >
                        <GifteeBoxInitialPointInput
                          initialPoint={
                            gifteeBoxIssueEstimateOption.initialPoint
                          }
                          initialPointCandidates={initialPointCandidates}
                          maxPoint={
                            selectedGifteeBoxTemplate?.supportedPoint.maxPoint
                          }
                          minPoint={
                            selectedGifteeBoxTemplate?.supportedPoint.minPoint
                          }
                          step={selectedGifteeBoxTemplate?.supportedPoint.step}
                          label="単価(円)"
                          index={index.toString()}
                          isError={
                            !!giftConfigErrors?.gifteeBoxIssueEstimateOptionInitialPoint
                          }
                          onInputChange={value =>
                            onInputChangeIssueEstimateOptionsInitialPoint(
                              value,
                              index
                            )
                          }
                          onChange={value =>
                            onChangeIssueEstimateOptionsInitialPoint(
                              value,
                              index
                            )
                          }
                        />
                      </FormControl>
                      <TextField
                        variant="standard"
                        name={`gifteeBoxDeliveryConfigApiTableGifteeBoxIssueEstimateOptionIssueAmount-${index}`}
                        value={gifteeBoxIssueEstimateOption.issueAmount}
                        type="number"
                        required
                        onChange={event =>
                          onChangeIssueEstimateOptionsIssueAmount(event, index)
                        }
                        inputProps={{ min: '1' }}
                        error={
                          !!giftConfigErrors?.gifteeBoxIssueEstimateOptionIssueAmount
                        }
                        className={classes.issueEstimateOptionFieldItem}
                        label="数量(見込み)"
                        InputLabelProps={{
                          shrink: true
                        }}
                        data-cy={`gifteeBoxDeliveryConfigApiTableGifteeBoxIssueEstimateOptionIssueAmount-${index}`}
                      />
                      <div className={classes.iconButtonContainer}>
                        <IconButton
                          disabled={!isIssueEstimateOptionRemovable}
                          aria-label="deleteGifteeBoxIssueEstimateOption"
                          data-cy={`gifteeBoxDeliveryConfigApiTableGifteeBoxIssueEstimateOption-${index}-DeleteButton`}
                          onClick={() =>
                            onClickRemoveIssueEstimateOption(index)
                          }
                          size="large"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    </Grid>
                  )
                )}
              </Grid>
              <Grid container>
                <Typography
                  className={classes.errorMessage}
                  variant="caption"
                  color="error"
                >
                  {giftConfigErrors?.gifteeBoxIssueEstimateOptionInitialPoint}
                </Typography>
                <Typography
                  className={classes.errorMessage}
                  variant="caption"
                  color="error"
                >
                  {giftConfigErrors?.gifteeBoxIssueEstimateOptionIssueAmount}
                </Typography>
              </Grid>
              <Grid
                item
                container
                className={classes.issueEstimateOptionSubFields}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  disabled={!isIssueEstimateOptionAddable}
                  aria-label="addGifteeBoxIssueEstimateOption"
                  data-cy="gifteeBoxIssueEstimateOptionAddButton"
                  onClick={event => onClickAddIssueEstimateOption()}
                >
                  単価と見込み数量を追加
                  <AddIcon />
                </Button>
              </Grid>
              <Grid
                container
                direction="column"
                className={classes.issueEstimateOptionSubFields}
                data-cy="gifteeBoxDeliveryConfigApiTableIssueEstimateOptionNote"
              >
                {selectedGifteeBoxTemplate && (
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    display="block"
                  >{`※単価は${getLocalString(
                    selectedGifteeBoxTemplate.supportedPoint.minPoint
                  )}円〜${getLocalString(
                    selectedGifteeBoxTemplate.supportedPoint.maxPoint
                  )}円の範囲で${getLocalString(
                    selectedGifteeBoxTemplate.supportedPoint.step
                  )}円単位で入力いただけます。`}</Typography>
                )}
                <Typography
                  variant="caption"
                  color="textSecondary"
                  display="block"
                >
                  ※単価はAPIリクエスト時に変更可能です。ご発注目安の確認のため、現時点での利用予定の券種および数量（見込み）をご入力ください。
                </Typography>
              </Grid>
            </TableCell>
          </TableRow>
        </TableBody>
        <GifteeBoxTemplateContentSelectionsModalContainer
          isOpen={gifteeBoxTemplateContentSelectionsModalOpen}
          handleClose={closeGifteeBoxTemplateContentSelectionsModal}
        />
      </Table>
    </>
  );
};

export default withStyles(styles)(ApiTable);
