import { initializeBulkGiftCardIssueConfigsNew } from '@console/actions/actions';
import New from '@console/components/project/giftCardConfigs/bulkGiftCardIssueConfigs/New';
import { getSelectedGiftCardConfig } from '@console/selectors/projectDetail/giftCardConfigs';
import { connect } from 'react-redux';
import type { AppThunkDispatch } from '@console/actions/thunkType';
import type { AppState } from '@console/reducers';

const mapStateToProps = (state: AppState) => ({
  giftCardConfig: getSelectedGiftCardConfig(state),
  errors: state.errors
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  onRenderNew: (giftCardConfigUrlCode: UrlCode) =>
    dispatch(initializeBulkGiftCardIssueConfigsNew(giftCardConfigUrlCode))
});

export type NewPropsMappedFromState = ReturnType<typeof mapStateToProps>;
export type NewPropsMappedFromDispatch = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(New);
