import { getLocalString } from '@common/lib/getLocalString';
import { getLocalePrice } from '@common/lib/getLocalePrice';
import GifteeBoxTemplateBrief from '@console/components/common/GifteeBoxTemplateBrief';
import GifteeBoxTemplateContentSelectionsModalContainer from '@console/containers/common/GifteeBoxTemplateContentSelectionsModalContainer';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { useState } from 'react';
import type {
  DisplaySelectedGifteeBoxConfigForBulkProjectMappedFromState,
  DisplaySelectedGifteeBoxConfigForBulkProjectMappedFromDispatch
} from '@console/containers/project/gifteeBoxConfigs/exchangeReport/DisplaySelectedGifteeBoxConfigForBulkProjectContainer';
import type { LocalGifteeBoxConfig } from '@console/reducers/projects';
import type { Theme } from '@mui/material';
import type { StyleRules, WithStyles } from '@mui/styles';

const styles = (theme: Theme): StyleRules => ({
  tableContainer: {
    overflowX: 'scroll',
    whiteSpace: 'nowrap'
  },
  tableHeadCell: {
    textAlign: 'left'
  },
  contentTableCell: {
    width: '40%',
    minWidth: 400
  },
  contentTableCellContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center'
  },
  tableCell: {
    width: '15%',
    minWidth: 100
  }
});

type DisplaySelectedGifteeBoxConfigForBulkProjectProps =
  DisplaySelectedGifteeBoxConfigForBulkProjectMappedFromState &
    DisplaySelectedGifteeBoxConfigForBulkProjectMappedFromDispatch &
    WithStyles<typeof styles>;

const DisplaySelectedGifteeBoxConfigForBulkProject: React.FC<
  DisplaySelectedGifteeBoxConfigForBulkProjectProps
> = ({
  selectedGifteeBoxConfig,
  exchangeableEndLabel,
  selectGifteeBoxTemplate,
  deselectGifteeBoxTemplate,
  classes
}) => {
  const [
    gifteeBoxTemplateContentSelectionsModalOpen,
    setGifteeBoxTemplateContentSelectionsModalOpen
  ] = useState(false);

  const openGifteeBoxTemplateContentSelectionsModal = (
    gifteeBoxTemplate: LocalGifteeBoxConfig['gifteeBoxTemplate']
  ) => {
    selectGifteeBoxTemplate(gifteeBoxTemplate);
    setGifteeBoxTemplateContentSelectionsModalOpen(true);
  };

  const closeGifteeBoxTemplateContentSelectionsModal = () => {
    setGifteeBoxTemplateContentSelectionsModalOpen(false);
    deselectGifteeBoxTemplate();
  };

  return (
    <>
      <div className={classes.tableContainer}>
        <Table>
          <TableHead data-cy="displaySelectedGifteeBoxConfigForBulkProject-tableHeader">
            <TableRow>
              <TableCell>
                <Typography>ギフト名</Typography>
              </TableCell>
              <TableCell className={classes.tableHeadCell}>
                <Typography>単価</Typography>
              </TableCell>
              <TableCell className={classes.tableHeadCell}>
                <Typography>確定数量</Typography>
              </TableCell>
              <TableCell className={classes.tableHeadCell}>
                <Typography>納品希望日</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedGifteeBoxConfig?.bulkGifteeBoxIssueConfigs.map(
              (bulkGifteeBoxIssueConfig, bulkGifteeBoxIssueConfigIndex) => (
                <TableRow
                  key={`${bulkGifteeBoxIssueConfigIndex}`}
                  data-cy="displaySelectedGifteeBoxConfigForBulkProject-tableRow"
                >
                  {bulkGifteeBoxIssueConfigIndex === 0 && (
                    <TableCell
                      rowSpan={
                        selectedGifteeBoxConfig.bulkGifteeBoxIssueConfigs.length
                      }
                      className={classes.contentTableCell}
                      data-cy="displaySelectedGifteeBoxConfigForBulkProject-tableCell-gifteeBoxTemplateBrief"
                    >
                      <GifteeBoxTemplateBrief
                        gifteeBoxTemplateName={
                          selectedGifteeBoxConfig.gifteeBoxTemplate.name
                        }
                        gifteeBoxTemplateImageUrl={
                          selectedGifteeBoxConfig.gifteeBoxTemplate.imageUrl
                        }
                        handleButtonClick={() =>
                          openGifteeBoxTemplateContentSelectionsModal(
                            selectedGifteeBoxConfig.gifteeBoxTemplate!
                          )
                        }
                        exchangeableEndLabel={exchangeableEndLabel}
                      />
                    </TableCell>
                  )}
                  <TableCell
                    className={classes.tableCell}
                    data-cy="displaySelectedGifteeBoxConfigForBulkProject-tableCell-price"
                  >
                    <Typography key={bulkGifteeBoxIssueConfigIndex}>
                      {getLocalePrice(bulkGifteeBoxIssueConfig.initialPoint)}
                    </Typography>
                  </TableCell>

                  <TableCell
                    className={classes.tableCell}
                    data-cy="displaySelectedGifteeBoxConfigForBulkProject-tableCell-fixedIssueConfig"
                  >
                    {!!bulkGifteeBoxIssueConfig.fixedIssueConfig
                      ?.issueAmount ? (
                      <Typography className={classes.tableCellBody}>
                        {getLocalString(
                          bulkGifteeBoxIssueConfig.fixedIssueConfig!.issueAmount
                        )}
                      </Typography>
                    ) : (
                      <Typography className={classes.tableCellBody}>
                        未確定
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    data-cy="displaySelectedGifteeBoxConfigForBulkProject-tableCell-deliveryDate"
                  >
                    <Typography key={bulkGifteeBoxIssueConfigIndex}>
                      {bulkGifteeBoxIssueConfig.deliveryDate}
                    </Typography>
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </div>
      <GifteeBoxTemplateContentSelectionsModalContainer
        isOpen={gifteeBoxTemplateContentSelectionsModalOpen}
        handleClose={closeGifteeBoxTemplateContentSelectionsModal}
      />
    </>
  );
};
export default withStyles(styles)(DisplaySelectedGifteeBoxConfigForBulkProject);
