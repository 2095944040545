import ComponentLoader from '@common/components/ComponentLoader';
import IssueReportsIssueDateRangePickerContainer from '@console/containers/project/apiIssueReports/IssueDateRangePickerContainer';
import { Typography, Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import IssueReportsTable from './apiIssueReports/Table';
import type {
  IssueReportsPropsMappedFromState,
  IssueReportsPropsMappedFromDispatch
} from '@console/containers/project/APIIssueReportsContainer';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => ({
  section: {
    paddingTop: theme.spacing(4)
  },
  filter: {
    marginBottom: theme.spacing(3)
  },
  table: {
    overflowX: 'scroll',
    whiteSpace: 'nowrap'
  }
});

type APIIssueReportsProps = IssueReportsPropsMappedFromState &
  IssueReportsPropsMappedFromDispatch &
  WithStyles<typeof styles>;

const APIIssueReports: React.FC<APIIssueReportsProps> = ({
  giftCardIssueReports,
  gifteeBoxIssueReports,
  loading,
  classes
}) => (
  <>
    <section className={classes.section}>
      <Typography variant="h6" color="textPrimary">
        発行実績
      </Typography>
    </section>
    <section className={classes.section}>
      <Grid container className={classes.filter}>
        <Grid item>
          <IssueReportsIssueDateRangePickerContainer />
        </Grid>
      </Grid>
      {loading ? (
        <ComponentLoader isLoading={loading} />
      ) : (
        <Grid container className={classes.table}>
          <Grid item>
            <IssueReportsTable
              giftCardIssueReports={giftCardIssueReports}
              gifteeBoxIssueReports={gifteeBoxIssueReports}
            />
          </Grid>
        </Grid>
      )}
    </section>
  </>
);

export default withStyles(styles)(APIIssueReports);
