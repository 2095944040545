// 絵文字が文字列に含まれているか判定するロジックは、対象の文字列がサロゲート文字であるかで判断します
// 一部の漢字（３０２文字）については、サロゲート文字に含まれてしまいますが、ほぼ利用されることのない漢字なので、一旦無視します
// https://www.magata.net/memo/index.php?%A5%B5%A5%ED%A5%B2%A1%BC%A5%C8%A5%DA%A5%A2

// なお、サロゲート文字の判定は、サロゲート文字のみ、encode すると 4 個のエスケープシーケンスになることを利用します
// https://developer.mozilla.org/ja/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent

export default (str): boolean => {
  if (str.length === 0) return false;
  return str
    .match(/./gu)
    .some(
      (char, i) => encodeURIComponent(char).replace(/%../g, 'x').length === 4
    );
};
