import HighlightOff from '@mui/icons-material/HighlightOff';
import { ImageListItemBar, IconButton } from '@mui/material';
import { grey } from '@mui/material/colors';
import withStyles from '@mui/styles/withStyles';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => ({
  imgPreview: {
    height: '100%',
    left: '50%',
    position: 'relative',
    transform: 'translateX(-50%)'
  },
  deleteIcon: {
    margin: theme.spacing(1)
  }
});

type ImageFilePreviewOwnProps = {
  attachedFile: File;
  removeAttachedFile;
};

type ImageFilePreviewProps = ImageFilePreviewOwnProps &
  WithStyles<typeof styles>;

const ImageFilePreview: React.FC<ImageFilePreviewProps> = ({
  classes,
  attachedFile,
  removeAttachedFile
}) => (
  <>
    <img
      src={URL.createObjectURL(attachedFile)}
      className={classes.imgPreview}
    />
    <ImageListItemBar
      actionIcon={
        <IconButton
          className={classes.deleteIcon}
          onClick={removeAttachedFile}
          data-cy="removeAttachedFile"
          size="large"
        >
          <HighlightOff style={{ color: grey[50] }} />
        </IconButton>
      }
      title={attachedFile.name}
      position="top"
    ></ImageListItemBar>
  </>
);

export default withStyles(styles)(ImageFilePreview);
