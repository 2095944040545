import { requestBulkDeliverableUrl } from '../../../../common/api/graphql/requestBulkDeliverableUrlGql';
import {
  requestBulkDeliverableUrlStart,
  requestBulkDeliverableUrlSuccess,
  requestBulkDeliverableUrlFailure
} from '../actions';
import handleApiErrorIfNeeded from './handleApiErrorIfNeeded';
import type {
  RequestBulkDeliverableUrlResponse,
  DownloadUrl
} from '../../../../common/api/graphql/requestBulkDeliverableUrlGql';
import type { AppThunkAction } from '../thunkType';
import type { ApiError } from '@common/api/graphql/apolloClient';

const requestBulkDeliverableUrlSuccessThunk =
  (
    response: RequestBulkDeliverableUrlResponse
  ): AppThunkAction<Promise<string>> =>
  dispatch => {
    const { downloadUrl } = response.data!.requestBulkDeliverableUrl;
    dispatch(requestBulkDeliverableUrlSuccess());
    return Promise.resolve(downloadUrl);
  };

export const requestBulkDeliverableUrlFailureThunk =
  (error: ApiError): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(requestBulkDeliverableUrlFailure());
    return dispatch(handleApiErrorIfNeeded(error));
  };

export const requestBulkDeliverableUrlThunk =
  (
    urlCode: string,
    downloadType: string
  ): AppThunkAction<Promise<DownloadUrl | void>> =>
  (dispatch, getState) => {
    dispatch(requestBulkDeliverableUrlStart());

    return requestBulkDeliverableUrl(urlCode, downloadType)
      .then((response: RequestBulkDeliverableUrlResponse) =>
        dispatch(requestBulkDeliverableUrlSuccessThunk(response))
      )
      .catch(error => dispatch(requestBulkDeliverableUrlFailureThunk(error)));
  };
