import { connect } from 'react-redux';
import { AppThunkAction } from '../../actions/thunkType';
import DistributionPartnerUser from '../../components/account/DistributionPartnerUser';
import type { AppThunkDispatch } from '../../actions/thunkType';
import type { AppState } from '../../reducers';

const mapStateToProps = (state: AppState) => ({
  distributionPartnerUser: state.distributionPartnerUser
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({});

export type DistributionPartnerUserPropsMappedFromState = ReturnType<
  typeof mapStateToProps
>;
export type DistributionPartnerUserPropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DistributionPartnerUser);
