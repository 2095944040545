import { getLocalePrice } from '@common/lib/getLocalePrice';
import {
  Typography,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Button,
  Chip
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import type {
  TablePropsMappedFromState,
  TablePropsMappedFromDispatch
} from '@console/containers/project/billing/TableContainer';
import type { LocalProject } from '@console/reducers/projects';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => ({
  tableContainer: {
    overflowX: 'scroll',
    whiteSpace: 'nowrap'
  },
  table: {
    textAlign: 'left',
    marginTop: theme.spacing(0.5)
  },
  tableCell: {
    padding: theme.spacing(2),
    verticalAlign: 'middle'
  },
  claimTitle: {
    whiteSpace: 'pre-line',
    width: 360
  },
  chip: {
    borderRadius: 5
  },
  sucesssIcon: {
    backgroundColor: theme.palette.success.main
  }
});

type BillingTableOwnProps = { claims: LocalProject['claims'] };
type BillingTableProps = TablePropsMappedFromState &
  TablePropsMappedFromDispatch &
  BillingTableOwnProps &
  WithStyles<typeof styles>;

const BillingTable: React.FC<BillingTableProps> = ({
  classes,
  claims,
  requestInvoicePdfUrl
}) => (
  <div className={classes.tableContainer} data-cy="tableContainer">
    <Table className={classes.table}>
      <TableHead data-cy="billingTableHeader">
        <TableRow>
          <TableCell className={classes.tableCell}>ID</TableCell>
          <TableCell className={classes.tableCell}>請求日</TableCell>
          <TableCell className={classes.tableCell}>支払い期日</TableCell>
          <TableCell className={classes.tableCell}>ご請求金額</TableCell>
          <TableCell className={classes.tableCell}>ステータス</TableCell>
          <TableCell className={classes.tableCell} align="center"></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {claims.map((claim, claimIndex) => (
          <TableRow key={claimIndex}>
            <TableCell className={classes.tableCell}>
              <Typography
                variant="body2"
                className={classes.claimTitle}
                data-cy={`claimIdentity-${claimIndex}`}
              >
                {claim.invoiceIdentity}
              </Typography>
            </TableCell>
            <TableCell className={classes.tableCell}>
              <Typography
                variant="body2"
                className={classes.content}
                data-cy={`claimBillingDate-${claimIndex}`}
              >
                {new Date(claim.billingDate).toLocaleDateString('ja-JP')}
              </Typography>
            </TableCell>
            <TableCell className={classes.tableCell}>
              <Typography
                variant="body2"
                className={classes.content}
                data-cy={`claimPaymentDueDate-${claimIndex}`}
              >
                {new Date(claim.paymentDueDate).toLocaleDateString('ja-JP')}
              </Typography>
            </TableCell>
            <TableCell className={classes.tableCell}>
              <Typography
                variant="body2"
                className={classes.content}
                data-cy={`claimTotalPaymentAmount-${claimIndex}`}
              >
                {getLocalePrice(claim.totalPaymentAmount)}
              </Typography>
            </TableCell>
            <TableCell className={classes.tableCell}>
              <Chip
                label={claim.paid ? '入金済' : '未入金'}
                className={clsx(classes.chip, {
                  [classes.sucesssIcon]: claim.paid
                })}
                data-cy={`claimPaid-${claimIndex}`}
              />
            </TableCell>
            <TableCell className={classes.tableCell} align="center">
              <Button
                color="primary"
                variant="contained"
                className={classes.button}
                data-cy={`claimDownloadButton-${claimIndex}`}
                onClick={() => requestInvoicePdfUrl(claim.invoiceUrlCode)}
              >
                <Typography variant="body2">ダウンロード</Typography>
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </div>
);

export default withStyles(styles)(BillingTable);
