import { AppActionTypes } from '../actions/actionType';
import type { AppAction } from '../actions/actionType';
import type { Reducer } from 'redux';

const selectedProjectGiftConfigIndex = (
  state = 0,
  action: AppAction
): number => {
  switch (action.type) {
    default:
      return state;
  }
};

const selectedProjectBulkGiftIssueConfigIndex = (
  state = 0,
  action: AppAction
): number => {
  switch (action.type) {
    default:
      return state;
  }
};

const selectedProjectBulkGiftCardIssueConfigIndex = (
  state = 0,
  action: AppAction
): number => {
  switch (action.type) {
    case AppActionTypes.UPDATE_SELECTED_PROJECT_BULK_GIFT_CARD_ISSUE_CONFIG:
      return action.bulkGiftCardIssueConfigIndex;
    case AppActionTypes.CLOSE_PROJECT_GIFT_CONFIG_BULK_TABLE:
      return 0;
    default:
      return state;
  }
};

export const selectedGiftCardConfigUrlCode: Reducer<
  UrlCode | null,
  AppAction
> = (state = null, action) => {
  switch (action.type) {
    case AppActionTypes.UPDATE_SELECTED_PROJECT_BULK_GIFT_CARD_ISSUE_CONFIG:
    case AppActionTypes.OPEN_PROJECT_GIFT_CARD_PREVIEW:
    case AppActionTypes.INITIALIZE_BULK_GIFT_CARD_ISSUE_CONFIGS_NEW:
      return action.giftCardConfigUrlCode;
    case AppActionTypes.CLOSE_PROJECT_GIFT_CONFIG_BULK_TABLE:
      return null;
    default:
      return state;
  }
};

const selectedProjectGifteeBoxConfigUrlCode: Reducer<
  UrlCode | null,
  AppAction
> = (state = null, action) => {
  switch (action.type) {
    case AppActionTypes.SELECT_BULK_GIFTEE_BOX_ISSUE_CONFIG:
      return action.gifteeBoxConfigUrlCode;
    case AppActionTypes.INITIALIZE_BULK_GIFTEE_BOX_ISSUE_CONFIGS_NEW:
      return action.gifteeBoxConfigUrlCode;
    case AppActionTypes.MOUNT_EXCHANGE_REPORT:
      return action.gifteeBoxConfigUrlCode;
    default:
      return state;
  }
};

const selectedProjectBulkGifteeBoxIssueConfigUrlCode: Reducer<
  UrlCode | null,
  AppAction
> = (state = null, action) => {
  switch (action.type) {
    case AppActionTypes.SELECT_BULK_GIFTEE_BOX_ISSUE_CONFIG:
      return action.bulkGifteeBoxIssueConfigUrlCode;
    default:
      return state;
  }
};

const selectedProjectGiftConfigReducer = {
  selectedProjectGiftConfigIndex,
  selectedProjectBulkGiftIssueConfigIndex,
  selectedProjectBulkGiftCardIssueConfigIndex,
  selectedGiftCardConfigUrlCode,
  selectedProjectGifteeBoxConfigUrlCode,
  selectedProjectBulkGifteeBoxIssueConfigUrlCode
};

export default selectedProjectGiftConfigReducer;
