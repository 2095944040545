import { combineReducers } from 'redux';
import { AppActionTypes } from '../actions/actionType';
import type { AppAction } from '../actions/actionType';
import type { GifteeBoxTemplateBrands } from '@common/api/graphql/getGifteeBoxTemplateBrandsGql';
import type {
  GifteeBoxTemplateContentSelectionsNode,
  GifteeBoxTemplateContentSelectionsConnection
} from '@common/api/graphql/getGifteeBoxTemplateContentSelectionsGql';
import type { GifteeBoxTemplate } from '@common/api/graphql/getGifteeBoxTemplatesGql';

const urlCode = (
  state = null,
  action: AppAction
): GifteeBoxTemplate['urlCode'] | null => {
  switch (action.type) {
    case AppActionTypes.SELECT_GIFTEE_BOX_TEMPLATE_FOR_DRAFT_PROJECT:
      return action.gifteeBoxTemplate?.urlCode ?? null;
    case AppActionTypes.SELECT_GIFTEE_BOX_TEMPLATE:
      return action.gifteeBoxTemplate.urlCode;
    case AppActionTypes.DESELECT_GIFTEE_BOX_TEMPLATE:
      return null;
    default:
      return state;
  }
};

const name = (
  state = null,
  action: AppAction
): GifteeBoxTemplate['name'] | null => {
  switch (action.type) {
    case AppActionTypes.SELECT_GIFTEE_BOX_TEMPLATE_FOR_DRAFT_PROJECT:
      return action.gifteeBoxTemplate?.name ?? null;
    case AppActionTypes.SELECT_GIFTEE_BOX_TEMPLATE:
      return action.gifteeBoxTemplate.name;
    case AppActionTypes.DESELECT_GIFTEE_BOX_TEMPLATE:
      return null;
    default:
      return state;
  }
};

const imageUrl = (
  state = null,
  action: AppAction
): GifteeBoxTemplate['imageUrl'] | null => {
  switch (action.type) {
    case AppActionTypes.SELECT_GIFTEE_BOX_TEMPLATE_FOR_DRAFT_PROJECT:
      return action.gifteeBoxTemplate?.imageUrl ?? null;
    case AppActionTypes.SELECT_GIFTEE_BOX_TEMPLATE:
      return action.gifteeBoxTemplate.imageUrl;
    case AppActionTypes.DESELECT_GIFTEE_BOX_TEMPLATE:
      return null;
    default:
      return state;
  }
};

const contentSelections = (
  state: GifteeBoxTemplateContentSelectionsNode[] | null = null,
  action: AppAction
): GifteeBoxTemplateContentSelectionsNode[] | null => {
  switch (action.type) {
    case AppActionTypes.DESELECT_GIFTEE_BOX_TEMPLATE:
      return null;
    case AppActionTypes.GET_GIFTEE_BOX_TEMPLATE_INITIAL_CONTENT_SELECTIONS_SUCCESS:
      if (!action.contentSelectionsConnection.nodes) return null;
      return filterNodes(action.contentSelectionsConnection.nodes);
    case AppActionTypes.GET_GIFTEE_BOX_TEMPLATE_CONTINUOUS_CONTENT_SELECTIONS_SUCCESS:
      if (!action.contentSelectionsConnection.nodes) return state;
      const filteredNodes = filterNodes(
        action.contentSelectionsConnection.nodes
      );

      if (state === null) {
        return filteredNodes;
      } else {
        return [...state, ...filteredNodes];
      }
    default:
      return state;
  }
};

const filterNodes = (
  contentSelectionsNodes: NonNullable<
    GifteeBoxTemplateContentSelectionsConnection['nodes']
  >
): GifteeBoxTemplateContentSelectionsNode[] =>
  contentSelectionsNodes.filter(
    (node): node is GifteeBoxTemplateContentSelectionsNode => !!node
  );

const brands = (
  state: GifteeBoxTemplateBrands | null = null,
  action: AppAction
): GifteeBoxTemplateBrands | null => {
  switch (action.type) {
    case AppActionTypes.DESELECT_GIFTEE_BOX_TEMPLATE:
      return null;
    case AppActionTypes.GET_GIFTEE_BOX_TEMPLATE_BRANDS_SUCCESS:
      return action.brands;
    default:
      return state;
  }
};

export const selectedGifteeBoxTemplate = combineReducers({
  urlCode,
  name,
  imageUrl,
  contentSelections,
  brands
});
