import { QueryGiftCardTicketConfig } from '@common/api/graphql/getProjectsGql';
import {
  isGiftCardFixedTicketConfig,
  isGiftCardChoosableTicketConfig
} from '@console/common/project/giftCardTicketConfig';
import ContentRow from '@console/components/common/ContentRow';
import GiftCardTicketConfigContents from '@console/components/project/common/GiftCardTicketConfigContents';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { useCallback, useState } from 'react';
import type { QueryGiftCardConfig } from '@common/api/graphql/getProjectsGql';
import type { Theme } from '@mui/material';

import type { StyleRules, WithStyles } from '@mui/styles';

const styles = (theme: Theme): StyleRules => ({
  accordion: {
    boxShadow: 'none'
  },
  accordionSummary: {
    marginBottom: 0
  },
  accordionDetails: {
    display: 'block',
    paddingTop: 0
  }
});

type GiftCardTicketConfigContentsOwnProps = {
  giftCardConfig: QueryGiftCardConfig;
  imageDownloadable?: boolean;
};

type GiftCardTicketConfigContentsProps = GiftCardTicketConfigContentsOwnProps &
  WithStyles<typeof styles>;

const GiftCardTicketConfigContentsAccordion: React.FC<
  GiftCardTicketConfigContentsProps
> = ({ giftCardConfig, imageDownloadable, classes }) => {
  const [firstGiftCardTicketConfig, ...giftCardTicketConfigRemains] =
    giftCardConfig.giftCardTicketConfigs;

  const [isAccordionExpanded, setIsAccordionExpanded] = useState(false);

  const toggleAccordion = useCallback(() => {
    setIsAccordionExpanded(!isAccordionExpanded);
  }, [isAccordionExpanded]);

  const getAccordionSummary = () => {
    if (isAccordionExpanded) {
      return (
        <GiftCardTicketConfigContents
          giftCardTicketConfig={firstGiftCardTicketConfig}
          imageDownloadable={imageDownloadable}
        />
      );
    }
    return getGiftCardTicketConfigFirstContent();
  };

  const getGiftCardTicketConfigFirstContent = () => {
    if (isGiftCardFixedTicketConfig(firstGiftCardTicketConfig)) {
      return (
        <GiftCardTicketConfigContents
          giftCardTicketConfig={firstGiftCardTicketConfig}
          imageDownloadable={imageDownloadable}
        />
      );
    } else if (isGiftCardChoosableTicketConfig(firstGiftCardTicketConfig)) {
      const firstContent =
        firstGiftCardTicketConfig.choosableContentOption.choices[0].content;
      return (
        <ContentRow
          contentName={firstContent.name}
          contentInternalName={firstContent.internalName}
          contentImageUrl={firstContent.imageUrl}
          contentBrandName={firstContent.brand.name}
          imageDownloadUrl={firstContent.imageUrl}
        />
      );
    }
  };

  if (firstGiftCardTicketConfig && giftCardTicketConfigRemains.length) {
    return (
      <Accordion
        square
        className={classes.accordion}
        onChange={toggleAccordion}
        data-cy={`giftCardTicketConfigContentsAccordion-${giftCardConfig.urlCode}`}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon color="primary" />}
          className={classes.accordionSummary}
          data-cy={`giftCardTicketConfigContentsAccordionSummary-${giftCardConfig.urlCode}`}
        >
          {getAccordionSummary()}
        </AccordionSummary>
        <AccordionDetails
          className={classes.accordionDetails}
          data-cy={`giftCardTicketConfigContentsAccordionDetails-${giftCardConfig.urlCode}`}
        >
          {giftCardTicketConfigRemains.map(
            (giftCardTicketConfig, giftCardTicketConfigIndex) => (
              <div
                key={`giftCardTicketConfigContentsAccordionDetail-${giftCardConfig.urlCode}-${giftCardTicketConfigIndex}`}
                data-cy={`giftCardTicketConfigContentsAccordionDetail-${giftCardConfig.urlCode}-${giftCardTicketConfigIndex}`}
              >
                <Divider />
                <GiftCardTicketConfigContents
                  giftCardTicketConfig={giftCardTicketConfig}
                  imageDownloadable={imageDownloadable}
                />
              </div>
            )
          )}
        </AccordionDetails>
      </Accordion>
    );
  } else if (firstGiftCardTicketConfig && !giftCardTicketConfigRemains.length) {
    return (
      <div data-cy={`giftCardTicketConfigContents-${giftCardConfig.urlCode}`}>
        <GiftCardTicketConfigContents
          giftCardTicketConfig={firstGiftCardTicketConfig}
          imageDownloadable={imageDownloadable}
        />
      </div>
    );
  } else {
    return null;
  }
};

export default withStyles(styles)(GiftCardTicketConfigContentsAccordion);
