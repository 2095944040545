import { gql } from '@apollo/client';

import { internalApiAppClient } from './apolloClient';
import { operationNames } from './operationNames';
import type { QueryApiAllowedIp } from './getProjectsGql';
import type { MutationErrors } from './mutationErrorType';
import type { FetchResult } from '@apollo/client';

const addApiAllowedIpGql = gql`
  mutation ${operationNames.ADD_API_ALLOWED_IP}($projectUrlCode: String!, $ip: String!) {
    addApiAllowedIp(input: { projectUrlCode: $projectUrlCode, ip: $ip }) {
      apiAllowedIp {
        ip
        urlCode
      }
      errors {
        __typename
        message
        path
      }
    }
  }
`;

export type AddApiAllowedIpResponse = FetchResult<{
  addApiAllowedIp: {
    apiAllowedIp: QueryApiAllowedIp;
    errors: MutationErrors;
  };
}>;

export const addApiAllowedIp = (
  projectUrlCode: UrlCode,
  ip: QueryApiAllowedIp['ip']
): Promise<AddApiAllowedIpResponse> =>
  internalApiAppClient.mutate({
    mutation: addApiAllowedIpGql,
    variables: {
      projectUrlCode,
      ip
    }
  });
