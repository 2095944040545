import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import withStyles from '@mui/styles/withStyles';
import type { Header } from '../api/graphql/getGiftCardGql';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const LOGO_HEIGHT = 30;

const styles = (theme: Theme): StyleRules => ({
  appBar: {
    boxSizing: 'content-box',
    height: 60,
    borderBottom: 'solid 1px #EDEDED'
  },
  container: {
    height: '100%'
  },
  headerImage: {
    height: LOGO_HEIGHT,
    margin: LOGO_HEIGHT / 2
  }
});

interface GiftCardHeaderOwnProps {
  header: Header;
}

type GiftCardHeaderProps = GiftCardHeaderOwnProps & WithStyles<typeof styles>;

const GiftCardHeader: React.FC<GiftCardHeaderProps> = ({ header, classes }) => (
  <AppBar position="static" className={classes.appBar}>
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={classes.container}
    >
      <img
        src={header.imageUrl}
        className={classes.headerImage}
        data-cy="headerImage"
      />
    </Grid>
  </AppBar>
);

export default withStyles(styles)(GiftCardHeader);
