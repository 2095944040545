import { ERROR_MESSAGE_FOR_EXCEED_CREDIT_LIMIT_IN_REQUEST_ISSUE_AMOUNT } from '@common/middleware/errorMessages';
import { getCreditLimitBalance } from '@console/selectors/distributionPartner';
import type { AppThunkAction } from '../thunkType';
import type { GiftCardConfigForDisplay } from '@console/selectors/projectDetail/giftCardConfigs';

export const validateRequestGiftCardIssueAmountThunk =
  (
    giftCardConfig: GiftCardConfigForDisplay,
    bulkGiftCardIssueConfigIndex: number
  ): AppThunkAction<Promise<void>> =>
  (dispatch, getState) => {
    const creditLimitBalance = getCreditLimitBalance(getState());

    const unitPrice = giftCardConfig.unitPrice;
    const bulkGiftCardIssueConfig =
      giftCardConfig.bulkGiftCardIssueConfigs[bulkGiftCardIssueConfigIndex];
    const issueAmount = bulkGiftCardIssueConfig.issueAmountRequest.issueAmount;
    const bulkGiftCardIssueConfigAmount = unitPrice * issueAmount;

    if (
      isExceedCreditLimit(bulkGiftCardIssueConfigAmount, creditLimitBalance)
    ) {
      return Promise.reject(
        ERROR_MESSAGE_FOR_EXCEED_CREDIT_LIMIT_IN_REQUEST_ISSUE_AMOUNT
      );
    }

    return Promise.resolve();
  };

const isExceedCreditLimit = (
  bulkGiftCardIssueConfigAmount: number,
  creditLimitBalance: number | null
): boolean => {
  if (creditLimitBalance === null) return false;

  return creditLimitBalance < bulkGiftCardIssueConfigAmount;
};
