import {
  addMessageThreadAttachedFiles,
  updateMessageThreadInputMessages
} from '@console/actions/actions';
import { getMessageThreadThunk } from '@console/actions/thunks/getMessageThread';
import { handleMessageThreadScroll } from '@console/actions/thunks/handleMessageThreadScroll';
import { postMessageThreadMessageThunk } from '@console/actions/thunks/postMessageThreadMessage';
import { subscribeMessageThreadPostedMessageThunk } from '@console/actions/thunks/subscribeMessageThreadPostedMessage';
import MessageThreadMessages from '@console/components/project/MessageThreadMessages';
import { getSelectedProjectDetail } from '@console/selectors/projectDetail';
import {
  getMessageThread as getMessageThreadOfProjectDetail,
  getMessageThreadInputMessage
} from '@console/selectors/projectDetail/messageThread';
import { connect } from 'react-redux';
import type {
  Node,
  MessageThread,
  MessageThreadUrlCode
} from '@common/api/graphql/getMessageThread';
import type { TextContent } from '@common/api/graphql/postMessageThreadMessage';
import type {
  AppThunkDispatch,
  AppThunkAction
} from '@console/actions/thunkType';
import type { AppState } from '@console/reducers';

const getMessageThread =
  (
    messageThreadUrlCode: string,
    scrollTargetNodeRequestCode: Node['requestCode']
  ): AppThunkAction<void> =>
  dispatch => {
    dispatch(getMessageThreadThunk(messageThreadUrlCode))
      .then(onGetMessageThreadThunkSuccess(scrollTargetNodeRequestCode))
      .catch(() => {});
  };

const onGetMessageThreadThunkSuccess =
  (scrollTargetNodeRequestCode: Node['requestCode']) =>
  (messageThread: MessageThread) => {
    if (
      isExistsRequestCodeInMessageThread(
        messageThread,
        scrollTargetNodeRequestCode
      )
    ) {
      handleMessageThreadScroll(scrollTargetNodeRequestCode);
    } else {
      const { nodes } = messageThread.messages;
      const lastMessageRequestCode = nodes[nodes.length - 1].requestCode;
      handleMessageThreadScroll(lastMessageRequestCode);
    }
  };

const isExistsRequestCodeInMessageThread = (
  messageThread: MessageThread,
  requestCode: Node['requestCode']
): boolean => {
  const { nodes } = messageThread.messages;
  return nodes.some(node => node.requestCode === requestCode);
};
const subscribeMessageThreadPostedMessage =
  (): AppThunkAction<void> => (dispatch, getState) => {
    const selectedMessageThreadUrlCode =
      getMessageThreadOfProjectDetail(getState())?.urlCode ?? '';
    if (selectedMessageThreadUrlCode !== '') {
      dispatch(
        subscribeMessageThreadPostedMessageThunk(selectedMessageThreadUrlCode)
      );
    }
  };

const handleChangeMessageThreadInputMessage =
  (event: React.ChangeEvent<HTMLInputElement>): AppThunkAction<void> =>
  (dispatch, getState) => {
    const selectedMessageThreadUrlCode =
      getMessageThreadOfProjectDetail(getState())?.urlCode ?? '';
    dispatch(
      updateMessageThreadInputMessages(
        selectedMessageThreadUrlCode,
        event.target.value
      )
    );
  };

const attachFile =
  (event: React.ChangeEvent<HTMLInputElement>): AppThunkAction<void> =>
  dispatch => {
    if (event.target.files) {
      const files: File[] = [];

      for (let i = 0; i < event.target.files.length; i += 1) {
        files.push(event.target.files[i]);
      }

      dispatch(addMessageThreadAttachedFiles(files));
    }
    event.target.value = '';
  };

const handleSubmitMessage =
  (
    textContent: TextContent,
    messageThreadUrlCode: MessageThreadUrlCode
  ): AppThunkAction<void> =>
  dispatch => {
    dispatch(postMessageThreadMessageThunk(messageThreadUrlCode, textContent))
      .then(handleMessageThreadScroll)
      .catch(() => {});
  };

const handleDropFiles =
  (event: React.DragEvent): AppThunkAction<void> =>
  dispatch => {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files) {
      const files: File[] = [];

      for (let i = 0; i < event.dataTransfer.files.length; i += 1) {
        const attachedFile = event.dataTransfer.files[i];
        const isFile = !!attachedFile.type;
        if (isFile) {
          files.push(attachedFile);
        }
      }

      dispatch(addMessageThreadAttachedFiles(files));
    }
  };

const mapStateToProps = (state: AppState) => ({
  projectName: getSelectedProjectDetail(state).name,
  messageThread: state.messageThread,
  messageThreadUrlCode: getMessageThreadOfProjectDetail(state)?.urlCode ?? '',
  messageThreadInputText: getMessageThreadInputMessage(state),
  messageThreadAttachedFiles: state.messageThreadAttachedFiles,
  isSampleProject: state.projectDetail.isSample
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  getMessageThread: (
    messageThreadUrlCode: string,
    scrollTargetNodeRequestCode: Node['requestCode']
  ) =>
    dispatch(
      getMessageThread(messageThreadUrlCode, scrollTargetNodeRequestCode)
    ),
  subscribeMessageThreadPostedMessage: () =>
    dispatch(subscribeMessageThreadPostedMessage()),
  handleChangeMessageThreadInputMessage: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => dispatch(handleChangeMessageThreadInputMessage(event)),
  handleSubmitMessage: (
    text: TextContent,
    messageThreadUrlCode: MessageThreadUrlCode
  ) => dispatch(handleSubmitMessage(text, messageThreadUrlCode)),
  handleDropFiles: (event: React.DragEvent) => dispatch(handleDropFiles(event)),
  attachFile: (event: React.ChangeEvent<HTMLInputElement>) =>
    dispatch(attachFile(event))
});

export type MessageThreadMessagesPropsMappedFromState = ReturnType<
  typeof mapStateToProps
>;
export type MessageThreadMessagesPropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MessageThreadMessages);
