import {
  openProjectGiftCardPreview,
  selectGifteeBoxTemplate,
  deselectGifteeBoxTemplate
} from '@console/actions/actions';
import GcpTable from '@console/components/project/giftConfig/GcpTable';
import { getGiftCardConfigsForDisplay } from '@console/selectors/projectDetail/giftCardConfigs';
import { getGifteeBoxConfigs } from '@console/selectors/projectDetail/gifteeBoxConfigs';
import { connect } from 'react-redux';
import type { AppThunkDispatch } from '@console/actions/thunkType';
import type { AppState } from '@console/reducers';
import type { LocalGifteeBoxConfig } from '@console/reducers/projects';
import type { GiftCardConfigForDisplay } from '@console/selectors/projectDetail/giftCardConfigs';

const mapStateToProps = (state: AppState) => ({
  giftCardConfigs: getGiftCardConfigsForDisplay(state),
  gifteeBoxConfigs: getGifteeBoxConfigs(state)
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  onClickGiftCardPreviewButton: (giftCardConfig: GiftCardConfigForDisplay) =>
    dispatch(openProjectGiftCardPreview(giftCardConfig.urlCode)),
  selectGifteeBoxTemplate: (
    gifteeBoxTemplate: LocalGifteeBoxConfig['gifteeBoxTemplate']
  ) => dispatch(selectGifteeBoxTemplate(gifteeBoxTemplate)),
  deselectGifteeBoxTemplate: () => dispatch(deselectGifteeBoxTemplate())
});

export type GcpTablePropsMappedFromState = ReturnType<typeof mapStateToProps>;
export type GcpTablePropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(mapStateToProps, mapDispatchToProps)(GcpTable);
