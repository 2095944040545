import { getLocalePrice } from '@common/lib/getLocalePrice';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import type { Content } from '@common/api/graphql/getContentsGql';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';
import type { HTMLAttributes } from 'react';

const styles = (theme: Theme): StyleRules => {
  const CARD_WIDTH = 240;
  const CARD_HEIGHT = 96;
  const CONTENT_WIDTH = CARD_HEIGHT;

  return createStyles({
    card: {
      width: CARD_WIDTH,
      height: CARD_HEIGHT,
      borderRadius: theme.spacing(),
      boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.1)'
    },
    cardActionArea: {
      display: 'flex',
      justifyContent: 'flex-start'
    },
    contentImage: {
      width: CONTENT_WIDTH,
      objectFit: 'contain'
    },
    contentTexts: {
      width: CARD_WIDTH - CONTENT_WIDTH
    },
    noContentCard: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      boxShadow: 'none',
      border: `1px solid ${theme.palette.divider}`
    }
  });
};

type OwnProps = {
  content: Content | null;
  onClickHandler?: (event: React.MouseEvent, ...args: any) => void;
};

type SelectedContentCardProps = Pick<
  HTMLAttributes<HTMLDivElement>,
  'className'
> &
  OwnProps &
  WithStyles<typeof styles>;

const SelectedContentCard: React.FC<SelectedContentCardProps> = ({
  content,
  onClickHandler,
  className,
  classes
}) => {
  if (!!content) {
    return (
      <Card className={clsx(classes.card, className)}>
        <CardActionArea
          className={classes.cardActionArea}
          onClick={onClickHandler}
          data-cy={`selectedContentCard-${content.urlCode}`}
        >
          <img className={classes.contentImage} src={content?.imageUrl} />
          <CardContent className={classes.contentTexts}>
            <Typography variant="body1" color="textPrimary" noWrap={true}>
              {content.internalName}
            </Typography>
            <Typography variant="body2" color="textSecondary" noWrap={true}>
              {content.brand.name}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {getLocalePrice(content.price)}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  }
  return (
    <Card
      className={clsx(classes.card, classes.noContentCard, className)}
      data-cy="selectedContentCard-noContent"
    >
      <Typography variant="body1" color="textSecondary">
        未選択
      </Typography>
    </Card>
  );
};

export default withStyles(styles)(SelectedContentCard);
