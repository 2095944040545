import { AppActionTypes } from '../actions/actionType';
import type { AppAction } from '../actions/actionType';

const messageThreadAttachedFiles = (
  state: File[] = [],
  action: AppAction
): File[] => {
  let newAttachedFiles;
  switch (action.type) {
    case AppActionTypes.ADD_MESSAGE_THREAD_ATTACHED_FILES:
      return state.concat(action.files);
    case AppActionTypes.REMOVE_MESSAGE_THREAD_ATTACHED_FILES:
      newAttachedFiles = [...state];
      newAttachedFiles.splice(action.index, 1);
      return newAttachedFiles;
    case AppActionTypes.REMOVE_MESSAGE_THREAD_ATTACHED_FILES_ALL:
    case AppActionTypes.POST_MESSAGE_THREAD_MESSAGE_SUCCESS:
    case AppActionTypes.POST_MESSAGE_THREAD_MESSAGE_FAILURE:
      return [];
    default:
      return state;
  }
};

export default messageThreadAttachedFiles;
