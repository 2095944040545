import convertFirstLetterToLowercase from '@common/lib/convertFirstLetterToLowercase';
import {
  openDrawer,
  setSelectedProjectUrlCode,
  exitProjectDetail
} from '@console/actions/actions';
import { getProjectDetailThunk } from '@console/actions/thunks/getProjectDetail';
import ProjectLayout from '@console/components/project/ProjectLayout';
import {
  getSelectedProjectDetail,
  getIsMypageProject
} from '@console/selectors/projectDetail';
import { connect } from 'react-redux';
import type { QueryProjectUrlCode } from '@common/api/graphql/getProjectsGql';
import type {
  AppThunkDispatch,
  AppThunkAction
} from '@console/actions/thunkType';
import type { AppState } from '@console/reducers';

const initializeSelectedProject =
  (urlCode: QueryProjectUrlCode): AppThunkAction<Promise<boolean>> =>
  (dispatch, getState) =>
    dispatch(getProjectDetailThunk(urlCode))
      .then(async () => {
        dispatch(setSelectedProjectUrlCode(urlCode));
        return Promise.resolve(getIsMypageProject(getState()));
      })
      .catch(error => Promise.reject(error));

const mapStateToProps = (state: AppState) => ({
  isDrawerOpen: state.isDrawerOpen,
  selectedProjectDetail: getSelectedProjectDetail(state),
  isSampleProject: state.projectDetail.isSample,
  isMypageProject: getIsMypageProject(state)
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  openDrawer: () => dispatch(openDrawer()),
  initializeSelectedProject: (urlCode: QueryProjectUrlCode) =>
    dispatch(initializeSelectedProject(urlCode)),
  exitProjectDetail: () => dispatch(exitProjectDetail())
});

export type ProjectLayoutPropsMappedFromState = ReturnType<
  typeof mapStateToProps
>;
export type ProjectLayoutPropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(mapStateToProps, mapDispatchToProps)(ProjectLayout);
