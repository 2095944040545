import {
  createErrorForGifteeBoxInitialPoint,
  createErrorMessageForEmpty,
  createErrorMessageForIsNumeric
} from '@common/middleware/errorMessages';
import { JP_GIFTEE_BOX_INITIAL_POINT } from '@console/common/constants';
import { isSupportedPoint } from '@console/middleware/validator/common/isSupportedPoint';
import validator from 'validator';
import type { SupportedPoint } from '@common/api/graphql/getGifteeBoxTemplatesGql';
import type { ErrorMessage } from '@console/reducers/errors';

export const getInitialPointValidationErrorMessage = (
  initialPoint: string | null,
  supportedPoint?: SupportedPoint
): string => {
  let errorMessage: ErrorMessage = '';

  if (!supportedPoint) {
    return '';
  } else if (initialPoint === null || initialPoint === '') {
    errorMessage = createErrorMessageForEmpty(JP_GIFTEE_BOX_INITIAL_POINT);
  } else if (!validator.isNumeric(initialPoint, { no_symbols: true })) {
    errorMessage = createErrorMessageForIsNumeric(JP_GIFTEE_BOX_INITIAL_POINT);
  } else if (!isSupportedPoint(Number(initialPoint), supportedPoint)) {
    errorMessage = createErrorForGifteeBoxInitialPoint(
      JP_GIFTEE_BOX_INITIAL_POINT,
      supportedPoint
    );
  }

  return errorMessage;
};
