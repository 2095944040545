import {
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableBody,
  TableRow,
  TableCell
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';
import type { ImgHTMLAttributes } from 'react';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    dialog: {
      wordBreak: 'break-all'
    },
    labelCell: {
      width: 140
    },
    cardImage: {
      width: theme.spacing(25),
      verticalAlign: 'middle'
    }
  });

type OwnProps = {
  isOpen: boolean;
  onCloseHandler: (event: React.MouseEvent, ...args: any) => void;
  imageUrl: ImgHTMLAttributes<HTMLImageElement>['src'] | null;
  description: string | undefined;
};

type MessageCardModalProps = OwnProps & WithStyles<typeof styles>;

const GifteeBoxConfigMessageCardOptionModal: React.FC<
  MessageCardModalProps
> = ({ isOpen, onCloseHandler, imageUrl, description, classes }) => (
  <Dialog
    aria-labelledby="dialog-title"
    aria-describedby="dialog-description"
    open={isOpen}
    fullWidth={true}
    maxWidth="sm"
    onClose={onCloseHandler}
    className={classes.dialog}
    data-cy="messageCardModal"
  >
    <DialogTitle id="dialog-title">メッセージカード詳細</DialogTitle>
    <DialogContent>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell className={classes.labelCell}>カードデザイン</TableCell>
            <TableCell>
              {!!imageUrl ? (
                <img
                  src={imageUrl}
                  className={classes.cardImage}
                  data-cy="cardImage"
                />
              ) : (
                '無し'
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.labelCell}>メッセージ</TableCell>
            <TableCell data-cy="description">{description}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </DialogContent>
  </Dialog>
);

export default withStyles(styles)(GifteeBoxConfigMessageCardOptionModal);
