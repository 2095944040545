import moment from '@common/lib/moment';
import { Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { DesktopDatePicker as DatePicker } from '@mui/x-date-pickers';
import { useEffect } from 'react';
import type {
  IssueReportsIssueDateRangePickerPropsMappedFromDispatch,
  IssueReportsIssueDateRangePickerPropsMappedFromState
} from '@console/containers/project/apiIssueReports/IssueDateRangePickerContainer';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => ({});

type IssueReportsIssueDateRangePickersProps =
  IssueReportsIssueDateRangePickerPropsMappedFromDispatch &
    IssueReportsIssueDateRangePickerPropsMappedFromState &
    WithStyles<typeof styles>;

const IssueReportsIssueDateRangePicker: React.FC<
  IssueReportsIssueDateRangePickersProps
> = ({
  initializeIssueReportsIssueDateRangePicker,
  handleIssueDateFrom,
  handleIssueDateFromBlur,
  handleIssueDateTo,
  handleIssueDateToBlur,
  issueDateFrom,
  issueDateTo,
  disableDatePicker
}) => {
  useEffect(() => {
    initializeIssueReportsIssueDateRangePicker().catch(() => {});
  }, []);

  return (
    <Grid container spacing={5} data-cy="issueDateRangePicker">
      <Grid item>
        <DatePicker
          label="集計開始日"
          onChange={newIssueDateFrom => handleIssueDateFrom(newIssueDateFrom!)}
          onClose={handleIssueDateFromBlur}
          value={moment(issueDateFrom)}
          maxDate={moment()}
          disabled={disableDatePicker}
          data-cy="issueDateFrom"
          slotProps={{
            textField: {
              variant: 'standard',
              inputProps: { 'data-cy': 'issueDateFromInput' },
              onBlur: handleIssueDateFromBlur
            }
          }}
        />
      </Grid>
      <Grid item>
        <DatePicker
          label="集計終了日"
          onChange={newIssueDateTo => handleIssueDateTo(newIssueDateTo!)}
          onClose={handleIssueDateToBlur}
          value={moment(issueDateTo)}
          maxDate={moment()}
          disabled={disableDatePicker}
          data-cy="issueDateTo"
          slotProps={{
            textField: {
              variant: 'standard',
              inputProps: { 'data-cy': 'issueDateToInput' },
              onBlur: handleIssueDateToBlur
            }
          }}
        />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(IssueReportsIssueDateRangePicker);
