import { AppActionTypes } from '../actions/actionType';
import type { Prefectures } from '../../../common/api/graphql/getPrefecturesGql';
import type { AppAction } from '../actions/actionType';

const prefectures = (state = [], action: AppAction): Prefectures => {
  switch (action.type) {
    case AppActionTypes.GET_PREFECTURES_SUCCESS:
    case AppActionTypes.GET_MASTER_DATA_SUCCESS:
      return action.prefectures;
    default:
      return state;
  }
};

export default prefectures;
