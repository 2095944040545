import GiftConfig from '@console/components/project/GiftConfig';
import {
  getIsBulkProject,
  getIsApiProject,
  getIsGcpProject
} from '@console/selectors/projectDetail/solutionSystem';
import { connect } from 'react-redux';
import type { AppThunkDispatch } from '@console/actions/thunkType';
import type { AppState } from '@console/reducers';

const mapStateToProps = (state: AppState) => ({
  isApiProject: getIsApiProject(state),
  isBulkProject: getIsBulkProject(state),
  isGcpProject: getIsGcpProject(state)
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({});

export type GiftConfigPropsMappedFromState = ReturnType<typeof mapStateToProps>;
export type GiftConfigPropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(mapStateToProps, mapDispatchToProps)(GiftConfig);
