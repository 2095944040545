import { ERROR_MESSAGE_FOR_PAGE_VALIDATION } from '@common/middleware/errorMessages';
import {
  toggleCampaignDistributionChannel,
  updateGiftDistributionChannelDescription,
  toggleCampaignAdvertisingMedium,
  updateCampaignAdvertisingMediumDescription,
  validateProjectDraftFailure
} from '@console/actions/actions';
import { validateGiftDistributionThunk } from '@console/actions/thunks/validateProjectDraft/validateGiftDistribution';
import CampaignOverview from '@console/components/projectDraft/GiftDistribution';
import queryString from 'query-string';
import { connect } from 'react-redux';
import type {
  AppThunkAction,
  AppThunkDispatch
} from '@console/actions/thunkType';
import type { AppState } from '@console/reducers';
import type { NavigateFunction } from 'react-router-dom';

const handleStepNextFromParent =
  (navigate: NavigateFunction): AppThunkAction<void> =>
  async (dispatch, getState) =>
    dispatch(validateGiftDistributionThunk())
      .then(() => {
        const activeStep = queryString.parse(location.search);
        const parentStep = Number(activeStep.parentStep);
        const childStep = Number(activeStep.childStep);
        navigate(
          `${location.pathname}?parentStep=${
            parentStep + 1
          }&childStep=${childStep}`
        );
      })
      .catch(() => {
        dispatch(
          validateProjectDraftFailure(ERROR_MESSAGE_FOR_PAGE_VALIDATION)
        );
      });

const mapStateToProps = (state: AppState) => ({
  campaignGiftDistributionChannels:
    state.selectedProjectDraft.projectJson.campaignGiftDistributionChannels,
  giftDistributionChannelCategories: state.giftDistributionChannelCategories,
  campaignGiftDistributionChannelDescriptions:
    state.selectedProjectDraft.projectJson
      .campaignGiftDistributionChannelDescriptions,
  advertisingMedia: state.advertisingMedia,
  campaignAdvertisingMedia:
    state.selectedProjectDraft.projectJson.campaignAdvertisingMedia,
  errors: state.errors
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  onChangeCampaignGiftDistributionChannel: (urlCode: string) =>
    dispatch(toggleCampaignDistributionChannel(urlCode)),
  onChangeGiftDistributionChannelDescription: (
    event: GenericChangeEvent<string>,
    urlCode: string
  ) =>
    dispatch(
      updateGiftDistributionChannelDescription(urlCode, event.target.value)
    ),
  onChangeCampaignAdvertisingMedium: (urlCode: string) =>
    dispatch(toggleCampaignAdvertisingMedium(urlCode)),
  onChangeCampaignAdvertisingMediumDescription: (
    event: GenericChangeEvent<string>,
    urlCode: string
  ) =>
    dispatch(
      updateCampaignAdvertisingMediumDescription(urlCode, event.target.value)
    ),
  handleStepNextFromParent: (navigate: NavigateFunction) =>
    dispatch(handleStepNextFromParent(navigate))
});

export type GiftDistributionPropsMappedFromState = ReturnType<
  typeof mapStateToProps
>;
export type GiftDistributionPropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(mapStateToProps, mapDispatchToProps)(CampaignOverview);
