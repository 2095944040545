import { useEffect } from 'react';

type OwnProps = {
  url: string;
};

const RedirectToExternalUrl: React.FC<OwnProps> = ({ url }) => {
  useEffect(() => {
    if (url) {
      window.location.href = url;
    }
  }, [url]);
  return <></>;
};

export default RedirectToExternalUrl;
