import openFileByUrlInAnotherTab from '@common/lib/openFileByUrlInAnotherTab';
import { connect } from 'react-redux';
import { requestMessageThreadFileUrlThunk } from '../../../actions/thunks/requestMessageThreadFileUrl';
import UploadedFileComponent from '../../../components/project/UploadedFile';
import type {
  UploadedFile,
  UploadedFileUrlCode
} from '../../../../../common/api/graphql/uploadFileGql';
import type {
  AppThunkDispatch,
  AppThunkAction
} from '../../../actions/thunkType';
import type { AppState } from '../../../reducers';

const downloadUploadedFile =
  (urlCode: UploadedFileUrlCode): AppThunkAction<void> =>
  dispatch => {
    dispatch(requestMessageThreadFileUrlThunk(urlCode))
      .then(downloadUrl => {
        if (downloadUrl) {
          location.href = downloadUrl;
        }
      })
      .catch(() => {});
  };

const openUploadedFileInAnotherTab =
  (urlCode: UploadedFileUrlCode): AppThunkAction<void> =>
  dispatch => {
    dispatch(requestMessageThreadFileUrlThunk(urlCode))
      .then(downloadUrl => {
        if (downloadUrl) {
          openFileByUrlInAnotherTab(downloadUrl);
        }
      })
      .catch(() => {});
  };

type UploadedFileOwnProps = {
  uploadedFile: UploadedFile;
};

const mapStateToProps = (state: AppState, ownProps: UploadedFileOwnProps) => ({
  uploadedFile: ownProps.uploadedFile
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  openUploadedFileInAnotherTab: (urlCode: UploadedFileUrlCode) =>
    dispatch(openUploadedFileInAnotherTab(urlCode)),
  downloadUploadedFile: (urlCode: UploadedFileUrlCode) =>
    dispatch(downloadUploadedFile(urlCode))
});

export type UploadedFilePropsMappedFromState = ReturnType<
  typeof mapStateToProps
>;
export type UploadedFilePropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadedFileComponent);
