import { gql } from '@apollo/client';

import { internalApiAppClient } from './apolloClient';
import { operationNames } from './operationNames';
import type { FetchResult } from '@apollo/client';

const getMarketingIndustriesGql = gql`
  query ${operationNames.GET_MARKETING_INDUSTRIES}{
    marketingIndustries {
      name
      urlCode
    }
  }
`;

export type MarketingIndustry = {
  name: string;
  urlCode: string;
};

export type MarketingIndustries = MarketingIndustry[];

export type GetMarketingIndustriesResponse = FetchResult<{
  marketingIndustries: MarketingIndustries;
}>;

export const getMarketingIndustries =
  (): Promise<GetMarketingIndustriesResponse> =>
    internalApiAppClient.query({
      query: getMarketingIndustriesGql
    });
