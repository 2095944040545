import { AppActionTypes } from '../actions/actionType';
import type { TextContent } from '../../../common/api/graphql/postMessageThreadMessage';
import type { AppAction } from '../actions/actionType';

type MessageThreadInputMessages = {
  [key: string]: TextContent;
};

const messageThreadInputMessages = (
  state = {},
  action: AppAction
): MessageThreadInputMessages => {
  switch (action.type) {
    case AppActionTypes.GET_PROJECTS_SUCCESS:
      return action.projects.reduce((messageThreadInputMessages, project) => {
        if (Object.keys(messageThreadInputMessages).length) {
          const messageThreadUrlCode = project.messageThread
            ? project.messageThread.urlCode
            : '';
          const nextMessageThreadInputMessage =
            messageThreadInputMessages.hasOwnProperty(messageThreadUrlCode)
              ? messageThreadInputMessages[messageThreadUrlCode]
              : '';
          return {
            ...messageThreadInputMessages,
            [messageThreadUrlCode]: nextMessageThreadInputMessage
          };
        } else {
          // my page で作成した案件は message thread を利用しないため空の object を返す
          return {};
        }
      }, state);
    case AppActionTypes.UPDATE_MESSAGE_THREAD_INPUT_MESSAGES:
      return { ...state, [action.messageThreadUrlCode]: action.text };
    case AppActionTypes.POST_MESSAGE_THREAD_MESSAGE_SUCCESS:
      return { ...state, [action.messageThreadUrlCode]: '' };
    default:
      return state;
  }
};

export default messageThreadInputMessages;
