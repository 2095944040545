import { gql } from '@apollo/client';
import { internalApiAppClient } from './apolloClient';
import { operationNames } from './index';
import type { MessageThreadUrlCode } from './getMessageThread';

import type { UploadedFileUrlCodes } from './uploadFileGql';
import type { FetchResult } from '@apollo/client';

const postMessageThreadMessageGql = gql`
  mutation ${operationNames.POST_MESSAGE_THREAD_MESSAGE}(
    $messageThreadUrlCode: String!
    $requestCode: String!
    $text: String
    $fileUrlCodes: [String!]
  ) {
    postMessageThreadMessage(
      input: {
        messageThreadUrlCode: $messageThreadUrlCode
        requestCode: $requestCode
        text: $text
        fileUrlCodes: $fileUrlCodes
      }
    ) {
      message {
        requestCode
      }
    }
  }
`;

export type RequestCode = string;
export type TextContent = string | null;

export type PostMessageThreadMessageResponse = FetchResult<{
  message: {
    requestCode: RequestCode;
  };
}>;

export const postMessageThreadMessage = (
  messageThreadUrlCode: MessageThreadUrlCode,
  requestCode: RequestCode,
  text?: TextContent,
  fileUrlCodes?: UploadedFileUrlCodes
): Promise<PostMessageThreadMessageResponse> =>
  internalApiAppClient.mutate({
    mutation: postMessageThreadMessageGql,
    variables: {
      messageThreadUrlCode: messageThreadUrlCode,
      requestCode: requestCode,
      text: text,
      fileUrlCodes: fileUrlCodes
    }
  });
