import {
  excludeInvalidSolutionSystem,
  excludeInvalidGiftDistributionChannelCategories,
  excludeInvalidCampaignAdvertisingMedia,
  excludeInvalidGiftConfigs
} from '../actions';
import type { AdvertisingMedium } from '../../../../common/api/graphql/getAdvertisingMediaGql';
import type { Content } from '../../../../common/api/graphql/getContentsGql';
import type { GiftCardMainVisual } from '../../../../common/api/graphql/getGiftCardMainVisualsGql';
import type { GiftDistributionChannelCategory } from '../../../../common/api/graphql/getGiftDistributionChannelCategoriesGql';
import type { SolutionSystem } from '../../../../common/api/graphql/getSolutionSystemsGql';
import type { CampaignSolutionSystem } from '../../reducers/projectJson/campaignSolutionSystem';
import type { GiftConfig } from '../../reducers/projectJson/giftConfigs';
import type { AppThunkAction } from '../thunkType';
import type { ProjectAdvertisingMediumRegistration } from '@common/api/graphql/projectTypes';

export const excludeInvalidProjectDraftDataThunk =
  (): AppThunkAction<void> => (dispatch, getState) => {
    dispatch(excludeInvalidSolutionSystemThunk());

    dispatch(excludeInvalidGiftDistributionChannelsThunk());

    dispatch(excludeInvalidCampaignAdvertisingMediaThunk());

    dispatch(excludeInvalidGiftConfigsThunk());
  };

const excludeInvalidSolutionSystemThunk =
  (): AppThunkAction<void> => (dispatch, getState) => {
    const { solutionSystems, selectedProjectDraft } = getState();
    const { campaignSolutionSystem } = selectedProjectDraft.projectJson;

    if (hasInvalidSolutionSystem(campaignSolutionSystem, solutionSystems)) {
      dispatch(excludeInvalidSolutionSystem());
    }
  };

const hasInvalidSolutionSystem = (
  campaignSolutionSystem: CampaignSolutionSystem,
  solutionSystems: SolutionSystem[]
): boolean =>
  !solutionSystems.some(
    (solutionSystem: SolutionSystem) =>
      solutionSystem.urlCode === campaignSolutionSystem.urlCode
  );

const excludeInvalidGiftDistributionChannelsThunk =
  (): AppThunkAction<void> => (dispatch, getState) => {
    const { giftDistributionChannelCategories, selectedProjectDraft } =
      getState();
    const { campaignGiftDistributionChannels } =
      selectedProjectDraft.projectJson;

    const excludedGiftDistributionChannels =
      getExcludedGiftDistributionChannels(
        campaignGiftDistributionChannels,
        giftDistributionChannelCategories
      );
    dispatch(
      excludeInvalidGiftDistributionChannelCategories(
        excludedGiftDistributionChannels
      )
    );
  };

const getExcludedGiftDistributionChannels = (
  campaignGiftDistributionChannels: GiftDistributionChannelCategory['urlCode'][],
  giftDistributionChannels: GiftDistributionChannelCategory[]
): GiftDistributionChannelCategory['urlCode'][] =>
  campaignGiftDistributionChannels.filter(
    (
      campaignGiftDistributionChannel: GiftDistributionChannelCategory['urlCode']
    ) =>
      giftDistributionChannels.some(
        (giftDistributionChannelCategory: GiftDistributionChannelCategory) =>
          campaignGiftDistributionChannel ===
          giftDistributionChannelCategory.urlCode
      )
  );

const excludeInvalidCampaignAdvertisingMediaThunk =
  (): AppThunkAction<void> => (dispatch, getState) => {
    const { advertisingMedia, selectedProjectDraft } = getState();
    const { campaignAdvertisingMedia } = selectedProjectDraft.projectJson;

    const excludedCampaignAdvertisingMedia =
      getExcludedCampaignAdvertisingMedia(
        campaignAdvertisingMedia,
        advertisingMedia
      );
    dispatch(
      excludeInvalidCampaignAdvertisingMedia(excludedCampaignAdvertisingMedia)
    );
  };

const getExcludedCampaignAdvertisingMedia = (
  campaignAdvertisingMedia: ProjectAdvertisingMediumRegistration[],
  advertisingMedia: AdvertisingMedium[]
): ProjectAdvertisingMediumRegistration[] =>
  campaignAdvertisingMedia.filter(campaignAdvertisingMedium =>
    advertisingMedia.some(
      advertisingMedium =>
        campaignAdvertisingMedium.urlCode === advertisingMedium.urlCode
    )
  );

const excludeInvalidGiftConfigsThunk =
  (): AppThunkAction<void> => (dispatch, getState) => {
    const { giftCardMainVisuals, contents, selectedProjectDraft } = getState();
    const { giftConfigs } = selectedProjectDraft.projectJson;

    const excludedGiftConfigs = getExcludedGiftConfigs(
      giftConfigs,
      contents,
      giftCardMainVisuals
    );
    dispatch(excludeInvalidGiftConfigs(excludedGiftConfigs));
  };

const getExcludedGiftConfigs = (
  giftConfigs: GiftConfig[],
  contents: Content[],
  giftCardMainVisuals: GiftCardMainVisual[]
): GiftConfig[] =>
  giftConfigs.map(giftConfig => ({
    ...giftConfig,
    selectedContents: getExcludedSelectedContents(giftConfig, contents),
    selectedMainVisual: getExcludedSelectedMainVisual(
      giftConfig,
      giftCardMainVisuals
    )
  }));

const getExcludedSelectedContents = (
  giftConfig: GiftConfig,
  contents: Content[]
): string[] =>
  giftConfig.selectedContents.filter(selectedContent =>
    contents.some(content => content.urlCode === selectedContent)
  );

const getExcludedSelectedMainVisual = (
  giftConfig: GiftConfig,
  giftCardMainVisuals: GiftCardMainVisual[]
): GiftCardMainVisual['urlCode'] => {
  const foundMainVisual = giftCardMainVisuals.find(
    giftCardMainVisual =>
      giftConfig.selectedMainVisual === giftCardMainVisual.urlCode
  );
  return foundMainVisual ? foundMainVisual.urlCode : '';
};
