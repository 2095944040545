import { getClaimEstimate } from '../../../../common/api/graphql/getClaimEstimate';
import {
  getClaimEstimateStart,
  getClaimEstimateSuccess,
  getClaimEstimateFailure
} from '../actions';
import handleApiErrorIfNeeded from './handleApiErrorIfNeeded';
import type { GetClaimEstimateResponse } from '../../../../common/api/graphql/getClaimEstimate';
import type { AppThunkAction } from '../thunkType';
import type { ApiError } from '@common/api/graphql/apolloClient';

const getClaimEstimateSuccessThunk =
  (response: GetClaimEstimateResponse): AppThunkAction<Promise<void>> =>
  dispatch => {
    const claimEstimate = response.data!.claimEstimate;
    dispatch(getClaimEstimateSuccess(claimEstimate));
    return Promise.resolve();
  };

export const getClaimEstimateFailureThunk =
  (error: ApiError): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(getClaimEstimateFailure());
    return dispatch(handleApiErrorIfNeeded(error));
  };

export const getClaimEstimateThunk =
  (giftConfigsTotalAmount: number): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(getClaimEstimateStart());
    return getClaimEstimate(giftConfigsTotalAmount)
      .then((response: GetClaimEstimateResponse) =>
        dispatch(getClaimEstimateSuccessThunk(response))
      )
      .catch(error => dispatch(getClaimEstimateFailureThunk(error)));
  };
