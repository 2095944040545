import { openApiIpRestriction } from '@console/actions/actions';
import APIIpRestriction from '@console/components/project/APIIpRestriction';
import { getApiConfidentialInformation } from '@console/selectors/projectDetail/confidentialInformation';
import { connect } from 'react-redux';
import type { AppThunkDispatch } from '@console/actions/thunkType';
import type { AppState } from '@console/reducers';
import type { LocalAPIConfidentialInformation } from '@console/reducers/projects';

const mapStateToProps = (state: AppState) => ({
  apiConfidentialInformation: getApiConfidentialInformation(state)
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  setApiAllowedIps: (
    apiAllowedIps: LocalAPIConfidentialInformation['apiAllowedIps']
  ) => dispatch(openApiIpRestriction(apiAllowedIps))
});

export type APIIpRestrictionPropsMappedFromState = ReturnType<
  typeof mapStateToProps
>;
export type APIIpRestrictionPropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(mapStateToProps, mapDispatchToProps)(APIIpRestriction);
