import HighlightOff from '@mui/icons-material/HighlightOff';
import InsertDriveFile from '@mui/icons-material/InsertDriveFile';
import { ImageListItemBar, IconButton } from '@mui/material';
import { grey } from '@mui/material/colors';
import withStyles from '@mui/styles/withStyles';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => ({
  defaultFilePreview: {
    position: 'absolute',
    width: '40%',
    height: '40%',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto'
  },
  deleteIcon: {
    margin: theme.spacing(1)
  }
});

type DefaultFilePreviewOwnProps = {
  attachedFile: File;
  removeAttachedFile;
};

type DefaultFilePreviewProps = DefaultFilePreviewOwnProps &
  WithStyles<typeof styles>;

const DefaultFilePreview: React.FC<DefaultFilePreviewProps> = ({
  classes,
  attachedFile,
  removeAttachedFile
}) => (
  <>
    <InsertDriveFile className={classes.defaultFilePreview} />
    <ImageListItemBar
      actionIcon={
        <IconButton
          className={classes.deleteIcon}
          onClick={removeAttachedFile}
          data-cy="removeAttachedFile"
          size="large"
        >
          <HighlightOff style={{ color: grey[50] }} />
        </IconButton>
      }
      title={attachedFile.name}
      position="top"
    ></ImageListItemBar>
  </>
);

export default withStyles(styles)(DefaultFilePreview);
