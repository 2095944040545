import { Typography, Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import ContentBrief from './ContentBrief';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => ({
  contentContainer: {
    width: theme.spacing(45),
    whiteSpace: 'pre-line',
    margin: `${theme.spacing()} 0`
  }
});

type ContentRowOwnProps = {
  contentImageUrl: string;
  contentName: string;
  contentInternalName: string;
  contentBrandName: string;
  selectablePeriodText?: string;
  contentAvailablePeriodText?: string;
  imageDownloadUrl?: string;
  isLimitedPublished?: boolean;
};

type ContentRowProps = ContentRowOwnProps & WithStyles<typeof styles>;

const ContentRow: React.FC<ContentRowProps> = ({
  classes,
  contentImageUrl,
  contentName,
  contentInternalName,
  contentBrandName,
  selectablePeriodText,
  contentAvailablePeriodText,
  imageDownloadUrl,
  isLimitedPublished
}) => (
  <Grid
    className={classes.contentContainer}
    data-cy={`contentRow-${contentName}`}
  >
    <ContentBrief
      contentName={contentName}
      contentInternalName={contentInternalName}
      contentImageUrl={contentImageUrl}
      selectablePeriodText={selectablePeriodText}
      contentAvailablePeriodText={contentAvailablePeriodText}
      contentBrandName={contentBrandName}
      imageDownloadUrl={imageDownloadUrl}
      isLimitedPublished={isLimitedPublished}
    />
  </Grid>
);

export default withStyles(styles)(ContentRow);
