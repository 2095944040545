import { getSelectedGiftCardConfig } from '@console/selectors/projectDetail/giftCardConfigs';
import { createSelector } from 'reselect';
import type { AppState } from '@console/reducers';

const getAddingBulkGiftCardIssueConfigs = (state: AppState) =>
  state.edits.project.giftCardConfigs.addingBulkGiftCardIssueConfigs;

export const getAddingBulkGiftCardIssueConfigsSubTotals = createSelector(
  [getAddingBulkGiftCardIssueConfigs, getSelectedGiftCardConfig],
  (addingBulkGiftCardIssueConfigs, giftCardConfig): number[] =>
    addingBulkGiftCardIssueConfigs.map(addingBulkGiftCardIssueConfig => {
      if (
        !!addingBulkGiftCardIssueConfig.requestedIssueAmount &&
        !!giftCardConfig
      ) {
        return (
          Number(addingBulkGiftCardIssueConfig.requestedIssueAmount) *
          giftCardConfig.unitPrice
        );
      }
      return 0;
    })
);

export const getAddingBulkGiftCardIssueConfigsTotal = createSelector(
  [getAddingBulkGiftCardIssueConfigsSubTotals],
  (AddingBulkGiftCardIssueConfigsSubTotals): number =>
    AddingBulkGiftCardIssueConfigsSubTotals.reduce(
      (total, addingBulkGiftCardIssueConfigSubTotal) =>
        total + addingBulkGiftCardIssueConfigSubTotal,
      0
    )
);

export const getAddingBulkGiftCardIssueAmountTotal = createSelector(
  [getAddingBulkGiftCardIssueConfigs],
  (addingBulkGiftCardIssueConfigs): number =>
    addingBulkGiftCardIssueConfigs.reduce(
      (total, addingBulkGiftCardIssueConfig) =>
        total + Number(addingBulkGiftCardIssueConfig.requestedIssueAmount),
      0
    )
);
