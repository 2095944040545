import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import {
  Snackbar,
  IconButton,
  SnackbarContent,
  Typography
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';
import type { SyntheticEvent } from 'react';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    success: {
      backgroundColor: theme.palette.success.main
    },
    error: {
      backgroundColor: theme.palette.error.light
    },
    icon: {
      fontSize: 20,
      fill: theme.palette.text.primary
    },
    message: {
      display: 'flex',
      alignItems: 'center'
    }
  });

export const SUCCESS = 'success';
export const ERROR = 'error';

export const isSuccessMessage = (snackbarMessage: SnackbarMessage) =>
  snackbarMessage.status === SUCCESS;
export const isErrorMessage = (snackbarMessage: SnackbarMessage) =>
  snackbarMessage.status === ERROR;

export type SnackbarMessageContent = string;
export type SnackbarMessage = {
  isOpen: boolean;
  status: 'success' | 'error' | undefined;
  content: SnackbarMessageContent;
};

type SnackbarMessageOwnProps = {
  snackbarMessage: SnackbarMessage;
  handleClose: (
    event?: Event | SyntheticEvent<any, Event>,
    reason?: string
  ) => void;
  autoHideDuration?: number;
};

type SnackbarMessageProps = SnackbarMessageOwnProps & WithStyles<typeof styles>;

const SnackbarMessageComponent: React.FC<SnackbarMessageProps> = ({
  handleClose,
  snackbarMessage,
  autoHideDuration = 5000,
  classes
}) => {
  const isSuccess = isSuccessMessage(snackbarMessage);
  const isError = isErrorMessage(snackbarMessage);
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      open={snackbarMessage.isOpen}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
    >
      <SnackbarContent
        className={clsx({
          [classes.success]: isSuccess,
          [classes.error]: isError
        })}
        aria-describedby="client-snackbar"
        message={
          <Typography
            color="textPrimary"
            id="client-snackbar"
            className={classes.message}
          >
            {isSuccess ? <CheckCircleIcon className={classes.icon} /> : null}
            {isError ? <ErrorIcon className={classes.icon} /> : null}
            {snackbarMessage.content}
          </Typography>
        }
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
            size="large"
          >
            <CloseIcon className={classes.icon} />
          </IconButton>
        ]}
      />
    </Snackbar>
  );
};

export default withStyles(styles)(SnackbarMessageComponent);
