import { connect } from 'react-redux';
import { AppThunkAction } from '../../actions/thunkType';
import Account from '../../components/account/Account';
import type { AppThunkDispatch } from '../../actions/thunkType';
import type { AppState } from '../../reducers';

const mapStateToProps = (state: AppState) => ({
  distributionPartner: state.distributionPartner,
  distributionPartnerUser: state.distributionPartnerUser
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({});

export type AccountPropsMappedFromState = ReturnType<typeof mapStateToProps>;
export type AccountPropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(mapStateToProps, mapDispatchToProps)(Account);
