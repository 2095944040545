import { AppActionTypes } from '../../actions/actionType';
import type { AppAction } from '../../actions/actionType';

export type CampaignMarketingIndustry = {
  urlCode: string;
};

export const initialStateOfCampaignMarketingIndustry = {
  urlCode: ''
};

export const marketingIndustry = (
  state = initialStateOfCampaignMarketingIndustry,
  action: AppAction
): CampaignMarketingIndustry => {
  switch (action.type) {
    case AppActionTypes.ADD_PROJECT_DRAFT:
    case AppActionTypes.COPY_PROJECT_TO_PROJECT_DRAFT_FAILURE:
      return initialStateOfCampaignMarketingIndustry;
    case AppActionTypes.SELECT_PROJECT_DRAFT:
      return action.projectDraft.projectJson.campaignMarketingIndustry;
    case AppActionTypes.UPDATE_CAMPAIGN_MARKETING_INDUSTRY:
      return {
        urlCode: action.campaignMarketingIndustry
      };
    case AppActionTypes.COPY_PROJECT_TO_PROJECT_DRAFT:
      return { urlCode: action.project.marketingIndustry.urlCode };
    default:
      return state;
  }
};

export default marketingIndustry;
