import {
  errorMessageForApolloClientNetworkError,
  errorMessageForApolloClientAuthenticationError
} from '@common/api/graphql/errorMessage';
import { isApolloError } from '@common/api/graphql/isApolloError';
import { requestFailure } from '../actions';
import { handleGraphqlErrors } from './handleGraphqlErrorsIfNeeded';
import type { AppThunkAction } from '../thunkType';
import type { ApolloError } from '@apollo/client';

export const AUTHENTICATION_ERROR_CODE = 'AUTHENTICATION_ERROR';

export const handleApolloError =
  (error: ApolloError): AppThunkAction<Promise<void>> =>
  dispatch => {
    if (!isApolloError(error)) {
      return Promise.reject(JSON.stringify(error));
    }

    if (error.networkError) {
      // apollo client の error.networkError の型に、statusCode がないため、ignore してます
      // ref: https://github.com/apollographql/apollo-client/blob/3d48f9fc7d07d8170c5b906b6e93bf0400aa2698/src/errors/ApolloError.ts#L39
      // @ts-ignore
      if (error.networkError.statusCode === 422) {
        dispatch(
          requestFailure(
            errorMessageForApolloClientAuthenticationError,
            AUTHENTICATION_ERROR_CODE
          )
        );
      } else {
        dispatch(
          requestFailure(
            errorMessageForApolloClientNetworkError,
            'NETWORK_ERROR'
          )
        );
      }
      return Promise.reject(JSON.stringify(error));
    } else if (error.graphQLErrors.length > 0) {
      return dispatch(handleGraphqlErrors(error.graphQLErrors));
    }

    return Promise.reject(JSON.stringify(error));
  };
