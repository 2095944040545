import { updateSuccessMessage } from '@common/api/graphql/successMessage';
import { updateDistributionPartnerUser } from '@common/api/graphql/updateDistributionPartnerUserGql';
import {
  updateDistributionPartnerUserSuccess,
  updateDistributionPartnerUserFailure,
  updateDistributionPartnerUserStart
} from '../actions';
import handleApiErrorIfNeeded from './handleApiErrorIfNeeded';
import type { ApiError } from '../../../../common/api/graphql/apolloClient';
import type { AppThunkAction } from '../thunkType';
import type {
  UpdateDistributionPartnerUserResponse,
  UpdateDistributionPartnerUserParams
} from '@common/api/graphql/updateDistributionPartnerUserGql';
import type { AppState } from '@console/reducers';

export const getUpdateDistributionPartnerUserRequestParams = (
  state: AppState
): UpdateDistributionPartnerUserParams => {
  const distributionPartnerUser = state.edits.distributionPartnerUser;
  return {
    name: distributionPartnerUser.name,
    division: distributionPartnerUser.division,
    phoneNumber: distributionPartnerUser.phoneNumber
  };
};

export const updateDistributionPartnerUserSuccessThunk =
  (
    response: UpdateDistributionPartnerUserResponse
  ): AppThunkAction<Promise<void>> =>
  dispatch => {
    const snackbarMessage = updateSuccessMessage('ユーザ情報');
    const { updateDistributionPartnerUser } = response.data!;
    dispatch(updateDistributionPartnerUserSuccess(snackbarMessage));
    return Promise.resolve();
  };

export const updateDistributionPartnerUserFailureThunk =
  (error: ApiError): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(updateDistributionPartnerUserFailure());
    return dispatch(handleApiErrorIfNeeded(error));
  };

export const updateDistributionPartnerUserThunk =
  (): AppThunkAction<Promise<void>> => (dispatch, getState) => {
    const updateDistributionPartnerUserRequestParams =
      getUpdateDistributionPartnerUserRequestParams(getState());
    dispatch(updateDistributionPartnerUserStart());
    return updateDistributionPartnerUser(
      updateDistributionPartnerUserRequestParams
    )
      .then((response: UpdateDistributionPartnerUserResponse) =>
        dispatch(updateDistributionPartnerUserSuccessThunk(response))
      )
      .catch(error =>
        dispatch(updateDistributionPartnerUserFailureThunk(error))
      );
  };
