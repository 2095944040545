import { AppActionTypes } from '../../actions/actionType';
import type { AppAction } from '../../actions/actionType';

type GiftConfigCommon = {
  ticketType: TicketType;
  selectedContents: string[];
  issueAmount: string;
  deliveryDate: string | null;
  messageCardUsing: boolean;
  senderName: string;
  message: string;
  selectedMainVisual: string;
  selectedGifteeBoxTemplate: SelectedGifteeBoxTemplate;
  gifteeBoxInitialPoint: string;
  gifteeBoxIssueEstimateOptions: GifteeBoxIssueEstimateOptions;
};

export type SelectedGifteeBoxTemplate = null | GifteeBoxTemplate;

type GifteeBoxTemplate = {
  name: string;
  urlCode: UrlCode;
  imageUrl: string;
  isLimitedPublished: boolean;
  exchangeableBeginLabel: string;
  exchangeableEndLabel: string;
};

export type InitialPointCandidate = {
  initialPoint: number;
};

export type GiftConfig = GiftCardGiftConfig | GifteeBoxGiftConfig;

export type GiftCardGiftConfig = FixedGiftConfig | ChoosableGiftConfig;

export type FixedGiftConfig = GiftConfigCommon & {
  ticketType: typeof TICKET_TYPE.FIXED;
};

export type ChoosableGiftConfig = GiftConfigCommon & {
  ticketType: typeof TICKET_TYPE.CHOOSABLE;
};

export type GifteeBoxGiftConfig = GiftConfigCommon & {
  ticketType: typeof TICKET_TYPE.GIFTEE_BOX;
};

export const TICKET_TYPE = {
  FIXED: 'fixed',
  CHOOSABLE: 'choosable',
  GIFTEE_BOX: 'gifteeBox'
} as const;

export type TicketType = (typeof TICKET_TYPE)[keyof typeof TICKET_TYPE];

export type GifteeBoxIssueEstimateOptions = GifteeBoxIssueEstimateOption[];

export type GifteeBoxIssueEstimateOption = {
  initialPoint: string;
  issueAmount: string;
};

const initialGifteeBoxIssueEstimateOption = {
  initialPoint: '',
  issueAmount: ''
};

const initialStateOfGiftConfig = {
  ticketType: TICKET_TYPE.FIXED,
  selectedContents: [],
  issueAmount: '',
  deliveryDate: null,
  messageCardUsing: true,
  senderName: '',
  message: '',
  selectedMainVisual: '',
  selectedGifteeBoxTemplate: null,
  gifteeBoxInitialPoint: '',
  gifteeBoxIssueEstimateOptions: [initialGifteeBoxIssueEstimateOption]
};

export type GiftConfigs = GiftConfig[];
export const initialStateOfGiftConfigs: GiftConfigs = [];

export const giftConfigs = (
  state = initialStateOfGiftConfigs,
  action: AppAction
): GiftConfigs => {
  switch (action.type) {
    case AppActionTypes.ADD_PROJECT_DRAFT:
    case AppActionTypes.COPY_PROJECT_TO_PROJECT_DRAFT:
    case AppActionTypes.COPY_PROJECT_TO_PROJECT_DRAFT_FAILURE:
      return initialStateOfGiftConfigs;
    case AppActionTypes.SELECT_PROJECT_DRAFT:
      return action.projectDraft.projectJson.giftConfigs;
    case AppActionTypes.ADD_NEW_GIFT_CONFIG:
      return [initialStateOfGiftConfig, ...state];
    case AppActionTypes.UPDATE_GIFT_CONFIG_TICKET_TYPE:
      return state.map((giftConfig: GiftConfig, i: number) => {
        if (i === action.index) {
          return {
            ...giftConfig,
            ticketType: action.ticketType,
            selectedContents: []
          };
        }
        return giftConfig;
      });
    case AppActionTypes.ADD_GIFT_CONFIG_SELECTED_CONTENTS:
      return state.map((giftConfig: GiftConfig, i: number) => {
        if (i === action.index) {
          return {
            ...giftConfig,
            selectedContents: [...giftConfig.selectedContents, action.content]
          };
        }
        return giftConfig;
      });
    case AppActionTypes.REMOVE_GIFT_CONFIG_SELECTED_CONTENTS:
      return state.map((giftConfig: GiftConfig, giftConfigIndex: number) => {
        if (giftConfigIndex === action.index) {
          return {
            ...giftConfig,
            selectedContents: giftConfig.selectedContents.filter(
              selectedContent => selectedContent !== action.content
            )
          };
        }
        return giftConfig;
      });
    case AppActionTypes.SELECT_GIFT_CONFIG_GIFTEE_BOX_TEMPLATE:
      return state.map((giftConfig: GiftConfig, giftConfigIndex: number) => {
        if (giftConfigIndex === action.index) {
          return {
            ...giftConfig,
            selectedGifteeBoxTemplate: {
              name: action.gifteeBoxTemplate.name,
              urlCode: action.gifteeBoxTemplate.urlCode,
              imageUrl: action.gifteeBoxTemplate.imageUrl,
              exchangeableBeginLabel:
                action.gifteeBoxTemplate.exchangeableBeginLabel,
              exchangeableEndLabel:
                action.gifteeBoxTemplate.exchangeableEndLabel,
              isLimitedPublished: action.gifteeBoxTemplate.isLimitedPublished
            }
          };
        }
        return giftConfig;
      });
    case AppActionTypes.DESELECT_GIFT_CONFIG_GIFTEE_BOX_TEMPLATE:
      return state.map((giftConfig: GiftConfig, giftConfigIndex: number) => {
        if (giftConfigIndex === action.index) {
          return {
            ...giftConfig,
            selectedGifteeBoxTemplate: null
          };
        }
        return giftConfig;
      });
    case AppActionTypes.UPDATE_GIFT_CONFIG_ISSUE_AMOUNT:
      return state.map((giftConfig: GiftConfig, i: number) => {
        if (i === action.index) {
          return {
            ...giftConfig,
            issueAmount: action.issueAmount
          };
        }
        return giftConfig;
      });
    case AppActionTypes.UPDATE_GIFT_CONFIG_DELIVERY_DATE:
      return state.map((giftConfig: GiftConfig, i: number) => {
        if (i === action.index) {
          return {
            ...giftConfig,
            deliveryDate: action.deliveryDate
              ? action.deliveryDate.format('YYYY-MM-DD')
              : null
          };
        }
        return giftConfig;
      });
    case AppActionTypes.UPDATE_GIFT_CONFIG_GIFTEE_BOX_INITIAL_POINT:
      return state.map((giftConfig: GiftConfig, i: number) => {
        if (i === action.index) {
          return {
            ...giftConfig,
            gifteeBoxInitialPoint: action.gifteeBoxInitialPoint
          };
        }
        return giftConfig;
      });
    case AppActionTypes.UPDATE_GIFT_CONFIG_GIFTEE_BOX_ISSUE_ESTIMATE_OPTIONS_INITIAL_POINT:
      return state.map((giftConfig: GiftConfig, giftConfigIndex: number) => {
        if (giftConfigIndex === action.giftConfigindex) {
          const gifteeBoxIssueEstimateOptions =
            giftConfig.gifteeBoxIssueEstimateOptions.map(
              (gifteeBoxIssueEstimateOption, issueEstimateOptionIndex) => {
                if (
                  issueEstimateOptionIndex === action.issueEstimateOptionIndex
                ) {
                  return {
                    ...gifteeBoxIssueEstimateOption,
                    initialPoint: action.initialPoint
                  };
                }
                return gifteeBoxIssueEstimateOption;
              }
            );
          return {
            ...giftConfig,
            gifteeBoxIssueEstimateOptions
          };
        }
        return giftConfig;
      });
    case AppActionTypes.UPDATE_GIFT_CONFIG_GIFTEE_BOX_ISSUE_ESTIMATE_OPTIONS_ISSUE_AMOUNT:
      return state.map((giftConfig: GiftConfig, giftConfigIndex: number) => {
        if (giftConfigIndex === action.giftConfigindex) {
          const gifteeBoxIssueEstimateOptions =
            giftConfig.gifteeBoxIssueEstimateOptions.map(
              (gifteeBoxIssueEstimateOption, issueEstimateOptionIndex) => {
                if (
                  issueEstimateOptionIndex === action.issueEstimateOptionIndex
                ) {
                  return {
                    ...gifteeBoxIssueEstimateOption,
                    issueAmount: action.issueAmount
                  };
                }
                return gifteeBoxIssueEstimateOption;
              }
            );
          return {
            ...giftConfig,
            gifteeBoxIssueEstimateOptions
          };
        }
        return giftConfig;
      });
    case AppActionTypes.ADD_GIFT_CONFIG_GIFTEE_BOX_ISSUE_ESTIMATE_OPTION:
      return state.map((giftConfig: GiftConfig, giftConfigIndex: number) => {
        if (giftConfigIndex === action.giftConfigIndex) {
          return {
            ...giftConfig,
            gifteeBoxIssueEstimateOptions: [
              ...giftConfig.gifteeBoxIssueEstimateOptions,
              initialGifteeBoxIssueEstimateOption
            ]
          };
        }
        return giftConfig;
      });
    case AppActionTypes.REMOVE_GIFT_CONFIG_GIFTEE_BOX_ISSUE_ESTIMATE_OPTION:
      return state.map((giftConfig: GiftConfig, giftConfigIndex: number) => {
        if (giftConfigIndex === action.giftConfigIndex) {
          const gifteeBoxIssueEstimateOptions =
            giftConfig.gifteeBoxIssueEstimateOptions.filter(
              (_, gifteeBoxIssueEstimateOptionIndex) =>
                gifteeBoxIssueEstimateOptionIndex !==
                action.issueEstimateOptionIndex
            );
          return {
            ...giftConfig,
            gifteeBoxIssueEstimateOptions
          };
        }
        return giftConfig;
      });
    case AppActionTypes.UPDATE_GIFT_CONFIG_MESSAGE_CARD_USING:
      return state.map((giftConfig: GiftConfig, i: number) => {
        if (i === action.index) {
          if (!action.messageCardUsing) {
            return {
              ...giftConfig,
              messageCardUsing: action.messageCardUsing,
              senderName: '',
              message: '',
              selectedMainVisual: ''
            };
          }
          return {
            ...giftConfig,
            messageCardUsing: action.messageCardUsing
          };
        }
        return giftConfig;
      });
    case AppActionTypes.UPDATE_GIFT_CONFIG_SENDER_NAME:
      return state.map((giftConfig: GiftConfig, i: number) => {
        if (i === action.index) {
          return {
            ...giftConfig,
            senderName: action.senderName
          };
        }
        return giftConfig;
      });
    case AppActionTypes.UPDATE_GIFT_CONFIG_MESSAGE:
      return state.map((giftConfig: GiftConfig, i: number) => {
        if (i === action.index) {
          return {
            ...giftConfig,
            message: action.message
          };
        }
        return giftConfig;
      });
    case AppActionTypes.UPDATE_GIFT_CONFIG_MAIN_VISUAL:
      return state.map((giftConfig: GiftConfig, i: number) => {
        if (i === action.index) {
          return {
            ...giftConfig,
            selectedMainVisual: action.urlCode
          };
        }
        return giftConfig;
      });
    case AppActionTypes.DELETE_GIFT_CONFIG:
      return state.filter(
        (giftConfig: GiftConfig, i: number) => i !== action.index
      );
    case AppActionTypes.EXCLUDE_INVALID_GIFT_CONFIGS:
      return action.excludedGiftConfigs;
    default:
      return state;
  }
};

export default giftConfigs;
