import { gql } from '@apollo/client';

import { internalApiAppClient } from './apolloClient';
import { operationNames } from './index';
import type { FetchResult } from '@apollo/client';

const requestBulkDeliverableUrlGql = gql`
  mutation ${operationNames.REQUEST_BULK_DELIVERABLE_URL}(
    $urlCode: String!
    $downloadType: String!
  ) {
    requestBulkDeliverableUrl(
      input: { urlCode: $urlCode, downloadType: $downloadType }
    ) {
      downloadUrl
    }
  }
`;

export type DownloadUrl = string;

export type RequestBulkDeliverableUrlResponse = FetchResult<{
  requestBulkDeliverableUrl: {
    downloadUrl: DownloadUrl;
  };
}>;

export const requestBulkDeliverableUrl = (
  urlCode: string,
  downloadType: string
): Promise<RequestBulkDeliverableUrlResponse> =>
  internalApiAppClient.mutate({
    mutation: requestBulkDeliverableUrlGql,
    variables: {
      urlCode,
      downloadType
    }
  });
