import APITokenEmpty from '@console/components/project/apiToken/Empty';
import APITokenTable from '@console/components/project/apiToken/Table';
import withStyles from '@mui/styles/withStyles';
import type {
  APITokenPropsMappedFromState,
  APITokenPropsMappedFromDispatch
} from '@console/containers/project/APITokenContainer';
import type { LocalAPIConfidentialInformation } from '@console/reducers/projects';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';
import type { ReactElement } from 'react';

const styles = (theme: Theme): StyleRules => ({
  sections: {
    paddingBottom: theme.spacing(5)
  },
  section: {
    paddingTop: theme.spacing(4)
  }
});

type APITokenProps = APITokenPropsMappedFromState &
  APITokenPropsMappedFromDispatch &
  WithStyles<typeof styles>;

const APIToken: React.FC<APITokenProps> = ({
  classes,
  apiConfidentialInformation
}) => {
  const renderContents = (): ReactElement => {
    if (
      !!apiConfidentialInformation &&
      apiConfidentialInformation.accessTokens.length !== 0
    ) {
      return renderTable(apiConfidentialInformation.accessTokens);
    }

    return renderEmpty();
  };

  const renderTable = (
    accessTokens: LocalAPIConfidentialInformation['accessTokens']
  ): ReactElement => <APITokenTable accessTokens={accessTokens} />;

  const renderEmpty = (): ReactElement => <APITokenEmpty />;

  return (
    <div className={classes.sections}>
      <section className={classes.section}>{renderContents()}</section>
    </div>
  );
};

export default withStyles(styles)(APIToken);
