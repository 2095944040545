import { AppActionTypes } from '@console/actions/actionType';
import type { AggregateGifteeBoxSelectedContentResults } from '@common/api/graphql/aggregateGifteeBoxSelectedContentPercentageGql';
import type { AppAction } from '@console/actions/actionType';

const initialState = {
  aggregatedGifteeBoxSelectedContentResults: []
};
const aggregatedGifteeBoxSelectedContentResults = (
  state = initialState.aggregatedGifteeBoxSelectedContentResults,
  action: AppAction
): AggregateGifteeBoxSelectedContentResults => {
  switch (action.type) {
    case AppActionTypes.AGGREGATE_GIFTEE_BOX_SELECTED_CONTENT_PERCENTAGE_SUCCESS:
      return action.results;
    case AppActionTypes.CLEAR_AGGREGATE_GIFTEE_BOX_SELECTED_CONTENT_PERCENTAGE:
      return initialState.aggregatedGifteeBoxSelectedContentResults;
    default:
      return state;
  }
};

export default aggregatedGifteeBoxSelectedContentResults;
