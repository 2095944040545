import { openProjectDraftContentsSelectionPage } from '@console/actions/actions';
import { getContentsOfSelectedProjectDraftThunk } from '@console/actions/thunks/getContentsOfSelectedProjectDraft';
import { getInitialContentsThunk } from '@console/actions/thunks/getInitialContents';
import ContentsSelection from '@console/components/common/ContentsSelection';
import { getSelectedProjectDraftSelectedGiftConfig } from '@console/selectors/getSelectedProjectDraftForDisplay';
import { connect } from 'react-redux';
import type { AppThunkDispatch } from '../../actions/thunkType';
import type { AppState } from '../../reducers';

const onMount = (): AppThunkDispatch<void> => async dispatch => {
  dispatch(openProjectDraftContentsSelectionPage());
  await dispatch(getContentsOfSelectedProjectDraftThunk()).catch(() => {});
  await dispatch(getInitialContentsThunk()).catch(() => {});
};

const mapStateToProps = (state: AppState) => ({
  selectedGiftConfig: getSelectedProjectDraftSelectedGiftConfig(state)
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  onMount: () => dispatch(onMount())
});

export type ContentsSelectionPropsMappedFromState = ReturnType<
  typeof mapStateToProps
>;
export type ContentsSelectionPropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(mapStateToProps, mapDispatchToProps)(ContentsSelection);
