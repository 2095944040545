import { getGifteeBoxTemplateContentSelections } from '@common/api/graphql/getGifteeBoxTemplateContentSelectionsGql';
import {
  getGifteeBoxTemplateInitialContentSelectionsStart,
  getGifteeBoxTemplateInitialContentSelectionsSuccess,
  getGifteeBoxTemplateInitialContentSelectionsFailure
} from '../actions';
import handleApiErrorIfNeeded from './handleApiErrorIfNeeded';
import type { AppThunkAction } from '../thunkType';
import type { ApiError } from '@common/api/graphql/apolloClient';
import type {
  GetGifteeBoxTemplateContentSelectionsResponse,
  GetGifteeBoxTemplateContentSelectionsSearchInfo
} from '@common/api/graphql/getGifteeBoxTemplateContentSelectionsGql';

export const getGifteeBoxTemplateInitialContentSelectionsSuccessThunk =
  (
    response: GetGifteeBoxTemplateContentSelectionsResponse
  ): AppThunkAction<Promise<void>> =>
  dispatch => {
    const { contentSelections } = response.data!.gifteeBoxTemplate;

    dispatch(
      getGifteeBoxTemplateInitialContentSelectionsSuccess(contentSelections)
    );
    return Promise.resolve();
  };

export const getGifteeBoxTemplateInitialContentSelectionsFailureThunk =
  (error: ApiError): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(getGifteeBoxTemplateInitialContentSelectionsFailure());
    return dispatch(handleApiErrorIfNeeded(error));
  };

export const getGifteeBoxTemplateInitialContentSelectionsThunk =
  (
    gifteeBoxTemplateUrlCode: UrlCode,
    searchInfo?: GetGifteeBoxTemplateContentSelectionsSearchInfo
  ): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(getGifteeBoxTemplateInitialContentSelectionsStart());

    return getGifteeBoxTemplateContentSelections(
      gifteeBoxTemplateUrlCode,
      searchInfo
    )
      .then((response: GetGifteeBoxTemplateContentSelectionsResponse) =>
        dispatch(
          getGifteeBoxTemplateInitialContentSelectionsSuccessThunk(response)
        )
      )
      .catch(error =>
        dispatch(
          getGifteeBoxTemplateInitialContentSelectionsFailureThunk(error)
        )
      );
  };
