export const getNodes = <T>(connection: IkedayamaConnection<T>): T[] => {
  const edges = connection.edges;
  if (!edges) {
    return [];
  }
  return edges.filter((edge): edge is Edge<T> => !!edge).map(edge => edge.node);
};

export const getNodesFromEdges = <T>(edges: Edge<T | null>[] | null): T[] => {
  if (!edges) {
    return [];
  }
  return edges.filter((edge): edge is Edge<T> => !!edge).map(edge => edge.node);
};
