import { gql } from '@apollo/client';
import { internalApiAppClient } from './apolloClient';
import { operationNames } from './operationNames';
import type { FetchResult } from '@apollo/client';

const getAdvertisingMediaGql = gql`
  query ${operationNames.GET_ADVERTISING_MEDIA}{
    advertisingMedia {
      name
      urlCode
      descriptionPlaceholder
      descriptionExample
    }
  }
`;

export type AdvertisingMedium = {
  name: string;
  urlCode: UrlCode;
  descriptionPlaceholder: string;
  descriptionExample: string;
};

export type AdvertisingMedia = AdvertisingMedium[];

export type GetAdvertisingMediaResponse = FetchResult<{
  advertisingMedia: AdvertisingMedia;
}>;

export const getAdvertisingMedia = (): Promise<GetAdvertisingMediaResponse> =>
  internalApiAppClient.query({
    query: getAdvertisingMediaGql
  });
