// 参考：https://github.com/giftee/ikedayama/pull/3535
// 今回利用しているフォントの全角と半角の幅に応じて、引数 text を全角２文字、半角１.3文字として文字列の長さを測定。
// text の長さが、length より大きい場合は、length までで文字をカットし、末尾に omissionSymbol をつけて返す

export default (text: string, length: number, omissionSymbol = '') => {
  const textArray = text.split('');
  let count = 0;
  let str = '';
  for (let i = 0; i < textArray.length; i++) {
    const escapedText = escape(textArray[i]);

    escapedText.length < 4 ? (count += 1.3) : (count += 2);

    if (count > length) {
      return str + omissionSymbol;
    }
    str += text.charAt(i);
  }
  return text;
};
