import { ERROR_MESSAGE_FOR_EXCEED_CREDIT_LIMIT_IN_REQUEST_ISSUE_AMOUNT } from '@common/middleware/errorMessages';
import { getCreditLimitBalance } from '@console/selectors/distributionPartner';
import type { AppThunkAction } from '../thunkType';
import type { LocalBulkGifteeBoxIssueConfig } from '@console/reducers/projects';

export const validateRequestGifteeBoxIssueAmountThunk =
  (
    bulkGifteeBoxIssueConfig: LocalBulkGifteeBoxIssueConfig
  ): AppThunkAction<Promise<void>> =>
  (dispatch, getState) => {
    const creditLimitBalance = getCreditLimitBalance(getState());

    const initialPoint = bulkGifteeBoxIssueConfig.initialPoint;
    const issueAmount = bulkGifteeBoxIssueConfig.issueAmountRequest.issueAmount;
    const bulkGifteeBoxIssueConfigAmount = initialPoint * issueAmount;

    if (
      isExceedCreditLimit(bulkGifteeBoxIssueConfigAmount, creditLimitBalance)
    ) {
      return Promise.reject(
        ERROR_MESSAGE_FOR_EXCEED_CREDIT_LIMIT_IN_REQUEST_ISSUE_AMOUNT
      );
    }

    return Promise.resolve();
  };

const isExceedCreditLimit = (
  bulkGifteeBoxIssueConfigAmount: number,
  creditLimitBalance: number | null
): boolean => {
  if (creditLimitBalance === null) return false;

  return creditLimitBalance < bulkGifteeBoxIssueConfigAmount;
};
