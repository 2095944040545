import {
  clearAggregateGifteeBoxSelectedContentPercentage,
  mountExchangeReport
} from '@console/actions/actions';
import { aggregateGifteeBoxSelectedContentPercentageThunk } from '@console/actions/thunks/aggregateGifteeBoxSelectedContentPercentage';
import ExchangeReport from '@console/components/project/gifteeBoxConfigs/ExchangeReport';
import {
  getSortedAggregatedGifteeBoxSelectedContentResults,
  getRemainingContentsSelectedPercentage,
  getAggregatedGifteeBoxSelectedContentResultsForCsvExport
} from '@console/selectors/aggregatedGifteeBoxSelectedContentResults';
import { getSelectedGifteeBoxConfig } from '@console/selectors/projectDetail/gifteeBoxConfigs';
import {
  getIsBulkProject,
  getIsApiProject,
  getIsGcpProject
} from '@console/selectors/projectDetail/solutionSystem';
import { connect } from 'react-redux';
import type { AppThunkDispatch } from '@console/actions/thunkType';
import type { AppState } from '@console/reducers';

const mapStateToProps = (state: AppState) => ({
  aggregatedGifteeBoxSelectedContentResults:
    getSortedAggregatedGifteeBoxSelectedContentResults(state),
  selectedGifteeBoxConfig: getSelectedGifteeBoxConfig(state),
  remainingContentsSelectedPercentage:
    getRemainingContentsSelectedPercentage(state),
  aggregatedGifteeBoxSelectedContentResultsForCsvExport:
    getAggregatedGifteeBoxSelectedContentResultsForCsvExport(state),
  isApiProject: getIsApiProject(state),
  isBulkProject: getIsBulkProject(state),
  isGcpProject: getIsGcpProject(state)
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  onMount: (gifteeBoxConfigUrlCode: UrlCode) =>
    dispatch(mountExchangeReport(gifteeBoxConfigUrlCode)),
  getAggregateGifteeBoxSelectedContentPercentage: (
    gifteeBoxConfigUrlCode: UrlCode
  ) =>
    dispatch(
      aggregateGifteeBoxSelectedContentPercentageThunk(gifteeBoxConfigUrlCode)
    ),
  deleteAggregateGifteeBoxSelectedContentPercentage: () =>
    dispatch(clearAggregateGifteeBoxSelectedContentPercentage())
});

export type ContentPercentageTablePropsMappedFromState = ReturnType<
  typeof mapStateToProps
>;

export type ContentPercentageTablePropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(mapStateToProps, mapDispatchToProps)(ExchangeReport);
