import Grid from '@mui/material/Grid';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import RootContainer from '../../containers/projectDraft/RootContainer';
import SideStepperContainer from '../../containers/projectDraft/SideStepperContainer';
import SyncStatusContainer from '../../containers/projectDraft/SyncStatusContainer';
import type {
  EditPropsMappedFromState,
  EditPropsMappedFromDispatch
} from '../../containers/projectDraft/EditContainer';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {},
    contents: {
      paddingLeft: 200,
      paddingBottom: theme.spacing(13)
    }
  });

type PathParams = {
  urlCode?: string;
};

type EditProps = EditPropsMappedFromState &
  EditPropsMappedFromDispatch &
  WithStyles<typeof styles>;

export const steps = [
  ['キャンペーン概要'],
  ['ギフトの配布方法'],
  [
    'ギフト設定',
    'チケットタイプ選択',
    '商品選択',
    '商品/数量選択',
    'メッセージカードの有無選択',
    'メッセージカード画像選択'
  ],
  ['最終確認']
];

export type ActiveStep = {
  parentStep?: string;
  childStep?: string;
};

const Edit: React.FC<EditProps> = ({
  classes,
  urlCode,
  getProjectDrafts,
  updateProjectDraftIfNeeded,
  initilizeProjectDraftSyncStatus,
  excludeInvalidProjectDraftData
}) => {
  const navigate = useNavigate();
  const params = useParams<PathParams>();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    let intervalID: number = 0;
    if (params.urlCode) {
      getProjectDrafts(params.urlCode, navigate)
        .then(res => {
          setLoaded(true);
          intervalID = window.setInterval(updateProjectDraftIfNeeded, 5000);

          excludeInvalidProjectDraftData();
        })
        .catch(() => {});
    }

    return () => {
      cleanup(intervalID);
    };
  }, [urlCode]);

  const cleanup = (intervalID: number): void => {
    updateProjectDraftIfNeeded().finally(() => {
      initilizeProjectDraftSyncStatus();
    });
    window.clearInterval(intervalID!);
  };

  return !loaded ? null : (
    <Grid
      container
      direction="column"
      justifyContent="center"
      className={classes.root}
    >
      <SideStepperContainer />
      <Grid container justifyContent="center" className={classes.contents}>
        <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
          <SyncStatusContainer />
        </Grid>
        {/* ステップに基づくコンテンツの表示 */}
        <RootContainer />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(Edit);
