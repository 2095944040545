import { getLocalString } from '@common/lib/getLocalString';
import {
  getLocaleDateString,
  getLocaleEndDateString
} from '@common/lib/getLocaleDate';
import { getLocalePrice } from '@common/lib/getLocalePrice';
import {
  GIFTEE_BOX_DISPLAY_NAME,
  GIFTEE_BOX_TEMPLATE_BRAND_NAME
} from '@console/common/constants';
import { ProjectContents } from '@console/components/MainContents';
import ContentBrief from '@console/components/common/ContentBrief';
import { faFileCsv } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import type {
  GCPTablePropsMappedFromDispatch,
  GCPTablePropsMappedFromState
} from '@console/containers/project/selectionReport/GCPTableContainer';
import type { Theme } from '@mui/material';

import type { StyleRules, WithStyles } from '@mui/styles';

// apiの有効期限は最新の設定だけを表示する
const LAST_API_ISSUE_CONFIG_ITEM_INDEX = -1;

const styles = (theme: Theme): StyleRules => ({
  tableContainer: {
    overflowX: 'scroll',
    whiteSpace: 'nowrap'
  },
  contentTableCell: {
    width: '20%',
    minWidth: 400,
    textAlign: 'center'
  },
  tableCell: {
    width: '20%',
    minWidth: 100
  },
  nextButtonTableCell: {
    width: '20%',
    minWidth: 200
  },
  empty: {
    paddingTop: theme.spacing(4),
    textAlign: 'center'
  },
  emptyGifteeBoxSelectionReport: {
    margin: theme.spacing(5),
    color: theme.palette.icon.file
  }
});

type BulkTableProps = GCPTablePropsMappedFromState &
  GCPTablePropsMappedFromDispatch &
  WithStyles<typeof styles>;

const GCPTable: React.FC<BulkTableProps> = ({
  gifteeBoxConfigs,
  onClickGifteeBoxConfig,
  classes
}) => {
  const navigate = useNavigate();

  return (
    <>
      {!!gifteeBoxConfigs && gifteeBoxConfigs.length !== 0 ? (
        <div className={classes.tableContainer}>
          <Table>
            <TableHead data-cy="tableHeader">
              <TableRow>
                <TableCell>
                  <Typography>ギフト名</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography>単価</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography>数量</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>発行有効期限</Typography>
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {!!gifteeBoxConfigs &&
                gifteeBoxConfigs.map((gifteeBoxConfig, gifteeBoxConfigIndex) =>
                  gifteeBoxConfig.apiGifteeBoxIssueConfigs
                    .slice(LAST_API_ISSUE_CONFIG_ITEM_INDEX)
                    .map(
                      (apiGifteeBoxIssueConfig, apiGifteeBoxIssueConfigIndex) =>
                        apiGifteeBoxIssueConfig.issueEstimateOptions.map(
                          (issueEstimateOption, issueEstimateOptionIndex) => (
                            <TableRow
                              key={`${gifteeBoxConfigIndex}-${apiGifteeBoxIssueConfigIndex}-${issueEstimateOptionIndex}`}
                              data-cy={`gifteeBoxConfig-tableRow-${gifteeBoxConfig.urlCode}`}
                            >
                              {issueEstimateOptionIndex === 0 && (
                                <TableCell
                                  rowSpan={
                                    apiGifteeBoxIssueConfig.issueEstimateOptions
                                      .length
                                  }
                                  className={classes.contentTableCell}
                                  data-cy={`gifteeBoxConfig-tableCell-contentBrief-${gifteeBoxConfig.urlCode}`}
                                >
                                  <ContentBrief
                                    contentName={
                                      gifteeBoxConfig.gifteeBoxTemplate.name
                                    }
                                    contentImageUrl={
                                      gifteeBoxConfig.gifteeBoxTemplate.imageUrl
                                    }
                                    contentBrandName={
                                      GIFTEE_BOX_TEMPLATE_BRAND_NAME
                                    }
                                  />
                                </TableCell>
                              )}
                              <TableCell
                                align="right"
                                className={classes.tableCell}
                                data-cy={`gifteeBoxConfig-tableCell-initialPoint-${gifteeBoxConfig.urlCode}`}
                              >
                                <Typography
                                  key={`${gifteeBoxConfig.urlCode}-${apiGifteeBoxIssueConfigIndex}-${issueEstimateOptionIndex}`}
                                  data-cy={`gifteeBoxConfig-tableCell-initialPoint-${gifteeBoxConfig.urlCode}-${apiGifteeBoxIssueConfigIndex}-${issueEstimateOptionIndex}`}
                                >
                                  {getLocalePrice(
                                    issueEstimateOption.initialPoint
                                  )}
                                </Typography>
                              </TableCell>
                              <TableCell
                                align="right"
                                className={classes.tableCell}
                                data-cy={`gifteeBoxConfig-tableCell-issueAmount-${gifteeBoxConfig.urlCode}`}
                              >
                                <Typography
                                  data-cy={`gifteeBoxConfig-tableCell-issueAmount-${gifteeBoxConfig.urlCode}-${apiGifteeBoxIssueConfigIndex}-${issueEstimateOptionIndex}`}
                                >
                                  {getLocalString(
                                    issueEstimateOption.issueAmount
                                  )}
                                </Typography>
                              </TableCell>
                              {issueEstimateOptionIndex === 0 ? (
                                <>
                                  <TableCell
                                    className={classes.tableCell}
                                    align="center"
                                    rowSpan={
                                      apiGifteeBoxIssueConfig
                                        .issueEstimateOptions.length
                                    }
                                    data-cy={`gifteeBoxConfig-tableCell-issuableEndAt-${gifteeBoxConfig.urlCode}`}
                                  >
                                    <Typography>
                                      {getLocaleEndDateString(
                                        apiGifteeBoxIssueConfig.issuableEndAt
                                      )}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    rowSpan={
                                      apiGifteeBoxIssueConfig
                                        .issueEstimateOptions.length
                                    }
                                    align="center"
                                    className={classes.nextButtonTableCell}
                                  >
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      data-cy={`gifteeBoxConfig-tableCell-nextPageButton-${gifteeBoxConfig.urlCode}`}
                                      onClick={() =>
                                        onClickGifteeBoxConfig(
                                          navigate,
                                          gifteeBoxConfig.urlCode
                                        )
                                      }
                                    >
                                      選択実績を見る
                                      <KeyboardArrowRightIcon />
                                    </Button>
                                  </TableCell>
                                </>
                              ) : null}
                            </TableRow>
                          )
                        )
                    )
                )}
            </TableBody>
          </Table>
        </div>
      ) : (
        <div className={classes.empty}>
          <FontAwesomeIcon
            icon={faFileCsv}
            size="3x"
            className={classes.emptyGifteeBoxSelectionReport}
            data-cy="dataEmptyIcon"
          />
          <Typography
            variant="subtitle1"
            color="textSecondary"
            data-cy="dataEmptyString"
          >{`${GIFTEE_BOX_DISPLAY_NAME}選択実績はありません`}</Typography>
        </div>
      )}
    </>
  );
};

export default withStyles(styles)(GCPTable);
