import {
  validateDeliveryForBulkProject,
  validateDeliveryForApiProject,
  validateGifteeBoxDeliveryConfigForBulkProject,
  validateGifteeBoxDeliveryConfigForGcpProject,
  validateGifteeBoxDeliveryConfigForApiProject
} from '@console/actions/actions';
import {
  isGiftCardGiftConfig,
  isGifteeBoxGiftConfig
} from '@console/common/projectDraft/giftConfig';
import {
  projectDraftDeliveryHasValidationErrors,
  projectDraftGifteeBoxDeliveryConfigHasValidationErrors
} from '@console/middleware/validator/projectDraft/giftConfigs';
import {
  isBulkSolutionSystemSelected,
  isApiSolutionSystemSelected,
  isGcpSolutionSystemSelected,
  getSelectedProjectDraftSelectedGiftConfig
} from '@console/selectors/getSelectedProjectDraftForDisplay';
import type { AppThunkAction } from '@console/actions/thunkType';

export const validateDeliveryConfigThunk =
  (): AppThunkAction<Promise<void>> => (dispatch, getState) => {
    const selectedGiftConfig =
      getSelectedProjectDraftSelectedGiftConfig(getState());

    if (isGiftCardGiftConfig(selectedGiftConfig)) {
      return dispatch(validateGiftConfigDeliveryConfigThunk());
    } else if (isGifteeBoxGiftConfig(selectedGiftConfig)) {
      return dispatch(validateGifteeBoxDeliveryConfigThunk());
    }
    return Promise.reject();
  };

export const validateGiftConfigDeliveryConfigThunk =
  (): AppThunkAction<Promise<void>> => (dispatch, getState) => {
    const { projectJson } = getState().selectedProjectDraft;
    if (isBulkSolutionSystemSelected(getState())) {
      dispatch(validateDeliveryForBulkProject(projectJson));
    }
    if (
      isApiSolutionSystemSelected(getState()) ||
      isGcpSolutionSystemSelected(getState())
    ) {
      dispatch(validateDeliveryForApiProject(projectJson));
    }

    const { errors } = getState();
    const { selectedGiftConfigIndex } = projectJson;
    if (
      projectDraftDeliveryHasValidationErrors(errors, selectedGiftConfigIndex)
    ) {
      return Promise.reject();
    }
    return Promise.resolve();
  };

export const validateGifteeBoxDeliveryConfigThunk =
  (): AppThunkAction<Promise<void>> => (dispatch, getState) => {
    const { projectJson } = getState().selectedProjectDraft;

    if (isBulkSolutionSystemSelected(getState())) {
      dispatch(validateGifteeBoxDeliveryConfigForBulkProject(projectJson));
    } else if (isGcpSolutionSystemSelected(getState())) {
      dispatch(validateGifteeBoxDeliveryConfigForGcpProject(projectJson));
    } else if (isApiSolutionSystemSelected(getState())) {
      dispatch(validateGifteeBoxDeliveryConfigForApiProject(projectJson));
    }

    const { errors } = getState();
    const { selectedGiftConfigIndex } = projectJson;
    if (
      projectDraftGifteeBoxDeliveryConfigHasValidationErrors(
        errors,
        selectedGiftConfigIndex
      )
    ) {
      return Promise.reject();
    }
    return Promise.resolve();
  };
