import moment from '@common/lib/moment';
import { Alert } from '@mui/lab/';
import { Grid, Link } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { useMemo } from 'react';
import type { Theme } from '@mui/material';

import type { StyleRules, WithStyles } from '@mui/styles';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    wrapper: {
      width: '100%',
      marginBottom: theme.spacing(2)
    },
    alert: {
      width: 'fit-content'
    }
  });

type MaintenanceMessageOwnProps = {
  message: string;
  linkUrl: string | null;
};

type MaintenanceMessageProps = MaintenanceMessageOwnProps &
  WithStyles<typeof styles>;

const MaintenanceMessage: React.FC<MaintenanceMessageProps> = ({
  message,
  linkUrl,
  classes
}) => (
  <Grid className={classes.wrapper} data-cy="maintenanceMessage">
    <Alert className={classes.alert} severity="warning">
      {linkUrl ? (
        <Link
          href={linkUrl}
          target="_blank"
          rel="noopener"
          underline="always"
          color="textPrimary"
          data-cy="maintenanceMessageLink"
        >
          {message}
        </Link>
      ) : (
        message
      )}
    </Alert>
  </Grid>
);

export default withStyles(styles)(MaintenanceMessage);
