import { getGifteeBoxTemplateBrands } from '@common/api/graphql/getGifteeBoxTemplateBrandsGql';
import {
  getGifteeBoxTemplateBrandsStart,
  getGifteeBoxTemplateBrandsSuccess,
  getGifteeBoxTemplateBrandsFailure
} from '../actions';
import handleApiErrorIfNeeded from './handleApiErrorIfNeeded';
import type { AppThunkAction } from '../thunkType';
import type { ApiError } from '@common/api/graphql/apolloClient';
import type { GetGifteeBoxTemplateBrandsResponse } from '@common/api/graphql/getGifteeBoxTemplateBrandsGql';

export const getGifteeBoxTemplateBrandsSuccessThunk =
  (
    response: GetGifteeBoxTemplateBrandsResponse
  ): AppThunkAction<Promise<void>> =>
  dispatch => {
    const { brands } = response.data!.gifteeBoxTemplate;

    dispatch(getGifteeBoxTemplateBrandsSuccess(brands));
    return Promise.resolve();
  };

export const getGifteeBoxTemplateBrandsFailureThunk =
  (error: ApiError): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(getGifteeBoxTemplateBrandsFailure());
    return dispatch(handleApiErrorIfNeeded(error));
  };

export const getGifteeBoxTemplateBrandsThunk =
  (gifteeBoxTemplateUrlCode: UrlCode): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(getGifteeBoxTemplateBrandsStart());

    return getGifteeBoxTemplateBrands(gifteeBoxTemplateUrlCode)
      .then((response: GetGifteeBoxTemplateBrandsResponse) =>
        dispatch(getGifteeBoxTemplateBrandsSuccessThunk(response))
      )
      .catch(error => dispatch(getGifteeBoxTemplateBrandsFailureThunk(error)));
  };
