import withStyles from '@mui/styles/withStyles';
import TicketTypeChip, { getGiftCardTicketType } from './TicketTypeChip';
import type { QueryGiftCardConfig } from '@common/api/graphql/getProjectsGql';
import type { Theme } from '@mui/material';
import type { StyleRules, WithStyles } from '@mui/styles';

const styles = (theme: Theme): StyleRules => ({});

type TicketTypeChipsOwnProps = {
  giftCardConfig: QueryGiftCardConfig;
};

type TicketTypeChipsProps = TicketTypeChipsOwnProps & WithStyles<typeof styles>;

const TicketTypeChips: React.FC<TicketTypeChipsProps> = ({
  giftCardConfig,
  classes
}) => (
  <div data-cy={`ticketTypeChips-${giftCardConfig.urlCode}`}>
    {giftCardConfig.giftCardTicketConfigs.map(
      (giftCardTicketConfig, giftCardTicketConfigIndex) => (
        <TicketTypeChip
          key={`ticketTypeChip-${giftCardConfig.urlCode}-${giftCardTicketConfigIndex}`}
          ticketType={getGiftCardTicketType(giftCardTicketConfig)}
        />
      )
    )}
  </div>
);

export default withStyles(styles)(TicketTypeChips);
