import { createSelector } from 'reselect';
import type { AppState } from '@console/reducers';
import type { LocalProject } from '@console/reducers/projects';

const getProjectDetailHostCompany = (state: AppState) =>
  state.projectDetail.hostCompany;

export const getHostCompany = createSelector(
  [getProjectDetailHostCompany],
  (hostCompany): LocalProject['hostCompany'] => ({
    name: hostCompany.name ?? '',
    isOtherCompanyHosting: hostCompany.isOtherCompanyHosting ?? false
  })
);
