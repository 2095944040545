import APITableContainer from '@console/containers/project/selectionReport/APITableContainer';
import BulkTableContainer from '@console/containers/project/selectionReport/BulkTableContainer';
import GCPTableContainer from '@console/containers/project/selectionReport/GCPTableContainer';
import withStyles from '@mui/styles/withStyles';
import type {
  SelectedGifteeBoxReportsPropsMappedFromDispatch,
  SelectedGifteeBoxReportsPropsMappedFromState
} from '@console/containers/project/SelectionReportContainer';
import type { Theme } from '@mui/material';
import type { StyleRules, WithStyles } from '@mui/styles';

const styles = (theme: Theme): StyleRules => ({});

type AggregateGifteeBoxSelectedContentPercentageProps =
  SelectedGifteeBoxReportsPropsMappedFromState &
    SelectedGifteeBoxReportsPropsMappedFromDispatch &
    WithStyles<typeof styles>;

const SelectionReport: React.FC<
  AggregateGifteeBoxSelectedContentPercentageProps
> = ({ isApiProject, isGcpProject, isBulkProject, classes }) => (
  <>
    {isApiProject && <APITableContainer />}
    {isBulkProject && <BulkTableContainer />}
    {isGcpProject && <GCPTableContainer />}
  </>
);

export default withStyles(styles)(SelectionReport);
