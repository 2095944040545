import Loader from '@common/components/Loader';
import SnackbarMessage from '@common/components/SnackbarMessage';
import ZendeskWidget from '@console/components/common/ZendeskWidget';
import CssBaseline from '@mui/material/CssBaseline';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { Component } from 'react';
import MainContentsContainer from '../containers/MainContentsContainer';
import NavbarContainer from '../containers/common/NavbarContainer';
import type {
  AppPropsMappedFromState,
  AppPropsMappedFromDispatch
} from '../containers/AppContainer';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      wordBreak: 'break-all'
    }
  });

type AppProps = AppPropsMappedFromState &
  AppPropsMappedFromDispatch &
  WithStyles<typeof styles>;

type AppLocalState = {
  initialDataLoaded: boolean;
};

class App extends Component<AppProps, AppLocalState> {
  constructor(props: AppProps) {
    super(props);
    this.state = {
      initialDataLoaded: false
    };
  }

  componentDidMount() {
    const { getInitialData } = this.props;
    getInitialData()
      .then(() => this.setState({ initialDataLoaded: true }))
      .catch(() => {});
  }

  render() {
    const {
      classes,
      isLoggedIn,
      isLoading,
      distributionPartnerUser,
      snackbarMessage,
      handleClose
    } = this.props;
    const { initialDataLoaded } = this.state;
    if (!isLoggedIn || !initialDataLoaded) {
      return (
        <div className={classes.root}>
          <CssBaseline />
          <Loader isLoading={isLoading} />
        </div>
      );
    }
    return (
      <div className={classes.root}>
        <SnackbarMessage
          snackbarMessage={snackbarMessage}
          handleClose={handleClose}
        />
        <CssBaseline />
        <Loader isLoading={isLoading} />
        <NavbarContainer />
        <MainContentsContainer />
        <ZendeskWidget distributionPartnerUser={distributionPartnerUser} />
      </div>
    );
  }
}

export default withStyles(styles)(App);
