import moment from '@common/lib/moment';
import GifteeBoxInitialPointInput from '@console/components/common/GifteeBoxInitialPointInput';
import GifteeBoxTemplateBrief from '@console/components/common/GifteeBoxTemplateBrief';
import TicketTypeChip from '@console/components/projectDraft/common/TicketTypeChip';
import GifteeBoxTemplateContentSelectionsModalContainer from '@console/containers/common/GifteeBoxTemplateContentSelectionsModalContainer';
import {
  Grid,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  FormControl
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { DesktopDatePicker as DatePicker } from '@mui/x-date-pickers';
import { useState, useEffect } from 'react';
import type {
  BulkTablePropsMappedFromDispatch,
  BulkTablePropsMappedFromState
} from '@console/containers/projectDraft/deliveryConfig/gifteeBoxDevliveryConfig/BulkTableContainer';
import type { SelectedGifteeBoxTemplate } from '@console/reducers/projectJson/giftConfigs';
import type { Theme } from '@mui/material';
import type { StyleRules, WithStyles } from '@mui/styles';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    table: {
      margin: `1% 0`
    },
    tableCell: {
      padding: theme.spacing(2),
      verticalAlign: 'middle',
      minWidth: 130
    },
    contentCell: {
      paddingRight: theme.spacing(2),
      width: theme.spacing(52)
    },
    contentImage: {
      width: theme.spacing(10),
      verticalAlign: 'middle',
      margin: `${theme.spacing()} 0`
    },
    contentImageContainer: {
      marginRight: theme.spacing(2)
    },
    gifteeBoxPrice: {
      width: '100%'
    },
    errorMessage: {
      minHeight: theme.spacing(3),
      fontSize: 14
    }
  });

type BulkTableProps = BulkTablePropsMappedFromDispatch &
  BulkTablePropsMappedFromState &
  WithStyles<typeof styles>;

const BulkTable: React.FC<BulkTableProps> = ({
  classes,
  giftConfig,
  initialPointCandidates,
  selectedGifteeBoxTemplate,
  giftConfigErrors,
  onChangeGifteeBoxInitialPoint,
  onInputChangeGifteeBoxInitialPoint,
  onChangeDeliveryDate,
  onChangeIssueAmount,
  selectGifteeBoxTemplate,
  deselectGifteeBoxTemplate,
  onMount
}) => {
  if (!giftConfig) return null;

  useEffect(() => {
    onMount();
  }, []);

  const [
    gifteeBoxTemplateContentSelectionsModalOpen,
    setGifteeBoxTemplateContentSelectionsModalOpen
  ] = useState(false);

  const openGifteeBoxTemplateContentSelectionsModal = (
    gifteeBoxTemplate: NonNullable<SelectedGifteeBoxTemplate>
  ) => {
    selectGifteeBoxTemplate(gifteeBoxTemplate);
    setGifteeBoxTemplateContentSelectionsModalOpen(true);
  };

  const closeGifteeBoxTemplateContentSelectionsModal = () => {
    setGifteeBoxTemplateContentSelectionsModalOpen(false);
    deselectGifteeBoxTemplate();
  };

  return giftConfig.selectedGifteeBoxTemplate === null ? null : (
    <Table className={classes.table} data-cy="gifteeBoxDeliveryConfigBulkTable">
      <TableHead>
        <TableRow data-cy="gifteeBoxDeliveryConfigBulkTableHeader">
          <TableCell className={classes.tableCell} align="center">
            ギフトのタイプ
          </TableCell>
          <TableCell className={classes.contentCell} align="left">
            ギフト・ブランド名
          </TableCell>
          <TableCell className={classes.tableCell} align="right">
            単価
          </TableCell>
          <TableCell className={classes.tableCell} align="right">
            数量（見込み）
          </TableCell>
          <TableCell className={classes.tableCell} align="right">
            納品希望日
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell
            className={classes.tableCell}
            align="center"
            data-cy="gifteeBoxDeliveryConfigBulkTableTicketType"
          >
            <TicketTypeChip ticketType={giftConfig.ticketType} />
          </TableCell>
          <TableCell className={classes.contentCell} align="left">
            <Grid container alignItems="center">
              <GifteeBoxTemplateBrief
                gifteeBoxTemplateName={
                  giftConfig.selectedGifteeBoxTemplate.name
                }
                gifteeBoxTemplateImageUrl={
                  giftConfig.selectedGifteeBoxTemplate.imageUrl
                }
                exchangeableEndLabel={
                  giftConfig.selectedGifteeBoxTemplate.exchangeableEndLabel
                }
                isLimitedPublished={
                  giftConfig.selectedGifteeBoxTemplate.isLimitedPublished
                }
                handleButtonClick={() =>
                  openGifteeBoxTemplateContentSelectionsModal(
                    giftConfig.selectedGifteeBoxTemplate!
                  )
                }
              />
            </Grid>
          </TableCell>
          <TableCell className={classes.tableCell} align="right">
            <FormControl
              variant="standard"
              required
              className={classes.gifteeBoxPrice}
            >
              <GifteeBoxInitialPointInput
                initialPoint={giftConfig.gifteeBoxInitialPoint}
                initialPointCandidates={initialPointCandidates}
                maxPoint={selectedGifteeBoxTemplate?.supportedPoint.maxPoint}
                minPoint={selectedGifteeBoxTemplate?.supportedPoint.minPoint}
                step={selectedGifteeBoxTemplate?.supportedPoint.step}
                isError={!!giftConfigErrors?.gifteeBoxInitialPoint}
                onInputChange={onInputChangeGifteeBoxInitialPoint}
                onChange={onChangeGifteeBoxInitialPoint}
              />
            </FormControl>
            <Typography className={classes.errorMessage} color="error">
              {giftConfigErrors?.gifteeBoxInitialPoint}
            </Typography>
          </TableCell>
          <TableCell className={classes.tableCell} align="right">
            <TextField
              variant="standard"
              name="numOfGiftDelivery"
              value={giftConfig.issueAmount}
              type="number"
              onChange={onChangeIssueAmount}
              inputProps={{ min: '1' }}
              error={!!giftConfigErrors?.issueAmount}
              data-cy="gifteeBoxDeliveryConfigBulkTableIssueAmount"
            />
            <Typography className={classes.errorMessage} color="error">
              {giftConfigErrors?.issueAmount}
            </Typography>
          </TableCell>
          <TableCell className={classes.tableCell} align="right">
            <DatePicker
              value={moment(giftConfig.deliveryDate)}
              minDate={moment().add(1, 'd')}
              maxDate={moment().add(1, 'y')}
              className={classes.dateInput}
              onChange={onChangeDeliveryDate}
              data-cy="gifteeBoxDeliveryConfigBulkTableDeliveryDate"
              slotProps={{
                textField: {
                  variant: 'standard',
                  error: !!giftConfigErrors?.deliveryDate
                }
              }}
            />
            <Typography className={classes.errorMessage} color="error">
              {giftConfigErrors?.deliveryDate}
            </Typography>
          </TableCell>
        </TableRow>
      </TableBody>
      <GifteeBoxTemplateContentSelectionsModalContainer
        isOpen={gifteeBoxTemplateContentSelectionsModalOpen}
        handleClose={closeGifteeBoxTemplateContentSelectionsModal}
      />
    </Table>
  );
};

export default withStyles(styles)(BulkTable);
