import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    modal: {
      maxWidth: 600,
      margin: 'auto'
    },
    paper: {
      height: 500,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    title: {
      marginBottom: theme.spacing(5),
      fontWeight: 'bold',
      padding: 0
    },
    contents: {
      padding: 0
    },
    subtitle: {
      marginBottom: 0
    }
  });

type UnchoosableModalOwnProps = {
  open: boolean;
  choosablePeriodText: string;
};

type UnchoosableModalProps = UnchoosableModalOwnProps &
  WithStyles<typeof styles>;

const UnchoosableModal: React.FC<UnchoosableModalProps> = ({
  classes,
  open,
  choosablePeriodText
}) => (
  <Dialog
    open={open}
    aria-labelledby="unchoosableModalTitle"
    aria-describedby="unchoosableModalDescription"
    className={classes.modal}
    scroll="paper"
  >
    <DialogContent className={classes.paper}>
      <DialogTitle
        id="unchoosableModalTitle"
        className={classes.title}
        data-cy="unchoosableModalTitle"
      >
        <DialogContentText variant="h6" color="textPrimary">
          ギフトの選択期間外です。
        </DialogContentText>
      </DialogTitle>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={classes.contents}
      >
        <DialogContentText variant="subtitle1" className={classes.subtitle}>
          ギフトの選択期間
        </DialogContentText>
        <DialogContentText
          variant="h6"
          data-cy="unchoosableModalChoosablePeriod"
        >
          {choosablePeriodText}
        </DialogContentText>
      </Grid>
    </DialogContent>
  </Dialog>
);

export default withStyles(styles)(UnchoosableModal);
