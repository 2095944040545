import { createSelector } from 'reselect';
import type { AppState } from '@console/reducers';
import type { LocalProject } from '@console/reducers/projects';

const getProjectDetailMessageThread = (state: AppState) =>
  state.projectDetail.messageThread;

export const getMessageThread = createSelector(
  [getProjectDetailMessageThread],
  (messageThread): LocalProject['messageThread'] => ({
    urlCode: messageThread.urlCode ?? ''
  })
);

const getMessageThreadInputMessages = (state: AppState) =>
  state.messageThreadInputMessages;

export const getMessageThreadInputMessage = createSelector(
  [getMessageThread, getMessageThreadInputMessages],
  (messageThread, inputMessages): string =>
    messageThread ? (inputMessages[messageThread.urlCode] ?? '') : ''
);
