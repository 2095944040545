import { gql } from '@apollo/client';
import { internalApiAppClient } from './apolloClient';
import { operationNames } from './operationNames';
import type { MutationErrors } from './mutationErrorType';
import type { FetchResult } from '@apollo/client';
import type { AddingBulkGiftCardIssueConfigs } from '@console/reducers/edit/project/giftCardConfigs/addingBulkGiftCardIssueConfigs';

const addBulkGiftCardIssueConfigsGql = gql`
  mutation ${operationNames.ADD_BULK_GIFT_CARD_ISSUE_CONFIGS}($giftCardConfigUrlCode: String!, $bulkGiftCardIssueConfigs: [BulkGiftCardIssueConfigInput!]!) {
    addBulkGiftCardIssueConfigs(input: { giftCardConfigUrlCode: $giftCardConfigUrlCode, bulkGiftCardIssueConfigs: $bulkGiftCardIssueConfigs }) {
      bulkGiftCardIssueConfigs {
        urlCode
      }
      errors {
        __typename
        message
        path
      }
    }
  }
`;

type BulkGiftCardIssueConfig = {
  urlCode: UrlCode;
};

type BulkGiftCardIssueConfigs = BulkGiftCardIssueConfig[];

export type AddBulkGiftCardIssueConfigsResponse = FetchResult<{
  addBulkGiftCardIssueConfigs: {
    bulkGiftCardIssueConfigs: BulkGiftCardIssueConfigs;
    errors: MutationErrors;
  };
}>;

export const addBulkGiftCardIssueConfigs = (
  giftCardConfigUrlCode: UrlCode,
  bulkGiftCardIssueConfigs: AddingBulkGiftCardIssueConfigs
): Promise<AddBulkGiftCardIssueConfigsResponse> =>
  internalApiAppClient.mutate({
    mutation: addBulkGiftCardIssueConfigsGql,
    variables: {
      giftCardConfigUrlCode,
      bulkGiftCardIssueConfigs: bulkGiftCardIssueConfigs.map(
        bulkGiftCardIssueConfig => ({
          ...bulkGiftCardIssueConfig,
          requestedIssueAmount: Number(
            bulkGiftCardIssueConfig.requestedIssueAmount
          )
        })
      )
    }
  });
