import {
  isGiftCardChoosableTicketConfig,
  isGiftCardFixedTicketConfig
} from '@console/common/project/giftCardTicketConfig';
import ContentRow from '@console/components/common/ContentRow';
import { Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import type { Content } from '@common/api/graphql/getContentsGql';
import type { LocalGiftCardTicketConfig } from '@console/reducers/projects';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';
import type { ReactElement } from 'react';

const styles = (theme: Theme): StyleRules => ({});

type GiftCardTicketConfigContentsOwnProps = {
  giftCardTicketConfig: LocalGiftCardTicketConfig;
  imageDownloadable?: boolean;
};

type GiftCardTicketConfigContentsProps = GiftCardTicketConfigContentsOwnProps &
  WithStyles<typeof styles>;

const GiftCardTicketConfigContents: React.FC<
  GiftCardTicketConfigContentsProps
> = ({ giftCardTicketConfig, imageDownloadable, classes }) => {
  const renderContent = (
    content: Content,
    availablePeriodText: string,
    selectablePeriodText?: string
  ): ReactElement => (
    <ContentRow
      contentName={content.name}
      contentInternalName={content.internalName}
      contentImageUrl={content.imageUrl}
      contentAvailablePeriodText={availablePeriodText}
      selectablePeriodText={selectablePeriodText}
      contentBrandName={content.brand.name}
      imageDownloadUrl={imageDownloadable ? content.imageUrl : undefined}
    />
  );

  const renderFixedContent = (content): ReactElement =>
    renderContent(content, `有効期限：${content.availablePeriod}`);

  const renderChoosableContent = (content): ReactElement =>
    renderContent(
      content,
      `選択後の有効期限：${content.availablePeriod}`,
      '選択期限：1ヶ月後の月末'
    );

  if (isGiftCardFixedTicketConfig(giftCardTicketConfig)) {
    const { content } = giftCardTicketConfig.fixedContentOption;
    return renderFixedContent(content);
  }

  if (isGiftCardChoosableTicketConfig(giftCardTicketConfig)) {
    return (
      <Grid container direction="column">
        {giftCardTicketConfig.choosableContentOption.choices
          .sort((a, b) => a.order - b.order)
          .map(choice => {
            const { content } = choice;
            return (
              <Grid
                item
                key={`giftCardTicketConfigChoosableContent-${choice.content.urlCode}`}
              >
                {renderChoosableContent(content)}
              </Grid>
            );
          })}
      </Grid>
    );
  }

  return null;
};

export default withStyles(styles)(GiftCardTicketConfigContents);
