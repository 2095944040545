import { getHasProjectAddingBulkGiftCardIssueConfigsError } from '@console/selectors/errors';
import { validateProjectAddingBulkGiftCardIssueConfigs } from '../actions';
import type { AppThunkAction } from '../thunkType';

export const validateProjectAddingBulkGiftCardIssueConfigsThunk =
  (): AppThunkAction<Promise<void>> => (dispatch, getState) => {
    dispatch(validateProjectAddingBulkGiftCardIssueConfigs());

    if (getHasProjectAddingBulkGiftCardIssueConfigsError(getState())) {
      return Promise.reject();
    }
    return Promise.resolve();
  };
