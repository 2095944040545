import ComponentLoader from '@common/components/ComponentLoader';
import { getG4bMyPageProjectsUrl } from '@console/common/g4bMyPage';
import { Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import FloatingButtonContainer from '../../containers/project/FloatingButtonContainer';
import MessageThreadMessagesContainer from '../../containers/project/MessageThreadMessagesContainer';
import MessageThreadSideContentsContainer from '../../containers/project/MessageThreadSideContentsContainer';
import type {
  MessageThreadPropsMappedFromState,
  MessageThreadPropsMappedFromDispatch
} from '../../containers/project/MessageThreadContainer';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => ({
  containerGrid: {
    height: '100%',
    overflow: 'hidden'
  }
});

type PathParams = {
  urlCode?: string;
};

type MessageThreadProps = MessageThreadPropsMappedFromState &
  MessageThreadPropsMappedFromDispatch &
  WithStyles<typeof styles>;

const MessageThread: React.FC<MessageThreadProps> = ({
  initializeSelectedProject,
  isSampleProject,
  classes
}) => {
  const params = useParams<PathParams>();
  const [isProjectInitialized, setIsProjectInitialized] = useState(false);

  useEffect(() => {
    if (params.urlCode) {
      initializeSelectedProject(params.urlCode)
        .then(isMyPageProject => {
          if (isMyPageProject) {
            location.href = `${getG4bMyPageProjectsUrl()}/${params.urlCode}`;
          } else {
            setIsProjectInitialized(true);
          }
        })
        .catch(() => setIsProjectInitialized(true));
    }
  }, []);

  return (
    <>
      {!isProjectInitialized && <ComponentLoader isLoading={true} />}
      <Grid container className={classes.containerGrid}>
        {isProjectInitialized ? <MessageThreadSideContentsContainer /> : null}
        {isProjectInitialized ? <MessageThreadMessagesContainer /> : null}
      </Grid>
      {isSampleProject ? null : <FloatingButtonContainer />}
    </>
  );
};

export default withStyles(styles)(MessageThread);
