import {
  isFixedTicketType,
  isChoosableTicketType,
  isGifteeBoxTicketType,
  isFixedGiftConfig,
  isChoosableGiftConfig,
  isGifteeBoxGiftConfig
} from '@console/common/projectDraft/giftConfig';
import { createSelector } from 'reselect';
import type { Contents } from '../../../common/api/graphql/getContentsGql';
import type { AppState } from '../reducers';
import type {
  GiftConfigs,
  GiftConfig
} from '../reducers/projectJson/giftConfigs';
import type { ProjectDraft } from '../reducers/selectedProjectDraft';
import type { GifteeBoxGiftConfig } from '@console/reducers/projectJson/giftConfigs';

const getProjectDrafts = (state: AppState) => state.projectDrafts;
const getContents = (state: AppState) => state.contents;

export interface ProjectDraftForDisplay extends ProjectDraft {
  contentImageUrls: ContentImageUrl[];
}

export type ContentImageUrl = string | null;

const getProjectDraftsForDisplay = createSelector(
  [getProjectDrafts, getContents],
  (
    projectDrafts: ProjectDraft[],
    contents: Contents
  ): ProjectDraftForDisplay[] =>
    projectDrafts.map(projectDraft => {
      const giftConfigsContentImageUrls = getGiftConfigsContentImageUrls(
        projectDraft.projectJson.giftConfigs,
        contents
      );
      return {
        ...projectDraft,
        contentImageUrls: giftConfigsContentImageUrls
      };
    })
);

const getGiftConfigsContentImageUrls = (
  giftConfigs: GiftConfigs,
  contents: Contents
) =>
  giftConfigs
    .map(giftConfig => {
      if (isFixedGiftConfig(giftConfig) || isChoosableGiftConfig(giftConfig)) {
        return getGiftConfigContentImageUrls(giftConfig, contents);
      } else if (isGifteeBoxGiftConfig(giftConfig)) {
        return getGifteeBoxTemplateImageUrl(giftConfig);
      }
      return null;
    })
    .flat();

const getGiftConfigContentImageUrls = (
  giftConfig: GiftConfig,
  contents: Contents
) =>
  giftConfig.selectedContents.map(selectedContent => {
    const content = contents.find(
      content => content.urlCode === selectedContent
    );
    return content ? content.imageUrl : null;
  });

const getGifteeBoxTemplateImageUrl = (
  gifteeBoxGiftConfig: GifteeBoxGiftConfig
) => gifteeBoxGiftConfig.selectedGifteeBoxTemplate?.imageUrl || null;

export default getProjectDraftsForDisplay;
