import handleApiErrorIfNeeded from '@console/actions/thunks/handleApiErrorIfNeeded';
import { registerProjectDraft } from '../../../../common/api/graphql/registerProjectDraftGql';
import {
  registerProjectDraftStart,
  registerProjectDraftSuccess,
  registerProjectDraftFailure
} from '../actions';
import type { RegisterProjectDraftResponse } from '../../../../common/api/graphql/registerProjectDraftGql';
import type { AppThunkAction } from '../thunkType';
import type { ApiError } from '@common/api/graphql/apolloClient';

const registerProjectDraftSuccessThunk =
  (response: RegisterProjectDraftResponse): AppThunkAction<Promise<void>> =>
  dispatch => {
    const { urlCode } = response.data!.registerProjectDraft.projectDraft;
    dispatch(registerProjectDraftSuccess(urlCode));
    return Promise.resolve();
  };

const registerProjectDraftFailureThunk =
  (error: ApiError): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(registerProjectDraftFailure());
    return dispatch(handleApiErrorIfNeeded(error));
  };

export const registerProjectDraftThunk =
  (): AppThunkAction<Promise<void>> => (dispatch, getState) => {
    dispatch(registerProjectDraftStart());

    const projectDraft = {
      schemaVersion: getState().selectedProjectDraft.schemaVersion,
      projectJson: JSON.stringify(getState().selectedProjectDraft.projectJson)
    };

    return registerProjectDraft(projectDraft)
      .then((response: RegisterProjectDraftResponse) => {
        dispatch(registerProjectDraftSuccessThunk(response));
      })
      .catch(error => dispatch(registerProjectDraftFailureThunk(error)));
  };
