import {
  validateFixedContentSelection,
  validateChoosableContentsSelection,
  validateGifteeBoxTemplateSelection
} from '@console/actions/actions';
import {
  isChoosableGiftConfig,
  isFixedGiftConfig,
  isGifteeBoxGiftConfig
} from '@console/common/projectDraft/giftConfig';
import {
  projectDraftFixedContentSelectionHasValidationErrors,
  projectDraftChoosableContentsSelectionHasValidationErrors,
  projectDraftGifteeBoxTemplateSelectionHasValidationErrors
} from '@console/middleware/validator/projectDraft/giftConfigs';
import { getSelectedProjectDraftSelectedGiftConfig } from '@console/selectors/getSelectedProjectDraftForDisplay';
import type { AppThunkAction } from '@console/actions/thunkType';

export const validateContentsSelectionThunk =
  (): AppThunkAction<Promise<void>> => (dispatch, getState) => {
    const selectedGiftConfig =
      getSelectedProjectDraftSelectedGiftConfig(getState());

    if (selectedGiftConfig) {
      if (isChoosableGiftConfig(selectedGiftConfig)) {
        return dispatch(validateChoosableContentsSelectionThunk());
      } else if (isFixedGiftConfig(selectedGiftConfig)) {
        return dispatch(validateFixedContentSelectionThunk());
      } else if (isGifteeBoxGiftConfig(selectedGiftConfig)) {
        return dispatch(validateGifteeBoxTemplateSelectionThunk());
      }
    }

    return Promise.reject();
  };

export const validateFixedContentSelectionThunk =
  (): AppThunkAction<Promise<void>> => (dispatch, getState) => {
    const { projectJson } = getState().selectedProjectDraft;
    dispatch(validateFixedContentSelection(projectJson));

    const { errors } = getState();
    const { selectedGiftConfigIndex } = projectJson;
    if (
      projectDraftFixedContentSelectionHasValidationErrors(
        errors,
        selectedGiftConfigIndex
      )
    ) {
      return Promise.reject();
    }
    return Promise.resolve();
  };

export const validateChoosableContentsSelectionThunk =
  (): AppThunkAction<Promise<void>> => (dispatch, getState) => {
    const { projectJson } = getState().selectedProjectDraft;
    dispatch(validateChoosableContentsSelection(projectJson));

    const { errors } = getState();
    const { selectedGiftConfigIndex } = projectJson;
    if (
      projectDraftChoosableContentsSelectionHasValidationErrors(
        errors,
        selectedGiftConfigIndex
      )
    ) {
      return Promise.reject();
    }
    return Promise.resolve();
  };

export const validateGifteeBoxTemplateSelectionThunk =
  (): AppThunkAction<Promise<void>> => (dispatch, getState) => {
    const { selectedGiftConfigIndex } =
      getState().selectedProjectDraft.projectJson;
    dispatch(validateGifteeBoxTemplateSelection(selectedGiftConfigIndex));

    const { errors } = getState();
    if (
      projectDraftGifteeBoxTemplateSelectionHasValidationErrors(
        errors,
        selectedGiftConfigIndex
      )
    ) {
      return Promise.reject();
    }
    return Promise.resolve();
  };
