import {
  updateAddingBulkGifteeBoxIssueConfigInitialPoint,
  updateAddingBulkGifteeBoxIssueConfigRequestedIssueAmount,
  updateAddingBulkGifteeBoxIssueConfigDeliveryDate,
  addAddingBulkGifteeBoxIssueConfig,
  removeAddingBulkGifteeBoxIssueConfig,
  validateProjectAddingBulkGifteeBoxIssueConfigsTotalSuccess
} from '@console/actions/actions';
import { addBulkGifteeBoxIssueConfigsThunk } from '@console/actions/thunks/addBulkGifteeBoxIssueConfigs';
import { getBulkIssueConfigsClaimEstimateThunk } from '@console/actions/thunks/getBulkIssueConfigsClaimEstimate';
import { getLoginUserThunk } from '@console/actions/thunks/getLoginUser';
import { getProjectDetailThunk } from '@console/actions/thunks/getProjectDetail';
import { validateProjectAddingBulkGifteeBoxIssueConfigsThunk } from '@console/actions/thunks/validateProjectAddingBulkGifteeBoxIssueConfigs';
import { goProjectGiftConfig } from '@console/common/movePages';
import AddingBulkGifteeBoxIssueConfigsTable from '@console/components/project/gifteeBoxConfigs/bulkGifteeBoxIssueConfigs/new/AddingBulkGifteeBoxIssueConfigsTable';
import { getCreditLimitBalance } from '@console/selectors/distributionPartner';
import {
  getAddingBulkGifteeBoxIssueConfigsTotal,
  getAddingBulkGifteeBoxIssueAmountTotal
} from '@console/selectors/edit/project/gifteeBoxConfigs/addingBulkGifteeBoxIssueConfigs';
import { getHasProjectAddingBulkGifteeBoxIssueConfigsError } from '@console/selectors/errors';
import {
  getSelectedInitialPointCandidates,
  getSelectedGifteeBoxConfig,
  getSelectedGifteeBoxTemplate
} from '@console/selectors/projectDetail/gifteeBoxConfigs';
import { connect } from 'react-redux';
import type {
  AppThunkDispatch,
  AppThunkAction
} from '@console/actions/thunkType';
import type { AppState } from '@console/reducers';
import type { Moment } from 'moment';
import type { NavigateFunction } from 'react-router-dom';

const handleAddBulkGifteeBoxIssueConfigsButtonClick =
  (navigate: NavigateFunction): AppThunkAction<void> =>
  (dispatch, getState) => {
    const state = getState();
    const { selectedProjectGifteeBoxConfigUrlCode } = state;
    const { addingBulkGifteeBoxIssueConfigs } =
      state.edits.project.gifteeBoxConfigs;

    if (!!selectedProjectGifteeBoxConfigUrlCode) {
      dispatch(validateProjectAddingBulkGifteeBoxIssueConfigsThunk())
        .then(() =>
          dispatch(
            addBulkGifteeBoxIssueConfigsThunk(
              selectedProjectGifteeBoxConfigUrlCode,
              addingBulkGifteeBoxIssueConfigs
            )
          )
        )
        .then(() => {
          const state = getState();
          const { selectedProjectUrlCode } = state;
          dispatch(getProjectDetailThunk(selectedProjectUrlCode));
          dispatch(goGiftConfig(navigate));

          if (getCreditLimitBalance(state) !== null) {
            // creditLimitBalance が更新されるので再取得
            dispatch(getLoginUserThunk());
          }
        })
        .catch(() => {});
    }
  };

const handleBackButtonClick =
  (navigate: NavigateFunction): AppThunkAction<void> =>
  dispatch => {
    dispatch(goGiftConfig(navigate));
  };

const goGiftConfig =
  (navigate: NavigateFunction): AppThunkAction<void> =>
  (_dispatch, getState) => {
    const { selectedProjectUrlCode } = getState();
    if (!!selectedProjectUrlCode) {
      goProjectGiftConfig(navigate, selectedProjectUrlCode);
    }
  };

const getBulkGifteeBoxIssueConfigsClaimEstimate =
  (): AppThunkAction<void> => (dispatch, getState) => {
    // バリデーションエラーがある場合、見積り金額を更新しない
    const { projectAddingBulkGifteeBoxIssueConfigs } = getState().errors;
    if (
      projectAddingBulkGifteeBoxIssueConfigs.some(
        projectAddingBulkGifteeBoxIssueConfig =>
          !!projectAddingBulkGifteeBoxIssueConfig.intialPoint
      )
    ) {
      return;
    }

    const totalPrice = getAddingBulkGifteeBoxIssueConfigsTotal(getState());
    dispatch(getBulkIssueConfigsClaimEstimateThunk(totalPrice)).catch(() => {});
  };

const handleAddingBulkGifteeBoxIssueConfigInitialPointChange =
  (
    initialPoint: number,
    addingBulkGifteeBoxIssueConfigIndex: number
  ): AppThunkAction<void> =>
  dispatch => {
    dispatch(
      handleAddingBulkGifteeBoxIssueConfigInitialPointInputChange(
        initialPoint.toString(),
        addingBulkGifteeBoxIssueConfigIndex
      )
    );
  };

const handleAddingBulkGifteeBoxIssueConfigInitialPointInputChange =
  (
    initialPoint: string,
    addingBulkGifteeBoxIssueConfigIndex: number
  ): AppThunkAction<void> =>
  dispatch => {
    dispatch(
      updateAddingBulkGifteeBoxIssueConfigInitialPoint(
        initialPoint,
        addingBulkGifteeBoxIssueConfigIndex
      )
    );
    dispatch(validateProjectAddingBulkGifteeBoxIssueConfigsTotalSuccess());
    dispatch(getBulkGifteeBoxIssueConfigsClaimEstimate());
  };

const handleAddingBulkGifteeBoxIssueConfigRequestedIssueAmountBlur =
  (): AppThunkAction<void> => dispatch => {
    dispatch(getBulkGifteeBoxIssueConfigsClaimEstimate());
  };

const handleRemoveAddingBulkGifteeBoxIssueConfigButtonClick =
  (addingBulkGifteeBoxIssueConfigIndex: number): AppThunkAction<void> =>
  dispatch => {
    dispatch(
      removeAddingBulkGifteeBoxIssueConfig(addingBulkGifteeBoxIssueConfigIndex)
    );
    dispatch(validateProjectAddingBulkGifteeBoxIssueConfigsTotalSuccess());
    dispatch(getBulkGifteeBoxIssueConfigsClaimEstimate());
  };

const mapStateToProps = (state: AppState) => ({
  gifteeBoxConfig: getSelectedGifteeBoxConfig(state),
  initialPointCandidates: getSelectedInitialPointCandidates(state),
  selectedGifteeBoxTemplate: getSelectedGifteeBoxTemplate(state),
  addingBulkGifteeBoxIssueAmountTotal:
    getAddingBulkGifteeBoxIssueAmountTotal(state),
  addingBulkGifteeBoxIssueConfigs:
    state.edits.project.gifteeBoxConfigs.addingBulkGifteeBoxIssueConfigs,
  claimEstimate: state.claimEstimate,
  addingBulkGifteeBoxIssueConfigsTotal:
    getAddingBulkGifteeBoxIssueConfigsTotal(state),
  projectAddingBulkGifteeBoxIssueConfigsErrors:
    state.errors.projectAddingBulkGifteeBoxIssueConfigs,
  hasValidationError: getHasProjectAddingBulkGifteeBoxIssueConfigsError(state)
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  handleAddBulkGifteeBoxIssueConfigsButtonClick: (navigate: NavigateFunction) =>
    dispatch(handleAddBulkGifteeBoxIssueConfigsButtonClick(navigate)),
  handleBackButtonClick: (navigate: NavigateFunction) =>
    dispatch(handleBackButtonClick(navigate)),
  handleAddingBulkGifteeBoxIssueConfigInitialPointChange: (
    initialPoint: number,
    addingBulkGifteeBoxIssueConfigIndex: number
  ) =>
    dispatch(
      handleAddingBulkGifteeBoxIssueConfigInitialPointChange(
        initialPoint,
        addingBulkGifteeBoxIssueConfigIndex
      )
    ),
  handleAddingBulkGifteeBoxIssueConfigInitialPointInputChange: (
    initialPoint: string,
    addingBulkGifteeBoxIssueConfigIndex: number
  ) =>
    dispatch(
      handleAddingBulkGifteeBoxIssueConfigInitialPointInputChange(
        initialPoint,
        addingBulkGifteeBoxIssueConfigIndex
      )
    ),
  handleAddingBulkGifteeBoxIssueConfigRequestedIssueAmountChange: (
    event: GenericChangeEvent<string>,
    addingBulkGifteeBoxIssueConfigIndex: number
  ) => {
    dispatch(
      updateAddingBulkGifteeBoxIssueConfigRequestedIssueAmount(
        event.target.value,
        addingBulkGifteeBoxIssueConfigIndex
      )
    );
    dispatch(validateProjectAddingBulkGifteeBoxIssueConfigsTotalSuccess());
  },
  handleAddingBulkGifteeBoxIssueConfigRequestedIssueAmountBlur: () =>
    dispatch(handleAddingBulkGifteeBoxIssueConfigRequestedIssueAmountBlur()),
  handleAddingBulkGifteeBoxIssueConfigDeliveryDateChange: (
    deliveryDate: Moment | null,
    addingBulkGifteeBoxIssueConfigIndex: number
  ) =>
    dispatch(
      updateAddingBulkGifteeBoxIssueConfigDeliveryDate(
        deliveryDate,
        addingBulkGifteeBoxIssueConfigIndex
      )
    ),
  handleAddAddingBulkGifteeBoxIssueConfigButtonClick: () =>
    dispatch(addAddingBulkGifteeBoxIssueConfig()),
  handleRemoveAddingBulkGifteeBoxIssueConfigButtonClick: (
    addingBulkGifteeBoxIssueConfigIndex: number
  ) =>
    dispatch(
      handleRemoveAddingBulkGifteeBoxIssueConfigButtonClick(
        addingBulkGifteeBoxIssueConfigIndex
      )
    ),
  handleValidateProjectAddingBulkGifteeBoxIssueConfigs: () =>
    dispatch(validateProjectAddingBulkGifteeBoxIssueConfigsThunk())
});

export type AddingBulkGifteeBoxIssueConfigsTablePropsMappedFromState =
  ReturnType<typeof mapStateToProps>;
export type AddingBulkGifteeBoxIssueConfigsTablePropsMappedFromDispatch =
  ReturnType<typeof mapDispatchToProps>;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddingBulkGifteeBoxIssueConfigsTable);
