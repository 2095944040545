import { AppActionTypes } from '../../actions/actionType';
import { accountDistibutionPartnerEditValidators } from './account/distributionPartnerEdit';
import { accountDistibutionPartnerUserEditValidators } from './account/distributionPartnerUserEdit';
import { projectBulkGiftCardIssueConfigsValidator } from './project/giftCardConfigs/bulkGiftCardIssueConfigs/addingBulkGiftCardIssueConfigs';
import { projectBulkGifteeBoxIssueConfigsValidator } from './project/gifteeBoxConfigs/bulkGifteeBoxIssueConfigs/addingBulkGifteeBoxIssueConfigs';
import { projectDraftCampaignOverviewValidators } from './projectDraft/campaignOverview';
import { projectDraftGiftConfigs } from './projectDraft/giftConfigs';
import { projectDraftGiftDistributionValidators } from './projectDraft/giftDistribution';
import type { AppAction } from '../../actions/actionType';
import type { AppThunkDispatch } from '../../actions/thunkType';
import type { Dispatch, MiddlewareAPI } from 'redux';

const validator =
  ({ dispatch, getState }: MiddlewareAPI<AppThunkDispatch>) =>
  (next: Dispatch) =>
  async (action: AppAction) => {
    switch (action.type) {
      case AppActionTypes.FOCUS_OUT_FROM_CAMPAIGN_NAME:
        dispatch(
          projectDraftCampaignOverviewValidators.validateCampaignName(
            action.campaignName
          )
        );
        break;
      case AppActionTypes.UPDATE_CAMPAIGN_DESCRIPTION:
        dispatch(
          projectDraftCampaignOverviewValidators.validateCampaignDescription(
            action.campaignDescription
          )
        );
        break;
      case AppActionTypes.UPDATE_HOST_COMPANY_NAME:
        dispatch(
          projectDraftCampaignOverviewValidators.validateHostCompanyName(
            action.hostCompanyName
          )
        );
        break;
      case AppActionTypes.UPDATE_START_DATE_OF_AD:
        dispatch(
          projectDraftCampaignOverviewValidators.validateStartDateOfAd(
            action.startDateOfAd
          )
        );
        break;
      case AppActionTypes.UPDATE_END_DATE_OF_AD:
        dispatch(
          projectDraftCampaignOverviewValidators.validateEndDateOfAd(
            action.endDateOfAd
          )
        );
        break;
      case AppActionTypes.UPDATE_START_DATE_OF_DISTRIBUTION:
        dispatch(
          projectDraftCampaignOverviewValidators.validateStartDateOfDistribution(
            action.startDateOfDistribution
          )
        );
        break;
      case AppActionTypes.UPDATE_END_DATE_OF_DISTRIBUTION:
        dispatch(
          projectDraftCampaignOverviewValidators.validateEndDateOfDistribution(
            action.endDateOfDistribution
          )
        );
        break;
      case AppActionTypes.UPDATE_CAMPAIGN_SOLUTION_SYSTEM_API_BULK:
        dispatch(
          projectDraftCampaignOverviewValidators.validateCampaignSolutionSystem(
            action.campaignSolutionSystem
          )
        );
        break;
      case AppActionTypes.UPDATE_CAMPAIGN_MARKETING_INDUSTRY:
        dispatch(
          projectDraftCampaignOverviewValidators.validateCampaignMarketingIndustry(
            action.campaignMarketingIndustry
          )
        );
        break;
      case AppActionTypes.VALIDATE_CAMPAIGN_OVERVIEW:
        await dispatch(
          projectDraftCampaignOverviewValidators.validateCampaignOverview()
        );
        break;
      case AppActionTypes.VALIDATE_GIFT_DISTRIBUTION:
        dispatch(
          projectDraftGiftDistributionValidators.validateGiftDistribution()
        );
        break;
      case AppActionTypes.VALIDATE_GIFT_CONFIGS_FOR_BULK_PROJECT:
        dispatch(
          projectDraftGiftConfigs.validateGiftConfigsCommon(
            action.projectJson.giftConfigs
          )
        );
        dispatch(
          projectDraftGiftConfigs.validateGiftConfigsForBulkProject(
            action.projectJson.giftConfigs
          )
        );
        break;
      case AppActionTypes.VALIDATE_GIFT_CONFIGS_FOR_API_PROJECT:
        dispatch(
          projectDraftGiftConfigs.validateGiftConfigsCommon(
            action.projectJson.giftConfigs
          )
        );
        dispatch(
          projectDraftGiftConfigs.validateGiftConfigsForApiProject(
            action.projectJson.giftConfigs
          )
        );
        break;
      case AppActionTypes.VALIDATE_GIFT_CONFIGS_FOR_GCP_PROJECT:
        dispatch(
          projectDraftGiftConfigs.validateGiftConfigsCommon(
            action.projectJson.giftConfigs
          )
        );
        dispatch(
          projectDraftGiftConfigs.validateGiftConfigsForGcpProject(
            action.projectJson.giftConfigs
          )
        );
        break;
      case AppActionTypes.VALIDATE_FIXED_CONTENT_SELECTION:
        dispatch(
          projectDraftGiftConfigs.validateFixedGiftSelectedContents(
            action.projectJson.giftConfigs[
              action.projectJson.selectedGiftConfigIndex
            ],
            action.projectJson.selectedGiftConfigIndex
          )
        );
        break;
      case AppActionTypes.VALIDATE_CHOOSABLE_CONTENTS_SELECTION:
        dispatch(
          projectDraftGiftConfigs.validateChoosableGiftSelectedContents(
            action.projectJson.selectedGiftConfigIndex
          )
        );
        break;
      case AppActionTypes.VALIDATE_GIFTEE_BOX_TEMPLATE_SELECTION:
        dispatch(
          projectDraftGiftConfigs.validateGifteeBoxTemplateSelection(
            action.selectedGiftConfigIndex
          )
        );
        break;
      case AppActionTypes.VALIDATE_DELIVERY_FOR_BULK_PROJECT:
        dispatch(
          projectDraftGiftConfigs.validateIssueAmount(
            action.projectJson.giftConfigs[
              action.projectJson.selectedGiftConfigIndex
            ],
            action.projectJson.selectedGiftConfigIndex
          )
        );
        dispatch(
          projectDraftGiftConfigs.validateDeliveryDate(
            action.projectJson.giftConfigs[
              action.projectJson.selectedGiftConfigIndex
            ],
            action.projectJson.selectedGiftConfigIndex
          )
        );
        break;
      case AppActionTypes.VALIDATE_DELIVERY_FOR_API_PROJECT:
        dispatch(
          projectDraftGiftConfigs.validateIssueAmount(
            action.projectJson.giftConfigs[
              action.projectJson.selectedGiftConfigIndex
            ],
            action.projectJson.selectedGiftConfigIndex
          )
        );
        break;
      case AppActionTypes.VALIDATE_GIFTEE_BOX_DELIVERY_CONFIG_FOR_BULK_PROJECT:
        dispatch(
          projectDraftGiftConfigs.validateIssueAmount(
            action.projectJson.giftConfigs[
              action.projectJson.selectedGiftConfigIndex
            ],
            action.projectJson.selectedGiftConfigIndex
          )
        );
        dispatch(
          projectDraftGiftConfigs.validateDeliveryDate(
            action.projectJson.giftConfigs[
              action.projectJson.selectedGiftConfigIndex
            ],
            action.projectJson.selectedGiftConfigIndex
          )
        );
        dispatch(
          projectDraftGiftConfigs.validateGifteeBoxInitialPoint(
            action.projectJson.giftConfigs[
              action.projectJson.selectedGiftConfigIndex
            ],
            action.projectJson.selectedGiftConfigIndex
          )
        );
        break;
      case AppActionTypes.VALIDATE_GIFTEE_BOX_DELIVERY_CONFIG_FOR_GCP_PROJECT:
        dispatch(
          projectDraftGiftConfigs.validateGifteeBoxIssueEstimateOptionInitialPoint(
            action.projectJson.giftConfigs[
              action.projectJson.selectedGiftConfigIndex
            ],
            action.projectJson.selectedGiftConfigIndex
          )
        );
        dispatch(
          projectDraftGiftConfigs.validateGifteeBoxIssueEstimateOptionIssueAmount(
            action.projectJson.giftConfigs[
              action.projectJson.selectedGiftConfigIndex
            ],
            action.projectJson.selectedGiftConfigIndex
          )
        );
        break;
      case AppActionTypes.VALIDATE_GIFTEE_BOX_DELIVERY_CONFIG_FOR_API_PROJECT:
        dispatch(
          projectDraftGiftConfigs.validateGifteeBoxIssueEstimateOptionInitialPoint(
            action.projectJson.giftConfigs[
              action.projectJson.selectedGiftConfigIndex
            ],
            action.projectJson.selectedGiftConfigIndex
          )
        );
        dispatch(
          projectDraftGiftConfigs.validateGifteeBoxIssueEstimateOptionIssueAmount(
            action.projectJson.giftConfigs[
              action.projectJson.selectedGiftConfigIndex
            ],
            action.projectJson.selectedGiftConfigIndex
          )
        );
        break;
      case AppActionTypes.VALIDATE_MESSAGE_CARD_CONFIG:
        dispatch(
          projectDraftGiftConfigs.validateSenderName(
            action.projectJson.giftConfigs[
              action.projectJson.selectedGiftConfigIndex
            ],
            action.projectJson.selectedGiftConfigIndex
          )
        );
        dispatch(
          projectDraftGiftConfigs.validateMessage(
            action.projectJson.giftConfigs[
              action.projectJson.selectedGiftConfigIndex
            ],
            action.projectJson.selectedGiftConfigIndex
          )
        );
        break;
      case AppActionTypes.VALIDATE_BULK_PROJECT_DRAFT:
        dispatch(
          projectDraftCampaignOverviewValidators.validateCampaignOverview()
        );
        dispatch(
          projectDraftGiftDistributionValidators.validateGiftDistribution()
        );
        dispatch(
          projectDraftGiftConfigs.validateGiftConfigsCommon(
            action.projectJson.giftConfigs
          )
        );
        dispatch(
          projectDraftGiftConfigs.validateGiftConfigsForBulkProject(
            action.projectJson.giftConfigs
          )
        );
        break;
      case AppActionTypes.VALIDATE_API_PROJECT_DRAFT:
        dispatch(
          projectDraftCampaignOverviewValidators.validateCampaignOverview()
        );
        dispatch(
          projectDraftGiftDistributionValidators.validateGiftDistribution()
        );
        dispatch(
          projectDraftGiftConfigs.validateGiftConfigsCommon(
            action.projectJson.giftConfigs
          )
        );
        dispatch(
          projectDraftGiftConfigs.validateGiftConfigsForApiProject(
            action.projectJson.giftConfigs
          )
        );
        break;
      case AppActionTypes.VALIDATE_GCP_PROJECT_DRAFT:
        dispatch(
          projectDraftCampaignOverviewValidators.validateCampaignOverview()
        );
        dispatch(
          projectDraftGiftDistributionValidators.validateGiftDistribution()
        );
        dispatch(
          projectDraftGiftConfigs.validateGiftConfigsCommon(
            action.projectJson.giftConfigs
          )
        );
        dispatch(
          projectDraftGiftConfigs.validateGiftConfigsForGcpProject(
            action.projectJson.giftConfigs
          )
        );
        break;
      case AppActionTypes.UPDATE_BILLING_ADDRESS_NAME:
        dispatch(
          accountDistibutionPartnerEditValidators.validateBillingAddressName(
            action.billingAddressName
          )
        );
        break;
      case AppActionTypes.UPDATE_BILLING_ADDRESS_DIVISION:
        dispatch(
          accountDistibutionPartnerEditValidators.validateBillingAddressDivision(
            action.billingAddressDivision
          )
        );
        break;
      case AppActionTypes.UPDATE_BILLING_ADDRESS_POSTAL_CODE:
        dispatch(
          accountDistibutionPartnerEditValidators.validateBillingAddressPostalCode(
            action.billingAddressPostalCode
          )
        );
        break;
      case AppActionTypes.UPDATE_BILLING_ADDRESS_PREFECTURE_ID:
        dispatch(
          accountDistibutionPartnerEditValidators.validateBillingAddressPrefecture(
            action.billingAddressPrefectureId
          )
        );
        break;
      case AppActionTypes.UPDATE_BILLING_ADDRESS_CITY:
        dispatch(
          accountDistibutionPartnerEditValidators.validateBillingAddressCity(
            action.billingAddressCity
          )
        );
        break;
      case AppActionTypes.UPDATE_BILLING_ADDRESS_ADDRESS:
        dispatch(
          accountDistibutionPartnerEditValidators.validateBillingAddressAddress(
            action.billingAddressAddress
          )
        );
        break;
      case AppActionTypes.UPDATE_DISTRIBUTION_PARTNER_USER_NAME:
        dispatch(
          accountDistibutionPartnerUserEditValidators.validateDistributionPartnerUserName(
            action.name
          )
        );
        break;
      case AppActionTypes.UPDATE_DISTRIBUTION_PARTNER_USER_DIVISION:
        dispatch(
          accountDistibutionPartnerUserEditValidators.validateDistributionPartnerUserDivision(
            action.division
          )
        );
        break;
      case AppActionTypes.UPDATE_DISTRIBUTION_PARTNER_USER_PHONE_NUMBER:
        dispatch(
          accountDistibutionPartnerUserEditValidators.validateDistributionPartnerUserPhoneNumber(
            action.phoneNumber
          )
        );
        break;
      case AppActionTypes.VALIDATE_PROJECT_ADDING_BULK_GIFT_CARD_ISSUE_CONFIGS:
        dispatch(
          projectBulkGiftCardIssueConfigsValidator.validateAddingBulkGiftCardIssueConfigs()
        );
        break;
      case AppActionTypes.UPDATE_ADDING_BULK_GIFT_CARD_ISSUE_CONFIG_REQUESTED_ISSUE_AMOUNT:
        dispatch(
          projectBulkGiftCardIssueConfigsValidator.validateRequestedIssueAmount(
            action.addingBulkGiftCardIssueConfigIndex,
            action.requestedIssueAmount
          )
        );
        break;
      case AppActionTypes.UPDATE_ADDING_BULK_GIFT_CARD_ISSUE_CONFIG_DESIRED_DELIVERY_DATE:
        dispatch(
          projectBulkGiftCardIssueConfigsValidator.validateDesiredDeliveryDate(
            action.addingBulkGiftCardIssueConfigIndex,
            action.desiredDeliveryDate
          )
        );
        break;
      case AppActionTypes.VALIDATE_PROJECT_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIGS:
        dispatch(
          projectBulkGifteeBoxIssueConfigsValidator.validateAddingBulkGifteeBoxIssueConfigs()
        );
        break;
      case AppActionTypes.UPDATE_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIG_REQUESTED_ISSUE_AMOUNT:
        dispatch(
          projectBulkGifteeBoxIssueConfigsValidator.validateRequestedIssueAmount(
            action.addingBulkGifteeBoxIssueConfigIndex,
            action.requestedIssueAmount
          )
        );
        break;
      case AppActionTypes.UPDATE_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIG_DELIVERY_DATE:
        dispatch(
          projectBulkGifteeBoxIssueConfigsValidator.validateDeliveryDate(
            action.addingBulkGifteeBoxIssueConfigIndex,
            action.deliveryDate
          )
        );
        break;
      case AppActionTypes.UPDATE_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIG_INITIAL_POINT:
        dispatch(
          projectBulkGifteeBoxIssueConfigsValidator.validateInitialPoint(
            action.addingBulkGifteeBoxIssueConfigIndex,
            action.initialPoint
          )
        );
        break;
      default:
        break;
    }
    return next(action);
  };

export default validator;
