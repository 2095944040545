import {
  validateGiftConfigsForBulkProject,
  validateGiftConfigsForApiProject,
  validateGiftConfigsForGcpProject
} from '@console/actions/actions';
import {
  projectDraftGiftConfigsHasValidationErrors,
  projectDraftGiftConfigsCommonHasValidationErrors
} from '@console/middleware/validator/projectDraft/giftConfigs';
import {
  isBulkSolutionSystemSelected,
  isApiSolutionSystemSelected,
  isGcpSolutionSystemSelected
} from '@console/selectors/getSelectedProjectDraftForDisplay';
import type { AppThunkAction } from '@console/actions/thunkType';

export const validateGiftConfigsThunk =
  (): AppThunkAction<Promise<void>> => (dispatch, getState) => {
    const { projectJson } = getState().selectedProjectDraft;
    if (isBulkSolutionSystemSelected(getState())) {
      dispatch(validateGiftConfigsForBulkProject(projectJson));
    }
    if (isApiSolutionSystemSelected(getState())) {
      dispatch(validateGiftConfigsForApiProject(projectJson));
    }
    if (isGcpSolutionSystemSelected(getState())) {
      dispatch(validateGiftConfigsForGcpProject(projectJson));
    }

    const { errors } = getState();
    if (
      projectDraftGiftConfigsHasValidationErrors(errors) ||
      projectDraftGiftConfigsCommonHasValidationErrors(errors)
    ) {
      return Promise.reject();
    }
    return Promise.resolve();
  };
