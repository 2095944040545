import APIToken from '@console/components/project/APIToken';
import { getApiConfidentialInformation } from '@console/selectors/projectDetail/confidentialInformation';
import { connect } from 'react-redux';
import type { AppThunkDispatch } from '@console/actions/thunkType';
import type { AppState } from '@console/reducers';

const mapStateToProps = (state: AppState) => ({
  apiConfidentialInformation: getApiConfidentialInformation(state)
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({});

export type APITokenPropsMappedFromState = ReturnType<typeof mapStateToProps>;
export type APITokenPropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(mapStateToProps, mapDispatchToProps)(APIToken);
