import { gql } from '@apollo/client';

import { internalApiAppClient } from './apolloClient';
import { operationNames } from './operationNames';
import type { FetchResult } from '@apollo/client';

const deleteProjectDraftGql = gql`
  mutation ${operationNames.DELETE_PROJECT_DRAFT}($projectDraftUrlCode: String!) {
    deleteProjectDraft(input: { urlCode: $projectDraftUrlCode }) {
      urlCode
    }
  }
`;

export type DeleteProjectDraftResponse = FetchResult<{
  deleteProjectDraft: {
    urlCode: UrlCode;
  };
}>;

export const deleteProjectDraft = (
  projectDraftUrlCode: UrlCode
): Promise<DeleteProjectDraftResponse> =>
  internalApiAppClient.mutate({
    mutation: deleteProjectDraftGql,
    variables: {
      projectDraftUrlCode
    }
  });
