import { gql } from '@apollo/client';

import { internalApiAppClient } from './apolloClient';
import { operationNames } from './index';
import type { QueryApiAllowedIp } from './getProjectsGql';
import type { MutationErrors } from './mutationErrorType';
import type { FetchResult } from '@apollo/client';

const updateApiAllowedIpGql = gql`
  mutation ${operationNames.UPDATE_API_ALLOWED_IP}(
    $projectUrlCode: String!
    $apiAllowedIpUrlCode: String!
    $ip: String!
  ) {
    updateApiAllowedIp(
      input: {
        projectUrlCode: $projectUrlCode
        apiAllowedIpUrlCode: $apiAllowedIpUrlCode
        ip: $ip
      }
    ) {
      errors {
        __typename
        message
        path
      }
    }
  }
`;

export type UpdateApiAllowedIpResponse = FetchResult<{
  updateApiAllowedIp: {
    errors: MutationErrors;
  };
}>;

export const updateApiAllowedIp = (
  projectUrlCode: UrlCode,
  apiAllowedIp: QueryApiAllowedIp
): Promise<UpdateApiAllowedIpResponse> =>
  internalApiAppClient.mutate({
    mutation: updateApiAllowedIpGql,
    variables: {
      projectUrlCode,
      apiAllowedIpUrlCode: apiAllowedIp.urlCode,
      ip: apiAllowedIp.ip
    }
  });
