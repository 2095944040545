import { Typography, Divider, Grid } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    notFoundContainer: {
      width: '100%',
      height: '100%',
      padding: theme.spacing(3),
      paddingTop: '20%'
    },
    divider: {
      width: 200
    },
    statusCode: {
      paddingBottom: theme.spacing(2)
    },
    message: {
      paddingTop: theme.spacing(3)
    }
  });
type NotFoundProps = WithStyles<typeof styles>;
const NotFound: React.FC<NotFoundProps> = ({ classes }) => (
  <Grid
    className={classes.notFoundContainer}
    container
    direction="column"
    justifyContent="center"
    alignItems="center"
    data-cy="notFound"
  >
    <Typography variant="h2" color="textPrimary" className={classes.statusCode}>
      404
    </Typography>
    <Divider className={classes.divider} />
    <Typography variant="body1" color="textPrimary" className={classes.message}>
      お探しのページが見つかりません。URLに誤りがあるか、ページが移動もしくは削除された可能性があります。
    </Typography>
  </Grid>
);
export default withStyles(styles)(NotFound);
