import BulkTable from '@console/components/project/brandCheck/BulkTable';
import { getGiftCardConfigsForDisplay } from '@console/selectors/projectDetail/giftCardConfigs';
import { getGifteeBoxConfigs } from '@console/selectors/projectDetail/gifteeBoxConfigs';
import { connect } from 'react-redux';
import type { AppThunkDispatch } from '@console/actions/thunkType';
import type { AppState } from '@console/reducers';

const mapStateToProps = (state: AppState) => ({
  gifteeBoxConfigs: getGifteeBoxConfigs(state),
  giftCardConfigs: getGiftCardConfigsForDisplay(state)
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({});

export type BulkTablePropsMappedFromState = ReturnType<typeof mapStateToProps>;
export type BulkTablePropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(mapStateToProps, mapDispatchToProps)(BulkTable);
