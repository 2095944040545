import { getHtmlString } from '@common/lib/getHtmlString';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import type { Footer } from '../api/graphql/getGiftCardGql';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const LOGO_HEIGHT = 30;
const FOOTER_HEIGHT = 60;

const styles = (theme: Theme): StyleRules => ({
  footerContainer: {
    marginBottom: 'auto'
  },
  title: {
    marginTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    maxWidth: 600
  },
  root: {
    marginTop: theme.spacing(2),
    width: '100%',
    backgroundColor: theme.palette.giftCard.background.main,
    height: 50
  },
  footerDescription: {
    paddingBottom: theme.spacing(2),
    marginBottom: FOOTER_HEIGHT
  },
  serviceDescription: {
    marginTop: theme.spacing(2),
    padding: `0 ${theme.spacing(2)}`,
    maxWidth: 600
  },
  guide: {
    margin: `${theme.spacing(2)} 0`,
    color: theme.palette.text.secondary
  },
  footer: {
    width: '100%',
    boxSizing: 'content-box',
    height: FOOTER_HEIGHT,
    backgroundColor: theme.palette.giftCard.background.main,
    position: 'absolute',
    bottom: 0,
    borderTop: 'solid 1px #EDEDED'
  },
  footerImage: {
    height: LOGO_HEIGHT,
    margin: LOGO_HEIGHT / 2
  }
});

interface GiftCardFooterOwnProps {
  footer: Footer;
}

type GiftCardFooterProps = GiftCardFooterOwnProps & WithStyles<typeof styles>;

const GiftCardFooter: React.FC<GiftCardFooterProps> = ({ footer, classes }) => (
  <Grid
    container
    direction="column"
    alignItems="center"
    className={classes.footerContainer}
  >
    <Grid item className={classes.footerDescription}>
      {footer.description && (
        <Grid
          container
          justifyContent="center"
          className={classes.serviceDescription}
        >
          <Typography
            variant="caption"
            dangerouslySetInnerHTML={{
              __html: getHtmlString(footer.description)
            }}
          ></Typography>
        </Grid>
      )}
    </Grid>
    <Grid
      item
      container
      justifyContent="center"
      alignItems="center"
      xs={12}
      className={classes.footer}
    >
      <img
        src={footer.imageUrl}
        className={classes.footerImage}
        data-cy="footerImage"
      />
    </Grid>
  </Grid>
);

export default withStyles(styles)(GiftCardFooter);
