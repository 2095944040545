import {
  isBulkSolutionSystem,
  isApiSolutionSystem
} from '@console/common/solutionSystem';
import { createSelector } from 'reselect';
import type {
  SolutionSystem,
  SolutionSystems
} from '@common/api/graphql/getSolutionSystemsGql';
import type { AppState } from '@console/reducers';

const getSolutionSystems = (state: AppState): SolutionSystems =>
  state.solutionSystems;

export const getBulkSolutionSystem = createSelector(
  [getSolutionSystems],
  (solutionSystems): SolutionSystem | undefined =>
    solutionSystems.find(solutionSystem => isBulkSolutionSystem(solutionSystem))
);
export const getApiSolutionSystem = createSelector(
  [getSolutionSystems],
  (solutionSystems): SolutionSystem | undefined =>
    solutionSystems.find(solutionSystem => isApiSolutionSystem(solutionSystem))
);
