import { gql } from '@apollo/client';

import { internalApiAppClient } from './apolloClient';
import type { MessageThreadUrlCode, Node } from './getMessageThread';
import type { FetchResult, Observable } from '@apollo/client';

const messageThreadPostedMessageSubscriptionGql = gql`
  subscription ($messageThreadUrlCode: String!) {
    messageThreadMessageWasPosted(messageThreadUrlCode: $messageThreadUrlCode) {
      message {
        requestCode
        postedAt
        text {
          content
        }
        files {
          urlCode
          name
          size
        }
        contributor {
          __typename
          ... on DistributionPartnerContributor {
            name
            iconUrl
            distributionPartner {
              name
            }
          }
          ... on ProjectManagementContributor {
            name
            iconUrl
          }
        }
      }
    }
  }
`;

export type MessageThreadPostedMessageSubscriptionResponse = FetchResult<{
  messageThreadMessageWasPosted: {
    message: Node;
  };
}>;

export const messageThreadPostedMessageObserver = (
  messageThreadUrlCode: MessageThreadUrlCode
): Observable<MessageThreadPostedMessageSubscriptionResponse> =>
  internalApiAppClient.subscribe({
    query: messageThreadPostedMessageSubscriptionGql,
    variables: {
      messageThreadUrlCode: messageThreadUrlCode
    }
  });
