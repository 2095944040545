import { getSelectedGiftCardConfig } from '@console/selectors/projectDetail/giftCardConfigs';
import { createSelector } from 'reselect';
import type { AppState } from '@console/reducers';
import type {
  LocalBulkGiftCardIssueConfig,
  LocalGiftCardConfig
} from '@console/reducers/projects';
import type { LocalClaimEstimateForDisplay } from '@console/selectors/projectDetail';

export type LocalBulkGiftCardIssueConfigForDisplay =
  LocalBulkGiftCardIssueConfig & {
    claimEstimate: LocalClaimEstimateForDisplay;
  };

const getSelectedProjectBulkGiftCardIssueConfigIndex = (state: AppState) =>
  state.selectedProjectBulkGiftCardIssueConfigIndex;

const getSelectedBulkGiftCardIssueConfigs = createSelector(
  [getSelectedGiftCardConfig],
  (giftCardConfig): LocalGiftCardConfig['bulkGiftCardIssueConfigs'] =>
    giftCardConfig?.bulkGiftCardIssueConfigs ?? []
);

const getSelectedBulkGiftCardIssueConfigFromArray = createSelector(
  [
    getSelectedBulkGiftCardIssueConfigs,
    getSelectedProjectBulkGiftCardIssueConfigIndex
  ],
  (
    bulkGiftCardIssueConfigs,
    bulkGiftCardIssueConfigIndex
  ): LocalBulkGiftCardIssueConfig | undefined =>
    bulkGiftCardIssueConfigs[bulkGiftCardIssueConfigIndex]
);

// TODO: 命名の見直し
// https://github.com/giftee/ikedayama/pull/13887/files#r736093206
const getSelectedBulkGiftCardIssueConfig = createSelector(
  [getSelectedBulkGiftCardIssueConfigFromArray],
  (
    bulkGiftCardIssueConfig
  ): Omit<
    LocalBulkGiftCardIssueConfig,
    'fixedIssueConfig' | 'issueAmountRequest' | 'claimEstimate'
  > => ({
    deliveryDate: bulkGiftCardIssueConfig?.deliveryDate ?? '',
    requestedIssueAmount: bulkGiftCardIssueConfig?.requestedIssueAmount ?? 0,
    urlCode: bulkGiftCardIssueConfig?.urlCode ?? '',
    isIssueAmountRequested:
      bulkGiftCardIssueConfig?.isIssueAmountRequested ?? false
  })
);

const getFixedIssueConfig = createSelector(
  [getSelectedBulkGiftCardIssueConfigFromArray],
  (bulkGiftCardIssueConfig): LocalBulkGiftCardIssueConfig['fixedIssueConfig'] =>
    bulkGiftCardIssueConfig?.fixedIssueConfig ?? {
      issueAmount: 0
    }
);

const getIssueAmountRequest = createSelector(
  [getSelectedBulkGiftCardIssueConfigFromArray],
  (
    bulkGiftCardIssueConfig
  ): LocalBulkGiftCardIssueConfig['issueAmountRequest'] =>
    bulkGiftCardIssueConfig?.issueAmountRequest ?? {
      issueAmount: 0,
      requestedAt: ''
    }
);

const getClaimEstimateForDisplay = createSelector(
  [getSelectedGiftCardConfig, getSelectedBulkGiftCardIssueConfigFromArray],
  (giftCardConfig, bulkGiftCardIssueConfig): LocalClaimEstimateForDisplay => {
    if (!giftCardConfig || !bulkGiftCardIssueConfig?.claimEstimate) {
      return {
        consumptionTaxPrice: 0,
        issueCommissionPrice: 0,
        totalPrice: 0,
        estimatedAmount: 0
      };
    }
    return {
      ...bulkGiftCardIssueConfig.claimEstimate,
      estimatedAmount:
        giftCardConfig.unitPrice *
        bulkGiftCardIssueConfig.issueAmountRequest.issueAmount
    };
  }
);

export const getSelectedBulkGiftCardIssueConfigForDisplay = createSelector(
  [
    getSelectedBulkGiftCardIssueConfig,
    getFixedIssueConfig,
    getIssueAmountRequest,
    getClaimEstimateForDisplay
  ],
  (
    bulkGiftCardIssueConfig,
    fixedIssueConfig,
    issueAmountRequest,
    claimEstimateForDisplay
  ): LocalBulkGiftCardIssueConfigForDisplay => ({
    ...bulkGiftCardIssueConfig,
    fixedIssueConfig,
    issueAmountRequest,
    claimEstimate: claimEstimateForDisplay
  })
);
