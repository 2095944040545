import { ApolloClient, ApolloLink, from, split } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { onError } from '@apollo/client/link/error';

import { createConsumer } from '@rails/actioncable';
import { createUploadLink } from 'apollo-upload-client';
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink';
import convertFirstLetterToLowercase from '../../lib/convertFirstLetterToLowercase';
import getTokenFromCookie from '../../lib/getTokenFromCookie';
import { getApiUri, getApiUriWebsocket } from '../uri';
import type { MutationErrors } from './mutationErrorType';
import type {
  DefaultOptions,
  OperationVariables,
  MutationOptions,
  QueryOptions,
  ApolloQueryResult,
  ApolloError,
  Observable,
  FetchResult
} from '@apollo/client';
import type { GraphQLError } from 'graphql';
import type { OperationDefinitionNode, NameNode } from 'graphql/language/ast';

const csrfTokenElement: HTMLInputElement | null = document.querySelector(
  'meta[name=csrf-token]'
);

const csrfToken: string | null = csrfTokenElement
  ? csrfTokenElement.getAttribute('content')
  : null;

type GraphqlPathsType = 'public' | 'internal';
type GraphqlPaths = { [key in GraphqlPathsType]: string };

const graphqlPaths: GraphqlPaths = {
  public: 'public_api',
  internal: 'internal_api'
};

const getBasicAuth = (type: GraphqlPathsType) => {
  const token = type == 'internal' ? getTokenFromCookie() : '';
  const basicAuth = btoa(`${token}:`);
  return basicAuth;
};

const httpLink = (type: GraphqlPathsType) =>
  new createUploadLink({
    uri: `${getApiUri()}/${graphqlPaths[type]}/graphql`
  });

const cable = createConsumer(
  `${getApiUriWebsocket()}/cable?token=${getTokenFromCookie()}`
);

const isSubscriptionOperation = ({ query: { definitions } }) =>
  definitions.some(
    ({ kind, operation }) =>
      kind === 'OperationDefinition' && operation === 'subscription'
  );

const authMiddleware = (type: GraphqlPathsType) =>
  new ApolloLink((operation, forward): Observable<FetchResult> | null => {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        'X-CSRF-Token': `${csrfToken}`,
        authorization: `Basic ${getBasicAuth(type)}`
      }
    }));

    return forward ? forward(operation) : null;
  });

const errorMiddleware = onError(({ networkError }) => {
  // Do something
  // if (networkError.statusCode === 401) {
  // }
});

const cache = new InMemoryCache({ addTypename: false });

const defaultOptions: DefaultOptions = {
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  },
  mutate: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  }
};

const createClient = (
  type: GraphqlPathsType,
  isRejectError: boolean = false
) => {
  const options = {
    link: split(
      isSubscriptionOperation,
      new ActionCableLink({
        cable,
        channelName: 'InternalAPI::GraphqlChannel'
      }),
      from([authMiddleware(type), errorMiddleware, httpLink(type)])
    ),
    cache,
    defaultOptions
  };
  return isRejectError
    ? new AppApolloClient(options)
    : new ApolloClient(options);
};

type MutationResponse = FetchResult<{ [key: string]: any }>;
type QueryResponse = ApolloQueryResult<{ [key: string]: any }>;
export type Response = MutationResponse | QueryResponse;

export type ApiError =
  | ApolloError
  | ReadonlyArray<GraphQLError>
  | MutationErrors;

type MutationName = NameNode['value'];

export class AppApolloClient<TCacheSape> extends ApolloClient<TCacheSape> {
  private hasGraphqlError(response: Response): boolean {
    return response.errors !== undefined;
  }

  private rejectGraphqlErrorIfNeeded<TResult extends Response>(): (
    response: TResult
  ) => Promise<TResult> {
    return response => {
      if (this.hasGraphqlError(response)) {
        return Promise.reject(response.errors);
      }
      return Promise.resolve(response);
    };
  }

  private getMutationName<T = any, TVariables = OperationVariables>(
    options: MutationOptions<T, TVariables>
  ): MutationName {
    const definition = options.mutation.definitions.find(
      definition => definition.kind === 'OperationDefinition'
    ) as OperationDefinitionNode;
    const mutationName = !!definition?.name ? definition.name.value : '';
    const convertedMutationName = convertFirstLetterToLowercase(mutationName);
    return convertedMutationName;
  }

  private hasMutationError(
    mutationName: MutationName,
    response: FetchResult<{ [key: string]: any }>
  ): boolean {
    const { data } = response;
    const mutationNameObject = data ? data[mutationName] : undefined;
    const errors = mutationNameObject
      ? (mutationNameObject.errors as Array<any>)
      : undefined;
    return !!data && !!errors && errors.length > 0;
  }

  private rejectMutationErrorIfNeeded<T = any, TVariables = OperationVariables>(
    options: MutationOptions<T, TVariables>
  ): (response: Response) => Promise<Response> {
    return response => {
      const mutationName = this.getMutationName(options);
      if (this.hasMutationError(mutationName, response)) {
        const mutationErrors: MutationErrors =
          !!response.data && response.data[mutationName].errors;
        return Promise.reject(mutationErrors);
      }
      return Promise.resolve(response);
    };
  }

  private rejectErrorIfNeeded<T = any, TVariables = OperationVariables>(
    options: MutationOptions<T, TVariables>
  ): (response: FetchResult<any>) => Promise<FetchResult<any>> {
    return response =>
      this.rejectMutationErrorIfNeeded<T, TVariables>(options)(response).then(
        this.rejectGraphqlErrorIfNeeded<FetchResult<typeof response>>()
      );
  }

  mutate<T = any, TVariables = OperationVariables>(
    options: MutationOptions<T, TVariables>
  ): Promise<FetchResult<T>>;
  mutate<T = any, TVariables = OperationVariables>(
    options: MutationOptions<T, TVariables>
  ): Promise<FetchResult<T>>;

  public mutate<
    T extends MutationResponse['data'],
    TVariables extends OperationVariables
  >(options: MutationOptions<T, TVariables>): Promise<FetchResult<T>> {
    return super
      .mutate(options)
      .then(this.rejectErrorIfNeeded<T, TVariables>(options));
  }

  query<T = any, TVariables = OperationVariables>(
    options: QueryOptions<TVariables>
  ): Promise<ApolloQueryResult<T>>;

  public query<
    T extends QueryResponse['data'],
    TVariables extends OperationVariables
  >(options: QueryOptions<TVariables>): Promise<ApolloQueryResult<T>> {
    return super
      .query(options)
      .then(this.rejectGraphqlErrorIfNeeded<ApolloQueryResult<T>>());
  }
}

export const publicApiAppClient = createClient('public', true);
export const internalApiAppClient = createClient('internal', true);
