import { getGeneralAnnouncements } from '@common/api/graphql/getGeneralAnnouncementsGql';
import {
  getGeneralAnnouncementsStart,
  getGeneralAnnouncementsSuccess,
  getGeneralAnnouncementsFailure
} from '../actions';
import handleApiErrorIfNeeded from './handleApiErrorIfNeeded';
import type { AppThunkAction } from '../thunkType';
import type { ApiError } from '@common/api/graphql/apolloClient';
import type { GetGeneralAnnouncementsResponse } from '@common/api/graphql/getGeneralAnnouncementsGql';

export const getGeneralAnnouncementsSuccessThunk =
  (response: GetGeneralAnnouncementsResponse): AppThunkAction<Promise<void>> =>
  dispatch => {
    const { generalAnnouncements } = response.data!;
    dispatch(getGeneralAnnouncementsSuccess(generalAnnouncements));
    return Promise.resolve();
  };

export const getGeneralAnnouncementsFailureThunk =
  (error: ApiError): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(getGeneralAnnouncementsFailure());
    return dispatch(handleApiErrorIfNeeded(error));
  };

export const getGeneralAnnouncementsThunk =
  (): AppThunkAction<Promise<void>> => dispatch => {
    dispatch(getGeneralAnnouncementsStart());

    return getGeneralAnnouncements()
      .then((response: GetGeneralAnnouncementsResponse) =>
        dispatch(getGeneralAnnouncementsSuccessThunk(response))
      )
      .catch(error => dispatch(getGeneralAnnouncementsFailureThunk(error)));
  };
