import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Grid,
  Typography
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import type { GiftConfigForDisplay } from '../../containers/projectDraft/GiftConfigsContainer';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    giftImage: {
      width: 70,
      height: 70,
      objectFit: 'contain'
    },
    giftLabel: {
      paddingLeft: theme.spacing(2)
    },
    truncatedText: {
      width: '100%',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    }
  });

type OwnProps = {
  isOpen: boolean;
  onCloseHandler: (event: React.MouseEvent, ...args: any) => void;
  onOKHandler: (event: React.MouseEvent, ...args: any) => void;
  // TODO: 型情報をcontainerから参照しているため、当componentがGiftConfigsContainerに依存した形となっている。
  // 型定義を別ファイルに移動するなどして、containerへの依存関係を無くすほうが良さそう。
  giftConfigForDisplay: GiftConfigForDisplay;
};

type GiftConfigConfirmModalProps = OwnProps & WithStyles<typeof styles>;

const GiftConfigConfirmModal: React.FC<GiftConfigConfirmModalProps> = ({
  isOpen,
  onCloseHandler,
  onOKHandler,
  giftConfigForDisplay,
  classes
}) => (
  <Dialog
    open={isOpen}
    onClose={onCloseHandler}
    aria-labelledby="confirm-dialog-title"
    aria-describedby="confirm-dialog-description"
    fullWidth
    maxWidth="xs"
  >
    <DialogTitle id="confirm-dialog-title">ギフト削除確認</DialogTitle>
    <DialogContent>
      <DialogContentText id="confirm-dialog-description">
        {/* TODO: 複数商品ギフトの対応を入れる */}
        以下のギフトを削除しますか？
      </DialogContentText>
      <Grid container>
        <Grid item sm={2}>
          <img
            src={giftConfigForDisplay.thumbnailImageUrl}
            className={classes.giftImage}
          />
        </Grid>
        <Grid
          container
          item
          sm={10}
          direction="column"
          justifyContent="center"
          className={classes.giftLabel}
        >
          <Typography
            variant="caption"
            color="textSecondary"
            className={classes.truncatedText}
          >
            {giftConfigForDisplay.firstBrandName}
          </Typography>
          <Typography variant="body2" className={classes.truncatedText}>
            {giftConfigForDisplay.firstContentName || '商品が未選択です'}
          </Typography>
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={onCloseHandler} color="primary">
        キャンセル
      </Button>
      <Button onClick={onOKHandler} variant="contained" color="secondary">
        削除
      </Button>
    </DialogActions>
  </Dialog>
);

export default withStyles(styles)(GiftConfigConfirmModal);
