import CommonErrorContainer from '@console/containers/common/CommonErrorContainer';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
  Typography
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { useState } from 'react';
import APIIpRestrictionExample from './Example';
import type { QueryApiAllowedIp } from '@common/api/graphql/getProjectsGql';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    dialogCard: {
      padding: theme.spacing(2)
    },
    textField: {
      marginTop: theme.spacing()
    },
    example: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing()
    },
    button: {
      padding: `${theme.spacing()} ${theme.spacing(3)}`
    },
    error: {
      margin: `0 ${theme.spacing(2)}`
    }
  });

export type AddModalOwnProps = {
  open: boolean;
  closeModal: () => void;
  handleOKClick: (ip: QueryApiAllowedIp['ip']) => void;
  handleOnExit: () => void;
};

type AddModalProps = AddModalOwnProps & WithStyles<typeof styles>;

const AddModal: React.FC<AddModalProps> = ({
  open,
  handleOKClick,
  closeModal,
  handleOnExit,
  classes
}) => {
  const [apiAllowedIp, setApiAllowedIp] = useState('');

  const onChangeApiAllowedIp = (event: GenericChangeEvent<string>) => {
    setApiAllowedIp(event.target.value);
  };

  const handleAddClick = () => {
    handleOKClick(apiAllowedIp);
  };

  const handleExit = () => {
    handleOnExit();
    setApiAllowedIp('');
  };

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      data-cy="dialog"
      TransitionProps={{ onExit: handleExit }}
    >
      <div className={classes.dialogCard}>
        <DialogTitle id="modal-title">許可するIPアドレスの追加</DialogTitle>
        <div className={classes.error}>
          <CommonErrorContainer />
        </div>
        <DialogContent>
          <DialogContentText>
            以下にIPアドレスを１つ記入し、追加ボタンを押してください。
          </DialogContentText>
          <TextField
            variant="standard"
            value={apiAllowedIp}
            label="IPアドレス"
            required
            fullWidth
            className={classes.textField}
            onChange={onChangeApiAllowedIp}
            data-cy="apiAllowedIpAddress"
            inputProps={{ maxLength: 50 }}
          />
          <div className={classes.example}>
            <APIIpRestrictionExample />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="primary">
            キャンセル
          </Button>
          <Button
            variant="contained"
            onClick={handleAddClick}
            color="primary"
            autoFocus
            className={classes.button}
            data-cy="addApiAllowedIpAddress"
          >
            追加
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default withStyles(styles)(AddModal);
