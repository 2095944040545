import { AppActionTypes } from '../../actions/actionType';
import { intialStateOfDistributionPartner } from '../distributionPartner';
import type { DistributionPartner } from '../../../../common/api/graphql/getLoginUserGql';
import type { AppAction } from '../../actions/actionType';

const distributionPartner = (
  state = intialStateOfDistributionPartner,
  action: AppAction
): DistributionPartner => {
  // for checking index access
  const firstBillingAddress = state.billingAddresses[0];
  switch (action.type) {
    case AppActionTypes.GET_LOGIN_USER_SUCCESS:
      return action.distributionPartner;
    case AppActionTypes.UPDATE_BILLING_ADDRESS_NAME:
      if (!firstBillingAddress) return state;
      return {
        ...state,
        billingAddresses: [
          {
            ...firstBillingAddress,
            name: action.billingAddressName
          }
        ]
      };
    case AppActionTypes.UPDATE_BILLING_ADDRESS_DIVISION:
      if (!firstBillingAddress) return state;
      return {
        ...state,
        billingAddresses: [
          {
            ...firstBillingAddress,
            division: action.billingAddressDivision
          }
        ]
      };
    case AppActionTypes.UPDATE_BILLING_ADDRESS_POSTAL_CODE:
      if (!firstBillingAddress) return state;
      return {
        ...state,
        billingAddresses: [
          {
            ...firstBillingAddress,
            postalCode: action.billingAddressPostalCode
          }
        ]
      };
    case AppActionTypes.UPDATE_BILLING_ADDRESS_PREFECTURE_ID:
      if (!firstBillingAddress) return state;
      return {
        ...state,
        billingAddresses: [
          {
            ...firstBillingAddress,
            prefecture: {
              ...firstBillingAddress.prefecture,
              id: action.billingAddressPrefectureId
            }
          }
        ]
      };
    case AppActionTypes.UPDATE_BILLING_ADDRESS_CITY:
      if (!firstBillingAddress) return state;
      return {
        ...state,
        billingAddresses: [
          {
            ...firstBillingAddress,
            city: action.billingAddressCity
          }
        ]
      };
    case AppActionTypes.UPDATE_BILLING_ADDRESS_ADDRESS:
      if (!firstBillingAddress) return state;
      return {
        ...state,
        billingAddresses: [
          {
            ...firstBillingAddress,
            address: action.billingAddressAddress
          }
        ]
      };
    case AppActionTypes.UPDATE_BILLING_ADDRESS_OPTIONAL_ADDRESS:
      if (!firstBillingAddress) return state;
      return {
        ...state,
        billingAddresses: [
          {
            ...firstBillingAddress,
            optionalAddress: action.billingAddressOptionalAddress
          }
        ]
      };
    case AppActionTypes.SEARCH_POSTCODES_SUCCESS:
      if (!firstBillingAddress) return state;
      return {
        ...state,
        billingAddresses: [
          {
            ...firstBillingAddress,
            postalCode: action.postcode,
            prefecture: {
              ...firstBillingAddress.prefecture,
              id: action.prefectureId
            },
            city: action.city,
            address: action.address
          }
        ]
      };
    default:
      return state;
  }
};

export default distributionPartner;
