import { handleMessageThreadScroll } from '@console/actions/thunks/handleMessageThreadScroll';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { postMessageThreadMessage } from '../../../../common/api/graphql/postMessageThreadMessage';
import {
  postMessageThreadMessageStart,
  postMessageThreadMessageSuccess,
  postMessageThreadMessageFailure
} from '../actions';
import handleApiErrorIfNeeded from './handleApiErrorIfNeeded';
import type { ApiError } from '../../../../common/api/graphql/apolloClient';
import type { DistributionPartner } from '../../../../common/api/graphql/getLoginUserGql';
import type {
  MessageThreadUrlCode,
  Node
} from '../../../../common/api/graphql/getMessageThread';
import type {
  PostMessageThreadMessageResponse,
  TextContent,
  RequestCode
} from '../../../../common/api/graphql/postMessageThreadMessage';
import type {
  UploadedFiles,
  UploadedFileUrlCodes
} from '../../../../common/api/graphql/uploadFileGql';
import type { AppThunkAction } from '../thunkType';

export const dateFormat = 'YYYY-MM-DD HH:mm:ssZ';

export const createMessageThreadNode = (
  requestCode: RequestCode,
  postedAt: string,
  name: string,
  distributionPartner: DistributionPartner,
  textContent?: TextContent,
  files?: UploadedFiles
): Node => ({
  requestCode,
  postedAt,
  text: textContent ? { content: textContent } : null,
  files: files ? files : null,
  contributor: {
    __typename: 'DistributionPartnerContributor',
    name,
    iconUrl: '',
    distributionPartner
  }
});

export const postMessageThreadMessageSuccessThunk =
  (
    messageThreadUrlCode: MessageThreadUrlCode,
    requestCode: RequestCode
  ): AppThunkAction<Promise<RequestCode>> =>
  dispatch => {
    dispatch(postMessageThreadMessageSuccess(messageThreadUrlCode));
    return Promise.resolve(requestCode);
  };

export const postMessageThreadMessageFailureThunk =
  (error: ApiError, requestCode: RequestCode): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(postMessageThreadMessageFailure(requestCode));
    return dispatch(handleApiErrorIfNeeded(error));
  };

export const postMessageThreadMessageThunk =
  (
    messageThreadUrlCode: MessageThreadUrlCode,
    textContent?: TextContent,
    fileUrlCodes?: UploadedFileUrlCodes,
    files?: UploadedFiles
  ): AppThunkAction<Promise<RequestCode | void>> =>
  (dispatch, getState) => {
    const requestCode = uuidv4();
    const postedAt = moment().format(dateFormat);
    const { distributionPartner, distributionPartnerUser } = getState();
    const { name } = distributionPartnerUser;

    // update messsageThread state before calling graphql mutation to post message thread message.
    const messageThreadNode = createMessageThreadNode(
      requestCode,
      postedAt,
      name,
      distributionPartner,
      textContent,
      files
    );
    dispatch(postMessageThreadMessageStart(messageThreadNode));

    // execute graphql mutation to post message thread message.
    return postMessageThreadMessage(
      messageThreadUrlCode,
      requestCode,
      textContent,
      fileUrlCodes
    )
      .then((response: PostMessageThreadMessageResponse) =>
        // TODO: update file url after post message thread message with files.
        dispatch(
          postMessageThreadMessageSuccessThunk(
            messageThreadUrlCode,
            requestCode
          )
        )
      )
      .catch(error =>
        dispatch(postMessageThreadMessageFailureThunk(error, requestCode))
      );
  };
