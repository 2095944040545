import { AppActionTypes } from '../../actions/actionType';
import { intialStateOfDistributionPartnerUser } from '../distributionPartnerUser';
import type { DistributionPartnerUser } from '../../../../common/api/graphql/getLoginUserGql';
import type { AppAction } from '../../actions/actionType';

const distributionPartnerUser = (
  state = intialStateOfDistributionPartnerUser,
  action: AppAction
): DistributionPartnerUser => {
  switch (action.type) {
    case AppActionTypes.GET_LOGIN_USER_SUCCESS:
      return action.distributionPartnerUser;
    case AppActionTypes.UPDATE_DISTRIBUTION_PARTNER_USER_NAME:
      return {
        ...state,
        name: action.name
      };
    case AppActionTypes.UPDATE_DISTRIBUTION_PARTNER_USER_DIVISION:
      return {
        ...state,
        division: action.division
      };
    case AppActionTypes.UPDATE_DISTRIBUTION_PARTNER_USER_PHONE_NUMBER:
      return {
        ...state,
        phoneNumber: action.phoneNumber
      };
    default:
      return state;
  }
};

export default distributionPartnerUser;
