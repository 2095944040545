import { createSelector } from 'reselect';
import type { ContentSelectionsFilter } from '@common/api/graphql/getGifteeBoxTemplateContentSelectionsGql';
import type { AppState } from '@console/reducers';

const getContentSelectionsSearchInfo = (state: AppState) =>
  state.searchInfo.contentSelections;

const getContentSelectionsConditions = createSelector(
  [getContentSelectionsSearchInfo],
  contentSelectionsSearchInfo => contentSelectionsSearchInfo?.conditions
);

const getContentSelectionsFilterConditions = createSelector(
  [getContentSelectionsConditions],
  contentSelectionsConditions => contentSelectionsConditions?.filter
);

export const getContentSelectionsFilteringBrandUrlCodes = createSelector(
  [getContentSelectionsFilterConditions],
  (
    contentSelectionsFilterConditions
  ): ContentSelectionsFilter['brandUrlCodes'] =>
    contentSelectionsFilterConditions?.brandUrlCodes || []
);
