import moment from '@common/lib/moment';
import { AppActionTypes } from '../actions/actionType';
import type {
  MessageThread,
  Nodes,
  Node
} from '../../../common/api/graphql/getMessageThread';
import type { AppAction } from '../actions/actionType';

const initialMessageThread: MessageThread = {
  messages: {
    nodes: [],
    pageInfo: {
      endCursor: null,
      hasNextPage: false,
      hasPreviousPage: false,
      startCursor: null
    }
  }
};

const appendNodeIfNeeeded = (nodes: Nodes, newNode: Node) => {
  const existedNode = nodes.find(
    node => node.requestCode === newNode.requestCode
  );
  return !!existedNode ? nodes : [...nodes, newNode];
};

const sortNodeByPostedAtAscendant = (nodes: Nodes) =>
  nodes.sort((nodeA, nodeB) =>
    moment(nodeA.postedAt).diff(moment(nodeB.postedAt)) < 0 ? -1 : 1
  );

const messageThread = (
  state = initialMessageThread,
  action: AppAction
): MessageThread => {
  switch (action.type) {
    case AppActionTypes.POST_MESSAGE_THREAD_MESSAGE_START:
      return {
        messages: {
          nodes: [...state.messages.nodes, action.node],
          pageInfo: state.messages.pageInfo
        }
      };
    case AppActionTypes.POST_MESSAGE_THREAD_MESSAGE_FAILURE:
      return {
        messages: {
          nodes: [
            ...state.messages.nodes.filter(
              node => node.requestCode !== action.requestCode
            )
          ],
          pageInfo: state.messages.pageInfo
        }
      };
    case AppActionTypes.RECEIVE_MESSAGE_THREAD_POSTED_MESSAGE_SUCCESS:
      const nextNodes = appendNodeIfNeeeded(state.messages.nodes, action.node);
      const sortedNextNodes = sortNodeByPostedAtAscendant(nextNodes);
      return {
        messages: {
          nodes: sortedNextNodes,
          pageInfo: state.messages.pageInfo
        }
      };
    case AppActionTypes.GET_MESSAGE_THREAD_SUCCESS:
      return action.messageThread;
    default:
      return state;
  }
};

export default messageThread;
