import {
  GIFTEE_BOX_TEMPLATE_BRAND_NAME,
  GIFTEE_BOX_TEMPLATE_IMAGE_DOWNLOAD_URL
} from '@console/common/constants';
import { Grid, Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import ContentBrief from './ContentBrief';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => ({
  contentContainer: {
    width: theme.spacing(65),
    whiteSpace: 'pre-line',
    margin: `${theme.spacing()} 0`
  },
  handleButton: {
    margin: `${theme.spacing(1)} 0`
  }
});

type GifteeBoxTemplateBriefOwnProps = {
  gifteeBoxTemplateImageUrl: string;
  gifteeBoxTemplateName: string;
  gifteeBoxConfigUrlCode?: string;
  exchangeableEndLabel?: string; // TODO: 案件詳細の発行設定の表示の対応ができたら?を外す
  isLimitedPublished?: boolean;
  handleButtonClick?: () => void;
  handleMessageCardOptionButton?: () => void;
  imageDownloadable?: boolean;
};

type GifteeBoxTemplateBriefProps = GifteeBoxTemplateBriefOwnProps &
  WithStyles<typeof styles>;

const GifteeBoxTemplateBrief: React.FC<GifteeBoxTemplateBriefProps> = ({
  classes,
  gifteeBoxTemplateImageUrl,
  gifteeBoxTemplateName,
  gifteeBoxConfigUrlCode,
  exchangeableEndLabel,
  isLimitedPublished,
  handleButtonClick,
  handleMessageCardOptionButton,
  imageDownloadable
}) => (
  <Grid
    container
    direction="row"
    alignItems="center"
    className={classes.contentContainer}
    data-cy={`gifteeBoxTemplateBrief-${gifteeBoxTemplateName}`}
  >
    <Grid item xs={8}>
      <ContentBrief
        contentName={gifteeBoxTemplateName}
        contentImageUrl={gifteeBoxTemplateImageUrl}
        selectablePeriodText={`選択期限：${
          exchangeableEndLabel || '3ヶ月後の月末'
        }`} // TODO: 案件詳細の発行設定の表示の対応ができたらデフォルトの3ヶ月後の月末を削除
        contentBrandName={GIFTEE_BOX_TEMPLATE_BRAND_NAME}
        imageDownloadUrl={
          imageDownloadable ? GIFTEE_BOX_TEMPLATE_IMAGE_DOWNLOAD_URL : undefined
        }
        inquiryCodeText={
          gifteeBoxConfigUrlCode
            ? `識別コード：${gifteeBoxConfigUrlCode.slice(0, 3)}`
            : ''
        }
        isLimitedPublished={isLimitedPublished}
      />
    </Grid>
    {!!handleButtonClick ? (
      <Grid item xs={4}>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleButtonClick}
          data-cy={`gifteeBoxTemplateBriefButton-${gifteeBoxTemplateName}`}
          className={classes.handleButton}
        >
          掲載商品一覧
        </Button>
        {!!handleMessageCardOptionButton ? (
          <Button
            variant="outlined"
            color="primary"
            onClick={handleMessageCardOptionButton}
            data-cy={`gifteeBoxTemplateBriefButton-${gifteeBoxTemplateName}`}
            className={classes.handleButton}
          >
            メッセージカード情報
          </Button>
        ) : null}
      </Grid>
    ) : null}
  </Grid>
);

export default withStyles(styles)(GifteeBoxTemplateBrief);
