import { createMessage as createErrorMessage } from '@common/api/graphql/errorMessage';
import { createMessage } from '@common/api/graphql/successMessage';
import {
  copyProjectToProjectDraft,
  copyProjectToProjectDraftFailure,
  copyProjectToProjectDraftSuccess
} from '../actions';
import { registerProjectDraftThunk } from './registerProjectDraft';
import type { AppThunkAction } from '../thunkType';
import type { ProjectState } from '@/src/project_executor/console/reducers/projects';

export const copyProjectToProjectDraftSuccessThunk =
  (): AppThunkAction<Promise<void>> => dispatch => {
    dispatch(
      copyProjectToProjectDraftSuccess(createMessage('キャンペーンをコピー'))
    );
    return Promise.resolve();
  };

export const copyProjectToProjectDraftFailureThunk =
  (): AppThunkAction<Promise<void>> => dispatch => {
    dispatch(
      copyProjectToProjectDraftFailure(
        createErrorMessage('キャンペーンをコピー')
      )
    );
    return Promise.reject();
  };

export const copyProjectToProjectDraftThunk =
  (project: ProjectState): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(copyProjectToProjectDraft(project));

    return dispatch(registerProjectDraftThunk())
      .then(() => dispatch(copyProjectToProjectDraftSuccessThunk()))
      .catch(() => dispatch(copyProjectToProjectDraftFailureThunk()));
  };
