import { gql } from '@apollo/client';

import { internalApiAppClient } from './apolloClient';
import { operationNames } from './operationNames';
import type { FetchResult } from '@apollo/client';

const getGiftDistributionChannelCategoriesGql = gql`
  query ${operationNames.GET_GIFT_DISTRIBUTION_CHANNEL_CATEGORIES}{
    giftDistributionChannelCategories {
      name
      urlCode
      descriptionEnabled
    }
  }
`;

export type GiftDistributionChannelCategory = {
  name: string;
  urlCode: string;
  descriptionEnabled: boolean;
};

export type GiftDistributionChannelCategories =
  GiftDistributionChannelCategory[];

export type GetGiftDistributionChannelCategoriesResponse = FetchResult<{
  giftDistributionChannelCategories: GiftDistributionChannelCategories;
}>;

export const getGiftDistributionChannelCategories =
  (): Promise<GetGiftDistributionChannelCategoriesResponse> =>
    internalApiAppClient.query({
      query: getGiftDistributionChannelCategoriesGql
    });
