import { AppActionTypes } from '@console/actions/actionType';
import type { AdvertisingMedia } from '@common/api/graphql/getAdvertisingMediaGql';
import type { AppAction } from '@console/actions/actionType';

const initialAdvertisingMedia: AdvertisingMedia = [];

const advertisingMedia = (
  state = initialAdvertisingMedia,
  action: AppAction
): AdvertisingMedia => {
  switch (action.type) {
    case AppActionTypes.GET_ADVERTISING_MEDIA_SUCCESS:
    case AppActionTypes.GET_MASTER_DATA_SUCCESS:
      return action.advertisingMedia;
    default:
      return state;
  }
};

export default advertisingMedia;
