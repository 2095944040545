import { createSelector } from 'reselect';
import type { AppState } from '@console/reducers';

const getDistributionPartnerFromAppState = (state: AppState) =>
  state.distributionPartner;

export const getCreditLimitBalance = createSelector(
  [getDistributionPartnerFromAppState],
  (distributionPartner): number | null => distributionPartner.creditLimitBalance
);
