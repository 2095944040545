import CircularProgress from '@mui/material/CircularProgress';
import Fade from '@mui/material/Fade';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    mask: {
      position: 'fixed',
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0,0,0,0.3)',
      zIndex: theme.zIndex.loader,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    progress: {
      margin: theme.spacing(2),
      width: 60
    }
  });

type LoaderOwnProps = {
  isLoading: boolean;
};

type LoaderProps = LoaderOwnProps & WithStyles<typeof styles>;

const Loader: React.FC<LoaderProps> = ({ isLoading, classes }) => (
  <Fade in={isLoading} timeout={{ exit: 500 }} data-cy="loader">
    <div className={classes.mask}>
      <CircularProgress className={classes.progress} color="primary" />
    </div>
  </Fade>
);

export default withStyles(styles)(Loader);
