import { gql } from '@apollo/client';

import { internalApiAppClient } from './apolloClient';
import { operationNames } from './operationNames';
import type { Brand } from './getBrandsGql';
import type { FetchResult } from '@apollo/client';

const getGifteeBoxTemplateBrandsGql = gql`
  query ${operationNames.GET_GIFTEE_BOX_TEMPLATE_BRANDS}($urlCode: String!) {
    gifteeBoxTemplate(urlCode: $urlCode) {
      brands {
        name
        urlCode
      }
    }
  }
`;

export type GifteeBoxTemplateBrand = {
  name: Brand['name'];
  urlCode: Brand['urlCode'];
};

export type GifteeBoxTemplateBrands = GifteeBoxTemplateBrand[];

export type GetGifteeBoxTemplateBrandsResponse = FetchResult<{
  gifteeBoxTemplate: {
    brands: GifteeBoxTemplateBrands;
  };
}>;

export const getGifteeBoxTemplateBrands = (
  gifteeBoxTemplateUrlCode: UrlCode
): Promise<GetGifteeBoxTemplateBrandsResponse> =>
  internalApiAppClient.query({
    query: getGifteeBoxTemplateBrandsGql,
    variables: {
      urlCode: gifteeBoxTemplateUrlCode
    }
  });
