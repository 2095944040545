import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import { Fragment } from 'react';
import { projectDraftGiftDistributionHasValidationErrors } from '../../middleware/validator/projectDraft/giftDistribution';
import type {
  CommonErrorPropsMappedFromState,
  CommonErrorPropsMappedFromDispatch
} from '../../containers/common/CommonErrorContainer';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => ({
  errorMessage: {
    width: '100%',
    minHeight: theme.spacing(3),
    fontSize: 14
  }
});

type CommonErrorProps = CommonErrorPropsMappedFromState &
  CommonErrorPropsMappedFromDispatch &
  WithStyles<typeof styles>;

const CommonError: React.FC<CommonErrorProps> = ({
  errorKey,
  errors,
  classes
}) => {
  const renderError = () => {
    if (errorKey) {
      return renderPageValidationError();
    }
    return renderCommonError();
  };

  const renderPageValidationError = () => {
    const pageErrors = errors[errorKey!];
    return hasPageValidationError()
      ? Object.keys(pageErrors).map((error, index) => (
          <Typography
            id="pageValidationError"
            className={classes.errorMessage}
            color="error"
            key={`error-${index}`}
          >
            ・{pageErrors[error]}
          </Typography>
        ))
      : null;
  };

  const hasPageValidationError = (): boolean => {
    switch (errorKey) {
      case 'projectDraftGiftDistribution':
        return projectDraftGiftDistributionHasValidationErrors(errors);
      case 'projectDraftCampaignOverview':
        return projectDraftGiftDistributionHasValidationErrors(errors);
      default:
        return false;
    }
  };

  const renderCommonError = () =>
    errors.common
      ? errors.common.map((commonError, index) => (
          <Typography
            id="commonError"
            className={classes.errorMessage}
            color="error"
            key={`error-${index}`}
          >
            ・{commonError}
          </Typography>
        ))
      : null;
  return <Fragment>{renderError()}</Fragment>;
};

export default withStyles(styles)(CommonError);
