import { AppActionTypes } from '../../actions/actionType';
import type { AppAction } from '../../actions/actionType';

export type GiftDistributionPeriod = {
  startDateOfDistribution: string | null;
  endDateOfDistribution: string | null;
};

export const initialStateOfGiftDistributionPeriod = {
  startDateOfDistribution: null,
  endDateOfDistribution: null
};

export const giftDistributionPeriod = (
  state = initialStateOfGiftDistributionPeriod,
  action: AppAction
): GiftDistributionPeriod => {
  switch (action.type) {
    case AppActionTypes.ADD_PROJECT_DRAFT:
    case AppActionTypes.COPY_PROJECT_TO_PROJECT_DRAFT:
    case AppActionTypes.COPY_PROJECT_TO_PROJECT_DRAFT_FAILURE:
      return initialStateOfGiftDistributionPeriod;
    case AppActionTypes.SELECT_PROJECT_DRAFT:
      return action.projectDraft.projectJson.giftDistributionPeriod;
    case AppActionTypes.UPDATE_START_DATE_OF_DISTRIBUTION:
      return {
        ...state,
        startDateOfDistribution: action.startDateOfDistribution
          ? action.startDateOfDistribution.toISOString()
          : null
      };
    case AppActionTypes.UPDATE_END_DATE_OF_DISTRIBUTION:
      return {
        ...state,
        endDateOfDistribution: action.endDateOfDistribution
          ? action.endDateOfDistribution.toISOString()
          : null
      };
    default:
      return state;
  }
};

export default giftDistributionPeriod;
