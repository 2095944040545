import { NULL_CONFIDENTIAL_INFORMATION } from '@common/api/graphql/getProjectsGql';
import {
  isBulkConfidentialInformation,
  isAPIConfidentialInformation
} from '@console/common/project/confidentialInformation';
import { createSelector } from 'reselect';
import type { AppState } from '@console/reducers';
import type {
  LocalBulkConfidentialInformation,
  LocalAPIConfidentialInformation,
  LocalProject
} from '@console/reducers/projects';

const getProjectDetailConfidentialInformation = (state: AppState) =>
  state.projectDetail.confidentialInformation;

export const getConfidentialInformation = createSelector(
  [getProjectDetailConfidentialInformation],
  (confidentialInformation): LocalProject['confidentialInformation'] =>
    confidentialInformation ?? {
      __typename: NULL_CONFIDENTIAL_INFORMATION,
      reason: ''
    }
);

export const getBulkConfidentialInformation = createSelector(
  [getConfidentialInformation],
  (confidentialInformation): LocalBulkConfidentialInformation | null =>
    isBulkConfidentialInformation(confidentialInformation)
      ? confidentialInformation
      : null
);

export const getApiConfidentialInformation = createSelector(
  [getConfidentialInformation],
  (confidentialInformation): LocalAPIConfidentialInformation | null =>
    isAPIConfidentialInformation(confidentialInformation)
      ? confidentialInformation
      : null
);
