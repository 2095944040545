import { AppActionTypes } from '../../actions/actionType';
import type { ClaimEstimate } from '../../../../common/api/graphql/getClaimEstimate';
import type { AppAction } from '../../actions/actionType';

export const initialStateOfClaimEstimate: ClaimEstimate = {
  consumptionTaxPrice: 0,
  issueCommissionPrice: 0,
  totalPrice: 0
};

export const claimEstimate = (
  state = initialStateOfClaimEstimate,
  action: AppAction
): ClaimEstimate => {
  switch (action.type) {
    case AppActionTypes.GET_CLAIM_ESTIMATE_SUCCESS:
      return action.claimEstimate;
    case AppActionTypes.ADD_PROJECT_DRAFT:
    case AppActionTypes.COPY_PROJECT_TO_PROJECT_DRAFT:
    case AppActionTypes.COPY_PROJECT_TO_PROJECT_DRAFT_FAILURE:
      return initialStateOfClaimEstimate;
    case AppActionTypes.SELECT_PROJECT_DRAFT:
      return action.projectDraft.projectJson.claimEstimate;
    default:
      return state;
  }
};
