import { getG4bMyPageContentsUrl } from '@console/common/g4bMyPage';
import Note from '@console/components/common/Note';
import { Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';
import type { ReactElement } from 'react';

const styles = (theme: Theme): StyleRules => ({
  issueRateNotes: {
    marginBottom: theme.spacing(2)
  }
});

type ConfirmationNotesProps = WithStyles<typeof styles>;

const ConfirmationNotes: React.FC<ConfirmationNotesProps> = ({ classes }) => {
  const ISSUE_RATE_NOTES: (string | ReactElement)[] = [
    '※発行手数料について',
    '商品代合計に応じて発行手数料は異なります。',
    '商品代合計が10万円以上の場合、商品代合計の10％',
    '商品代合計が10万円未満3万円以上の場合、一律1万円',
    '商品代合計が3万円未満2万円以上の場合、一律6000円',
    '商品代合計が2万円未満の場合、一律3000円',
    <Note>
      ※発行手数料が上記と異なるギフトもございます。
      <a
        href={getG4bMyPageContentsUrl()}
        target="_blank"
        rel="noopener noreferrer"
      >
        ギフト一覧
      </a>
      に掲載された発行手数料をご確認ください。
    </Note>,
    'なお、発行手数料は納品日ごとの算出となります。納品日が複数に分かれる場合、上記発行手数料が変わる可能性がございますので、予めご了承ください。',
    '※弊社キャンペーンツールをご利用いただく場合、別途システム利用料がかかります。詳細は担当にご確認ください。'
  ];

  const TOS_NOTES = [
    'お申込内容を再度確認し、以下をご確認の上、お申込みを行ってください。',
    '※「giftee for Business等利用規約目次」から、上記お申込み内容に関して定められた各サービスの利用規約をご確認頂けます。'
  ];

  return (
    <Grid container direction="column" data-cy="confirmationNotes">
      <Grid
        item
        className={classes.issueRateNotes}
        data-cy="confirmationIssueRateNotes"
      >
        {ISSUE_RATE_NOTES.map(note =>
          typeof note === 'string' ? <Note key={note}>{note}</Note> : note
        )}
      </Grid>
      <Grid item data-cy="confirmationTOSNotes">
        {TOS_NOTES.map(note => (
          <Note key={note}>{note}</Note>
        ))}
        <Note>
          giftee for Business等利用規約目次は
          <a
            href="https://docs.giftee.biz/business/downloads/giftee_Business_Agreement_index.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            こちら
          </a>
        </Note>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(ConfirmationNotes);
