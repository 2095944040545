import { Typography, Dialog, DialogTitle, DialogContent } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    note: {
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`
    }
  });

type OwnProps = {
  isOpen: boolean;
  onCloseHandler: (event: React.MouseEvent, ...args: any) => void;
};

type GiftCardPreviewModalProps = OwnProps & WithStyles<typeof styles>;

export const GIFT_CARD_PREVIEW_DIALOG_SCROLL_CONTAINER =
  'giftCardPreviewDialogScrollContainer';

const GiftCardPreviewModal: React.FC<
  React.PropsWithChildren<GiftCardPreviewModalProps>
> = ({ isOpen, onCloseHandler, classes, children }) => (
  <Dialog
    aria-labelledby="dialog-title"
    aria-describedby="dialog-description"
    open={isOpen}
    fullWidth={true}
    maxWidth="xs"
    scroll="paper"
    onClose={onCloseHandler}
    data-cy="giftCardPreviewModal"
  >
    <DialogTitle id="dialog-title">プレビュー</DialogTitle>
    <Typography color="textPrimary" className={classes.note}>
      ※ご利用の機種、ブラウザ等により、実際の画面と異なる場合がございます。
    </Typography>
    <DialogContent dividers id={GIFT_CARD_PREVIEW_DIALOG_SCROLL_CONTAINER}>
      {children}
    </DialogContent>
  </Dialog>
);

export default withStyles(styles)(GiftCardPreviewModal);
