import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import { Fragment, useState } from 'react';
import type { MessageCard } from '../api/graphql/getGiftCardGql';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => ({
  root: {
    maxWidth: 600
  },
  mainVisual: {
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  },
  title: {
    marginTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'inline',
    wordWrap: 'break-word'
  },
  titleMain: {
    fontWeight: 'bold'
  },
  senderNameHelp: {
    marginLeft: 3
  },
  descriptionCard: {
    width: '100%',
    minHeight: 64,
    margin: theme.spacing(2),
    padding: theme.spacing(),
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.spacing()
  },
  message: {
    fontSize: 14,
    padding: 16,
    wordWrap: 'break-word'
  },
  more: {
    fontSize: 12,
    color: '#969696'
  }
});

type GiftCardMessageCardOwnProps = {
  messageCard: MessageCard | null;
};

type GiftCardMessageCardProps = GiftCardMessageCardOwnProps &
  WithStyles<typeof styles>;

const GiftCardMessageCard: React.FC<GiftCardMessageCardProps> = ({
  messageCard,
  classes
}) => {
  const [isOpen, setOpen] = useState(false);

  const isFullMessageShowable = (): boolean => isOpen || isShortMessage();

  const handleMessageOpen = (): void => {
    setOpen(true);
  };

  const isShortMessage = (): boolean => messageCard!.description!.length <= 37;

  return !messageCard ? null : (
    <Grid container justifyContent="center" className={classes.root}>
      {messageCard.mainVisualUrl ? (
        <img
          src={messageCard.mainVisualUrl}
          className={classes.mainVisual}
          data-cy="messageCardMainVisual"
        />
      ) : null}
      <Grid
        item
        container
        justifyContent="flex-start"
        alignItems="flex-end"
        xs={12}
        className={classes.title}
      >
        {messageCard.senderName ? (
          <Fragment>
            <Typography
              variant="h6"
              display="inline"
              className={classes.titleMain}
            >
              {messageCard.senderName}
            </Typography>
            <Typography
              variant="body1"
              display="inline"
              className={classes.senderNameHelp}
            >
              さんからのギフト
            </Typography>
          </Fragment>
        ) : null}
      </Grid>
      <Grid item container justifyContent="center" xs={12}>
        {messageCard.description ? (
          <Card className={classes.descriptionCard}>
            {isFullMessageShowable() ? (
              <Typography
                className={classes.message}
                data-cy="messageCardFullDescription"
              >
                {messageCard.description}
              </Typography>
            ) : (
              <Typography
                className={classes.message}
                data-cy="messageCardDescription"
              >
                {messageCard.description.substring(0, 37)}
                <Link
                  variant="body2"
                  underline="always"
                  className={classes.more}
                  onClick={handleMessageOpen}
                >
                  ...もっと見る
                </Link>
              </Typography>
            )}
          </Card>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(GiftCardMessageCard);
