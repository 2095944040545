import { getIssueCounts } from '@common/api/graphql/getIssueCountsGql';
import moment from '@common/lib/moment';
import {
  getIssueCountsFailure,
  getIssueCountsStart,
  getIssueCountsSuccess
} from '@console/actions/actions';
import handleApiErrorIfNeeded from './handleApiErrorIfNeeded';
import type { ApiError } from '@common/api/graphql/apolloClient';
import type {
  IssueCountResult,
  IssueCountsParams
} from '@common/api/graphql/getIssueCountsGql';
import type { AppThunkAction } from '@console/actions/thunkType';

export const createIssueCountsParams = (
  projectUrlCode: string,
  issueDateFrom: string | null,
  issueDateTo: string | null
): IssueCountsParams => ({
  projectUrlCode,
  issueDateFrom: moment(issueDateFrom)
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    .toISOString(true),
  issueDateTo: moment(issueDateTo)
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    .add(1, 'days')
    .toISOString(true)
});

export const getIssueCountsSuccessThunk =
  (issueCountResult: IssueCountResult): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(getIssueCountsSuccess(issueCountResult));
    return Promise.resolve();
  };

export const getIssueCountsFailureThunk =
  (error: ApiError): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(getIssueCountsFailure());
    return dispatch(handleApiErrorIfNeeded(error));
  };

export const getIssueCountsThunk =
  (): AppThunkAction<Promise<void>> => (dispatch, getState) => {
    dispatch(getIssueCountsStart());
    const issueCountsParams = createIssueCountsParams(
      getState().selectedProjectUrlCode,
      getState().issueReportsFilter.issueDateFrom,
      getState().issueReportsFilter.issueDateTo
    );
    return getIssueCounts(issueCountsParams)
      .then(response =>
        dispatch(getIssueCountsSuccessThunk(response.data!.aggregateIssueCount))
      )
      .catch(error => dispatch(getIssueCountsFailureThunk(error)));
  };
