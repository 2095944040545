import CopyToClipboardButton from '@common/components/CopyToClipboardButton';
import {
  getLocaleDateString,
  getLocaleEndDateString
} from '@common/lib/getLocaleDate';
import { getLocalePrice } from '@common/lib/getLocalePrice';
import { isAPIConfidentialInformation } from '@console/common/project/confidentialInformation';
import GiftCardPreviewModal from '@console/components/common/GiftCardPreviewModal';
import GiftCardTicketConfigsContents from '@console/components/project/common/GiftCardTicketConfigsContents';
import TicketTypeChips from '@console/components/project/common/TicketTypeChips';
import GiftCardPreviewContainer from '@console/containers/project/GiftCardPreviewContainer';
import GifteeBoxConfigsTableContainer from '@console/containers/project/giftConfig/APITable/GifteeBoxConfigsTableContainer';
import {
  Typography,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Grid,
  Button
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { useState, useCallback } from 'react';
import GiftCardTicketConfigContents from '../common/GiftCardTicketConfigContents';
import TicketTypeChip, {
  getGiftCardTicketType
} from '../common/TicketTypeChip';
import Notes from './Notes';
import type { QueryApiGiftCardIssueConfig } from '@common/api/graphql/getProjectsGql';
import type {
  ApiTablePropsMappedFromState,
  ApiTablePropsMappedFromDispatch
} from '@console/containers/project/giftConfig/ApiTableContainer';
import type { GiftCardConfigForDisplay } from '@console/selectors/projectDetail/giftCardConfigs';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';
import type { ReactNode } from 'react';

const styles = (theme: Theme): StyleRules => ({
  tableContainer: {
    overflowX: 'scroll',
    whiteSpace: 'nowrap'
  },
  table: {
    textAlign: 'left',
    marginTop: theme.spacing(0.5)
  },
  tableCell: {
    padding: theme.spacing(2),
    verticalAlign: 'middle'
  },
  contentContainer: {
    width: theme.spacing(45),
    whiteSpace: 'pre-line'
  },
  contentImage: {
    width: theme.spacing(9),
    verticalAlign: 'middle'
  },
  contentImageContainer: {
    marginRight: theme.spacing(2)
  },
  button: {
    margin: theme.spacing(),
    marginRight: 0,
    padding: '5px 8px'
  },
  notes: {
    marginTop: theme.spacing(2)
  },
  disabled: {
    backgroundColor: theme.palette.text.disabled
  }
});

type APITableProps = ApiTablePropsMappedFromState &
  ApiTablePropsMappedFromDispatch &
  WithStyles<typeof styles>;

const API_NOTES = [
  '※ gift_card_config_code は、ご利用の準備が整い次第発行されます。'
];

const APITable: React.FC<APITableProps> = ({
  classes,
  giftCardConfigs,
  confidentialInformation,
  onClickGiftCardPreviewButton
}) => {
  const [giftCardPreviewModalOpen, setGiftCardPreviewModalOpen] =
    useState(false);

  const openGiftCardPreviewModal = useCallback(() => {
    setGiftCardPreviewModalOpen(true);
  }, []);

  const closeGiftCardPreviewModal = useCallback(() => {
    setGiftCardPreviewModalOpen(false);
  }, []);

  const handleGiftCardPreviewButtonClick = useCallback(
    (giftCardConfig: GiftCardConfigForDisplay) => {
      onClickGiftCardPreviewButton(giftCardConfig);
      openGiftCardPreviewModal();
    },
    []
  );

  const handleGiftCardPreviewModalClose = useCallback(() => {
    closeGiftCardPreviewModal();
  }, []);

  const renderIssueCode = (giftCardConfigIndex: number): ReactNode => {
    if (isAPIConfidentialInformation(confidentialInformation)) {
      const confidentialInformationGiftCardConfig =
        confidentialInformation.giftCardConfigs[giftCardConfigIndex];

      if (!!confidentialInformationGiftCardConfig.issueCode) {
        return (
          <>
            <div>{confidentialInformationGiftCardConfig.issueCode}</div>
            <CopyToClipboardButton
              copyText={confidentialInformationGiftCardConfig.issueCode}
              tooltipPlacement="left"
            />
          </>
        );
      }
    }

    return 'まだ発行されていません';
  };

  const isDisabledGiftCardIssueConfig = (
    giftCardIssueConfig: QueryApiGiftCardIssueConfig | null
  ) =>
    !!giftCardIssueConfig?.issuableEndAt &&
    new Date(giftCardIssueConfig.issuableEndAt) < new Date();

  return (
    <>
      <div
        className={classes.tableContainer}
        data-cy="giftCardConfigsTableContainer"
      >
        <Table className={classes.table}>
          <TableHead data-cy="tableHeader">
            <TableRow>
              <TableCell className={classes.tableCell} align="center">
                ギフトのタイプ
              </TableCell>
              <TableCell className={classes.tableCell} align="left">
                ギフト・ブランド名
              </TableCell>
              <TableCell className={classes.tableCell} align="left">
                ギフトカード
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                単価
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                {/* TODO: RENAME */}
                gift_card_config_code
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                発行開始日
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                発行終了日
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                数量（見込み）
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                予定金額
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {giftCardConfigs.map((giftCardConfig, giftCardConfigIndex) => {
              const lastApiGiftCardIssueConfig =
                giftCardConfig.lastApiGiftCardIssueConfig;
              const currentApiGiftCardIssueConfig =
                giftCardConfig.currentApiGiftCardIssueConfig;

              if (!!lastApiGiftCardIssueConfig) {
                return (
                  <TableRow
                    className={
                      isDisabledGiftCardIssueConfig(
                        currentApiGiftCardIssueConfig
                      )
                        ? classes.disabled
                        : ''
                    }
                    key={`apiGiftCardIssueConfig-${giftCardConfigIndex}`}
                    data-cy={`apiGiftCardIssueConfigRow-${giftCardConfigIndex}`}
                  >
                    <TableCell
                      className={classes.tableCell}
                      align="center"
                      data-cy={`apiGiftCardIssueConfigTicketType-${giftCardConfigIndex}`}
                    >
                      <TicketTypeChips giftCardConfig={giftCardConfig} />
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      align="left"
                      data-cy={`apiGiftCardIssueConfigContent-${giftCardConfigIndex}`}
                    >
                      <GiftCardTicketConfigsContents
                        giftCardConfig={giftCardConfig}
                        imageDownloadable={true}
                      />
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      align="left"
                      data-cy={`apiGiftCardIssueConfigMessageCard-${giftCardConfigIndex}`}
                    >
                      {giftCardConfig.isGiftCardPreviewVisible ? (
                        <Button
                          color="primary"
                          variant="outlined"
                          className={classes.button}
                          onClick={() => {
                            handleGiftCardPreviewButtonClick(giftCardConfig);
                          }}
                        >
                          <Typography variant="body2">
                            プレビューを見る
                          </Typography>
                        </Button>
                      ) : (
                        '無し'
                      )}
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      align="right"
                      data-cy={`apiGiftCardIssueConfigContentCurrentPrice-${giftCardConfigIndex}`}
                    >
                      {getLocalePrice(giftCardConfig.unitPrice)}
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      align="right"
                      data-cy={`apiConfidentialInformationGiftCardConfigIssueCode-${giftCardConfigIndex}`}
                    >
                      {renderIssueCode(giftCardConfigIndex)}
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      align="center"
                      data-cy={`apiGiftCardIssueConfigIssuableTimeSpan-${giftCardConfigIndex}`}
                    >
                      <Typography
                        variant="body2"
                        data-cy={`apiGiftCardIssueConfigIssuableBeginAt-${giftCardConfigIndex}`}
                      >
                        {currentApiGiftCardIssueConfig
                          ? getLocaleDateString(
                              currentApiGiftCardIssueConfig.issuableBeginAt
                            )
                          : ''}
                      </Typography>
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      align="center"
                      data-cy={`apiGiftCardIssueConfigIssuableTimeSpan-${giftCardConfigIndex}`}
                    >
                      <Typography
                        variant="body2"
                        data-cy={`apiGiftCardIssueConfigIssuableEndAt-${giftCardConfigIndex}`}
                      >
                        {currentApiGiftCardIssueConfig
                          ? getLocaleEndDateString(
                              currentApiGiftCardIssueConfig.issuableEndAt
                            )
                          : ''}
                      </Typography>
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      align="right"
                      data-cy={`apiGiftCardIssueConfigEstimatedIssueAmount-${giftCardConfigIndex}`}
                    >
                      {currentApiGiftCardIssueConfig?.estimatedIssueAmount ||
                        ''}
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      align="right"
                      data-cy={`apiGiftCardIssueConfigEstimatedTotal-${giftCardConfigIndex}`}
                    >
                      {currentApiGiftCardIssueConfig
                        ? getLocalePrice(
                            giftCardConfig.unitPrice *
                              currentApiGiftCardIssueConfig.estimatedIssueAmount
                          )
                        : ''}
                    </TableCell>
                  </TableRow>
                );
              }
            })}
          </TableBody>
        </Table>
        <div className={classes.notes}>
          <Notes notes={API_NOTES} />
        </div>
        <GiftCardPreviewModal
          isOpen={giftCardPreviewModalOpen}
          onCloseHandler={handleGiftCardPreviewModalClose}
        >
          <GiftCardPreviewContainer />
        </GiftCardPreviewModal>
      </div>
      <div
        className={classes.tableContainer}
        data-cy="gifteeBoxConfigsTableContainer"
      >
        <GifteeBoxConfigsTableContainer />
      </div>
    </>
  );
};

export default withStyles(styles)(APITable);
