import { AppActionTypes } from '@console/actions/actionType';
import { combineReducers } from 'redux';
import type { ThirdPartyService } from '@common/api/graphql/projectTypes';
import type { AppAction } from '@console/actions/actionType';
import type { Reducer } from 'react';

const description: Reducer<
  ThirdPartyService['description'] | null,
  AppAction
> = (state = null, action) => {
  switch (action.type) {
    case AppActionTypes.GET_PROJECT_DETAIL_SUCCESS:
      return action.project.thirdPartyService?.description ?? null;
    default:
      return state;
  }
};

const name: Reducer<ThirdPartyService['name'] | null, AppAction> = (
  state = null,
  action
) => {
  switch (action.type) {
    case AppActionTypes.GET_PROJECT_DETAIL_SUCCESS:
      return action.project.thirdPartyService?.name ?? null;
    default:
      return state;
  }
};

const url: Reducer<ThirdPartyService['url'] | null, AppAction> = (
  state = null,
  action
) => {
  switch (action.type) {
    case AppActionTypes.GET_PROJECT_DETAIL_SUCCESS:
      return action.project.thirdPartyService?.url ?? null;
    default:
      return state;
  }
};

export const thirdPartyService = combineReducers({
  description,
  name,
  url
});
