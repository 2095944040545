import { gql } from '@apollo/client';

import { internalApiAppClient } from './apolloClient';
import { operationNames } from './operationNames';
import type { Content } from './getContentsGql';
import type { GifteeBoxTemplateBrand } from './getGifteeBoxTemplateBrandsGql';
import type { FetchResult } from '@apollo/client';

const getGifteeBoxTemplateContentSelectionsGql = gql`
  query ${operationNames.GET_GIFTEE_BOX_TEMPLATE_CONTENT_SELECTIONS}(
    $urlCode: String!
    $first: Int!
    $after: String
    $conditions: ConditionsInput
  ) {
    gifteeBoxTemplate(urlCode: $urlCode) {
      contentSelections(first: $first, after: $after, conditions: $conditions) {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        nodes {
          urlCode
          exchangePoint
          content {
            name
            internalName
            imageUrl
            urlCode
            availablePeriod
            brand {
              name
              urlCode
            }
          }
        }
      }
    }
  }
`;

export type GifteeBoxTemplateContentSelectionsContent = {
  name: Content['name'];
  internalName: Content['internalName'];
  imageUrl: Content['imageUrl'];
  urlCode: Content['urlCode'];
  availablePeriod: Content['availablePeriod'];
  brand: {
    name: Content['brand']['name'];
    urlCode: Content['brand']['urlCode'];
  };
};

export type GifteeBoxTemplateContentSelectionsNode = {
  urlCode: UrlCode;
  content: GifteeBoxTemplateContentSelectionsContent;
  exchangePoint: number;
};

export type GifteeBoxTemplateContentSelectionsConnectionPageInfo = {
  startCursor: GifteeBoxTemplateContentSelectionsConnectionBase['pageInfo']['startCursor'];
  endCursor: GifteeBoxTemplateContentSelectionsConnectionBase['pageInfo']['endCursor'];
};

export type GifteeBoxTemplateContentSelectionsConnection = {
  nodes: GifteeBoxTemplateContentSelectionsConnectionBase['nodes'];
  pageInfo: GifteeBoxTemplateContentSelectionsConnectionBase['pageInfo'];
};

type GifteeBoxTemplateContentSelectionsConnectionBase =
  IkedayamaConnection<GifteeBoxTemplateContentSelectionsNode>;

export type GetGifteeBoxTemplateContentSelectionsResponse = FetchResult<{
  gifteeBoxTemplate: {
    contentSelections: GifteeBoxTemplateContentSelectionsConnection;
  };
}>;

export type ContentSelectionsConditions = {
  filter: ContentSelectionsFilter;
};

export type ContentSelectionsFilter = {
  brandUrlCodes: GifteeBoxTemplateBrand['urlCode'][];
};

export type GetGifteeBoxTemplateContentSelectionsSearchInfo = {
  after?: GifteeBoxTemplateContentSelectionsConnection['pageInfo']['endCursor'];
  first?: number;
  conditions?: ContentSelectionsConditions;
};

export const getGifteeBoxTemplateContentSelections = (
  gifteeBoxTemplateUrlCode: UrlCode,
  searchInfo?: GetGifteeBoxTemplateContentSelectionsSearchInfo
): Promise<GetGifteeBoxTemplateContentSelectionsResponse> =>
  internalApiAppClient.query({
    query: getGifteeBoxTemplateContentSelectionsGql,
    variables: {
      urlCode: gifteeBoxTemplateUrlCode,
      after: searchInfo?.after || null,
      first: searchInfo?.first || 20,
      conditions: searchInfo?.conditions
    }
  });
