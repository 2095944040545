import { AppActionTypes } from '../actions/actionType';
import type { DistributionPartnerUser } from '../../../common/api/graphql/getLoginUserGql';
import type { AppAction } from '../actions/actionType';

export const intialStateOfDistributionPartnerUser = {
  name: '',
  email: '',
  division: '',
  phoneNumber: '',
  urlCode: ''
};

const distributionPartnerUser = (
  state = intialStateOfDistributionPartnerUser,
  action: AppAction
): DistributionPartnerUser => {
  switch (action.type) {
    case AppActionTypes.GET_LOGIN_USER_SUCCESS:
      return action.distributionPartnerUser;
    default:
      return state;
  }
};

export default distributionPartnerUser;
