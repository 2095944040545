import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import * as Sentry from '@sentry/react';
import { Component } from 'react';
import InternalServerError from './InternalServerError';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => createStyles({});

type ErrorBoundaryOwnState = {
  hasError: boolean;
  eventId: string | null;
};

export type ErrorBoundaryProps = React.PropsWithChildren<
  WithStyles<typeof styles>
>;

class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryOwnState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, eventId: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    const { children } = this.props;
    const { hasError, eventId } = this.state;

    if (hasError && !!eventId) {
      return <InternalServerError />;
    }
    return children;
  }
}

export default withStyles(styles)(ErrorBoundary);
