import { hasGon } from '@common/lib/gon';
import { createSelector } from 'reselect';
import type { Gon } from '@console/common/window';
import type { AppState } from '@console/reducers';
import type { LocalGifteeBoxTemplates } from '@console/reducers/gifteeBoxTemplates';

export const getGifteeBoxTemplates = (
  state: AppState
): LocalGifteeBoxTemplates => state.gifteeBoxTemplates;

export const getImmediateDeliverableGifteeBoxTemplates = createSelector(
  [getGifteeBoxTemplates],
  (gifteeBoxTemplates): LocalGifteeBoxTemplates => {
    if (hasGon<Gon>(window)) {
      const { eraberuPayGifteeBoxTemplateUrlCode } = window.gon;
      if (eraberuPayGifteeBoxTemplateUrlCode) {
        return gifteeBoxTemplates.filter(
          gifteeBoxTemplate =>
            gifteeBoxTemplate.urlCode !== eraberuPayGifteeBoxTemplateUrlCode
        );
      }
    }
    return gifteeBoxTemplates;
  }
);
