import { AppActionTypes } from '../actions/actionType';
import type { SolutionSystems } from '../../../common/api/graphql/getSolutionSystemsGql';
import type { AppAction } from '../actions/actionType';

const solutionSystems = (state = [], action: AppAction): SolutionSystems => {
  switch (action.type) {
    case AppActionTypes.GET_SOLUTION_SYSTEMS_SUCCESS:
    case AppActionTypes.GET_MASTER_DATA_SUCCESS:
      return action.solutionSystems;
    default:
      return state;
  }
};

export default solutionSystems;
