import { gql } from '@apollo/client';

import { internalApiAppClient } from './apolloClient';
import { operationNames } from './index';
import type { MutationErrors } from './mutationErrorType';
import type { FetchResult } from '@apollo/client';

const updateDistributionPartnerUserGql = gql`
  mutation ${operationNames.UPDATE_DISTRIBUTION_PARTNER_USER}(
    $name: String!
    $division: String!
    $phoneNumber: String!
  ) {
    updateDistributionPartnerUser(
      input: { name: $name, division: $division, phoneNumber: $phoneNumber }
    ) {
      errors {
        __typename
        message
        path
      }
    }
  }
`;

export type UpdateDistributionPartnerUserResponse = FetchResult<{
  updateDistributionPartnerUser: {
    errors: MutationErrors;
  };
}>;

export type UpdateDistributionPartnerUserParams = {
  name: string;
  division: string;
  phoneNumber: string;
};

export const updateDistributionPartnerUser = (
  updateDistributionPartnerUserParams: UpdateDistributionPartnerUserParams
): Promise<UpdateDistributionPartnerUserResponse> =>
  internalApiAppClient.mutate({
    mutation: updateDistributionPartnerUserGql,
    variables: {
      ...updateDistributionPartnerUserParams
    }
  });
