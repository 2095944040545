import { AppActionTypes } from '../../actions/actionType';
import type { AppAction } from '../../actions/actionType';

export type ThirdPartyService = {
  name: string;
  url: string;
  description: string;
};

export const initialStateOfThirdPartyService = {
  name: '',
  url: '',
  description: ''
};

export const thirdPartyService = (
  state = initialStateOfThirdPartyService,
  action: AppAction
): ThirdPartyService => {
  switch (action.type) {
    case AppActionTypes.ADD_PROJECT_DRAFT:
    case AppActionTypes.COPY_PROJECT_TO_PROJECT_DRAFT_FAILURE:
      return initialStateOfThirdPartyService;
    case AppActionTypes.SELECT_PROJECT_DRAFT:
      return action.projectDraft.projectJson.thirdPartyService;
    case AppActionTypes.UPDATE_NAME_OF_THIRD_PARTY_SERVICE:
      return {
        ...state,
        name: action.name
      };
    case AppActionTypes.UPDATE_URL_OF_THIRD_PARTY_SERVICE:
      return {
        ...state,
        url: action.url
      };
    case AppActionTypes.UPDATE_DESCRIPTION_OF_THIRD_PARTY_SERVICE:
      return {
        ...state,
        description: action.description
      };
    case AppActionTypes.COPY_PROJECT_TO_PROJECT_DRAFT:
      return {
        name: action.project.thirdPartyService?.name ?? '',
        url: action.project.thirdPartyService?.url ?? '',
        description: action.project.thirdPartyService?.description ?? ''
      };
    default:
      return state;
  }
};

export default thirdPartyService;
