import { createSelector } from 'reselect';
import type { AppState } from '@console/reducers';
import type { LocalProject } from '@console/reducers/projects';

const getProjectDetailAdvertisingPeriod = (state: AppState) =>
  state.projectDetail.advertisingPeriod;

export const getAdvertisingPeriod = createSelector(
  [getProjectDetailAdvertisingPeriod],
  (advertisingPeriod): LocalProject['advertisingPeriod'] => ({
    beginAt: advertisingPeriod.beginAt ?? '',
    endAt: advertisingPeriod.endAt ?? ''
  })
);
