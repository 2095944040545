import DOMPurify from 'dompurify';
import linkifyHtml from 'linkify-html';

export const getHtmlString = (str: string): string =>
  DOMPurify.sanitize(
    linkifyHtml(str, {
      nl2br: true,
      target: '_blank',
      rel: 'noopener noreferrer'
    }),
    { ADD_ATTR: ['target'] }
  );
