import {
  isFixedGiftConfig,
  isChoosableGiftConfig,
  isGifteeBoxGiftConfig
} from '@console/common/projectDraft/giftConfig';
import ChoosableContentsSelectionContainer from '@console/containers/projectDraft/contentsSelection/ChoosableContentsSelectionContainer';
import FixedContentSelectionContainer from '@console/containers/projectDraft/contentsSelection/FixedContentSelectionContainer';
import GifteeBoxTemplateSelectionContainer from '@console/containers/projectDraft/contentsSelection/GifteeBoxTemplateSelectionContainer';
import { useEffect } from 'react';
import type {
  ContentsSelectionPropsMappedFromDispatch,
  ContentsSelectionPropsMappedFromState
} from '@console/containers/projectDraft/ContentsSelectionContainer';
import type { ReactElement } from 'react';

type ContentsSelectionProps = ContentsSelectionPropsMappedFromState &
  ContentsSelectionPropsMappedFromDispatch;

const ContentsSelection: React.FC<ContentsSelectionProps> = ({
  selectedGiftConfig,
  onMount
}) => {
  useEffect(() => {
    onMount();
  }, []);

  const renderBody = (): ReactElement | null => {
    if (isFixedGiftConfig(selectedGiftConfig)) {
      return <FixedContentSelectionContainer />;
    } else if (isChoosableGiftConfig(selectedGiftConfig)) {
      return <ChoosableContentsSelectionContainer />;
    } else if (isGifteeBoxGiftConfig(selectedGiftConfig)) {
      return <GifteeBoxTemplateSelectionContainer />;
    }

    return null;
  };
  return <>{renderBody()}</>;
};

export default ContentsSelection;
