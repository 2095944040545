import validator from 'validator';
import {
  createErrorMessageForEmpty,
  createErrorMessageForIsNumeric,
  createErrorMessageForIsLength,
  createErrorMessageForUnselected
} from '../../../../../common/middleware/errorMessages';
import { validationSuccess, validationFailure } from '../../../actions/actions';
import {
  JP_BILLING_ADDRESS_NAME,
  JP_BILLING_ADDRESS_DIVISION,
  JP_BILLING_ADDRESS_POSTAL_CODE,
  JP_BILLING_ADDRESS_PREFECTURE,
  JP_BILLING_ADDRESS_CITY,
  JP_BILLING_ADDRESS_ADDRESS
} from '../../../common/constants';
import type { AppThunkAction } from '../../../actions/thunkType';

const DISTRIBUTION_PARTNER_EDIT = 'accountDistributionPartnerEdit';
export const BILLING_ADDRESS_NAME = 'billingAddressName' as const;
export const BILLING_ADDRESS_DIVISION = 'billingAddressDivision' as const;
export const BILLING_ADDRESS_POSTAL_CODE = 'billingAddressPostalCode' as const;
export const BILLING_ADDRESS_PREFECTURE = 'billingAddressPrefecture' as const;
export const BILLING_ADDRESS_CITY = 'billingAddressCity' as const;
export const BILLING_ADDRESS_ADDRESS = 'billingAddressAddress' as const;

const validateBillingAddressName =
  (billingAddressName: string): AppThunkAction<void> =>
  dispatch => {
    let errorMessage: string = '';

    if (validator.isEmpty(billingAddressName)) {
      errorMessage = createErrorMessageForEmpty(JP_BILLING_ADDRESS_NAME);
    }

    dispatch(handleValidation(errorMessage, BILLING_ADDRESS_NAME));
  };

const validateBillingAddressDivision =
  (billingAddressDivision: string): AppThunkAction<void> =>
  dispatch => {
    let errorMessage: string = '';

    if (validator.isEmpty(billingAddressDivision)) {
      errorMessage = createErrorMessageForEmpty(JP_BILLING_ADDRESS_DIVISION);
    }

    dispatch(handleValidation(errorMessage, BILLING_ADDRESS_DIVISION));
  };

const validateBillingAddressPostalCode =
  (billingAddressPostalCode: string): AppThunkAction<void> =>
  dispatch => {
    let errorMessage: string = '';

    if (validator.isEmpty(billingAddressPostalCode)) {
      errorMessage = createErrorMessageForEmpty(JP_BILLING_ADDRESS_POSTAL_CODE);
    } else if (
      !validator.isNumeric(billingAddressPostalCode, { no_symbols: true })
    ) {
      errorMessage = createErrorMessageForIsNumeric(
        JP_BILLING_ADDRESS_POSTAL_CODE
      );
    } else if (!validator.isLength(billingAddressPostalCode, { max: 7 })) {
      errorMessage = createErrorMessageForIsLength(
        JP_BILLING_ADDRESS_POSTAL_CODE,
        7
      );
    }

    dispatch(handleValidation(errorMessage, BILLING_ADDRESS_POSTAL_CODE));
  };

const validateBillingAddressPrefecture =
  (billingAddressPrefectureId: number): AppThunkAction<void> =>
  dispatch => {
    let errorMessage: string = '';

    if (isNaN(billingAddressPrefectureId)) {
      errorMessage = createErrorMessageForUnselected(
        JP_BILLING_ADDRESS_PREFECTURE
      );
    }

    dispatch(handleValidation(errorMessage, BILLING_ADDRESS_PREFECTURE));
  };

const validateBillingAddressCity =
  (billingAddressCity: string): AppThunkAction<void> =>
  dispatch => {
    let errorMessage: string = '';

    if (validator.isEmpty(billingAddressCity)) {
      errorMessage = createErrorMessageForEmpty(JP_BILLING_ADDRESS_CITY);
    }

    dispatch(handleValidation(errorMessage, BILLING_ADDRESS_CITY));
  };

const validateBillingAddressAddress =
  (billingAddressAddress: string): AppThunkAction<void> =>
  dispatch => {
    let errorMessage: string = '';

    if (validator.isEmpty(billingAddressAddress)) {
      errorMessage = createErrorMessageForEmpty(JP_BILLING_ADDRESS_ADDRESS);
    }

    dispatch(handleValidation(errorMessage, BILLING_ADDRESS_ADDRESS));
  };

const handleValidation =
  (errorMessage: string, errorKey: string): AppThunkAction<void> =>
  dispatch => {
    if (errorMessage === '') {
      dispatch(validationSuccess(DISTRIBUTION_PARTNER_EDIT, errorKey));
    } else {
      dispatch(
        validationFailure(DISTRIBUTION_PARTNER_EDIT, {
          [errorKey]: errorMessage
        })
      );
    }
  };

export const accountDistibutionPartnerEditValidators = {
  validateBillingAddressName,
  validateBillingAddressDivision,
  validateBillingAddressPostalCode,
  validateBillingAddressPrefecture,
  validateBillingAddressCity,
  validateBillingAddressAddress
};
