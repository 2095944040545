import {
  getLocaleDateString,
  getLocaleEndDateString
} from '@common/lib/getLocaleDate';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import { Grid, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Link, useParams } from 'react-router-dom';
import { LocalProject } from '../../reducers/projects';
import ProjectItem from './ProjectItem';
import type {
  MessageThreadSideContentsPropsMappedFromState,
  MessageThreadSideContentsPropsMappedFromDispatch
} from '../../containers/project/MessageThreadSideContentsContainer';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => ({
  projectDetailViewportGrid: {
    marginRight: theme.spacing(1),
    paddingRight: theme.spacing(1),
    height: '100%',
    borderRight: `solid thin ${theme.palette.divider}`
  },
  subContentTitle: {
    margin: `${theme.spacing(2)} 0`
  },
  projectItems: {
    height: `calc(90% - ${theme.spacing(4)})`,
    overflow: 'scroll'
  },
  link: {
    textDecoration: 'none',
    display: 'block',
    marginTop: theme.spacing(2)
  },
  icon: {
    fill: theme.palette.primary.main,
    verticalAlign: 'middle'
  },
  linkLabel: {
    verticalAlign: 'middle'
  }
});

type PathParams = {
  urlCode?: string;
};

type MessageThreadSideContentsProps =
  MessageThreadSideContentsPropsMappedFromState &
    MessageThreadSideContentsPropsMappedFromDispatch &
    WithStyles<typeof styles>;

const MessageThreadSideContents: React.FC<MessageThreadSideContentsProps> = ({
  project,
  projectGiftDistributionChannelsForDisplay,
  projectAdvertisingMediumRegistrationsForDisplay,
  classes
}) => {
  const params = useParams<PathParams>();
  return (
    <Grid item xs className={classes.projectDetailViewportGrid}>
      <Link
        to={`/console/projects/${params.urlCode}/detail`}
        className={classes.link}
      >
        <KeyboardArrowLeft className={classes.icon} />
        <Typography
          variant="body2"
          color="primary"
          display="inline"
          className={classes.linkLabel}
        >
          お申し込み情報に戻る
        </Typography>
      </Link>
      <Typography variant="subtitle2" className={classes.subContentTitle}>
        キャンペーン情報
      </Typography>
      <div className={classes.projectItems}>
        <ProjectItem label="利用サービス" value={project.solutionSystem.name} />
        <ProjectItem label="キャンペーン概要" value={project.description} />
        <ProjectItem
          label="キャンペーン実施業界"
          value={project.marketingIndustry.name}
        />
        {project.advertisingPeriod ? (
          <ProjectItem
            label="掲載期間"
            value={`${getLocaleDateString(
              project.advertisingPeriod.beginAt
            )} ~ ${getLocaleDateString(project.advertisingPeriod.endAt)}`}
          />
        ) : null}
        {project.giftDistributionPeriod ? (
          <ProjectItem
            label="ギフト配布期間"
            value={`${getLocaleDateString(
              project.giftDistributionPeriod.beginAt
            )} ~ ${getLocaleEndDateString(
              project.giftDistributionPeriod.endAt
            )}`}
          />
        ) : null}

        <ProjectItem
          label="キャンペーン対象のサービス名"
          value={project.thirdPartyService?.name ?? ''}
        />
        <ProjectItem
          label="キャンペーン対象のサービスサイトURL"
          value={project.thirdPartyService?.url ?? ''}
        />
        <ProjectItem
          label="露出媒体"
          value={projectAdvertisingMediumRegistrationsForDisplay}
        />
        <ProjectItem
          label="ギフト配布方法"
          value={projectGiftDistributionChannelsForDisplay}
        />
      </div>
    </Grid>
  );
};

export default withStyles(styles)(MessageThreadSideContents);
