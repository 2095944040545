import moment from '@common/lib/moment';
import {
  isFixedGiftConfig,
  isGifteeBoxGiftConfig
} from '@console/common/projectDraft/giftConfig';
import type {
  MutationMessageCardConfig,
  MutationGiftCardTicketConfig,
  MutationFixedGiftCardTicketConfig,
  MutationChoosableGiftCardTicketConfig,
  MutationChoices,
  MutationBulkGiftCardConfig,
  MutationBulkGiftCardIssueConfigs,
  MutationApiGiftCardConfig,
  MutationApiGiftCardIssueConfigs
} from '@common/api/graphql/createProjectGql';
import type {
  GiftConfigs,
  GiftConfig,
  FixedGiftConfig,
  ChoosableGiftConfig,
  GiftCardGiftConfig
} from '@console/reducers/projectJson/giftConfigs';
import type { GiftDistributionPeriod } from '@console/reducers/projectJson/giftDistributionPeriod';

const getGiftCardGiftConfigs = (giftConfigs: GiftConfigs) =>
  giftConfigs.filter(
    (giftConfig: GiftConfig): giftConfig is GiftCardGiftConfig =>
      !isGifteeBoxGiftConfig(giftConfig)
  );

export const getGiftCardConfigsForBulkProject = (
  giftConfigs: GiftConfigs
): MutationBulkGiftCardConfig[] | null => {
  const giftCardGiftConfigs = getGiftCardGiftConfigs(giftConfigs).map(
    giftConfig => {
      const messageCardConfig = getMessageCardConfig(giftConfig);

      return {
        messageCardConfig,
        giftCardTicketConfigs: getGiftCardTicketConfigs(giftConfig),
        bulkGiftCardIssueConfigs: getBulkGiftCardIssueConfigs(giftConfig),
        apiGiftCardIssueConfigs: null
      };
    }
  );

  return giftCardGiftConfigs.length === 0 ? null : giftCardGiftConfigs;
};

export const getGiftCardConfigsForApiProject = (
  giftDistributionPeriod: GiftDistributionPeriod,
  giftConfigs: GiftConfigs
): MutationApiGiftCardConfig[] =>
  getGiftCardGiftConfigs(giftConfigs).map(giftConfig => {
    const messageCardConfig = getMessageCardConfig(giftConfig);

    return {
      messageCardConfig,
      giftCardTicketConfigs: getGiftCardTicketConfigs(giftConfig),
      bulkGiftCardIssueConfigs: null,
      apiGiftCardIssueConfigs: getApiGiftCardIssueConfigs(
        giftDistributionPeriod,
        giftConfig
      )
    };
  });

const getMessageCardConfig = (
  giftConfig: GiftConfig
): MutationMessageCardConfig => {
  if (!giftConfig.messageCardUsing || !hasMessageCardConfig(giftConfig)) {
    return null;
  }

  const messageCardConfig: MutationMessageCardConfig = {
    senderName: giftConfig.senderName,
    description: giftConfig.message
  };

  if (giftConfig.selectedMainVisual !== '') {
    messageCardConfig.mainVisualUrlCode = giftConfig.selectedMainVisual;
  }

  return messageCardConfig;
};

const hasMessageCardConfig = (giftConfig: GiftConfig): boolean =>
  giftConfig.senderName !== '' ||
  giftConfig.message !== '' ||
  giftConfig.selectedMainVisual !== '';

// TODO: 綴りの対応
const getGiftCardTicketConfigs = (
  giftConfig: GiftCardGiftConfig
): MutationGiftCardTicketConfig[] => [
  isFixedGiftConfig(giftConfig)
    ? getFixedGiftCardTicketConfig(giftConfig)
    : getChoosableGiftCardTicketConfig(giftConfig)
];

const getFixedGiftCardTicketConfig = (
  giftConfig: FixedGiftConfig
): MutationFixedGiftCardTicketConfig => ({
  fixedContentOption: {
    urlCode: giftConfig.selectedContents[0] || ''
  },
  choosableContentOption: null
});

const getChoosableGiftCardTicketConfig = (
  giftConfig: ChoosableGiftConfig
): MutationChoosableGiftCardTicketConfig => {
  const choices: MutationChoices = giftConfig.selectedContents.map(
    selectedContent => ({ urlCode: selectedContent })
  );

  return {
    fixedContentOption: null,
    choosableContentOption: {
      choices
    }
  };
};

const getBulkGiftCardIssueConfigs = (
  giftConfig: GiftConfig
): NonNullable<MutationBulkGiftCardIssueConfigs> => [
  {
    desiredDeliveryDate: giftConfig.deliveryDate,
    requestedIssueAmount: Number(giftConfig.issueAmount)
  }
];

const getApiGiftCardIssueConfigs = (
  giftDistributionPeriod: GiftDistributionPeriod,
  giftConfig: GiftConfig
): NonNullable<MutationApiGiftCardIssueConfigs> => [
  {
    issuableBeginAt: moment().toISOString(),
    issuableEndAt: giftDistributionPeriod.endDateOfDistribution
      ? moment(giftDistributionPeriod.endDateOfDistribution)
          .add(1, 'd')
          .toISOString(true)
      : '',
    estimatedIssueAmount: Number(giftConfig.issueAmount)
  }
];
