import { ERROR_MESSAGE_FOR_EXCEED_CREDIT_LIMIT_IN_ADDING_ISSUE_CONFIGS } from '@common/middleware/errorMessages';
import {
  validateProjectAddingBulkGiftCardIssueConfigsSuccess,
  validateProjectAddingBulkGiftCardIssueConfigsFailure,
  validateProjectAddingBulkGiftCardIssueConfigsTotalSuccess,
  validateProjectAddingBulkGiftCardIssueConfigsTotalFailure
} from '@console/actions/actions';
import {
  getRequestedIssueAmountValidationErrorMessage,
  getDeliveryDateValidationErrorMessage
} from '@console/middleware/validator/project/validations';
import { PROJECT_ADDING_BULK_GIFT_CARD_ISSUE_CONFIG_ERROR_KEYS } from '@console/reducers/errors';
import { getCreditLimitBalance } from '@console/selectors/distributionPartner';
import { getAddingBulkGiftCardIssueConfigsTotal } from '@console/selectors/edit/project/giftCardConfigs/addingBulkGiftCardIssueConfigs';
import type { AppThunkAction } from '@console/actions/thunkType';
import type { AddingBulkGiftCardIssueConfig } from '@console/reducers/edit/project/giftCardConfigs/addingBulkGiftCardIssueConfigs';
import type { ErrorMessage } from '@console/reducers/errors';
import type { Moment } from 'moment';

const validateAddingBulkGiftCardIssueConfigs =
  (): AppThunkAction<void> => (dispatch, getState) => {
    const { addingBulkGiftCardIssueConfigs } =
      getState().edits.project.giftCardConfigs;

    addingBulkGiftCardIssueConfigs.forEach(
      (addingBulkGiftCardIssueConfig, index) =>
        dispatch(
          validateAddingBulkGiftCardIssueConfig(
            index,
            addingBulkGiftCardIssueConfig
          )
        )
    );

    dispatch(validateAddingBulkGiftCardIssueConfigsTotal());
  };

const validateAddingBulkGiftCardIssueConfig =
  (
    addingBulkGiftCardIssueConfigsIndex: number,
    addingBulkGiftCardIssueConfig: AddingBulkGiftCardIssueConfig
  ): AppThunkAction<void> =>
  dispatch => {
    dispatch(
      validateRequestedIssueAmount(
        addingBulkGiftCardIssueConfigsIndex,
        addingBulkGiftCardIssueConfig.requestedIssueAmount
      )
    );
    dispatch(
      validateDesiredDeliveryDate(
        addingBulkGiftCardIssueConfigsIndex,
        addingBulkGiftCardIssueConfig.desiredDeliveryDate
      )
    );
  };

const validateRequestedIssueAmount =
  (
    addingBulkGiftCardIssueConfigsIndex: number,
    requestedIssueAmount:
      | string
      | AddingBulkGiftCardIssueConfig['requestedIssueAmount']
  ): AppThunkAction<void> =>
  dispatch => {
    const errorMessage: ErrorMessage =
      getRequestedIssueAmountValidationErrorMessage(requestedIssueAmount);

    if (errorMessage === '') {
      dispatch(
        validateProjectAddingBulkGiftCardIssueConfigsSuccess(
          PROJECT_ADDING_BULK_GIFT_CARD_ISSUE_CONFIG_ERROR_KEYS.REQUESTED_ISSUE_AMOUNT,
          addingBulkGiftCardIssueConfigsIndex
        )
      );
    } else {
      dispatch(
        validateProjectAddingBulkGiftCardIssueConfigsFailure(
          PROJECT_ADDING_BULK_GIFT_CARD_ISSUE_CONFIG_ERROR_KEYS.REQUESTED_ISSUE_AMOUNT,
          addingBulkGiftCardIssueConfigsIndex,
          errorMessage
        )
      );
    }
  };

const validateDesiredDeliveryDate =
  (
    addingBulkGiftCardIssueConfigsIndex: number,
    desiredDeliveryDate:
      | Moment
      | null
      | AddingBulkGiftCardIssueConfig['desiredDeliveryDate']
  ): AppThunkAction<void> =>
  dispatch => {
    const errorMessage: ErrorMessage =
      getDeliveryDateValidationErrorMessage(desiredDeliveryDate);

    if (errorMessage === '') {
      dispatch(
        validateProjectAddingBulkGiftCardIssueConfigsSuccess(
          PROJECT_ADDING_BULK_GIFT_CARD_ISSUE_CONFIG_ERROR_KEYS.DESIRED_DELIVERY_DATE,
          addingBulkGiftCardIssueConfigsIndex
        )
      );
    } else {
      dispatch(
        validateProjectAddingBulkGiftCardIssueConfigsFailure(
          PROJECT_ADDING_BULK_GIFT_CARD_ISSUE_CONFIG_ERROR_KEYS.DESIRED_DELIVERY_DATE,
          addingBulkGiftCardIssueConfigsIndex,
          errorMessage
        )
      );
    }
  };

const validateAddingBulkGiftCardIssueConfigsTotal =
  (): AppThunkAction<void> => (dispatch, getState) => {
    const state = getState();
    const creditLimitBalance = getCreditLimitBalance(state);
    const total = getAddingBulkGiftCardIssueConfigsTotal(state);
    if (typeof creditLimitBalance === 'number' && creditLimitBalance < total) {
      const errorMessage =
        ERROR_MESSAGE_FOR_EXCEED_CREDIT_LIMIT_IN_ADDING_ISSUE_CONFIGS;
      dispatch(
        validateProjectAddingBulkGiftCardIssueConfigsTotalFailure(errorMessage)
      );
    } else {
      dispatch(validateProjectAddingBulkGiftCardIssueConfigsTotalSuccess());
    }
  };

export const projectBulkGiftCardIssueConfigsValidator = {
  validateAddingBulkGiftCardIssueConfigs,
  validateRequestedIssueAmount,
  validateDesiredDeliveryDate
};
