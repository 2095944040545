import StepHandlerContainer from '@console/containers/projectDraft/StepHandlerContainer';
import ApiTableContainer from '@console/containers/projectDraft/deliveryConfig/ApiTableContainer';
import BulkTableContainer from '@console/containers/projectDraft/deliveryConfig/BulkTableContainer';
import Grid from '@mui/material/Grid';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { useNavigate } from 'react-router-dom';
import SectionTitle from '../../projectDraft/common/SectionTitle';
import Empty from './Empty';
import type {
  GiftCardDeliveryConfigPropsMappedFromState,
  GiftCardDeliveryConfigPropsMappedFromDispatch
} from '@console/containers/projectDraft/deliveryConfig/GiftCardDeliveryConfigContainer';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';
import type { ReactElement } from 'react';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    title: {
      marginTop: theme.spacing(3)
    }
  });

type GiftCardDeliveryConfigProps = GiftCardDeliveryConfigPropsMappedFromState &
  GiftCardDeliveryConfigPropsMappedFromDispatch &
  WithStyles<typeof styles>;

const GiftCardDeliveryConfig: React.FC<GiftCardDeliveryConfigProps> = ({
  giftConfig,
  isBulkSolutionSystemSelected,
  isApiSolutionSystemSelected,
  isGcpSolutionSystemSelected,
  handleStepNextFromParent,
  classes
}) => {
  const navigate = useNavigate();

  const renderSectionTitle = (): ReactElement => {
    if (isBulkSolutionSystemSelected) {
      return (
        <SectionTitle text="数量（見込み）と希望納品日を選択してください" />
      );
    }
    if (isApiSolutionSystemSelected || isGcpSolutionSystemSelected) {
      return <SectionTitle text="数量（見込み）を入力してください" />;
    }
    return <></>;
  };

  const renderBody = (): ReactElement => {
    if (giftConfig.selectedContents.length === 0) {
      return <Empty />;
    }
    if (isBulkSolutionSystemSelected) {
      return renderBulkContent();
    }
    if (isApiSolutionSystemSelected || isGcpSolutionSystemSelected) {
      return renderApiContent();
    }
    return <></>;
  };

  const renderBulkContent = (): ReactElement => <BulkTableContainer />;

  const renderApiContent = (): ReactElement => <ApiTableContainer />;

  return (
    <>
      <Grid
        container
        item
        xs={12}
        className={classes.title}
        data-cy="giftCardDeliveryConfigSectionTitle"
      >
        {renderSectionTitle()}
      </Grid>
      {renderBody()}
      <StepHandlerContainer
        handleStepNextFromParent={() => handleStepNextFromParent(navigate)}
      />
    </>
  );
};

export default withStyles(styles)(GiftCardDeliveryConfig);
