import { searchPostcodes } from '../../../../common/api/graphql/searchPostcodesGql';
import {
  searchPostcodesStart,
  searchPostcodesSuccess,
  searchPostcodesFailure
} from '../actions';
import handleApiErrorIfNeeded from './handleApiErrorIfNeeded';
import type { ApiError } from '../../../../common/api/graphql/apolloClient';
import type { SearchPostcodesResponse } from '../../../../common/api/graphql/searchPostcodesGql';
import type { AppThunkAction } from '../thunkType';

export const searchPostcodesSuccessThunk =
  (response: SearchPostcodesResponse): AppThunkAction<Promise<void>> =>
  (dispatch, getState) => {
    const addressData = response.data!.searchPostcodes.data[0];
    const { city, town, postcode } = addressData;
    const prefectureName = addressData.pref;

    const prefecture = getState().prefectures.find(
      prefecture => prefecture.name === prefectureName
    );
    dispatch(searchPostcodesSuccess(prefecture!.id, city, town, postcode));
    return Promise.resolve();
  };

export const searchPostcodesFailureThunk =
  (error: ApiError): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(searchPostcodesFailure());
    return dispatch(handleApiErrorIfNeeded(error));
  };

export const searchPostcodesThunk =
  (): AppThunkAction<Promise<void>> => (dispatch, getState) => {
    dispatch(searchPostcodesStart());
    const { postalCode } =
      getState().edits.distributionPartner.billingAddresses[0];

    return searchPostcodes(postalCode)
      .then((response: SearchPostcodesResponse) =>
        dispatch(searchPostcodesSuccessThunk(response))
      )
      .catch(error => dispatch(searchPostcodesFailureThunk(error)));
  };
