import { getLocalString } from '@common/lib/getLocalString';
import { getLocalePrice } from '@common/lib/getLocalePrice';
import moment from '@common/lib/moment';
import GifteeBoxInitialPointInput from '@console/components/common/GifteeBoxInitialPointInput';
import IssueAmountRequestModal from '@console/components/project/giftConfig/bulkTable/IssueAmountRequestModal';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TextField,
  IconButton,
  Button,
  Grid,
  FormControl,
  Typography
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { DesktopDatePicker as DatePicker } from '@mui/x-date-pickers';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import type {
  AddingBulkGifteeBoxIssueConfigsTablePropsMappedFromState,
  AddingBulkGifteeBoxIssueConfigsTablePropsMappedFromDispatch
} from '@console/containers/project/gifteeBoxConfigs/bulkGifteeBoxIssueConfigs/new/AddingBulkGifteeBoxIssueConfigsTableContainer';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => ({
  table: {
    borderBottom: 'hidden',
    marginBottom: theme.spacing(4)
  },
  tableCell: {
    paddingRight: theme.spacing(4),
    verticalAlign: 'middle',
    maxWidth: 230
  },
  gifteeBoxPrice: {
    minWidth: 150
  },
  addingBulkGifteeBoxIssueConfigAddButton: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing()
  },
  backButton: {
    marginRight: theme.spacing(4)
  }
});

type AddingBulkGifteeBoxIssueConfigsTableProps =
  AddingBulkGifteeBoxIssueConfigsTablePropsMappedFromState &
    AddingBulkGifteeBoxIssueConfigsTablePropsMappedFromDispatch &
    WithStyles<typeof styles>;

const AddingBulkGifteeBoxIssueConfigsTable: React.FC<
  AddingBulkGifteeBoxIssueConfigsTableProps
> = ({
  gifteeBoxConfig,
  initialPointCandidates,
  selectedGifteeBoxTemplate,
  addingBulkGifteeBoxIssueAmountTotal,
  addingBulkGifteeBoxIssueConfigs,
  claimEstimate,
  addingBulkGifteeBoxIssueConfigsTotal,
  projectAddingBulkGifteeBoxIssueConfigsErrors,
  hasValidationError,
  handleAddBulkGifteeBoxIssueConfigsButtonClick,
  handleBackButtonClick,
  handleAddingBulkGifteeBoxIssueConfigInitialPointChange,
  handleAddingBulkGifteeBoxIssueConfigInitialPointInputChange,
  handleAddingBulkGifteeBoxIssueConfigRequestedIssueAmountChange,
  handleAddingBulkGifteeBoxIssueConfigRequestedIssueAmountBlur,
  handleAddingBulkGifteeBoxIssueConfigDeliveryDateChange,
  handleAddAddingBulkGifteeBoxIssueConfigButtonClick,
  handleRemoveAddingBulkGifteeBoxIssueConfigButtonClick,
  handleValidateProjectAddingBulkGifteeBoxIssueConfigs,
  classes
}) => {
  const navigate = useNavigate();
  const [
    bulkGifteeBoxIssueConfigModalOpen,
    setBulkGifteeBoxIssueConfigModalOpen
  ] = useState<boolean>(false);

  const handleBulkGifteeBoxIssueConfigClick = () => {
    handleValidateProjectAddingBulkGifteeBoxIssueConfigs()
      .then(() =>
        setBulkGifteeBoxIssueConfigModalOpen(!bulkGifteeBoxIssueConfigModalOpen)
      )
      .catch(() => {});
  };

  const handleGifteeBoxModalOKClick = () => {
    handleAddBulkGifteeBoxIssueConfigsButtonClick(navigate);
    setBulkGifteeBoxIssueConfigModalOpen(!bulkGifteeBoxIssueConfigModalOpen);
  };

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        handleBulkGifteeBoxIssueConfigClick();
      }}
    >
      <Table
        className={classes.table}
        data-cy="addingBulkGifteeBoxIssueConfigsTable"
      >
        <TableHead data-cy="addingBulkGifteeBoxIssueConfigsTableHeader">
          <TableRow>
            <TableCell className={classes.tableCell} align="left">
              単価
            </TableCell>
            <TableCell className={classes.tableCell} align="left">
              数量（見込み）
            </TableCell>
            <TableCell className={classes.tableCell} align="left">
              希望納品日
            </TableCell>
            <TableCell className={classes.tableCell} align="left" />
          </TableRow>
        </TableHead>
        <TableBody data-cy="addingBulkGifteeBoxIssueConfigsTableBody">
          {addingBulkGifteeBoxIssueConfigs.map(
            (addingBulkGifteeBoxIssueConfig, index) => (
              <TableRow
                key={index}
                data-cy={`addingBulkGifteeBoxIssueConfigRow-${index}`}
              >
                <TableCell className={classes.tableCell} align="left">
                  <FormControl
                    variant="standard"
                    required
                    className={classes.gifteeBoxPrice}
                  >
                    <GifteeBoxInitialPointInput
                      initialPoint={
                        addingBulkGifteeBoxIssueConfig.initialPoint ?? ''
                      }
                      initialPointCandidates={initialPointCandidates}
                      maxPoint={
                        selectedGifteeBoxTemplate?.supportedPoint.maxPoint
                      }
                      minPoint={
                        selectedGifteeBoxTemplate?.supportedPoint.minPoint
                      }
                      step={selectedGifteeBoxTemplate?.supportedPoint.step}
                      isError={
                        !!projectAddingBulkGifteeBoxIssueConfigsErrors[index]
                          ?.intialPoint
                      }
                      onInputChange={value =>
                        handleAddingBulkGifteeBoxIssueConfigInitialPointInputChange(
                          value,
                          index
                        )
                      }
                      onChange={value =>
                        handleAddingBulkGifteeBoxIssueConfigInitialPointChange(
                          value,
                          index
                        )
                      }
                    />
                    <Typography className={classes.errorMessage} color="error">
                      {
                        projectAddingBulkGifteeBoxIssueConfigsErrors[index]
                          ?.intialPoint
                      }
                    </Typography>
                  </FormControl>
                </TableCell>
                <TableCell className={classes.tableCell} align="left">
                  <TextField
                    variant="standard"
                    id="requestedIssueAmount"
                    name="requestedIssueAmount"
                    value={
                      addingBulkGifteeBoxIssueConfig.requestedIssueAmount || ''
                    }
                    required
                    type="number"
                    onChange={event =>
                      handleAddingBulkGifteeBoxIssueConfigRequestedIssueAmountChange(
                        event,
                        index
                      )
                    }
                    onBlur={
                      handleAddingBulkGifteeBoxIssueConfigRequestedIssueAmountBlur
                    }
                    inputProps={{ min: '1' }}
                    error={
                      !!projectAddingBulkGifteeBoxIssueConfigsErrors[index]
                        ?.requestedIssueAmount
                    }
                    data-cy={`addingBulkGifteeBoxIssueConfigRequestedIssueAmount-${index}`}
                  />
                  <Typography className={classes.errorMessage} color="error">
                    {
                      projectAddingBulkGifteeBoxIssueConfigsErrors[index]
                        ?.requestedIssueAmount
                    }
                  </Typography>
                </TableCell>
                <TableCell className={classes.tableCell} align="left">
                  <DatePicker
                    value={moment(addingBulkGifteeBoxIssueConfig.deliveryDate)}
                    minDate={moment().add(1, 'd')}
                    maxDate={moment().add(1, 'y')}
                    onChange={deliveryDate =>
                      handleAddingBulkGifteeBoxIssueConfigDeliveryDateChange(
                        deliveryDate,
                        index
                      )
                    }
                    data-cy={`addingBulkGifteeBoxIssueConfigDeliveryDate-${index}`}
                    slotProps={{
                      textField: {
                        variant: 'standard',
                        id: 'deliveryDate',
                        required: true,
                        error:
                          !!projectAddingBulkGifteeBoxIssueConfigsErrors[index]
                            ?.deliveryDate
                      }
                    }}
                  />
                  <Typography className={classes.errorMessage} color="error">
                    {
                      projectAddingBulkGifteeBoxIssueConfigsErrors[index]
                        ?.deliveryDate
                    }
                  </Typography>
                </TableCell>
                <TableCell className={classes.tableCell} align="left">
                  <IconButton
                    onClick={() =>
                      handleRemoveAddingBulkGifteeBoxIssueConfigButtonClick(
                        index
                      )
                    }
                    data-cy={`addingBulkGifteeBoxIssueConfigDeleteButton-${index}`}
                    size="large"
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            )
          )}
          <TableRow>
            <TableCell className={classes.tableCell} align="left" colSpan={4}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleAddAddingBulkGifteeBoxIssueConfigButtonClick}
                startIcon={<AddIcon fontSize="small" />}
                size="large"
                data-cy="addingBulkGifteeBoxIssueConfigAddButton"
                className={classes.addingBulkGifteeBoxIssueConfigAddButton}
              >
                発注設定を追加
              </Button>
            </TableCell>
          </TableRow>
          <TableRow data-cy="addingBulkGifteeBoxIssueConfigsTotal">
            <TableCell rowSpan={4} colSpan={2} />
            <TableCell align="right">商品代金合計</TableCell>
            <TableCell align="right">
              {getLocalePrice(addingBulkGifteeBoxIssueConfigsTotal)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Grid container justifyContent="flex-end">
        <Button
          onClick={() => handleBackButtonClick(navigate)}
          color="primary"
          size="large"
          startIcon={<KeyboardArrowLeftIcon />}
          className={classes.backButton}
        >
          戻る
        </Button>
        <Button
          type="submit"
          variant="contained"
          size="large"
          color="primary"
          disabled={
            addingBulkGifteeBoxIssueConfigs.length === 0 || hasValidationError
          }
        >
          追加発注する
        </Button>
      </Grid>
      <IssueAmountRequestModal
        open={bulkGifteeBoxIssueConfigModalOpen}
        toggleModalOpen={() =>
          setBulkGifteeBoxIssueConfigModalOpen(
            !bulkGifteeBoxIssueConfigModalOpen
          )
        }
        gifteeBoxConfig={gifteeBoxConfig}
        handleClick={handleGifteeBoxModalOKClick}
        issueAmount={getLocalString(addingBulkGifteeBoxIssueAmountTotal)}
        currentPriceTotal={getLocalePrice(addingBulkGifteeBoxIssueConfigsTotal)}
        addingBulkGifteeBoxIssueConfigs={addingBulkGifteeBoxIssueConfigs}
      />
    </form>
  );
};

export default withStyles(styles)(AddingBulkGifteeBoxIssueConfigsTable);
