import ImageDownloadButton from '@console/components/common/contentBrief/ImageDownloadButton';
import { Typography, Grid, Button, Chip } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => {
  const IMAGE_WIDTH = 72;
  const IMAGE_HEIGHT = 72;
  return {
    contentImageGrid: {
      width: 'auto',
      marginRight: theme.spacing(2)
    },
    contentImage: {
      width: IMAGE_WIDTH,
      height: IMAGE_HEIGHT,
      objectFit: 'contain',
      verticalAlign: 'middle'
    },
    chip: {
      marginBottom: theme.spacing(0.5),
      verticalAlign: 'text-bottom'
    }
  };
};

type ContentBriefOwnProps = {
  contentImageUrl: string;
  contentName: string;
  contentInternalName?: string;
  contentBrandName: string;
  selectablePeriodText?: string;
  contentAvailablePeriodText?: string;
  imageDownloadUrl?: string;
  inquiryCodeText?: string;
  isLimitedPublished?: boolean;
};

type ContentBriefProps = ContentBriefOwnProps & WithStyles<typeof styles>;

const ContentBrief: React.FC<ContentBriefProps> = ({
  classes,
  contentImageUrl,
  contentName,
  contentInternalName,
  contentBrandName,
  selectablePeriodText,
  contentAvailablePeriodText,
  imageDownloadUrl,
  inquiryCodeText,
  isLimitedPublished
}) => (
  <Grid
    container
    direction="row"
    alignItems="center"
    wrap="nowrap"
    data-cy={`contentBrief-${contentName}`}
  >
    <Grid
      container
      className={classes.contentImageGrid}
      alignItems="center"
      justifyContent="center"
      direction="column"
      wrap="nowrap"
    >
      <img
        className={classes.contentImage}
        src={contentImageUrl}
        data-cy={`contentImage-${contentName}`}
        alt={contentName}
      />
      {!!imageDownloadUrl ? (
        <ImageDownloadButton
          contentName={contentName}
          contentBrandName={contentBrandName}
          imageDownloadUrl={imageDownloadUrl}
        />
      ) : null}
    </Grid>
    <div>
      {isLimitedPublished && (
        <Chip
          label="貴社専用"
          color="primary"
          variant="outlined"
          size="small"
          className={classes.chip}
        />
      )}
      <Typography variant="body2" data-cy={`contentName-${contentName}`}>
        {contentInternalName ? contentInternalName : contentName}
      </Typography>
      <Typography variant="caption" data-cy={`contentBrandName-${contentName}`}>
        {contentBrandName}
      </Typography>
      {!!selectablePeriodText && (
        <Typography
          variant="caption"
          display="block"
          data-cy={`selectablePeriod-${contentName}`}
        >
          {selectablePeriodText}
        </Typography>
      )}
      {!!contentAvailablePeriodText ? (
        <Typography
          variant="caption"
          display="block"
          data-cy={`contentAvailablePeriod-${contentName}`}
        >
          {contentAvailablePeriodText}
        </Typography>
      ) : null}
      {!!inquiryCodeText ? (
        <Typography
          variant="caption"
          display="block"
          data-cy={`inquiryCode-${contentName}`}
        >
          {inquiryCodeText}
        </Typography>
      ) : null}
    </div>
  </Grid>
);

export default withStyles(styles)(ContentBrief);
