import { isIE11 } from '@common/lib/userAgent';
import HighlightOff from '@mui/icons-material/HighlightOff';
import InsertDriveFile from '@mui/icons-material/InsertDriveFile';
import { ImageListItemBar, IconButton } from '@mui/material';
import { grey } from '@mui/material/colors';
import withStyles from '@mui/styles/withStyles';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => ({
  pdfFilePreview: {
    height: '100%',
    left: '50%',
    position: 'relative',
    transform: 'translateX(-50%)'
  },
  defaultFilePreview: {
    position: 'absolute',
    width: '40%',
    height: '40%',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto'
  },
  deleteIcon: {
    margin: theme.spacing(1)
  }
});

type PdfFilePreviewOwnProps = {
  attachedFile: File;
  removeAttachedFile;
};

type PdfFilePreviewProps = PdfFilePreviewOwnProps & WithStyles<typeof styles>;

const PdfFilePreview: React.FC<PdfFilePreviewProps> = ({
  classes,
  attachedFile,
  removeAttachedFile
}) => {
  const preview = isIE11 ? (
    <InsertDriveFile className={classes.defaultFilePreview} />
  ) : (
    <object
      className={classes.pdfFilePreview}
      data={URL.createObjectURL(attachedFile)}
    />
  );
  return (
    <>
      {preview}
      <ImageListItemBar
        actionIcon={
          <IconButton
            className={classes.deleteIcon}
            onClick={removeAttachedFile}
            data-cy="removeAttachedFile"
            size="large"
          >
            <HighlightOff style={{ color: grey[50] }} />
          </IconButton>
        }
        title={attachedFile.name}
        position="top"
      ></ImageListItemBar>
    </>
  );
};

export default withStyles(styles)(PdfFilePreview);
