import { AppActionTypes } from '@console/actions/actionType';
import { combineReducers } from 'redux';
import type { AppAction } from '@console/actions/actionType';
import type { LocalProject } from '@console/reducers/projects';
import type { Reducer } from 'react';

const name: Reducer<
  LocalProject['solutionSystem']['name'] | null,
  AppAction
> = (state = null, action) => {
  switch (action.type) {
    case AppActionTypes.GET_PROJECT_DETAIL_SUCCESS:
      return action.project.solutionSystem.name;
    default:
      return state;
  }
};

const solutionSystemIdentifier: Reducer<
  LocalProject['solutionSystem']['solutionSystemIdentifier'] | null,
  AppAction
> = (state = null, action) => {
  switch (action.type) {
    case AppActionTypes.GET_PROJECT_DETAIL_SUCCESS:
      return action.project.solutionSystem.solutionSystemIdentifier;
    default:
      return state;
  }
};

const urlCode: Reducer<
  LocalProject['solutionSystem']['urlCode'] | null,
  AppAction
> = (state = null, action) => {
  switch (action.type) {
    case AppActionTypes.GET_PROJECT_DETAIL_SUCCESS:
      return action.project.solutionSystem.urlCode;
    default:
      return state;
  }
};

export const solutionSystem = combineReducers({
  name,
  solutionSystemIdentifier,
  urlCode
});
