import {
  API_CONFIDENTIAL_INFORMATION,
  BULK_CONFIDENTIAL_INFORMATION,
  NULL_CONFIDENTIAL_INFORMATION
} from '@common/api/graphql/getProjectsGql';
import type {
  LocalConfidentialInformation,
  LocalNullConfidentialInformation,
  LocalBulkConfidentialInformation,
  LocalAPIConfidentialInformation
} from '@console/reducers/projects';

export const isAPIConfidentialInformation = (
  confidentialInformation: LocalConfidentialInformation
): confidentialInformation is LocalAPIConfidentialInformation =>
  confidentialInformation.__typename === API_CONFIDENTIAL_INFORMATION;

export const isBulkConfidentialInformation = (
  confidentialInformation: LocalConfidentialInformation
): confidentialInformation is LocalBulkConfidentialInformation =>
  confidentialInformation.__typename === BULK_CONFIDENTIAL_INFORMATION;

export const isNullConfidentialInformation = (
  confidentialInformation: LocalConfidentialInformation
): confidentialInformation is LocalNullConfidentialInformation =>
  confidentialInformation.__typename === NULL_CONFIDENTIAL_INFORMATION;
