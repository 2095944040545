import { TICKET_TYPE } from '@console/reducers/projectJson/giftConfigs';
import type {
  GiftConfig,
  FixedGiftConfig,
  ChoosableGiftConfig,
  GiftCardGiftConfig,
  GifteeBoxGiftConfig,
  TicketType
} from '@console/reducers/projectJson/giftConfigs';
import type {
  GiftConfigForDisplay,
  FixedGiftConfigForDisplay,
  ChoosableGiftConfigForDisplay,
  GifteeBoxGiftConfigForDisplay
} from '@console/selectors/getSelectedProjectDraftForDisplay';

export const isFixedGiftConfig = (
  giftConfig: GiftConfig | undefined
): giftConfig is FixedGiftConfig =>
  giftConfig?.ticketType === TICKET_TYPE.FIXED || false;

export const isChoosableGiftConfig = (
  giftConfig: GiftConfig | undefined
): giftConfig is ChoosableGiftConfig =>
  giftConfig?.ticketType === TICKET_TYPE.CHOOSABLE || false;

export const isGiftCardGiftConfig = (
  giftConfig: GiftConfig | undefined
): giftConfig is GiftCardGiftConfig =>
  isFixedGiftConfig(giftConfig) || isChoosableGiftConfig(giftConfig);

export const isGifteeBoxGiftConfig = (
  giftConfig: GiftConfig | undefined
): giftConfig is GifteeBoxGiftConfig =>
  giftConfig?.ticketType === TICKET_TYPE.GIFTEE_BOX || false;

export const isFixedTicketType = (ticketType: TicketType): boolean =>
  ticketType === TICKET_TYPE.FIXED;

export const isChoosableTicketType = (ticketType: TicketType): boolean =>
  ticketType === TICKET_TYPE.CHOOSABLE;

export const isGifteeBoxTicketType = (ticketType: TicketType): boolean =>
  ticketType === TICKET_TYPE.GIFTEE_BOX;

export const isFixedGiftConfigForDisplay = (
  giftConfigForDisplay: GiftConfigForDisplay
): giftConfigForDisplay is FixedGiftConfigForDisplay =>
  giftConfigForDisplay.ticketType === TICKET_TYPE.FIXED;

export const isChoosableGiftConfigForDisplay = (
  giftConfigForDisplay: GiftConfigForDisplay
): giftConfigForDisplay is ChoosableGiftConfigForDisplay =>
  giftConfigForDisplay.ticketType === TICKET_TYPE.CHOOSABLE;

export const isGifteeBoxGiftConfigForDisplay = (
  giftConfigForDisplay: GiftConfigForDisplay
): giftConfigForDisplay is GifteeBoxGiftConfigForDisplay =>
  giftConfigForDisplay.ticketType === TICKET_TYPE.GIFTEE_BOX;
