import { faKey } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => ({
  empty: {
    textAlign: 'center'
  },
  emptyAccessToken: {
    margin: theme.spacing(5),
    color: theme.palette.icon.file
  }
});

type APITokenEmptyProps = WithStyles<typeof styles>;

const APITokenEmpty: React.FC<APITokenEmptyProps> = ({ classes }) => (
  <div className={classes.empty} data-cy="emptyContainer">
    <FontAwesomeIcon
      icon={faKey}
      size="3x"
      className={classes.emptyAccessToken}
    />
    <Typography variant="subtitle1" color="textSecondary">
      トークンは発行されていません。
    </Typography>
  </div>
);

export default withStyles(styles)(APITokenEmpty);
