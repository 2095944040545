import { gql } from '@apollo/client';
import { internalApiAppClient } from './apolloClient';

import { operationNames } from './index';
import type { FetchResult } from '@apollo/client';

const uploadFileGql = gql`
  mutation ${operationNames.UPLOAD_FILE}($file: Upload!) {
    uploadFile(input: { file: $file }) {
      uploadedFile {
        urlCode
      }
    }
  }
`;

export type UploadedFileUrlCode = string;
export type UploadedFileUrlCodes = UploadedFileUrlCode[];

export interface UploadedFile {
  urlCode: UploadedFileUrlCode;
  name: string;
  size: number;
}
export type UploadedFiles = UploadedFile[] | null;

export type UploadFileResponse = FetchResult<{
  uploadFile: {
    uploadedFile: UploadedFile;
  };
}>;

export const uploadFile = (file: File): Promise<UploadFileResponse> =>
  internalApiAppClient.mutate({
    mutation: uploadFileGql,
    variables: {
      file
    }
  });
