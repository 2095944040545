import { requestGiftCardIssueAmount } from '../../../../common/api/graphql/requestGiftCardIssueAmountGql';
import { createSuccessMessage } from '../../../../common/api/graphql/successMessage';
import {
  requestGiftCardIssueAmountStart,
  requestGiftCardIssueAmountSuccess,
  requestGiftCardIssueAmountFailure
} from '../actions';
import handleApiErrorIfNeeded from './handleApiErrorIfNeeded';
import type { RequestGiftCardIssueAmountResponse } from '../../../../common/api/graphql/requestGiftCardIssueAmountGql';
import type { AppThunkAction } from '../thunkType';
import type { ApiError } from '@common/api/graphql/apolloClient';

const requestGiftCardIssueAmountSuccessThunk =
  (): AppThunkAction<Promise<void>> => dispatch => {
    const snackbarMessage = createSuccessMessage('数量確定');
    dispatch(requestGiftCardIssueAmountSuccess(snackbarMessage));
    return Promise.resolve();
  };

export const requestGiftCardIssueAmountFailureThunk =
  (error: ApiError): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(requestGiftCardIssueAmountFailure());
    return dispatch(handleApiErrorIfNeeded(error));
  };

export const requestGiftCardIssueAmountThunk =
  (issueAmount: number, urlCode: string): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(requestGiftCardIssueAmountStart());

    return requestGiftCardIssueAmount(issueAmount, urlCode)
      .then((response: RequestGiftCardIssueAmountResponse) =>
        dispatch(requestGiftCardIssueAmountSuccessThunk())
      )
      .catch(error => dispatch(requestGiftCardIssueAmountFailureThunk(error)));
  };
