import { gql } from '@apollo/client';

import { publicApiAppClient } from './apolloClient';
import { operationNames } from './operationNames';
import type { FetchResult } from '@apollo/client';

const getBrandsGql = gql`
  query ${operationNames.GET_BRANDS}{
    brands {
      name
      urlCode
    }
  }
`;

export type Brand = {
  name: string;
  urlCode: string;
};

export type Brands = Brand[];

export type GetBrandsResponse = FetchResult<{
  brands: Brands;
}>;

export const getBrands = (): Promise<GetBrandsResponse> =>
  publicApiAppClient.query({
    query: getBrandsGql
  });
