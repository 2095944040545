import MessageThreadSideContents from '@console/components/project/MessageThreadSideContents';
import { getSelectedProjectDetail } from '@console/selectors/projectDetail';
import { getProjectAdvertisingMediumRegistrationsForDisplay } from '@console/selectors/projectDetail/projectAdvertisingMediumRegistrations';
import { getProjectGiftDistributionChannelsForDisplay } from '@console/selectors/projectDetail/projectGiftDistributionChannels';
import { connect } from 'react-redux';
import type { AppThunkDispatch } from '@console/actions/thunkType';
import type { AppState } from '@console/reducers';

const mapStateToProps = (state: AppState) => ({
  projectGiftDistributionChannelsForDisplay:
    getProjectGiftDistributionChannelsForDisplay(state),
  projectAdvertisingMediumRegistrationsForDisplay:
    getProjectAdvertisingMediumRegistrationsForDisplay(state),
  project: getSelectedProjectDetail(state)
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({});

export type MessageThreadSideContentsPropsMappedFromState = ReturnType<
  typeof mapStateToProps
>;
export type MessageThreadSideContentsPropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MessageThreadSideContents);
