import Note from '@console/components/common/Note';
import { Link } from '@mui/material';

const IssueAmountRequestNotes: React.FC<Record<string, never>> = ({ ffam }) => (
  <>
    <Note>
      ※別途発行手数料がかかります。発行手数料は納品日ごとに計算されますので、ご注意ください。詳細は
      <Link
        underline="always"
        href="https://giftee.zendesk.com/hc/ja/articles/4404197192985"
        target="_blank"
        rel="noopener noreferrer"
      >
        こちら
      </Link>
      をご確認ください。
    </Note>
    <Note>
      ※既に希望納品日が過ぎている場合は、確定後、弊社営業担当か、右下のサポートよりご連絡ください。
    </Note>
    <Note>※希望納品日に関わらずご入金確認後の納品となります。</Note>
  </>
);

export default IssueAmountRequestNotes;
