import { gql } from '@apollo/client';

import { internalApiAppClient } from './apolloClient';
import { operationNames } from './index';
import type { FetchResult } from '@apollo/client';

const updateProjectDraftGql = gql`
  mutation ${operationNames.UPDATE_PROJECT_DRAFT}(
    $urlCode: String!
    $schemaVersion: Int!
    $projectJson: JSON!
  ) {
    updateProjectDraft(
      input: {
        urlCode: $urlCode
        schemaVersion: $schemaVersion
        projectJson: $projectJson
      }
    ) {
      projectDraft {
        urlCode
      }
    }
  }
`;

export type UpdateProjectDraftResponse = FetchResult<{
  updateProjectDraft: {
    projectDraft: {
      urlCode: string;
    };
  };
}>;

export type ProjectDraft = {
  schemaVersion: number;
  urlCode: string;
  projectJson: string;
};

export const updateProjectDraft = (
  projectDraft: ProjectDraft
): Promise<UpdateProjectDraftResponse> =>
  internalApiAppClient.mutate({
    mutation: updateProjectDraftGql,
    variables: {
      ...projectDraft
    }
  });
