import { AppActionTypes } from '@console/actions/actionType';
import { combineReducers } from 'redux';
import type { ClaimEstimate } from '@common/api/graphql/getClaimEstimate';
import type { AppAction } from '@console/actions/actionType';
import type { Reducer } from 'redux';

const initialState: ClaimEstimate = {
  consumptionTaxPrice: 0,
  issueCommissionPrice: 0,
  totalPrice: 0
};

const consumptionTaxPrice: Reducer<
  ClaimEstimate['consumptionTaxPrice'],
  AppAction
> = (state = initialState.consumptionTaxPrice, action) => {
  switch (action.type) {
    case AppActionTypes.INITIALIZE_BULK_GIFT_CARD_ISSUE_CONFIGS_NEW:
    case AppActionTypes.INITIALIZE_BULK_GIFTEE_BOX_ISSUE_CONFIGS_NEW:
      return initialState.consumptionTaxPrice;
    case AppActionTypes.GET_BULK_ISSUE_CONFIGS_CLAIM_ESTIMATE_SUCCESS:
      return action.claimEstimate.consumptionTaxPrice;
    default:
      return state;
  }
};

const issueCommissionPrice: Reducer<
  ClaimEstimate['issueCommissionPrice'],
  AppAction
> = (state = initialState.issueCommissionPrice, action: AppAction) => {
  switch (action.type) {
    case AppActionTypes.INITIALIZE_BULK_GIFT_CARD_ISSUE_CONFIGS_NEW:
    case AppActionTypes.INITIALIZE_BULK_GIFTEE_BOX_ISSUE_CONFIGS_NEW:
      return initialState.issueCommissionPrice;
    case AppActionTypes.GET_BULK_ISSUE_CONFIGS_CLAIM_ESTIMATE_SUCCESS:
      return action.claimEstimate.issueCommissionPrice;
    default:
      return state;
  }
};

const totalPrice: Reducer<ClaimEstimate['totalPrice'], AppAction> = (
  state = initialState.totalPrice,
  action
) => {
  switch (action.type) {
    case AppActionTypes.INITIALIZE_BULK_GIFT_CARD_ISSUE_CONFIGS_NEW:
    case AppActionTypes.INITIALIZE_BULK_GIFTEE_BOX_ISSUE_CONFIGS_NEW:
      return initialState.totalPrice;
    case AppActionTypes.GET_BULK_ISSUE_CONFIGS_CLAIM_ESTIMATE_SUCCESS:
      return action.claimEstimate.totalPrice;
    default:
      return state;
  }
};

export const claimEstimate = combineReducers({
  consumptionTaxPrice,
  issueCommissionPrice,
  totalPrice
});
