import { AppActionTypes } from '../actions/actionType';
import type { Brands } from '../../../common/api/graphql/getBrandsGql';
import type { AppAction } from '../actions/actionType';

const brands = (state = [], action: AppAction): Brands => {
  switch (action.type) {
    case AppActionTypes.GET_BRANDS_SUCCESS:
    case AppActionTypes.GET_MASTER_DATA_SUCCESS:
      return action.brands;
    default:
      return state;
  }
};

export default brands;
