import { getLocalString } from '@common/lib/getLocalString';
import GifteeBoxTemplateBrief from '@console/components/common/GifteeBoxTemplateBrief';
import Note from '@console/components/common/Note';
import IssueAmountRequestNotes from '@console/components/project/common/IssueAmountRequestNotes';
import AddingBulkGifteeBoxIssueConfigsTableContainer from '@console/containers/project/gifteeBoxConfigs/bulkGifteeBoxIssueConfigs/new/AddingBulkGifteeBoxIssueConfigsTableContainer';
import { Grid, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import type { ProjectLayoutPathParams } from '@console/components/project/ProjectLayout';
import type {
  NewPropsMappedFromState,
  NewPropsMappedFromDispatch
} from '@console/containers/project/gifteeBoxConfigs/bulkGifteeBoxIssueConfigs/NewContainer';
import type { LocalBulkGifteeBoxIssueConfig } from '@console/reducers/projects';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => ({
  title: {
    marginTop: theme.spacing(6)
  },
  section: {
    marginBottom: theme.spacing(2)
  },
  gifteeBoxConfigContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  backButton: {
    marginRight: theme.spacing(8)
  }
});

type NewPathParams = ProjectLayoutPathParams & {
  gifteeBoxConfigUrlCode?: UrlCode;
};

type NewProps = NewPropsMappedFromState &
  NewPropsMappedFromDispatch &
  WithStyles<typeof styles>;

const New: React.FC<NewProps> = ({
  gifteeBoxConfig,
  onRenderNew,
  errors,
  classes
}) => {
  const params = useParams<NewPathParams>();
  useEffect(() => {
    if (!!params.gifteeBoxConfigUrlCode) {
      onRenderNew(params.gifteeBoxConfigUrlCode);
    }
  }, [params.gifteeBoxConfigUrlCode]);

  const exchangeableEndLabelString = (
    bulkGifteeBoxIssueConfigs: LocalBulkGifteeBoxIssueConfig[]
  ): string =>
    bulkGifteeBoxIssueConfigs.length !== 0
      ? bulkGifteeBoxIssueConfigs[bulkGifteeBoxIssueConfigs.length - 1]
          .gifteeBoxIssueConfig.exchangeableEndLabel
      : '';

  return (
    <Grid data-cy="new">
      <Typography variant="subtitle1" className={classes.title}>
        追加発注の登録をしてください
      </Typography>
      <Typography
        id="projectAddingBulkGifteeBoxIssueConfigsTotal"
        className={classes.errorMessage}
        color="error"
      >
        {errors.projectAddingBulkGifteeBoxIssueConfigsTotal}
      </Typography>
      <Grid container className={classes.section}>
        <Grid item className={classes.gifteeBoxConfigContainer}>
          {!!gifteeBoxConfig ? (
            <GifteeBoxTemplateBrief
              gifteeBoxTemplateName={gifteeBoxConfig.gifteeBoxTemplate.name}
              gifteeBoxTemplateImageUrl={
                gifteeBoxConfig.gifteeBoxTemplate.imageUrl
              }
              exchangeableEndLabel={exchangeableEndLabelString(
                gifteeBoxConfig.bulkGifteeBoxIssueConfigs
              )}
            />
          ) : null}
        </Grid>
        <Grid item>
          <AddingBulkGifteeBoxIssueConfigsTableContainer />
        </Grid>
      </Grid>
      <Grid container direction="column" data-cy="notes">
        {gifteeBoxConfig?.gifteeBoxTemplate && (
          <Note>{`※単価は${getLocalString(
            gifteeBoxConfig.gifteeBoxTemplate.supportedPoint.minPoint
          )}以上、${getLocalString(
            gifteeBoxConfig.gifteeBoxTemplate.supportedPoint.maxPoint
          )}以下の${getLocalString(
            gifteeBoxConfig.gifteeBoxTemplate.supportedPoint.step
          )}の倍数で入力してください。`}</Note>
        )}
        <IssueAmountRequestNotes />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(New);
