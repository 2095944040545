import { AppActionTypes } from '../actions/actionType';
import type { AppAction } from '../actions/actionType';

const projectDraftSyncedDate = (
  state = null,
  action: AppAction
): null | Date => {
  switch (action.type) {
    case AppActionTypes.UPDATE_PROJECT_DRAFT_SUCCESS:
      return action.date;
    default:
      return state;
  }
};

export default projectDraftSyncedDate;
