import { faAngleRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from '@mui/material/CardMedia';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import { Component } from 'react';
import coralAsteriskForFixedTicket from '../../assets/images/coralAsterisk.svg';
import grayAsteriskForFixedTicket from '../../assets/images/grayAsterisk.svg';
import { Choice } from '../api/graphql/getGiftCardGql';
import getReadableDate from '../lib/getReadableDate';
import type { TicketType } from './localTypes';
import type { GiftCardTicket } from '../api/graphql/getGiftCardGql';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const TICKET_HEIGHT = 88;

const styles = (theme: Theme): StyleRules => ({
  tickets: {
    width: '100%',
    height: TICKET_HEIGHT,
    margin: `${theme.spacing()} 0`,
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
    borderRadius: theme.spacing(),
    position: 'relative'
  },
  mask: {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: theme.spacing(),
    display: 'none',
    alignItems: 'center',
    justifyContent: 'center'
  },
  maskOpen: {
    display: 'flex'
  },
  grayAsteriskContainer: {
    position: 'absolute',
    right: 0
  },
  chip: {
    margin: theme.spacing(),
    background: '#888888',
    color: 'white'
  },
  maskOpenTicket: {
    opacity: 0.3
  },
  ticketContainer: {
    position: 'absolute',
    height: 'inherit'
  },
  giftImage: {
    height: TICKET_HEIGHT,
    width: TICKET_HEIGHT,
    position: 'absolute',
    objectFit: 'contain',
    top: 0,
    left: 0
  },
  choosableGiftImage: {
    backgroundColor: theme.palette.secondary.main
  },
  contentDetail: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '100%',
    height: 'inherit',
    paddingRight: theme.spacing(3.5),
    paddingLeft: 96
  },
  ticketDescription: {
    paddingTop: theme.spacing(2),
    height: 60,
    '&:last-child': {
      padding: 0
    }
  },
  giftName: {
    fontWeight: 'bold'
  },
  giftCaption: {
    paddingBottom: theme.spacing()
  },
  expiredAt: {
    display: 'flex',
    bottom: 0
  },
  angleRightIcon: {
    fontSize: 20,
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: theme.spacing(2),
    margin: 'auto',
    color: theme.palette.info.dark
  },
  truncate: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  }
});

interface TicketOwnProps {
  ticket: TicketType;
  handleClick: Function;
  expiresAt?: string | null;
  beginsAt?: string;
  isDisableTicket?: boolean;
  withMask: boolean;
}

type TicketProps = TicketOwnProps & WithStyles<typeof styles>;

class Ticket extends Component<TicketProps, {}> {
  constructor(props: TicketProps) {
    super(props);
  }

  isInvalidTicket = (): boolean =>
    this.isAfterExpirationDate() || this.isBeforeBeginAt();

  isAfterExpirationDate = (): boolean => {
    const { expiresAt } = this.props;
    if (expiresAt === undefined || expiresAt === null) return false;
    const expirationDate = new Date(expiresAt);
    return expirationDate < new Date();
  };

  isBeforeBeginAt = (): boolean => {
    const { beginsAt } = this.props;
    if (beginsAt === undefined) return false;
    const beginDate = new Date(beginsAt);
    return beginDate > new Date();
  };

  isGiftCardTicket = (ticket: TicketType): ticket is GiftCardTicket =>
    ticket.hasOwnProperty('ticketType');

  isFixedTicket = (ticket: GiftCardTicket): boolean =>
    ticket.ticketType === 'fixed';

  isSelectedTicket = (ticket: GiftCardTicket): boolean =>
    ticket.content !== null;

  isSelectedChoosableTicket = (ticket: GiftCardTicket): boolean =>
    this.isSelectedTicket(ticket);

  renderGiftImage = () => {
    const { classes, ticket } = this.props;
    let image = coralAsteriskForFixedTicket;
    if (this.isGiftCardTicket(ticket)) {
      if (this.isFixedTicket(ticket)) {
        image = ticket.content?.imageUrl;
      } else if (this.isSelectedChoosableTicket(ticket)) {
        image = ticket.content?.imageUrl;
      }
    } else {
      image = ticket.content.imageUrl;
    }

    return (
      <img src={image} className={classes.giftImage} data-cy="contentImage" />
    );
  };

  renderContentName = (): string => {
    const { ticket } = this.props;
    if (this.isGiftCardTicket(ticket)) {
      if (this.isSelectedTicket(ticket)) {
        return ticket.content!.name;
      }
      return '選べるギフト';
    }
    return ticket.content.name;
  };

  renderBrandName = (): string => {
    const { ticket } = this.props;
    if (this.isGiftCardTicket(ticket)) {
      if (this.isSelectedTicket(ticket)) {
        return ticket.content!.brand.name;
      }
      return '';
    }
    return ticket.content.brand.name;
  };

  renderExpiresAt = () => {
    const { ticket, expiresAt } = this.props;

    if (this.isGiftCardTicket(ticket)) {
      if (!!ticket.gift?.expiresAt) {
        return `${getReadableDate(ticket.gift.expiresAt)}まで有効`;
      }
      if (!!ticket.content?.availablePeriod) {
        return ticket.content.availablePeriod;
      }
    }

    if (!!expiresAt) {
      return `${getReadableDate(expiresAt)}まで有効`;
    }

    return '発行処理中...';
  };

  render() {
    const {
      classes,
      ticket,
      handleClick,
      expiresAt,
      beginsAt,
      isDisableTicket,
      withMask
    } = this.props;
    return (
      <Card
        className={classes.tickets}
        data-cy={`tickets-${ticket.content?.name}`}
      >
        <div className={classes.grayAsteriskContainer}>
          <img src={grayAsteriskForFixedTicket} />
        </div>
        <CardActionArea
          disabled={isDisableTicket}
          data-cy={`ticketActionArea-${ticket.content?.name}`}
          onClick={() => handleClick(ticket)}
          className={clsx(classes.ticketContainer, {
            [classes.maskOpenTicket]: withMask && this.isInvalidTicket()
          })}
        >
          {this.renderGiftImage()}
          <div className={classes.contentDetail}>
            <div className={clsx(classes.ticketDescription)}>
              <Typography
                className={clsx(classes.giftName, classes.truncate)}
                data-cy={`contentName-${ticket.content?.name}`}
                variant="body2"
              >
                {this.renderContentName()}
              </Typography>
              <div className={clsx(classes.truncate, classes.giftCaption)}>
                <Typography
                  variant="caption"
                  data-cy={`contentBrandName-${ticket.content?.name}`}
                >
                  {this.renderBrandName()}
                </Typography>
              </div>
            </div>
            <Grid
              className={clsx(classes.expiredAt)}
              justifyContent="flex-end"
              container
            >
              <div className={clsx(classes.giftCaption, classes.truncate)}>
                {!!beginsAt ? (
                  <Typography variant="caption" color="textSecondary">
                    {getReadableDate(beginsAt)}〜
                  </Typography>
                ) : null}
                <Typography
                  variant="caption"
                  color="textSecondary"
                  data-cy={`ticketExpiresAt-${ticket.content?.name}`}
                >
                  {this.renderExpiresAt()}
                </Typography>
              </div>
            </Grid>
          </div>
          <FontAwesomeIcon
            icon={faAngleRight}
            className={classes.angleRightIcon}
            data-cy="angleRightIcon"
          />
        </CardActionArea>
        {withMask ? (
          <div
            className={clsx(classes.mask, {
              [classes.maskOpen]: this.isInvalidTicket()
            })}
          >
            {this.isAfterExpirationDate() ? (
              <Chip label="期限切れ" className={classes.chip} />
            ) : null}
            {this.isBeforeBeginAt() ? (
              <Chip label="選択期限前" className={classes.chip} />
            ) : null}
          </div>
        ) : null}
      </Card>
    );
  }
}

export default withStyles(styles)(Ticket);
