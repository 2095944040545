import { updateProjectDraft } from '@common/api/graphql/updateProjectDraftGql';
import {
  updateProjectDraftStart,
  updateProjectDraftSuccess,
  updateProjectDraftFailure
} from '../actions';
import type { AppThunkAction } from '../thunkType';
import type { UpdateProjectDraftResponse } from '@common/api/graphql/updateProjectDraftGql';

const updateProjectDraftSuccessThunk =
  (): AppThunkAction<Promise<void>> => dispatch => {
    const syncedDate = new Date();
    dispatch(updateProjectDraftSuccess(syncedDate));
    return Promise.resolve();
  };

export const updateProjectDraftThunk =
  (): AppThunkAction<Promise<void>> => (dispatch, getState) => {
    dispatch(updateProjectDraftStart());
    const projectDraft = {
      ...getState().selectedProjectDraft,
      projectJson: JSON.stringify(getState().selectedProjectDraft.projectJson)
    };

    return updateProjectDraft(projectDraft)
      .then((response: UpdateProjectDraftResponse) =>
        dispatch(updateProjectDraftSuccessThunk())
      )
      .catch(error => {
        dispatch(updateProjectDraftFailure());
        return Promise.reject(JSON.stringify(error));
      });
  };
