import { errorMessageForApolloClientGraphqlError } from '@common/api/graphql/errorMessage';
import { graphqlFailure } from '../actions';
import type { AppThunkAction } from '../thunkType';
import type { FetchResult } from '@apollo/client';
import type { GraphQLFormattedError } from 'graphql';

export const handleGraphqlErrors =
  (
    errors: ReadonlyArray<GraphQLFormattedError>
  ): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(graphqlFailure([errorMessageForApolloClientGraphqlError]));
    return Promise.reject(JSON.stringify(errors));
  };

type Response = FetchResult<{ [key: string]: any }>;

export const handleGraphqlErrorsIfNeeded =
  (response: Response): AppThunkAction<Promise<void>> =>
  dispatch => {
    if (hasGraphqlError(response)) {
      return dispatch(
        handleGraphqlErrors(
          response.errors as ReadonlyArray<GraphQLFormattedError>
        )
      );
    }
    return Promise.resolve();
  };

export const hasGraphqlError = (response: Response) =>
  response.errors !== undefined;
