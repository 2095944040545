import { AppActionTypes } from '@console/actions/actionType';
import type { AppAction } from '@console/actions/actionType';
import type { Reducer } from 'redux';

export type AddingBulkGifteeBoxIssueConfig = {
  requestedIssueAmount: string | null;
  deliveryDate: string | null;
  initialPoint: string | null;
};

export type AddingBulkGifteeBoxIssueConfigs = AddingBulkGifteeBoxIssueConfig[];

const initialAddingBulkGifteeBoxIssueConfig: AddingBulkGifteeBoxIssueConfig = {
  requestedIssueAmount: null,
  deliveryDate: null,
  initialPoint: null
};
const initialState: AddingBulkGifteeBoxIssueConfigs = [
  initialAddingBulkGifteeBoxIssueConfig
];

export const addingBulkGifteeBoxIssueConfigs: Reducer<
  AddingBulkGifteeBoxIssueConfigs,
  AppAction
> = (state = initialState, action) => {
  switch (action.type) {
    case AppActionTypes.INITIALIZE_BULK_GIFTEE_BOX_ISSUE_CONFIGS_NEW:
      return initialState;
    case AppActionTypes.UPDATE_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIG_INITIAL_POINT:
      return state.map((addingBulkGifteeBoxIssueConfig, index) => {
        if (index === action.addingBulkGifteeBoxIssueConfigIndex) {
          return {
            ...addingBulkGifteeBoxIssueConfig,
            initialPoint: action.initialPoint
          };
        }
        return addingBulkGifteeBoxIssueConfig;
      });
    case AppActionTypes.UPDATE_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIG_REQUESTED_ISSUE_AMOUNT:
      return state.map((addingBulkGifteeBoxIssueConfig, index) => {
        if (index === action.addingBulkGifteeBoxIssueConfigIndex) {
          return {
            ...addingBulkGifteeBoxIssueConfig,
            requestedIssueAmount: action.requestedIssueAmount
          };
        }
        return addingBulkGifteeBoxIssueConfig;
      });
    case AppActionTypes.UPDATE_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIG_DELIVERY_DATE:
      return state.map((addingBulkGifteeBoxIssueConfig, index) => {
        if (index === action.addingBulkGifteeBoxIssueConfigIndex) {
          return {
            ...addingBulkGifteeBoxIssueConfig,
            deliveryDate: !!action.deliveryDate
              ? action.deliveryDate.format('YYYY-MM-DD')
              : null
          };
        }
        return addingBulkGifteeBoxIssueConfig;
      });
    case AppActionTypes.ADD_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIG:
      return [...state, initialAddingBulkGifteeBoxIssueConfig];
    case AppActionTypes.REMOVE_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIG:
      return state.filter(
        (_, index) => index !== action.addingBulkGifteeBoxIssueConfigIndex
      );
    default:
      return state;
  }
};
