import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import type { Theme, ButtonTypeMap } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => createStyles({});

type OwnProps = {
  isOpen: boolean;
  title: string;
  okButtonText: string;
  okButtonColor: ButtonTypeMap['props']['color'];
  onCloseHandler: (event: React.MouseEvent, ...args: any) => void;
  onOKHandler: (event: React.MouseEvent, ...args: any) => void;
};

type ConfirmationModalModalProps = OwnProps & WithStyles<typeof styles>;

const ConfirmationModal: React.FC<
  React.PropsWithChildren<ConfirmationModalModalProps>
> = ({
  isOpen,
  title,
  okButtonText,
  okButtonColor,
  onCloseHandler,
  onOKHandler,
  children
}) => (
  <Dialog
    open={isOpen}
    onClose={onCloseHandler}
    aria-labelledby="confirmation-modal-title"
    aria-describedby="confirmation-modal-description"
    fullWidth
    maxWidth="sm"
    data-cy="confirmationModal"
  >
    <DialogTitle id="confirmation-modal-title" data-cy="confirmationModalTitle">
      {title}
    </DialogTitle>
    <DialogContent
      id="confirmation-modal-description"
      data-cy="confirmationModalContents"
    >
      {children}
    </DialogContent>
    <DialogActions>
      <Button
        onClick={onCloseHandler}
        color="primary"
        data-cy="confirmationModalCancelButton"
      >
        キャンセル
      </Button>
      <Button
        onClick={onOKHandler}
        variant="contained"
        color={okButtonColor}
        data-cy="confirmationModalOKButton"
      >
        {okButtonText}
      </Button>
    </DialogActions>
  </Dialog>
);

export default withStyles(styles)(ConfirmationModal);
