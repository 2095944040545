import { AppActionTypes } from '@console/actions/actionType';
import { combineReducers } from 'redux';
import type { AppAction } from '@console/actions/actionType';
import type { LocalProject } from '@console/reducers/projects';
import type { Reducer } from 'react';

const name: Reducer<LocalProject['hostCompany']['name'] | null, AppAction> = (
  state = null,
  action
) => {
  switch (action.type) {
    case AppActionTypes.GET_PROJECT_DETAIL_SUCCESS:
      return action.project.hostCompany.name;
    default:
      return state;
  }
};

const isOtherCompanyHosting: Reducer<
  LocalProject['hostCompany']['isOtherCompanyHosting'] | null,
  AppAction
> = (state = null, action) => {
  switch (action.type) {
    case AppActionTypes.GET_PROJECT_DETAIL_SUCCESS:
      return action.project.hostCompany.isOtherCompanyHosting;
    default:
      return state;
  }
};

export const hostCompany = combineReducers({
  name,
  isOtherCompanyHosting
});
