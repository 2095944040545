import { validateCampaignOverview } from '@console/actions/actions';
import { projectDraftCampaignOverviewHasValidationErrors } from '@console/middleware/validator/projectDraft/campaignOverview';
import type { AppThunkAction } from '@console/actions/thunkType';

export const validateCampaignOverviewThunk =
  (): AppThunkAction<Promise<void>> => async (dispatch, getState) => {
    const { projectJson } = getState().selectedProjectDraft;
    await dispatch(validateCampaignOverview(projectJson));

    const { errors } = getState();
    if (projectDraftCampaignOverviewHasValidationErrors(errors)) {
      return Promise.reject();
    }
    return Promise.resolve();
  };
