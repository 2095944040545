import { AppActionTypes } from '@console/actions/actionType';
import { combineReducers } from 'redux';
import type { ContentsConnection } from '@common/api/graphql/getContentsGql';
import type { AppAction } from '@console/actions/actionType';
import type { Reducer } from 'redux';

type ContentsSearchResult = {
  totalCount: ContentsConnection['totalCount'];
};

const initialStateOfTotalCount = null;

const totalCount: Reducer<
  ContentsSearchResult['totalCount'] | null,
  AppAction
> = (state = initialStateOfTotalCount, action) => {
  switch (action.type) {
    case AppActionTypes.GET_INITIAL_CONTENTS_SUCCESS:
      return action.contentsConnection.totalCount;
    default:
      return state;
  }
};

export const contents = combineReducers({
  totalCount
});
