import { Button } from '@mui/material';
import { useCallback } from 'react';

type InputType = (string | number)[];

type Props = {
  header: InputType;
  data: Array<InputType>;
  fileName: string;
};

const CSVDownloadButton: React.FC<React.PropsWithChildren<Props>> = ({
  header,
  data,
  children,
  fileName
}) => {
  const createRecords = () => [header, ...data];

  const downloadCSV = useCallback(() => {
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    const records = createRecords();
    const csvData = records.map(record => record.join(',')).join('\r\n');
    const blob = new Blob([bom, csvData], { type: 'text/csv' });

    const downloadLink = document.createElement('a');
    downloadLink.download = `${fileName}.csv`;
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.dataset.downloadurl = [
      'text/plain',
      downloadLink.download,
      downloadLink.href
    ].join(':');
    downloadLink.click();
    downloadLink.remove();
    URL.revokeObjectURL(downloadLink.dataset.downloadurl);
  }, [header, data, fileName]);

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={downloadCSV}
      data-cy="csvDownloadButton"
    >
      {children}
    </Button>
  );
};

export default CSVDownloadButton;
