import { requestInvoicePdfUrl } from '../../../../common/api/graphql/requestInvoicePdfUrlGql';
import {
  requestInvoicePdfUrlStart,
  requestInvoicePdfUrlSuccess,
  requestInvoicePdfUrlFailure
} from '../actions';
import handleApiErrorIfNeeded from './handleApiErrorIfNeeded';
import type {
  RequestInvoicePdfUrlResponse,
  DownloadUrl
} from '../../../../common/api/graphql/requestInvoicePdfUrlGql';
import type { AppThunkAction } from '../thunkType';
import type { ApiError } from '@common/api/graphql/apolloClient';

const requestInvoicePdfUrlSuccessThunk =
  (response: RequestInvoicePdfUrlResponse): AppThunkAction<Promise<string>> =>
  dispatch => {
    const { downloadUrl } = response.data!.requestInvoicePdfUrl;
    dispatch(requestInvoicePdfUrlSuccess());
    return Promise.resolve(downloadUrl);
  };

export const requestInvoicePdfUrlFailureThunk =
  (error: ApiError): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(requestInvoicePdfUrlFailure());
    return dispatch(handleApiErrorIfNeeded(error));
  };

export const requestInvoicePdfUrlThunk =
  (urlCode: UrlCode): AppThunkAction<Promise<DownloadUrl | void>> =>
  (dispatch, getState) => {
    dispatch(requestInvoicePdfUrlStart());

    return requestInvoicePdfUrl(urlCode)
      .then((response: RequestInvoicePdfUrlResponse) =>
        dispatch(requestInvoicePdfUrlSuccessThunk(response))
      )
      .catch(error => dispatch(requestInvoicePdfUrlFailureThunk(error)));
  };
