import Root from '@console/components/projectDraft/Root';
import { getSelectedProjectDraftSelectedGiftConfig } from '@console/selectors/getSelectedProjectDraftForDisplay';
import { connect } from 'react-redux';
import type { AppThunkDispatch } from '@console/actions/thunkType';
import type { AppState } from '@console/reducers';

const mapStateToProps = (state: AppState) => ({
  giftConfigs: state.selectedProjectDraft.projectJson.giftConfigs,
  selectedGiftConfig: getSelectedProjectDraftSelectedGiftConfig(state)
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({});

export type RootPropsMappedFromState = ReturnType<typeof mapStateToProps>;
export type RootPropsMappedFromDispatch = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(Root);
