import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import type {
  StepHandlerPropsMappedFromState,
  StepHandlerPropsMappedFromDispatch
} from '../../containers/projectDraft/StepHandlerContainer';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      paddingBottom: theme.spacing(9)
    },
    stepper: {
      padding: '20px 5%'
    },
    stepHandlers: {
      position: 'fixed',
      bottom: 0,
      height: theme.spacing(12),
      backgroundColor: 'white',
      left: 200,
      paddingLeft: theme.spacing(5)
    },
    stepButtons: {
      height: 50
    },
    nextButton: {
      marginLeft: '10%'
    },
    contents: {
      paddingLeft: 200
    }
  });

export type StepHandlerOwnProps = {
  nextButtonName?: string;
  isBackButtonDisabled?: boolean;
  isNextButtonDisabled?: boolean;
};

type StepHandlerProps = StepHandlerOwnProps &
  StepHandlerPropsMappedFromState &
  StepHandlerPropsMappedFromDispatch &
  WithStyles<typeof styles>;

const StepHandler: React.FC<StepHandlerProps> = ({
  classes,
  nextButtonName,
  isBackButtonDisabled,
  isNextButtonDisabled,
  handleStepNext,
  handleStepBack
}) => {
  const navigate = useNavigate();

  return (
    <Grid
      container
      alignItems="center"
      className={classes.stepHandlers}
      data-cy="stepHandler"
    >
      <Button
        disabled={isBackButtonDisabled ? isBackButtonDisabled : false}
        onClick={() => handleStepBack(navigate)}
        color="primary"
        className={classes.stepButtons}
        data-cy="stepHandlerGoBackButton"
      >
        <KeyboardArrowLeft />
        戻る
      </Button>
      <Button
        disabled={isNextButtonDisabled ? isNextButtonDisabled : false}
        color="primary"
        variant="contained"
        onClick={() => handleStepNext(navigate)}
        className={clsx(classes.stepButtons, classes.nextButton)}
        data-cy="stepHandlerGoNextButton"
      >
        {nextButtonName ? nextButtonName : '次へ'}
        <KeyboardArrowRight />
      </Button>
    </Grid>
  );
};

export default withStyles(styles)(StepHandler);
