import { AppActionTypes } from '../actions/actionType';
import type { DistributionPartner } from '../../../common/api/graphql/getLoginUserGql';
import type { AppAction } from '../actions/actionType';

export const intialStateOfDistributionPartner: DistributionPartner = {
  name: '',
  inBusiness: true,
  creditLimitValue: null,
  creditLimitBalance: null,
  billingAddresses: [],
  urlCode: ''
};

const distributionPartner = (
  state = intialStateOfDistributionPartner,
  action: AppAction
): DistributionPartner => {
  switch (action.type) {
    case AppActionTypes.GET_LOGIN_USER_SUCCESS:
      return action.distributionPartner;
    default:
      return state;
  }
};

export default distributionPartner;
