import { getLocalString } from '@common/lib/getLocalString';
import { getLocalePrice } from '@common/lib/getLocalePrice';
import getReadableDate from '@common/lib/getReadableDate';
import {
  isChoosableGiftConfigForDisplay,
  isGifteeBoxGiftConfigForDisplay,
  isGifteeBoxTicketType,
  isFixedTicketType
} from '@console/common/projectDraft/giftConfig';
import GiftConfigContent from '@console/components/common/ContentRow';
import DetailTableRow from '@console/components/common/DetailTableRow';
import GiftCardPreviewModal from '@console/components/common/GiftCardPreviewModal';
import GifteeBoxTemplateBrief from '@console/components/common/GifteeBoxTemplateBrief';
import GifteeBoxTemplateContentSelectionsModalContainer from '@console/containers/common/GifteeBoxTemplateContentSelectionsModalContainer';
import GiftCardPreviewContainer from '@console/containers/projectDraft/GiftCardPreviewContainer';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { useState, useEffect } from 'react';
import SectionTitle from '../common/SectionTitle';
import TicketTypeChip from '../common/TicketTypeChip';
import type {
  ConfirmedContentsPropsMappedFromState,
  ConfirmedContentsPropsMappedFromDispatch
} from '@console/containers/projectDraft/confirmation/ConfirmedContentsContainer';
import type { SelectedGifteeBoxTemplate } from '@console/reducers/projectJson/giftConfigs';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => ({
  accordion: {
    boxShadow: 'none',
    margin: `${theme.spacing()} 0`
  },
  accordionContents: {
    padding: 0
  },
  tableContainer: {
    overflowX: 'scroll',
    whiteSpace: 'nowrap'
  },
  table: {
    borderBottom: 'hidden',
    marginBottom: theme.spacing()
  },
  tableCell: {
    padding: theme.spacing(2),
    verticalAlign: 'middle'
  },
  contentContainer: {
    width: theme.spacing(45),
    whiteSpace: 'pre-line'
  },
  contentImage: {
    width: theme.spacing(9),
    verticalAlign: 'middle'
  },
  contentImageContainer: {
    marginRight: theme.spacing(2)
  },
  button: {
    margin: `${theme.spacing()} 0 ${theme.spacing()} ${theme.spacing()}`,
    padding: '5px 8px'
  },
  note: {
    marginBottom: theme.spacing()
  }
});

type ConfirmedContentsProps = ConfirmedContentsPropsMappedFromState &
  ConfirmedContentsPropsMappedFromDispatch &
  WithStyles<typeof styles>;

const ConfirmedContents: React.FC<ConfirmedContentsProps> = ({
  classes,
  projectJson,
  projectDraftSolutionSystem,
  projectDraftHostCompanyName,
  projectDraftMarketingIndustry,
  campaignGiftDistributionChannelsForDisplay,
  campaignAdvertisingMediaForDisplay,
  isBulkSolutionSystemSelected,
  isGcpSolutionSystemSelected,
  isApiSolutionSystemSelected,
  projectDraftGiftConfigs,
  onMount,
  selectGiftConfig,
  projectDraftGiftConfigsEstimatedAmountTotal,
  issueRateAmount,
  consumerTaxAmount,
  projectDraftEstimatedAmount,
  handleProjectDraftGiftConfigsEstimatedAmountTotalChange,
  selectGifteeBoxTemplate,
  deselectGifteeBoxTemplate
}) => {
  const [giftCardPreviewModalOpen, setGiftCardPreviewModalOpen] =
    useState(false);

  const handleOpenGiftCardPreviewButtonClick = (giftConfigIndex: number) => {
    selectGiftConfig(giftConfigIndex);
    openGiftCardPreviewModal();
  };

  const handleGiftCardPreviewModalClose = () => {
    closeGiftCardPreviewModal();
  };

  const openGiftCardPreviewModal = () => {
    setGiftCardPreviewModalOpen(true);
  };

  const closeGiftCardPreviewModal = () => {
    setGiftCardPreviewModalOpen(false);
  };

  const isGiftCardPreviewVisible = (
    giftConfigForDisplay: (typeof projectDraftGiftConfigs)[number]
  ): boolean => {
    if (isGifteeBoxGiftConfigForDisplay(giftConfigForDisplay)) {
      return false;
    }
    if (isChoosableGiftConfigForDisplay(giftConfigForDisplay)) {
      return true;
    }
    return giftConfigForDisplay.messageCardUsing;
  };

  useEffect(() => onMount(), []);

  useEffect(() => {
    handleProjectDraftGiftConfigsEstimatedAmountTotalChange();
  }, [projectDraftGiftConfigsEstimatedAmountTotal]);

  const [
    gifteeBoxTemplateContentSelectionsModalOpen,
    setGifteeBoxTemplateContentSelectionsModalOpen
  ] = useState(false);

  const openGifteeBoxTemplateContentSelectionsModal = (
    gifteeBoxTemplate: NonNullable<SelectedGifteeBoxTemplate>
  ) => {
    selectGifteeBoxTemplate(gifteeBoxTemplate);
    setGifteeBoxTemplateContentSelectionsModalOpen(true);
  };

  const closeGifteeBoxTemplateContentSelectionsModal = () => {
    setGifteeBoxTemplateContentSelectionsModalOpen(false);
    deselectGifteeBoxTemplate();
  };

  return (
    <>
      <Grid item xs={12} data-cy="campaignOverviewSection">
        <Accordion className={classes.accordion} defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="campaignOverview"
            id="campaignOverview"
            className={classes.accordionContents}
          >
            <SectionTitle text="利用概要" />
          </AccordionSummary>
          <AccordionDetails className={classes.accordionContents}>
            <Table className={classes.table}>
              <TableBody>
                <DetailTableRow
                  label="利用サービス"
                  value={
                    projectDraftSolutionSystem === undefined
                      ? ''
                      : projectDraftSolutionSystem.name
                  }
                />
                <DetailTableRow
                  label="キャンペーン主催企業"
                  value={projectDraftHostCompanyName}
                />
                <DetailTableRow
                  label="キャンペーン名"
                  value={projectJson.name}
                  data-cy="campaignName"
                />
                <DetailTableRow
                  label="キャンペーン内容"
                  value={projectJson.description}
                />
                <DetailTableRow
                  label="キャンペーン実施業界"
                  value={
                    projectDraftMarketingIndustry === undefined
                      ? ''
                      : projectDraftMarketingIndustry.name
                  }
                />
                <DetailTableRow
                  label="掲載期間"
                  value={`${getReadableDate(
                    projectJson.advertisingPeriod.startDateOfAd
                  )} 〜 ${getReadableDate(
                    projectJson.advertisingPeriod.endDateOfAd
                  )}`}
                />
                <DetailTableRow
                  label="ギフト配布期間"
                  value={`${getReadableDate(
                    projectJson.giftDistributionPeriod.startDateOfDistribution
                  )} 〜 ${getReadableDate(
                    projectJson.giftDistributionPeriod.endDateOfDistribution
                  )}`}
                />
                <DetailTableRow
                  label="キャンペーン対象のサービス名"
                  value={projectJson.thirdPartyService.name}
                />
                <DetailTableRow
                  label="キャンペーン対象のサービスサイトURL"
                  value={projectJson.thirdPartyService.url}
                />
                <DetailTableRow
                  label="キャンペーン対象のサービス内容"
                  value={projectJson.thirdPartyService.description}
                />
              </TableBody>
            </Table>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid
        item
        xs={12}
        className={classes.section}
        data-cy="giftDistributionSection"
      >
        <Accordion className={classes.accordion} defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="giftDistribution"
            id="giftDistribution"
            className={classes.accordionContents}
          >
            <SectionTitle text="ギフトの配布方法" />
          </AccordionSummary>
          <AccordionDetails className={classes.accordionContents}>
            <Table className={classes.table}>
              <TableBody>
                <DetailTableRow
                  label="配布方法"
                  value={campaignGiftDistributionChannelsForDisplay}
                />
                <DetailTableRow
                  label="露出媒体"
                  value={campaignAdvertisingMediaForDisplay}
                />
              </TableBody>
            </Table>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid
        item
        xs={12}
        className={classes.section}
        data-cy="giftConfigSection"
      >
        <Accordion className={classes.accordion} defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="giftConfig"
            id="giftConfig"
            className={classes.accordionContents}
          >
            <SectionTitle text="利用ギフト" />
          </AccordionSummary>
          <AccordionDetails className={classes.accordionContents}>
            <div className={classes.tableContainer}>
              <Table className={classes.table}>
                <TableHead data-cy="giftConfigsTableHeader">
                  <TableRow>
                    <TableCell className={classes.tableCell} align="left">
                      No.
                    </TableCell>
                    <TableCell className={classes.tableCell} align="left">
                      ギフトのタイプ
                    </TableCell>
                    <TableCell className={classes.tableCell} align="left">
                      ギフト・ブランド名
                    </TableCell>
                    <TableCell className={classes.tableCell} align="left">
                      単価
                    </TableCell>
                    <TableCell className={classes.tableCell} align="left">
                      数量（見込み）
                    </TableCell>
                    <TableCell className={classes.tableCell} align="left">
                      予定金額
                    </TableCell>
                    {isBulkSolutionSystemSelected && (
                      <TableCell className={classes.tableCell} align="left">
                        納品希望日
                      </TableCell>
                    )}
                    <TableCell className={classes.tableCell} align="left">
                      ギフトカード
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {projectJson.giftConfigs.length === 0
                    ? null
                    : projectDraftGiftConfigs.map((giftConfig, i) => (
                        <TableRow key={i} data-cy={`giftConfigRow-${i}`}>
                          <TableCell
                            className={classes.tableCell}
                            align="left"
                            data-cy="no"
                          >
                            {i + 1}
                          </TableCell>
                          <TableCell
                            className={classes.tableCell}
                            align="left"
                            data-cy="ticketType"
                          >
                            <TicketTypeChip
                              ticketType={giftConfig.ticketType}
                            />
                          </TableCell>
                          <TableCell className={classes.tableCell} align="left">
                            <Grid
                              container
                              direction="column"
                              alignItems="center"
                            >
                              {isGifteeBoxTicketType(giftConfig.ticketType) ? (
                                !!giftConfig.selectedGifteeBoxTemplate ? (
                                  <GifteeBoxTemplateBrief
                                    key={`selectedGifteeBoxTemplate-${i}`}
                                    gifteeBoxTemplateName={
                                      giftConfig.selectedGifteeBoxTemplate.name
                                    }
                                    gifteeBoxTemplateImageUrl={
                                      giftConfig.selectedGifteeBoxTemplate
                                        .imageUrl
                                    }
                                    isLimitedPublished={
                                      giftConfig.selectedGifteeBoxTemplate
                                        .isLimitedPublished
                                    }
                                    handleButtonClick={() =>
                                      openGifteeBoxTemplateContentSelectionsModal(
                                        giftConfig.selectedGifteeBoxTemplate!
                                      )
                                    }
                                  />
                                ) : null
                              ) : (
                                giftConfig.selectedContents.map((content, i) =>
                                  content ===
                                  undefined ? null : isFixedTicketType(
                                      giftConfig.ticketType
                                    ) ? (
                                    <GiftConfigContent
                                      key={i}
                                      contentName={content.name}
                                      contentInternalName={content.internalName}
                                      contentBrandName={content.brand.name}
                                      contentImageUrl={content.imageUrl}
                                      contentAvailablePeriodText={`有効期限：${content.availablePeriod}`}
                                      isLimitedPublished={
                                        content.isLimitedPublished
                                      }
                                    />
                                  ) : (
                                    <GiftConfigContent
                                      key={i}
                                      contentName={content.name}
                                      contentInternalName={content.internalName}
                                      contentBrandName={content.brand.name}
                                      contentImageUrl={content.imageUrl}
                                      selectablePeriodText="選択期限：1ヶ月後の月末"
                                      contentAvailablePeriodText={`選択後の有効期限：${content.availablePeriod}`}
                                      isLimitedPublished={
                                        content.isLimitedPublished
                                      }
                                    />
                                  )
                                )
                              )}
                            </Grid>
                          </TableCell>
                          <TableCell
                            className={classes.tableCell}
                            align="right"
                          >
                            {isGifteeBoxTicketType(giftConfig.ticketType) &&
                            (isGcpSolutionSystemSelected ||
                              isApiSolutionSystemSelected) ? (
                              giftConfig.gifteeBoxIssueEstimateOptions.map(
                                (
                                  gifteeBoxIssueEstimateOption,
                                  gifteeBoxIssueEstimateOptionIndex
                                ) => (
                                  <Typography
                                    key={`giftConfig-${i}-gifteeBoxIssueEstimateOptionInitialPoint-${gifteeBoxIssueEstimateOptionIndex}`}
                                    className={classes.content}
                                    data-cy={`giftConfig-${i}-gifteeBoxIssueEstimateOptionInitialPoint-${gifteeBoxIssueEstimateOptionIndex}`}
                                  >
                                    {getLocalePrice(
                                      Number(
                                        gifteeBoxIssueEstimateOption.initialPoint
                                      )
                                    )}
                                  </Typography>
                                )
                              )
                            ) : (
                              <Typography
                                className={classes.content}
                                data-cy="currentPriceTotal"
                              >
                                {getLocalePrice(giftConfig.price)}
                              </Typography>
                            )}
                          </TableCell>
                          <TableCell
                            className={classes.tableCell}
                            align="right"
                          >
                            {isGifteeBoxTicketType(giftConfig.ticketType) &&
                            (isGcpSolutionSystemSelected ||
                              isApiSolutionSystemSelected) ? (
                              giftConfig.gifteeBoxIssueEstimateOptions.map(
                                (
                                  gifteeBoxIssueEstimateOption,
                                  gifteeBoxIssueEstimateOptionIndex
                                ) => (
                                  <Typography
                                    key={`giftConfig-${i}-gifteeBoxIssueEstimateOptionIssueAmount-${gifteeBoxIssueEstimateOptionIndex}`}
                                    className={classes.content}
                                    data-cy={`giftConfig-${i}-gifteeBoxIssueEstimateOptionIssueAmount-${gifteeBoxIssueEstimateOptionIndex}`}
                                  >
                                    {getLocalString(
                                      Number(
                                        gifteeBoxIssueEstimateOption.issueAmount
                                      )
                                    )}
                                  </Typography>
                                )
                              )
                            ) : (
                              <Typography
                                className={classes.content}
                                data-cy="issueAmount"
                              >
                                {getLocalString(Number(giftConfig.issueAmount))}
                              </Typography>
                            )}
                          </TableCell>
                          <TableCell
                            className={classes.tableCell}
                            align="right"
                          >
                            {isGifteeBoxTicketType(giftConfig.ticketType) &&
                            (isGcpSolutionSystemSelected ||
                              isApiSolutionSystemSelected) ? (
                              giftConfig.gifteeBoxIssueEstimateOptions.map(
                                (
                                  gifteeBoxIssueEstimateOption,
                                  gifteeBoxIssueEstimateOptionIndex
                                ) => (
                                  <Typography
                                    key={`giftConfig-${i}-gifteeBoxIssueEstimateOptionEstimatedAmount-${gifteeBoxIssueEstimateOptionIndex}`}
                                    className={classes.content}
                                    data-cy={`giftConfig-${i}-gifteeBoxIssueEstimateOptionEstimatedAmount-${gifteeBoxIssueEstimateOptionIndex}`}
                                  >
                                    {getLocalePrice(
                                      Number(
                                        gifteeBoxIssueEstimateOption.issueAmount
                                      ) *
                                        Number(
                                          gifteeBoxIssueEstimateOption.initialPoint
                                        )
                                    )}
                                  </Typography>
                                )
                              )
                            ) : (
                              <Typography
                                className={classes.content}
                                data-cy="giftConfigEstimatedAmount"
                              >
                                {getLocalePrice(giftConfig.estimatedAmount)}
                              </Typography>
                            )}
                          </TableCell>
                          {isBulkSolutionSystemSelected && (
                            <TableCell
                              className={classes.tableCell}
                              align="left"
                            >
                              <Typography
                                className={classes.content}
                                data-cy="deliveryDate"
                              >
                                {getReadableDate(giftConfig.deliveryDate)}
                              </Typography>
                            </TableCell>
                          )}
                          <TableCell
                            className={classes.tableCell}
                            align="center"
                            data-cy="messageCardDetail"
                          >
                            {isGiftCardPreviewVisible(giftConfig) ? (
                              <Button
                                color="primary"
                                variant="outlined"
                                className={classes.button}
                                onClick={() => {
                                  handleOpenGiftCardPreviewButtonClick(i);
                                }}
                              >
                                <Typography variant="body2">
                                  プレビューを見る
                                </Typography>
                              </Button>
                            ) : (
                              '無し'
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                  <TableRow>
                    <TableCell rowSpan={4} colSpan={4} />
                    <TableCell className={classes.tableCell} align="left">
                      商品代合計
                    </TableCell>
                    <TableCell className={classes.tableCell} align="right">
                      <Typography
                        className={classes.content}
                        data-cy="estimatedAmountTotal"
                      >
                        {getLocalePrice(
                          projectDraftGiftConfigsEstimatedAmountTotal
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell colSpan={5} />
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableCell} align="left">
                      発行手数料
                    </TableCell>
                    <TableCell className={classes.tableCell} align="right">
                      <Typography
                        className={classes.content}
                        data-cy="issueRateAmount"
                      >
                        {getLocalePrice(issueRateAmount)}
                      </Typography>
                    </TableCell>
                    <TableCell colSpan={5} />
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableCell} align="left">
                      消費税
                    </TableCell>
                    <TableCell className={classes.tableCell} align="right">
                      <Typography
                        className={classes.content}
                        data-cy="consumerTaxAmount"
                      >
                        {getLocalePrice(consumerTaxAmount)}
                      </Typography>
                    </TableCell>
                    <TableCell colSpan={5} />
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableCell} align="left">
                      概算合計
                    </TableCell>
                    <TableCell className={classes.tableCell} align="right">
                      <Typography
                        className={classes.content}
                        data-cy="estimatedAmount"
                      >
                        {getLocalePrice(projectDraftEstimatedAmount)}
                      </Typography>
                    </TableCell>
                    <TableCell colSpan={5} />
                  </TableRow>
                </TableBody>
              </Table>
            </div>
            {projectJson.giftConfigs.length !== 0 ? null : (
              <Grid
                container
                justifyContent="center"
                className={classes.unselected}
              >
                <Typography variant="body1" color="textPrimary">
                  ギフト設定は追加されていません。
                </Typography>
              </Grid>
            )}
          </AccordionDetails>
        </Accordion>
      </Grid>
      <GifteeBoxTemplateContentSelectionsModalContainer
        isOpen={gifteeBoxTemplateContentSelectionsModalOpen}
        handleClose={closeGifteeBoxTemplateContentSelectionsModal}
      />
      <GiftCardPreviewModal
        isOpen={giftCardPreviewModalOpen}
        onCloseHandler={handleGiftCardPreviewModalClose}
      >
        <GiftCardPreviewContainer />
      </GiftCardPreviewModal>
    </>
  );
};

export default withStyles(styles)(ConfirmedContents);
