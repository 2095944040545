import { addBulkGiftCardIssueConfigs } from '@common/api/graphql/addBulkGiftCardIssueConfigsGql';
import { createSuccessMessage } from '@common/api/graphql/successMessage';
import {
  addBulkGiftCardIssueConfigsStart,
  addBulkGiftCardIssueConfigsSuccess,
  addBulkGiftCardIssueConfigsFailure
} from '@console/actions/actions';
import handleApiErrorIfNeeded from './handleApiErrorIfNeeded';
import type { AddBulkGiftCardIssueConfigsResponse } from '@common/api/graphql/addBulkGiftCardIssueConfigsGql';
import type { ApiError } from '@common/api/graphql/apolloClient';
import type { AppThunkAction } from '@console/actions/thunkType';
import type { AddingBulkGiftCardIssueConfigs } from '@console/reducers/edit/project/giftCardConfigs/addingBulkGiftCardIssueConfigs';

export const addBulkGiftCardIssueConfigsSuccessThunk =
  (
    _response: AddBulkGiftCardIssueConfigsResponse
  ): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(
      addBulkGiftCardIssueConfigsSuccess(createSuccessMessage('追加発注'))
    );
    return Promise.resolve();
  };

export const addBulkGiftCardIssueConfigsFailureThunk =
  (error: ApiError): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(addBulkGiftCardIssueConfigsFailure());
    return dispatch(handleApiErrorIfNeeded(error));
  };

export const addBulkGiftCardIssueConfigsThunk =
  (
    giftCardConfig: UrlCode,
    addingBulkGiftCardIssueConfigs: AddingBulkGiftCardIssueConfigs
  ): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(addBulkGiftCardIssueConfigsStart());

    return addBulkGiftCardIssueConfigs(
      giftCardConfig,
      addingBulkGiftCardIssueConfigs
    )
      .then((response: AddBulkGiftCardIssueConfigsResponse) =>
        dispatch(addBulkGiftCardIssueConfigsSuccessThunk(response))
      )
      .catch((error: ApiError) =>
        dispatch(addBulkGiftCardIssueConfigsFailureThunk(error))
      );
  };
