import CopyToClipboardButton from '@common/components/CopyToClipboardButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  Typography,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Chip,
  Input,
  InputAdornment,
  IconButton
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import moment from 'moment';
import { useState, useEffect } from 'react';
import type { QueryAccessToken } from '../../../../../common/api/graphql/getProjectsGql';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => ({
  tableContainer: {
    overflowX: 'scroll',
    whiteSpace: 'nowrap'
  },
  table: {
    textAlign: 'left',
    marginTop: theme.spacing(0.5),
    maxWidth: 600
  },
  tableCell: {
    padding: theme.spacing(2),
    verticalAlign: 'middle'
  },
  tokenTableCell: {
    padding: theme.spacing(2),
    verticalAlign: 'middle',
    width: 500
  },
  accessTokenTitle: {
    whiteSpace: 'pre-line',
    width: 360
  },
  accessTokenContainer: {
    marginBottom: theme.spacing()
  },
  accessTokens: {
    color: theme.palette.text.primary
  },
  chip: {
    borderRadius: 5
  },
  sucesssIcon: {
    backgroundColor: theme.palette.success.main
  },
  iconButton: {
    margin: `0 ${theme.spacing()}`
  }
});

type APITokenTableOwnProps = {
  accessTokens: QueryAccessToken[];
};

type ShowTokens = boolean[];

type APITokenTableProps = APITokenTableOwnProps & WithStyles<typeof styles>;

const APITokenTable: React.FC<APITokenTableProps> = ({
  classes,
  accessTokens
}) => {
  const [showTokens, setShowTokens] = useState<ShowTokens>([]);

  const handleClickShowTokens = (index: number): void => {
    const newShowTokens = [...showTokens];
    newShowTokens[index] = !newShowTokens[index];
    setShowTokens(newShowTokens);
  };

  const handleMouseDownTokens = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    event.preventDefault();
  };

  useEffect(() => {
    const showTokens = accessTokens.reduce(acc => [...acc, false], []);
    setShowTokens(showTokens);
  }, [accessTokens]);

  return (
    <div className={classes.tableContainer} data-cy="tableContainer">
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableCell}>No.</TableCell>
            <TableCell className={classes.tokenTableCell}>
              トークン情報
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {accessTokens.map((accessToken, accessTokenIndex) => (
            <TableRow key={accessTokenIndex}>
              <TableCell className={classes.tableCell}>
                <Typography
                  variant="body2"
                  className={classes.content}
                  data-cy={`accessTokenNumber-${accessTokenIndex}`}
                >
                  {accessTokenIndex + 1}
                </Typography>
              </TableCell>
              <TableCell className={classes.tableCell}>
                <div className={classes.accessTokenContainer}>
                  <Input
                    type={showTokens[accessTokenIndex] ? 'text' : 'password'}
                    disabled
                    value={accessToken.token}
                    inputProps={{
                      className: classes.accessTokens
                    }}
                    fullWidth={true}
                    data-cy={`accessTokenIdentity-${accessTokenIndex}`}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle tokens visibility"
                          className={classes.iconButton}
                          onClick={() =>
                            handleClickShowTokens(accessTokenIndex)
                          }
                          onMouseDown={handleMouseDownTokens}
                          data-cy={`accessTokenVisibleButton-${accessTokenIndex}`}
                          size="large"
                        >
                          {showTokens[accessTokenIndex] ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                        <CopyToClipboardButton
                          className={classes.iconButton}
                          copyText={accessToken.token}
                        />
                      </InputAdornment>
                    }
                  />
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default withStyles(styles)(APITokenTable);
