import { gql } from '@apollo/client';

import { internalApiAppClient } from './apolloClient';
import { operationNames } from './index';
import type { FetchResult } from '@apollo/client';

const getProjectDraftsGql = gql`
  query ${operationNames.GET_PROJECT_DRAFTS} {
    projectDrafts {
      schemaVersion
      projectJson
      urlCode
    }
  }
`;

export interface ProjectDraft {
  schemaVersion: string;
  urlCode: string;
  projectJson: JSON;
}

type ProjectDrafts = ProjectDraft[];

export type GetProjectDraftsResponse = FetchResult<{
  projectDrafts: ProjectDrafts;
}>;

export const getProjectDrafts = (): Promise<GetProjectDraftsResponse> =>
  internalApiAppClient.query({
    query: getProjectDraftsGql
  });
