import { ProjectContents } from '@console/components/MainContents';
import BulkTable from '@console/components/project/selectionReport/BulkTable';
import { getGifteeBoxConfigs } from '@console/selectors/projectDetail/gifteeBoxConfigs';
import { connect } from 'react-redux';
import type { AppThunkDispatch } from '@console/actions/thunkType';
import type { AppState } from '@console/reducers';
import type { NavigateFunction } from 'react-router-dom';

const mapStateToProps = (state: AppState) => ({
  gifteeBoxConfigs: getGifteeBoxConfigs(state),
  onClickGifteeBoxConfig: (
    navigate: NavigateFunction,
    gifteeBoxConfigUrlCode: UrlCode
  ) => {
    const projectUrlCode = state.selectedProjectUrlCode;
    const url = `/console/projects/${projectUrlCode}/${ProjectContents.GIFTEE_BOX_CONFIGS}/${gifteeBoxConfigUrlCode}/${ProjectContents.EXCHANGE_REPORT}`;
    navigate(url);
  }
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({});

export type BulkTablePropsMappedFromState = ReturnType<typeof mapStateToProps>;

export type BulkTablePropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(mapStateToProps, mapDispatchToProps)(BulkTable);
