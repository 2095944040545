import {
  isBulkSolutionSystemSelected,
  isApiSolutionSystemSelected,
  isGcpSolutionSystemSelected
} from '@console/selectors/getSelectedProjectDraftForDisplay';
import { getAdvertisingPeriod } from './createProject/getAdvertisingPeriod';
import {
  getGiftCardConfigsForBulkProject,
  getGiftCardConfigsForApiProject
} from './createProject/getGiftCardConfigs';
import { getGiftDistributionPeriod } from './createProject/getGiftDistributionPeriod';
import {
  getGifteeBoxConfigsForBulkProject,
  getGifteeBoxConfigsForApiProject,
  getGifteeBoxConfigsForGcpProject
} from './createProject/getGifteeBoxConfigs';
import { getHostCompanyOption } from './createProject/getHostCompanyOption';
import { getProjectGiftDistributionChannels } from './createProject/getProjectGiftDistributionChannels';
import type { MutationProject } from '@common/api/graphql/createProjectGql';
import type { AppThunkAction } from '@console/actions/thunkType';

export const getCreateProjectParamsThunk =
  (): AppThunkAction<MutationProject | null> => (_, getState) => {
    const state = getState();
    const { urlCode, projectJson } = state.selectedProjectDraft;
    const { hostCompany } = projectJson;

    const mutationProject = {
      name: projectJson.name,
      description: projectJson.description,
      projectDraftUrlCode: urlCode,
      hostCompanyOption: getHostCompanyOption(hostCompany),
      advertisingPeriod: getAdvertisingPeriod(projectJson.advertisingPeriod),
      solutionSystem: projectJson.campaignSolutionSystem,
      marketingIndustry: projectJson.campaignMarketingIndustry,
      giftDistributionPeriod: getGiftDistributionPeriod(
        projectJson.giftDistributionPeriod
      ),
      thirdPartyService: projectJson.thirdPartyService,
      projectGiftDistributionChannels: getProjectGiftDistributionChannels(
        projectJson.campaignGiftDistributionChannels,
        projectJson.campaignGiftDistributionChannelDescriptions
      ),
      projectAdvertisingMediumRegistrations:
        projectJson.campaignAdvertisingMedia
    };

    if (isBulkSolutionSystemSelected(getState())) {
      return {
        ...mutationProject,
        giftCardConfigs: getGiftCardConfigsForBulkProject(
          projectJson.giftConfigs
        ),
        gifteeBoxConfigs: getGifteeBoxConfigsForBulkProject(
          projectJson.giftConfigs
        )
      };
    }
    if (isGcpSolutionSystemSelected(getState())) {
      return {
        ...mutationProject,
        giftCardConfigs: getGiftCardConfigsForApiProject(
          projectJson.giftDistributionPeriod,
          projectJson.giftConfigs
        ),
        gifteeBoxConfigs: getGifteeBoxConfigsForGcpProject(
          projectJson.giftDistributionPeriod,
          projectJson.giftConfigs
        )
      };
    }
    if (isApiSolutionSystemSelected(getState())) {
      return {
        ...mutationProject,
        giftCardConfigs: getGiftCardConfigsForApiProject(
          projectJson.giftDistributionPeriod,
          projectJson.giftConfigs
        ),
        gifteeBoxConfigs: getGifteeBoxConfigsForApiProject(
          projectJson.giftDistributionPeriod,
          projectJson.giftConfigs
        )
      };
    }
    return null;
  };
