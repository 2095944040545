import { getMasterDataThunk } from '@console/actions/thunks/getMasterData';
import { connect } from 'react-redux';
import {
  updateIsLoggedIn,
  initializeSnackbarMessage
} from '../actions/actions';
import { getLoginUserThunk } from '../actions/thunks/getLoginUser';
import App from '../components/App';
import type { AppThunkDispatch, AppThunkAction } from '../actions/thunkType';
import type { AppState } from '../reducers';

const getInitialData = (): AppThunkAction<Promise<void>> => dispatch =>
  dispatch(getLoginUserThunk())
    .then(() => {
      dispatch(updateIsLoggedIn(true));
    })
    .catch(() => {
      window.location.href = '/account/login';
    })
    .then(() => dispatch(getMasterDataThunk()).catch(() => {}));

const handleClose = (): AppThunkAction<void> => dispatch => {
  dispatch(initializeSnackbarMessage());
};

const mapStateToProps = (state: AppState) => ({
  isLoggedIn: state.isLoggedIn,
  isLoading: state.loaders.isAppLoading,
  snackbarMessage: state.snackbarMessage,
  distributionPartnerUser: state.distributionPartnerUser
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  getInitialData: () => dispatch(getInitialData()),
  handleClose: () => dispatch(handleClose())
});

export type AppPropsMappedFromState = ReturnType<typeof mapStateToProps>;
export type AppPropsMappedFromDispatch = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(App);
