export const getUniqueElements = <T>(array: T[]): T[] => [...new Set(array)];

export const getUniqueObjects = <T>(
  array: T[],
  comparator: (obj1: T, obj2: T) => boolean,
  findFromLast = false
): T[] => {
  // TODO: performance tuning
  // https://stackoverflow.com/a/14438954 (indexOfの例)を参考に、
  // findIndex / findLastIndex(https://github.com/tc39/proposal-array-find-from-last) を使うと改善できそう。
  const targetArray = array.slice();

  if (findFromLast) {
    targetArray.reverse();
  }

  const resultArray: T[] = [];
  targetArray.forEach(obj1 => {
    if (targetArray.some(obj2 => comparator(obj1, obj2))) {
      if (resultArray.some(obj2 => comparator(obj1, obj2))) {
        return;
      }
    }
    // 要素の順番が元の配列から変わらないようにするため、メソッドを使い分けています。
    if (findFromLast) {
      resultArray.unshift(obj1);
    } else {
      resultArray.push(obj1);
    }
  });

  return resultArray;
};
