import type { MutationProjectGiftDistributionChannels } from '@common/api/graphql/createProjectGql';
import type { CampaignGiftDistributionChannelDescriptions } from '@console/reducers/projectJson/campaignGiftDistributionChannelDescriptions';
import type { CampaignGiftDistributionChannels } from '@console/reducers/projectJson/campaignGiftDistributionChannels';

export const getProjectGiftDistributionChannels = (
  giftDistributionChannels: CampaignGiftDistributionChannels,
  giftDistributionChannelDescriptions: CampaignGiftDistributionChannelDescriptions
): NonNullable<MutationProjectGiftDistributionChannels> =>
  giftDistributionChannels.map(giftDistributionChannelsUrlCode => {
    const descriptions = giftDistributionChannelDescriptions.filter(
      description => description.urlCode === giftDistributionChannelsUrlCode
    );

    return descriptions.length > 0
      ? {
          urlCode: giftDistributionChannelsUrlCode,
          description: descriptions[0].description
        }
      : {
          urlCode: giftDistributionChannelsUrlCode,
          description: ''
        };
  });
