import { getContents } from '@common/api/graphql/getContentsGql';
import { getUniqueElements } from '@common/lib/getUniqueElements';
import {
  getSelectedProjectDraftContentsStart,
  getSelectedProjectDraftContentsSuccess,
  getSelectedProjectDraftContentsFailure
} from '@console/actions/actions';
import { getProjectDraftContentUrlCodes } from '@console/actions/thunks/getProjectDraftsContents';
import handleApiErrorIfNeeded from '@console/actions/thunks/handleApiErrorIfNeeded';
import type { ApiError } from '@common/api/graphql/apolloClient';
import type { GetContentsResponse } from '@common/api/graphql/getContentsGql';
import type { AppThunkAction } from '@console/actions/thunkType';
import type { ProjectDraft } from '@console/reducers/selectedProjectDraft';

export const getSelectedProjectDraftContentsSuccessThunk =
  (response: GetContentsResponse): AppThunkAction<Promise<void>> =>
  dispatch => {
    const { contents } = response.data!;
    dispatch(getSelectedProjectDraftContentsSuccess(contents));
    return Promise.resolve();
  };

export const getSelectedProjectDraftContentsFailureThunk =
  (error: ApiError): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(getSelectedProjectDraftContentsFailure());
    return dispatch(handleApiErrorIfNeeded(error));
  };

export const getSelectedProjectDraftContentsThunk =
  (): AppThunkAction<Promise<void>> => (dispatch, getState) => {
    const { selectedProjectDraft } = getState();
    const selectedProjectDraftUniqueContentsUrlCodes =
      getProjectDraftUniqueContentUrlCodes(selectedProjectDraft);

    dispatch(getSelectedProjectDraftContentsStart());

    return getContents({
      conditions: {
        filter: {
          urlCodes: selectedProjectDraftUniqueContentsUrlCodes
        }
      }
    })
      .then(response =>
        dispatch(getSelectedProjectDraftContentsSuccessThunk(response))
      )
      .catch(error =>
        dispatch(getSelectedProjectDraftContentsFailureThunk(error))
      );
  };

export const getProjectDraftUniqueContentUrlCodes = (
  projectDraft: ProjectDraft
): UrlCode[] => {
  const projectDraftContentsUrlCodes =
    getProjectDraftContentsUrlCodes(projectDraft);
  return getUniqueElements<UrlCode>(projectDraftContentsUrlCodes);
};

export const getProjectDraftContentsUrlCodes = (
  projectDraft: ProjectDraft
): UrlCode[] => {
  const { giftConfigs } = projectDraft.projectJson;
  return getProjectDraftContentUrlCodes(giftConfigs);
};
