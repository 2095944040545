import { gql } from '@apollo/client';

import { internalApiAppClient } from './apolloClient';
import { operationNames } from './index';
import type { FetchResult } from '@apollo/client';

const requestInvoicePdfUrlGql = gql`
  mutation ${operationNames.REQUEST_INVOICE_PDF_URL}($urlCode: String!) {
    requestInvoicePdfUrl(input: { urlCode: $urlCode }) {
      downloadUrl
    }
  }
`;

export type DownloadUrl = string;

export type RequestInvoicePdfUrlResponse = FetchResult<{
  requestInvoicePdfUrl: {
    downloadUrl: DownloadUrl;
  };
}>;

export const requestInvoicePdfUrl = (
  urlCode: UrlCode
): Promise<RequestInvoicePdfUrlResponse> =>
  internalApiAppClient.mutate({
    mutation: requestInvoicePdfUrlGql,
    variables: {
      urlCode
    }
  });
