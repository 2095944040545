import { getLoginUser } from '../../../../common/api/graphql/getLoginUserGql';
import {
  getLoginUserStart,
  getLoginUserSuccess,
  getLoginUserFailure
} from '../actions';
import handleApiErrorIfNeeded from './handleApiErrorIfNeeded';
import type { GetLoginUserResponse } from '../../../../common/api/graphql/getLoginUserGql';
import type { AppThunkAction } from '../thunkType';
import type { ApiError } from '@common/api/graphql/apolloClient';

const getLoginUserSuccessThunk =
  (response: GetLoginUserResponse): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(getLoginUserSuccess(response.data!));
    return Promise.resolve();
  };

export const getLoginUserFailureThunk =
  (error: ApiError): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(getLoginUserFailure());
    return dispatch(handleApiErrorIfNeeded(error));
  };

export const getLoginUserThunk =
  (): AppThunkAction<Promise<void>> => (dispatch, getState) => {
    dispatch(getLoginUserStart());
    return getLoginUser()
      .then(response => dispatch(getLoginUserSuccessThunk(response)))
      .catch(error => dispatch(getLoginUserFailureThunk(error)));
  };
