import { gql } from '@apollo/client';

import { internalApiAppClient } from '@common/api/graphql/apolloClient';
import { operationNames } from './index';
import type { FetchResult } from '@apollo/client';
import type { QueryProject } from '@common/api/graphql/getProjectsGql';

const getProjectByNameGql = gql`
  query ${operationNames.GET_PROJECT_BY_NAME}($name: String!) {
    project(name: $name) {
      urlCode
    }
  }
`;

type GetProjectByNameResponse = FetchResult<{
  project: Pick<QueryProject, 'urlCode'>;
}>;

export const getProjectByName = (
  name: string
): Promise<GetProjectByNameResponse> =>
  internalApiAppClient.query({
    query: getProjectByNameGql,
    variables: {
      name
    }
  });
