import { gql } from '@apollo/client';

import { internalApiAppClient } from './apolloClient';
import { operationNames } from './operationNames';
import type { FetchResult } from '@apollo/client';

const getClaimEstimateGql = gql`
  query ${operationNames.GET_CLAIM_ESTIMATE}($giftPrice: Int!) {
    claimEstimate(giftPrice: $giftPrice) {
      consumptionTaxPrice
      issueCommissionPrice
      totalPrice
    }
  }
`;

export interface ClaimEstimate {
  consumptionTaxPrice: number;
  issueCommissionPrice: number;
  totalPrice: number;
}

export type GetClaimEstimateResponse = FetchResult<{
  claimEstimate: ClaimEstimate;
}>;

export const getClaimEstimate = (
  giftPrice: number
): Promise<GetClaimEstimateResponse> =>
  internalApiAppClient.query({
    query: getClaimEstimateGql,
    variables: {
      giftPrice
    }
  });
