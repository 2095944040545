import { AppActionTypes } from '@console/actions/actionType';
import { combineReducers } from 'redux';
import type { AppAction } from '@console/actions/actionType';
import type { LocalProject } from '@console/reducers/projects';
import type { Reducer } from 'react';

const urlCode: Reducer<
  NonNullable<LocalProject['messageThread']>['urlCode'] | null,
  AppAction
> = (state = null, action) => {
  switch (action.type) {
    case AppActionTypes.GET_PROJECT_DETAIL_SUCCESS:
      return action.project.messageThread
        ? action.project.messageThread.urlCode
        : null;
    default:
      return state;
  }
};

export const messageThread = combineReducers({
  urlCode
});
