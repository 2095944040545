import moment from 'moment';

moment.updateLocale('ja', {
  months: [
    '１月',
    '２月',
    '３月',
    '４月',
    '５月',
    '６月',
    '７月',
    '８月',
    '９月',
    '１０月',
    '１１月',
    '１２月'
  ],
  longDateFormat: {
    LT: 'h:mm A',
    LTS: 'h:mm:ss A',
    L: 'MM/DD/YYYY',
    LL: 'MMMM',
    LLL: 'MMMM LT',
    LLLL: 'YYYY, dddd, MMMM'
  }
});

export default moment;
