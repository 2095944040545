import InfoIcon from '@mui/icons-material/Info';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    info: {
      margin: `0 0 ${theme.spacing(4)} 0`,
      padding: theme.spacing(),
      border: `solid 2px ${theme.palette.error.main}`,
      borderRadius: 5
    },
    infoIcon: {
      fill: theme.palette.info.main,
      margin: `0 ${theme.spacing()}`
    }
  });

type OwnProps = {
  inBusiness: boolean;
};

type UnapprovedMessageProps = OwnProps & WithStyles<typeof styles>;

const UnapprovedMessage: React.FC<UnapprovedMessageProps> = ({
  inBusiness,
  classes
}) =>
  inBusiness ? null : (
    <Grid
      container
      item
      wrap="nowrap"
      alignItems="center"
      xs={12}
      className={classes.info}
      data-cy="unapprovedMessage"
    >
      <InfoIcon className={classes.infoIcon} fontSize="large" />
      <ul>
        <li>
          <Typography variant="body2" color="textPrimary">
            デジタルギフト利用のお申込みの確定は、弊社にてアカウント登録内容を確認完了次第、
            順次可能となりますので、いましばらくお待ちください。
            追ってメールにてお知らせいたします。
          </Typography>
        </li>
        <li>
          <Typography variant="body2" color="textPrimary">
            登録完了通知が届いてもお申込みの完了ができない場合は画面の再読み込み（リロード）をお願いします。
          </Typography>
        </li>
        <li>
          <Typography variant="body2" color="textPrimary">
            お申込みサイトの利用方法や弊社サービス全般でご不明な点がありましたら、右上の「よくある質問」をご確認ください。
          </Typography>
        </li>
      </ul>
    </Grid>
  );

export default withStyles(styles)(UnapprovedMessage);
