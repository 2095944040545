import { getItemizedString } from '@common/lib/getItemizedString';
import { createSelector } from 'reselect';
import type { AppState } from '@console/reducers';
import type { LocalProject } from '@console/reducers/projects';

export const getProjectGiftDistributionChannels = (
  state: AppState
): LocalProject['projectGiftDistributionChannels'] =>
  state.projectDetail.projectGiftDistributionChannels ?? [];

export const getProjectGiftDistributionChannelsForDisplay = createSelector(
  [getProjectGiftDistributionChannels],
  (projectGiftDistributionChannels): string =>
    getItemizedString(
      getProjectGiftDistributionChannelStrings(projectGiftDistributionChannels)
    )
);

const getProjectGiftDistributionChannelStrings = (
  projectGiftDistributionChannels: LocalProject['projectGiftDistributionChannels']
): string[] =>
  projectGiftDistributionChannels.map(projectGiftDistributionChannel =>
    projectGiftDistributionChannel.description
      ? `${projectGiftDistributionChannel.name}：${projectGiftDistributionChannel.description}`
      : `${projectGiftDistributionChannel.name}`
  );
