import { getLocalString } from '@common/lib/getLocalString';
import { getLocalePrice } from '@common/lib/getLocalePrice';
import moment from '@common/lib/moment';
import IssueAmountRequestModal from '@console/components/project/giftConfig/bulkTable/IssueAmountRequestModal';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TextField,
  IconButton,
  Button,
  Grid,
  Typography
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { DesktopDatePicker as DatePicker } from '@mui/x-date-pickers';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import type {
  AddingBulkGiftCardIssueConfigsTablePropsMappedFromState,
  AddingBulkGiftCardIssueConfigsTablePropsMappedFromDispatch
} from '@console/containers/project/giftCardConfigs/bulkGiftCardIssueConfigs/new/AddingBulkGiftCardIssueConfigsTableContainer';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => ({
  table: {
    borderBottom: 'hidden',
    marginBottom: theme.spacing(4)
  },
  tableCell: {
    paddingRight: theme.spacing(4),
    verticalAlign: 'middle'
  },
  addingBulkGiftCardIssueConfigAddButton: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing()
  },
  backButton: {
    marginRight: theme.spacing(8)
  }
});

type AddingBulkGiftCardIssueConfigsTableProps =
  AddingBulkGiftCardIssueConfigsTablePropsMappedFromState &
    AddingBulkGiftCardIssueConfigsTablePropsMappedFromDispatch &
    WithStyles<typeof styles>;

const AddingBulkGiftCardIssueConfigsTable: React.FC<
  AddingBulkGiftCardIssueConfigsTableProps
> = ({
  giftCardConfig,
  addingBulkGiftCardIssueConfigs,
  addingBulkGiftCardIssueAmountTotal,
  addingBulkGiftCardIssueConfigsTotal,
  projectAddingBulkGiftCardIssueConfigsErrors,
  hasValidatorError,
  handleAddingBulkGiftCardIssueConfigRequestedIssueAmountChange,
  handleAddingBulkGiftCardIssueConfigDesiredDeliveryDateChange,
  handleAddAddingBulkGiftCardIssueConfigButtonClick,
  handleRemoveAddingBulkGiftCardIssueConfigButtonClick,
  handleAddingBulkGiftCardIssueConfigRequestedIssueAmountBlur,
  handleAddBulkGiftCardIssueConfigsButtonClick,
  handleBackButtonClick,
  handleValidateProjectAddingBulkGiftCardIssueConfigs,
  classes
}) => {
  const naivagate = useNavigate();
  const [
    bulkGiftCardIssueConfigModalOpen,
    setBulkGiftCardIssueConfigModalOpen
  ] = useState<boolean>(false);

  const handleBulkGiftCardIssueConfigClick = () => {
    handleValidateProjectAddingBulkGiftCardIssueConfigs()
      .then(() =>
        setBulkGiftCardIssueConfigModalOpen(!bulkGiftCardIssueConfigModalOpen)
      )
      .catch(() => {});
  };

  const handleGiftCardModalOKClick = () => {
    handleAddBulkGiftCardIssueConfigsButtonClick(naivagate);
    setBulkGiftCardIssueConfigModalOpen(!bulkGiftCardIssueConfigModalOpen);
  };

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        handleBulkGiftCardIssueConfigClick();
      }}
    >
      <Table
        className={classes.table}
        data-cy="addingBulkGiftCardIssueConfigsTable"
      >
        <TableHead data-cy="addingBulkGiftCardIssueConfigsTableHeader">
          <TableRow>
            <TableCell className={classes.tableCell} align="center">
              単価
            </TableCell>
            <TableCell className={classes.tableCell} align="left">
              数量（見込み）
            </TableCell>
            <TableCell className={classes.tableCell} align="left">
              希望納品日
            </TableCell>
            <TableCell className={classes.tableCell} align="left" />
          </TableRow>
        </TableHead>
        <TableBody data-cy="addingBulkGiftCardIssueConfigsTableBody">
          {addingBulkGiftCardIssueConfigs.map(
            (addingBulkGiftCardIssueConfig, index) => (
              <TableRow
                key={index}
                data-cy={`addingBulkGiftCardIssueConfigRow-${index}`}
              >
                {index === 0 ? (
                  <TableCell
                    className={classes.tableCell}
                    align="center"
                    data-cy="giftCardUnitPrice"
                    rowSpan={addingBulkGiftCardIssueConfigs.length + 1}
                  >
                    {!!giftCardConfig
                      ? getLocalePrice(giftCardConfig.unitPrice)
                      : null}
                  </TableCell>
                ) : null}
                <TableCell className={classes.tableCell} align="left">
                  <TextField
                    variant="standard"
                    id="requestedIssueAmount"
                    name="requestedIssueAmount"
                    value={
                      addingBulkGiftCardIssueConfig.requestedIssueAmount || ''
                    }
                    required
                    type="number"
                    onChange={event =>
                      handleAddingBulkGiftCardIssueConfigRequestedIssueAmountChange(
                        event,
                        index
                      )
                    }
                    onBlur={
                      handleAddingBulkGiftCardIssueConfigRequestedIssueAmountBlur
                    }
                    inputProps={{ min: '1' }}
                    className={classes.bulkGiftCardIssueConfigCell}
                    error={
                      !!projectAddingBulkGiftCardIssueConfigsErrors[index]
                        ?.requestedIssueAmount
                    }
                    data-cy={`addingBulkGiftCardIssueConfigRequestedIssueAmount-${index}`}
                  />
                  <Typography className={classes.errorMessage} color="error">
                    {
                      projectAddingBulkGiftCardIssueConfigsErrors[index]
                        ?.requestedIssueAmount
                    }
                  </Typography>
                </TableCell>
                <TableCell className={classes.tableCell} align="left">
                  <DatePicker
                    value={moment(
                      addingBulkGiftCardIssueConfig.desiredDeliveryDate
                    )}
                    minDate={moment().add(1, 'd')}
                    maxDate={moment().add(1, 'y')}
                    onChange={desiredDeliveryDate =>
                      handleAddingBulkGiftCardIssueConfigDesiredDeliveryDateChange(
                        desiredDeliveryDate,
                        index
                      )
                    }
                    className={classes.bulkGiftCardIssueConfigCell}
                    data-cy={`addingBulkGiftCardIssueConfigDeliveryDate-${index}`}
                    slotProps={{
                      textField: {
                        variant: 'standard',
                        id: 'deliveryDate',
                        required: true,
                        error:
                          !!projectAddingBulkGiftCardIssueConfigsErrors[index]
                            ?.desiredDeliveryDate
                      }
                    }}
                  />
                  <Typography className={classes.errorMessage} color="error">
                    {
                      projectAddingBulkGiftCardIssueConfigsErrors[index]
                        ?.desiredDeliveryDate
                    }
                  </Typography>
                </TableCell>
                <TableCell className={classes.tableCell} align="left">
                  <IconButton
                    onClick={() =>
                      handleRemoveAddingBulkGiftCardIssueConfigButtonClick(
                        index
                      )
                    }
                    data-cy={`addingBulkGiftCardIssueConfigDeleteButton-${index}`}
                    size="large"
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            )
          )}
          <TableRow>
            <TableCell className={classes.tableCell} align="left" colSpan={3}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleAddAddingBulkGiftCardIssueConfigButtonClick}
                startIcon={<AddIcon fontSize="small" />}
                size="large"
                data-cy="addingBulkGiftCardIssueConfigAddButton"
                className={classes.addingBulkGiftCardIssueConfigAddButton}
              >
                発注設定を追加
              </Button>
            </TableCell>
          </TableRow>
          <TableRow data-cy="addingBulkGiftCardIssueConfigsTotal">
            <TableCell rowSpan={4} colSpan={2} />
            <TableCell align="right">商品代金合計</TableCell>
            <TableCell align="right">
              {getLocalePrice(addingBulkGiftCardIssueConfigsTotal)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Grid container justifyContent="flex-end">
        <Button
          onClick={() => handleBackButtonClick(naivagate)}
          color="primary"
          size="large"
          startIcon={<KeyboardArrowLeftIcon />}
          className={classes.backButton}
        >
          戻る
        </Button>
        <Button
          variant="contained"
          size="large"
          color="primary"
          type="submit"
          disabled={
            addingBulkGiftCardIssueConfigs.length === 0 || hasValidatorError
          }
        >
          追加発注する
        </Button>
      </Grid>
      <IssueAmountRequestModal
        open={bulkGiftCardIssueConfigModalOpen}
        toggleModalOpen={() =>
          setBulkGiftCardIssueConfigModalOpen(!bulkGiftCardIssueConfigModalOpen)
        }
        giftCardTicketConfigs={giftCardConfig?.giftCardTicketConfigs}
        handleClick={handleGiftCardModalOKClick}
        issueAmount={getLocalString(addingBulkGiftCardIssueAmountTotal)}
        currentPriceTotal={getLocalePrice(addingBulkGiftCardIssueConfigsTotal)}
        addingBulkGiftCardIssueConfigs={addingBulkGiftCardIssueConfigs}
      />
    </form>
  );
};

export default withStyles(styles)(AddingBulkGiftCardIssueConfigsTable);
