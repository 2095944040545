import Note from '@console/components/common/Note';
import withStyles from '@mui/styles/withStyles';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => ({
  notes: {
    margin: `${theme.spacing(2)} 0`
  }
});

type OwnProps = {
  notes?: string[];
};

type NotesProps = OwnProps & WithStyles<typeof styles>;

const Notes: React.FC<NotesProps> = ({ classes, notes }) => (
  <div data-cy="notes">
    <Note>
      ※発行手数料は変動し、最終金額が異なる場合があります。最終金額は請求書をご確認ください。
    </Note>
    {notes?.map(note => <Note key={note}>{note}</Note>)}
  </div>
);

export default withStyles(styles)(Notes);
