import { requestMessageThreadFileUrl } from '../../../../common/api/graphql/requestMessageThreadFileUrlGql';
import {
  requestMessageThreadFileUrlStart,
  requestMessageThreadFileUrlSuccess,
  requestMessageThreadFileUrlFailure
} from '../actions';
import handleApiErrorIfNeeded from './handleApiErrorIfNeeded';
import type { ApiError } from '../../../../common/api/graphql/apolloClient';
import type {
  RequestMessageThreadFileUrlResponse,
  DownloadUrl
} from '../../../../common/api/graphql/requestMessageThreadFileUrlGql';
import type { UploadedFileUrlCode } from '../../../../common/api/graphql/uploadFileGql';
import type { AppThunkAction } from '../thunkType';

const requestMessageThreadFileUrlSuccessThunk =
  (
    response: RequestMessageThreadFileUrlResponse
  ): AppThunkAction<Promise<string>> =>
  dispatch => {
    const { downloadUrl } = response.data!.requestMessageThreadFileUrl;
    dispatch(requestMessageThreadFileUrlSuccess());
    return Promise.resolve(downloadUrl);
  };

export const requestMessageThreadFileUrlFailureThunk =
  (error: ApiError): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(requestMessageThreadFileUrlFailure());
    return dispatch(handleApiErrorIfNeeded(error));
  };

export const requestMessageThreadFileUrlThunk =
  (urlCode: UploadedFileUrlCode): AppThunkAction<Promise<DownloadUrl | void>> =>
  (dispatch, getState) => {
    dispatch(requestMessageThreadFileUrlStart());

    return requestMessageThreadFileUrl(urlCode)
      .then((response: RequestMessageThreadFileUrlResponse) =>
        dispatch(requestMessageThreadFileUrlSuccessThunk(response))
      )
      .catch(error => dispatch(requestMessageThreadFileUrlFailureThunk(error)));
  };
