import { gql } from '@apollo/client';

import { internalApiAppClient } from './apolloClient';
import { FRAGMENT_CONTENT } from './fragments/content';
import { operationNames } from './operationNames';
import type { Content } from './getContentsGql';
import type { FetchResult } from '@apollo/client';

const getIssueCountsGql = gql`
  ${FRAGMENT_CONTENT}
  mutation ${operationNames.AGGREGATE_GIFTEE_BOX_SELECTED_CONTENT_PERCENTAGE}(
    $gifteeBoxConfigUrlCode: String!
  ) {
    aggregateGifteeBoxSelectedContentPercentage(
      input: {
        gifteeBoxConfigUrlCode: $gifteeBoxConfigUrlCode
      }
    ) {
      results {
        content {
          ...ContentFields
        }
        selectedPercentage
      }
    }
  }
`;

export type AggregateGifteeBoxSelectedContentPercentageParams = {
  gifteeBoxConfigUrlCode: UrlCode;
};

export type AggregateGifteeBoxSelectedContentResult = {
  content: Content;
  selectedPercentage: number;
};

export type AggregateGifteeBoxSelectedContentResults =
  AggregateGifteeBoxSelectedContentResult[];

export type AggregateGifteeBoxSelectedContentPercentagesResponse = FetchResult<{
  aggregateGifteeBoxSelectedContentPercentage: {
    results: AggregateGifteeBoxSelectedContentResults;
  };
}>;

export const aggregateGifteeBoxSelectedContentPercentages = (
  params: AggregateGifteeBoxSelectedContentPercentageParams
): Promise<AggregateGifteeBoxSelectedContentPercentagesResponse> =>
  internalApiAppClient.mutate({
    mutation: getIssueCountsGql,
    variables: {
      ...params
    }
  });
