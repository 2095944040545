import { combineReducers } from 'redux';
import distributionPartner from './distributionPartner';
import distributionPartnerUser from './distributionPartnerUser';
import project from './project';

const editReducer = combineReducers({
  distributionPartner,
  distributionPartnerUser,
  project
});

export default editReducer;
