import { gql } from '@apollo/client';

import { internalApiAppClient } from '@common/api/graphql/apolloClient';
import { FRAGMENT_CONTENT } from '@common/api/graphql/fragments/content';
import { operationNames } from '@common/api/graphql/operationNames';
import type { FetchResult } from '@apollo/client';

const getContentsGql = gql`
  ${FRAGMENT_CONTENT}
  query ${operationNames.GET_CONTENTS}($count: Int, $cursor: String, $conditions: Content_ConditionsInputType){
    contents(first: $count, after: $cursor, conditions: $conditions) {
      edges {
        cursor
        node {
          ...ContentFields
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
`;

type NoteForUsageOption = {
  description: string;
};

export type Content = {
  name: string;
  internalName: string;
  detail: string;
  imageUrl: string;
  brand: {
    name: string;
    noteForUsageOptions: NoteForUsageOption[];
    urlCode: string;
  };
  noteForUsageOptions: NoteForUsageOption[] | null;
  availablePeriod: string;
  urlCode: string;
  price: number;
  customerAttention: string;
  isLimitedPublished: boolean;
};

export type Contents = Content[];

interface ContentsNode extends Content {}
interface ContentsConditions {
  filter: {
    urlCodes?: UrlCode[] | undefined;
    brandUrlCodes?: UrlCode[] | undefined;
    minPrice?: number | undefined;
    maxPrice?: number | undefined;
  };
}
export interface ContentsConnection extends IkedayamaConnection<ContentsNode> {
  conditions: ContentsConditions;
}

export type GetContentsResponse = FetchResult<{
  contents: ContentsConnection;
}>;

export const getContents = (option: {
  count?: number;
  startCursor?: ConnectionCursor;
  conditions?: ContentsConditions;
}): Promise<GetContentsResponse> =>
  internalApiAppClient.query({
    query: getContentsGql,
    variables: {
      count: option.count,
      cursor: option.startCursor,
      conditions: option.conditions
    }
  });
