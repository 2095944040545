import { gql } from '@apollo/client';

import { internalApiAppClient } from './apolloClient';
import { FRAGMENT_PROJJECT } from './fragments/project';
import { operationNames } from './operationNames';
import type { GetProjectsQuery } from './getProjectsGql';
import type { MutationErrors } from './mutationErrorType';
import type { FetchResult } from '@apollo/client';

const createSampleProjectGql = gql`
  ${FRAGMENT_PROJJECT}
  mutation ${operationNames.CREATE_SAMPLE_PROJECT}(
    $solutionSystemUrlCode: String!
    $name: String!
  ) {
    createSampleProject(
      input: { solutionSystemUrlCode: $solutionSystemUrlCode, name: $name }
    ) {
      project {
        ...ProjectFields
      }
      errors {
        __typename
        message
        path
      }
    }
  }
`;

export type CreateSampleProjectResponse = FetchResult<{
  createSampleProject: {
    project: GetProjectsQuery['projects'][number];
    errors: MutationErrors;
  };
}>;

export const createSampleProject = (
  solutionSystemUrlCode: UrlCode,
  projectName: string
): Promise<CreateSampleProjectResponse> =>
  internalApiAppClient.mutate({
    mutation: createSampleProjectGql,
    variables: {
      solutionSystemUrlCode,
      name: projectName
    }
  });
