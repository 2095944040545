import { AppActionTypes } from '../actions/actionType';
import type { AppAction } from '../actions/actionType';
import type { GeneralAnnouncements } from '@common/api/graphql/getGeneralAnnouncementsGql';

export const generalAnnouncements = (
  state: GeneralAnnouncements = [],
  action: AppAction
): GeneralAnnouncements => {
  switch (action.type) {
    case AppActionTypes.GET_GENERAL_ANNOUNCEMENTS_SUCCESS:
      return action.generalAnnouncements;
    default:
      return state;
  }
};
