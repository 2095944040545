import {
  addMessageThreadAttachedFiles,
  removeMessageThreadAttachedFiles,
  removeMessageThreadAttachedFilesAll,
  updateMessageThreadInputMessages
} from '@console/actions/actions';
import { postMessageThreadMessageWithAttachedFilesThunk } from '@console/actions/thunks/postMessageThreadMessageWithAttachedFiles';
import MessageThreadDialog from '@console/components/project/messageThread/MessageThreadDialog';
import {
  getMessageThread,
  getMessageThreadInputMessage
} from '@console/selectors/projectDetail/messageThread';
import { connect } from 'react-redux';
import type {
  AppThunkDispatch,
  AppThunkAction
} from '@console/actions/thunkType';
import type { AppState } from '@console/reducers';

const handleChangeMessageThreadInputMessage =
  (event: React.ChangeEvent<HTMLInputElement>): AppThunkAction<void> =>
  (dispatch, getState) => {
    const selectedMessageThreadUrlCode =
      getMessageThread(getState())?.urlCode ?? '';
    dispatch(
      updateMessageThreadInputMessages(
        selectedMessageThreadUrlCode,
        event.target.value
      )
    );
  };

const attachFile =
  (event: React.ChangeEvent<HTMLInputElement>): AppThunkAction<void> =>
  dispatch => {
    if (event.target.files) {
      const files: File[] = [];

      for (let i = 0; i < event.target.files.length; i += 1) {
        files.push(event.target.files[i]);
      }

      dispatch(addMessageThreadAttachedFiles(files));
    }
    event.target.value = '';
  };

const handleDropFiles =
  (event: React.DragEvent): AppThunkAction<void> =>
  dispatch => {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files) {
      const files: File[] = [];

      for (let i = 0; i < event.dataTransfer.files.length; i += 1) {
        const attachedFile = event.dataTransfer.files[i];
        const isFile = !!attachedFile.type;
        if (isFile) {
          files.push(attachedFile);
        }
      }

      dispatch(addMessageThreadAttachedFiles(files));
    }
  };

const postMessageThreadMessageWithAttachedFiles =
  (messageThreadUrlCode: UrlCode): AppThunkAction<void> =>
  dispatch => {
    dispatch(
      postMessageThreadMessageWithAttachedFilesThunk(messageThreadUrlCode)
    ).catch(() => {});
  };

const mapStateToProps = (state: AppState) => ({
  isMessageThreadDialogOpen: state.isMessageThreadDialogOpen,
  messageThreadUrlCode: getMessageThread(state)?.urlCode ?? '',
  messageThreadInputText: getMessageThreadInputMessage(state),
  messageThreadAttachedFiles: state.messageThreadAttachedFiles
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  handleChangeMessageThreadInputMessage: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => dispatch(handleChangeMessageThreadInputMessage(event)),
  handleDropFiles: (event: React.DragEvent) => dispatch(handleDropFiles(event)),
  attachFile: (event: React.ChangeEvent<HTMLInputElement>) =>
    dispatch(attachFile(event)),
  removeAttachedFile: (attachedFileIndex: number) =>
    dispatch(removeMessageThreadAttachedFiles(attachedFileIndex)),
  removeAttachedFileAll: () => dispatch(removeMessageThreadAttachedFilesAll()),
  handleSubmitButton: (messageThreadUrlCode: string) =>
    dispatch(postMessageThreadMessageWithAttachedFiles(messageThreadUrlCode))
});

export type MessageThreadDialogPropsMappedFromState = ReturnType<
  typeof mapStateToProps
>;

export type MessageThreadDialogPropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MessageThreadDialog);
