import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Fragment } from 'react';
import GiftCardTicketConfigRow from './GiftCardTicketConfigRow';
import GifteeBoxConfigRow from './GifteeBoxConfigRow';
import type {
  BulkTablePropsMappedFromDispatch,
  BulkTablePropsMappedFromState
} from '@console/containers/project/brandCheck/BulkTableContainer';
import type { LocalGiftCardConfig } from '@console/reducers/projects';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => ({
  tableCell: {
    paddingRight: theme.spacing(4),
    verticalAlign: 'middle'
  },
  contentCell: {
    paddingRight: theme.spacing(2),
    width: 420
  }
});

type BulkTableProps = BulkTablePropsMappedFromDispatch &
  BulkTablePropsMappedFromState &
  WithStyles<typeof styles>;

const BulkTable: React.FC<BulkTableProps> = ({
  giftCardConfigs,
  gifteeBoxConfigs,
  classes
}) => {
  const getFirstColumnRowSpan = (giftCardConfig: LocalGiftCardConfig): number =>
    giftCardConfig.giftCardTicketConfigs.reduce(
      (total, giftCardTicketConfig) => {
        if (giftCardTicketConfig.fixedContentOption) {
          return total + 1;
        }
        return (
          total + giftCardTicketConfig.choosableContentOption.choices.length
        );
      },
      1
    );

  return (
    <Table className={classes.table} data-cy="bulkTable">
      <TableHead>
        <TableRow>
          <TableCell className={classes.tableCell} align="left">
            納品希望日
          </TableCell>
          <TableCell className={classes.tableCell} align="left">
            ギフトのタイプ
          </TableCell>
          <TableCell className={classes.tableCell} align="left">
            ブランド
          </TableCell>
          <TableCell className={classes.contentCell} align="left">
            ギフト
          </TableCell>
          <TableCell className={classes.tableCell} align="right">
            審査ステータス
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {giftCardConfigs
          ? giftCardConfigs.map((giftCardConfig, giftCardConfigIndex) => (
              <Fragment key={`giftCardConfig-${giftCardConfigIndex}`}>
                <TableRow>
                  <TableCell
                    className={classes.tableCell}
                    align="left"
                    rowSpan={getFirstColumnRowSpan(giftCardConfig)}
                    data-cy={`bulkGiftCardIssueConfigs-${giftCardConfigIndex}`}
                  >
                    {giftCardConfig.bulkGiftCardIssueConfigs.map(
                      (
                        bulkGiftCardIssueConfig,
                        bulkGiftCardIssueConfigIndex
                      ) => (
                        <Typography
                          variant="body2"
                          className={classes.content}
                          key={`bulkGiftCardIssueConfig-${giftCardConfigIndex}-${bulkGiftCardIssueConfigIndex}`}
                          data-cy={`bulkGiftCardIssueConfig-${giftCardConfigIndex}-${bulkGiftCardIssueConfigIndex}`}
                        >
                          {bulkGiftCardIssueConfig.deliveryDate}
                        </Typography>
                      )
                    )}
                  </TableCell>
                </TableRow>
                {giftCardConfig.giftCardTicketConfigs.map(
                  (giftCardTicketConfig, giftCardTicketConfigIndex) => (
                    <GiftCardTicketConfigRow
                      key={`giftCardTicketConfigRow-${giftCardConfigIndex}-${giftCardTicketConfigIndex}`}
                      giftCardTicketConfig={giftCardTicketConfig}
                    />
                  )
                )}
              </Fragment>
            ))
          : null}
        {gifteeBoxConfigs
          ? gifteeBoxConfigs.map((gifteeBoxConfig, gifteeBoxConfigIndex) => (
              <GifteeBoxConfigRow
                key={`gifteeBoxConfig-${gifteeBoxConfigIndex}`}
                gifteeBoxConfig={gifteeBoxConfig}
              />
            ))
          : null}
      </TableBody>
    </Table>
  );
};

export default withStyles(styles)(BulkTable);
