import { AppActionTypes } from '../actions/actionType';
import type { QueryProjectUrlCode } from '../../../common/api/graphql/getProjectsGql';
import type { AppAction } from '../actions/actionType';

export type SelectedProjectUrlCode = QueryProjectUrlCode;

const selectedProjectUrlCode = (
  state = '',
  action: AppAction
): SelectedProjectUrlCode => {
  switch (action.type) {
    case AppActionTypes.SET_SELECTED_PROJECT_URL_CODE:
      return action.projectUrlCode;
    default:
      return state;
  }
};

export default selectedProjectUrlCode;
