import { getApiConfidentialInformation } from '@console/selectors/projectDetail/confidentialInformation';
import { getGifteeBoxConfigs } from '@console/selectors/projectDetail/gifteeBoxConfigs';
import { createSelector } from 'reselect';
import type {
  GifteeBoxIssueCount,
  GifteeBoxIssueCounts
} from '@common/api/graphql/getIssueCountsGql';
import type { AppState } from '@console/reducers';
import type {
  LocalAPIConfidentialInformationGifteeBoxConfig,
  LocalGifteeBoxConfig
} from '@console/reducers/projects';

type GifteeBoxIssueReport = {
  gifteeBoxConfig: GifteeBoxConfigForIssueReport;
  issueCount: number;
  initialPoint: number;
  totalPrice: number;
};
export type GifteeBoxIssueReports = GifteeBoxIssueReport[];

export type GifteeBoxConfigForIssueReport = LocalGifteeBoxConfig & {
  issueCode: NonNullable<
    LocalAPIConfidentialInformationGifteeBoxConfig['issueCode']
  >;
};

const getGifteeBoxIssueCounts = (state: AppState): GifteeBoxIssueCounts =>
  state.gifteeBoxIssueCounts;

export const getApiGifteeBoxIssueReports = createSelector(
  [getGifteeBoxIssueCounts, getGifteeBoxConfigs, getApiConfidentialInformation],
  (
    gifteeBoxIssueCounts,
    gifteeBoxConfigs,
    apiConfidentialInformation
  ): GifteeBoxIssueReports => {
    if (!apiConfidentialInformation) {
      return [];
    }

    return gifteeBoxIssueCounts.flatMap<GifteeBoxIssueReport>(
      gifteeBoxIssueCount => {
        const { gifteeBoxConfigUrlCode } = gifteeBoxIssueCount;

        const gifteeBoxConfig = gifteeBoxConfigs.find(
          localGifteeBoxConfig =>
            localGifteeBoxConfig.urlCode === gifteeBoxConfigUrlCode
        );

        if (!gifteeBoxConfig) {
          return [];
        }

        const confidentialInformationGifteeBoxConfig =
          apiConfidentialInformation.gifteeBoxConfigs.find(
            localConfidentialInformationGifteeBoxConfig =>
              localConfidentialInformationGifteeBoxConfig.urlCode ===
              gifteeBoxConfig.urlCode
          );

        return createApiGifteeBoxIssueReport(
          gifteeBoxConfig,
          confidentialInformationGifteeBoxConfig,
          gifteeBoxIssueCount
        );
      }
    );
  }
);

const createApiGifteeBoxIssueReport = (
  gifteeBoxConfig: LocalGifteeBoxConfig,
  confidentialInformationGifteeBoxConfig:
    | LocalAPIConfidentialInformationGifteeBoxConfig
    | undefined,
  gifteeBoxIssueCount: GifteeBoxIssueCount
): GifteeBoxIssueReport => ({
  gifteeBoxConfig: getGifteeBoxConfigForIssueReport(
    gifteeBoxConfig,
    confidentialInformationGifteeBoxConfig
  ),
  initialPoint: gifteeBoxIssueCount.initialPoint,
  issueCount: gifteeBoxIssueCount.count,
  totalPrice: gifteeBoxIssueCount.initialPoint * gifteeBoxIssueCount.count
});

export const getGifteeBoxConfigForIssueReport = (
  gifteeBoxConfig: LocalGifteeBoxConfig,
  confidentialInformationGifteeBoxConfig:
    | LocalAPIConfidentialInformationGifteeBoxConfig
    | undefined
): GifteeBoxConfigForIssueReport => ({
  ...gifteeBoxConfig,
  issueCode: confidentialInformationGifteeBoxConfig?.issueCode ?? ''
});
