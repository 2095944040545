import { isGiftCardFixedTicketConfig } from '@console/common/project/giftCardTicketConfig';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Fragment } from 'react';
import GiftCardTicketConfigRow from './GiftCardTicketConfigRow';
import GifteeBoxConfigRow from './gcpTable/GifteeBoxConfigRow';
import type {
  GcpTablePropsMappedFromDispatch,
  GcpTablePropsMappedFromState
} from '@console/containers/project/brandCheck/GcpTableContainer';
import type { LocalGiftCardConfig } from '@console/reducers/projects';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => ({
  emptyCell: {
    padding: theme.spacing()
  },
  tableCell: {
    paddingRight: theme.spacing(4),
    verticalAlign: 'middle'
  },
  contentCell: {
    paddingRight: theme.spacing(2),
    width: 420
  },
  issuableDuration: {
    margin: `${theme.spacing()} 0`
  }
});

type GcpTableProps = GcpTablePropsMappedFromDispatch &
  GcpTablePropsMappedFromState &
  WithStyles<typeof styles>;

const GcpTable: React.FC<GcpTableProps> = ({
  classes,
  giftCardConfigs,
  gifteeBoxConfigs
}) => {
  const getFirstColumnRowSpan = (giftCardConfig: LocalGiftCardConfig): number =>
    giftCardConfig.giftCardTicketConfigs.reduce(
      (total, giftCardTicketConfig) => {
        if (isGiftCardFixedTicketConfig(giftCardTicketConfig)) {
          return total + 1;
        }
        return (
          total + giftCardTicketConfig.choosableContentOption.choices.length
        );
      },
      1
    );

  return (
    <Table className={classes.table} data-cy="gcpTable">
      <TableHead data-cy="gcpTableHeader">
        <TableRow>
          <TableCell className={classes.emptyCell} />
          <TableCell className={classes.tableCell} align="left">
            ギフトのタイプ
          </TableCell>
          <TableCell className={classes.tableCell} align="left">
            ブランド
          </TableCell>
          <TableCell className={classes.contentCell} align="left">
            ギフト
          </TableCell>
          <TableCell className={classes.tableCell} align="right">
            審査ステータス
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {giftCardConfigs
          ? giftCardConfigs.map((giftCardConfig, giftCardConfigIndex) => (
              <Fragment key={`giftCardConfig-${giftCardConfigIndex}`}>
                <TableRow>
                  <TableCell
                    className={classes.emptyCell}
                    rowSpan={getFirstColumnRowSpan(giftCardConfig)}
                  />
                </TableRow>
                {giftCardConfig.giftCardTicketConfigs.map(
                  (giftCardTicketConfig, giftCardTicketConfigIndex) => (
                    <GiftCardTicketConfigRow
                      key={`giftCardTicketConfigRow-${giftCardConfigIndex}-${giftCardTicketConfigIndex}`}
                      data-cy={`giftCardTicketConfigRow-${giftCardConfigIndex}-${giftCardTicketConfigIndex}`}
                      giftCardTicketConfig={giftCardTicketConfig}
                    />
                  )
                )}
              </Fragment>
            ))
          : null}
        {gifteeBoxConfigs
          ? gifteeBoxConfigs.map(gifteeBoxConfig => (
              <GifteeBoxConfigRow
                key={gifteeBoxConfig.urlCode}
                gifteeBoxConfig={gifteeBoxConfig}
              />
            ))
          : null}
      </TableBody>
    </Table>
  );
};

export default withStyles(styles)(GcpTable);
