import ComponentLoader from '@common/components/ComponentLoader';
import { useIntersectionObserver } from '@common/hooks/useIntersectionObserver';
import DeliveryEmpty from '@console/components/project/delivery/Empty';
import DeliveryTableContainer from '@console/containers/project/delivery/TableContainer';
import withStyles from '@mui/styles/withStyles';
import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import type {
  DeliveryPropsMappedFromState,
  DeliveryPropsMappedFromDispatch
} from '@console/containers/project/DeliveryContainer';
import type { LocalBulkDeliverable } from '@console/reducers/projects';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';
import type { ReactElement } from 'react';

const styles = (theme: Theme): StyleRules => ({});

type DeliveryProps = DeliveryPropsMappedFromState &
  DeliveryPropsMappedFromDispatch &
  WithStyles<typeof styles>;

type PathParams = {
  urlCode?: string;
};

const Delivery: React.FC<DeliveryProps> = ({
  bulkDeliverables,
  getProjectInitialBulkDeliverables,
  getProjectContinuousBulkDeliverables,
  isBulkDeliverablesLoading
}) => {
  const params = useParams<PathParams>();
  const infiniteScrollTarget = useRef<HTMLDivElement>(null);
  const { startObserving, isIntersect } =
    useIntersectionObserver(infiniteScrollTarget);

  useEffect(() => {
    if (params.urlCode) {
      getProjectInitialBulkDeliverables(params.urlCode);
      startObserving(true);
    }
  }, []);

  useEffect(() => {
    if (params.urlCode && isIntersect) {
      getProjectContinuousBulkDeliverables(params.urlCode);
    }
  }, [isIntersect]);

  const renderContents = (): ReactElement => {
    if (!!bulkDeliverables && bulkDeliverables.length !== 0) {
      return renderTable(bulkDeliverables);
    } else if (isBulkDeliverablesLoading) {
      return <ComponentLoader isLoading={isBulkDeliverablesLoading} />;
    }
    return renderEmpty();
  };

  const renderTable = (
    bulkDeliverables: LocalBulkDeliverable[]
  ): ReactElement => (
    <>
      <DeliveryTableContainer bulkDeliverables={bulkDeliverables} />
      <ComponentLoader isLoading={isBulkDeliverablesLoading} />
    </>
  );

  const renderEmpty = (): ReactElement => <DeliveryEmpty />;

  return (
    <section>
      {renderContents()}
      <div ref={infiniteScrollTarget} />
    </section>
  );
};

export default withStyles(styles)(Delivery);
