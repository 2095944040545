import APIIssueReports from '@console/components/project/APIIssueReports';
import { getApiGiftCardIssueReports } from '@console/selectors/projectDetail/giftCardIssueCounts';
import { getApiGifteeBoxIssueReports } from '@console/selectors/projectDetail/gifteeBoxIssueCounts';
import { connect } from 'react-redux';
import type { AppThunkDispatch } from '@console/actions/thunkType';
import type { AppState } from '@console/reducers';

const mapStateToProps = (state: AppState) => ({
  giftCardIssueReports: getApiGiftCardIssueReports(state),
  gifteeBoxIssueReports: getApiGifteeBoxIssueReports(state),
  loading: state.loaders.isAPIIssueReportsLoading
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({});

export type IssueReportsPropsMappedFromState = ReturnType<
  typeof mapStateToProps
>;

export type IssueReportsPropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(mapStateToProps, mapDispatchToProps)(APIIssueReports);
