import { connect } from 'react-redux';
import {
  updateBillingAddressName,
  updateBillingAddressDivision,
  updateBillingAddressPostalCode,
  updateBillingAddressCity,
  updateBillingAddressPrefectureId,
  updateBillingAddressAddress,
  updateillingAddressOptionalAddress
} from '../../actions/actions';
import { getLoginUserThunk } from '../../actions/thunks/getLoginUser';
import { searchPostcodesThunk } from '../../actions/thunks/searchPostcodes';
import { updateDefaultBillingAddressThunk } from '../../actions/thunks/updateDefaultBillingAddress';
import { AccountContents } from '../../components/MainContents';
import DistributionPartnerEdit from '../../components/account/DistributionPartnerEdit';
import type { AppThunkAction, AppThunkDispatch } from '../../actions/thunkType';
import type { AppState } from '../../reducers';
import type { NavigateFunction } from 'react-router-dom';

const onClickUpdateBillingAddress =
  (navigate: NavigateFunction): AppThunkAction<void> =>
  (dispatch, getState) => {
    const { urlCode } = getState().distributionPartner;
    dispatch(updateDefaultBillingAddressThunk())
      .then(() => {
        dispatch(getLoginUserThunk());
        navigate(
          `/console/${AccountContents.DISTRIBUTION_PARTNERS}/${urlCode}`
        );
      })
      .catch(() => {});
  };

const onClickSearchAddress =
  (): AppThunkAction<void> => (dispatch, getState) => {
    dispatch(searchPostcodesThunk()).catch(() => {});
  };

const mapStateToProps = (state: AppState) => ({
  distributionPartner: state.edits.distributionPartner,
  prefectures: state.prefectures,
  errors: state.errors
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  onChangeBillingAddressName: (event: GenericChangeEvent<string>) =>
    dispatch(updateBillingAddressName(event.target.value)),
  onChangeBillingAddressDivision: (event: GenericChangeEvent<string>) =>
    dispatch(updateBillingAddressDivision(event.target.value)),
  onChangeBillingAddressPostalCode: (event: GenericChangeEvent<string>) =>
    dispatch(updateBillingAddressPostalCode(event.target.value)),
  onChangeBillingAddressCity: (event: GenericChangeEvent<string>) =>
    dispatch(updateBillingAddressCity(event.target.value)),
  onChangeBillingAddressPrefectureId: (prefectureId: number) =>
    dispatch(updateBillingAddressPrefectureId(prefectureId)),
  onChangeBillingAddressAddress: (event: GenericChangeEvent<string>) =>
    dispatch(updateBillingAddressAddress(event.target.value)),
  onChangeBillingAddressOptionalAddress: (event: GenericChangeEvent<string>) =>
    dispatch(updateillingAddressOptionalAddress(event.target.value)),
  onClickSearchAddress: (event: React.MouseEvent) =>
    dispatch(onClickSearchAddress()),
  onClickUpdateBillingAddress: (navigate: NavigateFunction) =>
    dispatch(onClickUpdateBillingAddress(navigate))
});

export type DistributionPartnerEditPropsMappedFromState = ReturnType<
  typeof mapStateToProps
>;
export type DistributionPartnerEditPropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DistributionPartnerEdit);
