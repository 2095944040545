import { getContents } from '@common/api/graphql/getContentsGql';
import {
  getContentsOfSelectedProjectDraftStart,
  getContentsOfSelectedProjectDraftSuccess,
  getContentsOfSelectedProjectDraftFailure
} from '@console/actions/actions';
import handleApiErrorIfNeeded from '@console/actions/thunks/handleApiErrorIfNeeded';
import { getSelectedProjectDraftSelectedGiftConfig } from '@console/selectors/getSelectedProjectDraftForDisplay';
import type { ApiError } from '@common/api/graphql/apolloClient';
import type { GetContentsResponse } from '@common/api/graphql/getContentsGql';
import type { AppThunkAction } from '@console/actions/thunkType';

export const getContentsOfSelectedProjectDraftSuccessThunk =
  (response: GetContentsResponse): AppThunkAction<Promise<void>> =>
  dispatch => {
    const { contents } = response.data!;
    dispatch(getContentsOfSelectedProjectDraftSuccess(contents));
    return Promise.resolve();
  };

export const getContentsOfSelectedProjectDraftFailureThunk =
  (error: ApiError): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(getContentsOfSelectedProjectDraftFailure());
    return dispatch(handleApiErrorIfNeeded(error));
  };

export const getContentsOfSelectedProjectDraftThunk =
  (): AppThunkAction<Promise<void>> => (dispatch, getState) => {
    const selectedGiftConfig =
      getSelectedProjectDraftSelectedGiftConfig(getState());

    if (!selectedGiftConfig) {
      dispatch(getContentsOfSelectedProjectDraftFailure());
      return Promise.reject();
    }

    dispatch(getContentsOfSelectedProjectDraftStart());
    return getContents({
      conditions: { filter: { urlCodes: selectedGiftConfig.selectedContents } }
    })
      .then((response: GetContentsResponse) =>
        dispatch(getContentsOfSelectedProjectDraftSuccessThunk(response))
      )
      .catch(error =>
        dispatch(getContentsOfSelectedProjectDraftFailureThunk(error))
      );
  };
