import { ERROR_MESSAGE_FOR_PAGE_VALIDATION } from '@common/middleware/errorMessages';
import { validateProjectDraftFailure } from '@console/actions/actions';
import { validateGifteeBoxDeliveryConfigThunk } from '@console/actions/thunks/validateProjectDraft/validateGiftConfig/validateDeliveryConfig';
import GifteeBoxDeliveryConfig from '@console/components/common/deliveryConfig/GifteeBoxDeliveryConfig';
import {
  getSelectedProjectDraftSelectedGiftConfig,
  isBulkSolutionSystemSelected,
  isApiSolutionSystemSelected,
  isGcpSolutionSystemSelected
} from '@console/selectors/getSelectedProjectDraftForDisplay';
import { getSelectedGifteeBoxTemplate } from '@console/selectors/projectJson/giftConfigs';
import queryString from 'query-string';
import { connect } from 'react-redux';
import type {
  AppThunkAction,
  AppThunkDispatch
} from '@console/actions/thunkType';
import type { AppState } from '@console/reducers';
import type { NavigateFunction } from 'react-router-dom';

const handleStepNextFromParent =
  (navigate: NavigateFunction): AppThunkAction<void> =>
  dispatch =>
    dispatch(validateGifteeBoxDeliveryConfigThunk())
      .then(() => {
        const activeStep = queryString.parse(location.search);
        const parentStep = Number(activeStep.parentStep);

        navigate(
          `${location.pathname}?parentStep=${parentStep}&childStep=${0}`
        );
      })
      .catch(() => {
        dispatch(
          validateProjectDraftFailure(ERROR_MESSAGE_FOR_PAGE_VALIDATION)
        );
      });

const mapStateToProps = (state: AppState) => ({
  isBulkSolutionSystemSelected: isBulkSolutionSystemSelected(state),
  isApiSolutionSystemSelected: isApiSolutionSystemSelected(state),
  isGcpSolutionSystemSelected: isGcpSolutionSystemSelected(state),
  giftConfig: getSelectedProjectDraftSelectedGiftConfig(state),
  selectedGifteeBoxTemplate: getSelectedGifteeBoxTemplate(state)
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  handleStepNextFromParent: (navigate: NavigateFunction) =>
    dispatch(handleStepNextFromParent(navigate))
});

export type GifteeBoxDeliveryConfigPropsMappedFromState = ReturnType<
  typeof mapStateToProps
>;
export type GifteeBoxDeliveryConfigPropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GifteeBoxDeliveryConfig);
