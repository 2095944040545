const forceDownload = (blob: string, filename: string) => {
  const a = document.createElement('a');
  a.download = filename;
  a.href = blob;
  // For Firefox https://stackoverflow.com/a/32226068
  document.body.appendChild(a);
  a.click();
  a.remove();
};

export const downloadImage = (url: string, filename: string): void => {
  fetch(url, {
    headers: new Headers({ Origin: location.origin }),
    mode: 'cors'
  })
    .then(response => response.blob())
    .then(blob => {
      const blobUrl = window.URL.createObjectURL(blob);
      forceDownload(blobUrl, filename);
    })
    .catch(e => console.error(e));
};
