import { getLocalString } from '@common/lib/getLocalString';
import { InputAdornment, TextField, Autocomplete } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => ({
  textFieldInputLabel: {
    top: -4
  }
});

type OwnProps = {
  initialPoint: string;
  initialPointCandidates: number[];
  maxPoint?: number;
  minPoint?: number;
  step?: number;
  label?: string;
  index?: string;
  isError: boolean;
  onInputChange: (value: string) => void;
  onChange: (value: number) => void;
};

type GifteeBoxInitialPointInputProps = OwnProps & WithStyles<typeof styles>;

const GifteeBoxInitialPointInput: React.FC<GifteeBoxInitialPointInputProps> = ({
  initialPoint,
  initialPointCandidates,
  maxPoint,
  minPoint,
  step,
  label,
  index,
  isError,
  onInputChange,
  onChange,
  classes
}) => (
  <Autocomplete
    id={`giftee-box-initial-point-input-${index ?? 0}`}
    value={initialPoint}
    inputValue={initialPoint}
    options={initialPointCandidates.map(initialPointCandidate => ({
      value: initialPointCandidate,
      label: getLocalString(initialPointCandidate)
    }))}
    isOptionEqualToValue={(option, value) => option.value === value.value}
    filterOptions={(options, state) =>
      options.filter(option =>
        String(option.value).startsWith(state.inputValue)
      )
    }
    renderInput={params => (
      <TextField
        variant="standard"
        {...params}
        required
        type="number"
        autoComplete="off"
        label={label}
        error={isError}
        InputProps={{
          ...params.InputProps,
          startAdornment: <InputAdornment position="start">¥</InputAdornment>
        }}
        InputLabelProps={{
          ...params.InputLabelProps,
          shrink: true,
          className: classes.textFieldInputLabel
        }}
        inputProps={{
          ...params.inputProps,
          max: maxPoint,
          min: minPoint,
          step: step,
          'data-cy': `gifteeBoxInitialPointInputTextFieldInput-${index ?? 0}`
        }}
      />
    )}
    onInputChange={(_, value) => onInputChange(value)}
    onChange={(_, option) => {
      if (typeof option === 'string') {
        onChange(Number(option));
      } else {
        onChange(option.value);
      }
    }}
    freeSolo
    disableClearable
    openOnFocus
    fullWidth
    data-cy={`gifteeBoxInitialPointInput-${index ?? 0}`}
  />
);

export default withStyles(styles)(GifteeBoxInitialPointInput);
