import Typography from '@mui/material/Typography';

type SectionTitleOwnProps = {
  text: string;
  helperText?: string;
};

type SectionTitleProps = SectionTitleOwnProps;

const SectionTitle: React.FC<SectionTitleProps> = ({ text, helperText }) => (
  <>
    <Typography
      variant="body1"
      color="textPrimary"
      data-cy="projectDraftSectionTitle"
    >
      {text}
    </Typography>
    {!!helperText ? (
      <Typography variant="body2" color="textSecondary">
        {helperText}
      </Typography>
    ) : null}
  </>
);

export default SectionTitle;
