import { getLocalString } from '@common/lib/getLocalString';
import { getLocaleEndDateString } from '@common/lib/getLocaleDate';
import { getLocalePrice } from '@common/lib/getLocalePrice';
import GifteeBoxTemplateBrief from '@console/components/common/GifteeBoxTemplateBrief';
import GifteeBoxTemplateContentSelectionsModalContainer from '@console/containers/common/GifteeBoxTemplateContentSelectionsModalContainer';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { useState } from 'react';
import type {
  DisplaySelectedGifteeBoxConfigForGCPProjectMappedFromState,
  DisplaySelectedGifteeBoxConfigForGCPProjectMappedFromDispatch
} from '@console/containers/project/gifteeBoxConfigs/exchangeReport/DisplaySelectedGifteeBoxConfigForGCPProjectContainer';
import type { LocalGifteeBoxConfig } from '@console/reducers/projects';
import type { Theme } from '@mui/material';
import type { StyleRules, WithStyles } from '@mui/styles';

// apiの有効期限は最新の設定だけを表示する
const LAST_API_ISSUE_CONFIG_ITEM_INDEX = -1;

const styles = (theme: Theme): StyleRules => ({
  tableContainer: {
    overflowX: 'scroll',
    whiteSpace: 'nowrap'
  },
  contentTableCell: {
    width: '30%',
    minWidth: 400
  },
  contentTableCellContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center'
  },
  tableCell: {
    width: '20%',
    minWidth: 100
  }
});

type DisplaySelectedGifteeBoxConfigForGCPProjectProps =
  DisplaySelectedGifteeBoxConfigForGCPProjectMappedFromState &
    DisplaySelectedGifteeBoxConfigForGCPProjectMappedFromDispatch &
    WithStyles<typeof styles>;

const DisplaySelectedGifteeBoxConfigForGCPProject: React.FC<
  DisplaySelectedGifteeBoxConfigForGCPProjectProps
> = ({
  selectedGifteeBoxConfig,
  exchangeableEndLabel,
  selectGifteeBoxTemplate,
  deselectGifteeBoxTemplate,
  classes
}) => {
  const [
    gifteeBoxTemplateContentSelectionsModalOpen,
    setGifteeBoxTemplateContentSelectionsModalOpen
  ] = useState(false);

  const openGifteeBoxTemplateContentSelectionsModal = (
    gifteeBoxTemplate: LocalGifteeBoxConfig['gifteeBoxTemplate']
  ) => {
    selectGifteeBoxTemplate(gifteeBoxTemplate);
    setGifteeBoxTemplateContentSelectionsModalOpen(true);
  };

  const closeGifteeBoxTemplateContentSelectionsModal = () => {
    setGifteeBoxTemplateContentSelectionsModalOpen(false);
    deselectGifteeBoxTemplate();
  };

  return (
    <>
      <div className={classes.tableContainer}>
        <Table>
          <TableHead data-cy="displaySelectedGifteeBoxConfigForGCPProject-tableHeader">
            <TableRow>
              <TableCell>
                <Typography>ギフト名</Typography>
              </TableCell>
              <TableCell align="right">
                <Typography>単価</Typography>
              </TableCell>
              <TableCell align="right">
                <Typography>数量</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography>発行有効期限</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedGifteeBoxConfig?.apiGifteeBoxIssueConfigs
              .slice(LAST_API_ISSUE_CONFIG_ITEM_INDEX)
              .map((apiGifteeBoxIssueConfig, apiGifteeBoxIssueConfigIndex) =>
                apiGifteeBoxIssueConfig.issueEstimateOptions.map(
                  (issueEstimateOption, issueEstimateOptionIndex) => (
                    <TableRow
                      key={`${apiGifteeBoxIssueConfigIndex}-${issueEstimateOptionIndex}`}
                      data-cy="displaySelectedGifteeBoxConfigForGCPProject-tableRow"
                    >
                      {issueEstimateOptionIndex === 0 && (
                        <TableCell
                          rowSpan={
                            apiGifteeBoxIssueConfig.issueEstimateOptions.length
                          }
                          className={classes.contentTableCell}
                          data-cy="displaySelectedGifteeBoxConfigForGCPProject-tableCell-gifteeBoxTemplateBrief"
                        >
                          <GifteeBoxTemplateBrief
                            gifteeBoxTemplateName={
                              selectedGifteeBoxConfig.gifteeBoxTemplate.name
                            }
                            gifteeBoxTemplateImageUrl={
                              selectedGifteeBoxConfig.gifteeBoxTemplate.imageUrl
                            }
                            handleButtonClick={() =>
                              openGifteeBoxTemplateContentSelectionsModal(
                                selectedGifteeBoxConfig.gifteeBoxTemplate!
                              )
                            }
                            exchangeableEndLabel={exchangeableEndLabel}
                          />
                        </TableCell>
                      )}
                      <TableCell
                        align="right"
                        className={classes.tableCell}
                        data-cy="displaySelectedGifteeBoxConfigForGCPProject-tableCell-initialPoint"
                      >
                        <Typography
                          key={`${apiGifteeBoxIssueConfigIndex}-${issueEstimateOptionIndex}`}
                        >
                          {getLocalePrice(issueEstimateOption.initialPoint)}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.tableCell}
                        data-cy="displaySelectedGifteeBoxConfigForGCPProject-tableCell-issueAmount"
                      >
                        <Typography>
                          {getLocalString(issueEstimateOption.issueAmount)}
                        </Typography>
                      </TableCell>
                      {issueEstimateOptionIndex === 0 ? (
                        <TableCell
                          className={classes.tableCell}
                          align="center"
                          rowSpan={
                            apiGifteeBoxIssueConfig.issueEstimateOptions.length
                          }
                          data-cy="displaySelectedGifteeBoxConfigForGCPProject-tableCell-issuableEndAt"
                        >
                          <Typography>
                            {getLocaleEndDateString(
                              apiGifteeBoxIssueConfig.issuableEndAt
                            )}
                          </Typography>
                        </TableCell>
                      ) : null}
                    </TableRow>
                  )
                )
              )}
          </TableBody>
        </Table>
      </div>
      <GifteeBoxTemplateContentSelectionsModalContainer
        isOpen={gifteeBoxTemplateContentSelectionsModalOpen}
        handleClose={closeGifteeBoxTemplateContentSelectionsModal}
      />
    </>
  );
};
export default withStyles(styles)(DisplaySelectedGifteeBoxConfigForGCPProject);
