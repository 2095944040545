import Detail from '@console/components/project/Detail';
import { getSelectedProjectDetail } from '@console/selectors/projectDetail';
import { getProjectAdvertisingMediumRegistrationsForDisplay } from '@console/selectors/projectDetail/projectAdvertisingMediumRegistrations';
import { getProjectGiftDistributionChannelsForDisplay } from '@console/selectors/projectDetail/projectGiftDistributionChannels';
import { connect } from 'react-redux';
import type { AppState } from '@console/reducers';

const mapStateToProps = (state: AppState) => ({
  selectedProject: getSelectedProjectDetail(state),
  projectGiftDistributionChannelsForDisplay:
    getProjectGiftDistributionChannelsForDisplay(state),
  projectAdvertisingMediumRegistrationsForDisplay:
    getProjectAdvertisingMediumRegistrationsForDisplay(state)
});

const mapDispatchToProps = () => ({});

export type DetailPropsMappedFromState = ReturnType<typeof mapStateToProps>;
export type DetailPropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(mapStateToProps, mapDispatchToProps)(Detail);
