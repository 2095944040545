import {
  GIFTEE_BOX_TEMPLATE_BRAND_NAME,
  GIFTEE_BOX_TICKET_TYPE_DISPLAY_NAME
} from '@console/common/constants';
import { TableCell, TableRow, Typography, Chip } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import BrandCheckStatusChip from './BrandCheckStatusChip';
import ContentCells from './ContentCells';
import type { LocalGifteeBoxConfig } from '@console/reducers/projects';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => ({
  tableCell: {
    paddingRight: theme.spacing(4),
    verticalAlign: 'middle'
  },
  contentCell: {
    paddingRight: theme.spacing(2),
    width: theme.spacing(52)
  },
  contentImage: {
    width: theme.spacing(9),
    verticalAlign: 'middle'
  },
  contentImageContainer: {
    marginRight: theme.spacing(2)
  }
});

type GifteeBoxConfigRowOwnProps = {
  gifteeBoxConfig: LocalGifteeBoxConfig;
};

type GifteeBoxConfigRowProps = GifteeBoxConfigRowOwnProps &
  WithStyles<typeof styles>;

const GifteeBoxConfigRow: React.FC<GifteeBoxConfigRowProps> = ({
  classes,
  gifteeBoxConfig
}) => (
  <TableRow data-cy="gifteeBoxConfigRow">
    <TableCell className={classes.tableCell}>
      {gifteeBoxConfig.bulkGifteeBoxIssueConfigs.map(
        (bulkGifteeBoxIssueConfig, bulkGifteeBoxIssueConfigIndex) => (
          <Typography
            variant="body2"
            className={classes.content}
            key={`gifteeBoxConfigDeliveryDate-${bulkGifteeBoxIssueConfigIndex}`}
            data-cy={`gifteeBoxConfigDeliveryDate-${bulkGifteeBoxIssueConfigIndex}`}
          >
            {bulkGifteeBoxIssueConfig.deliveryDate}
          </Typography>
        )
      )}
    </TableCell>
    <TableCell
      className={classes.tableCell}
      align="left"
      data-cy="gifteeBoxConfigTicketType"
    >
      <Chip label={GIFTEE_BOX_TICKET_TYPE_DISPLAY_NAME} />
    </TableCell>
    <ContentCells
      brandName={GIFTEE_BOX_TEMPLATE_BRAND_NAME}
      contentName={gifteeBoxConfig.gifteeBoxTemplate.name}
      contentImageUrl={gifteeBoxConfig.gifteeBoxTemplate.imageUrl}
    />
    <TableCell className={classes.tableCell} align="right">
      <BrandCheckStatusChip
        creativeCheckApproved={gifteeBoxConfig.brandCheckApproved}
      />
    </TableCell>
  </TableRow>
);

export default withStyles(styles)(GifteeBoxConfigRow);
