import { getLocalePrice } from '@common/lib/getLocalePrice';
import {
  isFixedTicketType,
  isChoosableTicketType
} from '@console/common/projectDraft/giftConfig';
import TicketTypeChip from '@console/components/projectDraft/common/TicketTypeChip';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { Fragment } from 'react';
import ContentRow from '../ContentRow';
import type {
  ApiTablePropsMappedFromState,
  ApiTablePropsMappedFromDispatch
} from '@console/containers/projectDraft/deliveryConfig/ApiTableContainer';
import type { Theme } from '@mui/material';
import type { StyleRules, WithStyles } from '@mui/styles';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    table: {
      margin: `1% 0`
    },
    tableCell: {
      padding: theme.spacing(2),
      verticalAlign: 'middle',
      minWidth: 130
    },
    contentCell: {
      paddingRight: theme.spacing(2),
      width: theme.spacing(52)
    },
    contentContainer: {
      margin: `${theme.spacing()} 0`
    },
    errorMessage: {
      minHeight: theme.spacing(3),
      fontSize: 14
    }
  });

type ApiTableProps = ApiTablePropsMappedFromState &
  ApiTablePropsMappedFromDispatch &
  WithStyles<typeof styles>;

const ApiTable: React.FC<ApiTableProps> = ({
  giftConfigForDisplay,
  giftConfigErrors,
  onChangeIssueAmount,
  classes
}) =>
  !giftConfigForDisplay ? null : (
    <Table className={classes.table} data-cy="giftCardDeliveryConfigApiTable">
      <TableHead>
        <TableRow>
          <TableCell className={classes.tableCell} align="center">
            ギフトのタイプ
          </TableCell>
          <TableCell className={classes.contentCell} align="left">
            ギフト・ブランド名
          </TableCell>
          <TableCell className={classes.tableCell} align="right">
            単価
          </TableCell>
          <TableCell className={classes.tableCell} align="right">
            数量（見込み）
          </TableCell>
        </TableRow>
      </TableHead>
      {giftConfigForDisplay.selectedContents.length === 0 ? null : (
        <TableBody>
          <TableRow>
            <TableCell className={classes.tableCell} align="center">
              <TicketTypeChip ticketType={giftConfigForDisplay.ticketType} />
            </TableCell>
            <TableCell className={classes.contentCell} align="left">
              <Grid container direction="column" alignItems="center">
                {giftConfigForDisplay.selectedContents.map((content, i) => {
                  if (!content) return null;

                  if (isFixedTicketType(giftConfigForDisplay.ticketType)) {
                    return (
                      <div key={i} className={classes.contentContainer}>
                        <ContentRow
                          contentName={content.name}
                          contentInternalName={content.internalName}
                          contentImageUrl={content.imageUrl}
                          contentBrandName={content.brand.name}
                          contentAvailablePeriodText={`有効期限：${content.availablePeriod}`}
                          isLimitedPublished={content.isLimitedPublished}
                        />
                      </div>
                    );
                  } else if (
                    isChoosableTicketType(giftConfigForDisplay.ticketType)
                  ) {
                    return (
                      <div key={i} className={classes.contentContainer}>
                        <ContentRow
                          contentName={content.name}
                          contentInternalName={content.internalName}
                          contentImageUrl={content.imageUrl}
                          contentBrandName={content.brand.name}
                          selectablePeriodText={`選択期限: 1ヶ月後の月末`}
                          contentAvailablePeriodText={`選択後の有効期限：${content.availablePeriod}`}
                          isLimitedPublished={content.isLimitedPublished}
                        />
                      </div>
                    );
                  }

                  return null;
                })}
              </Grid>
            </TableCell>
            <TableCell className={classes.tableCell} align="right">
              {getLocalePrice(giftConfigForDisplay.price)}
            </TableCell>
            <TableCell className={classes.tableCell} align="right">
              <TextField
                variant="standard"
                id="numOfGiftDelivery"
                name="numOfGiftDelivery"
                value={giftConfigForDisplay.issueAmount}
                type="number"
                onChange={onChangeIssueAmount}
                inputProps={{ min: '0' }}
                error={!!giftConfigErrors?.issueAmount}
              />
              <Typography
                id="errorMessageForName"
                className={classes.errorMessage}
                color="error"
              >
                {giftConfigErrors?.issueAmount}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      )}
    </Table>
  );

export default withStyles(styles)(ApiTable);
