import { projectDraftCampaignOverviewHasValidationErrors } from '@console/middleware/validator/projectDraft/campaignOverview';
import {
  projectDraftGiftConfigsHasValidationErrors,
  projectDraftGiftConfigsCommonHasValidationErrors
} from '@console/middleware/validator/projectDraft/giftConfigs';
import { projectDraftGiftDistributionHasValidationErrors } from '@console/middleware/validator/projectDraft/giftDistribution';
import {
  isBulkSolutionSystemSelected,
  isApiSolutionSystemSelected,
  isGcpSolutionSystemSelected
} from '@console/selectors/getSelectedProjectDraftForDisplay';
import {
  validateBulkProjectDraft,
  validateApiProjectDraft,
  validateGcpProjectDraft
} from '../actions';
import type { AppThunkAction } from '../thunkType';
import type { Errors } from '@console/reducers/errors';

export const validateProjectDraftThunk =
  (): AppThunkAction<Promise<void>> => (dispatch, getState) => {
    const { projectJson } = getState().selectedProjectDraft;
    if (isBulkSolutionSystemSelected(getState())) {
      dispatch(validateBulkProjectDraft(projectJson));
    }
    if (isApiSolutionSystemSelected(getState())) {
      dispatch(validateApiProjectDraft(projectJson));
    }
    if (isGcpSolutionSystemSelected(getState())) {
      dispatch(validateGcpProjectDraft(projectJson));
    }

    const { errors } = getState();
    if (projectDraftHasValidationErrors(errors)) {
      return Promise.reject();
    }
    return Promise.resolve();
  };

const projectDraftHasValidationErrors = (errors: Errors): boolean =>
  projectDraftCampaignOverviewHasValidationErrors(errors) ||
  projectDraftGiftDistributionHasValidationErrors(errors) ||
  projectDraftGiftConfigsHasValidationErrors(errors) ||
  projectDraftGiftConfigsCommonHasValidationErrors(errors);
