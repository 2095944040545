import { AppActionTypes } from '../actions/actionType';
import type { AppAction } from '../actions/actionType';

export const selectedContentUrlCode = (
  state = '',
  action: AppAction
): string => {
  switch (action.type) {
    case AppActionTypes.SET_SELECTED_CONTENT_URL_CODE:
      return action.contentUrlCode;
    case AppActionTypes.REMOVE_SELECTED_CONTENT_URL_CODE:
      return '';
    default:
      return state;
  }
};
