import { connect } from 'react-redux';
import GiftCardPreview from '../../components/common/GiftCardPreview';
import {
  getMessageCardForPreviewInProjectDraft,
  getGiftCardTicketsForReviewInProjectDraft
} from '../../selectors/getGiftCardForPreview';
import type { AppThunkAction, AppThunkDispatch } from '../../actions/thunkType';
import type { AppState } from '../../reducers';

const mapStateToProps = (state: AppState) => ({
  header: state.defaultGiftCardConfig.header,
  footer: state.defaultGiftCardConfig.footer,
  messageCard: getMessageCardForPreviewInProjectDraft(state),
  giftCardTickets: getGiftCardTicketsForReviewInProjectDraft(state),
  // TODO: 固定値ではなく、giftWalletStorableを案件申込時に設定可能な値にした上で、その値を参照する
  giftWalletStorable: true
});

const handleTicketClick = (): AppThunkAction<void> => () => {};

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  handleTicketClick: () => dispatch(handleTicketClick())
});

export type GiftCardPreviewPropsMappedFromState = ReturnType<
  typeof mapStateToProps
>;
export type GiftCardPreviewPropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(mapStateToProps, mapDispatchToProps)(GiftCardPreview);
