import { validateGiftDistribution } from '@console/actions/actions';
import { projectDraftGiftDistributionHasValidationErrors } from '@console/middleware/validator/projectDraft/giftDistribution';
import type { AppThunkAction } from '@console/actions/thunkType';

export const validateGiftDistributionThunk =
  (): AppThunkAction<Promise<void>> => (dispatch, getState) => {
    const { projectJson } = getState().selectedProjectDraft;
    dispatch(validateGiftDistribution(projectJson));

    const { errors } = getState();
    if (projectDraftGiftDistributionHasValidationErrors(errors)) {
      return Promise.reject();
    }
    return Promise.resolve();
  };
