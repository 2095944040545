import { TICKET_TYPE } from '@console/reducers/projectJson/giftConfigs';
import { initialStateOfProjectDraft } from '@console/reducers/selectedProjectDraft';
import type { ProjectDraft } from '@console/reducers/selectedProjectDraft';

// 対象のProjectDraftに存在しない項目をinitialStateから入れる。
export const convertProjectDrafts = projectDrafts => {
  const parsedProjectDrafts = parseProjectDrafts(projectDrafts);

  const fullKeysProjectDrafts = addNecessaryKeys(parsedProjectDrafts);

  return convertGiftConfigTicketTypeToLoweraCase(fullKeysProjectDrafts);
};

const parseProjectDrafts = projectDrafts =>
  projectDrafts.map(projectDraft => ({
    ...projectDraft,
    projectJson: JSON.parse(projectDraft.projectJson)
  }));

const addNecessaryKeys = parsedProjectDrafts =>
  parsedProjectDrafts.map(projectDraft => ({
    ...projectDraft,
    projectJson: Object.assign(
      {},
      initialStateOfProjectDraft.projectJson,
      projectDraft.projectJson
    )
  }));

const convertGiftConfigTicketTypeToLoweraCase = fullKeysProjectDrafts =>
  fullKeysProjectDrafts.map((projectDraft: ProjectDraft) => ({
    ...projectDraft,
    projectJson: {
      ...projectDraft.projectJson,
      giftConfigs: projectDraft.projectJson.giftConfigs.map(giftConfig => ({
        ...giftConfig,
        ticketType:
          giftConfig.ticketType !== TICKET_TYPE.GIFTEE_BOX
            ? giftConfig.ticketType.toLowerCase()
            : giftConfig.ticketType
      }))
    }
  }));
