import { AppActionTypes } from '@console/actions/actionType';
import type { ProjectAdvertisingMediumRegistration } from '@common/api/graphql/projectTypes';
import type { AppAction } from '@console/actions/actionType';

export type CampaignAdvertisingMedia = ProjectAdvertisingMediumRegistration[];

export const initialStateOfCampaignAdvertisingMedia: CampaignAdvertisingMedia =
  [];

export const campaignAdvertisingMedia = (
  state = initialStateOfCampaignAdvertisingMedia,
  action: AppAction
): CampaignAdvertisingMedia => {
  switch (action.type) {
    case AppActionTypes.ADD_PROJECT_DRAFT:
    case AppActionTypes.COPY_PROJECT_TO_PROJECT_DRAFT_FAILURE:
      return initialStateOfCampaignAdvertisingMedia;
    case AppActionTypes.SELECT_PROJECT_DRAFT:
      return action.projectDraft.projectJson.campaignAdvertisingMedia;
    case AppActionTypes.TOGGLE_CAMPAIGN_ADVERTISING_MEDIUM:
      const hasUrlCode = state.some(
        advertisingMediumRegistration =>
          advertisingMediumRegistration.urlCode === action.urlCode
      );
      if (hasUrlCode) {
        return state.filter(
          advertisingMediumRegistration =>
            advertisingMediumRegistration.urlCode !== action.urlCode
        );
      }
      return [...state, { urlCode: action.urlCode, description: '' }];
    case AppActionTypes.UPDATE_CAMPAIGN_ADVERTISING_MEDIUM_DESCRIPTION:
      const registrations = state.filter(
        advertisingMediumRegistration =>
          advertisingMediumRegistration.urlCode !== action.urlCode
      );
      return [
        ...registrations,
        { urlCode: action.urlCode, description: action.description }
      ];
    case AppActionTypes.EXCLUDE_INVALID_CAMPAIGN_ADVERTISING_MEDIA:
      return action.excludedCampaignAdvertisingMedia;
    case AppActionTypes.COPY_PROJECT_TO_PROJECT_DRAFT:
      return action.project.projectAdvertisingMediumRegistrations.map(
        projectAdvertisingMediumRegistration => ({
          urlCode: projectAdvertisingMediumRegistration.urlCode,
          description: projectAdvertisingMediumRegistration.description
        })
      );
    default:
      return state;
  }
};

export default campaignAdvertisingMedia;
