import { isZendeskWidgetEnabled } from '@common/lib/zendeskWidget';
import { useState, useEffect } from 'react';

export const useZendeskWidget = (zendeskWidgetAccountKey: string | null) => {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);

  useEffect(() => {
    if (!!zendeskWidgetAccountKey) {
      const ZENDESK_WIDGET_ELEMENT_ID = 'ze-snippet';
      const ZENDESK_WIDGET_SRC_SCRIPT = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskWidgetAccountKey}`;

      const script = document.createElement('script');
      script.id = ZENDESK_WIDGET_ELEMENT_ID;
      script.src = ZENDESK_WIDGET_SRC_SCRIPT;
      script.addEventListener('load', () => {
        setIsScriptLoaded(true);
      });
      document.body.appendChild(script);
    }
  }, [zendeskWidgetAccountKey]);

  useEffect(() => {
    if (isScriptLoaded && isZendeskWidgetEnabled(window)) {
      window.zE('webWidget', 'setLocale', 'ja');
      window.zE('webWidget', 'hide');
    }
  }, [isScriptLoaded]);

  return { isScriptLoaded };
};
