import { Button, Typography, Divider, Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import giftIcon from '../../assets/images/giftIcon.svg';
import lineLogo from '../../assets/images/lineLogo.svg';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => {
  // ref https://line.me/ja/logo
  const LINE_LOGO_WIDTH = 35;
  const LINE_BASE_COLOR = '#00C300';
  const LINE_MOUSEOVER_COLOR = '#00E000';
  const LINE_CLICK_COLOR = '#00B300';
  const VERTICAL_LINE_BASE_COLOR = '#00b300';
  return {
    saveGiftCardButton: {
      height: LINE_LOGO_WIDTH + LINE_LOGO_WIDTH * 0.2 * 2,
      padding: 0,
      backgroundColor: LINE_BASE_COLOR,
      '&:hover': {
        backgroundColor: LINE_MOUSEOVER_COLOR
      },
      '&:active': {
        backgroundColor: LINE_CLICK_COLOR
      },
      color: 'white',
      borderRadius: 10,
      margin: `${theme.spacing()} 0`
    },
    lineLogo: {
      width: LINE_LOGO_WIDTH,
      height: LINE_LOGO_WIDTH,
      margin: LINE_LOGO_WIDTH * 0.2
    },
    saveGiftCardText: {
      padding: `0 ${LINE_LOGO_WIDTH}px`,
      fontWeight: 'bold'
    },
    giftIcon: {
      display: 'inline',
      width: theme.spacing(2),
      height: theme.spacing(2),
      marginRight: theme.spacing(),
      marginBottom: theme.spacing(0.5)
    }
  };
};

type GiftWalletOwnProps = {
  handleGiftWalletButtonClick?: () => void;
  text: string;
};

type GiftWalletButtonProps = GiftWalletOwnProps & WithStyles<typeof styles>;

const GiftWalletButton: React.FC<GiftWalletButtonProps> = ({
  classes,
  handleGiftWalletButtonClick,
  text
}) => {
  const handleOnClick = () => {
    if (!!handleGiftWalletButtonClick) {
      handleGiftWalletButtonClick();
    }
  };

  return (
    <>
      <Button
        className={classes.saveGiftCardButton}
        onClick={() => handleOnClick()}
        data-cy="giftWalletButton"
      >
        <img
          src={lineLogo}
          className={classes.lineLogo}
          data-cy="giftWalletLineLogo"
        />
        <Divider orientation="vertical" flexItem />
        <Typography variant="body2" className={classes.saveGiftCardText}>
          {text}
        </Typography>
      </Button>
      <Grid container justifyContent="center">
        <img src={giftIcon} className={classes.giftIcon} data-cy="giftIcon" />
        <Typography variant="caption" display="block">
          LINEからギフトを取り出せます
        </Typography>
      </Grid>
    </>
  );
};

export default withStyles(styles)(GiftWalletButton);
