import { combineReducers } from 'redux';
import { AppActionTypes } from '../../actions/actionType';
import type { AppAction } from '../../actions/actionType';

export const RELATION_BETWEEN_HOST_COMPANY_AND_DP = {
  EQUAL: 'equal',
  OTHER: 'other'
} as const;

export type RelationBetweenHostCompanyAndDP =
  (typeof RELATION_BETWEEN_HOST_COMPANY_AND_DP)[keyof typeof RELATION_BETWEEN_HOST_COMPANY_AND_DP];

export type HostCompany = {
  relationToDP: Readonly<RelationBetweenHostCompanyAndDP>;
  name: string | null;
};

export type HostCompanyOther = {
  relationToDP: typeof RELATION_BETWEEN_HOST_COMPANY_AND_DP.OTHER;
  name: NonNullable<HostCompany['name']>;
};

export const isHostCompanyOther = (
  hostCompany: HostCompany
): hostCompany is HostCompanyOther =>
  hostCompany.relationToDP === RELATION_BETWEEN_HOST_COMPANY_AND_DP.OTHER;

export const initialStateOfHostCompany: HostCompany = {
  relationToDP: RELATION_BETWEEN_HOST_COMPANY_AND_DP.EQUAL,
  name: null
};

export const relationToDP = (
  state = initialStateOfHostCompany.relationToDP,
  action: AppAction
): HostCompany['relationToDP'] => {
  switch (action.type) {
    case AppActionTypes.ADD_PROJECT_DRAFT:
      return initialStateOfHostCompany.relationToDP;
    case AppActionTypes.SELECT_PROJECT_DRAFT:
      return action.projectDraft.projectJson.hostCompany.relationToDP;
    case AppActionTypes.UPDATE_HOST_COMPANY_RELATION_TO_DP:
      return action.relationToDP;
    case AppActionTypes.COPY_PROJECT_TO_PROJECT_DRAFT:
      return action.project.hostCompany.isOtherCompanyHosting
        ? RELATION_BETWEEN_HOST_COMPANY_AND_DP.OTHER
        : RELATION_BETWEEN_HOST_COMPANY_AND_DP.EQUAL;
    default:
      return state;
  }
};

export const name = (
  state = initialStateOfHostCompany.name,
  action: AppAction
): HostCompany['name'] => {
  switch (action.type) {
    case AppActionTypes.ADD_PROJECT_DRAFT:
      return initialStateOfHostCompany.name;
    case AppActionTypes.SELECT_PROJECT_DRAFT:
      return action.projectDraft.projectJson.hostCompany.name;
    case AppActionTypes.UPDATE_HOST_COMPANY_RELATION_TO_DP:
      return action.relationToDP === RELATION_BETWEEN_HOST_COMPANY_AND_DP.EQUAL
        ? null
        : state;
    case AppActionTypes.UPDATE_HOST_COMPANY_NAME:
      return action.hostCompanyName;
    case AppActionTypes.COPY_PROJECT_TO_PROJECT_DRAFT:
      return action.project.hostCompany.isOtherCompanyHosting
        ? action.project.hostCompany.name
        : initialStateOfHostCompany.name;
    default:
      return state;
  }
};

export const hostCompany = combineReducers({
  relationToDP,
  name
});
