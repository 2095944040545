import { combineReducers } from 'redux';
import { AppActionTypes } from '../actions/actionType';
import type { DefaultGiftCardConfig } from '../../../common/api/graphql/getDefaultGiftCardConfig';
import type { AppAction } from '../actions/actionType';

const initialStateOfDefaultGiftCardConfig: DefaultGiftCardConfig = {
  header: {
    imageUrl: ''
  },
  footer: {
    imageUrl: '',
    description: ''
  }
};

const header = (
  state = initialStateOfDefaultGiftCardConfig.header,
  action: AppAction
): DefaultGiftCardConfig['header'] => {
  switch (action.type) {
    case AppActionTypes.GET_DEFAULT_GIFT_CARD_CONFIG_SUCCESS:
      return action.header;
    case AppActionTypes.GET_MASTER_DATA_SUCCESS:
      return action.defaultGiftCardConfig.header;
    default:
      return state;
  }
};

const footer = (
  state = initialStateOfDefaultGiftCardConfig.footer,
  action: AppAction
): DefaultGiftCardConfig['footer'] => {
  switch (action.type) {
    case AppActionTypes.GET_DEFAULT_GIFT_CARD_CONFIG_SUCCESS:
      return action.footer;
    case AppActionTypes.GET_MASTER_DATA_SUCCESS:
      return action.defaultGiftCardConfig.footer;
    default:
      return state;
  }
};

export const defaultGiftCardConfig = combineReducers({
  header,
  footer
});
