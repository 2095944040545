import GiftCardPreview from '@console/components/common/GiftCardPreview';
import {
  getMessageCardForPreview,
  getGiftCardTicketsForPreview,
  getGiftWalletStorableForPreview
} from '@console/selectors/projectDetail/giftCardForPreview';
import { connect } from 'react-redux';
import type {
  AppThunkAction,
  AppThunkDispatch
} from '@console/actions/thunkType';
import type { AppState } from '@console/reducers';

const mapStateToProps = (state: AppState) => ({
  header: state.defaultGiftCardConfig.header,
  footer: state.defaultGiftCardConfig.footer,
  messageCard: getMessageCardForPreview(state),
  giftCardTickets: getGiftCardTicketsForPreview(state),
  giftWalletStorable: getGiftWalletStorableForPreview(state)
});

const handleTicketClick = (): AppThunkAction<void> => () => {};

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  handleTicketClick: () => dispatch(handleTicketClick())
});

export type GiftCardPreviewPropsMappedFromState = ReturnType<
  typeof mapStateToProps
>;
export type GiftCardPreviewPropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(mapStateToProps, mapDispatchToProps)(GiftCardPreview);
