import moment from '@common/lib/moment';
import {
  createErrorMessageForEmpty,
  createErrorMessageForPastDate,
  createErrorMessageForProjectDraftFutureDate,
  createErrorMessageForInputAgain
} from '@common/middleware/errorMessages';
import { JP_ISSUE_AMOUNT, JP_DELIVERY_DATE } from '@console/common/constants';
import validator from 'validator';
import type { ErrorMessage } from '@console/reducers/errors';
import type { Moment } from 'moment';

export const getRequestedIssueAmountValidationErrorMessage = (
  requestedIssueAmount: string | null
): ErrorMessage => {
  let errorMessage: ErrorMessage = '';

  if (requestedIssueAmount === null) {
    errorMessage = createErrorMessageForEmpty(JP_ISSUE_AMOUNT);
  } else if (validator.isEmpty(requestedIssueAmount)) {
    errorMessage = createErrorMessageForEmpty(JP_ISSUE_AMOUNT);
  }

  return errorMessage;
};

export const getDeliveryDateValidationErrorMessage = (
  deliveryDate: Moment | null | string
): ErrorMessage => {
  let errorMessage: ErrorMessage = '';
  const oneYearLater = new Date();
  oneYearLater.setFullYear(oneYearLater.getFullYear() + 1);

  if (deliveryDate === null) {
    errorMessage = createErrorMessageForEmpty(JP_DELIVERY_DATE);
  } else if (!moment(deliveryDate).isValid()) {
    errorMessage = createErrorMessageForInputAgain(JP_DELIVERY_DATE);
  } else {
    const stringDeliveryDate = moment.isMoment(deliveryDate)
      ? deliveryDate.toISOString()
      : deliveryDate;
    if (!validator.isAfter(stringDeliveryDate)) {
      errorMessage = createErrorMessageForPastDate(JP_DELIVERY_DATE);
    }
    if (!validator.isBefore(stringDeliveryDate, oneYearLater.toString())) {
      errorMessage = createErrorMessageForProjectDraftFutureDate(
        JP_DELIVERY_DATE,
        '1年'
      );
    }
  }
  return errorMessage;
};
