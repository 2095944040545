import { createSelector } from 'reselect';
import { getContentSelectionsFilteringBrandUrlCodes } from './getSearchInfo';
import type { GifteeBoxTemplateBrands } from '@common/api/graphql/getGifteeBoxTemplateBrandsGql';
import type { AppState } from '@console/reducers';

const getSelectedGifteeBoxTemplate = (state: AppState) =>
  state.selectedGifteeBoxTemplate;

const getSelectedGifteeBoxTemplateBrands = createSelector(
  [getSelectedGifteeBoxTemplate],
  (selectedGifteeBoxTemplate): GifteeBoxTemplateBrands => {
    if (!selectedGifteeBoxTemplate || !selectedGifteeBoxTemplate.brands)
      return [];
    return selectedGifteeBoxTemplate.brands;
  }
);

export const getSelectedGifteeBoxTemplateFilteredBrands = createSelector(
  [
    getSelectedGifteeBoxTemplateBrands,
    getContentSelectionsFilteringBrandUrlCodes
  ],
  (
    selectedGifteeBoxTemplateBrands,
    contentSelectionsFilteringBrandUrlCodes
  ): GifteeBoxTemplateBrands =>
    selectedGifteeBoxTemplateBrands.filter(gifteeBoxTemplateBrand =>
      contentSelectionsFilteringBrandUrlCodes.includes(
        gifteeBoxTemplateBrand.urlCode
      )
    )
);
