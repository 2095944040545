import { Button, Popover, Slider, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import { Fragment, useState } from 'react';
import { getLocalePrice } from '../../../../common/lib/getLocalePrice';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    button: {
      borderColor: theme.palette.info.main,
      '& p': {
        fontSize: theme.spacing(2),
        color: theme.palette.text.secondary,
        display: 'block',
        textAlign: 'left',
        width: '100%'
      }
    },
    slider: {
      width: 320,
      margin: '70px 60px 60px 40px'
    }
  });

type SliderMark = {
  value: number;
  label: string;
};

export type SliderPrices = {
  higherPrice: number;
  lowerPrice: number;
};

export type SliderValues = [number, number];

type PricesSliderOwnProps = {
  sliderPrices: SliderPrices;
  sliderMin: number;
  sliderMax: number;
  sliderStep: number;
  sliderMarks: SliderMark[];
  handleSliderPricesChange: (sliderPrices: SliderPrices) => void;
  handleSliderPricesCommitted?: (sliderPrices: SliderPrices) => void;
};

type PricesSliderProps = PricesSliderOwnProps & WithStyles<typeof styles>;

const PricesSlider: React.FC<PricesSliderProps> = ({
  classes,
  sliderPrices,
  sliderMin,
  sliderMax,
  sliderStep,
  sliderMarks,
  handleSliderPricesChange,
  handleSliderPricesCommitted
}) => {
  const [pricesSliderAnchorEl, setPricesSliderAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const [sliderValues, setSliderValues] = useState<SliderValues>([
    sliderMin,
    sliderMax
  ]);
  const [previousSliderValues, setPreviousSliderValues] =
    useState<SliderValues>(sliderValues);

  const handlePricesSliderButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    setPricesSliderAnchorEl(event.currentTarget);
  };

  const handlePricesSliderClose = (): void => {
    setPricesSliderAnchorEl(null);

    if (!handleSliderPricesCommitted) return;

    if (
      sliderValues[0] === previousSliderValues[0] &&
      sliderValues[1] === previousSliderValues[1]
    )
      return;

    handleSliderPricesCommitted(convertToSliderPrices(sliderValues));
    setPreviousSliderValues(sliderValues);
  };

  const handleSliderChange = (newSliderValues: SliderValues): void => {
    handleSliderPricesChange(convertToSliderPrices(newSliderValues));
    setSliderValues(newSliderValues);
  };

  const renderPlaceHolder = (): string => {
    const lastMarkLabel = sliderMarks.find(
      mark => mark.value === sliderMax
    )?.label;
    if (
      sliderPrices.lowerPrice === sliderMin &&
      sliderPrices.higherPrice === sliderMax
    ) {
      return '金額';
    }
    if (sliderPrices.higherPrice === sliderMax) {
      return `${getLocalePrice(sliderPrices.lowerPrice)} ~ ${lastMarkLabel}`;
    }
    return `${getLocalePrice(sliderPrices.lowerPrice)} ~ ${getLocalePrice(
      sliderPrices.higherPrice
    )}`;
  };

  const convertToSliderValues = (sliderPrices: SliderPrices): SliderValues => [
    sliderPrices.lowerPrice,
    sliderPrices.higherPrice
  ];

  const convertToSliderPrices = (sliderValues: SliderValues): SliderPrices => ({
    lowerPrice: sliderValues[0],
    higherPrice: sliderValues[1]
  });

  return (
    <Fragment>
      <Button
        className={clsx(classes.filter, classes.button)}
        variant="outlined"
        onClick={handlePricesSliderButtonClick}
        fullWidth
        data-cy="pricesSliderButton"
        size="large"
      >
        <Typography>{renderPlaceHolder()}</Typography>
      </Button>
      <Popover
        open={Boolean(pricesSliderAnchorEl)}
        anchorEl={pricesSliderAnchorEl}
        onClose={handlePricesSliderClose}
        anchorOrigin={{
          vertical: 50,
          horizontal: 200
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        data-cy="pricesSliderPopover"
      >
        <Slider
          value={convertToSliderValues(sliderPrices)}
          onChange={(_e, value) => handleSliderChange(value as SliderValues)}
          valueLabelDisplay="on"
          className={classes.slider}
          step={sliderStep}
          marks={sliderMarks}
          min={sliderMin}
          max={sliderMax}
          data-cy="pricesSlider"
        />
      </Popover>
    </Fragment>
  );
};

export default withStyles(styles)(PricesSlider);
