import { AppActionTypes } from '../actions/actionType';
import type { AppAction } from '../actions/actionType';

const isMessageThreadDialogOpen = (
  state: boolean = false,
  action: AppAction
): boolean => {
  switch (action.type) {
    case AppActionTypes.ADD_MESSAGE_THREAD_ATTACHED_FILES:
      return true;
    case AppActionTypes.REMOVE_MESSAGE_THREAD_ATTACHED_FILES_ALL:
    case AppActionTypes.POST_MESSAGE_THREAD_MESSAGE_SUCCESS:
    case AppActionTypes.POST_MESSAGE_THREAD_MESSAGE_FAILURE:
      return false;
    default:
      return state;
  }
};

export default isMessageThreadDialogOpen;
