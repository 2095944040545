import { updateGiftConfigIssueAmount } from '@console/actions/actions';
import ApiTable from '@console/components/common/deliveryConfig/ApiTable';
import { getSelectedProjectDraftSelectedGiftConfigForDisplay } from '@console/selectors/getSelectedProjectDraftForDisplay';
import { connect } from 'react-redux';
import type {
  AppThunkAction,
  AppThunkDispatch
} from '@console/actions/thunkType';
import type { AppState } from '@console/reducers';

const onChangeIssueAmount =
  (issueAmount: string): AppThunkAction<void> =>
  (dispatch, getState) => {
    const { selectedGiftConfigIndex } =
      getState().selectedProjectDraft.projectJson;
    dispatch(updateGiftConfigIssueAmount(issueAmount, selectedGiftConfigIndex));
  };

const mapStateToProps = (state: AppState) => ({
  giftConfigForDisplay:
    getSelectedProjectDraftSelectedGiftConfigForDisplay(state),
  giftConfigErrors:
    state.errors.projectDraftGiftConfigs[
      state.selectedProjectDraft.projectJson.selectedGiftConfigIndex
    ]
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  onChangeIssueAmount: (event: GenericChangeEvent<string>) =>
    dispatch(onChangeIssueAmount(event.target.value))
});

export type ApiTablePropsMappedFromState = ReturnType<typeof mapStateToProps>;
export type ApiTablePropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(mapStateToProps, mapDispatchToProps)(ApiTable);
