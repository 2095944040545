import BrandCheckAPITableContainer from '@console/containers/project/brandCheck/APITableContainer';
import BrandCheckBulkTableContainer from '@console/containers/project/brandCheck/BulkTableContainer';
import BrandCheckGcpTableContainer from '@console/containers/project/brandCheck/GcpTableContainer';
import withStyles from '@mui/styles/withStyles';
import type {
  BrandCheckPropsMappedFromState,
  BrandCheckPropsMappedFromDispatch
} from '@console/containers/project/BrandCheckContainer';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';
import type { ReactElement } from 'react';

const styles = (theme: Theme): StyleRules => ({
  tableContainer: {
    overflowX: 'scroll',
    whiteSpace: 'nowrap'
  }
});

type BrandCheckProps = BrandCheckPropsMappedFromState &
  BrandCheckPropsMappedFromDispatch &
  WithStyles<typeof styles>;

const BrandCheck: React.FC<BrandCheckProps> = ({
  isApiProject,
  isBulkProject,
  isGcpProject,
  classes
}) => {
  const renderBody = (): ReactElement | null => {
    if (isBulkProject) {
      return <BrandCheckBulkTableContainer />;
    }
    if (isApiProject) {
      return <BrandCheckAPITableContainer />;
    }
    if (isGcpProject) {
      return <BrandCheckGcpTableContainer />;
    }

    return null;
  };

  return (
    <div className={classes.tableContainer} data-cy="tableContainer">
      {renderBody()}
    </div>
  );
};

export default withStyles(styles)(BrandCheck);
