import {
  ParentSteps,
  GiftConfigChildSteps
} from '@console/components/projectDraft/Root';
import { Grid, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { Link } from 'react-router-dom';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    unselectedContainer: {
      margin: `${theme.spacing(10)} 0`
    },
    contentsSelectionLink: {
      color: 'unset'
    }
  });

type EmptyProps = WithStyles<typeof styles>;

const Empty: React.FC<EmptyProps> = ({ classes }) => (
  <Grid
    container
    justifyContent="center"
    className={classes.unselectedContainer}
    data-cy="gifteeBoxDeliveryConfigEmpty"
  >
    <Typography variant="subtitle1" color="textPrimary">
      商品が選択されていません。
      <Link
        to={`${location.pathname}?parentStep=${ParentSteps.GIFT_CONFIG}&childStep=${GiftConfigChildSteps.CONTENTS_SELECTION}`}
        className={classes.contentsSelectionLink}
      >
        <Typography color="primary" display="inline">
          商品を選択
        </Typography>
      </Link>
      して下さい。
    </Typography>
  </Grid>
);

export default withStyles(styles)(Empty);
