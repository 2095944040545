import {
  isQueryBulkGiftCardDeliverable,
  isQueryBulkGifteeBoxDeliverable
} from '@common/api/graphql/getProjectsGql';
import { getLocalString } from '@common/lib/getLocalString';
import { getLocaleDateString } from '@common/lib/getLocaleDate';
import { getLocalePrice } from '@common/lib/getLocalePrice';
import GifteeBoxConfigMessageCardOptionModal from '@console/components/common/GifteeBoxConfigMessageCardOptionModal';
import GifteeBoxTemplateBrief from '@console/components/common/GifteeBoxTemplateBrief';

import GiftCardTicketConfigsContents from '@console/components/project/common/GiftCardTicketConfigsContents';
import TicketTypeChip, {
  PROJECT_TICKET_TYPE
} from '@console/components/project/common/TicketTypeChip';
import TicketTypeChips from '@console/components/project/common/TicketTypeChips';
import GifteeBoxTemplateContentSelectionsModalContainer from '@console/containers/common/GifteeBoxTemplateContentSelectionsModalContainer';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Typography
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { useState } from 'react';
import type { QueryGifteeBoxConfig } from '@common/api/graphql/getProjectsGql';
import type {
  TablePropsMappedFromDispatch,
  TablePropsMappedFromState
} from '@console/containers/project/delivery/TableContainer';
import type {
  LocalGifteeBoxConfig,
  LocalBulkDeliverable
} from '@console/reducers/projects';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => ({
  tableContainer: {
    overflowX: 'scroll',
    whiteSpace: 'nowrap'
  },
  tableCell: {
    paddingRight: theme.spacing(4),
    verticalAlign: 'middle'
  },
  contentCell: {
    paddingRight: theme.spacing(2),
    width: theme.spacing(52)
  },
  button: {
    width: theme.spacing(15),
    padding: theme.spacing()
  }
});

type DeliveryTableOwnProps = {
  bulkDeliverables: LocalBulkDeliverable[];
};

type DeliveryTableProps = DeliveryTableOwnProps &
  TablePropsMappedFromDispatch &
  TablePropsMappedFromState &
  WithStyles<typeof styles>;

const DeliveryTable: React.FC<DeliveryTableProps> = ({
  classes,
  bulkDeliverables,
  requestBulkDeliverableUrl,
  selectGifteeBoxTemplate,
  deselectGifteeBoxTemplate
}) => {
  const [
    gifteeBoxTemplateContentSelectionsModalOpen,
    setGifteeBoxTemplateContentSelectionsModalOpen
  ] = useState(false);

  const openGifteeBoxTemplateContentSelectionsModal = (
    gifteeBoxTemplate: LocalGifteeBoxConfig['gifteeBoxTemplate']
  ) => {
    selectGifteeBoxTemplate(gifteeBoxTemplate);
    setGifteeBoxTemplateContentSelectionsModalOpen(true);
  };

  const closeGifteeBoxTemplateContentSelectionsModal = () => {
    setGifteeBoxTemplateContentSelectionsModalOpen(false);
    deselectGifteeBoxTemplate();
  };

  const [
    gifteeBoxConfigMessageCardOptionModalOpen,
    setGifteeBoxConfigMessageCardOptionModalOpen
  ] = useState(false);
  const [
    gifteeBoxConfigMessageCardOption,
    setGifteeBoxConfigMessageCardOption
  ] = useState<LocalGifteeBoxConfig['messageCardOption']>({
    description: '',
    imageUrl: ''
  });

  const openGifteeBoxConfigMessageCardOptionModal = (
    gifteeBoxConfig: QueryGifteeBoxConfig
  ) => {
    setGifteeBoxConfigMessageCardOption(gifteeBoxConfig.messageCardOption);
    setGifteeBoxConfigMessageCardOptionModalOpen(true);
  };

  const closeGifteeBoxConfigMessageCardOptionModal = () => {
    setGifteeBoxConfigMessageCardOptionModalOpen(false);
  };

  return (
    <div className={classes.tableContainer} data-cy="tableContainer">
      <Table className={classes.table}>
        <TableHead data-cy="tableHeader">
          <TableRow>
            <TableCell className={classes.tableCell} align="center">
              ギフトのタイプ
            </TableCell>
            <TableCell className={classes.tableCell} align="left">
              ギフト・ブランド名
            </TableCell>
            <TableCell className={classes.contentCell} align="left">
              単価
            </TableCell>
            <TableCell className={classes.contentCell} align="left">
              数量
            </TableCell>
            <TableCell className={classes.tableCell} align="left">
              納品日
            </TableCell>
            <TableCell className={classes.tableCell} align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {bulkDeliverables.map((deliverable, deliverableIndex) => {
            if (isQueryBulkGiftCardDeliverable(deliverable)) {
              return (
                <TableRow key={deliverableIndex}>
                  <TableCell
                    className={classes.tableCell}
                    align="center"
                    data-cy={`bulkGiftCardTicketTypeChips-${deliverableIndex}`}
                  >
                    <TicketTypeChips
                      giftCardConfig={deliverable.giftCardConfig}
                    />
                  </TableCell>
                  <TableCell
                    className={classes.contentCell}
                    align="left"
                    data-cy={`bulkGiftCardTicketConfigContents-${deliverableIndex}`}
                  >
                    <GiftCardTicketConfigsContents
                      giftCardConfig={deliverable.giftCardConfig}
                    />
                  </TableCell>
                  <TableCell className={classes.tableCell} align="left">
                    <Typography variant="body2" className={classes.content}>
                      {getLocalePrice(deliverable.unitPrice)}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell} align="left">
                    <Typography
                      variant="body2"
                      className={classes.content}
                      data-cy="bulkGiftCardIssueAmount"
                    >
                      {getLocalString(deliverable.issueAmount)}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell} align="left">
                    <Typography
                      variant="body2"
                      className={classes.content}
                      data-cy="bulkGiftCardDeliveredAt"
                    >
                      {getLocaleDateString(deliverable.deliveredAt)}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell} align="center">
                    <Button
                      color="primary"
                      variant="contained"
                      className={classes.button}
                      onClick={() =>
                        requestBulkDeliverableUrl(
                          deliverable.urlCode,
                          deliverable.downloadType
                        )
                      }
                      data-cy="bulkGiftCardDownloadButton"
                    >
                      <Typography variant="body2">ダウンロード</Typography>
                    </Button>
                  </TableCell>
                </TableRow>
              );
            } else if (isQueryBulkGifteeBoxDeliverable(deliverable)) {
              return (
                <TableRow key={deliverableIndex}>
                  <TableCell className={classes.tableCell} align="center">
                    <TicketTypeChip
                      ticketType={PROJECT_TICKET_TYPE.GIFTEE_BOX}
                    />
                  </TableCell>
                  <TableCell className={classes.contentCell} align="left">
                    <GifteeBoxTemplateBrief
                      gifteeBoxTemplateName={
                        deliverable.gifteeBoxConfig.gifteeBoxTemplate.name
                      }
                      gifteeBoxTemplateImageUrl={
                        deliverable.gifteeBoxConfig.gifteeBoxTemplate.imageUrl
                      }
                      gifteeBoxConfigUrlCode={
                        deliverable.gifteeBoxConfig.urlCode
                      }
                      exchangeableEndLabel={deliverable.exchangeableEndLabel}
                      handleButtonClick={() =>
                        openGifteeBoxTemplateContentSelectionsModal(
                          deliverable.gifteeBoxConfig.gifteeBoxTemplate
                        )
                      }
                      handleMessageCardOptionButton={
                        deliverable.gifteeBoxConfig.messageCardOption
                          ? () =>
                              openGifteeBoxConfigMessageCardOptionModal(
                                deliverable.gifteeBoxConfig
                              )
                          : undefined
                      }
                    />
                  </TableCell>
                  <TableCell className={classes.tableCell} align="left">
                    <Typography variant="body2" className={classes.content}>
                      {getLocalePrice(deliverable.initialPoint)}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell} align="left">
                    <Typography
                      variant="body2"
                      className={classes.content}
                      data-cy={`bulkGifteeBoxIssueAmount-${deliverableIndex}`}
                    >
                      {getLocalString(deliverable.issueAmount)}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell} align="left">
                    <Typography
                      variant="body2"
                      className={classes.content}
                      data-cy={`bulkGifteeBoxDeliveredAt-${deliverableIndex}`}
                    >
                      {getLocaleDateString(deliverable.deliveredAt)}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell} align="center">
                    <Button
                      color="primary"
                      variant="contained"
                      className={classes.button}
                      onClick={() =>
                        requestBulkDeliverableUrl(
                          deliverable.urlCode,
                          deliverable.downloadType
                        )
                      }
                      data-cy="bulkGifteeBoxDownloadButton"
                    >
                      <Typography variant="body2">ダウンロード</Typography>
                    </Button>
                  </TableCell>
                </TableRow>
              );
            }
          })}
        </TableBody>
      </Table>
      <GifteeBoxTemplateContentSelectionsModalContainer
        isOpen={gifteeBoxTemplateContentSelectionsModalOpen}
        handleClose={closeGifteeBoxTemplateContentSelectionsModal}
      />
      <GifteeBoxConfigMessageCardOptionModal
        isOpen={gifteeBoxConfigMessageCardOptionModalOpen}
        onCloseHandler={closeGifteeBoxConfigMessageCardOptionModal}
        imageUrl={gifteeBoxConfigMessageCardOption?.imageUrl}
        description={gifteeBoxConfigMessageCardOption?.description}
      />
    </div>
  );
};

export default withStyles(styles)(DeliveryTable);
