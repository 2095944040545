import queryString from 'query-string';
import { connect } from 'react-redux';
import { updateMessageCardUsing } from '../../actions/actions';
import MessageCardUsing from '../../components/common/MessageCardUsing';
import type { AppThunkAction, AppThunkDispatch } from '../../actions/thunkType';
import type { AppState } from '../../reducers';
import type { NavigateFunction } from 'react-router-dom';

const onChangeMessageCardUsing =
  (messageCardUsing: string): AppThunkAction<void> =>
  (dispatch, getState) => {
    const { selectedGiftConfigIndex } =
      getState().selectedProjectDraft.projectJson;
    const boolMessageCardUsing = messageCardUsing === 'true';

    dispatch(
      updateMessageCardUsing(boolMessageCardUsing, selectedGiftConfigIndex)
    );
  };

const handleStepNextFromParent =
  (navigate: NavigateFunction): AppThunkAction<void> =>
  (dispatch, getState) => {
    const activeStep = queryString.parse(location.search);
    const parentStep = Number(activeStep.parentStep);
    const childStep = Number(activeStep.childStep);

    if (isMessageCardUsing(getState())) {
      navigate(
        `${location.pathname}?parentStep=${parentStep}&childStep=${
          childStep + 1
        }`
      );
    } else {
      navigate(`${location.pathname}?parentStep=${parentStep}&childStep=${0}`);
    }
  };

const isMessageCardUsing = (state: AppState): boolean => {
  const { selectedGiftConfigIndex } = state.selectedProjectDraft.projectJson;
  const { messageCardUsing } =
    state.selectedProjectDraft.projectJson.giftConfigs[selectedGiftConfigIndex];
  return messageCardUsing;
};

const mapStateToProps = (state: AppState) => ({
  messageCardUsing:
    state.selectedProjectDraft.projectJson.giftConfigs[
      state.selectedProjectDraft.projectJson.selectedGiftConfigIndex
    ].messageCardUsing
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  onChangeMessageCardUsing: (event: GenericChangeEvent<string>) =>
    dispatch(onChangeMessageCardUsing(event.target.value)),
  handleStepNextFromParent: (navigate: NavigateFunction) =>
    dispatch(handleStepNextFromParent(navigate))
});

export type MessageCardUsingPropsMappedFromState = ReturnType<
  typeof mapStateToProps
>;
export type MessageCardUsingPropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(mapStateToProps, mapDispatchToProps)(MessageCardUsing);
