import { ProjectContents } from '@console/components/MainContents';
import type { NavigateFunction } from 'react-router-dom';

export const goProjectGiftConfig = (
  navigate: NavigateFunction,
  selectedProjectUrlCode: UrlCode
) => {
  navigate(
    `/console/projects/${selectedProjectUrlCode}/${ProjectContents.GIFT_CONFIG}`
  );
};

export const goProjectBulkGiftCardIssueConfigsNew = (
  navigate: NavigateFunction,
  selectedProjectUrlCode: UrlCode,
  giftCardConfigUrlCode: UrlCode
) => {
  navigate(
    `/console/projects/${selectedProjectUrlCode}/${ProjectContents.GIFT_CARD_CONFIGS}/${giftCardConfigUrlCode}/${ProjectContents.BULK_GIFT_CARD_ISSUE_CONFIGS}/new`
  );
};

export const goProjectBulkGifteeBoxIssueConfigsNew = (
  navigate: NavigateFunction,
  selectedProjectUrlCode: UrlCode,
  gifteeBoxConfigUrlCode: UrlCode
) => {
  navigate(
    `/console/projects/${selectedProjectUrlCode}/${ProjectContents.GIFTEE_BOX_CONFIGS}/${gifteeBoxConfigUrlCode}/${ProjectContents.BULK_GIFTEE_BOX_ISSUE_CONFIGS}/new`
  );
};
