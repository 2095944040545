import { createSelector } from 'reselect';
import type { AppState } from '@console/reducers';

const getProjectAddingBulkGiftCardIssueConfigs = (state: AppState) =>
  state.errors.projectAddingBulkGiftCardIssueConfigs;
const getProjectAddingBulkGiftCardIssueConfigsTotal = (state: AppState) =>
  state.errors.projectAddingBulkGiftCardIssueConfigsTotal;
const getProjectAddingBulkGifteeBoxIssueConfigs = (state: AppState) =>
  state.errors.projectAddingBulkGifteeBoxIssueConfigs;
const getProjectAddingBulkGifteeBoxIssueConfigsTotal = (state: AppState) =>
  state.errors.projectAddingBulkGifteeBoxIssueConfigsTotal;

export const getHasProjectAddingBulkGiftCardIssueConfigsError = createSelector(
  [
    getProjectAddingBulkGiftCardIssueConfigs,
    getProjectAddingBulkGiftCardIssueConfigsTotal
  ],
  (
    projectAddingBulkGiftCardIssueConfigs,
    projectAddingBulkGiftCardIssueConfigsTotal
  ): boolean =>
    projectAddingBulkGiftCardIssueConfigs.some(
      projectAddingBulkGiftCardIssueConfig =>
        Object.keys(projectAddingBulkGiftCardIssueConfig).length !== 0
    ) || !!projectAddingBulkGiftCardIssueConfigsTotal
);

export const getHasProjectAddingBulkGifteeBoxIssueConfigsError = createSelector(
  [
    getProjectAddingBulkGifteeBoxIssueConfigs,
    getProjectAddingBulkGifteeBoxIssueConfigsTotal
  ],
  (
    projectAddingBulkGifteeBoxIssueConfigs,
    projectAddingBulkGifteeBoxIssueConfigsTotal
  ): boolean =>
    projectAddingBulkGifteeBoxIssueConfigs.some(
      projectAddingBulkGifteeBoxIssueConfig =>
        Object.keys(projectAddingBulkGifteeBoxIssueConfig).length !== 0
    ) || !!projectAddingBulkGifteeBoxIssueConfigsTotal
);
