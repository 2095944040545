import { isProduction, isStaging } from './env';

export const domain = () => {
  if (isProduction) {
    return 'g4b.giftee.biz';
  } else if (isStaging) {
    return 'g4b-stg.giftee.biz';
  } else {
    return 'localhost';
  }
};
