import { Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => ({
  billingMonth: {
    borderBottom: '1px solid',
    padding: 1
  }
});

type BillingLabelOwnProps = { billingMonth: string };
type BillingLabelProps = BillingLabelOwnProps & WithStyles<typeof styles>;

const BillingLabel: React.FC<BillingLabelProps> = ({
  classes,
  billingMonth
}) => (
  <Typography variant="h6">
    <span className={classes.billingMonth} data-cy="claimbillingMonth">
      請求月：{billingMonth}
    </span>
  </Typography>
);

export default withStyles(styles)(BillingLabel);
