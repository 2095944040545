import { projectDetail } from '@console/reducers/projectDetail';
import { searchResult } from '@console/reducers/searchResult';
import { combineReducers } from 'redux';
import advertisingMedia from './advertisingMedia';
import aggregatedGifteeBoxSelectedContentResults from './aggregatedGifteeBoxSelectedContentResults';
import brands from './brands';
import { claimEstimate } from './claimEstimate';
import contents from './contents';
import dashboardProjectDraftAnchors from './dashboardProjectDraftAnchors';
import { defaultGiftCardConfig } from './defaultGiftCardConfig';
import distributionPartner from './distributionPartner';
import distributionPartnerUser from './distributionPartnerUser';
import editReducer from './edit';
import { addingBulkGiftCardIssueConfigs } from './edit/project/giftCardConfigs/addingBulkGiftCardIssueConfigs';
import { errors } from './errors';
import { generalAnnouncements } from './generalAnnouncements';
import giftCardIssueCounts from './giftCardIssueCounts';
import giftCardMainVisuals from './giftCardMainVisuals';
import giftDistributionChannelCategories from './giftDistributionChannelCategories';
import gifteeBoxIssueCounts from './gifteeBoxIssueCounts';
import { gifteeBoxTemplates } from './gifteeBoxTemplates';
import isDrawerOpen from './isDrawerOpen';
import isLoggedIn from './isLoggedIn';
import isMessageThreadDialogOpen from './isMessageThreadDialogOpen';
import isSettingOpen from './isSettingOpen';
import issueReportsFilter from './issueReportsFilter';
import loaders from './loaders';
import marketingIndustries from './marketingIndustries';
import menuAnchor from './menuAnchor';
import messageThread from './messageThread';
import messageThreadAttachedFiles from './messageThreadAttachedFiles';
import messageThreadInputMessages from './messageThreadInputMessages';
import notificationNumber from './notificationNumber';
import prefectures from './prefectures';
import projectDraftSyncStatus from './projectDraftSyncStatus';
import projectDraftSyncedDate from './projectDraftSyncedDate';
import projectDrafts from './projectDrafts';
import projects from './projects';
import { searchInfo } from './searchInfo';
import { selectedContentUrlCode } from './selectedContentUrlCode';
import { selectedGifteeBoxTemplate } from './selectedGifteeBoxTemplate';
import selectedProjectDraft from './selectedProjectDraft';
import selectedProjectGiftConfig from './selectedProjectGiftConfig';
import selectedProjectUrlCode from './selectedProjectUrlCode';
import snackbarMessage from './snackbarMessage';
import solutionSystems from './solutionSystems';
import type { Reducer } from 'react';
import type { Action } from 'redux';

const reducer = combineReducers({
  errors,
  loaders,
  isDrawerOpen,
  notificationNumber,
  isSettingOpen,
  menuAnchor,
  isLoggedIn,
  projectDrafts,
  projects,
  selectedProjectUrlCode,
  projectDetail,
  addingBulkGiftCardIssueConfigs,
  claimEstimate,
  dashboardProjectDraftAnchors,
  distributionPartner,
  distributionPartnerUser,
  selectedProjectDraft,
  brands,
  contents,
  giftCardMainVisuals,
  solutionSystems,
  marketingIndustries,
  generalAnnouncements,
  giftDistributionChannelCategories,
  advertisingMedia,
  defaultGiftCardConfig,
  messageThread,
  messageThreadInputMessages,
  messageThreadAttachedFiles,
  isMessageThreadDialogOpen,
  projectDraftSyncStatus,
  projectDraftSyncedDate,
  snackbarMessage,
  ...selectedProjectGiftConfig,
  selectedContentUrlCode,
  prefectures,
  aggregatedGifteeBoxSelectedContentResults,
  gifteeBoxIssueCounts,
  giftCardIssueCounts,
  issueReportsFilter,
  gifteeBoxTemplates,
  selectedGifteeBoxTemplate,
  searchInfo,
  searchResult,
  edits: editReducer
});

export default reducer;

type StateType<T> = T extends Reducer<infer S, Action> ? S : never;

export type AppState = StateType<typeof reducer>;
