import { addApiAllowedIp } from '@common/api/graphql/addApiAllowedIpGql';
import { createMessage } from '@common/api/graphql/successMessage';
import {
  addApiAllowedIpStart,
  addApiAllowedIpSuccess,
  addApiAllowedIpFailure
} from '../actions';
import handleApiErrorIfNeeded from './handleApiErrorIfNeeded';
import type { AppThunkAction } from '../thunkType';
import type { AddApiAllowedIpResponse } from '@common/api/graphql/addApiAllowedIpGql';
import type { ApiError } from '@common/api/graphql/apolloClient';
import type { QueryApiAllowedIp } from '@common/api/graphql/getProjectsGql';

export const addApiAllowedIpSuccessThunk =
  (response: AddApiAllowedIpResponse): AppThunkAction<Promise<void>> =>
  dispatch => {
    const apiAllowedIp = response.data!.addApiAllowedIp.apiAllowedIp;
    dispatch(
      addApiAllowedIpSuccess(
        createMessage('許可IPアドレスを追加'),
        apiAllowedIp
      )
    );
    return Promise.resolve();
  };

export const addApiAllowedIpFailureThunk =
  (error: ApiError): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(addApiAllowedIpFailure());
    return dispatch(handleApiErrorIfNeeded(error));
  };

export const addApiAllowedIpThunk =
  (
    projectUrlCode: UrlCode,
    ip: QueryApiAllowedIp['ip']
  ): AppThunkAction<Promise<void>> =>
  (dispatch, getState) => {
    dispatch(addApiAllowedIpStart());

    return addApiAllowedIp(projectUrlCode, ip)
      .then((response: AddApiAllowedIpResponse) =>
        dispatch(addApiAllowedIpSuccessThunk(response))
      )
      .catch((error: ApiError) => dispatch(addApiAllowedIpFailureThunk(error)));
  };
