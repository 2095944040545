import {
  errorMessageForApolloClientGraphqlError,
  errorMessageForNotFound
} from '@common/api/graphql/errorMessage';
import { requestFailure } from '../actions';
import type { AppThunkAction } from '../thunkType';
import type { GraphQLFormattedError } from 'graphql';

export const GRAPHQL_ERROR_NOT_FOUND_CODE = 'NOT_FOUND';
export const INTERNAL_SERVER_ERROR_CODE = 'INTERNAL_SERVER_ERROR';

export const handleGraphqlErrors =
  (
    errors: ReadonlyArray<GraphQLFormattedError>
  ): AppThunkAction<Promise<void>> =>
  dispatch => {
    const firstGraphqlError = errors[0];
    if (firstGraphqlError.extensions?.code === GRAPHQL_ERROR_NOT_FOUND_CODE) {
      dispatch(
        requestFailure(
          errorMessageForNotFound,
          firstGraphqlError.extensions.code
        )
      );
    } else {
      dispatch(
        requestFailure(
          errorMessageForApolloClientGraphqlError,
          INTERNAL_SERVER_ERROR_CODE
        )
      );
    }
    return Promise.reject(JSON.stringify(errors));
  };

type Response = {
  errors?: ReadonlyArray<GraphQLFormattedError>;
  [key: string]: any;
};

export const handleGraphqlErrorsIfNeeded =
  (response: Response): AppThunkAction<Promise<void>> =>
  dispatch => {
    if (hasGraphqlError(response)) {
      return dispatch(
        handleGraphqlErrors(
          response.errors as ReadonlyArray<GraphQLFormattedError>
        )
      );
    }
    return Promise.resolve();
  };

export const hasGraphqlError = (response: Response) =>
  response.errors !== undefined;
