import { getGifteeBoxTemplateContinuousContentSelectionsThunk } from '@console/actions/thunks/getGifteeBoxTemplateContinuousContentSelections';
import { getGifteeBoxTemplateInitialContentSelectionsThunk } from '@console/actions/thunks/getGifteeBoxTemplateInitialContentSelections';
import GifteeBoxTemplateContentSelectionsModal from '@console/components/common/GifteeBoxTemplateContentSelectionsModal';
import { connect } from 'react-redux';
import type {
  AppThunkDispatch,
  AppThunkAction
} from '@console/actions/thunkType';
import type { AppState } from '@console/reducers';

const getGifteeBoxTemplateInitialContentSelections =
  (gifteeBoxTemplateUrlCode: UrlCode): AppThunkAction<void> =>
  dispatch => {
    dispatch(
      getGifteeBoxTemplateInitialContentSelectionsThunk(
        gifteeBoxTemplateUrlCode
      )
    ).catch(() => {});
  };

const getGifteeBoxTemplateContinuousContentSelections =
  (gifteeBoxTemplateUrlCode: UrlCode): AppThunkAction<void> =>
  (dispatch, getState) => {
    const { contentSelections } = getState().searchInfo;
    if (
      !!contentSelections &&
      contentSelections.pageInfo &&
      contentSelections.pageInfo.hasNextPage
    ) {
      dispatch(
        getGifteeBoxTemplateContinuousContentSelectionsThunk(
          gifteeBoxTemplateUrlCode,
          {
            after: contentSelections.pageInfo.endCursor,
            conditions: contentSelections.conditions
          }
        )
      ).catch(() => {});
    }
  };

const mapStateToProps = (state: AppState) => ({
  selectedGifteeBoxTemplate: state.selectedGifteeBoxTemplate,
  isGifteeBoxTemplateContentSelectionsLoading:
    state.loaders.isGifteeBoxTemplateContentSelectionsLoading
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  getGifteeBoxTemplateInitialContentSelections: (
    gifteeBoxTemplateUrlCode: UrlCode
  ) =>
    dispatch(
      getGifteeBoxTemplateInitialContentSelections(gifteeBoxTemplateUrlCode)
    ),
  getGifteeBoxTemplateContinuousContentSelections: (
    gifteeBoxTemplateUrlCode: UrlCode
  ) =>
    dispatch(
      getGifteeBoxTemplateContinuousContentSelections(gifteeBoxTemplateUrlCode)
    )
});

export type GifteeBoxTemplateContentSelectionsModalPropsMappedFromState =
  ReturnType<typeof mapStateToProps>;
export type GifteeBoxTemplateContentSelectionsModalPropsMappedFromDispatch =
  ReturnType<typeof mapDispatchToProps>;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GifteeBoxTemplateContentSelectionsModal);
