import { gql } from '@apollo/client';

import { internalApiAppClient } from './apolloClient';
import { operationNames } from './operationNames';
import type { QueryApiAllowedIp } from './getProjectsGql';
import type { MutationErrors } from './mutationErrorType';
import type { FetchResult } from '@apollo/client';

const deleteApiAllowedIpGql = gql`
  mutation ${operationNames.DELETE_API_ALLOWED_IP}(
    $projectUrlCode: String!
    $apiAllowedIpUrlCode: String!
  ) {
    deleteApiAllowedIp(
      input: {
        projectUrlCode: $projectUrlCode
        apiAllowedIpUrlCode: $apiAllowedIpUrlCode
      }
    ) {
      apiAllowedIp {
        ip
        urlCode
      }
      errors {
        __typename
        message
        path
      }
    }
  }
`;

export type DeleteApiAllowedIpResponse = FetchResult<{
  deleteApiAllowedIp: {
    apiAllowedIp: QueryApiAllowedIp;
    errors: MutationErrors;
  };
}>;

export const deleteApiAllowedIp = (
  projectUrlCode: UrlCode,
  apiAllowedIpUrlCode: UrlCode
): Promise<DeleteApiAllowedIpResponse> =>
  internalApiAppClient.mutate({
    mutation: deleteApiAllowedIpGql,
    variables: {
      projectUrlCode,
      apiAllowedIpUrlCode
    }
  });
