import { combineReducers } from 'redux';
import apiAllowedIps from './apiAllowedIps';
import { giftCardConfigs } from './giftCardConfigs';
import { gifteeBoxConfigs } from './gifteeBoxConfigs';

const project = combineReducers({
  apiAllowedIps,
  giftCardConfigs,
  gifteeBoxConfigs
});

export default project;
