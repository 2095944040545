import { hasGon } from '@common/lib/gon';
import { isZendeskWidgetEnabled } from '@common/lib/zendeskWidget';

export interface Gon {
  zendeskWidgetAccountKey?: string;
  g4bMyPageEndpoint?: string;
  eraberuPayGifteeBoxTemplateUrlCode?: string;
}

interface ConsoleWindowWithZendeskWidget
  extends WindowWithZendeskWidget,
    WindowWithGon<Gon> {}

export const isWindowWithZendeskWidget = (
  window: Window
): window is ConsoleWindowWithZendeskWidget =>
  isZendeskWidgetEnabled(window) && hasGon<Gon>(window);
