import { ERROR_MESSAGE_FOR_PAGE_VALIDATION } from '@common/middleware/errorMessages';
import { validateProjectDraftFailure } from '@console/actions/actions';
import { createProjectThunk } from '@console/actions/thunks/createProject';
import { getGifteeBoxTemplatesThunk } from '@console/actions/thunks/getGifteeBoxTemplates';
import { validateProjectDraftThunk } from '@console/actions/thunks/validateProjectDraft';
import Confirmation from '@console/components/projectDraft/Confirmation';
import { ParentSteps } from '@console/components/projectDraft/Root';
import { projectDraftGiftConfigsHasValidationErrors } from '@console/middleware/validator/projectDraft/giftConfigs';
import {
  isApiSolutionSystemSelected,
  isGcpSolutionSystemSelected
} from '@console/selectors/getSelectedProjectDraftForDisplay';
import { connect } from 'react-redux';
import type {
  AppThunkAction,
  AppThunkDispatch
} from '@console/actions/thunkType';
import type { AppState } from '@console/reducers';
import type { NavigateFunction } from 'react-router-dom';

const onMount = (): AppThunkAction<void> => dispatch => {
  dispatch(getGifteeBoxTemplatesThunk()).catch(() => {});
};

const handleStepNextFromParent =
  (navigate: NavigateFunction): AppThunkAction<void> =>
  (dispatch, getState) =>
    dispatch(validateProjectDraftThunk())
      .then(() => dispatch(createProjectThunk()))
      .then(() => {
        navigate('/console/');
      })
      .catch(() => {
        dispatch(
          validateProjectDraftFailure(ERROR_MESSAGE_FOR_PAGE_VALIDATION)
        );
      });

const handleStepBackFromParent =
  (navigate: NavigateFunction): AppThunkAction<void> =>
  dispatch => {
    navigate(
      `${location.pathname}?parentStep=${ParentSteps.GIFT_CONFIG}&childStep=${0}`
    );
  };

const mapStateToProps = (state: AppState) => ({
  isApiSolutionSystemSelected: isApiSolutionSystemSelected(state),
  isGcpSolutionSystemSelected: isGcpSolutionSystemSelected(state),
  isDistributionPartnerInBusiness: state.distributionPartner.inBusiness,
  giftConfigsHasValidationErrors: projectDraftGiftConfigsHasValidationErrors(
    state.errors
  ),
  errors: state.errors
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  onMount: () => dispatch(onMount()),
  handleStepNextFromParent: (navigate: NavigateFunction) =>
    dispatch(handleStepNextFromParent(navigate)),
  handleStepBackFromParent: (navigate: NavigateFunction) =>
    dispatch(handleStepBackFromParent(navigate))
});

export type ConfirmationPropsMappedFromState = ReturnType<
  typeof mapStateToProps
>;
export type ConfirmationPropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(mapStateToProps, mapDispatchToProps)(Confirmation);
