import { createSampleProject } from '@common/api/graphql/createSampleProjectGql';
import { createMessage } from '@common/api/graphql/successMessage';
import {
  createSampleProjectStart,
  createSampleProjectSuccess,
  createSampleProjectFailure
} from '../actions';
import handleApiErrorIfNeeded from './handleApiErrorIfNeeded';
import type { AppThunkAction } from '../thunkType';
import type { ApiError } from '@common/api/graphql/apolloClient';
import type { CreateSampleProjectResponse } from '@common/api/graphql/createSampleProjectGql';
import type { SolutionSystem } from '@common/api/graphql/getSolutionSystemsGql';

export const createSampleProjectSuccessThunk =
  (response: CreateSampleProjectResponse): AppThunkAction<Promise<void>> =>
  dispatch => {
    const { project } = response.data!.createSampleProject;

    dispatch(
      createSampleProjectSuccess(createMessage('APIテスト案件を作成'), project)
    );
    return Promise.resolve();
  };

export const createSampleProjectFailureThunk =
  (error: ApiError): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(createSampleProjectFailure());
    return dispatch(handleApiErrorIfNeeded(error));
  };

export const createSampleProjectThunk =
  (
    apiSolutionSystem: SolutionSystem,
    projectName: string
  ): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(createSampleProjectStart());

    return createSampleProject(apiSolutionSystem.urlCode, projectName)
      .then((response: CreateSampleProjectResponse) =>
        dispatch(createSampleProjectSuccessThunk(response))
      )
      .catch((error: ApiError) =>
        dispatch(createSampleProjectFailureThunk(error))
      );
  };
