import {
  updateGiftConfigIssueAmount,
  updateGiftConfigDeliveryDate
} from '@console/actions/actions';
import BulkTable from '@console/components/common/deliveryConfig/BulkTable';
import { getSelectedProjectDraftSelectedGiftConfigForDisplay } from '@console/selectors/getSelectedProjectDraftForDisplay';
import { connect } from 'react-redux';
import type {
  AppThunkAction,
  AppThunkDispatch
} from '@console/actions/thunkType';
import type { AppState } from '@console/reducers';
import type moment from 'moment';

const onChangeIssueAmount =
  (issueAmount: string): AppThunkAction<void> =>
  (dispatch, getState) => {
    const { selectedGiftConfigIndex } =
      getState().selectedProjectDraft.projectJson;
    dispatch(updateGiftConfigIssueAmount(issueAmount, selectedGiftConfigIndex));
  };

const onChangeDeliveryDate =
  (date: moment.Moment | null): AppThunkAction<void> =>
  (dispatch, getState) => {
    const { selectedGiftConfigIndex } =
      getState().selectedProjectDraft.projectJson;
    dispatch(updateGiftConfigDeliveryDate(date, selectedGiftConfigIndex));
  };

const mapStateToProps = (state: AppState) => ({
  giftConfigForDisplay:
    getSelectedProjectDraftSelectedGiftConfigForDisplay(state),
  giftConfigErrors:
    state.errors.projectDraftGiftConfigs[
      state.selectedProjectDraft.projectJson.selectedGiftConfigIndex
    ]
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  onChangeIssueAmount: (event: GenericChangeEvent<string>) =>
    dispatch(onChangeIssueAmount(event.target.value)),
  onChangeDeliveryDate: (date: moment.Moment | null) =>
    dispatch(onChangeDeliveryDate(date))
});

export type BulkTablePropsMappedFromState = ReturnType<typeof mapStateToProps>;
export type BulkTablePropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(mapStateToProps, mapDispatchToProps)(BulkTable);
