import { getContents } from '@common/api/graphql/getContentsGql';
import {
  getInitialContentsStart,
  getInitialContentsSuccess,
  getInitialContentsFailure
} from '@console/actions/actions';
import handleApiErrorIfNeeded from '@console/actions/thunks/handleApiErrorIfNeeded';
import { CONTENTS_PER_PAGE } from '@console/common/constants';
import type { ApiError } from '@common/api/graphql/apolloClient';
import type { GetContentsResponse } from '@common/api/graphql/getContentsGql';
import type { AppThunkAction } from '@console/actions/thunkType';

export const getInitialContentsSuccessThunk =
  (response: GetContentsResponse): AppThunkAction<Promise<void>> =>
  dispatch => {
    const { contents } = response.data!;
    dispatch(getInitialContentsSuccess(contents));
    return Promise.resolve();
  };

export const getInitialContentsFailureThunk =
  (error: ApiError): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(getInitialContentsFailure());
    return dispatch(handleApiErrorIfNeeded(error));
  };

export const getInitialContentsThunk =
  (): AppThunkAction<Promise<void>> => (dispatch, getState) => {
    const { searchInfo } = getState();
    const contentsSearchInfo = searchInfo.contents;

    dispatch(getInitialContentsStart());
    return getContents({
      count: CONTENTS_PER_PAGE,
      conditions: contentsSearchInfo?.conditions
    })
      .then((response: GetContentsResponse) =>
        dispatch(getInitialContentsSuccessThunk(response))
      )
      .catch(error => dispatch(getInitialContentsFailureThunk(error)));
  };
