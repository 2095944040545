import { Help } from '@mui/icons-material';
import { Typography, Link, Grid } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    helpLink: {
      display: 'flex',
      alignItems: 'center',
      whiteSpace: 'nowrap'
    },
    availablePeriod: {
      fontWeight: 'bold'
    }
  });

type GiftTicketTypeLabelOwnProps = {
  title: string;
  description: string;
  availablePeriod?: string;
  helpLinkUrl: string;
  helpLinkTitle: string;
  disabled?: boolean;
};

type GiftTicketTypeLabelProps = GiftTicketTypeLabelOwnProps &
  WithStyles<typeof styles>;

const GiftTicketTypeLabel: React.FC<GiftTicketTypeLabelProps> = ({
  title,
  description,
  availablePeriod,
  helpLinkTitle,
  helpLinkUrl,
  disabled = false,
  classes
}) => (
  <Grid
    container
    direction="column"
    alignItems="flex-start"
    data-cy={`giftTicketTypeLabel-${title}`}
  >
    <Typography variant="body1">{title}</Typography>
    <Typography
      variant="caption"
      color={disabled ? undefined : 'textSecondary'}
    >
      {description}
    </Typography>
    {availablePeriod && (
      <Typography
        variant="caption"
        data-cy="availablePeriod"
        color={disabled ? undefined : 'textPrimary'}
      >
        選択期限は納品⽇より
        <Typography
          variant="inherit"
          display="inline"
          className={classes.availablePeriod}
        >
          {availablePeriod}
        </Typography>
        となります。
      </Typography>
    )}
    <Link
      href={helpLinkUrl}
      variant="body1"
      target="_blank"
      rel="noopener"
      className={classes.helpLink}
      data-cy="helpLink"
      underline="hover"
    >
      <Help fontSize="small" />
      <Typography display="block" variant="caption">
        {helpLinkTitle}
      </Typography>
    </Link>
  </Grid>
);

export default withStyles(styles)(GiftTicketTypeLabel);
